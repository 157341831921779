import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MiniBreadCrumbs = (props) => {

    const StyledLink = styled(Link)({
        color : "#858585",
        fontSize : "15px ",
        fontFamily : "'Lato',san-serif",
        fontWeight : "500",
        '&:hover': {
            color : "#858585",
            textDecoration : "none"
        },
    })

    switch(props.variant){
        case "icon-breadcrumbs" :
            return (
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <StyledLink
                            underline="none" >
                            <FontAwesomeIcon icon={props.icon} size="sm" style={{color : "#14213d"}} />
                        </StyledLink>
                        {!isEmpty(props.path) && props.path.map((path) => (
                            <StyledLink key={path._id}
                            underline="none"
                            style={{color : props.activeId === path._id ? "rgba(68,68,68,255)" : ""}}
                            aria-current="page">
                            {path.name}
                            </StyledLink>
                        )) }
                    </Breadcrumbs>
                </div>
            )

            case "image-breadcrumbs" :
                return (
                    <div role="presentation">
                        <Breadcrumbs aria-label="breadcrumb">
                            <StyledLink
                                underline="none" >
                                <img className="wh-15" src={props.img} alt="icon" />
                            </StyledLink>
                            {!isEmpty(props.path) && props.path.map((path) => (
                                <StyledLink key={path._id}
                                underline="none"
                                style={{color : props.activeId === path._id ? "rgba(68,68,68,255)" : ""}}
                                aria-current="page">
                                {path.name}
                                </StyledLink>
                            )) }
                        </Breadcrumbs>
                    </div>
                )

        default : 
            return (
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        {!isEmpty(props.path) && props.path.map((path) => (
                            <StyledLink key={path._id}
                            underline="none"
                            style={{color : props.activeId === path._id ? "rgba(68,68,68,255)" : ""}}
                            aria-current="page"
                            >
                            {path.name}
                            </StyledLink>
                        )) }
                    </Breadcrumbs>
                </div>
            )
    }
}

export default MiniBreadCrumbs
