import { REQUEST_SPECIFIC_OBJECTIVE, 
         SPECIFIC_OBJECTIVE_SUCCESS, 
         SPECIFIC_OBJECTIVE_FAILURE,
         CLEAR_SPECIFIC_OBJECTIVE } from './SpecificObjectiveTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const specificObjectiveReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_SPECIFIC_OBJECTIVE:
            return {
                ...state,
                loading : true,
                error : null
            }

        case SPECIFIC_OBJECTIVE_SUCCESS :
            return {
                ...state,
                loading : false,
                response : action.payload,
            }

        case SPECIFIC_OBJECTIVE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload,
            }

        case CLEAR_SPECIFIC_OBJECTIVE : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default : 
            return state
    }
}

export default specificObjectiveReducer