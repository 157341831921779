import { REQUEST_DELETE_SEGMENT,
    DELETE_SEGMENT_SUCCESS,
    DELETE_SEGMENT_FAILURE,
    CLEAR_DELETE_SEGMENT_STATES } from './DeleteSegment.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const deleteSegmentReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_DELETE_SEGMENT:
            return {
                ...state,
                loading : true,
                error : null
            }

        case DELETE_SEGMENT_SUCCESS: 
            return {
                ...state,
                loading : false,
                response : action.payload
            }
        
        case DELETE_SEGMENT_FAILURE:
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_DELETE_SEGMENT_STATES: 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default :
            return state
    }
}

export default deleteSegmentReducer