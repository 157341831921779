import { 
    REQUEST_MODIFY_OBJECTIVE,
    MODIFY_OBJECTIVE_SUCCESS, 
    MODIFY_OBJECTIVE_FAILURE,
    CLEAR_MODIFY_OBJECTIVE_STATES,
    ADD_OBJECTIVE_REFERENCE_FILE,
    ADD_OBJECTIVE_ICON_FILE,
    ADD_OBJECTIVE_WALLPAPER_FILE,
    REMOVE_OBJECTIVE_ICON_FILE,
    REMOVE_OBJECTIVE_WALLPAPER_FILE,
    REMOVE_OBJECTIVE_REFERENCE_FILE,
    REMOVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR,
    SAVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR,
    SAVE_MANY_MODIFY_OBJECTIVE_VALIDATION_ERROR
} from './ModifyObjective.types'

export const requestModifyObjective = () => {
    return {
        type : REQUEST_MODIFY_OBJECTIVE
    }
}

export const modifyObjectiveSuccess = (response) => {
    return {
        type : MODIFY_OBJECTIVE_SUCCESS,
        payload : response
    }
}

export const modifyObjectiveFailure = (error) => {
    return {
        type : MODIFY_OBJECTIVE_FAILURE,
        payload : error
    }
}

export const clearModifyObjectiveStates = () => {
    return {
        type : CLEAR_MODIFY_OBJECTIVE_STATES
    }
}

export const addObjectiveReferenceFile = (objectiveId, referenceFiles) =>{
    return {
        type : ADD_OBJECTIVE_REFERENCE_FILE,
        objectiveId,
        referenceFiles 
    }
}

export const removeObjectiveReferenceFile = (objectiveId) =>{
    return {
        type : REMOVE_OBJECTIVE_REFERENCE_FILE,
        objectiveId
    }
}

export const addObjectiveWallpaper = (objectiveId, newFile, data) =>{
    return {
        type : ADD_OBJECTIVE_WALLPAPER_FILE,
        objectiveId,
        newFile,
        data
    }
}

export const removeObjectiveWallpaper = (objectiveId) =>{
    return {
        type : REMOVE_OBJECTIVE_WALLPAPER_FILE,
        objectiveId
    }
}

export const addObjectiveIcon = (objectiveId, newFile, data) =>{
    return {
        type : ADD_OBJECTIVE_ICON_FILE,
        objectiveId,
        newFile,
        data
    }
}

export const removeObjectiveIcon = (objectiveId) =>{
    return {
        type : REMOVE_OBJECTIVE_ICON_FILE,
        objectiveId
    }
}

export const removeOneValidateObj = (id) => {
    return {
        type : REMOVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR,
        payload: id
    }
}

export const updateOneValidateObj = (payload) => {
    return {
        type : SAVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR,
        payload
    }
}

export const updateManyValidateObjs = (payload) => {
    return {
        type : SAVE_MANY_MODIFY_OBJECTIVE_VALIDATION_ERROR,
        payload
    }
}