import { requestBranches, saveBranches, fetchBranchesFailure } from '../redux/Branch/branches/Branches.action'
import { requestCUBranch, saveCUBranch, cuBranchFailure} from '../redux/Branch/CU-branch/CUBranch.action'
import ProtectedWebClient from '../utils/protected-web-client'

export const createBranch = (branchDetail) => {
    return async (dispatch) => {
        try{
            dispatch(requestCUBranch())
            const response = await ProtectedWebClient.post(`/branch/create`,branchDetail)
            dispatch(saveCUBranch(response.data))
        }catch(error){
            dispatch(cuBranchFailure(error.response))
        }
    }
}

export const updateBranch = (id,branchDetail) => {
    return async (dispatch) => {
        try{
            dispatch(requestCUBranch())
            const response = await ProtectedWebClient.put(`/branch/${id}/edit`,branchDetail)
            dispatch(saveCUBranch(response.data))
        }catch(error){
            dispatch(cuBranchFailure(error.response))
        }
    }
}

export const fetchBranches = () => {
    return async (dispatch) => {
        try{
            dispatch(requestBranches())
            const response = await ProtectedWebClient.get(`/branch/read`)
            dispatch(saveBranches(response.data))
        }catch(error){
            dispatch(fetchBranchesFailure(error.response))
        }
    }
}