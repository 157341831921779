import { REQUEST_COMPANY_VIEW, 
         COMPANY_VIEW_SUCCESS,
         COMPANY_VIEW_FAILURE, 
         CLEAR_COMPANY_VIEW_STATE} from './CompanyView.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const companyViewReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_COMPANY_VIEW :
            return {
                ...state,
                loading : true,
                error : null
            }

        case COMPANY_VIEW_SUCCESS : 
            return {
                ...state,
                loading: false,
                response : action.payload
            }

        case COMPANY_VIEW_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_COMPANY_VIEW_STATE : 
            return {
                loading : false,
                response : {},
                error : null
            }

        default : 
            return state
    }
}

export default companyViewReducer