import React,{ useState, useEffect } from 'react'
import {isEmpty} from 'lodash'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'

import './profile.css'

import { fetchProfileData } from '../../services/ProfileData.service'
import CorporateProfile from '../../components/profile/corporate-profile/CorporateProfile'
import UserProfile from '../../components/profile/user-profile/UserProfile'
import { getRole } from '../../utils/utils'
import Container from '../../components/desktop-layout/container/Container'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import SubMainContainer from '../../components/desktop-layout/sub-main-container/SubMainContainer'
import BottomNavigation from '../../components/bottom-navgation/BottomNavigation'
import Header from '../../common/header/Header'

const Profile = (props) => {

    const {    
        profileDataState, 
        fetchProfile, 
    } = props

    const [pageLoading,setPageLoading] = useState(true)
    const [ menu, setMenu ] = useState(false)
    const [ drawerOpen, setDrawerOpen ] = useState(false)

    const [editMode, setEditMode] = useState({
        inEdit : false,
        component : ''
    })

    const history = useHistory()

    const isDesktop = useMediaQuery('(min-width:992px)')

    let profileData = profileDataState.response.profile
    let highestQualification = profileDataState.response.highestQualifications
    let countries = profileDataState.response.countries

    const open = Boolean(menu);

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenu(null);
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    function handleEditModeChange(editMode, component){
        setEditMode((ps) => {
            if(editMode){
                return {
                    ...ps,
                    inEdit: true,
                    component: component
                }
            } else {
                return {
                    ...ps,
                    inEdit: false,
                    component: ''
                }
            }
        })
    }

    function handleBackArrowEvent(){
        if(editMode.inEdit){
            setEditMode(ps => {
                return {
                    ...ps,
                    inEdit: false,
                    component: ''
                }
            })
        }  else {
            history.goBack()
        }
    }

    useEffect(() =>{
        if(!profileDataState.loading && !isEmpty(profileDataState.response)){
            setPageLoading(false)
        }

        if(!profileDataState.loading && !isEmpty(profileDataState.error)){
            setPageLoading(false)
            if(profileDataState.error?.data){
                toast.error(profileDataState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[profileDataState])


    useEffect(() => {
        if(isEmpty(profileDataState.response) && !profileDataState.loading){
            fetchProfile()
        }
    }, [])

    return (
        <Container>
            {
                isDesktop ?
                <>
                    <Navbar
                        open={open}
                        menu={menu}
                        handlePopoverClose={handleMenuClose}
                        handlePopoverOpen={handleMenuOpen}
                        enableLogout="true"
                        enableMore="true"
                        enableProfileButton="true"
                        enableNotification={true}
                        notificationOnClick={onClickNotification}
                    />
                    <ProcessSectionContainer>
                        <LeftVerticalMenuBar />
                        { 
                            !pageLoading ?
                            <SubMainContainer className="mt-4 mb-40">
                                {/* <h4 className='lato-fs24-bold-lh32'>Profile</h4> */}
                                { 
                                    getRole() !== "individual" ? 
                                        <>
                                            <CorporateProfile 
                                                highestQualification={highestQualification} 
                                                countries={countries} 
                                                profileData={profileData} 
                                                editMode={editMode}
                                                handleEditModeChange={handleEditModeChange}
                                                />
                                        </>
                                        : null
                                }
                                <UserProfile 
                                    highestQualification={highestQualification}
                                    countries={countries} 
                                    profileData={profileData} 
                                    setPageLoading={setPageLoading}
                                    editMode={editMode}
                                    handleEditModeChange={handleEditModeChange}
                                />
                            </SubMainContainer>
                            : null
                        }
                    </ProcessSectionContainer>
                </>
                : <>
                    <Navbar
                        variant="mobile"
                        enableNotificationsIcon={true}
                        onNotificationsIconClick={onClickNotification}
                        enableLogout={true}
                        enableDrawer={true}
                        drawerOpened={drawerOpen}
                        handleDrawerClose={toggleDrawer}
                        onDrawerClick={toggleDrawer}
                    />
                        { 
                            !pageLoading ?
                            <>
                                <div className='container pt-3 pb-70'>
                                    <Header 
                                        handleBackArrowEvent={handleBackArrowEvent}
                                    />
                                    {
                                        getRole() !== "individual" ? 
                                            <>
                                                <CorporateProfile 
                                                    highestQualification={highestQualification} 
                                                    countries={countries} 
                                                    profileData={profileData} 
                                                    editMode={editMode}
                                                    handleEditModeChange={handleEditModeChange}
                                                    />
                                            </>
                                            : null
                                    }
                                    <UserProfile
                                        highestQualification={highestQualification}
                                        countries={countries} 
                                        profileData={profileData} 
                                        setPageLoading={setPageLoading}
                                        editMode={editMode}
                                        handleEditModeChange={handleEditModeChange}
                                    />
                                </div>
                            </> 
                            : null
                        }
                    <BottomNavigation />
                </>
            }
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        profileDataState : state.profileData 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfile : () => dispatch(fetchProfileData()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
