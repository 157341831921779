import { REQUEST_CREATE_OBJECTIVE, 
         CREATE_OBJECTIVE_SUCCESS,
         CREATE_OBJECTIVE_FAILURE,
         CLEAR_OBJECTIVE_STATE, 
         CLEAR_OBJECTIVE_ERROR,
         REQUEST_EDIT_OBJECTIVE,
         EDIT_OBJECTIVE_SUCCESS,
         EDIT_OBJECTIVE_FAILURE} from "./ObjectiveTypes";

export const requestCreateObjective = () => {
    return {
        type : REQUEST_CREATE_OBJECTIVE
    }
}

export const createObjectiveSuccess = (resData) => {
    return {
        type : CREATE_OBJECTIVE_SUCCESS,
        payload : resData
    }
}

export const createObjectiveFailure = (error) => {
    return {
        type : CREATE_OBJECTIVE_FAILURE,
        payload : error
    }
}

export const requestEditObjective = () => {
    return {
        type : REQUEST_EDIT_OBJECTIVE
    }
}

export const editObjectiveSuccess = (resData) => {
    return {
        type : EDIT_OBJECTIVE_SUCCESS,
        payload : resData
    }
}

export const editObjectiveFailure = (error) => {
    return {
        type : EDIT_OBJECTIVE_FAILURE,
        payload : error
    }
}

export const clearObjectives = () => {
    return {
        type : CLEAR_OBJECTIVE_STATE
    }
}

export const clearObjectiveError = () => {
    return {
        type : CLEAR_OBJECTIVE_ERROR
    }
}