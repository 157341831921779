import { requestFetchObjectives,
         successFetchObjectives,
         failureFetchObjectives 
        } from '../redux/fetch-objectives/FetchObjectivesAction'
import ProtectedWebClient from '../utils/protected-web-client'

export const fetchObjectives = () => {
    return async (dispatch) => {
        try {
            dispatch(requestFetchObjectives())
            const response = await ProtectedWebClient.get('/objective/list-by-lvl')
            dispatch(successFetchObjectives(response.data))
        } catch (error) {
            dispatch(failureFetchObjectives(error.response))
        }
    }
}

export const fetchSpecificObjectivePromise = (payload) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post('/objective/specific-read/by-path', payload)
                        .then(res => resolve(res.data))
                        .catch(err => reject(err))
    })
}

export const checkObjectiveDuplicationPromise = (payload) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post('/objective/name-duplication-check', payload)
                        .then(res => resolve(res.data))
                        .catch(err => reject(err))
    })
}