import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'

const StyledTabs = styled(Tabs)({
    borderRadius:"10px",
    '& .MuiTabs-indicator': {
      backgroundColor: '#ff806a',
    },
})

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color   : 'rgba(156,155,185,255)',
    fontFamily: '"Lato",sans-serif',
    margin:"0px",
    fontSize:"16px !important",
    fontWeight:"600",
    '&.Mui-selected': {
      color: 'white',
      fontWeight: "600",
      background: '#14213d',
      borderRadius: "10px",
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
}));

const MobileStyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color   : 'rgba(156,155,185,255)',
  fontFamily: '"Lato",sans-serif',
  margin:"0px",
  fontSize:"13px !important",
  fontWeight:"600",
  minHeight: "42px",
  padding: "10px",
  '&.Mui-selected': {
    color: 'white',
    fontWeight: "600",
    background: '#14213d',
    borderRadius: "10px",
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const MobileStyledTabs = styled(Tabs)({
  borderRadius:"10px",
  minHeight:"42px",
  '& .MuiTabs-indicator': {
    backgroundColor: 'var(--primary-color-v2)',
  },
})

const BgFilledTab = (props) => {

  switch(props.variant){
    
    case "desktop":
      return (
        <StyledTabs value={props.value} 
                onChange={props.onChange} 
                aria-label="basic tabs example" 
                variant='fullWidth'
                TabIndicatorProps={{style: {background:'transparent'}}}
                >
            { props.tabArray.map((tab,index) => (
                <StyledTab label={tab.label} value={tab.value} className={props.className} key={uuidv4()}/>
            ))}
        </StyledTabs>
      )

      default:
        return (
          <MobileStyledTabs value={props.value} 
                  onChange={props.onChange} 
                  aria-label="basic tabs example" 
                  variant='fullWidth'
                  TabIndicatorProps={{style: {background:'transparent'}}}
                  >
              { props.tabArray.map((tab,index) => (
                  <MobileStyledTab label={tab.label} value={tab.value} className={props.className} key={uuidv4()}/>
              ))}
          </MobileStyledTabs>
        )
  }
}

export default BgFilledTab