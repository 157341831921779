import React from 'react'
import PopUp from '../../common/popup/PopUp'
import './delete-popup.css'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const DeletePopUp = (props) => {

    return (
        <PopUp handleClose={props.handleClose} open={props.open}>
            <div className='p-3'>
                <h3 className="lato-fs18-normal-lh22 text-center">{props.msg}</h3>
                <div className="d-flex justify-content-center align-item-center mt-20">
                    <CustomizeButton label="Cancel" variant="secondary-color-button" className="me-4" handleClick={props.handleClose} />
                    <CustomizeButton label="Delete" variant="primary-color-button" handleClick={props.onClick}  />
                </div>
            </div>
        </PopUp>
    )
}

export default DeletePopUp
