import { REQUEST_FETCH_LEARNING, 
         FETCH_LEARNING_SUCCESS, 
         FETCH_LEARNING_FAILURE, 
         CLEAR_FETCH_LEARNING } from './FetchLearningTypes'

export const requestFetchLearning = () =>{
    return {
        type : REQUEST_FETCH_LEARNING
    }
} 

export const fetchLearningSuccess = (resData) => {
    return {
        type : FETCH_LEARNING_SUCCESS,
        payload : resData
    }
}

export const fetchLearningFailure = (error) => {
    return {
        type : FETCH_LEARNING_FAILURE,
        payload : error
    }
}

export const clearFetchLearning = () => {
    return {
        type : CLEAR_FETCH_LEARNING
    }
}