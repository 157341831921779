import ProtectedWebClient from "../utils/protected-web-client"
import { requestTeamMembers, fetchTeamMemberSuccess, fetchTeamMemberFailure } from "../redux/team-member/teamMember.action"
import { fetchReviewTeamFailure, fetchReviewTeamSuccess, requestReviewTeam } from "../redux/review-team/ReviewTeam.action"

export const addMemberByController = (memberId,selectedmemberId) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post(`/team/add-users?user=${memberId}`,selectedmemberId)
            .then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            }  
    )})
}

export const addMember = (selectedmemberId) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post(`/team/add-users`,selectedmemberId)
            .then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            }  
    )})
}

export const teamList = (wfmboolean,userId) => {
    return async (dispatch) => {
        try {
            dispatch(requestTeamMembers())
            const response = await ProtectedWebClient.get( userId ? `/team/list?user=${userId}&wfm=${wfmboolean}` : `/team/list?wfm=${wfmboolean}`)
            dispatch(fetchTeamMemberSuccess(response.data))
        }catch(error){
            dispatch(fetchTeamMemberFailure(error.response))
        }
    }
}

export const teamListPromise = (payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.get(payload ? `/team/list?user=${payload}&wfm=true` : `/team/list?wfm=true`)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const replaceTeamMember = (payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.put(`team/replace-user`,payload)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const removeTeamMember = (payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.put(`team/remove-user`,payload)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const requestReviewTeams = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(requestReviewTeam())
            const response = await ProtectedWebClient.get(`review/user-list`)
            dispatch(fetchReviewTeamSuccess(response.data))
        }catch(error){
            dispatch(fetchReviewTeamFailure(error.response))
        }
    }
}