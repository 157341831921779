import { REQUEST_CU_BRANCH, 
        CU_BRANCH_SUCCESS,
        CU_BRANCH_FAILURE,
        CLEAR_CUBRANCH_STATE } from './CUBranch.types'

export const requestCUBranch = () => {
    return {
        type : REQUEST_CU_BRANCH
    }
}

export const saveCUBranch = (response) => {
    return {
        type : CU_BRANCH_SUCCESS,
        payload : response
    }
}

export const cuBranchFailure = (error) => {
    return {
        type : CU_BRANCH_FAILURE,
        payload : error
    }
}

export const clearCUBranchState = () => {
    return {
        type : CLEAR_CUBRANCH_STATE
    }
}