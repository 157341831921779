import React,{  useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import InputBox from '../../common/Input-box/InputBox'
import TextAreaBox from '../../common/textarea-box/TextAreaBox'
import SelectBox from '../../common/select-box/SelectBox'
import PasswordInput from '../../common/password-input/PasswordInput'
import "./corporate-signup.css"
import { clearAuthStates } from '../../redux/authentication/AuthenticationAction'
import { corporateSignup, updateSubscription } from '../../services/IndividualAuthentication.service'
import { storeUserCredentials } from '../../utils/utils'
import SelectBoxValueById from '../../common/select-box-value-id/SelectBoxValueById'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const CorporateSignup = (props) => {

    const { requestSignUpState, ownProps, clearAuthenticationState, authenticateCorporate } = props

    const history = useHistory();

    const [ userSlab, setUserSlab ] = useState({})
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(ps => !ps)
    };

    const initialValues = {
        org_name: "",
        first_name : "",
        last_name : "",
        org_address : {
            door_no : "",
            area : "",
            city:"",
            state:"",
            pincode:"",
            country: "",
        },
        email: "",
        password: "",
        plan_id : "",
    }

    const validationSchema = Yup.object({
        org_name : Yup.string().required("Please enter your organization name"),
        first_name : Yup.string()
                        .required("Please enter your first name")
                        .matches(/^[A-Za-z ]*$/, "Invalid first name")
                        .min(2,"Too short")
                        .max(40, "Too Long"),
        last_name : Yup.string()
                        .required("Please enter your last name")
                        .matches(/^[A-Za-z ]*$/, "Invalid last name")
                        .min(2,"Too short")
                        .max(40, "Too Long"),
        email: Yup.string().required("Please enter your email").email("Invalid email"),
        password : Yup.string()
                    .required('Please enter your password')
                    .matches( /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'),  
        org_address : Yup.object().shape({
            door_no: Yup.string()
                .min(0,"Too short")
                .max(50,"Too long"),
            area: Yup.string()
                .max(350, 'Too long'),
            city: Yup.string().required("Please enter your city"),
            state: Yup.string().required("Please enter your State/Province/Region"),
            country: Yup.string().required("Please enter your Country"),
            pincode : Yup.string().required('Please enter your ZIP/Postal Code')
        }),
        plan_id: Yup.string().required("Please select a user count")
    })

    const onSubmit = (values) => {
        if(navigator.onLine){
            authenticateCorporate(values)
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit,
    })

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(payload) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            toast.error("Razorpay SDK failed to load. Are you online?", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const options = {
            key: payload.key,
            order_id: payload.order.id ,
            name: "HR App",
            amount: payload.order?.amount,
            currency: payload.order?.currency,
            // description: "Monthly Test Plan",
            // image: "/your_logo.png",
            // pyment response
            handler: function(res) {    
                updateSubscription(payload.order.id, res, payload.token)
                .then((res) => {
                    storeUserCredentials({
                        token : payload.token,
                        role :  payload.role,
                        userId : payload._id
                    })
                    history.push("/vcf?mode=list-vcfs-by-lvl&lvl=0")
                    clearAuthenticationState()
                })
                
            },
            prefill: {
                name: `${formik.values.first_name} ${formik.values.last_name}`,
                email: formik.values.email,
                contact: ""
            },
            // notes: {
            //     note_key_1: "Tea. Earl Grey. Hot",
            //     note_key_2: "Make it so."
            // },
            theme: {
                color: "#14213d"
            }
        }

        const paymentObject = new window.Razorpay(options);
        // payment error
        // paymentObject.on("payment.failed",function(error){
        //     console.log("error",error)
        // })
        paymentObject.open();
    }

    function getuserSlabArray(plan){
        let userSlabArray = []
        for( let slab of plan){
            userSlabArray.push({
                label : `${slab.min} to ${slab.max}`,
                value : slab._id
            })
        }
        return userSlabArray
    }

    useEffect(() => {
        if(!requestSignUpState.loading && !isEmpty(requestSignUpState.response)){
            // history.push(`/objective?mode=list-vcfs-by-lvl&lvl=0`)
            // clearAuthenticationState()
            displayRazorpay(requestSignUpState.response)
        }

        if(!requestSignUpState.loading && !isEmpty(requestSignUpState.error)){
            // console.log(requestSignUpState.error)
            toast.error(requestSignUpState.error?.data?.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }

    },[requestSignUpState])

    return (
        <>
            <form onSubmit={formik.handleSubmit} >
                <div className="row">
                   <div className="col-md-12">
                        <div className="mt-3 max-width-385px">
                            <InputBox type="text"
                                required={true}
                                name="org_name"
                                label="Organisation Name"
                                placeholder="Enter organisation name"
                                value={formik.values.org_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.org_name && formik.errors.org_name ? formik.errors.org_name : ""}
                            />
                        </div>
                   </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mt-3">
                            <InputBox type="text"
                                name="first_name"
                                label="First Name"
                                required={true}
                                placeholder="Enter first name"
                                value={formik.values.first_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.first_name ? formik.errors.first_name : ""}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-3">
                            <InputBox type="text"
                                name="last_name" 
                                label="Last Name"
                                required={true}
                                placeholder="Enter last name"
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.last_name ? formik.errors.last_name : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mt-3">
                            <InputBox type="email"
                                name="email"
                                label="Email Id"
                                required={true}
                                placeholder="Enter email id"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.email && formik.errors.email ? formik.errors.email : ""}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-3" style={{marginTop : "10px"}}>
                            <PasswordInput 
                                label={"Password *"}
                                name="password" 
                                placeholder="Enter your password"
                                showPassword={showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                                value={formik.values.password} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.password && formik.errors.password ? formik.errors.password : ""} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* <h2 className="fs20-lh32 mb-0 mt-2 col"> Organisation Address </h2> */}
                    <div className="col-md-12">
                        <div className="mt-3">
                            <InputBox type="text" 
                                name="org_address.door_no"
                                label="Organisation Address Line 1"
                                placeholder="Enter Apartment, suite, unit, building, floor, etc.,"
                                value={formik.values.org_address.door_no}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.org_address && formik.touched.org_address.door_no && formik.errors.org_address && formik.errors.org_address.door_no ? formik.errors.org_address.door_no : ""}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mt-3">
                            <TextAreaBox 
                                name="org_address.area"
                                label="Organisation Address Line 2"
                                placeholder="Enter Street address, P.O. box, company name, c/o"
                                value={formik.values.org_address.area}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errormgs={formik.touched.org_address && formik.touched.org_address.area && formik.errors.org_address && formik.errors.org_address.area ? formik.errors.org_address.area : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mt-3">
                        <InputBox type="text" 
                            name="org_address.city"
                            label="City"
                            placeholder="Enter city"
                            required={true}
                            value={formik.values.org_address.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMgs={formik.touched.org_address && formik.touched.org_address.city && formik.errors.org_address && formik.errors.org_address.city ? formik.errors.org_address.city : ""}
                        />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-3">
                        <InputBox name="org_address.state"
                                label="State/Province/Region"
                                placeholder="Please enter State/Province/Region"
                                required={true}
                                value={formik.values.org_address.state}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.org_address && formik.touched.org_address.state && formik.errors.org_address && formik.errors.org_address.state ? formik.errors.org_address.state : ""}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-3">
                        <InputBox type="text" 
                            label="ZIP/Postal Code"
                            name="org_address.pincode"
                            placeholder="Enter ZIP/Postal Code"
                            required={true}
                            value={formik.values.org_address.pincode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMgs={formik.touched.org_address && formik.touched.org_address.pincode && formik.errors.org_address && formik.errors.org_address.pincode ? formik.errors.org_address.pincode : ""}
                        />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-3">
                            <SelectBox options={ownProps.constantData.countries} name="org_address.country"
                                placeholder="Select your country"
                                label="Country"
                                required={true}
                                value={formik.values.org_address.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.org_address && formik.touched.org_address.country && formik.errors.org_address && formik.errors.org_address.country ? formik.errors.org_address.country : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mt-3">
                            <SelectBoxValueById 
                                name="plan_id"
                                label="User Count"
                                options={getuserSlabArray(ownProps.plans)}
                                onChange={(e,child) =>{
                                    setUserSlab(ownProps.plans[child.props.id])
                                    formik.handleChange(e)
                                }}
                                value={formik.values.plan_id}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.plan_id && formik.errors.plan_id ? formik.errors.plan_id : ""}
                            />
                        </div>
                    </div>
                </div>
                {
                    !isEmpty(userSlab) ?
                        <div className="card plan-card mt-3" style={{maxWidth: 'fit-content'}}>
                            <h3 className="lato-fs18-normal-lh22 primary-color mb-0 pb-1">{userSlab.plan_info?.item?.name}</h3>
                            <div className="pb-1">
                                <span className="lato-fs-20-normal-lh32 font-bold primary-color">{userSlab.plan_info?.item?.currency === "INR" ? "₹" : "$" }</span>
                                <span className="lato-fs-20-normal-lh32 font-bold primary-color">{(userSlab.plan_info?.item?.amount * userSlab.max)}</span>
                                <span className="lato-fs-20-normal-lh32 font-bold primary-color">{`/${userSlab.plan_info?.period}`}</span>
                            </div>
                            <div>
                                <span className="lato-fs17-normal-lh20 primary-color">{userSlab.plan_info?.item?.currency === "INR" ? "₹" : "$" }</span>
                                <span className="lato-fs17-normal-lh20 primary-color">{`${userSlab.plan_info?.item?.amount}`}</span>
                                <span className="lato-fs17-normal-lh20 primary-color">{`*${userSlab.max} licenses`}</span>
                            </div>
                            <p className="secondary-color lato-fs15-normal-lh20 mb-0">{userSlab.plan_info?.item?.description}</p>
                            {
                                userSlab.plan_info?.notes ?
                                <>
                                    <span className="lato-fs13-normal-lh18 font-bold">Note</span>
                                    {
                                        userSlab?.plan_info?.notes.map((note,index) => (
                                            <span className="lato-fs12-normal-lh15 primary-color" key={index}>{note}</span>
                                        ))
                                    }
                                </> 
                                : null
                            }
                        </div>
                        : ""
                }
                <div className="button-container">
                    <CustomizeButton variant="primary-color-button" type="submit" label="Proceed" />
                </div>
            </form>  
        </>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        requestSignUpState : state.individualAuthentication,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateCorporate : (signUpDetail) => dispatch(corporateSignup(signUpDetail)),
        clearAuthenticationState : () => dispatch(clearAuthStates())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CorporateSignup)