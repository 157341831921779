import React from 'react'
import wallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png'
import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import './learning-card.css'
import ErrorIcon from '@mui/icons-material/Error'

const LearningCard = (props) => {

    switch(props.variant){
        case "desktop-learning-card":
            return (
                <div className={props.selected ? "card flex-row modify-learning-card selected ml-20 mr-20 p-0 pt-cursor": "card flex-row modify-learning-card ml-20 mr-20 p-0 pt-cursor"} 
                    onClick={props.cardClickEvent} style={{backgroundImage:`url(${wallpaperPlaceholder})`, objectFit:"cover", objectPosition:"center"}}>
                    <div className='tree-overlay d-flex align-items-center' style={{padding:"10px",borderRadius:"4px"}}>
                        <img className="obj-icon me-2" src={props.icon ? props.icon : iconPlaceholder} alt="Icon"/>
                        <h5 className={props.selected ? "mb-0 mr-10 lato-fs20-normal-lh28 white-color font-bold" : "mb-0 mr-10 lato-fs18-normal-lh22 white-color"}>{ props.name }</h5>
                        {props.error ? 
                            <ErrorIcon sx={{ color: 'red', marginLeft: '10px' }} fontSize="medium" /> : null
                        }
                    </div>
                </div> 
            )

        case "mobile-learning-card":
            return (
                <div className={props.selected ? "card flex-row mobile-modify-learning-card selected ml-20 mr-20 p-0 pt-cursor": "card flex-row mobile-modify-learning-card ml-20 mr-20 p-0 pt-cursor"} 
                    onClick={props.cardClickEvent} style={{backgroundImage:`url(${wallpaperPlaceholder})`, objectFit:"cover", objectPosition:"center"}}>
                    <div className='tree-overlay d-flex align-items-center' style={{padding:"10px",borderRadius:"4px"}}>
                        <img className="obj-icon me-2" src={props.icon ? props.icon : iconPlaceholder} alt="Icon"/>
                        <h5 className={props.selected ? "mb-0 mr-10 lato-fs18-normal-lh22 white-color font-bold" : "mb-0 white-color lato-fs16-normal-lh20"}>{ props.name }</h5>
                        {props.error ?
                            <ErrorIcon sx={{ color: 'red', marginLeft: '10px' }} fontSize="medium" />: null 
                        }
                    </div>
                </div> 
            )

        case "mobile-course-card":
            return (
                <div className={props.selected ? "card mobile-course-card mt-3 selected pt-cursor": "card mobile-course-card mt-3 pt-cursor"} 
                    onClick={props.cardClickEvent} style={{backgroundImage:`url(${wallpaperPlaceholder})`, objectFit:"cover", objectPosition:"center"}}>
                    <div className='tree-overlay' style={{padding:"10px",borderRadius:"4px"}}>
                        <div className='d-flex align-items-center'>
                            <h5 className={props.selected ? "mb-0 lato-fs18-normal-lh22 white-color one-line-wrap font-bold" : "mb-0 lato-fs16-normal-lh20 one-line-wrap white-color"}>{props.name}</h5>
                            {props.error ?
                                <div>
                                    <ErrorIcon sx={{ color: 'red', marginLeft: '10px' }} fontSize="medium" />
                                </div> : null 
                            }
                        </div>
                    </div>
                </div>
            )

        default : 
            return (
                <div className={props.selected ? "card modify-learning-course-card mt-3 selected pt-cursor": "card modify-learning-course-card mt-3 pt-cursor"} 
                    onClick={props.cardClickEvent} style={{backgroundImage:`url(${wallpaperPlaceholder})`, objectFit:"cover", objectPosition:"center"}}>
                    <div className='tree-overlay' style={{padding:"10px",borderRadius:"4px"}}>
                        <div className='d-flex align-items-center'>
                            <h5 className={props.selected ? "mb-0 lato-fs20-normal-lh28 white-color font-bold one-line-wrap" : "mb-0 lato-fs18-normal-lh22 white-color font-bold one-line-wrap"}>{props.name}</h5>
                            {props.error ?
                                <div>
                                    <ErrorIcon sx={{ color: 'red', marginLeft: '10px' }} fontSize="medium" />
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            )
    }
}

export default LearningCard