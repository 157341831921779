import { REQUEST_INBOX_ACTION_FETCH, 
         FETCH_INBOX_ACTION_SUCCESS,
         FETCH_INBOX_ACTION_FAILURE, 
         CLEAR_FETCH_INBOX_ACTION_STATES } from './inbox.types'

export const requestFetchInboxAction = () => {
    return {
        type : REQUEST_INBOX_ACTION_FETCH
    }
}

export const fetchInboxActionSuccess = (response) => {
    return {
        type : FETCH_INBOX_ACTION_SUCCESS,
        payload : response
    }
}

export const fetchInboxActionFailure = (error) => {
    return {
        type : FETCH_INBOX_ACTION_FAILURE,
        payload : error
    }
}

export const clearFetchInboxAction = () => {
    return {
        type : CLEAR_FETCH_INBOX_ACTION_STATES
    }
}