import React,{ useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Select from 'react-select'

import { clearCoursesState } from '../../redux/video-conference/course/Course.action'
import { requestCoursesForSelect } from '../../services/videoConference.service'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const CourseSelect = (props) => {

    const { courseState, 
            requestCourse,
            clearCourseState,
            selectedValue, 
            setSelectedValue,
            ownProps } = props

    const learningAreas = courseState.response.learning_areas

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ selectedCourse, setSelectedCourse ] = useState([])

    useEffect(() => {
        if(isEmpty(courseState.response)){
            requestCourse()
        }
        setSelectedCourse(selectedValue)
        return () => {
            clearCourseState()
        }
    },[])

    useEffect(() => {
        if(!courseState.loading && !isEmpty(courseState.response)){
            // console.log(courseState.response)
            setPageLoading(false)
        }

        if(!courseState.loading && !isEmpty(courseState.error)){
            console.log(courseState.error)
            setPageLoading(false)
        }
    },[courseState])

    function formattedCourseOptions(){
        let options = []
        for(const learningArea of learningAreas){
            let courses = []
            for(const course of learningArea.courses){
                courses.push({label: course.name, value: course._id})
            }
            options.push({label: learningArea.name, options: courses})
        }
        return options
    }

    function handleInputChange(value){
        setSelectedCourse(value)
    }

    function handleProceedEvent(){
        setSelectedValue(selectedCourse)
        ownProps.onCancel()
    }

    return (
        !pageLoading ?
            <>
                <Select 
                    closeMenuOnSelect={false}
                    isMulti
                    menuPortalTarget={document.body}
                    styles={{ 
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        control: (styles ,{ isFocused }) => ({
                                ...styles,
                                ':hover': { borderColor: '#14213d' }, // border style on hover
                                border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                boxShadow: isFocused ? '' : "", // no box-shadow
                            }),
                        option : (styles) => ({
                            ...styles,
                            fontSize : "15px",
                            fontFamily :"'Lato',sans-serif"
                        })
                    }}
                    options={formattedCourseOptions()}
                    onChange={handleInputChange}
                    value = {selectedCourse}
                />
                <div className="mt-3 d-flex justify-content-center">
                    <CustomizeButton variant="primary-color-button" handleClick={handleProceedEvent} label="Proceed"/>
                </div>
            </> : null
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        courseState : state.courseForSelect,
        ownProps : ownProps,
        setSelectedValue : ownProps.setSelectedValues,
        selectedValue : ownProps.selectedValue,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestCourse : () => dispatch(requestCoursesForSelect()),
        clearCourseState : () => dispatch(clearCoursesState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CourseSelect)