import React,{ useState, useEffect } from 'react'
import qs from 'query-string'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'

import MemberDetail from '../member/member-detail/MemberDetail'
import { getRole } from '../../utils/utils'
import { fetchMemberDetail } from '../../services/user.service'
import { clearMemberDetailState } from '../../redux/user/member-detail/membersDetail.action';
import Container from '../../components/desktop-layout/container/Container'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../components/desktop-layout/sub-main-container/SubMainContainer'
import BottomNavigation from '../../components/bottom-navgation/BottomNavigation'

const SingleUserDetail = (props) => {

    const { memberDetailState, requestMemberDetail, clearMemberDetailState } = props
    
    const memberMapping = memberDetailState.response.user_subscriber_mapping
    const member = memberDetailState.response

    const history = useHistory()
    const params= useParams()
    const location = useLocation()
    const queryParams = qs.parse(location.search)

    const isDesktop = useMediaQuery('(min-width:992px)')

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ menu, setMenu ] = useState(false)
    const [ drawerOpen, setDrawerOpen ] = useState(false)

    const open = Boolean(menu);

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenu(null);
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    function handleTabEvent(key){
        history.push(`/organisation/member/${params.memberId}?tab=${key}`)
    }

    useEffect(() => {
        requestMemberDetail(params.memberId)
        // return () =>{
        //     clearMemberDetailState()
        // }
    },[])

    useEffect(() => {
        if(!memberDetailState.loading && !isEmpty(memberDetailState.response)){
            setPageLoading(false)
        }

        if(!memberDetailState.loading && !isEmpty(memberDetailState.error)){
            console.log(memberDetailState.error)
            setPageLoading(false)
            if(memberDetailState.error.data){
                toast.error(memberDetailState.error?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[memberDetailState])

    function showTabs(){
        let showSettingTab = false
        if(getRole() == "user_cum_controller"){
            if( !isEmpty(memberMapping)){
                if(!isEmpty(memberMapping[0].role)){
                    if(memberMapping[0].role == "user_cum_controller"){
                        return showSettingTab = false
                    }else{
                        return showSettingTab = true
                    }
                }
            }
        }
    }

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    return (
        <Container>
            {
                isDesktop ?
                    <>
                        <Navbar 
                            open={open}
                            menu={menu}
                            handlePopoverClose={handleMenuClose}
                            handlePopoverOpen={handleMenuOpen}
                            enableProfileButton={true} 
                            enableMore={true} 
                            enableLogout={true}
                            enableNotification={true}
                            notificationOnClick={onClickNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer variant="noBackground" >
                                <SubMainContainer>
                                    {
                                        !pageLoading && !isEmpty(member) ?
                                            <MemberDetail />
                                            : null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>

                    </>
                    : <>
                        <Navbar 
                            variant="mobile"
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={onClickNotification}
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawerOpen}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                            {
                                !pageLoading && !isEmpty(member) ?
                                    <MemberDetail />
                                    : null
                            }
                        <BottomNavigation />
                    </>
            }
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        memberDetailState : state.memberDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestMemberDetail : (userId) => dispatch(fetchMemberDetail(userId)),
        clearMemberDetailState : () => dispatch(clearMemberDetailState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SingleUserDetail)

// !pageLoading && !isEmpty(member) ?
// <>
//     <div>
//         {/* { showTabs() ? */}
//             {/* <Tabs fill activeKey={queryParams.tab ? queryParams.tab : "details"} id="uncontrolled-tab-example" className="mt-3" 
//                 onSelect={(key) => handleTabEvent(key)} style={{borderBottom:"none"}}>
//                 <Tab eventKey="details" title="Details" className="color-black" tabClassName="border-tab">
//                     <div className="container-bg pt-1 ps-2 pe-2 border-rl tab-height">
//                     <MemberDetail/>
//                     </div>
//                 </Tab>
//                 <Tab eventKey="settings" title="Settings" className="color-black" tabClassName="border-tab">
//                     <Setting />
//                 </Tab>
//             </Tabs> :  */}
//             <MemberDetail/>
//         {/* } */}
//     </div>
// </> : null