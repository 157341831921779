import { REQUEST_DELETE_COURSE, 
         DELETE_COURSE_SUCCESS, 
         DELETE_COURSE_FAILURE, 
         CLEAR_DELETE_COURSE } from './DeleteCourseTypes'

export const requestDeleteCourse = () => {
    return {
        type : REQUEST_DELETE_COURSE
    }
}

export const deleteCourseSuccess = (resData) => {
    return {
        type : DELETE_COURSE_SUCCESS,
        payload : resData
    }
}

export const deleteCourseFailure  = (error) => {
    return {
        type : DELETE_COURSE_FAILURE,
        payload : error
    }
}

export const clearDeleteCourse = () => {
    return {
        type : CLEAR_DELETE_COURSE
    }
}