import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

import { fetchSubscription } from '../../services/IndividualAuthentication.service'
import './subscription.css'
import { CONTROLLER_ROLE, INDIVIDUAL_ROLE } from '../../utils/constants'
import { clearSubscriptionState } from '../../redux/subscription/Subscription.action'
import Container from '../../components/desktop-layout/container/Container'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../components/desktop-layout/sub-main-container/SubMainContainer'
import Header from '../../common/header/Header'
import BottomNavigation from '../../components/bottom-navgation/BottomNavigation'

const Subscription = (props) => {

    const { requestSubscription, subscriptionState, clearSubscriptionState } = props

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ drawer, setDrawer ] = useState(false)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const history = useHistory()

    const subscriptionDetail = subscriptionState.response

    const [ menu, setMenu ] = useState(false)

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    useEffect(() => {
        if(isEmpty(subscriptionState.response)){
            requestSubscription()
        }
        return () => {
            clearSubscriptionState()
        }
    },[])

    useEffect(() => {
        if(!subscriptionState.loading && !isEmpty(subscriptionState.response)){
            setPageLoading(false)
        }

        if(!subscriptionState.loading && !isEmpty(subscriptionState.error)){
            console.log(subscriptionState.error)
            setPageLoading(false)
            if(!isEmpty(subscriptionState.error.data)){
                toast.error(subscriptionDetail.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[subscriptionState])

    function handleOnNotificationsIconClick(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    function getSubscriptionDetail(){
        return (
            <>
                {!pageLoading && !isEmpty(subscriptionDetail) ?
                    <>
                        { !isEmpty(subscriptionDetail.latest_subscription) && subscriptionDetail.role === CONTROLLER_ROLE ?
                            <div className='mt-3'>
                                <h2 className='lato-fs18-normal-lh22 primary-text-color mb-0 pb-3'>Current Plan</h2>
                                <Card sx={{maxWidth: "fit-content"}}>
                                    <CardContent>
                                        <h4 className="mb-0 fs18-lh20 pb-1 primary-color">
                                            {subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.name}</h4>
                                        <div>
                                            <span className="lato-fs-20-normal-lh32 primary-text-color">{subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.currency === "INR" ? "₹" : "$"}</span>
                                            <span className="lato-fs-20-normal-lh32 primary-text-color">{subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.amount * subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.max}</span>
                                            <span className="lato-fs-20-normal-lh32 primary-text-color">{`/${subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.period}`}</span>
                                        </div>
                                        <div>
                                            <span className="lato-fs17-normal-lh20 primary-text-color">{subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.currency === "INR" ? "₹" : "$"}</span>
                                            <span className="lato-fs17-normal-lh20 primary-text-color">{`${subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.amount}`}</span>
                                            <span className="lato-fs17-normal-lh20 primary-text-color">{`*${subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.max} licences`}</span>
                                        </div>
                                        <div className='mt-3'>
                                            <p className="mb-1 tertiary-text-color-v2 lato-fs16-normal-lh20">Subscription valid till<span className='ms-1 font-bold primary-text-color'>{moment.unix(subscriptionDetail.latest_subscription?.end_at).format("Do MMM, YYYY")}</span></p>
                                            <p className="mb-0 tertiary-text-color-v2 lato-fs16-normal-lh20">License Remaining:<span className='ms-1 primary-text-color'>{subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.max - subscriptionDetail.active_users}</span></p>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div> : null
                        }
                        { !isEmpty(subscriptionDetail.latest_subscription) && subscriptionDetail.role == INDIVIDUAL_ROLE ?
                            <div className='mt-3'>
                                <h2 className='lato-fs18-normal-lh22 primary-text-color mb-0 pb-3'>Current Plan</h2>
                                <Card sx={{maxWidth:"fit-content"}}>
                                    <CardContent>
                                        <h4 className="mb-0 fs18-lh20 font-bold pb-1 primary-color">
                                            {subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.name}</h4>
                                        <div>
                                            <span className="lato-fs-20-normal-lh32 primary-text-color">{subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.currency === "INR" ? "₹" : "$"}</span>
                                            <span className="lato-fs-20-normal-lh32 primary-text-color">{subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.item?.amount}</span>
                                            <span className="lato-fs-20-normal-lh32 primary-text-color">{`/${subscriptionDetail.latest_subscription?.orders[0]?.order?.plan?.plan_info?.period}`}</span>
                                        </div>
                                        <div className='mt-3'>
                                            <p className="mb-1 tertiary-text-color-v2 lato-fs16-normal-lh20">Subscription valid till<span className='ms-1 font-bold primary-text-color'>{moment.unix(subscriptionDetail.latest_subscription?.end_at).format("Do MMM, YYYY")}</span></p>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div> : null 
                        }
                        {/* <div className="d-flex justify-content-center mt-3">
                            <CustomizeLoadingButton variant="accept-loading-button-v2" label="Upgrade Plan" />
                        </div> */}
                    </> : null
                }
            </>
        )
    }

    return (
        <Container>
            {isDesktop ?
                <>
                    <Navbar 
                        open={open}
                        menu={menu}
                        handlePopoverClose={handleMenuClose}
                        handlePopoverOpen={handleMenuOpen} 
                        enableMore={true} 
                        moreRight="188px"
                        enableLogout={true} 
                        enableProfileButton={true}
                        enableNotification={true}
                        notificationOnClick={handleOnNotificationsIconClick}
                    />
                    <ProcessSectionContainer>
                        <LeftVerticalMenuBar />
                        <ProcessMainContainer className="bg-tertiary-color-v2">
                            <SubMainContainer>
                                {getSubscriptionDetail()}
                            </SubMainContainer>
                        </ProcessMainContainer>
                    </ProcessSectionContainer>
                </> :
                <>
                    <Navbar 
                        variant="mobile"
                        enableLogout={true}
                        enableDrawer={true}
                        drawerOpened={drawer}
                        handleDrawerClose={toggleDrawer}
                        onDrawerClick={toggleDrawer}
                        enableNotificationsIcon={true}
                        onNotificationsIconClick={handleOnNotificationsIconClick}
                    />
                    <div className='flex-1 bg-tertiary-color-v2'>
                        <div className="container">
                            <Box sx={{marginLeft: {
                                xs: 0,
                                sm: "10%"
                            },paddingLeft:{
                                xs: "16px",
                                sm: 0
                            },paddingRight: {
                                xs: "16px",
                                sm: 0
                            }}}>
                                <div className='mt-3'>
                                    <Header heading="Subscription" handleBackArrowEvent={handleBackArrowEvent} />
                                </div>
                                <div>
                                    {getSubscriptionDetail()}
                                </div>
                            </Box>
                        </div>
                    </div>
                    <BottomNavigation />
                </>
            }
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        subscriptionState : state.subscription
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestSubscription : () => dispatch(fetchSubscription()),
        clearSubscriptionState : () => dispatch(clearSubscriptionState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Subscription)