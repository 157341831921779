import { REQUEST_NOTIFICATION_DETAIL, 
         FETCH_MAIL_DETAIL_SUCCESS,
         FETCH_MAIL_DETAIL_FAILURE, 
         CLEAR_MAIL_DETAIL_STATE,
         MAIL_ACTION_CHANGES } from './notificationDetail.types'

export const requestNotificationDetail = () => {
    return {
        type : REQUEST_NOTIFICATION_DETAIL
    }
}

export const mailActionChanges = () => {
    return {
        type : MAIL_ACTION_CHANGES
    }
}

export const fetchNotificationDetailSuccess = (response) => {
    return {
        type : FETCH_MAIL_DETAIL_SUCCESS,
        payload : response
    }
}

export const fetchNotificationDetailFailure = (error) => {
    return {
        type : FETCH_MAIL_DETAIL_FAILURE,
        payload : error
    }
}

export const clearNotificationDetailState = () => {
    return {
        type : CLEAR_MAIL_DETAIL_STATE
    }
}