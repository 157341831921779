import { REQUEST_METRICS_FOR_SELECT, 
        METRICS_FOR_SELECT_SUCCESS,
        METRICS_FOR_SELECT_FAILURE,
        CLEAR_METRICS_FOR_SELECT_STATE } from './Metric.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const metricsForSelectReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_METRICS_FOR_SELECT :
            return {
                ...state,
                loading : true,
                error :null
            }

        case METRICS_FOR_SELECT_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case METRICS_FOR_SELECT_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_METRICS_FOR_SELECT_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default metricsForSelectReducer