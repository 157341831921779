import { REQUEST_FETCH_OBJECTIVES,
        FETCH_OBJECTIVES_SUCCESS,
        FETCH_OBJECTIVES_FAILURE, 
        CLEAR_FETCH_OBJECTIVES,
        OBJECTIVE_ACTION_CHANGES} from './FetchObjectivesTypes'

export const requestFetchObjectives = () => {
    return{
        type : REQUEST_FETCH_OBJECTIVES,
    }
}

export const successFetchObjectives = (resData) => {
    return {
        type : FETCH_OBJECTIVES_SUCCESS,
        payload: resData
    }
}

export const failureFetchObjectives = (error) => {
    return {
        type : FETCH_OBJECTIVES_FAILURE,
        payload: error
    }
}

export const objectiveActionChanges = () => {
    return {
        type : OBJECTIVE_ACTION_CHANGES
    }
}

export const clearFetchObjectives = () => {
    return {
        type : CLEAR_FETCH_OBJECTIVES
    }
}