import React,{ useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import BottomNavigation from '../../components/bottom-navgation/BottomNavigation'
import useMediaQuery from '@mui/material/useMediaQuery'
import qs from 'query-string'

import CreateDepartmentPopup from '../../components/create-department-popup/CreateDepartmentPopup'
import './department.css'
import { fetchDepartments, updateDepartment } from '../../services/departments.service'
import { saveDepartments } from '../../redux/department/Department.action'
import Container from '../../components/desktop-layout/container/Container'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import SubMainContainer from '../../components/desktop-layout/sub-main-container/SubMainContainer'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import ProcessMainContainer from '../../components/desktop-layout/process-main-container/ProcessMainContainer'
import Box from '@mui/material/Box'
import SimpleTab from '../../components/tabs/simple-tab/simple-tab/SimpleTab'
import SimpleTabPanel from '../../components/tabs/simple-tab/Simple-tab-panel/SimpleTabPanel'

const Department = (props) => {

    const { departmentsState, requestDepartments, updateDepartment, CUDepartmentState, saveDepartments } = props

    const [ createDepartmentPopup, setCreateDepartmentPopup ] = useState(false)
    const [ departmentNameIndex, setDepartmentNameIndex ] = useState(0)
    const [ editDepartmentName, setEditDepartmentName ] = useState(false)
    const [ pageLoading, setPageLoading ] = useState(true)

    const [ drawerOpen, setDrawerOpen ] = useState(false)

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const location = useLocation()
    const queryParams = qs.parse(location.search)

    const [ menu, setMenu ] = useState(false)

    const open = Boolean(menu);

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenu(null);
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    const activeDepartments = departmentsState.response.active_departments
    const inactiveDepartments = departmentsState.response.inactive_departments

    function createDepartment(){
        setCreateDepartmentPopup(true)
    }

    function handlePopupClose(){
        setCreateDepartmentPopup(false)
    }

    function handleEditDepartmentName(e,index){
        setEditDepartmentName(true)
        setDepartmentNameIndex(index)
    }

    function handleEditPopupClose(){
        setEditDepartmentName(false)
    }

    function handleDepartmentStatus(id,status){
        updateDepartment(id,{ status : status})
        setPageLoading(true)
    }

    function handleBackArrow(){
        history.goBack()
    }

    useEffect(() => {
        if(isEmpty(departmentsState.response)){
            requestDepartments()
        }
        if(!['active-departments', 'inactive-departments'].includes(queryParams.selected)){
            history.push(`/department?selected=active-departments`)
        }
    },[])

    useEffect(() => {
        if(!departmentsState.loading && !isEmpty(departmentsState.response)){
            // console.log(departmentsState.response)
            setPageLoading(false)
        }

        if(!departmentsState.loading && !isEmpty(departmentsState.error)){
            console.log(departmentsState.error)
            setPageLoading(false)
            if(departmentsState.error?.data){
                toast.error(departmentsState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[departmentsState])

    useEffect(() => {
        if(!CUDepartmentState.loading && !isEmpty(CUDepartmentState.response)){
            // console.log("cude",CUDepartmentState.response)
            saveDepartments(CUDepartmentState.response)
            setPageLoading(false)
        }

        if(!CUDepartmentState.loading && !isEmpty(CUDepartmentState.error)){
            setPageLoading(false)
            console.log(CUDepartmentState.error)
            if(CUDepartmentState.error?.data){
                toast.error(CUDepartmentState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[CUDepartmentState])

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    const handleChange = (event, newValue) => {
        if(newValue){
            history.push(`/department?selected=${newValue}`)
        }
    }

    return (
        <>
            <Container>
                {
                    isDesktop ?
                        <>
                            <Navbar
                                open={open}
                                menu={menu}
                                handlePopoverClose={handleMenuClose}
                                handlePopoverOpen={handleMenuOpen} 
                                enableMore={true} 
                                enableLogout={true} 
                                enableProfileButton={true}
                                enableNotification={true}
                                notificationOnClick={onClickNotification}
                            />
                            <ProcessSectionContainer>
                                <LeftVerticalMenuBar 
                                    addIcon={true} 
                                    addIconTooltip="Create Department"
                                    onAddIconClick={createDepartment}
                                />
                                <ProcessMainContainer
                                    variant='noBackground'
                                >
                                    {
                                        !pageLoading ?
                                            <>
                                                <Box sx={{paddingLeft: '5%', paddingRight: '5%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}} >
                                                    <SimpleTab
                                                        value={queryParams.selected ? queryParams.selected : "members"} 
                                                        onChange={handleChange}
                                                        tabArray={[ 
                                                            {label:"Departments", value:"active-departments"},
                                                            {label:"Inactive Departments", value:"inactive-departments"}
                                                        ]}
                                                        variant="desktop"
                                                    />
                                                </Box>
                                                <SimpleTabPanel 
                                                    value="active-departments"
                                                    selectedTab={queryParams.selected}
                                                >
                                                    <div 
                                                        style={{
                                                            height: 'calc(100vh - 113px)',
                                                            backgroundColor: 'var(--tertiary-color-v2)',
                                                            overflowY: 'auto'
                                                        }}
                                                        className='d-flex'
                                                    >
                                                        <SubMainContainer>
                                                            {
                                                                !isEmpty(activeDepartments) ?
                                                                    <div className="py-4">
                                                                        {/* <h2 className="lato-fs22-normal-lh32 font-bold primary-text-color">Active Departments</h2> */}
                                                                        <div>
                                                                            {
                                                                                activeDepartments.map((department, index) => (
                                                                                    <div className='d-flex align-items-center mb-3'>
                                                                                        <label className="lato-fs18-normal-lh25 flex-fill mb-0" key={index}>
                                                                                            {department.name}
                                                                                        </label>
                                                                                        <div>
                                                                                            <CustomizeButton 
                                                                                                className="m-1"
                                                                                                variant="primary-color-button" 
                                                                                                handleClick={(e) => handleEditDepartmentName(e,index)}
                                                                                                label="Edit"
                                                                                            />
                                                                                            <CustomizeButton
                                                                                                className="m-1"
                                                                                                variant="red-color-button"
                                                                                                handleClick={() => handleDepartmentStatus(department._id, "inactive")}
                                                                                                label="Deactivate"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : <div className='py-4'>
                                                                        <p className="lato-fs17-normal-lh20 mb-0">You don't have any departments yet</p>
                                                                    </div>
                                                            }
                                                        </SubMainContainer>
                                                    </div>
                                                </SimpleTabPanel>
                                                <SimpleTabPanel 
                                                    value="inactive-departments"
                                                    selectedTab={queryParams.selected}
                                                >
                                                    <div 
                                                        style={{
                                                            height: 'calc(100vh - 113px)',
                                                            backgroundColor: 'var(--tertiary-color-v2)',
                                                            overflowY: 'auto'
                                                        }}
                                                        className='d-flex'
                                                    >
                                                        <SubMainContainer>
                                                            {
                                                                !isEmpty(inactiveDepartments) ?
                                                                    <div className="py-4">
                                                                        {/* <h2 className="lato-fs22-normal-lh32 font-bold primary-text-color">Inactive Departments</h2> */}
                                                                        <div>
                                                                            {
                                                                                inactiveDepartments.map((department, index) => (
                                                                                    <div className='d-flex mb-3 align-items-center'>
                                                                                        <label className="mb-0 lato-fs18-normal-lh25 flex-fill" key={index}>
                                                                                            {department.name}
                                                                                        </label>
                                                                                        <div>
                                                                                            <CustomizeButton
                                                                                                variant="green-color-button"
                                                                                                handleClick={() => handleDepartmentStatus(department._id, "active")}
                                                                                                label="Activate"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : <div className='py-4'>
                                                                        <p className="lato-fs17-normal-lh20 mb-0">You don't have any inactive departments</p>
                                                                    </div>
                                                            }
                                                        </SubMainContainer>
                                                    </div>
                                                </SimpleTabPanel>
                                            </> 
                                            : null  
                                    }
                                </ProcessMainContainer>
                            </ProcessSectionContainer>
                        </>
                        : <>
                            <Navbar 
                                variant="mobile"
                                enableNotificationsIcon={true}
                                onNotificationsIconClick={onClickNotification}
                                enableLogout={true}
                                enableDrawer={true}
                                drawerOpened={drawerOpen}
                                handleDrawerClose={toggleDrawer}
                                onDrawerClick={toggleDrawer}
                            />
                            {
                                !pageLoading ?
                                    <div className='content-area-height'>
                                        <Box sx={{paddingLeft: '5%', paddingRight: '5%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}} >
                                            <SimpleTab
                                                value={queryParams.selected ? queryParams.selected : "members"} 
                                                onChange={handleChange}
                                                tabArray={[ 
                                                    {label:"Departments", value:"active-departments"},
                                                    {label:"Inactive Departments", value:"inactive-departments"}
                                                ]}
                                                variant="mobile"
                                                tabVariant="fullWidth"
                                            />
                                        </Box>
                                        <hr style={{margin: 0, borderTop: '1px solid rgba(0, 0, 0, 0.1)'}}/>
                                        <div className="content-area-height-members-for-users bg-tertiary-color-v2 overflow-auto" >
                                            <SimpleTabPanel 
                                                value="active-departments"
                                                selectedTab={queryParams.selected}
                                            >
                                                <SubMainContainer className="container pb-70 px-3">
                                                    {
                                                        !isEmpty(activeDepartments) ?
                                                            <div className="py-4">
                                                                {/* <h2 className="lato-fs22-normal-lh32 font-bold primary-text-color">Active Departments</h2> */}
                                                                <div>
                                                                    {
                                                                        activeDepartments.map((department, index) => (
                                                                            <div className='d-flex align-items-center mb-3'>
                                                                                <label className="lato-fs18-normal-lh25 flex-fill mb-0" key={index}>
                                                                                    {department.name}
                                                                                </label>
                                                                                <div>
                                                                                    <CustomizeButton 
                                                                                        className="m-1"
                                                                                        variant="primary-color-button" 
                                                                                        handleClick={(e) => handleEditDepartmentName(e,index)}
                                                                                        label="Edit"
                                                                                    />
                                                                                    <CustomizeButton
                                                                                        className="m-1"
                                                                                        variant="red-color-button"
                                                                                        handleClick={() => handleDepartmentStatus(department._id, "inactive")}
                                                                                        label="Deactivate"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                            : <div className='py-4'>
                                                                <p className="lato-fs17-normal-lh20 mb-0">You don't have any departments yet</p>
                                                            </div>
                                                    }
                                                </SubMainContainer>
                                            </SimpleTabPanel>
                                            <SimpleTabPanel 
                                                value="inactive-departments"
                                                selectedTab={queryParams.selected}
                                            >
                                                <SubMainContainer className="container pb-70 px-3">
                                                    {
                                                        !isEmpty(inactiveDepartments) ?
                                                            <div className="py-4">
                                                                {/* <h2 className="lato-fs22-normal-lh32 font-bold primary-text-color">Inactive Departments</h2> */}
                                                                <div>
                                                                    {
                                                                        inactiveDepartments.map((department, index) => (
                                                                            <div className='d-flex mb-3 align-items-center'>
                                                                                <label className="mb-0 lato-fs18-normal-lh25 flex-fill" key={index}>
                                                                                    {department.name}
                                                                                </label>
                                                                                <div>
                                                                                    <CustomizeButton
                                                                                        variant="green-color-button"
                                                                                        handleClick={() => handleDepartmentStatus(department._id, "active")}
                                                                                        label="Activate"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                            : <div className='py-4'>
                                                                <p className="lato-fs17-normal-lh20 mb-0">You don't have any inactive departments</p>
                                                            </div>
                                                    }
                                                </SubMainContainer>
                                            </SimpleTabPanel>
                                        </div>
                                    </div> 
                                    : null  
                            }
                            <BottomNavigation 
                                enableAddIcon={true}
                                onAddIconClick={createDepartment}
                            />
                        </>
                }
            </Container> 
            { 
                createDepartmentPopup ? 
                    <CreateDepartmentPopup
                        open={createDepartmentPopup} 
                        handleClose={handlePopupClose}
                        heading="Create" 
                        buttonLabelName="Create"
                    /> :
                    null 
            }
            { 
                editDepartmentName ? 
                    <CreateDepartmentPopup 
                        heading="Edit"
                        open={editDepartmentName}
                        handleClose={handleEditPopupClose} 
                        editDepartment={true}
                        nameIndex={departmentNameIndex} 
                        buttonLabelName="Update" 
                    /> :
                    null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        departmentsState : state.departments,
        CUDepartmentState : state.cuDepartment
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestDepartments : () => dispatch(fetchDepartments()),
        updateDepartment : (id,departmentStatus) => dispatch(updateDepartment(id,departmentStatus)),
        saveDepartments : (response) => dispatch(saveDepartments(response))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Department)