import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useMediaQuery from '@mui/material/useMediaQuery'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'

import { getAuthToken } from '../../../utils/utils'
import { deleteCourse, readCourseAndCourseContentStatus } from '../../../services/learning.service'
import { insertCourseData, clearCourseContentStatus } from '../../../redux/learning/course/CourseAction'
import { clearDeleteCourse } from '../../../redux/learning/delete-courses/DeleteCourseAction'
import "./course-detail.css"
import PublicWebClient from '../../../utils/public-web-client'
import wallpaperPlaceholder from '../../../assets/images/wallpaper-placeholder.png'
import HeaderContainer from '../../../common/header-container/HeaderContainer'
import BodyContainer from '../../../common/body-container/BodyContainer'
import StandardSelectBoxById from '../../../common/standard-select-box-by-id/StandardSelectBoxById'
import { specificLearningAreaSuccess } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'
import DialogBox from '../../../components/dialog-box/DialogBox'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../../components/desktop-layout/description/Description'
import DeletePopUp from '../../../components/delete-popup/DeletePopUp'
import CoursePanelWithProgressStatus from '../../../components/course-panel-with-progress-status/CoursePanelWithProgressStatus'
import Header from '../../../common/header/Header'

const CourseDetails = (props) => {

    const { fetchCourse, 
            deleteCourse, 
            saveLearningArea, 
            saveCourse,
            deleteCourseState,
            clearDeleteCourseState,
            readCourseState } = props

    const history = useHistory()
    const params = useParams()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const course = readCourseState.response.course
    const courseContentStatus = readCourseState.response.courseContentStatus

    const [pageLoading,setPageLoading] = useState(true)
    const [ menu, setMenu ] = useState(null)
    const [ popUp, setPopup ] = useState(false)
    const [ courseStatus, setCourseStatus ] = useState({
        value : "",
        fileId : ""
    })
    const [ deletePopup, setDeletePopup ] = useState(false)
    const [navbarMoreMenu,setNavbarMoreMenu] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const handlePopUpOpen = () => {
        setPopup(true)
    }
    
    const handlePopUpClose = (value) => {
        setPopup(false)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const open = Boolean(menu);

    const handleClick = (event) => {
        setMenu(event.currentTarget);
    };

    const handleClose = () => {
        setMenu(null);
    };

    const toggleDeletePopup = () => {
        setDeletePopup( ps => !ps)
    }

    const handleChange = async ( e, fileId ) => {
        setCourseStatus(ps => {
            return {
                ...ps,
                value : e.target.value,
                fileId : fileId
            }
        })
        handlePopUpOpen()
    }

    async function handleAcceptEvent(){
        const selectedStatus = { "content_status" : courseStatus.value}
        const response = await PublicWebClient.put(`/course/${params.courseId}/course-content/${courseStatus.fileId}/status-edit`,selectedStatus,{
            headers:{
                Authorization : `Bearer ${getAuthToken()}`
            }
        })
        if(!isEmpty(response.data)){
            saveCourse(response.data.course)
            handlePopUpClose()
        }
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function handleDeleteTrigger(){
        deleteCourse(params.courseId)
    }

    function handleEditTrigger(){
        history.push(`/learning/learning-area/${course?.parent_learning_area?._id}/course/edit/${params.courseId}`)
    }

    useEffect(() => {
        if(!readCourseState.loading && !isEmpty(readCourseState.response)){
            setPageLoading(false)
        }

        if(!readCourseState.loading && !isEmpty(readCourseState.error)){
            setPageLoading(false)
            console.log(readCourseState.error)
            if(readCourseState.error?.data){
                toast.error(readCourseState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[readCourseState])

    useEffect(()=> {
        if(!deleteCourseState.loading && !isEmpty(deleteCourseState.response)){
            saveLearningArea(deleteCourseState.response)
            toast.success("Course deleted successfully",{
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.goBack()
        }

        if(!deleteCourseState.loading && !isEmpty(deleteCourseState.error)){
            // console.log(deleteCourseState.error)
            if(deleteCourseState.error?.data){
                toast.error(deleteCourseState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[deleteCourseState])

    useEffect(() => {
        if(isEmpty(fetchCourse.response)){
            fetchCourse(params.courseId)
        }
        return () => {
            clearDeleteCourseState()
        }
    }, [])

    function getDetail(){
        return (
            <>
                <div className="px-3 mt-2">
                    <h3 className="lato-fs22-normal-lh32 mb-0 primary-text-color font-bold">{!isEmpty(course) && course.name ? course.name :"" }</h3>
                    {
                        course?.description ?
                            <div className='mt-3'>
                                <Description description={course.description} />
                            </div>
                            : null
                    }
                </div>
                <>
                    <div className="scroll-container mt-4 bg-tertiary-color-v2 py-4" style={{borderRadius : "1rem"}}>
                        {
                            !isEmpty(course.course_content_files) && course.course_content_files.map((file,index) => (
                                <div className='reference-container'>
                                    <CoursePanelWithProgressStatus
                                        cardClassName="max-width-250px"
                                        preview={!isEmpty(file.mimetype) && file.mimetype.split('/')[0] === "image" ? file.url : ""}
                                        linkName={file?.originalname}
                                        url={file?.url}
                                        progressSelectBoxProps={{
                                            options: courseContentStatus,
                                            label: "status",
                                            name: "content_status",
                                            value: file.progress_status,
                                            onChange: (e) => handleChange(e,file._id)
                                        }}
                                    />
                                </div>
                            ))
                        }
                        {
                            !isEmpty(course.course_content_links) && course.course_content_links.map((link,index) =>(
                                <div className="reference-container" key={index} >
                                    <CoursePanelWithProgressStatus 
                                        cardClassName="max-width-250px"
                                        preview={link?.link_meta_data?.images?.[0]}
                                        linkName={link?.link_meta_data?.title ? link?.link_meta_data?.title : link?.url}
                                        url={link.url}
                                        progressSelectBoxProps={{
                                            options: courseContentStatus,
                                            label: "status",
                                            name: "content_status",
                                            value: link.progress_status,
                                            onChange: (e) => handleChange(e,link._id)
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </>
            </>
        )
    }

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function handleNotification(){
        history.push(`/notification-reference?selected=notification&tab=inbox&action=learnings`)
    }
    
    return (
        <Container>
            {
                isDesktop ? 
                    <>
                        <Navbar enableMore={true} 
                            enableLogout={true}
                            enableProfileButton={true}
                            moreRight="72px"
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen}
                            enableNotification={true}
                            notificationOnClick={handleNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {
                                        !pageLoading && !isEmpty(course) ? 
                                            <>
                                                <div className='mt-3 d-flex align-items-center'>
                                                    <div>
                                                        <span className='lato-fs22-normal-lh32 font-bold mb-0'>{course.name}</span>
                                                        <span className='lato-fs16-normal-lh20 secondary-text-color'> in {course.parent_learning_area?.name}</span>
                                                    </div>
                                                    <div className='ms-auto'>
                                                        <IconButton onClick={handleClick}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={menu}
                                                            open={open}
                                                            onClose={handleClose}
                                                            onClick={handleClose}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: 'visible',
                                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                    mt: 1.5,
                                                                    '& .MuiAvatar-root': {
                                                                        width: 32,
                                                                        height: 32,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                    },
                                                                    '&:before': {
                                                                        content: '""',
                                                                        display: 'block',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 14,
                                                                        width: 10,
                                                                        height: 10,
                                                                        bgcolor: 'background.paper',
                                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                                        zIndex: 0,
                                                                    },
                                                                },
                                                            }}
                                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                        >
                                                            <MenuItem 
                                                                sx={{
                                                                    fontSize:"16px", 
                                                                    fontFamily:"'Lato',sans-serif", 
                                                                    color:"var(--primary-text-color-v2)"
                                                                }} 
                                                                onClick={handleEditTrigger}
                                                            >
                                                                <FontAwesomeIcon icon={faPen} className="me-2 secondary-color" />
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem 
                                                                sx={{
                                                                    fontSize:"16px", 
                                                                    fontFamily:"'Lato',sans-serif", 
                                                                    color:"var(--primary-text-color-v2)"
                                                                }} 
                                                                onClick={toggleDeletePopup}
                                                            > 
                                                                <FontAwesomeIcon icon={faTrash} className="me-2 secondary-color" />
                                                                Delete 
                                                            </MenuItem>
                                                        </Menu> 
                                                    </div>
                                                </div>
                                                {
                                                    course.description ? 
                                                        <div className='mt-3'>
                                                            <Description description={course.description} />
                                                        </div> 
                                                        : null
                                                }
                                                <div 
                                                    className='mt-5'
                                                    style={{
                                                        backgroundColor: 'var(--tertiary-color-v2)',
                                                        width: '100%',
                                                        padding: "1.5rem 1rem",
                                                        borderRadius: "1rem"
                                                    }}
                                                >
                                                    <Grid container spacing={{ md:2 }}>
                                                        {
                                                            !isEmpty(course.course_content_files) && course.course_content_files.map(file => (
                                                                <Grid item md={4}>
                                                                    <CoursePanelWithProgressStatus 
                                                                        preview={!isEmpty(file.mimetype) && file.mimetype.split('/')[0] === "image" ? file.url : ""}
                                                                        linkName={file?.originalname}
                                                                        url={file?.url}
                                                                        progressSelectBoxProps={{
                                                                            options: courseContentStatus,
                                                                            label: "status",
                                                                            name: "content_status",
                                                                            value: file.progress_status,
                                                                            onChange: (e) => handleChange(e,file._id)
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                        {
                                                            !isEmpty(course.course_content_links) && course.course_content_links.map(link => (
                                                                <Grid item md={4} sx={{backgroundColor: 'var(--tertiary-color-v2)'}}>
                                                                    <CoursePanelWithProgressStatus 
                                                                        preview={link?.link_meta_data?.images?.[0]}
                                                                        linkName={link?.link_meta_data?.title ? link?.link_meta_data?.title : link?.url}
                                                                        url={link.url}
                                                                        progressSelectBoxProps={{
                                                                            options: courseContentStatus,
                                                                            label: "status",
                                                                            name: "content_status",
                                                                            value: link.progress_status,
                                                                            onChange: (e) => handleChange(e,link._id)
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </div>
                                            </>
                                            : null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    : <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={handleNotification}
                        />
                        <div className="container">
                            { !pageLoading && !isEmpty(course) ? 
                                <>
                                    <div className='mt-3 px-3'>
                                        <Header 
                                            heading={course.parent_learning_area?.name} 
                                            handleBackArrowEvent={handleBackArrowEvent}
                                            moreIcon={true}
                                            moreIconEvent={handleClick}
                                            open={open}
                                            menu={menu}
                                            handleClose={handleClose}
                                            handleEditEvent={handleEditTrigger}
                                            handleDeleteEvent={toggleDeletePopup}
                                        />
                                    </div>
                                    <div className="pb-70">
                                        {getDetail()}
                                    </div>
                                </> :null
                            }
                        </div>
                        <BottomNavigation/>
                    </> 
            }
            {
                popUp ? 
                    <DialogBox 
                        open={popUp} 
                        onClose={handlePopUpClose} 
                        mgs={<p className='lato-fs18-normal-lh22 mb-0'>Progress status has been changed to <b>{courseStatus.value}</b>. Are you sure ?</p>} 
                        rejectLabel="No" 
                        acceptLabel="Yes" 
                        cancelButtonEvent={handlePopUpClose} 
                        acceptButtonEvent={handleAcceptEvent}  
                    /> 
                    : null
            }
            { 
                deletePopup ? 
                    <DeletePopUp 
                        handleClose={toggleDeletePopup} 
                        msg={`${course.name} will be deleted permanently. Are you sure?`} 
                        open={deletePopup} 
                        onClick={handleDeleteTrigger}
                    />
                    : null
            }
        </Container>
    )
}

const mapStateToProps = (state) =>{
    return {
        deleteCourseState : state.deleteCourse,
        readCourseState : state.readCourse
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCourse : (courseId) => dispatch(readCourseAndCourseContentStatus(courseId)),
        deleteCourse : (courseId) => dispatch(deleteCourse(courseId)),
        saveCourse : (course) => dispatch(insertCourseData(course)),
        saveLearningArea : (learningArea) => dispatch(specificLearningAreaSuccess(learningArea)),
        clearDeleteCourseState : () => dispatch(clearDeleteCourse()),
        clearCourseStates : () => dispatch(clearCourseContentStatus())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CourseDetails)