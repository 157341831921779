import { REQUEST_SPECIFIC_LEARNING_AREA, 
         SPECIFIC_LEARNING_AREA_SUCCESS, 
         SPECIFIC_LEARNING_AREA_FAILURE, 
         CLEAR_SPECIFIC_LEARNING_AREA } from './specificLearningArea.types'

export const requestSpecificLearningArea = () =>{
    return {
        type : REQUEST_SPECIFIC_LEARNING_AREA
    }
} 

export const specificLearningAreaSuccess = (resData) => {
    return {
        type : SPECIFIC_LEARNING_AREA_SUCCESS,
        payload : resData
    }
}

export const specificLearningAreaFailure = (error) => {
    return {
        type : SPECIFIC_LEARNING_AREA_FAILURE,
        payload : error
    }
}

export const clearSpecificLearningArea = () => {
    return {
        type : CLEAR_SPECIFIC_LEARNING_AREA
    }
}