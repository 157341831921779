import React,{ useState, useEffect } from 'react'
import { toArray, isEmpty, size } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import IconButton from '@mui/material/IconButton'
import { connect } from 'react-redux'   
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import useMediaQuery from '@mui/material/useMediaQuery'

import withSupportDoubleClick from '../../../utils/with-support-double-click'
import { requestObjectiiveReviewAll } from '../../../services/review.service'
import { saveSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import { COMPONENTS_TYPE } from '../objective/objectives.utilis'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import './sub-objective-aggregate.css'
import { REVIEW_TYPES, PROCESS_STATUS } from '../../../utils/constants'
import AggregateCard from '../../../components/aggregate-card/AggregateCard'
import CarouselSlider from '../../../common/carousel-slider/CarouselSlider'
import GlassyCard from '../../../common/glassy-card/GlassyCard'
import ProcessContentArea from '../../../components/desktop-layout/process-content-area/ProcessContentArea'
import DrillDown from '../../../components/desktop-layout/drill-down/DrillDown'

const SubObjectiveAggregate = (props) => {

    const { objectiveStates, saveObjectives, ownProps } = props

    const ACTIVE_LEVEL_KEY_MAPPING = {
        0 : 'level_zero_objectives',
        1 : 'level_one_objectives',
        2 : 'level_two_objectives',
        3 : 'level_three_objectives'
    }

    const PATH_KEY_MAPPING = {
        0 : 'level_zero',
        1 : 'level_one',
        2 : 'level_two',
        3 : 'level_three'
    }

    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isMobile = useMediaQuery('(max-width:480px)')

    const [pageLoading, setPageLoading] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(undefined)
    const [subObjectives, setSubObjectives] = useState([])

    function getSubObjectives(parentObjectiveId, level){
        return new Promise((resolve, reject) => {
            let objectivesByLvl = objectiveStates.response[ACTIVE_LEVEL_KEY_MAPPING[level]]
            if(!isEmpty(objectivesByLvl)){
                let subObjs = []
                for(const obj of objectivesByLvl){
                    if(obj.path[PATH_KEY_MAPPING[level - 1]]._id === parentObjectiveId){
                        subObjs.push(obj)
                    }
                }
                resolve(subObjs)
            } else {
                resolve([])
            }
        })
    }

    function getSelectedObjectiveObj(index) {
        return subObjectives[index]
    }

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick: (e, index) => {
            saveObjectives({objective : getSelectedObjectiveObj(index)})
            history.push(`/vcf/${getSelectedObjectiveObj(index)._id}?level=${getSelectedObjectiveObj(index).level}`)
        },
        onSingleClick: (e, index) => {
            setSelectedIndex(index)
            if(index === selectedIndex){
                setSelectedIndex(undefined)
            }
        },
    })

    useEffect(() => {
        changeSubObjectives(queryParams.parent_id, parseInt(queryParams.lvl))
    }, [queryParams.lvl])

    function changeSubObjectives(parentId, level){
        if(queryParams.mode === COMPONENTS_TYPE.listSubObjs){
            getSubObjectives(parentId, level).then((res) => {
                setSubObjectives(res)
                setPageLoading(false)
            }).catch(err => {
                setPageLoading(false)
                console.log(err)
            })
        }
    }

    function getNextLevel(){
        if(selectedIndex !== undefined ){
            if(!isEmpty(getSelectedObjectiveObj(selectedIndex).sub_objectives)){
                history.push(`/vcf?mode=${COMPONENTS_TYPE.listSubObjs}&parent_id=${getSelectedObjectiveObj(selectedIndex)._id}&lvl=${getSelectedObjectiveObj(selectedIndex).level + 1}`)
                setPageLoading(true)
                changeSubObjectives(getSelectedObjectiveObj(selectedIndex)._id, getSelectedObjectiveObj(selectedIndex).level + 1)
                setSelectedIndex(undefined)
            } else {
                toast.info('No sub-objectives to view', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    function getPreviousLevel(){
        if(parseInt(queryParams.lvl)-2 >= 0){
            history.push(`/vcf?mode=${COMPONENTS_TYPE.listSubObjs}&parent_id=${subObjectives[0].path[PATH_KEY_MAPPING[parseInt(queryParams.lvl)-2]]._id}&lvl=${parseInt(queryParams.lvl)-1}`)
            setPageLoading(true)
            changeSubObjectives(subObjectives[0].path[PATH_KEY_MAPPING[parseInt(queryParams.lvl)-2]]._id, parseInt(queryParams.lvl)-1)
        } else {
            history.push(`/vcf?mode=${COMPONENTS_TYPE.listObjBylvl}&lvl=0`)
        }
    }

    function getPath(){
        if(!isEmpty(subObjectives)){
            if(!isEmpty(subObjectives[0])){
                let pathArray =  toArray(subObjectives[0].path)
                return pathArray.splice(0,pathArray.length-1)
            }
        }
    }

    // function getValue(key, objective){
    //     if((objective.status === PROCESS_STATUS.needsApproval) && (objective.review_status.review_type === REVIEW_TYPES.editRequest)){
    //         return objective.edits[key]
    //     } else {
    //         return objective[key]
    //     }
    // }
      
    switch(ownProps.variant){
        case "desktop":
            return (
                !pageLoading ?
                    !isEmpty(subObjectives) && queryParams.mode === COMPONENTS_TYPE.listSubObjs ?
                    <ProcessContentArea>
                        <GlassyCard className="pt-4">
                            <CarouselSlider
                                breadcrumbs={<BreadCrumbs icon={faThLarge} path={getPath()} truncate={isMobile} />}
                                size={size(subObjectives)}>
                                    { 
                                        subObjectives.map((subObjective, index) => (
                                            <div key={ subObjective._id }>
                                                <div className='mx-3 pb-4'>
                                                    <AggregateCard
                                                        variant="desktop"
                                                        wallpaper={ subObjective.wallpaper ? subObjective.wallpaper : "" }
                                                        icon={ subObjective.icon ? subObjective.icon : "" }
                                                        name={subObjective.name}
                                                        level={subObjective.level}
                                                        maxLevel={subObjective.max_level}
                                                        selected={selectedIndex === index}
                                                        onClick={(e) => handleCardClick(e,index)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                            </CarouselSlider>
                        </GlassyCard>
                        <DrillDown 
                            arrowUpDisabled={false}
                            onArrowUpClick={getPreviousLevel}
                            arrowDownDisabled={!(selectedIndex!==undefined && !isEmpty(subObjectives[selectedIndex].sub_objectives))}
                            onArrowDownClick={getNextLevel}
                            />
                    </ProcessContentArea>
                    : null
                : null
            )

        default:
            return (
                !pageLoading ?
                    !isEmpty(subObjectives) && queryParams.mode === COMPONENTS_TYPE.listSubObjs ?
                    <div className="position-relative process-aggregate-height" >
                        <div className=' pt-3 overflow-auto h-100'>
                            <div className='ml-20'>
                                <BreadCrumbs listClassName="bg-white" icon={faThLarge} path={getPath()} truncate={isMobile} />
                            </div>
                            <div className="scroll-container">
                                { 
                                    subObjectives.map((subObjective, index) => (
                                        <div key={ subObjective._id } className='sub-vcf-card'>
                                            <AggregateCard
                                                wallpaper={ subObjective.wallpaper ? subObjective.wallpaper : "" }
                                                icon={ subObjective.icon ? subObjective.icon : "" }
                                                name={subObjective.name}
                                                level={subObjective.level}
                                                maxLevel={subObjective.max_level}
                                                selected={selectedIndex === index}
                                                containerClassName={'min-width-250px min-height-240px max-width-250px'}
                                                onClick={(e) => handleCardClick(e,index)}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <DrillDown 
                            variant="mobile"
                            arrowUpDisabled={false}
                            onArrowUpClick={getPreviousLevel}
                            arrowDownDisabled={!(selectedIndex!==undefined && !isEmpty(subObjectives[selectedIndex].sub_objectives))}
                            onArrowDownClick={getNextLevel}
                        />
                    </div>
                    : null
                : null
            )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        objectiveStates : state.fetchObjectivesData,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjectiveReview : (userId) => dispatch(requestObjectiiveReviewAll(userId)),
        saveObjectives : (response) => dispatch(saveSpecificObjectiveForReview(response))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SubObjectiveAggregate)