import { REQUEST_COMPANY_VIEW,
         COMPANY_VIEW_FAILURE,
         COMPANY_VIEW_SUCCESS,
         CLEAR_COMPANY_VIEW_STATE } from './CompanyView.types'

export const requestCompanyView = () => {
    return {
        type : REQUEST_COMPANY_VIEW,
    }
}

export const companyViewSuccess = (response) => {
    return {
        type : COMPANY_VIEW_SUCCESS,
        payload : response
    }
}

export const companyViewFailure = (error) => {
    return {
        type : COMPANY_VIEW_FAILURE,
        payload : error
    }
}

export const clearCompanyViewState = () => {
    return {
        type : CLEAR_COMPANY_VIEW_STATE,
    }
}