import { 
    requestProfileDataFetch, 
    profileDataFetchSuccess,  
    profileDataFetchFailure, 
} from '../redux/profile/ProfileAction'
import { 
    requestProfileDataUpdate, 
    updateProfileDataSuccess,  
    updateProfileDataFailure, 
} from '../redux/update-profile/updateProfile.action'
import { 
    requestOrgProfileDataUpdate, 
    updateOrgProfileDataSuccess,  
    updateOrgProfileDataFailure,
} from '../redux/update-org-profile/updateOrgProfile.action'

import ProtectedWebClient from '../utils/protected-web-client'

import { fetchJobroles, fetchHighestQualifications, fetchCountries } from './constants.service'

export const fetchProfileData = () => {
    return async (dispatch) =>{
        try{
            dispatch(requestProfileDataFetch())
            const [ highestQualificationsRes,
                    countryRes, 
                    profileRes ] = await Promise.all([ fetchHighestQualifications(), 
                                                        fetchCountries(), 
                                                        fetchProfile()])
            dispatch(profileDataFetchSuccess({
                highestQualifications : highestQualificationsRes.data.highest_qualifications,
                countries : countryRes.data.countries,
                profile : profileRes.data
            }))
        } catch(error){
            dispatch(profileDataFetchFailure(error.response))
        }
    }
}

function fetchProfile(){
    return new Promise((resolve,reject) => {
        ProtectedWebClient.get("/user-profile").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export function updateProfileData(profileData) {
    return async (dispatch) =>{
        try{
            dispatch(requestProfileDataUpdate())
            const response = await ProtectedWebClient.put('/user-profile', profileData)
            dispatch(updateProfileDataSuccess(response.data))
        } catch(error){
            dispatch(updateProfileDataFailure(error.response))
        }
    }
}

export function updateOrgProfileData(profileData) {
    return async (dispatch) =>{
        try{
            dispatch(requestOrgProfileDataUpdate())
            const response = await ProtectedWebClient.put('/user-profile', profileData)
            dispatch(updateOrgProfileDataSuccess(response.data))
        } catch(error){
            dispatch(updateOrgProfileDataFailure(error.response))
        }
    }
}
