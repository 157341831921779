import { REQUEST_DEVELOPMENT_AREA, 
        DEVELOPMENT_AREA_SUCCESS,
        DEVELOPMENT_AREA_FAILURE,
        CLEAR_DEVELOPMENT_AREA_STATE } from './Development.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const developmentAreaReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_DEVELOPMENT_AREA :
            return {
                ...state,
                loading : true,
                error :null
            }

        case DEVELOPMENT_AREA_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case DEVELOPMENT_AREA_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_DEVELOPMENT_AREA_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default developmentAreaReducer