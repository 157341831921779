import { REQUEST_SUBSCRIPTION, 
        FETCH_SUBSCRIPTION_SUCCESS, 
        FETCH_SUBSCRIPTION_FAILURE, 
        CLEAR_SUBSCRIPTION_STATE } from './Subscription.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const subscriptionReducer = ( state = initialState, action ) =>  {
    switch(action.type) {
        case REQUEST_SUBSCRIPTION :
            return{
                ...state,
                loading : true,
                error : null
            }

        case FETCH_SUBSCRIPTION_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case FETCH_SUBSCRIPTION_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_SUBSCRIPTION_STATE : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default : 
            return state
    }
}

export default subscriptionReducer