import React from 'react'
import { Bar } from 'react-chartjs-2'
import { size } from 'lodash'
import moment from 'moment'
import useWindowDimension from '../../../hooks/useWindowDimension'
import useMediaQuery from '@mui/material/useMediaQuery'

import { formatDate } from '../../../utils/utils'

const GRAPH_COLORS = [
    {
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(75,192,192,1)",
    },
    {
        backgroundColor: "rgba(0,128,0,1)",
        borderColor: "rgba(0,128,0,1)",
    },
    {
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
    }
]

const BarChart = (props) => {

    const { height, width } = useWindowDimension();
    const isMobile = useMediaQuery('(max-width:480px)')

    function checkValidDate(dateString){
        return moment(dateString, moment.ISO_8601, true).isValid()
    }

    function getGraphLabel(datas, key){
        let graphLabel = []
        for(let i = 0; i<size(datas); i++ ){
            // graphLabel.push(checkValidDate(datas[i][key]) ? formatDate(datas[i][key]) : datas[i][key])
            graphLabel.push(datas[i][key])
        }
        return graphLabel
    }

    function getDatasets(datas) {
        let dataset = []
        let maskedLabels = Object.keys(datas[0])
        // maskedLabels.shift()
        let maskedData = datas[1]
        const graphData = maskedLabels.length > 2 && typeof(maskedData.C) === 'number' ? [maskedLabels[1], maskedLabels[2]] : [maskedLabels[1]]
        for(let i=0; i<size(graphData); i++){
            dataset.push({
                barPercentage: 0.5,
                barThickness: Math.floor(40/size(graphData)),
                maxBarThickness: 50,
                minBarLength: 2,
                label : `${i}`,
                backgroundColor: GRAPH_COLORS[i].backgroundColor,
                borderColor: GRAPH_COLORS[i].borderColor,
                borderWidth: 4,
                data: getGraphLabel(datas, graphData[i])
            })
        }
        return dataset
    }

    function getDataSetsPreview(datas, labels) {
        let dataset = []
        let maskedLabels = Object.keys(datas[0])
        // maskedLabels.shift()
        let maskedData = datas[1]
        const graphData = maskedLabels.length > 2 && typeof(maskedData.C) === 'number' ? [maskedLabels[1],maskedLabels[2]] : [maskedLabels[1]]
        for(let i=0; i<size(graphData); i++){
            dataset.push({
                label : labels[graphData[i]],
                barPercentage : 0.5,
                barThickness: 40/size(graphData),
                maxBarThickness: 50,
                backgroundColor: GRAPH_COLORS[i].backgroundColor,
                borderColor: GRAPH_COLORS[i].borderColor,
                borderWidth: 2,
                data: getGraphLabel(datas, graphData[i])
            })
        }
        return dataset
    }

    // function getLegends(){
    //     const labels = props.graphDetail[0]
    //     const legendsArr = []
    //     if(labels.A){
    //         legendsArr.push(
    //             <div className='d-flex'>
    //                 <div style={{width: '70px', height: '50px', backgroundColor: GRAPH_COLORS[0].backgroundColor}}></div>
    //                 <span style={{fontSize: '14px', color: "#323130"}}>{labels.A}</span>
    //             </div>
    //         )
    //     }
    //     if(labels.B){
    //         legendsArr.push(
    //             <div className='d-flex'>
    //                 <div style={{width: '70px', height: '50px', backgroundColor: GRAPH_COLORS[0].backgroundColor}}></div>
    //                 <span style={{fontSize: '14px', color: "#323130"}}>{labels.A}</span>
    //             </div>
    //         )
    //     }
    // }

    function formatGraphDataForPreview(graphData) {
        let graphDataCopy = graphData.slice()
        const maskedLabelValues = graphDataCopy.shift()
        return {
            labels: getGraphLabel(graphDataCopy, 'A'),
            datasets : getDataSetsPreview(graphDataCopy, maskedLabelValues)
        }
    }

    function formatGraphData(graphData) {
        const curtailedGraphData = size(graphData) > 5 ? graphData.slice((graphData.length - 5), graphData.length) : graphData.slice(1, graphData.length)
        return {
            labels: getGraphLabel(curtailedGraphData, 'A'),
            datasets : getDatasets(curtailedGraphData)
        }
    }

    function calculateWidth(){
        const chartWidth = (size(props.graphDetail) - 1)*55
        if(width < chartWidth){
            return `${chartWidth}px`
        } else {
            return `100%`
        }
    }

    // console.log("graph detail",props.graphDetail)

    switch(props.variant){
        case "preview" :
            return (
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div style={{width: `${calculateWidth()}`, height: isMobile ? '300px' : '400px'}}>
                        <Bar
                            className='p-1 w-100 h-100'
                            data={formatGraphDataForPreview(props.graphDetail)}
                            options={{
                                legend : {
                                    display: true,
                                    position: 'bottom',
                                    labels: {
                                        usePointStyle:true,
                                        fontColor: "#323130",
                                        fontSize: 14
                                    }
                                },
                                animation :{
                                    duration : 0  
                                },
                                responsive : true,
                                maintainAspectRatio: false,
                                scales: {                  
                                    x: {
                                        grid : {
                                            display:false,   //hide the label and border
                                        },
                                        ticks : {
                                            autoSkip : true,
                                            maxTicksLimit : 5,
                                            align : 'center',
                                            font: {
                                                size : 9
                                            }
                                        }
                                    },
                                    y: {
                                        grid : {
                                            display : false, //hide the label and border
                                        },
                                        ticks : {
                                            align : 'center',
                                            font: {
                                                size : 9
                                            }
                                        }
                                    },
                                }                  
                            }} />
                    </div>
                </div>
            )

        case "expanded-graph":
            return (
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div style={{width: `${calculateWidth()}`, height: '70vh'}}>
                        <Bar
                            data={formatGraphDataForPreview(props.graphDetail)}
                            className='w-100 h-100'
                            options={{
                                // plugins : {
                                //     title : {
                                //         display : true,
                                //         text : 'custom chart title' //Adding title
                                //     }
                                // },
                                animation :{
                                    duration : 0  
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                legend:{
                                    display: true,
                                    position: "bottom",
                                    labels: {
                                        fontColor: "rgba(68,68,68,255)",
                                        fontSize: 11,
                                        fontFamily: "'Lato',sans-serif"
                                    }
                                },
                                scales : {
                                    x : {
                                        title : {
                                            display : true,
                                            text : props.graphDetail[0].A,
                                            color: "rgba(68,68,68,255)",
                                            font : {
                                                size : 14,
                                                family : "'Lato',sans-serif",
                                            }
                                        },
                                        ticks : {
                                            autoSkip : true,
                                            maxTicksLimit : 5,
                                            color : 'rgba(68,68,68,255)',
                                            fontFamily : "'Lato',sans-serif",
                                            font : {
                                                size : 11
                                            }
                                        }
                                    },
                                    y : {
                                        title : {
                                            display : true,
                                            text : props.graphDetail[0].B,
                                            color: "rgba(68,68,68,255)",
                                            font : {
                                                size : 14,
                                                family : "'Lato',sans-serif"
                                            }
                                        }
                                    }
                                }
                        }}
                        />
                    </div>
                </div>
            )
        
        default :
            return (
                <Bar
                    className='p-4 h-100 w-100'
                    data={formatGraphData(props.graphData)}
                    options={{
                        plugins: {              // hide the color representation
                            legend: {
                                display: false,
                            },
                            tooltip:{
                                enabled: false
                            }
                        },
                        responsive : true,
                        animation :{
                            duration : 0  
                        },
                        scales: {
                            x: {
                                display: false,  //hide the label and border
                            },
                            y: {
                                display : false, //hide the label and border
                            }
                        }                  
                    }} 
                />
            )
    }

}

export default BarChart