import React,{useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { isEmpty, toArray } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import ObjectiveCard from '../../../components/objective-card/ObjectiveCard'
import ReferenceFiles from '../../../components/reference-files/ReferenceFiles'
import { clearSpecificGoalStates } from '../../../redux/specific-goal/specificGoal.action'
import { deleteGoal, goalDeletePromise, specificGoal } from '../../../services/goals.service'
import { fetchGoals } from '../../../services/fetchGoals.service'
import DeletePopUp from '../../../components/delete-popup/DeletePopUp'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import ProcessWallpaperIcon from '../../../components/desktop-layout/process-wallpaper-icon/ProcessWallpaperIcon'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import Description from '../../../components/desktop-layout/description/Description'
import Container from '../../../components/desktop-layout/container/Container'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import Header from '../../../common/header/Header'
import Name from '../../../components/name/Name'

const GoalsDetails = (props) => {

    const { specificGoalState, 
            requestSpecificGoal, 
            fetchGoals,
            clearSpecificGoalState } = props

    const params = useParams()
    const history = useHistory()
    const [contentLoading, setContentLoading] = useState(true)
    const [ menu, setMenu ] = useState(null)
    const [ deletePopup,setDeletePopup ] = useState(false)
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    const goal = specificGoalState.response

    const open = Boolean(menu)

    const handleClick = (event) => {
        setMenu(event.currentTarget)
    }

    const handleClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    // specific goal state
    useEffect(() => {
        if(!specificGoalState.loading && !isEmpty(specificGoalState.response)){
            // console.log(specificGoalState.response)
            setContentLoading(false)
        }

        if(!specificGoalState.loading && specificGoalState.error){
            console.log(specificGoalState.error)
            setContentLoading(false)
            if(specificGoalState.error?.data){
                toast.error(specificGoalState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                
            }
            setContentLoading(false)
        }
    }, [specificGoalState])

    useEffect(() => {
        // request for specfic goal
        if(isEmpty(specificGoalState.response)){
            requestSpecificGoal(params.goalId)
        }

        // Do not clear. Same state is used in update goal
        // return () => {
        //     clearSpecificGoalState()
        // }
    },[])

    function handleEditTrigger(){
        history.push(`/goal/edit?goal_id=${params.goalId}`)
    }

    function handleAddClickEvent(){
        history.push("/goal/create")
    }

    function handleDeleteTrigger(){
        goalDeletePromise(params.goalId)
        .then(res => {
            history.push("/goal")
            fetchGoals()
        }).catch(err => {
            console.log(err)
            if(err.response?.data){
                toast.error(err.response.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        })
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleDeletePopup(){
        setDeletePopup(ps => !ps)
    }

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    const onClickNotification = () => {
        history.push(`/notification-reference?selected=notification&tab=inbox&action=goals`)
    }

    function getDetail(){
        return (
            <>
                {
                    isDesktop?
                        <div className='mt-3'>
                            <Header 
                                platform="desktop" 
                                verticalEllipsisEvent={handleClick}
                                handleClose={handleClose}
                                menu={menu}
                                open={open}
                                editEvent={handleEditTrigger}
                                deleteEvent={toggleDeletePopup}
                                start={
                                    <>
                                        <BreadCrumbs
                                            path={toArray(goal.objective_id.path)}
                                            activeId={goal.objective_id._id}
                                            icon={faThLarge}
                                            truncate={isMobile} 
                                        />  
                                    </>
                                }
                            />
                        </div>
                    : null
                }
                <div>
                    { 
                        !isDesktop ? 
                            <div className='mb-2'>
                                <BreadCrumbs
                                    path={toArray(goal.objective_id.path)}
                                    activeId={goal.objective_id._id}
                                    icon={faThLarge}
                                    truncate={isMobile} 
                                />
                            </div>
                        : null
                    }
                    <div className='pt-3'>
                        <ProcessWallpaperIcon
                            wallpaper={goal.wallpaper}
                            icon={goal.icon}
                            variant={isDesktop ? 'desktop' : 'mobile'}
                        />
                    </div>
                    <div className='mt-3'>
                        <Name name={goal.name} />
                    </div>
                    {
                        !isEmpty(goal.description) ?
                            <div className='mt-2'>
                                <Description description={goal.description} />
                            </div> 
                            : null
                    }
                    {
                        !isEmpty(goal.reference_files) || !isEmpty(goal.reference_links) ?
                            <div className="mt-5">
                                <ReferenceFiles referenceFiles={goal.reference_files} referenceLinks={goal.reference_links} />
                            </div> 
                            : null
                    }
                </div>
            </>
        )
    }

    return (
        <>
            {
                deletePopup ? 
                    <DeletePopUp
                        open={deletePopup}
                        msg={`${goal?.name} with its metrics will be deleted permanently. Are you sure? `} 
                        handleClose={toggleDeletePopup} 
                        onClick={handleDeleteTrigger}
                    />
                    : null
            }
            <Container>
                {
                    isDesktop ? 
                        <>
                            <Navbar 
                                enableMore={true}
                                enableLogout={true}
                                enableProfileButton={true}
                                open={navbarOpen}
                                menu={navbarMoreMenu} 
                                handlePopoverClose={handleNavbarMoreMenuClose}
                                handlePopoverOpen={handleNavbarMoreMenuOpen}
                                enableNotification={true}
                                notificationOnClick={onClickNotification}
                            />
                            <ProcessSectionContainer>
                                <LeftVerticalMenuBar 
                                    addIcon={true} 
                                    addIconTooltip="Create Goal" 
                                    onAddIconClick={handleAddClickEvent}
                                />
                                <ProcessMainContainer className="white-bg" >
                                    <SubMainContainer className="mb-40">
                                        {
                                            !contentLoading && !isEmpty(goal) ?
                                                getDetail()
                                                : null
                                        }
                                    </SubMainContainer>
                                </ProcessMainContainer>
                            </ProcessSectionContainer>
                        </> :
                        <>
                            <Navbar 
                                variant="mobile" 
                                enableLogout={true}
                                enableDrawer={true}
                                drawerOpened={drawer}
                                handleDrawerClose={toggleDrawer}
                                onDrawerClick={toggleDrawer}
                                enableNotificationsIcon={true}
                                onNotificationsIconClick={onClickNotification}
                            />
                            <div className='container pb-60px'>
                                <div className='px-3 mt-3 mb-3'>
                                    <Header 
                                        handleBackArrowEvent={handleBackArrowEvent} 
                                        moreIcon={true}
                                        moreIconEvent={handleClick}
                                        open={open}
                                        menu={menu}
                                        handleClose={handleClose}
                                        handleEditEvent={handleEditTrigger}
                                        handleDeleteEvent={toggleDeletePopup}
                                    />
                                </div>
                                <div className='px-3'>
                                    {
                                        !contentLoading && !isEmpty(goal) ?
                                            getDetail()
                                            : null
                                    }
                                </div>
                            </div>                            
                            <BottomNavigation onAddIconClick={handleAddClickEvent} enableAddIcon={true}/>
                        </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return{
        fetchGoalsState : state.fetchGoals,
        specificGoalState : state.specificGoal
    }
}

const mapDispatchToProps=(dispatch) => {
    return {
        fetchGoals : () => dispatch(fetchGoals()),
        deleteGoal : (goalId) => dispatch(deleteGoal(goalId)),
        requestSpecificGoal : (goalId) => dispatch(specificGoal(goalId)),
        clearSpecificGoalState : () => dispatch(clearSpecificGoalStates())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GoalsDetails)