import React, { useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty, size } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import profilePlaceholder from '../../../assets/images/profile-placeholder.png'
import useMediaQuery from '@mui/material/useMediaQuery'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './member-detail.css'
import AddMember from '../../../components/add-member/AddMember'
import { getRole, getUserId } from '../../../utils/utils'
import ReplaceTeamMember from '../../../components/replace-team-member/ReplaceTeamMember'
import RemoveTeamMember from '../../../components/remove-team-member/RemoveTeamMember'
import { removeTeamMember } from '../../../services/teamMember.service'
import { CONTROLLER_ROLE, USER_ROLE } from '../../../utils/constants'
import MoreVert from '@mui/icons-material/MoreVert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Avatar from '@mui/material/Avatar'

const MemberDetail = (props) => {

    const { memberDetailState } = props

    const params = useParams()
    const history = useHistory()

    const [ addMemberPopup, setAddMemberPopup ] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [ replaceTeamMemberPopup, setReplaceTeamMemberPopup ] = useState(false)
    const [ removeTeamMemberPopup, setRemoveTeamMemberPopup ] = useState(false)
    const [ menu, setMenu ] = useState(false)

    const memberDetail = memberDetailState.response
    const isDesktop = useMediaQuery('(min-width:992px)')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleAddMemberEvent(){
        setAddMemberPopup(!addMemberPopup)
    }

    function handleAddMember(){
        handleAddMemberEvent()
        handleClose()
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function getGender(gender){
        if(gender === "M"){
            return "Male"
        }else if(gender === "F"){
            return "Female"
        }else if(gender === "O"){
            return "Others"
        }
    }

    function handleReplaceMember(){
        setReplaceTeamMemberPopup( ps => !ps)
        if(anchorEl){
            handleClose()
        }
    }

    function handleRemoveTeamMember(){
        if(getRole() === "user_cum_controller" && ((!isEmpty(memberDetail?.user_subscriber_mapping?.[0].assignees) && !isEmpty(memberDetail?.user_subscriber_mapping?.[0]?.incharge_of)) || size(memberDetail?.user_subscriber_mapping?.[0].assignees) > 1)){
            setRemoveTeamMemberPopup( ps => !ps )
            if(anchorEl){
                handleClose()
            }
        } else {
            const payload = getRole() === CONTROLLER_ROLE 
                ? {removing_user: params.memberId, remove_from: [{id: memberDetail?.user_subscriber_mapping?.[0].assignees[0]._id}]}
                : {removing_user: params.memberId}
            removeTeamMember(payload)
            .then((res) => {
                if(anchorEl){
                    handleClose()
                }
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                history.goBack()
            }).catch((err) => {
                console.log(err)
                if(err && err?.response?.data){
                    toast.error(err.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
        }
    }

    function showMenu(){
        let menu = false
        if(getRole() === CONTROLLER_ROLE){
            menu = true
        } else {
            if(!isEmpty(memberDetail) && !isEmpty(memberDetail.user_subscriber_mapping)){
                if(!isEmpty(memberDetail.user_subscriber_mapping[0].assignees)){
                    const isMember = memberDetail.user_subscriber_mapping[0].assignees.find((member) => member._id === getUserId())
                    if(isMember){
                        menu = true
                    }
                }
            }
        }
        return menu
    }

    function showRemoveMember(userSubscriberMapping){
        if( getRole() === USER_ROLE ){
            return !isEmpty(userSubscriberMapping[0].incharge_of) || size(userSubscriberMapping[0].assignees) > 1
        }else if( getRole() === CONTROLLER_ROLE ){
            return size(userSubscriberMapping[0].assignees) < 1
        }
    }

    return (
        <>
            <div className={isDesktop ? 'py-4' : 'container px-3 pt-4 pb-70'}>
                <div className="d-flex align-items-center justify-content-start pb-2">
                    {
                        showMenu()  ?
                            <>
                                {
                                    !isDesktop ?
                                        <IconButton sx={{padding : 0}} className="me-2" size="small" onClick={handleBackArrowEvent}>
                                            <ArrowBackIcon sx={{color:"var(--primary-text-color-v2)"}} />
                                        </IconButton>
                                        : null
                                }
                                <div className="ms-auto">
                                    { getRole() === "user" && (!isEmpty(memberDetail.user_subscriber_mapping[0].incharge_of) || size(memberDetail.user_subscriber_mapping[0].assignees) > 1) ?
                                        null :
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <MoreVert sx={{fontSize: '26px'}} />
                                        </IconButton>
                                    }
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {
                                            getRole() === CONTROLLER_ROLE ? 
                                                <MenuItem 
                                                    onClick={() => history.push(`/organisation/member/edit/${params.memberId}`)}>Edit</MenuItem> : null
                                        }
                                        { 
                                            getRole() === CONTROLLER_ROLE ? 
                                                <MenuItem onClick={handleAddMember}>Add a team member</MenuItem>
                                                : null
                                        }
                                        {/* <MenuItem onClick={handleReplaceMember}> Replace this member</MenuItem> */}
                                        { 
                                            showRemoveMember(memberDetail.user_subscriber_mapping) ? 
                                                null : 
                                                <MenuItem onClick={handleRemoveTeamMember}>Remove this member</MenuItem> 
                                        }
                                    </Menu>
                                </div> 
                            </>
                            : null
                    }
                </div>
                <Row>
                    <Col xs={12} sm={4} md={3} lg={3} xl={3} className="d-flex align-items-center" >
                        <div style={{width: '150px', height: '150px'}}>
                            <img style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: "center", borderRadius: '50%'}} src={memberDetail.profile_pic ? memberDetail.profile_pic : profilePlaceholder} alt="" />
                        </div>
                    </Col>
                    <Col className='d-flex flex-column justify-content-center'>
                        <div className='mb-2'>
                            {
                                memberDetail?.person_name?.first_name ?
                                    <span className='lato-fs26-bold-lh32'>{`${memberDetail?.person_name?.first_name} `}</span>
                                    : null
                            }
                            {
                                memberDetail?.person_name?.middle_name ?
                                    <span className='lato-fs26-bold-lh32'>{`${memberDetail?.person_name?.middle_name} `}</span>
                                    : null
                            }
                            {
                                memberDetail?.person_name?.last_name ?
                                    <span className='lato-fs26-bold-lh32'>{`${memberDetail?.person_name?.last_name}`}</span>
                                    : null
                            }
                        </div>
                        <div className='mb-1'>
                            <FontAwesomeIcon className="primary-color-v2 me-2" style={{fontSize: '17px'}} icon={faEnvelope}  />
                            <span className="lato-fs17-normal-lh20 mb-0">{memberDetail.email.id}</span>
                        </div>
                        { 
                            memberDetail?.mobile?.number ?
                            <div className='mb-1'>
                                <FontAwesomeIcon className="primary-color-v2 me-2" style={{fontSize: '17px'}} icon={faPhoneAlt} />
                                <span className="lato-fs17-normal-lh20 mb-0">{memberDetail?.mobile?.number}</span>
                            </div> : ""
                        }
                        {
                            !isEmpty(memberDetail?.address) ?
                            <div className="d-flex mt-2">
                                <FontAwesomeIcon className="primary-color-v2 me-2 mt-1" style={{fontSize: '20px'}} icon={faMapMarkerAlt} />
                                <div>
                                    {
                                        memberDetail?.address?.door_no || memberDetail?.address?.area ?
                                            <div>
                                                {
                                                    memberDetail?.address?.door_no ?
                                                        <span className="lato-fs17-normal-lh20" >{`${memberDetail?.address?.door_no}, `}</span>
                                                        : null
                                                }
                                                {
                                                    memberDetail?.address?.area ?
                                                        <span className="lato-fs17-normal-lh20" >{`${memberDetail?.address?.area},`}</span>
                                                        : null
                                                }
                                            </div> :
                                            null
                                    }
                                    {
                                        memberDetail?.address?.city ?
                                            <div>
                                                <span className="lato-fs17-normal-lh20">{`${memberDetail?.address?.city},`}</span>
                                            </div> :
                                            null
                                    }
                                    {
                                        memberDetail?.address?.state || memberDetail?.address.pincode ?
                                            <div>
                                                {
                                                    memberDetail?.address?.state ?
                                                        <span className="lato-fs17-normal-lh20">{`${memberDetail?.address?.state} - `}</span>
                                                        : null
                                                }
                                                {
                                                    memberDetail?.address.pincode ?
                                                        <span className="lato-fs17-normal-lh20">{memberDetail?.address.pincode}</span>
                                                        : null
                                                }
                                            </div> :
                                            null
                                    }
                                </div>
                            </div> : null 
                        }
                    </Col>
                </Row>
                <hr className="small-hl" />
                <div>
                    <div className="member-detail-grid">
                        {
                            !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0]) && memberDetail.user_subscriber_mapping[0].role ? 
                                <div>
                                    <h4 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Access level</h4> 
                                    <span className="mb-0 lato-fs17-normal-lh20">{memberDetail.user_subscriber_mapping[0].role === 'user_cum_controller' ? 'Controller' : 'User'}</span>
                                </div> 
                                : null
                        }
                        {
                            !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0]) && memberDetail.user_subscriber_mapping[0].job_role ? 
                                <div>
                                    <h4 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Job Role</h4> 
                                    <span className="mb-0 lato-fs17-normal-lh20">{memberDetail.user_subscriber_mapping[0].job_role}</span>
                                </div> 
                                : null
                        }
                        <div>
                            <h4 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Org. location</h4>
                            <span className="mb-0 lato-fs17-normal-lh20">{memberDetail.user_subscriber_mapping[0].branch?.city}</span>
                        </div>
                        {
                            !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0]) && !isEmpty(memberDetail.user_subscriber_mapping[0].department) ?
                                <div>
                                    <h4 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Department</h4>
                                    <span className="mb-0 lato-fs17-normal-lh20">{memberDetail.user_subscriber_mapping[0].department.name}</span>
                                </div> 
                                : null
                        }
                        {
                            !isEmpty(memberDetail.dob)?
                                <div>
                                    <h4 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Age</h4>
                                    <span className="mb-0 lato-fs17-normal-lh20">{moment().diff(`${memberDetail.dob}`, 'years')}</span>
                                </div> 
                                : null
                        }
                        {
                            !isEmpty(memberDetail.gender) ?
                                <div>
                                    <h4 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Gender</h4>
                                    <span className="mb-0 lato-fs17-normal-lh20">{getGender(memberDetail.gender)}</span>
                                </div>
                                : null
                        }
                    </div>
                    {
                        !isEmpty(memberDetail?.user_subscriber_mapping?.[0]?.super_reviewers) || !isEmpty(memberDetail?.user_subscriber_mapping?.[0]?.assignees) ?
                            <>
                                <hr className="hl-long" />
                                {
                                    !isEmpty(memberDetail?.user_subscriber_mapping?.[0]?.super_reviewers) ?
                                        <div>
                                            <label className="lato-fs13-normal-lh18 tertiary-text-color-v2 mb-2">Super Reviewers</label>
                                            <div className="grid-column-2">
                                                { 
                                                    memberDetail.user_subscriber_mapping[0].super_reviewers.map((superReviewer, index) => (
                                                        <div className="d-flex align-items-center mb-3" key={index}>
                                                            <Avatar
                                                                sx={{width: '50px', height: '50px'}} 
                                                                src={superReviewer.profile_pic}
                                                            />
                                                            <div className="ms-2">
                                                                { 
                                                                    !isEmpty(superReviewer.person_name) ?
                                                                        <div>
                                                                            {superReviewer?.person_name?.first_name && <span className="lato-fs17-normal-lh20 mb-0">{superReviewer.person_name.first_name}</span>}
                                                                            {superReviewer?.person_name?.middle_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{superReviewer.person_name.middle_name}</span>}
                                                                            {superReviewer?.person_name?.last_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{superReviewer.person_name.last_name}</span>}
                                                                        </div> 
                                                                        : null
                                                                }
                                                                { 
                                                                    !isEmpty(superReviewer.email) ?
                                                                        <div>
                                                                            {superReviewer?.email?.id && <span className="lato-fs15-normal-lh20 tertiary-text-color-v2 mb-0">{superReviewer.email.id}</span>}
                                                                        </div> 
                                                                        : null
                                                                }
                                                                <div>
                                                                    <span 
                                                                        className='lato-fs14-normal-lh20 tertiary-text-color-v2 mb-0'
                                                                    >
                                                                        {
                                                                            superReviewer?.user_subscriber_mapping?.[0]?.job_role ?
                                                                                `${superReviewer?.user_subscriber_mapping?.[0]?.job_role} (${superReviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"})` 
                                                                                : superReviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div> 
                                        </div> 
                                        : null 
                                }
                                {
                                    !isEmpty(memberDetail?.user_subscriber_mapping?.[0]?.assignees) ?
                                        <div>
                                            <label className="lato-fs13-normal-lh18 tertiary-text-color-v2 mb-2">Reviewers</label>
                                            <div className="grid-column-2">
                                                { 
                                                    memberDetail.user_subscriber_mapping[0].assignees.map((reviewer, index) => (
                                                        <div className="d-flex align-items-center mb-3" key={index}>
                                                            <Avatar
                                                                sx={{width: '50px', height: '50px'}} 
                                                                src={reviewer.profile_pic}
                                                            />
                                                            <div className="ms-2">
                                                                { 
                                                                    !isEmpty(reviewer.person_name) ?
                                                                        <div>
                                                                            {reviewer?.person_name?.first_name && <span className="lato-fs17-normal-lh20 mb-0">{reviewer.person_name.first_name}</span>}
                                                                            {reviewer?.person_name?.middle_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{reviewer.person_name.middle_name}</span>}
                                                                            {reviewer?.person_name?.last_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{reviewer.person_name.last_name}</span>}
                                                                        </div> 
                                                                        : null
                                                                }
                                                                { 
                                                                    !isEmpty(reviewer.email) ?
                                                                        <div>
                                                                            {reviewer?.email?.id && <span className="lato-fs15-normal-lh20 tertiary-text-color-v2 mb-0">{reviewer.email.id}</span>}
                                                                        </div> 
                                                                        : null
                                                                }
                                                                <div>
                                                                    <span className='lato-fs14-normal-lh20 tertiary-text-color-v2 mb-0'>
                                                                        {
                                                                            reviewer?.user_subscriber_mapping[0]?.job_role ?
                                                                                `${reviewer?.user_subscriber_mapping?.[0]?.job_role} (${reviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"})` 
                                                                                : reviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div> 
                                        </div> 
                                        : null 
                                }
                            </>
                            : null
                    }
                </div>
            </div>
            { 
                addMemberPopup ? 
                    <AddMember 
                        handleClose={handleAddMemberEvent} 
                        open={addMemberPopup}
                    />
                    : null 
            }
            {/* { 
                replaceTeamMemberPopup ? 
                    <ReplaceTeamMember 
                        handleClose={handleReplaceMember} 
                        open={replaceTeamMemberPopup}
                    /> 
                    : null
            } */}
            { 
                removeTeamMemberPopup ? 
                    <RemoveTeamMember 
                        handleClose={handleRemoveTeamMember} 
                        open={removeTeamMemberPopup}
                    />
                    : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        memberDetailState : state.memberDetail
    }
}

export default connect(mapStateToProps,null)(MemberDetail)