import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from "lodash";
import {createMicrophoneAndCameraTracks, createClient} from "agora-rtc-react";
import { useSelector, useDispatch } from 'react-redux'

import { getVCUserDetails } from "../../services/videoConference.service";
import Videos from "../videos/Videos";
import Controls from "../controls/Controls";
import { 
  saveRemoteUser,
  removeRemoteUser,
  updateRemoteUser,
  saveScreenSharingUser,
  removeScreenSharingUser
} from '../../redux/video-conference/video-chat/videoChat.action'

import './video-call.css'

const config = { 
    mode: "rtc", codec: "vp8"
};

const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const SS_UID_MIN = 4289989999
const SS_UID_MAX = 4289999999

const VideoCall = (props) => {
  
    const { setInCall, trackState, setTrackState, setEndCall } = props;
    const { ready, tracks } = useMicrophoneAndCameraTracks();

    const [start, setStart] = useState(false);
    const [ drawerOpen, setDrawerOpen ] = useState(false)

    const client = useClient()
    const params = useParams()

    const videoChatState = useSelector((state) => state.videoChat)
    const localVC = videoChatState.localVC
    const dispatch = useDispatch()

    useEffect(() => {
      // function to initialise the SDK
      let init = async (channelId) => {

        client.on("user-joined", async (user) => {
          let userDetails = {}
            try {
              userDetails = await getVCUserDetails(localVC.vc_data.vc, {uid: user.uid})
            } catch (error) {
              console.log(error)
            }
            if(user.uid <= SS_UID_MAX && user.uid >= SS_UID_MIN) {
              dispatch(saveScreenSharingUser({user: user, profileInfo: !isEmpty(userDetails?.data) ? userDetails.data : {}}))
            } else {
              dispatch(saveRemoteUser(user, !isEmpty(userDetails?.data) ? userDetails.data : {}))
            }
          // console.log(`remote user of ${user.uid} joined`)
          // console.log("user", user)
        })

        client.on("user-published", async (user, mediaType) => {

          await client.subscribe(user, mediaType);
          // console.log("subscribe success", user);
          // console.log("mediaType", mediaType);

          if (mediaType === "audio") {
              // console.log('audio unmuted', user)
              user.audioTrack?.play();
          }
          dispatch(updateRemoteUser(user))
        });

        client.on("user-unpublished", (user, type) => {
          // console.log("unpublished", user);
          // console.log("type", type)
          if (type === "audio") {
              user.audioTrack?.stop();
              // console.log('audio muted', user)
          }
          dispatch(updateRemoteUser(user))
        });

        client.on("user-left", (user) => {
          // console.log("leaving", user);
          if(user.uid <= SS_UID_MAX && user.uid >= SS_UID_MIN) {
            dispatch(removeScreenSharingUser(user.uid))
          } else {
            dispatch(removeRemoteUser(user.uid))
          }
        });

        await client.join(localVC.app_id, channelId, localVC.token, localVC.vc_data.uid);
        // console.log("tracks", tracks)
        if (tracks) await client.publish([tracks[0], tracks[1]]);
        setStart(true);

      };

      if (ready && tracks) {
          // console.log("init ready");
          init(params.channelId);
      }

    }, [ready, params.channelId, client, tracks]);

    return (
      <div style={{backgroundColor: 'black'}}>
        <div className='video-content-area'>
          {
            start && tracks ?
              <Videos
                tracks={tracks}
                trackState={trackState}
                setDrawerOpen={setDrawerOpen}
                drawerOpen={drawerOpen}
              />
            : 
              null
          }
        </div>
        <Controls 
          tracks={tracks}
          start={start}
          setStart={setStart}
          setInCall={setInCall}
          setTrackState={setTrackState} 
          trackState={trackState}
          setEndCall={setEndCall}
          localClient={client}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
      </div>
    );
};

export default VideoCall
