import React, { useEffect } from 'react'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import {useDispatch} from 'react-redux'
import { clearVideoChatStates } from '../../redux/video-conference/video-chat/videoChat.action'
import { useHistory } from 'react-router-dom'

const VCEnd = (props) => {

    const { resetState } = props

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch(clearVideoChatStates())
    },[])

    return (
        <div>
            <Navbar />
            <div className='container py-5 px-3'>
                <h3 className='lato-fs24-bold-lh32 mb-4'>Meeting Ended</h3>
                <CustomizeButton variant="secondary-color-button" className="me-5" label="Rejoin" handleClick={resetState} />
                <CustomizeButton variant="primary-color-button" label="Home" handleClick={() => history.push('/')} />
            </div>
        </div>
    )
}

export default VCEnd