import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const AcceptLoadingButton = styled(LoadingButton)({
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(255,128,106,255)',
    borderColor: 'rgba(255,128,106,255)',
    fontFamily : "'oxygen',sans-serif",
    letterSpacing : 0,
    color:"white",
    '&:hover': {
        backgroundColor: 'rgba(255,128,106,255)',
        borderColor: 'rgba(255,128,106,255)',
    },
})

const AcceptLoadingButtonV2 = styled(LoadingButton)({
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#14213d',
    borderColor: '#14213d',
    fontFamily : "'Lato',sans-serif",
    letterSpacing : 0,
    color:"white",
    boxShadow:"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    '&:hover': {
        backgroundColor: '#14213d',
        borderColor: '#14213d',
    },
})

const CustomizeLoadingButton = (props) => {

    switch(props.variant){
        case "accept-loading-button-v2" :
            return (
                <AcceptLoadingButtonV2
                    type={props.type}
                    loading={props.loadingState}
                    loadingPosition={props.loadingPosition}
                    loadingIndicator={<CircularProgress sx={{color: "white"}} size={18} />}
                    onClick={props.onClick}
                    variant="contained" >
                    {props.label}
                </AcceptLoadingButtonV2>
            )

        case "with-start-icon-button":{
            return (
                <AcceptLoadingButtonV2
                    type={props.type}
                    loading={props.loadingState}
                    loadingPosition={props.loadingPosition}
                    loadingIndicator={<CircularProgress sx={{color: "white"}} size={18} />}
                    onClick={props.onClick}
                    startIcon={props.startIcon}
                    variant="contained" >
                    {props.label}
                </AcceptLoadingButtonV2>
            )
        }

        default :
            return (
                <AcceptLoadingButton
                    type={props.type}
                    loading={props.loadingState}
                    loadingPosition={props.loadingPosition}
                    loadingIndicator={<CircularProgress sx={{color: "white"}} size={18} />}
                    onClick={props.onClick}
                    variant="contained" >
                    {props.label}
                </AcceptLoadingButton>
            )
    }
}

export default CustomizeLoadingButton