import { isEmpty } from 'lodash'
import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { requestCoursesForSelect } from '../../services/videoConference.service'

const CourseTab = (props) => {

    const { requestCourse, courseState } = props

    const [ pageLoading, setPageLoading ] = useState(true)

    const learningAreas = courseState.response.learning_areas

    useEffect(() => {
        if(!courseState.loading && !isEmpty(courseState.response)){
            setPageLoading(false)
        }

        if(!courseState.loading && !isEmpty(courseState.error)){
            console.log(courseState.error)
            setPageLoading(false)
            if(!isEmpty(courseState.error)){
                if(courseState.error.data){
                    toast.error(courseState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[courseState])

    useEffect(() => {
        if(isEmpty(courseState.response)){
            requestCourse()
        }
    },[])

    function formattedCourseOptions(){
        let options = []
        for(const learningArea of learningAreas){
            let courses = []
            for(const course of learningArea.courses){
                courses.push({label: course.name, value: course._id})
            }
            options.push({label: learningArea.name, options: courses})
        }
        return options
    }

    function handleInputChange(value){
        props.setSelected(prevState => {
            return {
                ...prevState,
                courses : value
            }
        })
    }

    return (
        !pageLoading ?
            <div className="tab-container-300px px-3 mt-3">
                <h2 className="lato-fs-20-normal-lh32 mb-0 primary-text-color font-bold text-center mb-3">Select Course</h2>
                <Select
                    closeMenuOnSelect={false}
                    isMulti
                    menuPortalTarget={document.body}
                    styles={{ 
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        control: (styles ,{ isFocused }) => ({
                                ...styles,
                                ':hover': { borderColor: '#14213d' }, // border style on hover
                                border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                boxShadow: isFocused ? '' : "", // no box-shadow
                            }),
                        option : (styles) => ({
                            ...styles,
                            fontSize : "15px",
                            fontFamily :"'Lato',sans-serif"
                        })
                    }}
                    options={formattedCourseOptions()}
                    onChange={handleInputChange}
                    value = {props.selected.courses}
                    />
            </div> :null
    )
}

const mapStateToProps = (state) => {
    return {
        courseState : state.courseForSelect 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestCourse : () => dispatch(requestCoursesForSelect()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CourseTab)