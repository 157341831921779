import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import {connect} from 'react-redux'
import {toast} from 'react-toastify'
import { styled } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Chip from '@mui/material/Chip'
import SendIcon from '@mui/icons-material/Send'
import Box from '@mui/material/Box'

import './compose.css'
import { getEmailSuggestion, sendAction } from '../../../services/share.service'
import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import ActionPopup from '../../../components/action-popup/ActionPopup'
import { clearComposeActionStates } from '../../../redux/compose/compose.actions'
import { newMailAdded } from '../../../redux/notification/sent/sent.actions'
import SelectBoxValueById from '../../../common/select-box-value-id/SelectBoxValueById'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import Container from '../../../components/desktop-layout/container/Container'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import Header from '../../../common/header/Header'
import useDebounce from '../../../hooks/useDebounce'

const CssTextField = styled(TextField)({
    "& label" : {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
    },
    // input label when focused
    "& label.Mui-focused": {
        color: "var(--primary-color-v2) !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
        "&.Mui-focused fieldset": {
            borderColor: "var(--primary-color-v2) !important"
        }
    }
})

const Compose = (props) => {

    const {
        clearComposeActionStates,
        composeAction,
        composeActionState,
        sentActionChanges 
    } = props

    const [actionPopup, setActionPopup] = useState(false)
    const [action, setAction] = useState('')
    const [selectedActions, setSelectedActions] = useState([])
    const [hasError, setHasError] = useState(false)
    const [ loadingButton, setLoadingButton ] = useState(false)
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)
    const [searchedValue, setSearchedValue] = useState({
        value : "",
        typing: false,
        data : []
    })

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const process = [
        {
            label : "VCF",
            value : "objectives"
        },{
            label : "Goals",
            value : "goals"
        },{
            label : "Metrics",
            value : "metrics"
        },{
            label : "Learning areas",
            value : "learning_areas"
        },{
            label : "Courses",
            value : "courses"
        }
    ]

    function handleInputChange(event,value){
        setSearchedValue( ps => {
            return {
                ...ps,
                value: event.target.value,
                typing: true,
                data: []
            }
        })
        searchDebounce(event.target.value)
    }

    const searchDebounce = useDebounce((nextString) => {
        if(nextString && nextString.length > 0){
            getEmailSuggestion({search : nextString})
                .then(res => {
                    setSearchedValue(ps => {
                        return {
                            ...ps,
                            data : res.data?.users,
                            typing: false
                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setSearchedValue(ps => {
                return {
                    ...ps,
                    value : "",
                    typing : false,
                    data : []
                }
            })
        }
    }, 1000)

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleActionPopup(event,reason){
        if(reason && reason === "backdropClick") return
        else setActionPopup(!actionPopup)
    }

    function handleRemoveSelectedAction(index){
        setSelectedActions((prevState) => {
            return prevState.filter((objective, i) => index!==i)
        })
    }

    const initialValues = {
        to : [],
        subject: '',
        message : '',
        process : ''
    }

    function getUserIds(users) {
        return users.map((user) =>  user._id)
    }

    function getSelectedActionIds() {
        return selectedActions.map((selectedAction) => selectedAction.value)
    }

    const onSubmit= (values) => {
        if(isEmpty(selectedActions)){
            if(!hasError) setHasError(true)
        } else {
            setLoadingButton(true)
            if(hasError){setHasError(false)}
            const valuesCopy = {
                ...values,
                to: getUserIds(values.to),
                actions : getSelectedActionIds()
            }
            delete valuesCopy.process
            if(action === "developments"){
                composeAction("learning_areas", valuesCopy)
            }else{
                composeAction(action, valuesCopy)
            }
        }
    }

    const validationSchema = Yup.object({
        to : Yup.array()
                .of(Yup.object())
                .min(1, "Please enter the sender email Id"),
        subject: Yup.string().required("Please enter the subject"),
        message : Yup.string().required("Please enter the message"),
        process : Yup.string().required("Please select a process")
    })

    useEffect(() => {
        if(!composeActionState.loading && !isEmpty(composeActionState.response)){
            sentActionChanges()
            setLoadingButton(false)
            toast.success('Actions sent successfully',{
                position:"bottom-right",
                autoClose:3000,
                hideProgressBar:false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.goBack()
        }

        if(!composeActionState.loading && composeActionState.error){
            console.log(composeActionState.error)
            if(composeActionState.error?.data){
                toast.error(composeActionState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[composeActionState])

    useEffect(() => {
        return () => {
            clearComposeActionStates()
        }
    }, [])

    useEffect(() => {
        setSelectedActions([])
    }, [action])

    function getForm(){
        return (
            <>
                <Formik 
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    {props => {
                        const { touched, values, errors, handleBlur, handleChange, setFieldValue } = props
                        return  (
                            <Form>
                                <div>
                                    <Autocomplete
                                        multiple
                                        options={searchedValue.data || []}
                                        name='to'
                                        className="autocompelte-input-box"
                                        getOptionLabel={(suggestion) => suggestion.email.id}
                                        onInputChange={handleInputChange}
                                        onChange={(event, value) => {
                                            setFieldValue('to', value)
                                        }}
                                        freeSolo
                                        renderInput={(params) => {
                                            const inputProps = params.inputProps;
                                            inputProps.autoComplete = "off";
                                            return (
                                                <CssTextField
                                                    {...params}
                                                    inputProps={inputProps}
                                                    name='to'
                                                    onBlur={ handleBlur }
                                                    variant="outlined"
                                                    label="Email Id"
                                                    placeholder="Enter email id"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            )
                                        }}
                                    />
                                    {touched.to && isEmpty(values.to) ? 
                                        <div className="ms-1 mb-1">
                                            <span className="error-message">Please enter email id</span>
                                        </div> : null }
                                </div>
                                <div className="mt-3">
                                    <InputBox type="text"
                                        name="subject" 
                                        label="Subject"
                                        placeholder="Enter your subject"
                                        value={values.subject}
                                        onChange={ handleChange }
                                        onBlur ={ handleBlur }
                                        errorMgs={touched.subject && errors.subject ? errors.subject : null} />
                                </div>
                                <div className="mt-3">
                                    <TextAreaBox name="message"
                                        label="Message"
                                        placeholder = "Enter your message"
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errormgs={touched.message && errors.message ? errors.message : null} />
                                </div>
                                <div className="mt-3">
                                    <SelectBoxValueById options={process} 
                                        name="process"
                                        label="Process"
                                        value={action}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            setAction(e.target.value)
                                            handleChange(e)
                                            }} 
                                        errorMgs={ touched.process && errors.process ? errors.process : ""}
                                        />
                                </div>
                                {
                                    action ? 
                                        <div className='mt-2'>
                                            <Chip variant="outlined" label={ action === "objectives" ? "Add VCF" :`Add ${action}`} color="default" onClick={toggleActionPopup} />
                                        </div>
                                        : null
                                }
                                {
                                    !isEmpty(selectedActions)  ? 
                                        <div className='mt-3'>
                                            <h6 className='font-weight-bold lato-fs18-normal-lh22 primary-text-color'>{ action === "objectives" ? "Selected VCF" : `Selected ${action}`}</h6>
                                            <div className="d-flex flex-wrap">
                                                {
                                                    selectedActions.map((selectedobjective, index) => {
                                                        return (
                                                            <div key={index} className='chip'>
                                                                <span className="chip-title">{selectedobjective.label}</span>
                                                                <FontAwesomeIcon 
                                                                    size='xs' 
                                                                    icon={faTimesCircle} 
                                                                    color="gray"
                                                                    onClick={() => handleRemoveSelectedAction(index)}
                                                                    />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                
                                {
                                    hasError && isEmpty(selectedActions) ? 
                                    <div className="ms-1">
                                        <span className="error-message">Please pick atleast one process.</span>
                                    </div> : null
                                }
                                <div className={isDesktop ? "d-flex mt-4 pb-3" : "d-flex justify-content-center align-center mt-4 pb-3"}>
                                    <CustomizeLoadingButton variant="with-start-icon-button" type="submit" label="Send" startIcon={<SendIcon />} loading={loadingButton} />
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar 
                            enableProfileButton={true} 
                            enableLogout={true}
                            enableMore={true}
                            moreRight="70px" 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg" >
                                <div className='desktop-compose-container'>
                                    <div className='mt-3'>
                                        <h5 className='lato-fs22-normal-lh32 font-bold mb-0 primary-text-color'>Compose</h5>
                                    </div>
                                    <div className='mt-2'>
                                        {getForm()}
                                    </div>
                                </div>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                :
                <>
                    <Navbar 
                        variant="mobile"
                        enableLogout={true}
                        enableDrawer={true}
                        drawerOpened={drawer}
                        handleDrawerClose={toggleDrawer}
                        onDrawerClick={toggleDrawer}
                    />
                    <div className='container'>
                        <Box sx={{
                            marginLeft : {
                                sm: "10%"
                            },
                            paddingLeft : {
                                xs: "16px",
                                sm: 0
                            },
                            paddingRight :{
                                xs: "16px",
                                sm: 0
                            }
                        }}>
                            <div className='mt-3'>
                                <Header heading="Compose" handleBackArrowEvent={handleBackArrowEvent} />
                            </div>
                            <div className='pb-70' style={{maxWidth:"450px"}}>
                                {getForm()}
                            </div>
                        </Box>
                    </div>
                    <BottomNavigation />
                </>
                }
                {actionPopup ? <ActionPopup handleClose={toggleActionPopup} open={actionPopup} action={action} selectedActions={selectedActions} setSelectedActions={setSelectedActions} removeSelectedAction={handleRemoveSelectedAction}/> : null}
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        composeActionState : state.composeAction
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        composeAction : (action, payload) => dispatch(sendAction(action, payload)),
        clearComposeActionStates : () => dispatch(clearComposeActionStates()),
        sentActionChanges : () => dispatch(newMailAdded())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Compose)