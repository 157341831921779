import { Formik, FieldArray, Form, Field  } from 'formik'
import React,{useState, useEffect, useRef} from 'react'
import { useHistory, useParams, useLocation } from 'react-router'
import * as Yup from 'yup'
import { isEmpty, size } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'

import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import { 
    createCourse,
    readCourseAndCourseContentStatus, 
    specificLearningArea, 
    updateCourse 
} from '../../../services/learning.service'
import { clearCuCourse } from '../../../redux/learning/create-edit-course/CreateEditCourseAction'
import './cu-learning-course.css'
import { specificLearningAreaSuccess } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'
import { insertCourseData } from '../../../redux/learning/course/CourseAction'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import UploadFiles from '../../../components/upload-files/UploadFiles'
import Header from '../../../common/header/Header'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'

const LEARNING_COURSE_ATTACHMENT_NAMES = {
    data : "course_data",
    reference : "course_content_files"
}

const CuLearningCourse = (props) => {

    const { 
        CuCourseState, 
        createCourse,
        saveCourse,
        readCourseState,
        fetchCourse,
        saveSpecificLearning,
        clearCuCourseState,
        updateCourse,
        requestSpecificLearningArea,
        specificLearningAreaState
    } = props

    const history = useHistory()
    const params = useParams()
    const referenceFileRef = useRef()
    const formRef = useRef()

    const courseDetail = readCourseState.response?.course
    const learningArea = specificLearningAreaState.response?.learning_area

    const location = useLocation()
    const action = location.pathname.split('/')[5]
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [pageLoading, setPageLoading] = useState({
        course : true,
        learningArea : true
    })
    const [isReset,setIsReset] = useState(false)
    const [navbarMoreMenu,setNavbarMoreMenu] = useState(false)
    const [drawer, setDrawer] = useState(false)

    const initialValues = {
        name: action === "edit" && !isEmpty(courseDetail) && courseDetail.name ? (!isReset ?courseDetail.name : '' ): '',
        description: action === "edit" && !isEmpty(courseDetail) && courseDetail.description ? (!isReset ? courseDetail.description : '') :'',
        course_content_links : action === "edit" && !isEmpty(courseDetail) && !isEmpty(courseDetail.course_content_links) && courseDetail.course_content_links ? (!isReset ? getLinks(courseDetail.course_content_links) : ['']):  ['']
    }

    const validationSchema = Yup.object({
        name : Yup.string().required("Please enter the course name"),
        description : Yup.string(),
        course_content_links : Yup.array().when('name', {
            is: (value) => {
                if(!isEmpty(formRef.current?.values?.course_content_links)){
                    return isEmpty(referenceFiles?.files) && isEmpty(existingReferences) && !isEmpty(formRef.current.values.course_content_links)
                }else{
                    return isEmpty(referenceFiles?.files) && isEmpty(existingReferences)
                }
            },
            then: Yup.array().of(Yup.string().required('Pick or fill at least one file or link').url('Invalid url')),
            otherwise : Yup.array().of(Yup.string().url('Invalid url'))
        })
    })
    
    const onSubmit = (values) => {
        setLoading(true)
        if(navigator.onLine){
            if(action==="edit"){
                updateCourse(addFormData(values),params.courseId)
            }else{
                createCourse(addFormData(values),params.segmentId)
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.map((link) => (
            contentLink.push(link.url)
        ))
        return contentLink
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function addFormData(courseData){
        const courseDataCopy = JSON.parse(JSON.stringify(courseData))
        courseDataCopy.course_content_links = courseData.course_content_links.filter(item => !isEmpty(item))
        let courseFormData = new FormData()
        if(!isEmpty(existingReferences)){
            courseDataCopy.course_content_files = existingReferences.map(contentFile => {
                return contentFile._id
            })
        } else {
            courseDataCopy.course_content_files = []
        }
        courseFormData.append(LEARNING_COURSE_ATTACHMENT_NAMES.data, JSON.stringify(courseDataCopy))
        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                courseFormData.append(LEARNING_COURSE_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }
        return courseFormData
    }

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index != i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index != i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            //console.log(prevState.files)
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }

    function handleResetForm(resetForm) {
        setIsReset(true)
        resetForm()
        setReferenceFiles({files : []})
        setExistingReferences([])
    }

    useEffect(() => {
        if(!CuCourseState.loading && !isEmpty(CuCourseState.response)){
            saveSpecificLearning(CuCourseState.response)
            setLoading(false)
            // console.log("course",CuCourseState.response.learning_area.courses)
            if(action == "edit"){
                saveCourse( params.segmentId, CuCourseState.response.learning_area.courses)
            }
            toast.success(action == "edit" ? "Course updated successfully" :"Course created successfully",{
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.goBack()
        }

        if(!CuCourseState.loading && !isEmpty(CuCourseState.error)){
            console.log(CuCourseState.error)
            setLoading(false)
            if(!isEmpty(CuCourseState.error)){
                if(CuCourseState.error.data){
                    toast.error( CuCourseState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }
    },[CuCourseState])

    useEffect(() => {
        if(!readCourseState.loading && !isEmpty(readCourseState.response)){
            if(action === "edit"){
                setExistingReferences(readCourseState.response.course && !isEmpty(readCourseState.response.course.course_content_files)?
                    readCourseState.response.course.course_content_files : [])
            }
            setPageLoading( ps => {
                return{
                    ...ps,
                    course :false
                }
            })
        }

        if(!readCourseState.loading && !isEmpty(readCourseState.error)){
            console.log(readCourseState.error)
            setPageLoading( ps => {
                return{
                    ...ps,
                    course :false
                }
            })
            if(!isEmpty(readCourseState.error)){
                if(readCourseState.error.data){
                    toast.error( readCourseState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }

    },[readCourseState])

    useEffect(() => {
        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.response)){
            setPageLoading( ps => {
                return {
                    ...ps,
                    learningArea : false
                }
            })
        }

        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.error)){
            console.log(specificLearningAreaState.error)
            setPageLoading( ps => {
                return {
                    ...ps,
                    learningArea : false
                }
            })
            if(!isEmpty(specificLearningAreaState.error)){
                if(specificLearningAreaState.error.data){
                    toast.error( specificLearningAreaState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }

    },[specificLearningAreaState])

    useEffect(() => {
        if(action === "edit" ){
            if(isEmpty(readCourseState.response)){
                fetchCourse(params.courseId)
            }
        }else{
            setPageLoading( ps => {
                return {
                    ...ps,
                    course : false
                }
            })
        }

        if(isEmpty(specificLearningAreaState.response)){
            requestSpecificLearningArea(params.segmentId)
        }
        
        return () => {
            clearCuCourseState()
        }
    }, [])

    const navbarOpen = Boolean(navbarMoreMenu);

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function handleNotification(){
        history.push(`/notification-reference?selected=notification&tab=inbox&action=learnings`)
    }

    function getForm(){
        return(
            <>
                <Formik 
                    innerRef={formRef}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    {props => {
                        const { values,touched,errors, handleChange, handleBlur, handleSubmit, resetForm} = props
                        return (
                            <>
                                <Form>
                                    {/* <div className={ isDesktop ? "mt-2 w-50" : "mt-2"} > */}
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <InputBox type="text"
                                                name="name"
                                                label="Name"
                                                placeholder="Enter the course name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMgs={touched.name && errors.name ? errors.name : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* </div> */}
                                    <div className="mt-3">
                                        <TextAreaBox name ="description"
                                            placeholder="Enter the course description"
                                            label="Description"
                                            value={values.description}
                                            rows={isDesktop ? 4 : ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errormgs={touched.description && errors.description ? errors.description : ""}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <FieldArray name="course_content_links" >
                                            {fieldArrayProps => {
                                                const { push, remove, form } = fieldArrayProps
                                                const { values } = form
                                                const { course_content_links } = values
                                                return (
                                                    <>
                                                        <Grid container spacing={2}>
                                                        {
                                                            course_content_links.map((metric,index) => (
                                                                <> 
                                                                    <Grid item xs={12} md={6} sx={{marginBottom:"10px"}}>
                                                                        <div className='d-flex'>
                                                                            <Field name={`course_content_links[${index}]`}>
                                                                                {
                                                                                    props => {
                                                                                        const {field,meta} = props
                                                                                        return (
                                                                                            <>
                                                                                                <InputBox type="url" 
                                                                                                    label="Link Url"
                                                                                                    placeholder="http://www.example.com"
                                                                                                    {...field}
                                                                                                    errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                                    containerClassName="flex-fill"
                                                                                                />
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            </Field>
                                                                            {
                                                                                size(course_content_links) > 1 ?
                                                                                    <div className='d-flex ms-2 align-items-center'>
                                                                                        <div>
                                                                                            <IconButton onClick={() => remove(index)}>
                                                                                                <CloseIcon />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                </>
                                                            ))
                                                        }
                                                        </Grid>
                                                        <div className='mt-1'>
                                                            <CustomizeButton variant="secondary-start-icon-button" 
                                                                handleClick={() => push('')} 
                                                                StartIcon={<AddIcon />} 
                                                                label="Add Link" />
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        </FieldArray>
                                    </div>
                                    <div>
                                        <div className='mt-3'>
                                            { 
                                                !isEmpty(existingReferences) ? 
                                                    existingReferences.map((reference, index) => ( 
                                                        <div key={index} className="d-flex mb-2">
                                                            <p className="filename lato-fs15-normal-lh20 mb-0 one-line-wrap"> {reference.originalname}</p>
                                                            <FontAwesomeIcon className=" ms-auto me-2 secondary-color pt-cursor" 
                                                                onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                        </div>
                                                    )) 
                                                    : null
                                            }
                                            { 
                                                !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? 
                                                    referenceFiles.files.map((file,index)=>(
                                                        <div key={index} className="mb-2 d-flex">
                                                            <p className="mb-0 lato-fs15-normal-lh20 one-line-wrap">{file.name}</p>
                                                            <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                        </div>
                                                    )) 
                                                    : null
                                            }
                                        </div>
                                        <div className='mt-3'>
                                            <UploadFiles 
                                                variant={isDesktop ? "" : "mobile-view"}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    referenceFileRef.current.click();
                                                }}
                                                label="Click to upload attachment" 
                                            />
                                        </div>
                                    </div>
                                    <input 
                                        type="file"
                                        style={{display:"none"}} 
                                        ref={referenceFileRef} 
                                        multiple
                                        accept="video/mp4,video/x-m4v,video/*,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain, application/pdf,image/png, image/jpeg, image/jpg,.mp3,audio/*,csv,.xlsx,.xls"
                                        name="course_reference"
                                        onChange={(event) =>{
                                            const file = event.target.files
                                            if(file){
                                                handleReferenceFileAddition(file)
                                            }
                                        }} 
                                    />
                                    <div className="d-flex justify-content-center align-center mt-4">
                                        <CustomizeButton 
                                            className={ isDesktop ? "me-5" : "me-4" } 
                                            variant="secondary-color-button" 
                                            type="reset" 
                                            handleClick={() => handleResetForm(resetForm)} 
                                            label="Clear All"
                                        />
                                        <CustomizeLoadingButton 
                                            type="Submit" 
                                            loading={loading} 
                                            variant="accept-loading-button-v2"
                                            label={action === "edit" ? "Update Course" :"Create Course"}
                                        />
                                    </div>
                                </Form>
                            </>
                        )
                    }}
                </Formik>
            </>
        )
    }

    return (
        <>
            <Container>
                { isDesktop ? 
                    <>
                        <Navbar enableMore={true} 
                            enableLogout={true}
                            enableProfileButton={true}
                            moreRight="72px" 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen}
                            enableNotification={true}
                            notificationOnClick={handleNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {!pageLoading.course && !pageLoading.learningArea ? 
                                        <>
                                            <div className='mt-3'>
                                                <span className='primary-text-color lato-fs22-normal-lh32 font-bold'>{action === "edit" ? "Update Course" : "Create Course"}</span>
                                                <span className='secondary-text-color lato-fs16-normal-lh20'>{` in ${learningArea.name}`}</span>
                                            </div>
                                            <div className='mt-4'>
                                                {getForm()}
                                            </div>
                                        </>  :null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={handleNotification}
                        />
                        <div className="container pb-70">
                            {
                                !pageLoading.course && !pageLoading.learningArea ?  
                                    <>
                                        <div className='mt-3 px-3'>
                                            <Header handleBackArrowEvent={handleBackArrowEvent}
                                                heading={action === "edit" ? `Update Course` : `Create Course`}
                                                subHeading={` in ${learningArea.name}`} 
                                            />
                                        </div>
                                        <div className="pb-5 px-3 mt-3">
                                            {getForm()}
                                        </div>
                                    </>
                                    : null
                            }
                        </div>
                        <BottomNavigation />
                    </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = ( state ) => {
    return {
        CuCourseState : state.cuCourse,
        readCourseState : state.readCourse,
        specificLearningAreaState : state.specificLearningArea,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createCourse : (courseDetails,segmentId) => dispatch(createCourse(courseDetails,segmentId)),
        clearCuCourseState : () => dispatch(clearCuCourse()),
        updateCourse : (courseDetails,courseId) => dispatch(updateCourse(courseDetails,courseId)),
        saveSpecificLearning : (response) => dispatch(specificLearningAreaSuccess(response)),
        fetchCourse : (courseId) => dispatch(readCourseAndCourseContentStatus(courseId)),
        saveCourse : (courseId,array) => dispatch(insertCourseData(courseId,array)),
        requestSpecificLearningArea : (learningAreaId) => dispatch(specificLearningArea(learningAreaId))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CuLearningCourse)