import { REQUEST_DELETE_SEGMENT,
         DELETE_SEGMENT_SUCCESS,
         DELETE_SEGMENT_FAILURE,
         CLEAR_DELETE_SEGMENT_STATES } from './DeleteSegment.types'

export const requestDeleteSegment = () => {
    return {
        type : REQUEST_DELETE_SEGMENT
    }
}

export const deleteSegmentSuccess = (resData) => {
    return {
        type : DELETE_SEGMENT_SUCCESS,
        payload : resData
    }
}

export const deleteSegmentFailure = (error) => {
    return {
        type : DELETE_SEGMENT_FAILURE,
        payload : error
    } 
}

export const clearDeleteSegmentStates = () => {
    return {
        type : CLEAR_DELETE_SEGMENT_STATES
    }
}