import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty, toArray } from 'lodash'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import qs from 'query-string'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import { specificGoal } from '../../services/goals.service'
import BreadCrumbs from '../../components/breadcrumb/BreadCrumbs'
import ObjectiveCard from '../../components/objective-card/ObjectiveCard'
import BottomNavigation from '../../components/bottom-navgation/BottomNavigation'
import { acceptAction } from '../../services/notification.service'
import RejectPopup from '../../components/reject-popup/RejectPopup'
import ReferenceFiles from '../../components/reference-files/ReferenceFiles'
import { clearNotificationDetailState, mailActionChanges } from '../../redux/notification/notification-detail/notificationDetail.action'
import StatusChip from '../../components/status-chip/StatusChip'
import Container from '../../components/desktop-layout/container/Container'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../components/desktop-layout/description/Description'
import ProcessWallpaperIcon from '../../components/desktop-layout/process-wallpaper-icon/ProcessWallpaperIcon'
import DesktopActionButton from '../../components/desktop-layout/desktop-action-button/DesktopActionButton'
import Header from '../../common/header/Header'
import Name from '../../components/name/Name'

const GoalInboxActionDetail = (props) => {

    const { specificGoalState, requestSpecificGoal, clearNotificationDetailState, notificationActionChanges } = props

    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [ pageLoading, setPageLoading ] = useState(true)
    const [rejectPopup,setRejectPopup] = useState(false)
    const [ menu, setMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const goal = specificGoalState.response

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }
    
    useEffect(() => {
        if(isEmpty(specificGoalState.response)){
            requestSpecificGoal(params.goalId)
        }
    },[])

    useEffect(() => {
        if(!specificGoalState.loading && !isEmpty(specificGoalState.response)){
            // console.log(specificGoalState.response)
            setPageLoading(false)
        }

        if(!specificGoalState.loading && !isEmpty(specificGoalState.error)){
            setPageLoading(false)
            console.log(specificGoalState.error)
            if(specificGoalState.error?.data){
                toast.error(specificGoalState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[specificGoalState])

    function handleAcceptAction(){
        acceptAction(queryParams.actionId,"goal")
        .then((res) => {
            // console.log(res)
            if(res){
                notificationActionChanges()
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                history.goBack()
            }
        })
        .catch((error) => 
            toast.error(error.response.data.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        )
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleRejestPopup(){
        setRejectPopup(!rejectPopup)
    }

    function handleModifyAction(){
        history.push(`/notification/${params.notificationId}/modify-goal?goal_id=${goal._id}&actionId=${queryParams.actionId}`)
    }

    function getDetail(){
        return (
            <>
                <BreadCrumbs 
                    path={toArray(goal.objective_id.path)} 
                    activeId={goal.objective_id._id}
                    icon={faThLarge}
                    truncate={isMobile} />
                <div>
                    <ProcessWallpaperIcon
                        variant={ isDesktop ? "" : "mobile" }
                        wallpaper={goal.wallpaper}
                        mainContainerClassName="mt-3"
                        icon={goal.icon} />
                </div>
                {
                    goal.name ?
                        <div className='mt-3'>
                            <Name name={goal?.name} />
                        </div>
                    : null
                }
                { goal.description ?
                    <div className='mt-2'>
                        <Description description={goal.description} />
                    </div> : null 
                }
                { !isEmpty(goal.reference_files) || !isEmpty(goal.reference_links) ? 
                    <div className='mt-5'>
                        <ReferenceFiles referenceFiles={goal.reference_files} referenceLinks={goal.reference_links} />
                    </div> : null
                }
                { queryParams.status !== "pending"? 
                    <div>
                        <StatusChip status={queryParams.status} />
                    </div> : null }
            </>
        )
    }

    return (
            <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableMore={true}
                            enableProfileButton={true}
                            enableLogout={true}
                            open={open}
                            moreRight="88px"
                            menu={menu}
                            handlePopoverClose={handleMenuClose} 
                            handlePopoverOpen={handleMenuOpen}/>
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {!pageLoading && !isEmpty(goal) ? 
                                        <>
                                            <div className='mt-4'>
                                                {getDetail()}
                                            </div>
                                            {queryParams.status === "pending" ?
                                                <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} /> :null 
                                            }
                                        </> : null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className="container">
                            {!pageLoading && !isEmpty(goal) ? 
                                <>
                                    <div className='mt-3 px-3'>
                                        <Header handleBackArrowEvent={handleBackArrowEvent} />
                                    </div>
                                    <div className='mt-3 pb-70 px-3'>
                                        <div>
                                            {getDetail()}
                                            { queryParams.status === "pending" ?
                                                <div className='mt-3'>
                                                    <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} />
                                                </div> :null 
                                            }
                                        </div>
                                    </div>
                                </> : null
                            }
                        </div> 
                        <BottomNavigation/>
                    </>
                }
                {rejectPopup ? <RejectPopup handleClose={toggleRejestPopup} open={rejectPopup}
                                            actionId={queryParams.actionId}
                                            mailId={params.notificationId} /> : null }
            </Container>
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        specificGoalState : state.specificGoal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestSpecificGoal : (goalId) => dispatch(specificGoal(goalId)),
        clearNotificationDetailState : () => dispatch(clearNotificationDetailState()),
        notificationActionChanges : () => dispatch(mailActionChanges())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GoalInboxActionDetail)