import { REQUEST_COMPOSE_ACTION,
    COMPOSE_ACTION_FAILURE,  
    COMPOSE_ACTION_SUCCESS,
    CLEAR_COMPOSE_ACTION_STATES } from './compose.types'

export const requestComposeAction = () =>{
    return {
        type : REQUEST_COMPOSE_ACTION
    }
} 

export const composeActionSuccess = (resData) => {
    return {
        type : COMPOSE_ACTION_SUCCESS,
        payload : resData
    }
}

export const composeActionFailure = (error) => {
    return {
        type : COMPOSE_ACTION_FAILURE,
        payload : error
    }
}

export const clearComposeActionStates = () => {
    return {
        type : CLEAR_COMPOSE_ACTION_STATES
    }
}