import { REQUEST_GOAL_PROGRESS,  
         GOAL_PROGRESS_SUCCESS,  
         GOAL_PROGRESS_FAILURE,   
         CLEAR_GOAL_PROGRESS} from './GoalProgressTypes'

const initialState = {
    loading: false,
    response: {},
    error: null
}

const goalProgressReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_GOAL_PROGRESS : 
            return {
                ...state,
                loading: true,
                error: null
            }

        case GOAL_PROGRESS_SUCCESS : 
            return {
                ...state,
                loading : false,
                response: action.payload
            }

        case GOAL_PROGRESS_FAILURE : 
            return {
                ...state,
                loading: false,
                error : action.payload
            }

        case CLEAR_GOAL_PROGRESS : 
            return  {
                ...state,
                loading: false,
                response: {},
                error : null
            }

        default : 
            return state
    }
}

export default goalProgressReducer