import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import qs from 'query-string'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'

import './objectives.css'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { fetchObjectives } from '../../../services/fetchObjectives.service'
import ProcessHeaderCard from '../../../components/process-header-card/ProcessHeaderCard'
import { COMPONENTS_TYPE } from './objectives.utilis'
import ObjectiveAggregate from '../../../components/objective-aggregate/ObjectiveAggregate'
import SubObjectiveAggregate from '../sub-objective-aggregate/SubObjectiveAggregate'
import { clearSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import { clearObjectiveState as clearObjectiveForSelectStates } from '../../../redux/video-conference/objective/Objective.action'
import CompanyView from '../../../components/company-view/CompanyView'
import ProcessSearch from '../../../components/search/process-search/ProcessSearch'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSearchAutocomplete from '../../../components/desktop-layout/process-search-bar/ProcessSearchBar'
import { getRole } from '../../../utils/utils'
import { INDIVIDUAL_ROLE } from '../../../utils/constants'
import ObjectiveCoverImage from '../../../assets/images/objectives.png'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import SlantContainer from '../../../components/desktop-layout/slant-container/SlantContainer'
import Container from '../../../components/desktop-layout/container/Container'
import MainContainer from '../../../components/main-container/MainContainer'

const Objectives = (props) => {

    const { 
        fetchObjectives,
        objectiveStates,
        clearSpecificObjectiveState,
        specificObjectiveState,
        objectiveForSelectState,
        clearObjectiveForSelectStates
    } = props
    
    const [ pageLoading, setPageLoading ] = useState(true)
    const [ drawerOpen, setDrawerOpen ] = useState(false)
    const [ companyView, setCompanyView ] = useState(false)
    const [ searchPopup, setSearchPopup] = useState(false)
    const [ menu, setMenu ] = useState(false)

    const location = useLocation()
    const history = useHistory()
    const queryParams = qs.parse(location.search)
    const action = location.pathname.split('/')[1]
    const companyViewPath = location.pathname.split("/")[1]

    const isDesktop = useMediaQuery('(min-width:992px)')

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    function handleCreateVCFClick(){
        history.push("/vcf/create")
    }

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function handleBuildingIconEvent(){
        // toast.info("Under development", {
        //     position: "bottom-right",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined
        // })
        setCompanyView(true)
        history.push("/company-view?lvl=0")
    }

    useEffect(() => {
        //fetch objectives
        if(isEmpty(objectiveStates.response)){
            fetchObjectives()
        }

        //objective select
        if(!isEmpty(objectiveForSelectState.response)){
            clearObjectiveForSelectStates()
        }

        //specific objective
        if(!isEmpty(specificObjectiveState.response)){
            clearSpecificObjectiveState()
        }

    }, [])

    useEffect(() => {
        if(action === "vcf"){
            setCompanyView(false)
        }else{
            setCompanyView(true)
        }
    },[action])

    useEffect(() => {
        if(!objectiveStates.loading && !isEmpty(objectiveStates.response)){
            setPageLoading(false)
        }
        if(!objectiveStates.loading && !isEmpty(objectiveStates.error)){
            setPageLoading(false)
            console.log(objectiveStates.error)
            if(objectiveStates.error.data){
                toast.error(objectiveStates.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => {
                        
                    }
                })
            }
        }

        if(objectiveStates.action_changes && objectiveStates.loading){
            fetchObjectives()
        }

    }, [objectiveStates])

    function searchPopupOpen(){
        setSearchPopup(true)
    }

    function searchPopupClose(){
        setSearchPopup(false)
    }
    
    return (
        <>
            { 
                isDesktop ? 
                    <Container>
                        <Navbar
                            enableSearchBar={true}
                            searchAutocomplete={<ProcessSearchAutocomplete />}
                            enableNotification={true}
                            enableMore={true}
                            logo={true}
                            open={open}
                            menu={menu}
                            enableLogout={true}
                            enableProfileButton={true}
                            notificationOnClick={onClickNotification}
                            handlePopoverClose={handleMenuClose}
                            handlePopoverOpen={handleMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar addIcon={action === "company-view" ? false : true} addIconTooltip="Create VCF" onAddIconClick={handleCreateVCFClick} buildingIcon={Boolean(getRole() !== INDIVIDUAL_ROLE)} />
                            <ProcessMainContainer>
                                <SlantContainer punchLine='Get started!! Reimagine to let your potential breakthrough' coverImgSrc={ObjectiveCoverImage}/>
                                {
                                    pageLoading ?
                                        null 
                                        : !companyView ?
                                            (queryParams.mode === COMPONENTS_TYPE.listObjBylvl || isEmpty(queryParams)) && action === "vcf" ?
                                                <ObjectiveAggregate variant="desktop" />
                                                : <SubObjectiveAggregate variant="desktop"/>
                                            : <CompanyView/>
                                }
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </Container>
                    : <Container>
                        <Navbar
                            variant="mobile"
                            enableSearchIcon={true}
                            onSearchIconClick={searchPopupOpen}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={onClickNotification}
                            enableLogout={true}
                            logo={true}
                            enableDrawer={true}
                            drawerOpened={drawerOpen}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <MainContainer className="bg-tertiary-color-v2">
                            <ProcessHeaderCard
                                punchLine='Get started!! Reimagine to let your potential breakthrough'
                                enableBuildingIcon={Boolean(getRole() !== INDIVIDUAL_ROLE)}
                                onBuildingIconClick={handleBuildingIconEvent}
                                coverImgSrc={ObjectiveCoverImage}
                            />
                            {
                                    pageLoading ?
                                        null
                                        : !companyView ?
                                            (queryParams.mode === COMPONENTS_TYPE.listObjBylvl || isEmpty(queryParams)) && action === "vcf" ?
                                                <ObjectiveAggregate /> 
                                                : <SubObjectiveAggregate /> 
                                            : <CompanyView/>
                            }
                        </MainContainer>
                        <BottomNavigation
                            enableAddIcon={action === "company-view" ? false : true}
                            onAddIconClick={handleCreateVCFClick}
                        />
                        { 
                            searchPopup ? 
                                <ProcessSearch 
                                    handleClose={searchPopupClose} 
                                    popupState={searchPopup} 
                                    // selectedAction="vcfs"
                                /> 
                                : null
                        }
                    </Container>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        objectiveStates : state.fetchObjectivesData,
        specificObjectiveState : state.reviewSpecificObjectiveDetail,
        objectiveForSelectState : state.objectiveForSelect
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchObjectives : () => dispatch(fetchObjectives()),
        clearSpecificObjectiveState : () => dispatch(clearSpecificObjectiveForReview()),
        clearObjectiveForSelectStates : () => dispatch(clearObjectiveForSelectStates())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Objectives)