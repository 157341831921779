import React from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'

const NotificationToDetails = (props) => {

    switch(props.variant){
        case "mobile":
            return (
                <div>
                    <div className="d-flex align-items-center">
                        <h6 className="lato-fs14-normal-lh20 primary-text-color mb-0">{props.actionWay}</h6>
                        <span className="lato-fs15-normal-lh20 primary-text-color ms-auto">{moment(props.createdDate).format('Do MMM, YYYY')}</span>
                    </div>
                    {
                        !isEmpty(props.usersData) ?
                            <div className="d-flex align-items-start flex-column mt-1">
                                { props.actionWay === 'To'  ?
                                    <div>
                                        { props.usersData.map((user,index) => (
                                            <div key={index} className='mb-2'>
                                                <span className="lato-fs15-normal-lh20 primary-text-color">{`${user.user.person_name.first_name} ${user.user.person_name.last_name}`}</span>
                                                <br/>
                                                <span className="lato-fs15-normal-lh20 primary-text-color">{` <${user.user.email.id}>`}</span>
                                            </div>
                                        ))}
                                    </div> :
                                    <div>                                    
                                        <span className="lato-fs15-normal-lh20 primary-text-color">{`${props.usersData.user.person_name.first_name} ${props.usersData.user.person_name.last_name}`}</span>
                                        <br />
                                        <span className="lato-fs15-normal-lh20 primary-text-color">{` <${props.usersData.user.email.id}>`}</span>
                                    </div>
                                }
                                <div className={ props.messageContainerClassName ? `${props.messageContainerClassName} mt-2` : "mt-2"}>
                                    <p className="lato-fs15-normal-lh20 mb-0 primary-text-color">
                                        {props.message}
                                    </p>
                                </div>
                            </div> : null
                }
                </div>
            )

        default : 
            return (
                <div>
                    <div className="d-flex align-items-center">
                        <h6 className="lato-fs14-normal-lh20 primary-text-color mb-0">{props.actionWay}</h6>
                        <span className="lato-fs15-normal-lh20 primary-text-color ms-auto">{moment(props.createdDate).format('Do MMM, YYYY')}</span>
                    </div>
                    {
                        !isEmpty(props.usersData) ?
                            <div className="d-flex align-items-start flex-column mt-1">
                                { props.actionWay === 'To'  ?
                                    <div>
                                        { props.usersData.map((user,index) => (
                                            <div key={index} className='mb-2'>
                                                <span className="lato-fs17-normal-lh20 primary-text-color">{`${user.user.person_name.first_name} ${user.user.person_name.last_name}`}</span>
                                                <br/>
                                                <span className="lato-fs17-normal-lh20 primary-text-color">{` <${user.user.email.id}>`}</span>
                                            </div>
                                        ))}
                                    </div> :
                                    <div>                                    
                                        <span className="lato-fs17-normal-lh20 primary-text-color">{`${props.usersData.user.person_name.first_name} ${props.usersData.user.person_name.last_name}`}</span>
                                        <br />
                                        <span className="lato-fs17-normal-lh20 primary-text-color">{` <${props.usersData.user.email.id}>`}</span>
                                    </div>
                                }
                                <div className={ props.messageContainerClassName ? `${props.messageContainerClassName} mt-2` : "mt-2"}>
                                    <p className="lato-fs15-normal-lh20 mb-0 primary-text-color">
                                        {props.message}
                                    </p>
                                </div>
                            </div> : null
                }
                </div>
            )
    }
}

export default NotificationToDetails