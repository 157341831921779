import React from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const TextArea = styled(TextField)({
    // input label when focused
    "& label.Mui-focused": {
        color: "#14213d !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
        borderColor: "#14213d !important"
        }
    }
})

const TextAreaBox = (props) => {

    return (
        <div>
            <TextArea name={props.name} 
                    id="outlined-multiline-flexible"
                    label={props.label}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    errormgs={props.errormgs}
                    multiline
                    fullWidth
                    rows={props.rows || 3 }
                    inputProps={{ maxLength: props.maxLength }}
                    InputProps={{
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    InputLabelProps={{
                        style:{fontFamily:'"Lato",sans-serif', fontSize:"15px"}
                    }}
                    variant="outlined"
                    autoComplete="off"
                    />

                {props.errormgs ? 
                <div>
                    <span className="error-message">{props.errormgs}</span>
                </div>: null}
        </div>
    )
}

export default TextAreaBox