import { REQUEST_CULEARNING, 
         SUCCESS_CULEARNING, 
         FAILURE_CULEARNING, 
         CLEAR_CULEARNING } from './CULearningTypes'

export const requestCULearning = () =>{
    return {
        type : REQUEST_CULEARNING
    }
} 

export const CULearningSuccess = (resData) => {
    return {
        type : SUCCESS_CULEARNING,
        payload : resData
    }
}

export const CULearningFailure = (error) => {
    return {
        type : FAILURE_CULEARNING,
        payload : error
    }
}

export const clearCULearning = () => {
    return {
        type : CLEAR_CULEARNING
    }
}