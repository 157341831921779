import React,{ useState } from 'react'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
// import { useHistory } from 'react-router-dom'

import Container from '../../../components/desktop-layout/container/Container'
import LandingPageCard from '../../../components/landing-page-card/LandingPageCard'
// import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import PublicNavbar from '../public-navbar/PublicNavbar'
import Footer from '../../../components/footer/Footer'
import './product.css'
import VcfImage from '../../../assets/images/objectives.png'
import GoalImage from '../../../assets/images/goal.png'
import MetricImage from '../../../assets/images/metrics.png'
import LearningImage from '../../../assets/images/learnings.png'

const Product = (props) => {

    const [ drawer, setDrawer ] = useState(false)

    // const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    return (
        <Container>
            <PublicNavbar 
                variant={ isDesktop ? "" : "mobile" }
                logo={true} 
                enableConsult={true} 
                enableSubscribe={true} 
                // enableLogin={true}
                enableAboutUs={true}
                enableHome={true}
                enableDrawer={true}
                drawerOpened={drawer}
                handleDrawerClose={toggleDrawer}
                onDrawerClick={toggleDrawer}
                drawerHome={true}
            />
            <article>
                <section className='bg-tertiary-color-v2' style={{minHeight:"75vh"}}>
                    <div className='mx-auto py-5' style={{width:"86%"}}>
                        <Grid container md={12}>
                            <Grid item>
                                <h1 className='lato-fs36-normal-lh42 primary-text-color font-medium'>Overview</h1>
                                    <p className='mt-4 mb-0 lato-fs18-normal-lh22 primary-text-color'>Inspired by evolutionary theory of economics, science on decision making and neuroscience perspective of habit formation, I have identified the fundamental building blocks that connect people and performance. Building blocks that can explain the complexity behind why ordinary people can produce extraordinary results and why great organizations slide into obscurity in spite of great talent.</p>
                                    <p className='mt-4 mb-0 lato-fs18-normal-lh22 primary-text-color'>Building blocks that I term as “Routines” are the equivalent of biological genes; Routines express themselves as performance and can uniquely combine to generate limitless performance.</p>
                                    <p className='mt-4 mb-0 lato-fs18-normal-lh22 primary-text-color'>The Just 4 product introduces the abstract concept of Work-Person (W-P) relationship. In this circular relationship both sides propel each other to produce results and raise performance levels. The 4 Routines shape the Work-Person relationship leading to performance.</p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{marginTop:"10px"}}>
                            <Grid item md={6} xs={12}>
                                <LandingPageCard
                                    heading="VCF"
                                    detail="The VCF grid helps to reimagine the organization in terms of activities that create value. The workforce can directly relate to how their efforts create value and rapidly align to emerging changes."
                                    image={VcfImage}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LandingPageCard
                                    heading="Goal"
                                    detail="In competition results are what matter. When individuals craft their own job it encourages them to stretch, these are meaningful moments that create a state of “flow” that absorbs people into work."
                                    image={GoalImage}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LandingPageCard
                                    containerClassName="h-280px"
                                    heading="Metric"
                                    detail="Metrics are the instant and inbuilt sensors in the W-P bond. It is this chemistry that charges up performance like an Olympian to go faster, higher, stronger and together."
                                    image={MetricImage}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LandingPageCard
                                    containerClassName="h-280px"
                                    heading="Learning"
                                    detail="The process of learning when analyzed through a framework of what to learn, where, when and how throws up the inadequacy of most existing learning systems. The Just 4 model bring it together in an encompassing manner and most importantly promotes a social network for learning."
                                    image={LearningImage}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </section>
                {/* <section className='d-flex align-items-center' style={{minHeight:"75vh"}}>
                    <div className='mx-auto py-5' style={{width:"86%"}}>
                        <Grid container>
                            <Grid className='d-flex justify-content-center align-items-center' item md={6}>
                                <img style={{maxHeight: "500px"}} src="https://w7.pngwing.com/pngs/536/290/png-transparent-iphone-x-iphone-5s-mockup-others-miscellaneous-angle-rectangle-thumbnail.png" alt=""/>
                            </Grid>
                            <Grid className='d-flex flex-column justify-content-center' item md={6}>
                                <div className='px-5'>
                                    <h2 className='mb-4 lato-fs36-normal-lh42'>What to <span className='lato-fs36-normal-lh42 font-bold' style={{color: 'var(--primary-color-v2)'}}>expect from this app?</span></h2>
                                    <p className='mb-4 lato-fs18-normal-lh25'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <ul className='custom-list'>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    </ul>
                                    <CustomizeButton className="mt-3" variant="primary-color-button" label="Subscribe now" />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section className='d-flex align-items-center bg-tertiary-color-v2' style={{minHeight:"75vh"}}>
                    <div className='mx-auto py-5' style={{width:"86%"}}>
                        <Grid container>
                            <Grid className='d-flex flex-column justify-content-center' item md={6}>
                                <div className='px-5'>
                                    <h2 className='mb-4 lato-fs36-normal-lh42'>Benefits for <span className='lato-fs36-normal-lh42 font-bold' style={{color: 'var(--primary-color-v2)'}}>Organisation</span></h2>
                                    <ul className='custom-list'>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    </ul>
                                    <CustomizeButton 
                                        className="mt-3" 
                                        variant="primary-color-button" 
                                        label="Subscribe Now"
                                        handleClick={() => history.push(`/sign-up`)} 
                                    />
                                </div>
                            </Grid>
                            <Grid className='d-flex align-items-center' item md={6}>
                                <img style={{width: "100%"}} src="https://miro.medium.com/max/700/1*CbtQV5wUNTtRCQlx0p7wkA.png" alt=""/>
                            </Grid>
                        </Grid>
                    </div>
                </section>
                <section className='d-flex align-items-center' style={{minHeight:"75vh"}}>
                    <div className='mx-auto py-5' style={{width:"86%"}}>
                        <Grid container>
                            <Grid className='d-flex align-items-center' item md={6}>
                                <img style={{width: "100%"}} src="https://static.helpjuice.com/helpjuice_production/uploads/upload/image/4752/direct/1595438203904-Learning%20Organization.png" alt=""/>
                            </Grid>
                            <Grid className='d-flex flex-column justify-content-center' item md={6}>
                                <div className='px-5'>
                                    <h2 className='mb-4 lato-fs36-normal-lh42'>Benefits for <span className='lato-fs36-normal-lh42 font-bold' style={{color: 'var(--primary-color-v2)'}}>Individual</span></h2>
                                    <ul className='custom-list'>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                        <li className='mb-3 lato-fs17-normal-lh20 primary-text-color'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                                    </ul>
                                    <CustomizeButton 
                                        className="mt-3" 
                                        variant="primary-color-button" 
                                        label="Subscribe Now"
                                        handleClick={() => history.push(`/sign-up`)} 
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </section> */}
                {/* <div>
                    <Footer/>
                </div> */}
            </article>
        </Container>
    )
}

export default Product