import React,{ useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faThLarge, faListAlt, faSignal } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import FullScreenPopup from '../../full-screen-popup/FullScreenPopup'
import { processSearch } from '../../../services/objectives.service'
import StandardInputBox from '../../../common/standard-input-box/StandardInputBox'
import useDebounce from '../../../hooks/useDebounce'
import activeGoalIcon from '../../../assets/icons/active-bullseye.svg'
import { boldString } from '../../../utils/utils'
import { connect } from 'react-redux'
import { clearSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import { clearSpecificGoalStates } from '../../../redux/specific-goal/specificGoal.action'
import { clearSpecificMetricState } from '../../../redux/specific-metric/specificMetric.action'
import { clearSpecificLearningArea } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'
import { clearCourseContentStatus } from '../../../redux/learning/course/CourseAction'

const ProcessSearch = (props) => {

    const { specificObjectiveState, 
            specificGoalState, 
            specificMetricState, 
            specificLearningAreaState, 
            readCourseState,
            clearSpecificObjectiveState, 
            clearSpecificGoalState, 
            clearSpecificMetricState, 
            clearLearningAreaState, 
            clearCourseStates } = props

    const [ search, setSearch ] = useState({
        isLoading: false,
        value : "",
        data : {}
    })

    const history = useHistory()

    function handleInputChange(event){
        const targetValue = event.target.value
        setSearch(ps => {
            return {
                ...ps,
                value : targetValue,
                isLoading: true
            }
        })
        searchDebounce(targetValue)
    }

    const searchDebounce = useDebounce((nextString) => {
        if(nextString.length > 0){
            processSearch(nextString)
            .then((res) => {
                setSearch(ps => {
                    return {
                        ...ps,
                        data : res.data,
                        isLoading: false
                    }
                })
            }).catch((err) => {
                console.log(err)
            })
        }else{
            setSearch(ps => {
                return {
                    ...ps,
                    data: [],
                    isLoading: false
                }
            })
        }
    },1000)

    const handleChange = (type, value) => {
        switch(type){
            case "objectives":
                history.push(`/vcf/${value._id}?level=${value.level}`)
                break
            case "goals":
                history.push(`/goal/${value._id}`)
                break
            case "metrics":
                history.push(`/metric/${value._id}`)
                break
            case "learning_areas":
                history.push(`/learning/${value.parent_circle}/learning-area/${value._id}/${value.segment_no}`)
                break
            case "courses":
                history.push(`/learning/learning-area/course/${value._id}`)
                break
            default:
                console.log("block 6 executed")
        }
    }

    const getList = () => {
        if(search.value){
            if(search.isLoading){
                return (
                    <div className='d-flex align-items-center py-3'>
                        <CircularProgress sx={{color: 'var(--primary-color-v2)'}} size={24} />
                        <span className='ms-2 lato-fs15-normal-lh20'>Searching...</span>
                    </div>
                )
            } else {
                if(!isEmpty(search?.data?.objectives) || !isEmpty(search?.data?.goals) || !isEmpty(search?.data?.metrics) || !isEmpty(search?.data?.learning_areas) || !isEmpty(search?.data?.courses)){
                    return (
                        <>
                            {
                                search?.data?.objectives.map(objective => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={objective._id} onClick={() => handleChange("objectives", objective)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faThLarge} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(objective.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.goals.map(goal => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={goal._id} onClick={() => handleChange("goals", goal)}>
                                        <img src={activeGoalIcon} alt="icon" style={{width:"23px", height:"23px"}}/>
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(goal.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.metrics.map(metric => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={metric._id} onClick={() => handleChange("metrics", metric)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faSignal} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(metric.metric.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.learning_areas.map(learningArea => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={learningArea._id} onClick={() => handleChange("learning_areas", learningArea)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faListAlt} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(learningArea.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.courses.map(course => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={course._id} onClick={() => handleChange("courses", course)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faBook} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(course.name, search.value)}} />
                                    </div>
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <div className='py-3'>
                            <span className='lato-fs16-normal-lh20'>No process found</span>
                        </div>
                    )
                }
            }
        } else {
            return null
        }
    }

    useEffect(() => {
        if(!isEmpty(specificObjectiveState.response)){
            clearSpecificObjectiveState()
        }

        if(!isEmpty(specificGoalState.response)){
            clearSpecificGoalState()
        }

        if(!isEmpty(specificMetricState.response)){
            clearSpecificMetricState()
        }

        if(!isEmpty(specificLearningAreaState.response)){
            clearLearningAreaState()
        }

        if(!isEmpty(readCourseState.response.course)){
            clearCourseStates()
        }
    },[])

    return (
        <FullScreenPopup 
            popupState={props.popupState} 
            handleClose={props.handleClose} 
        >
            <div>
                <div className='pt-3 d-flex align-items-center'>
                    <IconButton onClick={props.handleClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <div className='flex-fill ms-2 me-3'>
                        <StandardInputBox label="Search" 
                            size="small"
                            placeholder="VCF, goal, metric, learning area and course..."
                            onChange={handleInputChange}
                            value={search.value}
                        />
                    </div>
                </div>
                <div className='px-5 py-3'>
                    {getList()}
                </div>
            </div>
        </FullScreenPopup>
    )
}

const mapStateToProps = (state) =>{
    return {
        specificObjectiveState : state.reviewSpecificObjectiveDetail,
        specificGoalState : state.specificGoal,
        specificMetricState : state.specificMetric,
        specificLearningAreaState : state.specificLearningArea,
        readCourseState : state.readCourse
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        clearSpecificObjectiveState : () => dispatch(clearSpecificObjectiveForReview()),
        clearSpecificGoalState : () => dispatch(clearSpecificGoalStates()),
        clearSpecificMetricState : () => clearSpecificMetricState(),
        clearLearningAreaState : () => clearSpecificLearningArea(),
        clearCourseStates : () => clearCourseContentStatus()
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(ProcessSearch)