import React from 'react'
import {isEmpty} from 'lodash'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CustomizeInputBox from '../../../common/customize-input-box/CustomizeInputBox'

const MobSearchBox = (props) => {

    const defaultObj = {
        margin: '8px',
        padding: '4px 12px',
        display:"flex",
        alignItems:"center",
        background:"white",
        borderRadius: "5px",
    }

    const containerProps = !isEmpty(props.containerProps) ? {...defaultObj , ...props.containerProps} : defaultObj

  return (
    <Box 
        sx={containerProps}
    >
        <SearchIcon />
        <CustomizeInputBox
            className="lato-fs17-normal-lh20 ms-1 bg-transparent"
            type="text"
            name={props.name}
            placeholder={props.placeholder ||  "" }
            onChange={props.onChange}
            value={props.value}
            autoFocus={props.autoFocus}
        />
        {
            props.showCloseIcon ?
                <IconButton onClick={props.onSearchCloseClick} >
                    <CloseIcon sx={{fontSize: 14}} />
                </IconButton>
                : null
        }
    </Box>
  )
}

export default MobSearchBox