import { REQUEST_GOALS_FOR_SELECT,
        GOAL_FOR_SELECT_SUCCESS,
        GOAL_FOR_SELECT_FAILURE,
        CLEAR_GOAL_SELECT_STATE } from './goalSelect.types'

const initialState = {
    loading: false,
    goal_action_changes : false,
    response: {},
    error: null
}

const goalSelectReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_GOALS_FOR_SELECT:
            return {
                ...state,
                loading: true,
                goal_action_changes : false,
                error: null
            }

        case GOAL_FOR_SELECT_SUCCESS : 
            return {
                ...state,
                loading: false,
                goal_action_changes : false,
                response : action.payload
            }

        case GOAL_FOR_SELECT_FAILURE : 
            return {
                ...state,
                loading: false,
                goal_action_changes : false,
                error: action.payload
            }

        case CLEAR_GOAL_SELECT_STATE : 
            return {
                ...state,
                loading: false,
                goal_action_changes : false,
                response: {},
                error: null
            }

        default : 
            return state
    }
}

export default goalSelectReducer