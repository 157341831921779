import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import useMediaQuery from '@mui/material/useMediaQuery'

const ReferenceFiles = (props) => {

    const isDesktop = useMediaQuery('(min-width:992px)')

    switch(props.variant){
        case "modify-reference" :
            return(
                <ul className='ps-0'>
                    { !isEmpty(props.referenceFiles) && props.referenceFiles.map((file) => (
                        <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={file._id}>
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faPaperclip} className="fs-15 me-1 acent-color" />
                                <a href={file.url} style={{wordBreak:"break-word"}}
                                    className={isDesktop ? "link lato-fs17-normal-lh20 primary-text-color pb-1 two-line-wrap" : "link two-line-wrap primary-text-color lato-fs16-normal-lh20 pb-1"} 
                                    target="_blank">{file.originalname}</a>
                            </div>
                        </li>
                    ))}
                    { !isEmpty(props.newReferenceFiles) && props.newReferenceFiles.map((filename) => (
                        <>
                            <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={uuidv4()}>
                                <div className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={faPaperclip} className="fs-15 me-1 acent-color" />
                                    <span className={ isDesktop ? "link lato-fs17-normal-lh20 primary-text-color pb-1 two-line-wrap" : "link two-line-wrap primary-text-color lato-fs16-normal-lh20 pb-1"} target="_blank">{filename}</span>
                                </div>
                            </li>
                        </>
                    ))}
                    { !isEmpty(props.referenceLinks) && props.referenceLinks.map((link) => (
                        <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={link._id}>
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faLink} className="fs-15 me-1 acent-color" />
                                <a href={link.url} style={{wordBreak:"break-word"}}
                                    className={isDesktop ? "link lato-fs17-normal-lh20 primary-text-color pb-1 two-line-wrap" : "link lato-fs16-normal-lh20 primary-text-color pb-1 two-line-wrap"} 
                                    target="_blank">{link.originalname ? link.originalname : link.url }</a>
                            </div>
                        </li>
                    ))}
                    { !isEmpty(props.newReferenceLinks) && props.newReferenceLinks.map((link) => (
                        <>
                            {!isEmpty(link.url) ?
                                <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={uuidv4()}>
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faLink} className="fs-15 me-1 acent-color" />
                                        <a href={link} style={{wordBreak:"break-word"}}
                                            className={isDesktop ? "link lato-fs17-normal-lh20 primary-text-color pb-1 two-line-wrap" : "link lato-fs16-normal-lh20 primary-text-color pb-1 two-line-wrap"} 
                                            target="_blank">{link.originalname ? link.originalname : link.url}</a>
                                    </div>
                                </li> : null}
                        </>
                    ))}
                </ul>
            )
        
        default : 
            return (
                <div>
                    <ul className='ps-0'>
                        { !isEmpty(props.referenceFiles) && props.referenceFiles.map((file) => (
                            <li style={{listStyle:"none",fontSize:"0", marginBottom:"12px"}} key={file._id}>
                                <div className="d-flex align-items-center pb-1">
                                    <FontAwesomeIcon icon={faPaperclip} className="lato-fs18-normal-lh22 me-2 acent-color" />
                                    <a href={file.url} style={{wordBreak:"break-word"}} className={isDesktop ? "link lato-fs18-normal-lh22 pb-0 two-line-wrap" : "link lato-fs16-normal-lh20 pb-0 two-line-wrap"} target="_blank">{file.originalname}</a>
                                </div>
                            </li>
                        ))}
                        { !isEmpty(props.referenceLinks) && props.referenceLinks.map((link) => (
                            <li style={{listStyle:"none",fontSize:"0", marginBottom:"12px"}} key={link._id}>
                                <div className="d-flex align-items-center pb-1">
                                    <FontAwesomeIcon icon={faLink} className="lato-fs18-normal-lh22 me-2 acent-color" />
                                    <a href={link.url} style={{wordBreak:"break-word"}}
                                        className={isDesktop ? "link two-line-wrap lato-fs18-normal-lh22 pb-0" : "link lato-fs16-normal-lh20 two-line-wrap pb-0"} 
                                        target="_blank">
                                            { link.originalname ? link.originalname : link.url}
                                    </a>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )
    }
}

export default ReferenceFiles