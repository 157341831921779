import { REQUEST_SUBSCRIPTION, 
        FETCH_SUBSCRIPTION_SUCCESS,
        FETCH_SUBSCRIPTION_FAILURE,
        CLEAR_SUBSCRIPTION_STATE} from './Subscription.types'

export const requestSubscription = () => {
    return {
        type : REQUEST_SUBSCRIPTION
    }
}

export const fetchSubscriptionSuccess = (response) => {
    return {
        type : FETCH_SUBSCRIPTION_SUCCESS,
        payload : response
    }
}

export const fetchSubscriptionFailure = (error) => {
    return {
        type : FETCH_SUBSCRIPTION_FAILURE,
        payload : error
    }
}

export const clearSubscriptionState = () => {
    return {
        type : CLEAR_SUBSCRIPTION_STATE
    }
}