import { REQUEST_DELETE_LEARNING_AREA,
         DELETE_LEARNING_AREA_SUCCESS,
         DELETE_LEARNING_AREA_FAILURE,
         CLEAR_DELETE_LEARNING_AREA } from './DeleteLearningAreaTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const deleteLearningAreaReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_DELETE_LEARNING_AREA : 
            return {
                ...state,
                loading : true,
                error : null
            }

        case DELETE_LEARNING_AREA_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }
        
        case DELETE_LEARNING_AREA_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_DELETE_LEARNING_AREA : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default :
            return state
    }
}

export default deleteLearningAreaReducer