import React from 'react'
import { toArray } from 'lodash'

const PlanCard = (props) => {

    const className = props.containerClassName ? `card plan-card ${props.containerClassName}` : 'card plan-card'

    return (
        <div className={className} key={props.key}>
            <h3 className="lato-fs18-normal-lh22 mb-1">{props.name}</h3>
            <div className='mb-1'>
                <span className="lato-fs22-normal-lh32 font-bold mb-0 primary-color">{props.currency}</span>
                <span className="lato-fs22-normal-lh32 font-bold mb-0 primary-color">{props.amount}</span>
                <span className="lato-fs22-normal-lh32 font-bold mb-0 primary-color">{props.period}</span>
            </div>
            <p className="secondary-color lato-fs15-normal-lh20 mb-1">{props.description}</p>
            {props.notes ?
                <>
                    <span className="lato-fs13-normal-lh18 font-bold">Note</span>
                    {props.notes.map((note,index) => (
                        <span className="lato-fs12-normal-lh15 primary-color" key={index}>{note}</span>
                    ))}
                </> : null}
        </div>
    )
}

export default PlanCard