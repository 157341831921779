import { REQUEST_SENT_ACTION_FETCH,
    FETCH_SENT_ACTION_SUCCESS,
    FETCH_SENT_ACTION_FAILURE,
    CLEAR_FETCH_SENT_ACTION_STATES,
    NEW_MESSAGE_ADDED } from './sent.types'

export const requestSentActionFetch = () =>{
    return {
        type : REQUEST_SENT_ACTION_FETCH
    }
} 

export const fetchSentActionSuccess = (resData) => {
    return {
        type : FETCH_SENT_ACTION_SUCCESS,
        payload : resData
    }
}

export const fetchSentActionFailure = (error) => {
    return {
        type : FETCH_SENT_ACTION_FAILURE,
        payload : error
    }
}

export const newMailAdded = () => {
    return {
        type : NEW_MESSAGE_ADDED
    }
}

export const clearFetchSentActionStates = () => {
    return {
        type : CLEAR_FETCH_SENT_ACTION_STATES
    }
}