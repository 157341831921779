import { REQUEST_REFERENCE,
        REFERENCE_SUCCESS,
        REFERENCE_FAILURE,
        CLEAR_REFERENCE_CLEAR,
        INSERT_NOTES_IN_REFERENCE, 
        REMOVE_REFERENCE} from './Reference.types'
import { insertNotes, removeReferenceAtIndex } from './Reference.utils'

const initialState = {
    loading: false,
    response : {},
    error : null
}

const refernceReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_REFERENCE : 
            return {
                ...state,
                loading: true,
                error : null
            }

        case REFERENCE_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case REFERENCE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }
        
        case CLEAR_REFERENCE_CLEAR : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        case INSERT_NOTES_IN_REFERENCE : 
            return {
                ...state,
                response : {
                    ...state.response,
                    references : insertNotes(state.response.references, action.payload)
                }
            }

        case REMOVE_REFERENCE : 
            return {
                ...state,
                response :{
                    ...state.response,
                    references : removeReferenceAtIndex(state.response.references,
                                            action.payload.groupIndex,
                                            action.payload.referenceType,
                                            action.payload.referenceIndex )
                }
            }


        default :
            return state
    }
}

export default refernceReducer