import { REQUEST_METRIC_DATA,
         METRIC_DATA_SUCCESS,
         METRIC_DATA_FAILURE,
         METRIC_ACTION_CHANGES,
         CLEAR_METRICS } from './FetchMetricTypes'

const initialState = {
    loading : false,
    action_changes : false,
    response : {},
    error: null
}

const fetchMetricReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_METRIC_DATA :
            return {
                ...state,
                loading:true,
                action_changes : false,
                error:null
            }

        case METRIC_DATA_SUCCESS : 
            return {
                ...state,
                loading: false,
                action_changes : false,
                response: action.payload
            }
        
        case METRIC_DATA_FAILURE : 
            return {
                ...state,
                loading: false,
                action_changes : false,
                error: action.payload
            }

        case METRIC_ACTION_CHANGES : 
            return {
                ...state,
                loading : true,
                action_changes : true
            }

        case CLEAR_METRICS : 
            return {
                loading : false,
                response : {},
                error: null
            }

        default : 
            return state
    }
}

export default fetchMetricReducer