import ProtectedWebClient from '../utils/protected-web-client'
import {
    requestComposeAction,
    composeActionSuccess,
    composeActionFailure
} from '../redux/compose/compose.actions'

export const getEmailSuggestion = (payload) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post('/search/user', payload)
            .then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
    })
} 

export const sendAction = (action, payload) => {
    return async (dispatch) => {
        try {
            dispatch(requestComposeAction())
            const response = await ProtectedWebClient.post(`/mail/compose?action=${action}`, payload)
            dispatch(composeActionSuccess(response.data))
        } catch (error) {
            dispatch(composeActionFailure(error))
        }
    }
}