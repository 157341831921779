import { 
    REQUEST_MODIFY_LEARNING_AREA,
    MODIFY_LEARNING_AREA_SUCCESS,
    MODIFY_LEARNING_AREA_FAILURE,
    CLEAR_MODIFY_LEARNING_AREA,
    SAVE_LEARNING_AREA_ICON,
    SAVE_COURSE_CONTENT_FILES,
    REMOVE_COURSE_CONTENT_FILES,
    SAVE_MODIFY_LEARNING_VALIDATION_ERROR,
    REMOVE_MODIFY_LEARNING_VALIDATION_ERROR
} from './ModifyLearningArea.types'

export const requestModifyLearningArea = () => {
    return {
        type : REQUEST_MODIFY_LEARNING_AREA
    }
}

export const modifyLearningAreaSuccess = (response) => {
    return {
        type : MODIFY_LEARNING_AREA_SUCCESS,
        payload : response
    }
}

export const saveLearningAreaIcon = (iconPreview, file) => {
    return {
        type : SAVE_LEARNING_AREA_ICON,
        payload : {
            iconPreview,
            file
        }
    }
}

export const saveCourseContentFiles = (payload) => {
    return {
        type : SAVE_COURSE_CONTENT_FILES,
        payload : payload
    }
}

export const removeCourseContentFiles = (id) => {
    return {
        type : REMOVE_COURSE_CONTENT_FILES,
        payload : id
    }
}

export const saveLearningValidationError = (payload) => {
    return {
        type : SAVE_MODIFY_LEARNING_VALIDATION_ERROR,
        payload : payload
    }
}

export const removeLearningValidationError = (id) => {
    return {
        type : REMOVE_MODIFY_LEARNING_VALIDATION_ERROR,
        payload : id
    }
}

export const modifyLearningAreafailure = (error) => {
    return {
        type : MODIFY_LEARNING_AREA_FAILURE,
        payload : error
    }
}

export const clearModifyLearningArea = () => {
    return {
        type : CLEAR_MODIFY_LEARNING_AREA
    }
}