import { REQUEST_COMPUTE_METRIC_PROGRESS, 
         COMPUTE_METRIC_PROGRESS_SUCCESS,
         COMPUTE_METRIC_PROGRESS_FAILURE,
         CLEAR_COMPUTE_METRIC_PROGRESS } from './ComputeMetricProgressTypes'

export const requestComputeMetricProgress = () => {
    return {
        type : REQUEST_COMPUTE_METRIC_PROGRESS
    }
}

export const successComputeMetricProgress = (resData) => {
    return {
        type : COMPUTE_METRIC_PROGRESS_SUCCESS,
        payload : resData
    }
}

export const failureComputeMetricProgress = (error) => {
    return {
        type : COMPUTE_METRIC_PROGRESS_FAILURE,
        payload : error
    }
}

export const clearComputeMetricProgress = () => {
    return {
        type : CLEAR_COMPUTE_METRIC_PROGRESS
    }
}