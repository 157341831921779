import { 
    SAVE_REMOTE_USER, 
    REMOVE_REMOTE_USER,
    UPDATE_REMOTE_USER,
    CLEAR_VIDEO_CHAT_STATES, 
    SAVE_SCREEN_SHARING_REMOTE_USER,
    REMOVE_SCREEN_SHARING_REMOTE_USER,
    CHANGING_USER,
    SAVE_LOCAL_VC_DATA
} from './videoChat.types'

import { 
    removeUser, 
    insertUserAndProfile, 
    insertUser, 
    updateUser, 
    removeUserAndProfile, 
    changingRemoteUser 
} from './videoChat.utils'

const initialState = {
    localVC : {},
    remoteUsers: [
        // structure
        // {
        //     user : {},
        //     profileInfo : {}
        // }
    ],
    screenSharingUser : {
        // structure
        // {
        //     user : {},
        //     profileInfo : {}
        // }
    }
}

const videoChatReducer = (state=initialState, action) => {
    switch(action.type){

        case SAVE_LOCAL_VC_DATA:
            return {
                ...state,
                localVC : action.payload
            }

        case SAVE_REMOTE_USER:
            return {
                ...state,
                remoteUsers : insertUserAndProfile(state.remoteUsers, action.payload.user, action.payload.profileInfo)
            }

        case REMOVE_REMOTE_USER:
            return {
                ...state,
                remoteUsers : removeUserAndProfile(state.remoteUsers, action.payload)
            }

        case UPDATE_REMOTE_USER:
            return {
                ...state,
                remoteUsers : updateUser(state.remoteUsers, action.payload)
            }

        case SAVE_SCREEN_SHARING_REMOTE_USER:
            return {
                ...state,
                // screenSharingUsers : insertUser(state.screenSharingUsers, action.payload)
                screenSharingUser : action.payload
            }

        case REMOVE_SCREEN_SHARING_REMOTE_USER:
            return {
                ...state,
                // screenSharingUsers : removeUser(state.screenSharingUsers, action.payload)
                screenSharingUser : {}
            }

        case CLEAR_VIDEO_CHAT_STATES:
            return {
                ...state,
                localVC : {},
                remoteUsers : [],
                screenSharingUser : {}
            }

        case CHANGING_USER : 
            return {
                ...state,
                remoteUsers : changingRemoteUser(state.remoteUsers, action.payload)
            }

        default :
            return state
    }
}

export default videoChatReducer