import React from 'react'
import { useParams } from 'react-router-dom'
import { MicOffOutlined, MicOutlined, VideocamOutlined, VideocamOffOutlined } from "@mui/icons-material";
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import {AgoraVideoPlayer, createMicrophoneAndCameraTracks} from "agora-rtc-react";

import './vc-join.css'

import { fetchRtcToken } from '../../services/videoConference.service'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import { saveLocalVCData } from '../../redux/video-conference/video-chat/videoChat.action'

const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VCJoin = (props) => {

    const {
        setInCall,
        trackState,
        setTrackState
    } = props;

    const dispatch = useDispatch()

    const params = useParams();

    const { ready, tracks } = useMicrophoneAndCameraTracks();

    function handleJoinTrigger(){
        fetchRtcToken(params.channelId).then(res => {
            dispatch(saveLocalVCData(res.data))
            tracks[0].close()
            tracks[1].close()
            setInCall(true)
        }).catch(err => {
            console.log(err)
            toast.error(err?.data?.error ? err?.data?.error : 'Something went wrong!' , {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    //TODO clear error message in redux state
                }
            })
        })
    }

    const mute = async (type) => {
        if (type === "audio") {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (type === "video") {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
        }
    };
    
    return (
        <>
            <Navbar />
            <div className="px-4 d-flex align-items-center mx-auto" style={{height: 'calc(100vh - 64px)', maxWidth: '1200px'}}>
                <Grid container spacing={4}>
                    <Grid xs={12} md={8} item>
                        {
                            ready ?
                                <div className="vid-preview-container">
                                    <AgoraVideoPlayer
                                        className='vid-preview-player'
                                        videoTrack={tracks[1]}
                                    />
                                    <div className="vid-preview-controls">
                                        <div className='px-3 py-2' style={{backgroundColor: 'rgba(255, 255, 255, 0.35)', borderTopLeftRadius: '1rem'}}>
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    backgroundColor: 'white', 
                                                    '&:hover' : {backgroundColor: 'white'}
                                                }}
                                                onClick={() => mute("audio")}
                                            >
                                                {
                                                    trackState.audio ?
                                                        <MicOutlined sx={{color: 'var(--primary-color-v2)'}} />
                                                        : <MicOffOutlined sx={{color: 'var(--primary-color-v2)'}} />
                                                }
                                            </IconButton>
                                            <IconButton 
                                                size="small"
                                                sx={{
                                                    backgroundColor: 'white', 
                                                    marginLeft: '0.75rem', 
                                                    '&:hover' : {backgroundColor: 'white'}
                                                }}
                                                onClick={() => mute("video")}
                                            >
                                                {
                                                    trackState.video ? 
                                                        <VideocamOutlined sx={{color: 'var(--primary-color-v2)'}} /> 
                                                        : <VideocamOffOutlined sx={{color: 'var(--primary-color-v2)'}} />
                                                }
                                            </IconButton>
                                        </div>
                                    </div>
                                </div> 
                                : null
                        }
                    </Grid>
                    <Grid className='d-flex justify-content-center align-items-center' xs={12} md={4} item>
                        <div className='d-flex flex-column align-items-center'>
                            <h5 className="lato-fs-20-normal-lh32 primary-text-color font-bold">Ready to join?</h5>
                            <CustomizeButton handleClick={handleJoinTrigger} label="Join Now" variant="primary-color-button" />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default VCJoin