import { REQUEST_FETCH_OBJECTIVES,
        FETCH_OBJECTIVES_SUCCESS,
        FETCH_OBJECTIVES_FAILURE,
        CLEAR_FETCH_OBJECTIVES, 
        OBJECTIVE_ACTION_CHANGES} from './FetchObjectivesTypes'

const initialState = {
    loading: false,
    action_changes: false,
    response: {},
    error: null
}

const fetchObjectivesReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_FETCH_OBJECTIVES :
            return {
                ...state,
                loading: true,
                action_changes: false,
                error: null
            }

        case FETCH_OBJECTIVES_SUCCESS : 
            return {
                ...state,
                loading: false,
                action_changes: false,
                response: action.payload
            }

        case FETCH_OBJECTIVES_FAILURE : 
            return {
                ...state,
                loading: false,
                action_changes: false,
                error: action.payload
            }

        case OBJECTIVE_ACTION_CHANGES : 
            return {
                ...state,
                loading: true,
                action_changes: true,
            }

        case CLEAR_FETCH_OBJECTIVES : 
            return {
                ...state,
                loading: false,
                action_changes: false,
                response: {},
                error: null
            }

        default : 
            return state
    }
}

export default fetchObjectivesReducer