import { requestMetricData , 
         fetchMetricSuccess,
         fetchMetricFailure } from '../redux/fetch-metric/FetchMetricAction'
import { failureGoalProgress, requestGoalProgress, successGoalProgress } from '../redux/goal-progress/fetch-metric/GoalProgressAction'
import ProtectedWebClient from '../utils/protected-web-client'

export const fetchMetrics = () => {
    return async (dispatch) => {
        try{
            dispatch(requestMetricData())
            const response = await ProtectedWebClient.post("/graph/aggregate", {})
            dispatch(fetchMetricSuccess(response.data))
        }catch(error){
            dispatch(fetchMetricFailure(error.response))
        }
    }
}

export const fetchMetricForProgress = () => {
    return async (dispatch) => {
        try {
            dispatch(requestGoalProgress())
            const response = await ProtectedWebClient.get("/graph")
            dispatch(successGoalProgress(response.data))
        }catch(err){
            dispatch(failureGoalProgress(err.response))
        }
    }
}
