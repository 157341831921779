import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import qs from 'query-string'
import { useLocation, useHistory } from 'react-router'
import { toast } from 'react-toastify'

import './reset-password.css'
import InputBox from '../../../common/Input-box/InputBox'
import { getAuthToken } from '../../../utils/utils'
import { resetPassword } from '../../../services/IndividualAuthentication.service'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'

const ResetPassword = () => {

    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)

    const [ drawerOpen, setDrawerOpen ] = useState(false)
    const [ menu, setMenu ] = useState(false)

    const isDesktop = useMediaQuery('(min-width:992px)')

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    const open = Boolean(menu);

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenu(null);
    }

    const initialValues = {
        password : '',
        new_password : ''
    }

    const onSubmit = (values) => {
        if(Boolean(getAuthToken())){
            const token = getAuthToken()
            const valuesCopy = {...values}
            delete valuesCopy.password
            valuesCopy.auth_token = token
            resetPassword(valuesCopy)
            .then((res) => {
                if(res){
                    history.goBack()
                    toast.success("Your password reset successfully", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }).catch((err) => {
                if(err && err.response){
                    if(err.response.data){
                        toast.error(err.response.data.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }
            })
        }else{
            if(queryParams){
                const valuesCopy = {...values} 
                delete valuesCopy.password
                valuesCopy.token = queryParams.token
                resetPassword(valuesCopy)
                .then((res) => {
                    if(res){
                        toast.success("Your password reset successfully", {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        history.push("/")
                    }
                }).catch((err) => {
                    console.log(err.response.data)
                    if(err && err.response){
                        if(err.response.data){
                            toast.error(err.response.data.error, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    }
                })
            }
        }
    }

    const validationSchema = Yup.object({
        password: Yup.string()
                .required("Please enter new password")
                .matches( /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'),
        new_password: Yup.string()
                .required('Please enter confirm password')
                .when("password",{
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Both password need to be the same"
                    )
                })
    })

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    const getForm = () => {
        return (
            <div className='reset-password-container'>
                <h5 className="text-center font-bold lato-fs24-bold-lh32 mt-5">Password Reset</h5>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mt-4">
                        <InputBox 
                            label="New Password"
                            type="password"
                            name="password"
                            Placeholder="Enter password"
                            value={formik.values.password}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errorMgs={ formik.touched.password && formik.errors.password ? formik.errors.password : ""}
                        />
                    </div>
                    <div className="mt-3">
                        <InputBox 
                            label="Confirm Password"
                            type="password"
                            name="new_password"
                            Placeholder="Enter confirm password"
                            value={formik.values.new_password}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errorMgs={ formik.touched.new_password && formik.errors.new_password ? formik.errors.new_password : ""}
                        />
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                        <CustomizeButton variant="primary-color-button" type="submit" label="Reset Password" />
                    </div>
                </form>
            </div>
        )
    }

    return (
        <Container>
            {
                isDesktop ?
                    <>
                        <Navbar
                            enableNotification={Boolean(getAuthToken())}
                            notificationOnClick={onClickNotification}
                            enableLogout={Boolean(getAuthToken())}
                            enableProfileButton={Boolean(getAuthToken())}
                            enableMore={Boolean(getAuthToken())}
                            moreRight="133px"
                            open={open}
                            menu={menu}
                            handlePopoverClose={handleMenuClose}
                            handlePopoverOpen={handleMenuOpen}
                        />
                        <ProcessSectionContainer>
                            {
                                Boolean(getAuthToken()) ?
                                    <>
                                        <LeftVerticalMenuBar />
                                        <ProcessMainContainer variant="noBackground" >
                                            {getForm()}
                                        </ProcessMainContainer>
                                    </> :
                                    getForm()
                            }
                        </ProcessSectionContainer>
                    </>
                    : <>
                        <Navbar
                            variant="mobile"
                            enableNotificationsIcon={Boolean(getAuthToken())}
                            onNotificationsIconClick={onClickNotification}
                            enableLogout={Boolean(getAuthToken())}
                            enableDrawer={Boolean(getAuthToken())}
                            drawerOpened={drawerOpen}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className='container'>
                            {getForm()}
                        </div>
                        {
                            Boolean(getAuthToken()) ?
                                <BottomNavigation />
                                : null
                        }
                    </>
            }
        </Container>
    )
}

export default ResetPassword