import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'

import wallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png'
import StandardSelectBoxById from '../../common/standard-select-box-by-id/StandardSelectBoxById'
import './course-panel-with-progress-status.css'

const CoursePanelWithProgressStatus = (props) => {

    const cardClassName = props.cardClassName ? props.cardClassName : ""  

    return (
        <Card className={cardClassName}>
            <CardMedia component="img" 
                image={props.preview ? props.preview : wallpaperPlaceholder} 
                alt="image" height="140px" />
            <CardContent 
                sx={{
                    padding:"10px",
                    '&:last-child':{
                        paddingBottom:"10px"
                    }
                }}
            >
                <div 
                    className='d-flex justify-content-between align-items-center' 
                    style={{height:"60px"}}
                >
                    <a 
                        className='link mb-0 lato-fs15-normal-lh20 course-content-three-line-wrap' 
                        target="blank" 
                        style={{ wordBreak: "break-word" }}
                        href={props.url} >{props.linkName}</a>
                    <div>
                        <form className="status-container ms-2">
                            <StandardSelectBoxById 
                                {...props.progressSelectBoxProps}
                            />
                        </form>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default CoursePanelWithProgressStatus