import React,{ useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isEmpty, size } from 'lodash'
import { connect } from 'react-redux'
import qs from 'query-string'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import {toast} from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import ErrorIcon from '@mui/icons-material/Error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faPaperclip, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

import { specificLearningArea } from '../../../services/learning.service'
import { clearModifyLearningArea, modifyLearningAreaSuccess, removeCourseContentFiles, saveLearningValidationError, removeLearningValidationError } from '../../../redux/notification/modify-learning-area/ModifyLearningArea.action'
import ModifyLearningAreaForm from './ModifyLearningAreaForm'
import ModifyCourseForm from './ModifyCourseForm'
import './modify-learning.css'
import DevelopmentPopup from '../../../components/development-popup/DevelopmentPopup'
import {learningAreaValidationSchema} from './ModifyLearning.utils'
import {developmentModificationPromise} from '../../../services/notification.service'
import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import { mailActionChanges } from '../../../redux/notification/notification-detail/notificationDetail.action'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../../components/desktop-layout/description/Description'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import Header from '../../../common/header/Header'
import LearningCard from '../../../components/learning-card/LearningCard'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'

const COMPONENTS = {
    learningArea : 'learningArea',
    course : 'course'
}

const MODIFY_LEARNING_ATTACHMENT_NAMES = {
    data : "action_data",
    contentFile : "action_course_content",
    icon : "action_icon"
}

const ModifyLearningArea = (props) => {

    const { 
        modifyLearningAreaState, 
        requestSpecificLearningArea, 
        saveUpdatedLearningArea,
        removeContentFiles, 
        removeValidationError,
        notificationStatusChanges,
        saveValidationError,
        clearModifyLearningAreaStates 
    } = props

    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ editForm, setEditForm ] = useState(false)
    const [ activeCard, setActiveCard ] = useState({})
    const [ learningCirclePopup, setLearningCirclePopup ] = useState(false)
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)
    const [ loadingButton, setLoadingButton ] = useState(false)

    const learningArea = modifyLearningAreaState.response?.learning_area

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function toggleEditForm(){
        setEditForm( ps => !ps )
    }

    function toggleLearningCirclepopup(){
        setLearningCirclePopup(ps => !ps)
    }

    useEffect(() => {
        if(isEmpty(modifyLearningAreaState.response)){
            requestSpecificLearningArea(queryParams.development_id)
        }
        return () => {
            clearModifyLearningAreaStates()
        }
    },[])

    function getPayload(){
        const learningAreaCopy = JSON.parse(JSON.stringify(learningArea))

        // date convertions
        learningAreaCopy.start_date = (moment(learningAreaCopy.start_date).startOf('day')).toISOString()
        learningAreaCopy.end_date = (moment(learningAreaCopy).endOf('day')).toISOString()

        const formData = new FormData()

        // data
        formData.append(MODIFY_LEARNING_ATTACHMENT_NAMES.data, JSON.stringify(learningAreaCopy))

        // icon
        if(!isEmpty(modifyLearningAreaState.icon)){
            formData.append(MODIFY_LEARNING_ATTACHMENT_NAMES.icon, modifyLearningAreaState.icon.file)
        }

        // content files
        if(!isEmpty(modifyLearningAreaState.courseContentFiles)){
            for(const courseContentFilesObj of modifyLearningAreaState.courseContentFiles){
                if(size(courseContentFilesObj.files)){
                    for(const contentFile of courseContentFilesObj.files) {
                        formData.append(MODIFY_LEARNING_ATTACHMENT_NAMES.contentFile, contentFile)
                    }
                }
            }
        }

        return formData
    }

    function onLearningCircleSelected(circleSegment){
        const payload = getPayload()
        setLoadingButton(true)
        developmentModificationPromise(queryParams.actionId, payload, circleSegment)
        .then((res) => {
            if(res){
                notificationStatusChanges()
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.goBack()
                setLoadingButton(false)
            }
        })
        .catch((error) => 
            toast.error(error.response.data.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        )
    }

    useEffect(() => {
        if(!modifyLearningAreaState.loading && !isEmpty(modifyLearningAreaState.response)){
            if(isEmpty(activeCard)){
                setActiveCard(ps => {
                    return {
                        currComponent : COMPONENTS.learningArea,
                        id : learningArea._id
                    }
                })
            }
        }

        if(!modifyLearningAreaState.loading && !isEmpty(modifyLearningAreaState.error)){
            console.log(modifyLearningAreaState.error)
            setPageLoading(false)
            if(modifyLearningAreaState.error?.data){
                toast.error(modifyLearningAreaState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[modifyLearningAreaState])

    useEffect(() => {
        if(!isEmpty(activeCard)){
            setPageLoading(false)
        }
    }, [activeCard])

    function handleCardClick(component, id){
        setActiveCard(ps => {
            return {
                currComponent : component,
                id : id
            }
        })
    }

    function removeCourse(id){
        const learningAreaCopy = JSON.parse(JSON.stringify(learningArea))
        const courses = learningAreaCopy.courses.filter(course => course._id !== id)
        learningAreaCopy.courses = courses
        saveUpdatedLearningArea({learning_area : learningAreaCopy})
        removeContentFiles(id)
    }

    function getActiveCourseAreaDetails(){
        if(isEmpty(activeCard)){
            return null
        }

        const courseDoc = learningArea.courses.find(course => course._id === activeCard.id)
        const contentFilesObj = modifyLearningAreaState.courseContentFiles.find(obj => obj.id === activeCard.id)
        if(isEmpty(courseDoc)){
            return null
        }

        return (
            <div>
                <div className="d-flex mt-4">
                    <FontAwesomeIcon icon={faTrash} className="secondary-color ms-auto pt-cursor" onClick={() => removeCourse(courseDoc._id) } />
                    <FontAwesomeIcon icon={faPen} className="secondary-color ms-2 me-3 pt-cursor" onClick={toggleEditForm} />
                </div>
                <div>
                    <div className="d-flex align-items-center mt-3 ">
                        <h5 className="mb-0 lato-fs22-normal-lh32 font-bold primary-text-color">{courseDoc.name}</h5>
                    </div>
                    <div className='mt-3'>
                        <Description description={courseDoc.description} />
                    </div>
                    <div className='mt-5'>
                        <ul className='ps-0'>
                            { 
                                courseDoc.course_content_files.map((file) => (
                                    <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={file._id}>
                                        <div className="d-flex align-items-center mb-2">
                                            <FontAwesomeIcon icon={faPaperclip} className="fs-15 me-1 acent-color" />
                                            <a href={file.url} className={isDesktop ? "link lato-fs17-normal-lh20 primary-text-color pb-0" : "lato-fs16-normal-lh20 link primary-text-color pb-0"} target="_blank">{file.originalname}</a>
                                        </div>
                                    </li>
                                ))
                            }
                            { 
                                !isEmpty(contentFilesObj) ?
                                contentFilesObj.files.map((file) => (
                                    <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={uuidv4()}>
                                        <div className="d-flex align-items-center mb-2">
                                            <FontAwesomeIcon icon={faPaperclip} className="fs-15 me-1 acent-color" />
                                            <span className={isDesktop ? "link lato-fs17-normal-lh20 primary-text-color pb-0" : "link lato-fs16-normal-lh20 primary-text-color pb-0"}>{file.name}</span>
                                        </div>
                                    </li>
                                )) : null
                            }
                            {
                                courseDoc.course_content_links.map((link) => (
                                    <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={link._id}>
                                        <div className="d-flex align-items-center mb-2">
                                            <FontAwesomeIcon icon={faLink} className="fs-15 me-1 acent-color" />
                                            <a href={link.url} className={isDesktop ? "link lato-fs17-normal-lh20 primary-text-color pb-0" : "link lato-fs16-normal-lh20 pb-0"} target="_blank">{link.url}</a>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    async function modifyLearning(){
        if(navigator.onLine){
            if(!isEmpty(modifyLearningAreaState.validationError)){
                const appropriateObj = modifyLearningAreaState.validationError.some(obj => obj.id === learningArea._id)
                if(!isEmpty(appropriateObj)){
                    if(!appropriateObj.hasErrors){
                        // do network call
                        setLoadingButton(true)
                        toggleLearningCirclepopup()
                    }
                    return
                }
            }
    
            const learningAreaValidationResult = await learningAreaValidation(learningArea)
            if(learningAreaValidationResult.hasErrors){
                saveValidationError(learningAreaValidationResult)
            } else {
                // do network call
                toggleLearningCirclepopup()
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function learningAreaValidation(learningAreaDoc){
        return new Promise((resolve, reject) => {
            learningAreaValidationSchema().validate(learningAreaDoc).then(res => {
                resolve({id : learningAreaDoc._id, hasErrors: false})
            }).catch(err => {
                resolve({id: learningAreaDoc._id, hasErrors: true})
            })
        })
    }

    function checkForError(id){
        if(!isEmpty(modifyLearningAreaState.validationError)){
            const appropriateObj = modifyLearningAreaState.validationError.find(obj => obj.id === id)
            if(!isEmpty(appropriateObj)){
                return appropriateObj.hasErrors
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function getDetail(){
        return (
            <>
                <div className={isDesktop ? '' : 'bg-tertiary-color-v2 border-radius-1rem pt-4 pb-3'}>
                    <>
                        <LearningCard
                            variant={isDesktop ? "desktop-learning-card" : "mobile-learning-card"}
                            selected={!isEmpty(activeCard) && (activeCard.id === learningArea._id)}
                            icon={modifyLearningAreaState.icon?.iconPreview || learningArea?.icon}
                            name={learningArea.name}
                            error={checkForError(learningArea._id)}
                            cardClickEvent={() => handleCardClick(COMPONENTS.learningArea, learningArea._id)}
                        />
                    </>
                    { 
                        learningArea.courses ? 
                        <>
                            <hr className={isDesktop ? "" : "mx-3"} />
                            <div className={isDesktop ? "overflow-auto d-flex" : "scroll-container"}>
                                {
                                    learningArea.courses.map((course) => (
                                        <LearningCard 
                                            key={course._id}
                                            variant={isDesktop ? "" : "mobile-course-card"}
                                            name={course.name}
                                            selected={!isEmpty(activeCard) && (activeCard.id === course._id)}
                                            cardClickEvent={() => handleCardClick(COMPONENTS.course, course._id)}
                                        />
                                    ))
                                }
                            </div>
                        </> : ""
                    }
                </div>
                {
                    !editForm ?
                    !isEmpty(activeCard) && activeCard.currComponent === COMPONENTS.learningArea ? 
                    <div className={isDesktop ? "" : "mt-3"}>
                        <div className="d-flex">
                            <FontAwesomeIcon icon={faPen} className="secondary-color ms-auto me-3 pt-cursor" onClick={toggleEditForm} />
                        </div>
                        <div>
                            <div className="d-flex align-items-center mt-2 mb-2 pt-cursor" >
                                <img className="learning-area-icon me-2" 
                                    src={ (isEmpty(modifyLearningAreaState.icon) && isEmpty(learningArea.icon)) ? iconPlaceholder : (modifyLearningAreaState.icon?.iconPreview || learningArea.icon) } 
                                    alt="Icon"/>
                                <h5 className="mb-0 lato-fs22-normal-lh32 primary-text-color font-bold">{ learningArea.name }</h5>
                            </div>
                            {learningArea.description ?
                                <div className='mt-3'>
                                    <Description description={learningArea.description} />
                                </div> : null }
                        </div>
                    </div> : 
                    getActiveCourseAreaDetails() :
                    !isEmpty(activeCard) && activeCard.currComponent === COMPONENTS.learningArea ?
                        <div className={isDesktop ? '' : 'mt-4'}>
                            <ModifyLearningAreaForm cancel={toggleEditForm} /> 
                        </div>:
                        <div className={isDesktop ? '' : 'mt-4'}>
                            <ModifyCourseForm id={activeCard.id} cancel={toggleEditForm}/> 
                        </div>
                }
                {
                    !editForm ?
                        <div className="d-flex justify-content-center align-center mt-4">
                            {/* <CustomizeButton variant="primary-color-button" handleClick={modifyLearning} label="Modify Learning Area" /> */}
                            <CustomizeLoadingButton 
                                variant="accept-loading-button-v2"
                                onClick={modifyLearning}
                                loading={loadingButton}
                                label="Modify Learning Area"
                            />
                        </div> : null
                }
                {
                    learningCirclePopup ? 
                    <DevelopmentPopup open={learningCirclePopup}
                        handleClose={toggleLearningCirclepopup} 
                        onSubmit={onLearningCircleSelected} />
                    : null
                }
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableLogout={true}
                            enableMore={true}
                            enableProfileButton={true}
                            moreRight="88px" 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {!pageLoading && !isEmpty(learningArea)? 
                                        <>
                                            <div className='mt-3'>
                                                <h5 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>Modify Learning area</h5>
                                            </div>
                                            <div className='mt-4'>
                                                {getDetail()}
                                            </div>
                                        </> : null 
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </> : 
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className='container'>
                            <div className='mt-3 px-3'>
                                <Header handleBackArrowEvent={handleBackArrowEvent} 
                                    heading="Modify Learning Area"
                                />
                            </div>
                            <div className='px-3 pb-70'>
                                {!pageLoading && !isEmpty(learningArea)? 
                                    <>
                                        <div className='mt-3'>
                                            {getDetail()}
                                        </div>
                                    </> : null 
                                }
                            </div>
                        </div>
                        <BottomNavigation />
                    </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        modifyLearningAreaState : state.modifyLearningArea
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestSpecificLearningArea : (learningAreaId) => dispatch(specificLearningArea(learningAreaId, true)),
        clearModifyLearningAreaStates : () => dispatch(clearModifyLearningArea()),
        removeContentFiles : (id) => dispatch(removeCourseContentFiles(id)),
        saveUpdatedLearningArea : (payload) => dispatch(modifyLearningAreaSuccess(payload)),
        removeValidationError : (id) => dispatch(removeLearningValidationError(id)),
        saveValidationError : (payload) => dispatch(saveLearningValidationError(payload)),
        notificationStatusChanges : () => dispatch(mailActionChanges())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModifyLearningArea)