import React,{ useEffect, useState } from 'react'
import InputBase from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faThLarge, faListAlt, faSignal } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import useDebounce from '../../../hooks/useDebounce'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import CircularProgress from '@mui/material/CircularProgress'
import { connect } from 'react-redux'

import activeGoalIcon from '../../../assets/icons/active-bullseye.svg'
import { processSearch } from '../../../services/objectives.service'
import { boldString } from '../../../utils/utils'
import { clearSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import { clearSpecificGoalStates } from '../../../redux/specific-goal/specificGoal.action'
import { clearSpecificMetricState } from '../../../redux/specific-metric/specificMetric.action'
import { clearSpecificLearningArea } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'
import { clearCourseContentStatus } from '../../../redux/learning/course/CourseAction'

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        fontSize: '17px',
        fontFamily: '"Lato", sans-serif',
        fontWeight: 400,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
            color:"white"
        },
    },
}))

const ProcessSearchBar = (props) => {

    const { specificObjectiveState, 
        specificGoalState, 
        specificMetricState, 
        specificLearningAreaState, 
        readCourseState,
        clearSpecificObjectiveState, 
        clearSpecificGoalState, 
        clearSpecificMetricState, 
        clearLearningAreaState, 
        clearCourseStates } = props

    const [anchorEl, setAnchorEl] = useState(null)

    const [ search, setSearch ] = useState({
        isLoading: false,
        value : "",
        data : {}
    })

    const history = useHistory()

    const open = Boolean(anchorEl) && !isEmpty(search.value)
    const id = open ? 'simple-popover' : undefined

    // const handleClose = () => {
    //     setAnchorEl(null);
    // }

    function handleInputChange(event){
        const targetValue = event.target.value
        setSearch(ps => {
            return {
                ...ps,
                value : targetValue,
                isLoading: true
            }
        })
        searchDebounce(event.target.value)
        if(!anchorEl){
            setAnchorEl(event.currentTarget)
        }
    }

    const searchDebounce = useDebounce((nextString) => {
        if(nextString.length > 0){
            processSearch(nextString)
            .then((res) => {
                setSearch(ps => {
                    return {
                        ...ps,
                        data : res.data,
                        isLoading: false
                    }
                })
            }).catch((err) => {
                console.log(err)
            })
        }else{
            setSearch(ps => {
                return {
                    ...ps,
                    data: [],
                    isLoading: false
                }
            })
        }
    },1000)

    const handleChange = (type, value) => {
        switch(type){
            case "objectives":
                history.push(`/vcf/${value._id}?level=${value.level}`)
                break
            case "goals":
                history.push(`/goal/${value._id}`)
                break
            case "metrics":
                history.push(`/metric/${value._id}`)
                break
            case "learning_areas":
                history.push(`/learning/${value.parent_circle}/learning-area/${value._id}/${value.segment_no}`)
                break
            case "courses":
                history.push(`/learning/learning-area/course/${value._id}`)
                break
            default:
        }
    }

    const getPopUpList = () => {
        if(search.value){
            if(search.isLoading){
                return (
                    <div className='px-4 py-2'>
                        <div className='d-flex align-items-center py-3'>
                            <CircularProgress sx={{color: 'var(--primary-color-v2)'}} size={24} />
                            <span className='ms-2 lato-fs15-normal-lh20'>Searching...</span>
                        </div>
                    </div>
                )
            } else {
                if(!isEmpty(search?.data?.objectives) || !isEmpty(search?.data?.goals) || !isEmpty(search?.data?.metrics) || !isEmpty(search?.data?.learning_areas) || !isEmpty(search?.data?.courses)){
                    return (
                        <div className='px-4 py-2'>
                            {
                                search?.data?.objectives.map(objective => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={objective._id} onClick={() => handleChange("objectives", objective)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faThLarge} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(objective.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.goals.map(goal => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={goal._id} onClick={() => handleChange("goals", goal)}>
                                        <img src={activeGoalIcon} alt="icon" style={{width:"23px", height:"23px"}}/>
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(goal.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.metrics.map(metric => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={metric._id} onClick={() => handleChange("metrics", metric)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faSignal} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(metric.metric.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.learning_areas.map(learningArea => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={learningArea._id} onClick={() => handleChange("learning_areas", learningArea)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faListAlt} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(learningArea.name, search.value)}} />
                                    </div>
                                ))
                            }
                            {
                                search?.data?.courses.map(course => (
                                    <div className='d-flex align-items-center py-3 pt-cursor' key={course._id} onClick={() => handleChange("courses", course)}>
                                        <FontAwesomeIcon color="var(--primary-color-v2)" size="lg" icon={faBook} />
                                        <p className='mb-0 ms-3 lato-fs16-normal-lh20' dangerouslySetInnerHTML={{__html: boldString(course.name, search.value)}} />
                                    </div>
                                ))
                            }
                        </div>
                    )
                } else {
                    return (
                        <div className='px-4 py-2'>
                            <div className='py-3'>
                                <span className='lato-fs16-normal-lh20'>No process found</span>
                            </div>
                        </div>
                    )
                }
            }
        } else {
            return null
        }
    }

    useEffect(() => {
        if(!isEmpty(specificObjectiveState.response)){
            clearSpecificObjectiveState()
        }

        if(!isEmpty(specificGoalState.response)){
            clearSpecificGoalState()
        }

        if(!isEmpty(specificMetricState.response)){
            clearSpecificMetricState()
        }

        if(!isEmpty(specificLearningAreaState.response)){
            clearLearningAreaState()
        }

        if(!isEmpty(readCourseState.response.course)){
            clearCourseStates()
        }
    },[])

    return (
        <div>
            <StyledInputBase
                aria-describedby={id}
                onChange={handleInputChange}
                value={search.value}
                placeholder="Search Process…"
            />
            <Popper 
                id={id}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper sx={{ width: '37.5ch', padding: "0px" }}>
                            {getPopUpList()}
                        </Paper>
                    </Fade>
                )}
            </Popper>
            {/* <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                PaperProps={{
                    style: { width: '37.5ch' },
                }}
                // sx={{pointerEvents: 'none'}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }} >
                {getPopUpList()}
            </Popover> */}
        </div>
    );
}

const mapStateToProps = (state) =>{
    return {
        specificObjectiveState : state.reviewSpecificObjectiveDetail,
        specificGoalState : state.specificGoal,
        specificMetricState : state.specificMetric,
        specificLearningAreaState : state.specificLearningArea,
        readCourseState : state.readCourse
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        clearSpecificObjectiveState : () => dispatch(clearSpecificObjectiveForReview()),
        clearSpecificGoalState : () => dispatch(clearSpecificGoalStates()),
        clearSpecificMetricState : () => clearSpecificMetricState(),
        clearLearningAreaState : () => clearSpecificLearningArea(),
        clearCourseStates : () => clearCourseContentStatus()
    }
} 

export default connect(mapStateToProps, mapDispatchToProps)(ProcessSearchBar)