import React,{ useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faLink, faPaperclip, faThLarge, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import qs from 'query-string'
import { useHistory, useLocation, useParams, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty,toArray } from 'lodash'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { toast } from 'react-toastify'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import Collapse from '@mui/material/Collapse';

import ObjectiveCard from '../objective-card/ObjectiveCard'
import './review-action.css'
import { requestSpecificObjective, objectiveReviewAcceptAction } from '../../services/review.service'
import BreadCrumbs from '../breadcrumb/BreadCrumbs'
import { clearSpecificObjectiveForReview } from '../../redux/review-team-action/specific-objective/specificObjective.action'
import { actionChangedUnderReview } from '../../redux/review-team-action/objective-under-review/objectiveUnderReview.action'
import { objectiveAllReviewActionchanged } from '../../redux/review-team-action/objective-review/objectiveReview.action'
import { getUserId } from '../../utils/utils'
import RejectPopup from '../reject-popup/RejectPopup'
import { PROCESS_STATUS, REVIEW_TYPES } from '../../utils/constants'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'rgba(255,122,102,255)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'rgba(255,122,102,255)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
      borderLeftWidth : 2,
      marginLeft :"-6px !important",
    },
  }));

  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: 'rgba(255,122,102,255)',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: 'rgba(255,122,102,255)',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

const useStyles = makeStyles({
    root: {
      width: '100%',
      paddingLeft :10,
    },
    stepContainer :{
        backgroundColor : "transparent",
    },
    label :{
        fontSize : 15,
        color : "rgba(68,68,68,255) !important",
        fontWeight : "600 !important",
        fontFamily : '"oxygen",sans-serif',
    },
    active :{
        color : "rgba(255,122,102,255) !important"
    },
});

const ReviewAction = (props) => {

    const { requestObjectiveReview, objectiveDetail, clearObjectiveState, actionChangeUnderReview, objectiveAllActionChanged } = props

    const location = useLocation()
    const history = useHistory()
    const params = useParams()
    const queryParams = qs.parse(location.search)
    const classes = useStyles()

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ actionDetail, setActionDetail ] = useState({})
    const [ rejectPopupState, setRejectPopupState ] = useState(false)
    const [ collapse, setCollapse ] = useState(false)
 
    const REVIEW_TYPE = {
        new_creation_request : "Create request",
        edit_request : "Edit request",
        delete_request : "Delete request"
    }

    function rejectActionPopup(){
        setRejectPopupState(!rejectPopupState)
    }

    useEffect(() =>{
        if(isEmpty(objectiveDetail.response)){
            requestObjectiveReview(params.actionId)
        }
        // return () => {
        //     clearObjectiveState()
        // }
    },[])

    useEffect(() => {
        if( !objectiveDetail.loading && !isEmpty(objectiveDetail.response)){
            setActionDetail(objectiveDetail.response.objective)
            setPageLoading(false)
        }
        if( !objectiveDetail.loading && !isEmpty(objectiveDetail.error)){
            if(objectiveDetail.error){
                if(objectiveDetail.error.data){
                    toast.error(objectiveDetail.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[objectiveDetail])

    function tagColor(status){
        if(status == "new_creation_request"){
            return "box "
        }else if(status == "delete_request"){
            return "box red-tag"
        }else {
            return "box yellow-tag"
        }
    }

    function handleBackArrow(){
        history.goBack()
    }

    function acceptActionEvent(){
        objectiveReviewAcceptAction(params.actionId,"objective")
        .then((res) => {
            actionChangeUnderReview()
            objectiveAllActionChanged()
            toast.success(res.data.message , {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.goBack()
        }).catch((error) => {
            if(error.response){
                if(error.response.data){
                    toast.error(error.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        })
    }

    const isStepReviewer = (step) => {
        return step === 1;
    };

    const isStepSuperReviewer = (step) => {
        return step === 2;
    };

    function getStepperLabel(){
        let label = []
        if(!isEmpty(actionDetail)){
            if(actionDetail.status == "needs_approval"){
                if(actionDetail.review_status){
                    if(actionDetail.review_status.reviewer_needed && actionDetail.review_status.super_reviewer_needed){
                        label.push(REVIEW_TYPE[actionDetail.review_status.review_type],"Reviewer","Super reviewer")
                    } else if (actionDetail.review_status.reviewer_needed){
                        label.push(REVIEW_TYPE[actionDetail.review_status.review_type],"Reviewer")
                    } else if (actionDetail.review_status.super_reviewer_needed){
                        label.push(REVIEW_TYPE[actionDetail.review_status.review_type],"Super reviewer")
                    }
                }
            }
        }
        return label
    }

    function activeStep(){
        if(queryParams.role == "reviewer"){
            if(!isEmpty(actionDetail) && actionDetail.status == "needs_approval"){
                if(!isEmpty(actionDetail.review_status)){
                    if(actionDetail.review_status.reviewed_by){
                        return 1
                    }else {
                        return 0
                    }
                }
            }
        }else{
            if(!isEmpty(actionDetail) && actionDetail.status == "needs_approval"){
                if(!isEmpty(actionDetail.review_status)){
                    if(actionDetail.review_status.reviewed_by){
                        return 1
                    }else {
                        return 0
                    }
                }
            }
        }
    }

    function showActionButton(){
        let actionButton = false
        if(actionDetail.status == "needs_approval"){
            if(queryParams.role == "reviewer"){
                if(actionDetail.review_status){
                    if(actionDetail.review_status.reviewer_needed && actionDetail.review_status.reviewer_status == "pending"){
                        actionButton = true
                    }
                }
            }else{
                if(actionDetail.review_status){
                    if(actionDetail.review_status.super_reviewer_needed && actionDetail.review_status.super_reviewer_status == "pending"){
                        actionButton = true
                    }
                }
            }
        }
        return actionButton
    }

    function getValue(key, objective){
        if((objective.status === PROCESS_STATUS.needsApproval) && (objective.review_status.review_type === REVIEW_TYPES.editRequest)){
            return objective.edits[key]
        } else {
            return objective[key]
        }
    }

    return (
        !pageLoading && !isEmpty(actionDetail)?
        <div className="container vh-100 d-flex flex-column p-0">
            <div className="px-3">
                <div className="pt-2 d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2 secondary-color" onClick={handleBackArrow}/>
                    <h5 className="fs20-lh32 primary-color mb-0">Review VCF</h5>
                </div>
            </div>
            <div className="container-bg review-container px-1 mt-2 p-42">
                { !isEmpty(actionDetail.review_status) && (actionDetail.review_status.review_type == "new_creation_request" || actionDetail.review_status.review_type == "edit_request" || actionDetail.review_status.review_type == "delete_request" )  ?
                    <div className={tagColor(actionDetail.review_status.review_type)}>
                        <span className="white-color">{actionDetail.review_status.review_type == "new_creation_request" ? "New" : actionDetail.review_status.review_type == "delete_request" ? "Delete" : "Revised"}</span>
                    </div> : null}

                <div className="mt-2 ml-10">
                    <BreadCrumbs icon={faThLarge} path={toArray(actionDetail.path)} activeId={actionDetail._id}/>
                </div>
                <div>
                    <ObjectiveCard 
                        name={ getValue('name',actionDetail) } 
                        icon={ getValue('icon',actionDetail) }
                        wallPaper={getValue('wallpaper',actionDetail)}
                    />
                </div>
                <div style={{marginLeft:"10px",marginRight:"10px"}}>
                    <p className="secondary-color fs16-lh20">{getValue('description',actionDetail)}</p>
                </div>
                { !isEmpty(getValue('reference_files',actionDetail)) || !isEmpty(getValue('reference_links',actionDetail)) ?
                    <div style={{marginLeft:"10px",marginRight:"10px"}}>
                        <h5 className="fs18-lh20">Reference</h5>
                        <ul>
                            { !isEmpty(getValue('reference_files',actionDetail)) && getValue('reference_files',actionDetail).map((file) => (
                                <li style={{listStyle:"none",fontSize:"0",marginBottom:"5px"}} key={file._id}>
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faPaperclip} className="fs-15 me-1 orange-color" />
                                        <a href={file.url} className="link fs15-lh20 pb-0" target="_blank">{file.originalname}</a>
                                    </div>
                                </li>
                            ))}
                            { !isEmpty(getValue('reference_links',actionDetail)) && getValue('reference_links',actionDetail).map((link) => (
                                <li style={{listStyle:"none",fontSize:"0",marginBottom:"12px"}} key={link._id}>
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faLink} className="fs-15 me-1 orange-color" />
                                        <a href={link.url} className="link fs15-lh20 pb-0" target="_blank">{link.url}</a>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div> : null }

                {actionDetail.status == "needs_approval" ? 
                    <div className="progress-chip" onClick={() => setCollapse(ps => !ps)}>
                        <div className="d-flex align-items-center">
                            <p className="mb-0 me-2 secondary-color" >{ collapse ? "Hide Approval Progress" : "Show Approval Progress"}</p>
                            <FontAwesomeIcon className="orange-color" icon={ collapse ? faChevronUp : faChevronDown}  />
                        </div>
                    </div> : null }
                <Collapse in={collapse}>
                    { actionDetail.status == "needs_approval" ?
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep()} orientation="vertical" className={classes.stepContainer} connector={<QontoConnector />}>
                                {getStepperLabel().map((label,index) => {
                                    const labelProps ={}
                                    if(isStepReviewer(index)){
                                        labelProps.optional =( 
                                            !isEmpty(actionDetail) && !isEmpty(actionDetail.review_status) ?
                                                queryParams.role == "reviewer" || (queryParams.role == "super-reviewer" && actionDetail.review_status.reviewer_needed) ?
                                                <div>
                                                    {  actionDetail.review_status.reviewer_status ? 
                                                        <p className="mb-0 fs13-lh15 secondary-color">{actionDetail.review_status.reviewer_status}</p> : null
                                                    }
                                                    { !isEmpty(actionDetail.review_status.reviewed_by) ? 
                                                        !isEmpty(actionDetail.review_status.reviewed_by.person_name) ?
                                                        <p className="mb-0 fs13-lh15 secondary-color">by <a>{ getUserId() === actionDetail.review_status.reviewed_by._id ? 'You' : `${actionDetail.review_status.reviewed_by.person_name.first_name} ${actionDetail.review_status.reviewed_by.person_name.last_name}`}</a></p> : null : ""}
                                                </div> :  
                                                <div>
                                                    {actionDetail.review_status.super_reviewer_needed ? 
                                                        <p className="mb-0 fs13-lh15 secondary-color">{actionDetail.review_status.super_reviewer_status}</p> : null
                                                    }
                                                </div>
                                            : null
                                        )
                                    }

                                    if(isStepSuperReviewer(index)){
                                        labelProps.optional=(
                                            !isEmpty(actionDetail) && !isEmpty(actionDetail.review_status) ?
                                            <div>
                                                {  actionDetail.review_status.super_reviewer_status ? 
                                                    <p className="mb-0 fs13-lh15 secondary-color">{actionDetail.review_status.super_reviewer_status}</p> : null
                                                }
                                            </div> : null
                                        )
                                    }
                                    
                                    return (
                                        <Step key={label}> 
                                            <StepLabel  StepIconComponent={QontoStepIcon}
                                                StepIconProps={{ classes : {
                                                    active : classes.active,
                                                    completed : classes.active
                                                } }} 
                                                {...labelProps} classes={{label : classes.label}}>
                                                    {label}
                                            </StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </div> : null
                    }
                </Collapse>
                { showActionButton() ?
                 <Navbar fixed="bottom" expand="lg" variant="light" bg="light" className="p-0" >
                    <Container>
                        <span className="accept-link link-button" onClick={() => acceptActionEvent()}>Accept</span>
                        <span className="modify-link link-button" onClick={() => history.push(`/review/${params.userId}/action/${params.actionId}/modify`)}>Modify</span>
                        <span className="reject-link link-button" onClick={rejectActionPopup}>Reject</span>
                    </Container>
                </Navbar> : ""}
            </div>
            { rejectPopupState && <RejectPopup handleClose={rejectActionPopup} />}
        </div> : null
    )
}

const mapStateToProps = (state) => {
    return {
        objectiveDetail : state.reviewSpecificObjectiveDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjectiveReview : (userId) => dispatch(requestSpecificObjective(userId)),
        clearObjectiveState : () => dispatch(clearSpecificObjectiveForReview()),
        actionChangeUnderReview : () => dispatch(actionChangedUnderReview()),
        objectiveAllActionChanged : () => dispatch(objectiveAllReviewActionchanged())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReviewAction)