import React from 'react'
import Card from '@mui/material/Card'
import './metric-aggregate-card.css'

const MetricAggregateCard = (props) => {

    const { children, selected, handleClick } = props

    switch(props.variant){
        case "desktop":{
            const className = props.className ? `desktop-metric-aggregate-card ${props.className}` : `desktop-metric-aggregate-card`
            return (
                <Card className={selected ? `${className} selected` : className} onClick={handleClick} >
                    {children}
                </Card>
            )
        }
        default:{
            const className = props.className ? `metric-aggregate-card ${props.className}` : `metric-aggregate-card`
            return (
                <Card className={selected ? `${className} selected` : className} onClick={handleClick} >
                    {children}
                </Card>
            )
        }
    } 
}

export default MetricAggregateCard