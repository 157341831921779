import { REQUEST_UPDATE_COMPUTE_PROGRESS_METRIC,
         UPDATE_COMPUTE_PROGRESS_METRIC_SUCCESS,
         UPDATE_COMPUTE_PROGRESS_METRIC_FAILURE,
         CLEAR_UPDATE_COMPUTE_PROGRESS_METRIC } from './UpdateComputeProgressMetricTypes'

export const requestUpdateComputeProgressMetric = () => {
    return {
        type : REQUEST_UPDATE_COMPUTE_PROGRESS_METRIC
    }
}

export const successUpdateComputeProgressMetric = (resData) => {
    return {
        type : UPDATE_COMPUTE_PROGRESS_METRIC_SUCCESS,
        payload : resData
    }
}

export const failureUpdateComputeProgressMetric = (error) => {
    return {
        type : UPDATE_COMPUTE_PROGRESS_METRIC_FAILURE,
        payload : error
    }
}

export const clearUpdateComputeProgressMetric = () => {
    return {
        type : CLEAR_UPDATE_COMPUTE_PROGRESS_METRIC
    }
}