import { REQUEST_ADD_NOTE, 
        ADD_NOTE_SUCCESS, 
        ADD_NOTE_FAILURE,
        CLEAR_ADD_NOTE_STATE } from './AddNote.types'

export const requestAddNote = () => {
    return {
        type : REQUEST_ADD_NOTE
    }
}

export const addNoteSuccess = (response) => {
    return {
        type : ADD_NOTE_SUCCESS,
        payload : response
    }
}

export const addNoteFailure = (error) => {
    return {
        type : ADD_NOTE_FAILURE,
        payload : error
    }
}

export const clearAddNoteState = () => {
    return {
        type : CLEAR_ADD_NOTE_STATE
    }
}