import {isEmpty} from 'lodash'
import moment from 'moment'
import * as yup from 'yup'
import { checkObjectiveDuplicationPromise } from '../../../services/fetchObjectives.service'

export const getObjById = (id, objective) => {
    let appropriateObj = {};
    function func(obj){
        if(!isEmpty(appropriateObj)){
            return
        }
        if(obj._id === id){
            appropriateObj = obj
            return
        }
        if(!isEmpty(obj.sub_objectives)){
            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        } else {
            return
        }
    }
    func(objective)
    return appropriateObj
}

export const getObjByIdInArr = (id, objectives) => {
    let appropriateObjective = {}
    for(const objective of objectives){
        const obj = getObjById(id, objective)
        if(!isEmpty(obj)){
            appropriateObjective = obj
            break;
        }
    }
    return appropriateObjective
}

export const objectiveValidationSchema = (activeObjective, parentObjective) => {
    return yup.object().shape({
        name : yup.string().required('Name Required')
                .test('name-duplication-test', 'Already exists', (name) => {
                    return new Promise((resolve, reject) => {
                        if(name){
                            checkObjectiveDuplicationPromise({level: activeObjective.level, name: name}).then(res => {
                                resolve(!res.already_present)
                            }).catch(err => {
                                console.log(err)
                                resolve(false)
                            })
                        } else {
                            resolve(false)
                        }
                    })
                })
                .min(2, 'Too Short!')
                .max(50, 'Too Long!'),
        description: yup.string(),
        start_date: yup.date().required("Start date required")
                        .test('start-date-validation', 'Invalid start date', (value) => {
                            if(value){
                                if(activeObjective.level > 0){
                                    if(!isEmpty(parentObjective)){
                                        const parentStartDate = moment(parentObjective.start_date).startOf("day")
                                        const parentEndDate = moment(parentObjective.end_date).endOf("day")
                                        return moment(value).isBetween(parentStartDate, parentEndDate, undefined, '[]')
                                    } else {
                                        console.log('Error in validation')
                                        return false
                                    }
                                } else {
                                    return true
                                }
                            } else {
                                return false
                            }
                        }),
        end_date : yup.date().required('End date required')
                        .min(yup.ref('start_date'), "End date can't be before Start date")
                        .test('check for past dates', "End date can't be in past", (value) => {
                            if(value){
                                return (moment(value).endOf("day")).isSameOrAfter(moment())
                            } else {
                                return false
                            }
                        })
                        .test('end-date-validation', 'Invalid end date', (value) => {
                            if(value){
                                if(activeObjective.level > 0){
                                    if(!isEmpty(parentObjective)){
                                        const parentStartDate = moment(parentObjective.start_date).startOf("day")
                                        const parentEndDate = moment(parentObjective.end_date).endOf("day")
                                        return moment(value).isBetween(parentStartDate, parentEndDate, undefined, '[]')
                                    } else {
                                        console.log('Error in validation')
                                        return false
                                    }
                                } else {
                                    const valueInMoment = moment(value).endOf("day")
                                    const currentEndDateInMoment = moment().endOf("day")
                                    return valueInMoment.isSameOrAfter(currentEndDateInMoment)
                                }
                            } else {
                                return false
                            }
                        })
    })
}