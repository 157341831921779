import { requestCourses, coursesSuccess, coursesFailure } from '../redux/video-conference/course/Course.action'
import { developmentAreaFailure, 
        developmentAreaSuccess, 
        requestDevelopmentArea } from '../redux/video-conference/development/Development.action'
import { requestObjective,
        objectiveSuccess,
        objectiveFailure } from '../redux/video-conference/objective/Objective.action'
import { requestMetricsForSelect, 
         metricsForSelectSuccess, 
         metricsForSelectFailure } from '../redux/video-conference/metric/Metric.action'
import ProtectedWebClient from '../utils/protected-web-client'
import { requestYourMeeting, yourMeetingSuccess, yourMeetingFailure} from '../redux/video-conference/your-meeting/YourMeeting.actions'
import { requestInvite, invitesSuccess, invitesFailure } from '../redux/video-conference/invites/Invites.actions'

export const requestObjectiveForSelect = () => {
    return async (dispatch) => {
        try{
            dispatch(requestObjective())
            const response = await ProtectedWebClient.post(`/objective?option=select`)
            dispatch(objectiveSuccess(response.data))
        }catch(error){
            dispatch(objectiveFailure(error.response))
        }
    }
}

export const requestDevelopmentAreaForSelect = () => {
    return async (dispatch) => {
        try{
            dispatch(requestDevelopmentArea())
            const response = await ProtectedWebClient.get(`/learning-circle?option=select`)
            dispatch(developmentAreaSuccess(response.data))
        }catch(error){
            dispatch(developmentAreaFailure(error.response))
        }
    }
}

export const requestCoursesForSelect = () => {
    return async (dispatch) => {
        try{
            dispatch(requestCourses())
            const response = await ProtectedWebClient.get(`/learning-area/read?option=select`)
            dispatch(coursesSuccess(response.data))
        }catch(error){
            dispatch(coursesFailure(error.response))
        }
    }
}

export const requestMetricForSelect = () => {
    return async (dispatch) => {
        try {
            dispatch(requestMetricsForSelect())
            const response = await ProtectedWebClient.get(`/graph?option=select`)
            dispatch(metricsForSelectSuccess(response.data))
        }catch(error){
            dispatch(metricsForSelectFailure(error.response))
        }
    }
} 

export const fetchRtcToken = (channelId) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.get(`/video-conference/generate-token/${channelId}`)
        .then(res => {
            resolve(res)       
        }).catch(err => {
            reject(err)
        })
    })
}

export const getVCUserDetails = (vcId, payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/video-conference/${vcId}/user-details`, payload)
        .then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const createVideoConference = (values) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/video-conference/schedule`,values)
        .then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const requestYourMeetings = () => {
    return async (dispatch) => {
        try {
            dispatch(requestYourMeeting())
            const response = await ProtectedWebClient.get(`/video-conference/list?select=your_meetings`)
            dispatch(yourMeetingSuccess(response.data))
        }catch(error){
            dispatch(yourMeetingFailure(error.response))
        }
    }
}

export const requestInvites = () => {
    return async (dispatch) => {
        try {
            dispatch(requestInvite())
            const response = await ProtectedWebClient.get(`/video-conference/list?select=invites`)
            dispatch(invitesSuccess(response.data))
        }catch(error){
            dispatch(invitesFailure(error.response))
        }
    }
}

export const requestScreenSharingToken = (channelId) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.get(`/video-conference/generate-token/${channelId}?for=screen_share`)
        .then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}