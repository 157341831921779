import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import qs from 'query-string'
import useMediaQuery from '@mui/material/useMediaQuery'

import './sent.css'
import { fetchSentActions } from '../../../services/notification.service'
import NotificationCard from '../../../common/notification-card/NotificationCard'
import { fetchNotificationDetailSuccess } from '../../../redux/notification/notification-detail/notificationDetail.action'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { boldString } from '../../../utils/utils'

const Sent = (props) => {

    const {
        fetchAllSentActions,
        fetchSentActionsStates,
        ownProps,
        saveInboxAction
    } = props

    const [contentLoading, setContentLoading] = useState(true) 

    const sentActions = fetchSentActionsStates.response.sent_mails

    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    useEffect(() => {
        // if(isEmpty(fetchSentActionsStates.response)){
            fetchAllSentActions(ownProps.action)
        // }
    }, [])

    useEffect(() => {
        if(!fetchSentActionsStates.loading){
            fetchAllSentActions(ownProps.action)
        }
    },[queryParams.action])

    useEffect(() => {
        if(!fetchSentActionsStates.loading && !isEmpty(fetchSentActionsStates.response)){
            // console.log(fetchSentActionsStates.response)
            setContentLoading(false)
        }

        if(!fetchSentActionsStates.loading && fetchSentActionsStates.error){
            if(fetchSentActionsStates.error){
                if(fetchSentActionsStates.error.data){
                    toast.error(fetchSentActionsStates.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }

        if(fetchSentActionsStates.new_message && fetchSentActionsStates.loading){
            fetchAllSentActions(ownProps.action)
        }

    }, [fetchSentActionsStates])

    function handleCardClick(index, action){
        saveInboxAction({mail: action})
        history.push(`/notification/${action._id}/sent?action=${ownProps.action}`)
    }

    function concatAllReceiverNames(receiverArray){
        let receiverString = ''
        for(const receiver of receiverArray){
            if(receiverString){
                receiverString = receiverString + ', ' + receiver.user.person_name.first_name + " " + receiver.user.person_name.last_name
            } else {
                receiverString += receiver.user.person_name.first_name + " " + receiver.user.person_name.last_name
            }
        }
        return receiverString
    }

    function getAppropriateCards(){
        if(props.searchedValue.value){
            if(!props.searchedValue.typing){
                if(!isEmpty(props.searchedValue.data)){
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            {
                                props.searchedValue.data.map((sentAction,index) => (
                                    <NotificationCard 
                                        key={sentAction._id}
                                        onClick={() => handleCardClick(index, sentAction)}
                                        subject={boldString(sentAction.subject, props.searchedValue.value)}
                                        message={sentAction.message}
                                        person={`${concatAllReceiverNames(sentAction.to)}`}
                                        time={moment(sentAction.createdAt).format('Do MMM, YYYY')}
                                    />
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            <p className='lato-fs15-normal-lh20'>
                                No mail found for search results "
                                <span className='lato-fs15-normal-lh20 font-bold'>
                                    {props.searchedValue.value}
                                </span>
                                "
                            </p>
                        </>
                    )
                }
            } else {
                return (
                    <Box className="d-flex justify-content-center align-items-center">
                        <CircularProgress sx={{color: 'var(--primary-color-v2)'}} />
                    </Box>
                )
            }
        } else {
            if(!contentLoading){
                if(!isEmpty(sentActions)){
                    return (
                        <>
                            {
                                sentActions.map((sentAction,index) => (
                                    <NotificationCard
                                        key={sentAction._id}
                                        onClick={() => handleCardClick(index, sentAction)}
                                        subject={sentAction.subject}
                                        message={sentAction.message}
                                        person={`${concatAllReceiverNames(sentAction.to)}`}
                                        time={moment(sentAction.createdAt).format('Do MMM, YYYY')}
                                    />
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <div className="d-flex justify-content-center">
                            <h6 className="lato-fs20-normal-lh28 mb-0 tertiary-text-color-v2">No sent mails</h6>
                        </div>
                    )
                }
            } else {
                return null
            }
        }
    }

    return (
        isDesktop ?
            <div className='pt-4 pb-3'>
                {getAppropriateCards()}
            </div>
            : <div className='pt-3 pb-70'>
                {getAppropriateCards()}
            </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        fetchSentActionsStates : state.fetchSentActions,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllSentActions : (action) => dispatch(fetchSentActions(action)),
        saveInboxAction : (response) => dispatch(fetchNotificationDetailSuccess(response))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sent)