import { REQUEST_ADD_SEGMENT,
         ADD_SEGMENT_SUCCESS,
         ADD_SEGMENT_FAILURE,
         CLEAR_ADD_SEGMENT_STATES } from './AddSegment.types'

export const requestAddSegment = () => {
    return {
        type : REQUEST_ADD_SEGMENT
    }
}

export const addSegmentSuccess = (resData) => {
    return {
        type : ADD_SEGMENT_SUCCESS,
        payload : resData
    }
}

export const addSegmentFailure = (error) => {
    return {
        type : ADD_SEGMENT_FAILURE,
        payload : error
    } 
}

export const clearAddSegmentStates = () => {
    return {
        type : CLEAR_ADD_SEGMENT_STATES
    }
}