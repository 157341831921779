import React from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton'

const DrillDown = (props) => {
    switch(props.variant){
        case 'mobile':
            return (
                <div style={{position: "fixed", bottom: "51px", right: "0", padding: "10px 20px", zIndex: "3"}}>
                    <IconButton
                        sx={{
                            backgroundColor: 'var(--primary-color-v2)',
                            '&:hover, &.Mui-disabled' : {
                                backgroundColor: 'var(--primary-color-v2)'
                            }
                        }}
                        disabled={props.arrowUpDisabled}
                        onClick={props.onArrowUpClick} >
                        <ArrowUpwardIcon 
                            sx={{color: `${props.arrowUpDisabled ? "gray" : "white"}`}} />
                    </IconButton>
                    <IconButton
                        sx={{
                            backgroundColor: 'var(--primary-color-v2)',
                            '&:hover, &.Mui-disabled' : {
                                backgroundColor: 'var(--primary-color-v2)'
                            }
                        }}
                        disabled={props.arrowDownDisabled}
                        onClick={props.onArrowDownClick}
                        className='ms-3' >
                        <ArrowDownwardIcon sx={{color: `${props.arrowDownDisabled ? "gray" : "white"}`}}/>
                    </IconButton>
                </div>
            )
        default:
            return (
                <div style={{position: "fixed", bottom: "0", right: "0", backgroundColor: "#14213d", borderTopLeftRadius: "10px", padding: "10px 20px", zIndex: "3"}}>
                    <IconButton
                        disabled={props.arrowUpDisabled}
                        onClick={props.onArrowUpClick} >
                        <ArrowUpwardIcon 
                            sx={{color: `${props.arrowUpDisabled ? "gray" : "white"}`}} />
                    </IconButton>
                    <IconButton 
                        disabled={props.arrowDownDisabled}
                        onClick={props.onArrowDownClick}
                        className='ms-3' >
                        <ArrowDownwardIcon sx={{color: `${props.arrowDownDisabled ? "gray" : "white"}`}}/>
                    </IconButton>
                </div>
            )
    }
};

export default DrillDown;
