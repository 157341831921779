import { REQUEST_SPECIFIC_METRIC,
         SPECIFIC_METRIC_SUCCESS,
         SPECIFIC_METRIC_FAILURE,
         CLEAR_SPECIFIC_METRIC_STATE } from './specificMetric.types'

const initialState = {
    loading : false,
    response : {},
    error: null
}

const specificMetricReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_SPECIFIC_METRIC :
            return {
                ...state,
                loading:true,
                error:null
            }

        case SPECIFIC_METRIC_SUCCESS : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }
        
        case SPECIFIC_METRIC_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_SPECIFIC_METRIC_STATE : 
            return {
                loading : false,
                response : {},
                error: null
            }

        default : 
            return state
    }
}

export default specificMetricReducer