import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Drawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import Just4WhiteIcon from '../../../assets/icons/Just_4_icon_white.svg'
import Just4Logo from '../../../assets/icons/Just_4_logo_white.svg'

const PublicNavbar = (props) => {

    const history = useHistory()
    const location = useLocation().pathname.split('/')[1]

    function ElevationScroll(props) {
        const { children, window } = props;
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window() : undefined,
        })
    
        return React.cloneElement(children, {
            elevation: trigger ? 4 : 0,
        })
    }


    switch(props.variant){
        case "mobile":
            return (
                <>
                    <ElevationScroll>
                        <AppBar sx={{backgroundColor : "#14213d"}}>
                        <Toolbar>
                            <Grid container >
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    <div className='d-flex align-items-center'>
                                        {
                                            props.logo ? 
                                                <img src={Just4Logo} style={{width:"98px"}} alt="just 4 logo"/> 
                                                : <img src={Just4WhiteIcon} style={{width:"35px", height:"35px"}} alt="just 4 icon"/>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    {
                                        props.enableDrawer ? 
                                            <div className='d-flex justify-content-end align-items-center'>
                                                <IconButton
                                                    color="inherit"
                                                    style={{color : "#fff"}}
                                                    aria-label="open drawer"
                                                    edge="false"
                                                    onClick={props.onDrawerClick}
                                                    sx={{ marginRight:"5px" }}
                                                >
                                                    <MenuIcon />
                                                </IconButton>
                                            </div> 
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </Toolbar>
                        </AppBar>
                    </ElevationScroll>
                    <Toolbar />
                    {
                        props.enableDrawer ?
                            <Drawer
                                variant="temporary"
                                anchor="right"
                                open={props.drawerOpened}
                                onClose={props.handleDrawerClose}
                                ModalProps={{
                                    keepMounted: true
                                }}
                                sx={{
                                    display: { xs: 'block' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                                }}
                            >
                                <Box
                                    role="presentation"
                                    sx={{minWidth : {
                                        sm : 300,
                                        xs : 100,
                                    },
                                    maxWidth :{
                                        sm : 300,
                                        xs : 200
                                    }}}
                                    >
                                    <List sx={{paddingTop : 1}}>
                                        { props.drawerHome ?
                                            <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2 }} >
                                                <ListItemText primary="Home" 
                                                    primaryTypographyProps={{ color: "var(--primary-text-color-v2)",
                                                                                fontWeight:"normal",
                                                                                fontSize:15,
                                                                                fontFamily:'"Lato",sans-serif' }}
                                                    onClick={() => history.push('/')} 
                                                />
                                            </ListItem> : null
                                        }
                                        { location !== "product" ?
                                            <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2 }} >
                                                <ListItemText primary="Product" 
                                                    primaryTypographyProps={{ color: "var(--primary-text-color-v2)",
                                                                                fontWeight:"normal",
                                                                                fontSize:15,
                                                                                fontFamily:'"Lato",sans-serif' }}
                                                    onClick={() => history.push('/product')} 
                                                />
                                            </ListItem> : null
                                        }
                                        {location !== "consult-us" ? 
                                            <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}} >
                                                <ListItemText primary="Consult Us" 
                                                    primaryTypographyProps={{ color: "var(--primary-text-color-v2)",
                                                                                fontWeight:"normal",
                                                                                fontSize:15,
                                                                                fontFamily:'"Lato",sans-serif' }}
                                                    onClick={() => history.push('/consult-us')}
                                                />
                                            </ListItem> : null
                                        }
                                        {location !== "about-us" ? 
                                            <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}} >
                                                <ListItemText primary="About Us" 
                                                    primaryTypographyProps={{ color: "var(--primary-text-color-v2)",
                                                                                fontWeight:"normal",
                                                                                fontSize:15,
                                                                                fontFamily:'"Lato",sans-serif' }}
                                                    onClick={() => history.push('/about-us')}
                                                />
                                            </ListItem> : null
                                        }
                                        {location !== "subscribe" ?
                                            <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}} >
                                                <ListItemText primary="Subscribe" 
                                                    primaryTypographyProps={{ color: "var(--primary-text-color-v2)",
                                                                                fontWeight:"normal",
                                                                                fontSize:15,
                                                                                fontFamily:'"Lato",sans-serif' }}
                                                    onClick={() => history.push(`/subscribe`)}
                                                />
                                            </ListItem> : null
                                        }
                                        {/* { location === "sign-up" ? 
                                            <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}} >
                                                <ListItemText primary="Login" 
                                                    primaryTypographyProps={{ color: "var(--primary-text-color-v2)",
                                                                                fontWeight:"normal",
                                                                                fontSize:15,
                                                                                fontFamily:'"Lato",sans-serif' }}
                                                    onClick={() => history.push('/sign-in')}
                                                />
                                            </ListItem> : null 
                                        } */}
                                    </List>
                                </Box>
                            </Drawer>
                            : null
                    }
                </>
            )
        default:
            return (
                <>
                    <ElevationScroll>
                        <AppBar sx={{backgroundColor : "#14213d", paddingRight: "5%", paddingLeft: "5%"}}>
                        <Toolbar>
                            <Grid container >
                                <Grid item xs={5} style={{display: 'flex', alignItems: "center"}}>
                                    <div className='d-flex align-items-center'>
                                        {
                                            props.logo ? 
                                                <img src={Just4Logo} style={{width:"115px"}} alt="just 4 icon"/> 
                                                : <img src={Just4WhiteIcon} style={{width:"40px",height:"40px"}} alt="just 4 icon"/>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={7} style={{display: 'flex', alignItems: "center", justifyContent: "flex-end"}}>
                                    {
                                        props.enableHome ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none", cursor:"pointer"}}
                                                variant="text"
                                                onClick={() => history.push("/")}>
                                                Home
                                            </Button> :
                                            null
                                    }
                                    {
                                        props.enableProduct ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none", cursor:"pointer"}}
                                                variant="text"
                                                onClick={() => history.push("/product")}>
                                                Product
                                            </Button> :
                                            null
                                    }
                                    {
                                        props.enableConsult ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none", cursor:"pointer"}}
                                                variant="text"
                                                onClick={() => history.push("/consult-us")}>
                                                Consult Us
                                            </Button> :
                                            null
                                    }
                                    {
                                        props.enableAboutUs ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none", cursor:"pointer"}}
                                                variant="text"
                                                onClick={() => history.push("/about-us")}>
                                                About Us
                                            </Button> :
                                            null
                                    }
                                    {
                                        props.enableSubscribe ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none", cursor:"pointer"}}
                                                variant="text"
                                                onClick={() => history.push("/subscribe")}>
                                                Subscribe
                                            </Button> :
                                            null
                                    }
                                    {
                                        props.enableLogin ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none", cursor:"pointer"}}
                                                variant="text"
                                                onClick={() => history.push("/sign-in")}>
                                                Login
                                            </Button> :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </Toolbar>
                        </AppBar>
                    </ElevationScroll>
                    <Toolbar />
                </>
            )
    }
}

export default React.memo(PublicNavbar)