import { REQUEST_UPDATE_COMPUTE_PROGRESS_METRIC,
         UPDATE_COMPUTE_PROGRESS_METRIC_SUCCESS,  
         UPDATE_COMPUTE_PROGRESS_METRIC_FAILURE, 
         CLEAR_UPDATE_COMPUTE_PROGRESS_METRIC} from './UpdateComputeProgressMetricTypes'

const initialState = {
    loading: false,
    response: {},
    error: null
}

const updateComputeProgressMetricReducer = (state = initialState, action) => {
switch(action.type){
   case REQUEST_UPDATE_COMPUTE_PROGRESS_METRIC : 
       return {
           ...state,
           loading: true,
           error: null
       }

   case UPDATE_COMPUTE_PROGRESS_METRIC_SUCCESS : 
       return {
           ...state,
           loading : false,
           response: action.payload
       }

   case UPDATE_COMPUTE_PROGRESS_METRIC_FAILURE : 
       return {
           ...state,
           loading: false,
           error : action.payload
       }

   case CLEAR_UPDATE_COMPUTE_PROGRESS_METRIC : 
       return  {
           ...state,
           loading: false,
           response: {},
           error : null
       }

   default : 
       return state
}
}

export default updateComputeProgressMetricReducer