import React,{ useState } from 'react'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'

import Popup from '../../common/popup/PopUp'
import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import { getRoleMembers } from '../../services/user.service'
import { addMember, addMemberByController, teamList } from '../../services/teamMember.service'
import { fetchMemberDetail } from '../../services/user.service'
import { styled } from "@mui/material/styles"
import CustomizeButton from '../../common/customize-button/CustomizeButton';
import Avatar from '@mui/material/Avatar';
import {boldString} from '../../utils/utils'
import useDebounce from '../../hooks/useDebounce'

const useStyles = makeStyles({
    profileImg:{
        width:50,
        height:50,
        borderRadius:50,
        objectFit:"cover",
    },
    memberDetail:{
        display:"flex",
        flexDirection:"column"
    },
    labelName:{
        display:"inline-block",
        fontSize:12,
        fontFamily:"'Oxygen',sans-serif",
        whiteSpace :"nowrap",
        marginTop:1,
    },
    labelContainer:{
        marginLeft:8,
        fontSize:0,
    }
})

const CssTextField = styled(TextField)({
    "& label" : {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
    },
    // input label when focused
    "& label.Mui-focused": {
        color: "var(--primary-color-v2) !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
        "&.Mui-focused fieldset": {
            borderColor: "var(--primary-color-v2) !important"
        }
    }
})

const AddMember = (props) => {

    const { ownProps, requestMemberDetail, requestTeamMember } = props

    // const [ members, setMembers ] = useState([])
    const [ searchedValue, setSearchedValue ] = useState({
        value : "",
        typing: false,
        data : []
    })

    const classes = useStyles()
    const params = useParams()
    const history = useHistory()
    const path = useLocation().pathname
    const location = path.split("/")[2]

    const isMobile = useMediaQuery('(max-width:480px)')

    const className = isMobile ? 'p-3' : 'p-4 min-width-350px'

    const initialValues = {
        users : []
    }

    const onSubmit = (values) => {
        if(location === "team-member"){
            const valuesCopy = {...values}
            valuesCopy.users = getAddMemberId(values.users)
            addMember(valuesCopy).then((res) => {
                ownProps.handleClose()
                requestTeamMember(false)
            }).catch((error) => {
                if(error.response){
                    if(error.response.data){
                        toast.error(error.response.data.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }
            })
        } else {
            const valuesCopy = {...values}
            valuesCopy.users = getAddMemberId(values.users)
            addMemberByController(params.memberId, valuesCopy)
            .then((res) => {
                ownProps.handleClose()
                toast.success("Successfully added", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                history.goBack()
            }).catch((error) => {
                if(error.response){
                    if(error.response.data){
                        toast.error(error.response.data.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }
            })
        }
    }

    function getAddMemberId(array){
        return array.map((member) => {
            return member._id
        })
    }

    function handleInputChange(event, value){
        setSearchedValue( ps => {
            return {
                ...ps,
                value : event.target.value,
                typing : true,
                data : []
            }
        })
        searchDebounce(event.target.value)
    }

    const searchDebounce = useDebounce((nextString) => {
        if(nextString && nextString.length > 0){
            getRoleMembers(nextString, params.memberId)
                .then(res => {
                    setSearchedValue(ps => {
                        return {
                            ...ps,
                            data : res.data?.users,
                            typing: false
                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setSearchedValue(ps => {
                return {
                    ...ps,
                    value : "",
                    typing : false,
                    data : []
                }
            })
        }
    }, 1000)

    return (
        <Popup handleClose={ownProps.handleClose} open={ownProps.open} fullWidth={isMobile}>
            <div className={className}>
                <h4 className="lato-fs22-normal-lh32 font-bold text-center mb-0">Add member</h4>
                <div>
                    <Formik 
                        initialValues = {initialValues}
                        onSubmit = {onSubmit} >
                        {props => {
                            const { setFieldValue } = props
                            return (
                                <Form>
                                    <Autocomplete
                                        multiple
                                        options={searchedValue?.data || []}
                                        name='users'
                                        id="tags-outlined"
                                        getOptionLabel={(suggestion) => suggestion.email.id}
                                        renderOption={
                                            (props, option) => {
                                                return (
                                                    <div key={option._id} className="d-flex ps-2" {...props}>
                                                        <Avatar className={classes.profileImg} src={option.profile_pic} />
                                                        <div className="ms-2">
                                                            { 
                                                                !isEmpty(option.person_name) ?
                                                                    <p 
                                                                        className="lato-fs15-normal-lh20 mb-0"
                                                                    >
                                                                        {`${option.person_name.first_name}  ${option.person_name.last_name}`}
                                                                    </p> 
                                                                    : null

                                                            }
                                                            { 
                                                                !isEmpty(option.email) ?
                                                                    <p className="lato-fs15-normal-lh20 mb-0" dangerouslySetInnerHTML={{__html: boldString(option.email.id, searchedValue.value)}} />
                                                                    : null
                                                            }
                                                            { 
                                                                !isEmpty(option.user_subscriber_mapping) && !isEmpty(option.user_subscriber_mapping[0]) && option.user_subscriber_mapping[0].job_role ? 
                                                                <p
                                                                    className="lato-fs12-normal-lh15 mb-0"
                                                                >
                                                                    {option.user_subscriber_mapping[0].job_role}
                                                                </p> 
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        onInputChange={handleInputChange}
                                        onChange={(event, value) => {
                                            setFieldValue('users', value)
                                        }}
                                        freeSolo
                                        renderInput={(params) => {
                                            const inputProps = params.inputProps;
                                            inputProps.autoComplete = "off";
                                            return (
                                                <CssTextField
                                                    {...params}
                                                    inputProps={inputProps}
                                                    name='users'
                                                    variant="outlined"
                                                    label="Email Id"
                                                    placeholder="Enter email id"
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            )
                                        }}
                                    />
                                    <div className="d-flex justify-content-center mt-3">
                                        <CustomizeButton 
                                            variant="secondary-color-button"
                                            type="button"
                                            handleClick={ownProps.handleClose}
                                            label="Cancel"
                                        />
                                        <CustomizeButton
                                            className="ms-3" 
                                            variant="primary-color-button"
                                            type="submit"
                                            label="Add Member"
                                        />
                                    </div>
                                </Form>
                            )
                        }}
                </Formik>
                </div>
            </div>
        </Popup>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        state : state,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestMemberDetail : (userId) => dispatch(fetchMemberDetail(userId)),
        requestTeamMember : (wfmboolean) => dispatch(teamList(wfmboolean)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddMember)