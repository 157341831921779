import { REQUEST_COMPOSE_ACTION,
    COMPOSE_ACTION_FAILURE,  
    COMPOSE_ACTION_SUCCESS,
    CLEAR_COMPOSE_ACTION_STATES } from './compose.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const composeActionReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_COMPOSE_ACTION : 
            return {
                ...state,
                loading: true,
                error: null
            }

        case COMPOSE_ACTION_SUCCESS: 
            return {
                ...state,
                loading: false,
                response : action.payload
            }

        case COMPOSE_ACTION_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_COMPOSE_ACTION_STATES:
            return {
                loading : false,
                response : {},
                error : null
            }
            
        default : 
            return state
    }
}

export default composeActionReducer