import { REQUEST_DELETE_COURSE, 
         DELETE_COURSE_SUCCESS,
         DELETE_COURSE_FAILURE,
         CLEAR_DELETE_COURSE } from './DeleteCourseTypes'

const initialState = {
    loading: false,
    response: {},
    error: null
}

const deleteCourseReducer = ( state = initialState, action ) =>{
    switch(action.type){
        case REQUEST_DELETE_COURSE : 
            return {
                ...state,
                loading : true,
                error: null
            }

        case DELETE_COURSE_SUCCESS : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case DELETE_COURSE_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case CLEAR_DELETE_COURSE : 
            return {
                ...state,
                loading: false,
                response: {},
                error: null
            }

        default : 
            return state
    }
}

export default deleteCourseReducer