import { REQUEST_CU_DEPARTMENTS, 
        CU_DEPARTMENTS_SUCCESS, 
        CU_DEPARTMENTS_FAILURE, 
        CLEAR_CUDEPARTMENTS_STATE } from './CUDepartment.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const cuDepartmentReducer = ( state = initialState, action ) =>  {
    switch(action.type) {
        case REQUEST_CU_DEPARTMENTS :
            return{
                ...state,
                loading : true,
                error : null
            }

        case CU_DEPARTMENTS_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case CU_DEPARTMENTS_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_CUDEPARTMENTS_STATE : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default : 
            return state
    }
}

export default cuDepartmentReducer