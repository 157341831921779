import React from 'react'
import './customize-input-box.css'

const CustomizeInputBox = (props) => {

    const className = props.className ? `customize-input-box ${props.className}` : 'customize-input-box'

    return (
        <input
            placeholder={props.placeholder}
            type={props.type} 
            name={props.name}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            autoComplete='off'
            className={className}
            autoFocus={Boolean(props.autoFocus)}
        />
    )
}

export default CustomizeInputBox