import { REQUEST_DEPARTMENTS, 
        FETCH_DEPARTMENTS_SUCCESS,
        FETCH_DEPARTMENTS_FAILURE,
        CLEAR_DEPARTMENTS_STATE } from './Department.types'

export const requestDepartments = () => {
    return {
        type : REQUEST_DEPARTMENTS
    }
}

export const saveDepartments = (response) => {
    return {
        type : FETCH_DEPARTMENTS_SUCCESS,
        payload : response
    }
}

export const fetchDepartmentsFailure = (error) => {
    return {
        type : FETCH_DEPARTMENTS_FAILURE,
        payload : error
    }
}

export const clearDepartmentsState = () => {
    return {
        type : CLEAR_DEPARTMENTS_STATE
    }
}