import { REQUEST_YOUR_MEETING, 
         YOUR_MEETING_SUCCESS,
         YOUR_MEETING_FAILURE, 
         CLEAR_YOUR_MEETING_STATE,
         INSERTING_MEETING_IN_YOUR_MEETING } from './YourMeeting.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const yourMeetingReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_YOUR_MEETING :
            return {
                ...state,
                loading : true,
                error :null
            }

        case YOUR_MEETING_SUCCESS : 
            return{
                ...state,
                loading : false,
                response : action.payload
            }
        
        case YOUR_MEETING_FAILURE : 
            return{
                ...state,
                loading : false,
                error : action.payload
            }

        case INSERTING_MEETING_IN_YOUR_MEETING : 
            return {
                ...state,
                
            }

        case CLEAR_YOUR_MEETING_STATE :
            return {
                ...state,
                loading: false,
                response: {},
                error : null
            }

        default : 
            return state
    }
}

export default yourMeetingReducer