import { REQUEST_DELETE_NOTE, 
        DELETE_NOTE_SUCCESS, 
        DELETE_NOTE_FAILURE,
        CLEAR_DELETE_NOTE_STATE } from './DeleteNote.types'

export const requestDeleteNote = () => {
    return {
        type : REQUEST_DELETE_NOTE
    }
}

export const deleteNoteSuccess = (response) => {
    return {
        type : DELETE_NOTE_SUCCESS,
        payload : response
    }
}

export const deleteNoteFailure = (error) => {
    return {
        type : DELETE_NOTE_FAILURE,
        payload : error
    }
}

export const clearDeleteNoteState = () => {
    return {
        type : CLEAR_DELETE_NOTE_STATE
    }
}