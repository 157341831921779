import React,{useRef} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import { NavLink, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt, faPlusCircle, faSignal, faThLarge} from '@fortawesome/free-solid-svg-icons'

import goalIcon from '../../assets/icons/bullseye-smoky-white.svg'
import activeGoalIcon from '../../assets/icons/bullseye-white.svg'

import './bottom-navgation.css'

const BottomNavigation = (props) => {

    const location = useLocation()
    const currentlocation = location.pathname.split('/')
    const goalImgRef = useRef(null)

    return (
        <Navbar 
            expand="lg" 
            variant="light" 
            fixed="bottom" 
            className="primary-color-bg"
        >
            <Container>
                <NavLink 
                    className="bottom-navlink-one" 
                    exact 
                    activeClassName="active" 
                    to="/vcf?mode=list-vcfs-by-lvl&lvl=0" 
                    isActive={() => currentlocation[1] == "vcf"}
                >
                    <div className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faThLarge} /> 
                        <span
                            className="lato-fs12-normal-lh15 font-bold mb-0" 
                            style={{marginTop: '2px'}}
                        >
                            VCF
                        </span>
                    </div>
                </NavLink>
                <NavLink 
                    className="bottom-navlink-one" 
                    exact 
                    activeClassName="active" 
                    to="/goal" 
                    isActive={() => currentlocation[1] === "goal"}
                >
                    <div 
                        className="d-flex flex-column align-items-center"
                        onMouseEnter={() => {
                            if(currentlocation[1] !== "goal") goalImgRef.current.src = activeGoalIcon
                        }}
                        onMouseLeave={() => {
                            if(currentlocation[1] !== "goal") goalImgRef.current.src = goalIcon
                        }} 
                    >
                        <img 
                            ref={goalImgRef}
                            className="goal-icon" src={currentlocation[1] === "goal" ? activeGoalIcon : goalIcon}
                            alt="" 
                        />
                        <span 
                            className="lato-fs12-normal-lh15 font-bold mb-0" 
                            style={{marginTop: '2px'}} 
                        >
                            Goals
                        </span>
                    </div>
                </NavLink>
                {
                    props.enableAddIcon ? 
                        <div className="bottom-navlink d-flex justify-content-center">
                            <FontAwesomeIcon 
                                className="fs-30 pt-cursor" 
                                icon={faPlusCircle} 
                                onClick={props.onAddIconClick}
                            />
                        </div> 
                        : null
                }
                <NavLink 
                    className="bottom-navlink-one" 
                    exact 
                    activeClassName="active" 
                    to="/metric" 
                    isActive={() => currentlocation[1] == "metric"}
                >
                    <div className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faSignal} />
                        <span 
                            className="lato-fs12-normal-lh15 font-bold mb-0" 
                            style={{marginTop: '2px'}} 
                        >
                            Metrics
                        </span>
                    </div>
                </NavLink>
                <NavLink 
                    className="bottom-navlink-one" 
                    exact 
                    activeClassName="active" 
                    to="/learning?selected=focus-of-learning" 
                    isActive={() => currentlocation[1] == "learning"}    
                >
                    <div className="d-flex flex-column align-items-center">
                        <FontAwesomeIcon icon={faListAlt} />
                        <span 
                            className="lato-fs12-normal-lh15 font-bold mb-0" 
                            style={{marginTop: '2px'}}     
                        >
                            Learning
                        </span>
                    </div>
                </NavLink>
            </Container>
        </Navbar>
    )
}

export default BottomNavigation