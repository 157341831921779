import { isEmpty, size } from 'lodash' 

export const insertNotes = (referenceArray, payload) => {
    if(!isEmpty(referenceArray)){
        if(referenceArray[0].name === "Notes"){
            if(payload.source_from === "external"){
                if(payload.reference_of === "courses"){
                    if(!isEmpty(referenceArray[0].course_content_links)) {
                        referenceArray[0].course_content_links = [payload].concat(referenceArray[0].course_content_links)
                    } else {
                        referenceArray[0].course_content_links = [payload]
                    }
                } else {
                    if(!isEmpty(referenceArray[0].reference_links)) {
                        referenceArray[0].reference_links = [payload].concat(referenceArray[0].reference_links)
                    } else {
                        referenceArray[0].reference_links = [payload]
                    }
                }
            } else {
                if(payload.reference_of === "courses"){
                    if(!isEmpty(referenceArray[0].course_content_files)) {
                        referenceArray[0].course_content_files = [payload].concat(referenceArray[0].course_content_files)
                    } else {
                        referenceArray[0].course_content_files = [payload]
                    }
                } else {
                    if(!isEmpty(referenceArray[0].reference_files)) {
                        referenceArray[0].reference_files = [payload].concat(referenceArray[0].reference_files)
                    } else {
                        referenceArray[0].reference_files = [payload]
                    }
                }
            }
        } else {
            let referenceObj = {}
            if(payload.source_from === "external"){
                if(payload.reference_of === "courses"){
                    referenceObj = { course_content_links : [payload], name: "Notes" }
                } else {
                    referenceObj = { reference_links : [payload], name: "Notes" }
                }
            } else {
                if(payload.reference_of === "courses"){
                    referenceObj = { course_content_files : [payload], name: "Notes" }
                } else {
                    referenceObj = { reference_files : [payload], name: "Notes" }
                }
            }
            referenceArray = [referenceObj].concat(referenceArray)
        }
    } else {
        let referenceObj = {}
            if(payload.source_from === "external"){
                if(payload.reference_of === "courses"){
                    referenceObj = { course_content_links : [payload], name: "Notes" }
                } else {
                    referenceObj = { reference_links : [payload], name: "Notes" }
                }
            } else {
                if(payload.reference_of === "courses"){
                    referenceObj = { course_content_files : [payload], name: "Notes" }
                } else {
                    referenceObj = { reference_files : [payload], name: "Notes" }
                }
            }
            referenceArray = [referenceObj]
    }
    return referenceArray
}

export const removeReferenceAtIndex = (referenceArray, groupIndex, referenceType, referenceIndex) => {
    if(!isEmpty(referenceArray)){
        let currentGroup = referenceArray[groupIndex]
        if(referenceType === "reference_files" || referenceType === "reference_links"){
            if(referenceType === "reference_files"){
                if(size(currentGroup.reference_files) === 1 && isEmpty(currentGroup.reference_links)){
                    referenceArray = referenceArray.filter((ref, index) => groupIndex!==index)
                } else {
                    currentGroup.reference_files = currentGroup.reference_files.filter((ref, index) => referenceIndex!==index )
                }
            } else {
                if(size(currentGroup.reference_links) === 1 && isEmpty(currentGroup.reference_files)){
                    referenceArray = referenceArray.filter((ref, index) => groupIndex!==index)
                } else {
                    currentGroup.reference_links = currentGroup.reference_links.filter((ref, index) => referenceIndex!==index )
                }
            }
        } else {
            if(referenceType === "course_content_files"){
                if(size(currentGroup.course_content_files) === 1 && isEmpty(currentGroup.course_content_links)){
                    referenceArray = referenceArray.filter((ref, index) => groupIndex!==index)
                } else {
                    currentGroup.course_content_files = currentGroup.course_content_files.filter((ref, index) => referenceIndex!==index )
                }
            } else {
                if(size(currentGroup.course_content_links) === 1 && isEmpty(currentGroup.course_content_files)){
                    referenceArray = referenceArray.filter((ref, index) => groupIndex!==index)
                } else {
                    currentGroup.course_content_links = currentGroup.course_content_links.filter((ref, index) => referenceIndex!==index )
                }
            }
        }
    }
    return referenceArray
}