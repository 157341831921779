import { REQUEST_SPECIFIC_METRIC,
         SPECIFIC_METRIC_SUCCESS,
         SPECIFIC_METRIC_FAILURE, 
         CLEAR_SPECIFIC_METRIC_STATE } from './specificMetric.types'

export const requestSpecificMetric = () => {
    return {
        type : REQUEST_SPECIFIC_METRIC
    }
}

export const specificMetricSuccess = (res) => {
    return {
        type : SPECIFIC_METRIC_SUCCESS,
        payload: res
    }
} 

export const specificMetricFailure = (error) => {
    return {
        type : SPECIFIC_METRIC_FAILURE,
        payload : error
    }
}

export const clearSpecificMetricState = () => {
    return {
        type : CLEAR_SPECIFIC_METRIC_STATE
    }
}