import Cookies from "js-cookie";
import _ from 'lodash';
import moment from 'moment'

const KEY_USER_ID = "user_id";
const KEY_AUTH_TOKEN = "auth_token";
const KEY_ROLE = "role";

// export const getUserCredentials = () => {
//     return {
//         token : Cookies.get(KEY_AUTH_TOKEN),
//         role : localStorage.getItem(KEY_ROLE)
//     }
// }

export const getAuthToken = () => {
    return Cookies.get(KEY_AUTH_TOKEN)
}

export const storeUserCredentials = (userCredentials) => {
    Cookies.set(KEY_AUTH_TOKEN, userCredentials.token)
    localStorage.setItem(KEY_ROLE, userCredentials.role)
    localStorage.setItem(KEY_USER_ID, userCredentials.userId)
}

export const removeUserCredentials = () => {
    Cookies.remove(KEY_AUTH_TOKEN)
    localStorage.removeItem(KEY_ROLE)
    localStorage.removeItem(KEY_USER_ID)
}

export const getRole = () => {
    return localStorage.getItem(KEY_ROLE)
}

export const getUserId = () => {
    return localStorage.getItem(KEY_USER_ID)
}

export const isAuthenticated = () => {
    if(!_.isEmpty(Cookies.get(KEY_AUTH_TOKEN))){
        return true;
    } else {
        return false;
    }
}

export const formatDate = (date) => {
    return moment.utc(date).local().format('YYYY-MM-DD')
}

export const formatYearAndMonth = (date) => {
    return moment.utc(date).local().format('YYYY-MM')
}

export const formatYearAndMonth2 = (date) => {
    return moment.utc(date).local().format('MMM YYYY')
}

export const serverBaseURL = "http://hr-webapp.s3-website.ap-south-1.amazonaws.com"

export const global = {
    app_name : "VGML"
}

export const isDate = function(date) {
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export function  deepSearch(object, key, predicate) {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

    for (let i = 0; i < Object.keys(object).length; i++) {
        let value = object[Object.keys(object)[i]];
        if (typeof value === "object" && value != null) {
            let o = deepSearch(object[Object.keys(object)[i]], key, predicate)
            if (o != null) return o
        }
    }
    return null
}

export const boldString = (fullStr, subStr) => fullStr.replace(new RegExp(subStr, 'gi'), (str) => `<b class="font-extra-bold">${str}</b>`);