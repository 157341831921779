import React,{ useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import Box from '@mui/material/Box'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation' 
import Members from '../../../components/members/Members'
import InvitedMember from '../../../components/invited-member/InvitedMember'
import NonInvitedMembers from '../../../components/non-invited-members/NonInvitedMembers'
import { getRole } from '../../../utils/utils'
import { CONTROLLER_ROLE } from '../../../utils/constants'
import SimpleTab from '../../../components/tabs/simple-tab/simple-tab/SimpleTab'
import SimpleTabPanel from '../../../components/tabs/simple-tab/Simple-tab-panel/SimpleTabPanel'
import useDebounce from '../../../hooks/useDebounce'
import { searchUser } from '../../../services/user.service'
import { connect } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import DesktopSearchBarTwo from '../../../components/desktop-layout/desktop-search-bar-two/DesktopSearchBarTwo'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MobSearchBox from '../../../components/search/mob-search-box/MobSearchBox'
import './user.css'

const Users = (props) => {

    const history = useHistory()
    const location = useLocation()
    const [ menu, setMenu ] = useState(false)
    const queryParams = qs.parse(location.search)

    const [search, setSearch] = useState(false)
    const [ searchedValue, setSearchedValue ] = useState({
        value : "",
        typing: false,
        data : []
    })
    const [ drawerOpen, setDrawerOpen ] = useState(false)

    const isDesktop = useMediaQuery('(min-width:992px)')

    const open = Boolean(menu);

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenu(null);
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    function handleCreateUser(){
        history.push(`/organisation/member/create`)
    }

    function handleBackArrow(){
        history.goBack()
    }    

    const handleChange = (event, newValue) => {
        if(newValue){
            history.push(`/organisation?selected=${newValue}`)
            if(searchedValue.value || searchedValue.typing || !isEmpty(searchedValue.data)){
                setSearchedValue({
                    value : "",
                    typing: false,
                    data : []
                })
            }
        }
    }

    function findMember(){
        if(getRole() === CONTROLLER_ROLE){
            if(queryParams.selected === "members"){
                return "active"
            }else if(queryParams.selected === "invited-members"){
                return "invited"
            }else {
                return "uninvited"
            }
        }else{
            return "active"
        }
    }

    const handleSearchInputChange = (e) => {
        setSearchedValue( ps => {
            return {
                ...ps,
                value : e.target.value,
                typing : true,
            }
        })
        searchDebounce(e.target.value,findMember())
    }

    const searchDebounce = useDebounce((nextString, type) => {
        if(nextString.length > 0){
            searchUser(type,nextString)
            .then((res) => {
                setSearchedValue(ps => {
                    return {
                        ...ps,
                        data : res.data?.data,
                        typing: false
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        }else{
            setSearchedValue(ps => {
                return {
                    ...ps,
                    value : "",
                    typing : false,
                    data : []
                }
            })
        }
    }, 1000)

    function searchOpen(){
        setSearch(true)
    }

    function searchClose(){
        setSearch(false)
        setSearchedValue({
            value : "",
            typing : false,
            data : [],
        })
    }

    useEffect(() => {
        setSearch(false)
        if(search){
            setSearchedValue({
                value : "",
                typing : false,
                data : []
            })
        }
    },[queryParams.selected])

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    return (
        <Container>
            {
                isDesktop ?
                    <>
                        <Navbar 
                            open={open}
                            menu={menu}
                            handlePopoverClose={handleMenuClose}
                            handlePopoverOpen={handleMenuOpen}
                            enableProfileButton={true} 
                            enableMore={true} 
                            enableLogout={true}
                            enableNotification={true}
                            notificationOnClick={onClickNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar addIcon={true} addIconTooltip="Create Member" onAddIconClick={handleCreateUser} />
                            <ProcessMainContainer variant="noBackground" >
                                {
                                    getRole() === CONTROLLER_ROLE ?
                                        <>
                                            <Box sx={{paddingLeft: '5%', paddingRight: '5%'}} >
                                                <SimpleTab
                                                    value={queryParams.selected ? queryParams.selected : "members"} 
                                                    onChange={handleChange}
                                                    tabArray={[ 
                                                        {label:"Members", value:"members"},
                                                        {label:"Invited", value:"invited-members"},
                                                        {label:"Uninvited", value:"non-invited-members"}
                                                    ]}
                                                    variant="desktop"
                                                    additionalComponent = { 
                                                        <div style={{display:"flex", alignItems: 'center', marginLeft:"auto"}}>
                                                            <div className='me-2'>
                                                                <DesktopSearchBarTwo 
                                                                    inputBox={{
                                                                        name: "search",
                                                                        placeholder: "Search",
                                                                        onChange: handleSearchInputChange, 
                                                                        value: searchedValue.value
                                                                    }}
                                                                    showCloseIcon={Boolean(searchedValue.value)}
                                                                    onSearchCloseClick={searchClose}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </Box>
                                            <hr style={{marginBottom: 0, marginTop: 0}}/>
                                            <SimpleTabPanel 
                                                value="members"
                                                selectedTab={queryParams.selected} 
                                                containerClassname="white-bg"
                                            >
                                                <div 
                                                    style={{
                                                        height: 'calc(100vh - 113px)',
                                                        backgroundColor: 'var(--tertiary-color-v2)',
                                                    }}
                                                    className='d-flex'
                                                >
                                                    <Members 
                                                        searchedValue={searchedValue}
                                                        search={search}
                                                    />
                                                </div>
                                            </SimpleTabPanel>
                                            <SimpleTabPanel
                                                containerClassname="white-bg"
                                                value="invited-members" 
                                                selectedTab={queryParams.selected} 
                                            >
                                                <div 
                                                    style={{
                                                        height: 'calc(100vh - 113px)', 
                                                        backgroundColor: 'var(--tertiary-color-v2)',
                                                        paddingTop: '1rem',
                                                        paddingBottom: '1rem'
                                                    }}
                                                    className="overflow-auto"
                                                >
                                                    <InvitedMember
                                                        searchedValue={searchedValue}
                                                    />
                                                </div>
                                            </SimpleTabPanel>
                                            <SimpleTabPanel
                                                containerClassname="white-bg"
                                                value="non-invited-members" 
                                                selectedTab={queryParams.selected}
                                            >
                                                <div
                                                    style={{
                                                        height: 'calc(100vh - 113px)', 
                                                        backgroundColor: 'var(--tertiary-color-v2)', 
                                                        paddingTop: '1rem', 
                                                        paddingBottom: '1rem'
                                                    }}
                                                    className="overflow-auto"
                                                > 
                                                    <NonInvitedMembers 
                                                        searchedValue={searchedValue}
                                                    />
                                                </div>
                                            </SimpleTabPanel>
                                        </>
                                        : <>
                                            <div 
                                                style={{
                                                    display:"flex", 
                                                    alignItems: 'center', 
                                                    justifyContent: 'end', 
                                                    paddingLeft: '5%', 
                                                    paddingRight: '5%',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                                                }} 
                                                className="py-1"
                                            >
                                                <div className='me-2'>
                                                    <DesktopSearchBarTwo 
                                                        inputBox={{
                                                            name: "search",
                                                            placeholder: "Search",
                                                            onChange: handleSearchInputChange, 
                                                            value: searchedValue.value
                                                        }}
                                                        showCloseIcon={Boolean(searchedValue.value)}
                                                        onSearchCloseClick={searchClose}
                                                    />
                                                </div>
                                            </div>
                                            <div className='content-area-height-members-for-users-desktop bg-tertiary-color-v2 d-flex'>
                                                <Members 
                                                    searchedValue={searchedValue}
                                                    search={search}
                                                />
                                            </div>
                                        </>
                                }
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    : <>
                        <Navbar 
                            variant="mobile"
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={onClickNotification}
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawerOpen}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className='content-area-height'>
                            <div className="d-flex px-3 py-1 align-items-center justify-content-between" style={{backgroundColor: 'white'}} >
                                <IconButton onClick={handleBackArrow}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <MobSearchBox
                                    containerProps={{
                                        background : 'var(--tertiary-color-v2)', marginTop: '2px', marginBottom: "2px"
                                    }}
                                    name="search"
                                    placeholder="Search"
                                    onChange={handleSearchInputChange}
                                    value={searchedValue.value}
                                    showCloseIcon={Boolean(searchedValue.value)}
                                    onSearchCloseClick={searchClose}
                                />
                            </div>
                            {
                                getRole() === CONTROLLER_ROLE ?
                                    <>
                                        <Box 
                                            sx={{
                                                background:" white",
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                                            }}
                                        >
                                            <SimpleTab 
                                                value={queryParams.selected}
                                                onChange={handleChange}
                                                tabVariant="fullWidth"
                                                variant="mobile"
                                                tabArray={[ 
                                                    {label:"Members", value:"members"},
                                                    {label:"Invited", value:"invited-members"},
                                                    {label:"Uninvited", value:"non-invited-members"}
                                                ]}
                                            />
                                        </Box>
                                        <div className="content-area-height-members bg-tertiary-color-v2 overflow-auto" >
                                            <SimpleTabPanel
                                                value="members"
                                                selectedTab={queryParams.selected}
                                            >
                                                <Members 
                                                    searchedValue={searchedValue} 
                                                    search={search}
                                                />
                                            </SimpleTabPanel>
                                            <SimpleTabPanel 
                                                value="invited-members" 
                                                selectedTab={queryParams.selected} 
                                            >
                                                <InvitedMember 
                                                    searchedValue={searchedValue}
                                                />
                                            </SimpleTabPanel>
                                            <SimpleTabPanel 
                                                value="non-invited-members" 
                                                selectedTab={queryParams.selected}
                                            >
                                                <NonInvitedMembers 
                                                    searchedValue={searchedValue}
                                                />
                                            </SimpleTabPanel>
                                        </div>
                                    </>
                                    : <div 
                                        className='content-area-height-members-for-users bg-tertiary-color-v2' 
                                        style={{
                                            borderTop: '1px solid rgba(0, 0, 0, 0.1)'
                                        }}
                                    >
                                        <Members 
                                            searchedValue={searchedValue} 
                                            search={search}
                                        />
                                    </div>
                            }
                        </div>
                        <BottomNavigation onAddIconClick={handleCreateUser} enableAddIcon={true} />
                    </>
            }
        </Container>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        userLogout : () => dispatch({
            type :"USER_LOGOUT"
        }),
    }
}

export default connect(null, mapDispatchToProps)(Users)