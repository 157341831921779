import React,{ useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import DropdownTreeSelect from 'react-dropdown-tree-select'

import { requestObjectiveForSelect } from '../../services/videoConference.service'
import './objective-tab.css'

const ObjectiveTab = (props) => {

    const { objectiveState, requestObjectives } = props

    const [ pageLoading,setPageLoading ] = useState(true)

    const objectives = objectiveState.response.objectives

    function getObjInFormat(){
        let objectivesCopy = JSON.parse(JSON.stringify(objectives))
        let objectivesArray = []
        for(let inputObjLvlZero of objectivesCopy){
            let objLvlZero = {
                label : inputObjLvlZero.name,
                value : inputObjLvlZero._id
            }
            if(!isEmpty(props.selected.objectives) && formatToValueArray(props.selected.objectives).includes(inputObjLvlZero._id)){
                objLvlZero.checked = true
            }
            if(!isEmpty(inputObjLvlZero.sub_objectives)){
                objLvlZero.children = []
            }
            if(!isEmpty(inputObjLvlZero.sub_objectives)){
                for(let inputObjLvlFirst of inputObjLvlZero.sub_objectives){
                    let objLvlFirst = {
                        label : inputObjLvlFirst.name,
                        value : inputObjLvlFirst._id,
                    }
                    if(!isEmpty(props.selected.objectives) && formatToValueArray(props.selected.objectives).includes(inputObjLvlFirst._id)){
                        objLvlFirst.checked = true
                    }
                    if(!isEmpty(inputObjLvlFirst.sub_objectives)){
                        objLvlFirst.children = []
                    }
                    if(!isEmpty(inputObjLvlFirst.sub_objectives)){
                        for(let inputObjLvlSecond of inputObjLvlFirst.sub_objectives){
                            let objLvlSecond = {
                                label : inputObjLvlSecond.name,
                                value : inputObjLvlSecond._id,
                            }
                            if(!isEmpty(props.selected.objectives) && formatToValueArray(props.selected.objectives).includes(inputObjLvlSecond._id)){
                                objLvlSecond.checked = true
                            }
                            if(!isEmpty(inputObjLvlSecond.sub_objectives)){
                                objLvlSecond.children = []
                            }
                            if(!isEmpty(inputObjLvlSecond.sub_objectives)){
                                for(let inputObjLvlThird of inputObjLvlSecond.sub_objectives){
                                    let objLvlThird = {
                                        label : inputObjLvlThird.name,
                                        value : inputObjLvlThird._id
                                    }
                                    if(!isEmpty(props.selected.objectives) && formatToValueArray(props.selected.objectives).includes(inputObjLvlThird._id)){
                                        objLvlThird.checked = true
                                    }
                                    objLvlSecond.children.push(objLvlThird)
                                }
                            }
                            objLvlFirst.children.push(objLvlSecond)
                        }
                    }
                    objLvlZero.children.push(objLvlFirst)
                }
            }
            objectivesArray.push(objLvlZero)
        }
        return objectivesArray
    }

    useEffect(() => {
        if(isEmpty(objectiveState.response)){
            requestObjectives()
        }
    }, [])

    function formatToValueArray(array){
        return array.map((item) => item.value)
    }

    useEffect(() => {
        if( !objectiveState.loading && !isEmpty(objectiveState.response)){
            // console.log(objectiveState.response)
            //console.log("objFormat", getObjInFormat(objectives))
            setPageLoading(false)
        }

        if(!objectiveState.loading && !isEmpty(objectiveState.error)){
            console.log(objectiveState.error)
            setPageLoading(false)
            if(!isEmpty(objectiveState.error)){
                if(objectiveState.error.data){
                    toast.error(objectiveState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[objectiveState])

    const onChange = (currentNode, selectedNodes) => {
        if(!isEmpty(selectedNodes)){
            let valuesArr = []
            for(const node of selectedNodes){
                valuesArr.push({
                    value : node.value,
                    label : node.label
                })
            }
            props.setSelected((prevState) => {
                return {
                    ...prevState,
                    objectives : [...valuesArr]
                }
            })
        } else {
            props.setSelected((prevState) => {
                return {
                    ...prevState,
                    objectives : selectedNodes
                }
            })
        }
    }

    return (
        <div className="tab-container-300px mt-3 px-3">
            <h2 className="font-bold text-center lato-fs-20-normal-lh32 mb-3">Select VCF</h2>
            { !pageLoading && !isEmpty(objectives) ?
                <DropdownTreeSelect data={getObjInFormat()} texts={{ placeholder: 'Select VCF' }} onChange={onChange} /> : null
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        objectiveState : state.objectiveForSelect
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        requestObjectives : () => dispatch(requestObjectiveForSelect())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ObjectiveTab)