import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dropdown-tree-select/dist/styles.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Profile from './pages/profile/Profile';
import PublicRoute from './routes/PublicRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import SignUp from './pages/authentication/sign-up/SignUp';
import SignIn from './pages/authentication/sign-in/SignIn';
import Objectives from './pages/objectives/objective/Objectives';
import CUObjective from './pages/objectives/create-or-edit-objective/AddOrEditObjective';
import ObjectivesDetails from './pages/objectives/objectives-details/ObjectiveDetails';
import { getAuthToken } from './utils/utils'
import Goals from './pages/goals/goals/Goals';
import CreateOrEditGoals from './pages/goals/create-or-edit-goals/CreateOrEditGoals';
import GoalsDetails from './pages/goals/goals-details/GoalsDetails';
import Metrics from './pages/metrics/metrics/Metrics';
import CreateOrEditMetric from './pages/metrics/create-or-edit-metric/CreateOrEditMetric';
import MetricDetails from "./pages/metrics/metric-detail/MetricDetails";
import Learning from "./pages/learning/learning/Learning";
import CreateOrEditLearning from "./pages/learning/create-or-edit-learning/CreateOrEditLearning";
import learningDetails from "./pages/learning/learning-area-details/LearningAreaDetails";
import CuLearningCourse from "./pages/learning/create-or-edit-learning-course/CuLearningCourse";
import CourseDetails from "./pages/learning/course-detail/CourseDetails";
import Notification from './pages/notification/notification/Notification'
import SubObjectiveAggregate from "./pages/objectives/sub-objective-aggregate/SubObjectiveAggregate";
import Compose from "./pages/notification/compose/Compose";
import SentDetail from "./pages/notification/sent-detail/SentDetail";
import InboxActionDetail from "./pages/notification/objective-inbox-detail/ObjectiveInboxDetail";
import InboxDetail from "./pages/notification/inbox-detail/InboxDetail";
import ModifyObjective from "./pages/notification/modify-objective/ModifyObjective";
import VideoConference from "./pages/learning/video-conference/VideoConference";
import CreateVideoConference from "./pages/learning/create-video-conference/CreateVideoConference";
import VideoConferenceLanding from "./pages/video-conference-landing/VideoConferenceLanding";
import VCJoin from "./pages/vc-join/VCJoin";
import VideoChat from "./pages/video-chat/VideoChat";
import Users from "./pages/member/user/Users";
import CreateOrEditUser from "./components/create-or-edit-user/CreateOrEditUser";
import MemberDetail from "./pages/member/member-detail/MemberDetail";
import Department from "./pages/department/Department";
import Branches from "./pages/branch/Branches";
import GigUsersSignIn from "./pages/gig-users-sign-in/GigUsersSignIn";
import TeamMember from "./pages/team-member/TeamMember";
import SingleUserDetail from "./pages/single-user-detail/SingleUserDetail";
import ReviewTeam from "./pages/review-team/ReviewTeam";
import ReviewActionsTab from "./pages/review-action-tab/ReviewActionsTab";
import ReviewAction from "./components/review-action/ReviewAction";
import ObjectiveReviewModify from "./components/objective-review-modify/ObjectiveReviewModify";
import GoalInboxActionDetail from "./pages/goal-inbox-action-detail/GoalInboxActionDetail";
import ModifyGoal from "./pages/notification/modify-goal/ModifyGoal";
import MetricInboxDetail from "./pages/notification/metric-inbox-detail/MetricInboxDetail";
import ModifyMetric from "./pages/notification/modify-metric/ModifyMetric";
import LearningInboxDetail from "./pages/notification/learning-inbox-detail/LearningInboxDetail";
import ModifyLearning from "./pages/notification/modify-Learning/ModifyLearning";
import ModifyCourse from "./pages/notification/modify-course/ModifyCourse";
import ResetPassword from "./pages/authentication/reset-password/ResetPassword";
import Subscription from "./pages/subscription/Subscription";
import PrivacyPolicy from "./pages/footer-pages/PrivacyPolicy";
import AboutUs from "./pages/footer-pages/AboutUs";
import ContactUs from "./pages/footer-pages/ContactUs";
import TermsAndCondition from "./pages/footer-pages/TermsAndCondition";
import RefundAndCancellations from "./pages/footer-pages/RefundAndCancellations";
import ShippingPolicy from "./pages/footer-pages/ShippingPolicy";
import UpdateSubscription from "./pages/update-subscription/UpdateSubscription";
import LandingPage from "./pages/home-page/landing-page/LandingPage";
import Product from "./pages/home-page/product/Product";
import Consult from "./pages/home-page/consult/Consult";

function App() {
  return (
    <Router>
      <div className="App">
        <ToastContainer />
        <Switch>
            {/* Landing page */}
            <PublicRoute exact path="/" redirectPathname={getAuthToken() ? '/vcf' : "/sign-in"} component={LandingPage} />
            <PublicRoute exact path="/product" component={Product} />
            <PublicRoute exact path="/consult-us" component={Consult} />
            {/* Authentication */}
            <PublicRoute exact key="sign-up" path="/sign-up" redirectPathname={getAuthToken() ? '/vcf' : "/sign-up"} component={SignUp} />
            <PublicRoute exact key="subscribe" path="/subscribe" redirectPathname={getAuthToken() ? '/vcf' : "/subscribe"} component={SignUp} />
            <PublicRoute exact path="/sign-in" redirectPathname={getAuthToken() ? '/vcf' : "/sign-in"} component={SignIn} />
            <PublicRoute exact path="/sign-in/gig-user" component={GigUsersSignIn} />
            {/* VCFs */}
            <ProtectedRoute exact path={["/vcf", "/company-view"]} component={Objectives} />
            <ProtectedRoute exact path={["/vcf/create", "/vcf/edit"]} component={CUObjective} />
            <ProtectedRoute exact path="/vcf/:vcfId" component ={ObjectivesDetails} />
            {/* Goals */}
            <ProtectedRoute exact path="/goal" component={Goals} />
            <ProtectedRoute exact path={["/goal/create", "/goal/edit"]} component={CreateOrEditGoals} />
            <ProtectedRoute exact path="/goal/:goalId" component={GoalsDetails} />
            {/* Metrics */}
            <ProtectedRoute exact path="/metric" component={Metrics} />
            <ProtectedRoute exact path={["/metric/create", "/metric/edit"]} component={CreateOrEditMetric} />
            <ProtectedRoute exact key="metric-detail" path="/metric/:metricId/expanded-graph" component={MetricDetails}/>
            <ProtectedRoute exact key="graph-detail" path="/metric/:metricId" component={MetricDetails} />
            {/* Learning */}
            <ProtectedRoute exact path="/learning" component={Learning} />
            <ProtectedRoute exact path={["/learning/create","/learning/edit"]} component={CreateOrEditLearning} />
            <ProtectedRoute exact path="/learning/:learningCircleId/learning-area/:segmentId/:segment" component={learningDetails} />
            <ProtectedRoute exact 
              path={["/learning/learning-area/:segmentId/course/create", "/learning/learning-area/:segmentId/course/edit/:courseId"]} 
              component={CuLearningCourse}/>
            <ProtectedRoute exact path="/learning/learning-area/course/:courseId" component={CourseDetails} />
            {/* profile */}
            <ProtectedRoute exact path="/profile" component={Profile} />
            {/* notification and reference */}
            <ProtectedRoute exact path="/notification-reference" component={Notification} />
            <ProtectedRoute exact path="/compose" component={Compose} />
            <ProtectedRoute exact path="/notification/:notificationId/sent" component={SentDetail}/>
            <ProtectedRoute exact path="/notification/:notificationId/inbox" component={InboxDetail} />
            {/* notification vcf */}
            <ProtectedRoute exact path="/notification/:notificationId/vcf/:objectiveId" component={InboxActionDetail} />
            <ProtectedRoute exact path="/notification/:notificationId/modify-vcf" component={ModifyObjective} />
            {/* notification goal */}
            <ProtectedRoute exact path="/notification/:notificationId/goal/:goalId" component={GoalInboxActionDetail} />
            <ProtectedRoute exact path="/notification/:notificationId/modify-goal" component={ModifyGoal} />
            {/* notification metric */}
            <ProtectedRoute exact path="/notification/:notificationId/metric/:metricId" component={MetricInboxDetail} />
            <ProtectedRoute exact path="/notification/:notificationId/modify-metric" component={ModifyMetric} />
            {/* notification learning */}
            <ProtectedRoute exact 
              path={[
                "/notification/:notificationId/learning-area/:developmentId",
                "/notification/:notificationId/course/:courseId"
              ]} 
              component={LearningInboxDetail} />
            <ProtectedRoute exact path="/notification/:notificationId/modify-learning-area" component={ModifyLearning} />
            <ProtectedRoute exact path="/notification/:notificationId/modify-course" component={ModifyCourse} />
            {/* video conference */}
            <ProtectedRoute exact path="/video-conference" component={VideoConference} />
            <ProtectedRoute exact path="/video-conference/create" component={CreateVideoConference} />
            <ProtectedRoute exact path="/video-conference/:channelId" component={VideoChat}/>
            {/* members */}
            <ProtectedRoute exact path="/organisation" component={Users} />
            <ProtectedRoute exact path={["/organisation/member/create", "/organisation/member/edit/:memberId"]} component={CreateOrEditUser} />
            <ProtectedRoute exact path="/organisation/member/:memberId" component={SingleUserDetail}  />
            {/* departments */}
            <ProtectedRoute exact path="/department" component={Department} />
            {/* location */}
            <ProtectedRoute exact path="/location" component={Branches} />
            {/* team / workflow-mapping */}
            <ProtectedRoute exact path={["/team-member","/workflow-mappings"]}component={TeamMember} />
            {/* review */}
            <ProtectedRoute exact path="/review" component={ReviewTeam} />
            <ProtectedRoute exact path="/review/:userId" component={ReviewActionsTab} />
            <ProtectedRoute exact path="/review/:userId/action/:actionId" component={ReviewAction} />
            <ProtectedRoute exact path="/review/:userId/action/:actionId/modify" component={ObjectiveReviewModify} />
            {/* reset password */}
            <Route exact path="/reset-password" component={ResetPassword} />
            {/* subscription details */}
            <ProtectedRoute exact path="/subscription" component={Subscription} />

            {/* update subscription */}
            <ProtectedRoute exact path="/subscription/update" component={UpdateSubscription} />

            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/about-us" component={ AboutUs } />
            <Route exact path="/contact-us" component={ ContactUs } />
            <Route exact path="/terms-and-conditions" component={ TermsAndCondition } />
            <Route exact path="/refunds-and-cancellations" component={ RefundAndCancellations } />
            <Route exact path="/shipping-policy" component={ ShippingPolicy } />
        </Switch>
      </div>
    </Router>
  );
}

export default App;