import { REQUEST_MEMBER_DETAIL,
        SAVE_MEMBER_DETAIL,
        MEMBER_DETAIL_FAILURE,
        CLEAR_MEMBER_DETAIL_STATE, 
        UPDATE_SETTINGS} from './membersDetail.types'

export const requestMemberDetail = () => {
    return {
        type : REQUEST_MEMBER_DETAIL
    }
}

export const fetchedMemberDetailSuccess = (response) => {
    return {
        type : SAVE_MEMBER_DETAIL,
        payload : response
    }
}

export const fetchedMemberDetailFailure = (error) => {
    return {
        type : MEMBER_DETAIL_FAILURE,
        payload : error
    }
}

export const updateMemberSetting = (updateSettingsRes) => {
    return {
        type : UPDATE_SETTINGS,
        payload : updateSettingsRes
    }
}

export const clearMemberDetailState = () => {
    return {
        type : CLEAR_MEMBER_DETAIL_STATE
    }
}