import { REQUEST_CHOOSE_PATH,
         CHOOSE_PATH_SUCCESS,
         CHOOSE_PATH_FAILURE,
         CLEAR_CHOOSE_PATH_STATE } from './ChoosePathObjective.types'

export const requestChoosePathObjective = () => {
    return {
        type : REQUEST_CHOOSE_PATH
    }
}

export const saveChoosePathObjective = (response) => {
    return {
        type : CHOOSE_PATH_SUCCESS,
        payload : response
    }
}

export const choosePathObjectiveFailure = (error) => {
    return {
        type : CHOOSE_PATH_FAILURE,
        payload : error
    }
}

export const clearChoosePathObjective = () => {
    return {
        type : CLEAR_CHOOSE_PATH_STATE
    }
}