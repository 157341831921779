import React from 'react'

const MainContainer = (props) => {
    return (
        <section style={{width: "100%", height: "calc(100vh - 107px)"}} className={props.className || ""}>
            {props.children}
        </section>
    )
}

export default MainContainer