import React from 'react'
import Chip from '@mui/material/Chip'
import { capitalize } from 'lodash'

const StatusChip = (props) => {
    return (
        <div className="mt-4 mb-2">
            <Chip label={capitalize(props.status)} variant="outlined" 
                sx={{ borderColor:props.status == "accepted" ? "green" : props.status == "rejected" ? "red" : "rgb(252, 163, 17)",
                color:props.status == "accepted" ? "green" : props.status == "rejected" ? "red" : "rgb(252, 163, 17)", fontFamily:"'Lato',sans-serif", fontSize:13, borderWidth : 2, fontWeight: 700 }} />
        </div> 
    )
}

export default StatusChip