import { REQUEST_FETCH_GOALS,
        FETCH_GOALS_SUCCESS,
        FETCH_GOALS_FAILURE,
        GOAL_ACTION_CHANGES,
        CLEAR_FETCH_GOALS } from './FetchGoalsTypes'

const initialState = {
    loading: false,
    goal_action_changes : false,
    response: {},
    error: null
}

const fetchGoalsReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_FETCH_GOALS:
            return {
                ...state,
                loading: true,
                goal_action_changes : false,
                error: null
            }

        case FETCH_GOALS_SUCCESS : 
            return {
                ...state,
                loading: false,
                goal_action_changes : false,
                response : action.payload
            }

        case FETCH_GOALS_FAILURE : 
            return {
                ...state,
                loading: false,
                goal_action_changes : false,
                error: action.payload
            }

        case GOAL_ACTION_CHANGES : 
            return {
                ...state,
                loading : true,
                goal_action_changes : true
            }

        case CLEAR_FETCH_GOALS : 
            return {
                ...state,
                loading: false,
                goal_action_changes : false,
                response: {},
                error: null
            }

        default : 
            return state
    }
}

export default fetchGoalsReducer