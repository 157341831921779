import ProtectedWebClient from '../utils/protected-web-client'

import {
    requestSentActionFetch,
    fetchSentActionSuccess,
    fetchSentActionFailure
} from '../redux/notification/sent/sent.actions'

import { requestFetchInboxAction, 
         fetchInboxActionSuccess,
         fetchInboxActionFailure } from '../redux/notification/inbox/inbox.action'

import { choosePathObjectiveFailure, requestChoosePathObjective, saveChoosePathObjective } from '../redux/notification/choose-path/ChoosePathObjective.action'
import { referenceFailure, referencesSuccess, requestReferences } from '../redux/notification/reference/Reference.action'
import { addNoteFailure, addNoteSuccess, requestAddNote } from '../redux/notification/add-note/AddNote.action'
import { deleteNoteFailure, deleteNoteSuccess, requestDeleteNote } from '../redux/notification/delete-note/DeleteNote.action'
import { fetchNotificationDetailFailure, fetchNotificationDetailSuccess, requestNotificationDetail } from '../redux/notification/notification-detail/notificationDetail.action'

export const fetchSentActions = (action) => {
    return async (dispatch) => {
        try {
            dispatch(requestSentActionFetch())
            const response = await ProtectedWebClient.get(`/mail/sent?action=${action}`)
            dispatch(fetchSentActionSuccess(response.data))
        } catch (error) {
            dispatch(fetchSentActionFailure(error.response))
        }
    }
}

export const fetchInboxActions = (action) => {
    return async (dispatch) => {
        try {
            dispatch(requestFetchInboxAction())
            const response = await ProtectedWebClient.get(`/mail/inbox?action=${action}`)
            dispatch(fetchInboxActionSuccess(response.data))
        }catch(error){
            dispatch(fetchInboxActionFailure(error.response))
        }
    }
}

export const rejectAction = (mailId,actionType,actionDetails) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/shared-action/${mailId}/reject`,actionDetails)
        .then((res) => {
            resolve(res)
        }).catch(error => {
            reject(error)
        })
    })
}

export const acceptAction = (mailId,actiontype) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.get(`/shared-action/${mailId}/${actiontype}/accept`)
        .then((res) => {
            resolve(res)
        }).catch((error)=> {
            reject(error)
        })
    })
}

export const developmentAcceptancePromise = (sharedActionId, circleSeg) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.get(`/shared-action/${sharedActionId}/learning-area/accept?at=${circleSeg}`)
        .then((res) => {
            resolve(res)
        }).catch((error)=> {
            reject(error)
        })
    })
}

export const developmentModificationPromise = (sharedActionId, payload, circleSeg) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/shared-action/${sharedActionId}/learning-area/modify?at=${circleSeg}`, payload)
        .then((res) => {
            resolve(res)
        }).catch((error)=> {
            reject(error)
        })
    })
}

export const maxLevelObjective = (maxLevel) => {
    return async (dispatch) => {
        try{
            dispatch(requestChoosePathObjective())
            const response = await ProtectedWebClient.get(`/objective/read/max-level/${maxLevel}`)
            dispatch(saveChoosePathObjective(response.data))
        }catch(error){
            dispatch(choosePathObjectiveFailure(error.response))
        }
    }
}

export const modifyAction = (sharedActionId, action, modifyDetails) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/shared-action/${sharedActionId}/${action}/modify`, modifyDetails)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const fetchReferences = (action) => {
    return async (dispatch) => {
        try {
            dispatch(requestReferences())
            const response = await ProtectedWebClient.get(`/action-reference/read?action=${action}`)
            dispatch(referencesSuccess(response.data))
        }catch(error){
            dispatch(referenceFailure(error))
        }
    }
}

export const addNote = (action, payload) => {
    return async (dispatch) => {
        try{
            dispatch(requestAddNote())
            const response = await ProtectedWebClient.post(`/action-reference/add-notes?action=${action}`, payload)
            dispatch(addNoteSuccess(response.data))
        }catch(error){
            dispatch(addNoteFailure(error.response))
        }
    }
}

export const deleteNote = (noteId) =>{
    return async (dispatch) => {
        try {
            dispatch(requestDeleteNote())
            const response = await ProtectedWebClient.delete(`/action-reference/notes/${noteId}/delete-notes`)
            dispatch(deleteNoteSuccess(response.data))
        }catch(error){
            dispatch(deleteNoteFailure(error.response))
        }
    }
}

export const updateNote = (noteId,payload) => {
    return async (dispatch) => {
        try{
            dispatch(requestAddNote())
            const response = await ProtectedWebClient.put(`/action-reference/notes/${noteId}/update-notes`,payload)
            dispatch(addNoteSuccess(response.data))
        }catch(error){
            dispatch(addNoteFailure(error.response))
        }
    }
}

export const deleteReference = (referenceId) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.delete(`/action-reference/${referenceId}/delete`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const notificationDetail = (mailId,action) => {
    return async (dispatch) => {
        try{
            dispatch(requestNotificationDetail())
            const response = await ProtectedWebClient.get(`/mail/${mailId}/read?type=${action}`)
            dispatch(fetchNotificationDetailSuccess(response.data))
        }catch(error){
            dispatch(fetchNotificationDetailFailure(error.response))
        }
    }
}

export const searchReference = (process, payload) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post(`/search/reference?search=${payload}&process=${process}`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const searchNotification = (process, type, payload) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post(`/search/notification?search=${payload}&process=${process}&type=${type}`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}