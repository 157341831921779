import { REQUEST_DEPARTMENTS, 
        FETCH_DEPARTMENTS_SUCCESS, 
        FETCH_DEPARTMENTS_FAILURE, 
        CLEAR_DEPARTMENTS_STATE } from './Department.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const departmentReducer = ( state = initialState, action ) =>  {
    switch(action.type) {
        case REQUEST_DEPARTMENTS :
            return{
                ...state,
                loading : true,
                error : null
            }

        case FETCH_DEPARTMENTS_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case FETCH_DEPARTMENTS_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_DEPARTMENTS_STATE : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default : 
            return state
    }
}

export default departmentReducer