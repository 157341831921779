import { REQUEST_CREATE_GOALS, 
         CREATE_GOAL_SUCCESS,
         CREATE_GOAL_FAILURE, 
         CLEAR_GOALS_STATES } from './GoalsType'

const initialState = {
    loading: false,
    response: {},
    error: null
}

const goalReducer = (state = initialState ,action) => {

    switch(action.type){
        case REQUEST_CREATE_GOALS : 
            return {
                ...state,
                loading: true,
                error : null
            }

        case CREATE_GOAL_SUCCESS : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case CREATE_GOAL_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_GOALS_STATES : 
            return {
                loading: false,
                response : {}, 
                error: null
            }

        default : 
            return state
    }
}

export default goalReducer