import { REQUEST_MEMBERS,
        SAVE_MEMBERS,
        MEMBERS_FAILURE,
        CLEAR_MEMBER_STATE } from './members.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const membersReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_MEMBERS :
            return {
                ...state,
                loading : true,
                error :null
            }

        case SAVE_MEMBERS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case MEMBERS_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_MEMBER_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default membersReducer