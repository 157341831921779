import React,{ useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, FieldArray, Field } from 'formik'
import * as Yup from 'yup'
import { isEmpty, size } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import qs from 'query-string'
import XLSX from 'xlsx'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import AddIcon from '@mui/icons-material/Add'

import { clearSpecificMetricState } from '../../../redux/specific-metric/specificMetric.action'
import { fetchCreateMetricData, specificMetric } from '../../../services/metric.service'
import HeaderContainer from '../../../common/header-container/HeaderContainer'
import BodyContainer from '../../../common/body-container/BodyContainer'
import { formatDate, isDate } from '../../../utils/utils'
import DatePicker from '../../../common/date-picker/DatePicker'
import InputBox from '../../../common/Input-box/InputBox'
import SelectBox from '../../../common/select-box/SelectBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import PublicWebClient from '../../../utils/public-web-client'
import SelectBoxValueById from '../../../common/select-box-value-id/SelectBoxValueById'
import GoalListPopup from '../../../components/goal-list-popup/GoalListPopup'
import { modifyAction } from '../../../services/notification.service'
import BarChart from '../../../components/chart/bar-chart/BarChart'
import LineChart from '../../../components/chart/line-chart/LineChart'
import { clearFetchMetrics } from '../../../redux/fetch-metric/FetchMetricAction'
import PieChart from '../../../components/chart/pie-chart/PieChart'
import DoughnutChart from '../../../components/chart/doughnut-chart/DoughnutChart'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { mailActionChanges } from '../../../redux/notification/notification-detail/notificationDetail.action'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import GanttChart from '../../../components/chart/gantt-chart/GanttChart'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import UploadFiles from '../../../components/upload-files/UploadFiles'
import Header from '../../../common/header/Header'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'

const METRICS_ATTACHMENT_NAMES = {
    data: 'action_data',
    reference: 'action_reference',
    graph_data: 'action_graph_data'
}

const ModifyMetric = (props) => {

    const { prerequisiteMetricDataState, 
            specificMetricState, 
            clearSpecificMetricState, 
            requestSpecificMetric, 
            prerequisiteMetricData,
            clearFetchMetricState,
            notificationActionChanges } = props

    const [pageLoading, setPageLoading] = useState({
        prerequisiteMetricData :true,
        metric : true 
    })
    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [graphDataFile, setGraphDataFile] = useState('')
    const [goalPopup, setGoalPopup] = useState(false)
    const [ currentGoal,setCurrentGoal ] = useState({})
    const [graphPreviewData, setGraphPreviewData] = useState({
        loading : false,
        data : []
    })
    const [validate, setValidate] = useState({isValidating: false, isValid: false})
    const [ navbarMoreIcon, setNavbarMoreIcon ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)
    const [ loadingButton, setLoadingButton ] = useState(false)

    let history = useHistory()
    let location = useLocation()
    const referenceFileRef = useRef()
    const graphDataFileRef = useRef()
    const formRef = useRef()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const goals = prerequisiteMetricDataState.response?.goals
    const graphDetail = specificMetricState.response

    const emptyLinkObj = {
        originalname : "",
        url : ""
    }

    const navbarMoreOpen = Boolean(navbarMoreIcon)

    const handleMenuOpen = (event) => {
        setNavbarMoreIcon(event.currentTarget)
    }

    const handleMenuClose = () => {
        setNavbarMoreIcon(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const initialValues = {
        goal: "",
        metric: "",
        graph_type: "",
        start_date: !isEmpty(graphDetail) && graphDetail.start_date ? formatDate(graphDetail.start_date) : "",
        end_date: !isEmpty(graphDetail) && graphDetail.end_date ? formatDate(graphDetail.end_date) :"",
        metric_description: !isEmpty(graphDetail) && graphDetail.metric_description ? graphDetail.metric_description : "",
        reference_links : !isEmpty(graphDetail) && !isEmpty(graphDetail.reference_links) ? getLinks(graphDetail.reference_links) : [emptyLinkObj]
    }
    
    const validationSchema = Yup.object({
        goal: Yup.string().required("Please select a goal"),
        metric: Yup.string().required(!isEmpty(currentGoal) ? "Please select the metric" : "Please select the goal"),
        graph_type: Yup.string()
            .when("goal", {
                is: () => {
                    if(graphDataFile){
                        return true
                    } else {
                        return false
                    }
                },
                then: Yup.string().required("Please select a graph type"),
                otherwise: Yup.string()
            }),
        metric_description: Yup.string(),
        start_date: Yup.date().required("Please select the start date")
                        .test("check range","Invalid date", (value) => {
                            if(!isEmpty(currentGoal)){
                                return moment(value).isBetween(moment(currentGoal.start_date).startOf("day"), moment(currentGoal.end_date).endOf("day"), undefined, '[]');
                            } else {
                                return false
                            }
                        }),
        end_date: Yup.date().required("Please select your end_date")
                    .min(Yup.ref('start_date'),"End date can't be before Start date")
                    .test('check for past dates', "End date can't be in past", (value) => {
                        if(value){
                            return (moment(value).endOf("day")).isSameOrAfter(moment())
                        } else {
                            return false
                        }
                    })
                    .test("check range","Invalid date", (value) => {
                        if(!isEmpty(currentGoal)){
                            return moment(value).isBetween(moment(currentGoal.start_date).startOf("day"), moment(currentGoal.end_date).endOf("day"), undefined, '[]');
                        } else {
                            return false
                        }
                    }),
        reference_links : Yup.array().of(
            Yup.object().shape({
                originalname: Yup.string(),
                url: Yup.string().when("originalname",{
                    is : (value) => !isEmpty(value),
                    then : Yup.string().required("Please enter the url").url("Invalid url"),
                    otherwise : Yup.string().url("Invalid url")
                })
            })
        )
        })

    function parseExcelFiles(file){
        var reader = new FileReader();
        setGraphPreviewData(prevState => {
            return {
                ...prevState,
                loading : true
            }
        })
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary', cellDates: true});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
    
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {
                blankrows: false,
                header: 'A',
                raw: true,
                rawNumbers: true
            });
            setGraphPreviewData(prevState => {
                return {
                    loading : false,
                    data : dataParse
                }
            })
        }
        reader.readAsBinaryString(file)
    }

    const onSubmit = (values) => {
        if(graphDataFile && !validate.isValid){
            return
        }
        const valuesCopy = JSON.parse(JSON.stringify(values))
        valuesCopy.start_date = (moment(valuesCopy.start_date).startOf('day')).toISOString()
        valuesCopy.end_date = (moment(valuesCopy.end_date).startOf('day')).toISOString()
        valuesCopy.reference_links = valuesCopy.reference_links.filter(item => item.original_name || item.url)
        const payload = updateFormData(valuesCopy)
        if(navigator.onLine){
            setLoadingButton(true)
            modifyAction(queryParams.actionId,"graph", payload)
            .then((res) => {
                clearSpecificMetricState()
                clearFetchMetricState()
                notificationActionChanges()
                setLoadingButton(false)
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                history.goBack()
                history.goBack()
            }).catch((err) => {
                if(err){
                    if(err.response){
                        if(err.response.data){
                            setLoadingButton(false)
                            toast.error(err.response.data.error, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    }
                }
            })
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function toggleGoalPopup(){
        setGoalPopup(!goalPopup)
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function getGoalById(objectives, id){
        return new Promise((resolve, reject) => {
            let appropriateGoalDoc = {}
            exit_loops:
            for(const objective of objectives){
                if(!isEmpty(objective.goals)){
                    for(const goal of objective.goals){
                        if(goal._id === id){
                            appropriateGoalDoc = goal
                            break exit_loops
                        }
                    }
                }
            }
            resolve(appropriateGoalDoc)
        })
    }

    function getGoalByName(objectives, name){
        return new Promise((resolve, reject) => {
            let appropriateGoalDoc = {}
            exit_loops:
            for(const objective of objectives){
                if(!isEmpty(objective.goals)){
                    for(const goal of objective.goals){
                        if(goal.name === name){
                            appropriateGoalDoc = goal
                            break exit_loops
                        }
                    }
                }
            }
            resolve(appropriateGoalDoc)
        })
    }

    function updateCurrGoal(id){
        getGoalById(goals, id).then(goal => {
            setCurrentGoal(goal)
        })
    }

    function getmetric(merticArray){
        return merticArray.map((metric) => {
            return {
                value : metric._id,
                label : metric.name
            }
        })
    }

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index != i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index != i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }

    function updateFormData(goalData){

        let metricDataCopy = JSON.parse(JSON.stringify(goalData))

        let metricFormData = new FormData()
        if(!isEmpty(existingReferences)){
            metricDataCopy.reference_files = existingReferences.map((referenceFile) => referenceFile._id)
        }else{
            metricDataCopy.reference_files=[]
        }
        metricFormData.append(METRICS_ATTACHMENT_NAMES.data, JSON.stringify(metricDataCopy))

        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                metricFormData.append(METRICS_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }

        if(graphDataFile){
            metricFormData.append(METRICS_ATTACHMENT_NAMES.graph_data, graphDataFile)
        }
        return metricFormData
    }
    
    function onclickDownloadTemplate(){
        PublicWebClient.get('/data-template/download', {
            responseType: 'blob',
            headers : {
                'Content-Type' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data-template.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err)
        })
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.map((link) => (
            contentLink.push({url: link.url, originalname : link.originalname})
        ))
        return contentLink

    }

    function getGraphPreviewComponent(graph){
        switch(graph){
            case "Bar chart" :
                return (
                    <BarChart graphDetail={graphPreviewData.data} variant="preview" />
                )
            
            case "Pie chart" :
                return (
                    <PieChart graphDetail={graphPreviewData.data} variant="preview" />
                )

            case "Doughnut chart" : 
                return (
                    <DoughnutChart variant="preview" graphDetail={graphPreviewData.data} />
                )

            case "Gantt chart" :
                return (
                    <GanttChart graphDetail={graphPreviewData.data} variant="preview" />
                )

            default : 
                return (
                    <LineChart graphDetail={graphPreviewData.data} variant="preview"/>
                )
        }
    }

    useEffect(() => {
        if(isEmpty(prerequisiteMetricDataState.response)){
            prerequisiteMetricData()
        }

        if(isEmpty(specificMetricState.response)){
            requestSpecificMetric(queryParams.metric_id)
        }
    },[])

    useEffect(() => {
        if(!prerequisiteMetricDataState.loading && !isEmpty(prerequisiteMetricDataState.response)){
            setPageLoading(ps => {
                return {
                    ...ps,
                    prerequisiteMetricData : false
                }
            })
        }

        if(!prerequisiteMetricDataState.loading && !isEmpty(prerequisiteMetricDataState.error)){
            console.log(prerequisiteMetricDataState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    prerequisiteMetricData : false
                }
            })
            if(prerequisiteMetricDataState.error?.data){
                toast.error(prerequisiteMetricDataState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    },[prerequisiteMetricDataState])

    useEffect(() => {
        if(!prerequisiteMetricDataState.loading && !isEmpty(prerequisiteMetricDataState.response) && !specificMetricState.loading && !isEmpty(specificMetricState.response) && !isEmpty(formRef.current)){
            getGoalByName(goals, graphDetail.goal.name).then(appropriateGoal => {
                if(!isEmpty(appropriateGoal)){
                    setCurrentGoal(appropriateGoal)
                    const appropriateMetric = appropriateGoal.metrics.find(obj => obj.name === graphDetail.metric.name)
                    if(!isEmpty(appropriateMetric)){
                        formRef.current.setFieldValue('metric', appropriateMetric._id)
                    }
                    formRef.current.setFieldValue('goal', appropriateGoal._id)
                }
            })
        }
    },[prerequisiteMetricDataState, specificMetricState])

    useEffect(() => {
        if(!specificMetricState.loading && !isEmpty(specificMetricState.response)){
            setExistingReferences( !isEmpty(specificMetricState) && !isEmpty(specificMetricState.response.reference_files) ?
                                        specificMetricState.response.reference_files: [])
            setPageLoading(ps => {
                return {
                    ...ps,
                    metric : false
                }
            })
        }

        if(!specificMetricState.loading && !isEmpty(specificMetricState.error)){
            console.log(specificMetricState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    metric : false
                }
            })
            if(specificMetricState.error?.data){
                toast.error(specificMetricState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
        
    },[specificMetricState])

    function graphList(graphData){
        let graphLists = []
        if(size(graphData[0]) >=6){
            if(size(graphData) <= 11){
                graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart", "Gantt chart")
            } else {
                graphLists.push("Line chart", "Bar chart", "Gantt chart")
            }
        } else if(size(graphData) <= 11){
            graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart")
        } else {
            graphLists.push("Line chart", "Bar chart")
        }
        return graphLists
    }

    function validateData(graphType){

        setValidate(prevState => {
            return {
                ...prevState,
                isValidating: true
            }
        })

        function isPresent(dataArr, variable){
            let present = false
            for(const data of dataArr){
                if(data[variable] !== null && data[variable] !== undefined){
                    present = true
                    break;
                }
            }
            return present
        }

        function lineAndBarChartValidation(){
            if(size(graphPreviewData.data) > 1){
                if(graphPreviewData.data[0].A && graphPreviewData.data[0].B){
                    const dataArr = graphPreviewData.data.slice(1, size(graphPreviewData.data))
                    if(graphPreviewData.data[0].C && isPresent(dataArr, "C")){
                        let isValid = true
                        for(const dataItem of dataArr){
                            if(dataItem.A === null || dataItem.A === undefined){
                                isValid = false
                                break;
                            }
                            if(dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                                isValid = false
                                break;
                            }
                            if(dataItem.C === null || dataItem.C === undefined || typeof dataItem.C !== 'number'){
                                isValid = false
                                break;
                            }
                        }
                        return isValid
                    } else {
                        let isValid = true
                        for(const dataItem of dataArr){
                            if(dataItem.A === null || dataItem.A === undefined){
                                isValid = false
                                break;
                            }
                            if(dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                                isValid = false
                                break;
                            }
                        }
                        return isValid
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        function pieAndDoughtnutChartValidation(){
            if(size(graphPreviewData.data) > 1){
                if(graphPreviewData.data[0].A && graphPreviewData.data[0].B){
                    const dataArr = graphPreviewData.data.slice(1, size(graphPreviewData.data))
                    let isValid = true
                    for(const dataItem of dataArr){
                        if(dataItem.A === null || dataItem.A === undefined){
                            isValid = false
                            break;
                        }
                        if(dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                            isValid = false
                            break;
                        }
                    }
                    return isValid
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        function ganttChartValidation(){
            if(size(graphPreviewData.data) > 1){
                if(graphPreviewData.data[0].A && graphPreviewData.data[0].B && graphPreviewData.data[0].D && graphPreviewData.data[0].E && graphPreviewData.data[0].F){
                    const dataArr = graphPreviewData.data.slice(1, size(graphPreviewData.data))
                    let isValid = true
                    for(const dataItem of dataArr){
                        if(dataItem.A === null || dataItem.A === undefined){
                            isValid = false
                            break;
                        }
                        if(dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                            isValid = false
                            break;
                        }
                        if(dataItem.D === null || dataItem.D === undefined || !isDate(dataItem.D)){
                            isValid = false
                            break;
                        }
                        if(dataItem.E === null || dataItem.E === undefined || !isDate(dataItem.E)){
                            isValid = false
                            break;
                        }
                        if(dataItem.F === null || dataItem.F === undefined){
                            isValid = false
                            break;
                        }
                    }
                    return isValid
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        if(size(graphPreviewData) <= 1){
            setValidate({
                isValidating: false,
                isValid: false
            })
            return
        }

        switch(graphType){

            case "Pie chart":
            case "Doughnut chart":{
                const result = pieAndDoughtnutChartValidation()
                setValidate({
                    isValidating: false,
                    isValid: result
                })
            } break;

            case "Gantt chart":{
                const result = ganttChartValidation()
                setValidate({
                    isValidating: false,
                    isValid: result
                })
            }break;

            default:{
                // line and bar chart
                const result = lineAndBarChartValidation()
                setValidate({
                    isValidating: false,
                    isValid: result
                })
            }
        }
    }

    function getDetail(){
        return (
            <>
                <Formik 
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema= {validationSchema}
                    innerRef={formRef}
                    onSubmit={onSubmit}
                    >
                    {props => {
                        const {values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = props
                        return (
                            !pageLoading.metric && !pageLoading.prerequisiteMetricData && !isEmpty(prerequisiteMetricDataState.response) ?
                            <>
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6} sm={6}>
                                            <InputBox type="text"
                                                variant="withOnClick"
                                                name="goal"
                                                label="Goal"
                                                placeholder="Select goal"
                                                onClick={toggleGoalPopup}
                                                value={ !isEmpty(currentGoal) ? currentGoal.name :""}
                                                errorMgs={errors.goal && touched.goal ? errors.goal : ""} />
                                        </Grid>
                                        <Grid item xs={12} md={6} sm={6}>
                                            <SelectBoxValueById options={!isEmpty(currentGoal) ? getmetric(currentGoal.metrics) : []}
                                                name="metric"
                                                label="Select Metric"
                                                placeholder="Select your metric"
                                                value={values.metric}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMgs={touched.metric && errors.metric ? errors.metric : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className="mt-3">
                                        <TextAreaBox name="metric_description" 
                                            placeholder="Enter the metric description"
                                            label="Description"
                                            rows={ isDesktop ? 4 : "" }
                                            value={values.metric_description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errormgs={touched.metric_description && errors.metric_description ? errors.metric_description : ''}
                                        />
                                    </div>
                                    <Grid container spacing={2} sx={{marginTop:"8px"}}>
                                        <Grid item xs={12} md={6} sm={6}>
                                            <DatePicker type="date"
                                                name="start_date"
                                                label="Start Date"
                                                value={values.start_date}
                                                min={!isEmpty(currentGoal) ? formatDate(currentGoal.start_date) : ""}
                                                max={!isEmpty(currentGoal) ? formatDate(currentGoal.end_date) : ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMgs={touched.start_date && errors.start_date ? errors.start_date : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} sm={6}>
                                            <DatePicker type="date" 
                                                label="End Date"
                                                name="end_date"
                                                min={!isEmpty(currentGoal) ? formatDate(currentGoal.start_date) : ""}
                                                max={!isEmpty(currentGoal) ? formatDate(currentGoal.end_date) : ""}
                                                value={values.end_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMgs={touched.end_date && errors.end_date ? errors.end_date : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className="mt-3">
                                        <FieldArray name="reference_links" >
                                            {fieldArrayProps => {
                                                const { push, remove, form } = fieldArrayProps
                                                const { values } = form 
                                                const { reference_links } = values
                                                return (
                                                    <div>
                                                        {reference_links.map((file,index) => (
                                                            <div key={index} className="mb-2">
                                                                <Grid container spacing={2}>
                                                                    <Grid item md={4} xs={12} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].originalname`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <>
                                                                                        <InputBox type="text" 
                                                                                            label="Link Name"
                                                                                            placeholder="Enter the link name"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item md={ index === 0 ? 8 : 7 } xs={ index ===0 ? 12 : 11 } sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].url`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <div>
                                                                                        <InputBox type="url" 
                                                                                            label="Link Url"
                                                                                            placeholder="http://www.example.com"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    {index > 0 ? <Grid item md={1} xs={1} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                        <IconButton onClick={() => remove(index)}>
                                                                            <CloseIcon />   
                                                                        </IconButton>
                                                                    </Grid> : null }
                                                                </Grid>
                                                            </div>
                                                        ))}
                                                        <div className='mt-2'>
                                                            <CustomizeButton variant="secondary-start-icon-button" label="Add Link" StartIcon={<AddIcon/>} handleClick={() => push('')} />
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </FieldArray>
                                    </div>
                                    { graphDataFile && !isEmpty(graphPreviewData.data) ?
                                        <div className={isDesktop ? "mt-3 w-50" : "mt-3"}>
                                            <SelectBox 
                                                options={graphList(graphPreviewData.data)}
                                                label="Select Graph"
                                                name="graph_type"
                                                value={values.graph_type}
                                                onChange={ (e) => {
                                                    handleChange(e)
                                                    validateData(e.target.value)
                                                }}
                                                onBlur={handleBlur}
                                                errorMgs={touched.graph_type && errors.graph_type ? errors.graph_type : ''}
                                            />
                                        </div> : null }
                                    <div>
                                        <div className="mt-2">
                                            { graphDataFile ?
                                                <div className='mt-3'>
                                                    <h6 className="font-bold lato-fs16-normal-lh20 primary-text-color">Graph Data</h6>
                                                    <div className='d-flex'>
                                                        <p className="lato-fs15-normal-lh20 two-line-wrap mb-0 primary-color">{graphDataFile.name}</p> 
                                                        <FontAwesomeIcon className="ms-auto me-2 secondary-color" 
                                                        icon={faTrash} onClick={() => {
                                                                setGraphDataFile(null)
                                                                setValidate({isValidating: false, isValid: false})
                                                                setFieldValue("graph_type", "")
                                                                setFieldTouched("graph_type", true)
                                                            }}/>
                                                    </div>
                                                    { values?.graph_type ?
                                                        !graphPreviewData.loading ? 
                                                            <div>
                                                                {
                                                                    validate.isValidating ? 
                                                                    null : validate.isValid ? 
                                                                    getGraphPreviewComponent(values.graph_type) :
                                                                    <h6 className='color-red'>Unprocessable file</h6>
                                                                }
                                                            </div>
                                                            : 
                                                            null
                                                    : null }
                                                </div>
                                                : 
                                                <>  
                                                    <div className='mt-3'>
                                                        <CustomizeButton variant="primary-start-icon-button" 
                                                            handleClick={onclickDownloadTemplate}
                                                            startIcon={<DownloadIcon/>}
                                                            label="Template" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <UploadFiles 
                                                            variant={isDesktop ? '' : 'mobile-view'}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                graphDataFileRef.current.click();
                                                            }} 
                                                            label="Click to upload graph data" />
                                                    </div>
                                                    
                                                </>
                                            }
                                        </div>
                                        
                                    </div>
                                    <input type="file"
                                        style={{display:"none"}} 
                                        ref={graphDataFileRef} 
                                        accept=".xlsx,.xls"
                                        name="graph_data"
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}
                                        onChange={(event) =>{
                                            const file = event.target.files[0]                                                       
                                            if(file){
                                                setGraphDataFile(file)
                                            }else{
                                                setGraphDataFile(null)
                                            }
                                            parseExcelFiles(file) 
                                    }} />
                                    <div>
                                        <div className="mt-4">
                                            { !isEmpty(existingReferences) ? existingReferences.map((reference, index) => (
                                                    <div key={index} className="d-flex mb-2">
                                                        <p className="one-line-wrap lato-fs15-normal-lh20 mb-0"> {reference.originalname}</p>
                                                        <FontAwesomeIcon className="me-2 ms-auto secondary-color pt-cursor" 
                                                            onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                    </div>
                                                )
                                            ) : null }
                                            { !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? referenceFiles.files.map((file,index)=>(
                                                    <div key={index} className="mb-2 d-flex">
                                                        <p className="mb-0 one-line-wrap lato-fs15-normal-lh20">{file.name}</p>
                                                        <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                            onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                    </div>
                                            )) : null}
                                        </div>
                                        <div className='mt-2'>
                                            <UploadFiles label="Click to upload attachment" 
                                                variant={isDesktop ? '' : 'mobile-view'}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    referenceFileRef.current.click();
                                                }}/>
                                        </div>
                                    </div>
                                    <input type="file"
                                        style={{display:"none"}} 
                                        ref={referenceFileRef} 
                                        multiple
                                        accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx"
                                        name="reference"
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}
                                        onChange={(event) =>{
                                            const file = event.target.files
                                            if(file){
                                                handleReferenceFileAddition(file)
                                            }
                                    }} />
                                    <div className="d-flex justify-content-center align-center mt-4 mb-3">
                                        {/* <CustomizeButton variant="primary-color-button" type="Submit" label="Modify Metric" /> */}
                                        <CustomizeLoadingButton 
                                            variant="accept-loading-button-v2"
                                            loading={loadingButton}
                                            type="submit"
                                            label="Modify Metric"
                                        />
                                    </div>
                                    {goalPopup ? 
                                        <GoalListPopup handleClose={toggleGoalPopup} 
                                            open={goalPopup}
                                            onBlur={handleBlur}
                                            onChange={(event) => {
                                                    updateCurrGoal(event.target.value)
                                                    handleChange(event)
                                                    toggleGoalPopup()
                                                    setFieldValue("metric","")
                                                }}
                                            options={goals}
                                            value={values.goal}
                                            name="goal"
                                        /> : null}
                                </Form>
                            </> : null
                        )
                    }}
                </Formik>
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableLogout={true}
                            enableProfileButton={true}
                            enableMore={true}
                            open={navbarMoreOpen}
                            menu={navbarMoreIcon}
                            moreRight="70px"
                            handlePopoverClose={handleMenuClose} 
                            handlePopoverOpen={handleMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer>
                                    <div className='mt-3'>
                                        <h5 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>Modify Metric</h5>
                                    </div>
                                    <div className='mt-3'>
                                        {getDetail()}
                                    </div>
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className="container">
                            <div className='px-3 mt-3'>
                                <Header 
                                    handleBackArrowEvent={handleBackArrowEvent} 
                                    heading="Modify Metric"
                                />
                            </div>
                            <div className='px-3 mt-3 pb-70'>
                                {getDetail()}
                            </div>
                        </div>
                        <BottomNavigation/>
                    </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        prerequisiteMetricDataState : state.fetchCreateMetricData,
        specificMetricState : state.specificMetric,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSpecificMetricState : () => dispatch(clearSpecificMetricState()),
        requestSpecificMetric : (graphId) => dispatch(specificMetric(graphId)),
        prerequisiteMetricData: () => dispatch(fetchCreateMetricData()),
        clearFetchMetricState : () => dispatch(clearFetchMetrics()),
        notificationActionChanges : () => dispatch(mailActionChanges())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModifyMetric)