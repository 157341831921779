import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// import useMediaQuery from '@mui/material/useMediaQuery'
import './upload-files.css'

const UploadFiles = (props) => {

    // const isDesktop = useMediaQuery('(min-width:992px)')

    switch(props.variant){
        case "mobile-view":
            return(
                <>
                    <div className='mobile-upload-image' 
                        onClick={props.onClick}>
                        <div className='d-flex align-items-center justify-content-center'>
                            <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                            <p className='mb-0 lato-fs17-normal-lh20'>{props.label}</p>
                        </div>
                    </div>
                </>
            )

        default : 
            return(
                <>
                    <button className="upload-image p-2" 
                        onClick={props.onClick}>
                        <FontAwesomeIcon icon={faCloudUploadAlt} className='me-1' />{props.label}
                    </button>
                </>
            )
    }

    // return(
    //     <>
    //         <button className={isDesktop ? "upload-image p-2" : "upload-image p-1"} 
    //             onClick={props.onClick}>
    //             <FontAwesomeIcon icon={faCloudUploadAlt} className='mr-1' />{props.label}
    //         </button>
    //     </>
    // )
}

export default UploadFiles