import { REQUEST_TEAM_MEMBERS,
        SAVE_TEAM_MEMBERS,
        TEAM_MEMBERS_FAILURE,
        CLEAR_TEAM_MEMBER_STATE } from './teamMember.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const teamMembersReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_TEAM_MEMBERS :
            return {
                ...state,
                loading : true,
                error :null
            }

        case SAVE_TEAM_MEMBERS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case TEAM_MEMBERS_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_TEAM_MEMBER_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default teamMembersReducer