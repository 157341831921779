import { REQUEST_CREATE_OBJECTIVE, 
         CREATE_OBJECTIVE_SUCCESS,
         CREATE_OBJECTIVE_FAILURE, 
         CLEAR_OBJECTIVE_STATE,
         CLEAR_OBJECTIVE_ERROR, 
         REQUEST_DELETE_OBJECTIVE,
         DELETE_OBJECTIVE_SUCCESS,
         DELETE_OBJECTIVE_FAILURE,
         REQUEST_EDIT_OBJECTIVE,
         EDIT_OBJECTIVE_SUCCESS,
         EDIT_OBJECTIVE_FAILURE} from './ObjectiveTypes'

const initialState = {
    loading: false,
    response: {},
    error: null,
}

const objectivesReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_CREATE_OBJECTIVE : 
            return {
                ...state,
                loading: true,
                error : null
            }

        case CREATE_OBJECTIVE_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case CREATE_OBJECTIVE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case REQUEST_EDIT_OBJECTIVE : 
            return{
                ...state,
                loading:true
            }

        case EDIT_OBJECTIVE_SUCCESS : 
            return{
                ...state,
                loading: false,
                response : action.payload
            }
        
        case EDIT_OBJECTIVE_FAILURE : 
            return{
                ...state,
                loading: false,
                error: action.payload
            }

        case REQUEST_DELETE_OBJECTIVE : 
            return {
                ...state,
                loading: true
            }

        case DELETE_OBJECTIVE_SUCCESS : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case DELETE_OBJECTIVE_FAILURE : {
            return {
                ...state,
                loading: false,
                error : action.payload
            }
        }
            
        case CLEAR_OBJECTIVE_STATE : 
            return {
                ...state,
                loading : false,
                response : {},
                error: null
            }
        
        case CLEAR_OBJECTIVE_ERROR: 
            return {
                ...state,
                error: null
            }

        default :
            return state
    }
}

export default objectivesReducer