import React,{ useState } from 'react'
import { useHistory, useLocation  } from 'react-router-dom'
import qs from 'query-string'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import AddIcon from '@mui/icons-material/Add';

import withSupportDoubleClick from '../../utils/with-support-double-click'
import { COMPONENTS_TYPE } from '../../pages/objectives/objective/objectives.utilis'
import { saveSpecificObjectiveForReview } from '../../redux/review-team-action/specific-objective/specificObjective.action'
import { REVIEW_TYPES, PROCESS_STATUS } from '../../utils/constants'
import { getRole } from '../../utils/utils'
import AggregateCard from '../aggregate-card/AggregateCard'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import DrillDown from '../desktop-layout/drill-down/DrillDown'
import ProcessContentArea from '../desktop-layout/process-content-area/ProcessContentArea'


const ObjectiveAggregate = (props) => {

    const { objectivesState, saveObjectives  } = props

    const ACTIVE_LEVEL_KEY_MAPPING = {
        0 : 'level_zero_objectives',
        1 : 'level_one_objectives',
        2 : 'level_two_objectives',
        3 : 'level_three_objectives'
    }

    const [selectedIndex, setSelectedIndex] = useState(undefined)

    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    function getMaxLevel(objectives){
        let maxLevel = 0
        if(!isEmpty(objectives.level_zero_objectives)){
            if(!isEmpty(objectives.level_one_objectives)){
                maxLevel += 1
                if(!isEmpty(objectives.level_two_objectives)) {
                    maxLevel += 1
                    if(!isEmpty(objectives.level_three_objectives)){
                        maxLevel += 1
                    }
                }
            }
        }
        return maxLevel
    }

    function getSelectedObjectiveObj(index) {
        return objectivesState.response[ACTIVE_LEVEL_KEY_MAPPING[queryParams.lvl ? parseInt(queryParams.lvl) : 0]][index]
    }

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick: (e, payload) => {
            saveObjectives({objective : getSelectedObjectiveObj(payload.index)})
            history.push(`/vcf/${getSelectedObjectiveObj(payload.index)._id}?level=${getSelectedObjectiveObj(payload.index).level}`)
        },
        onSingleClick: (e, payload) => {
            if(payload.index !== selectedIndex){
                setSelectedIndex(payload.index)
            } else{
                setSelectedIndex(undefined)
            }
        },
    })

    function getNextLevel(){
        if(selectedIndex !== undefined ){
            if(!isEmpty(getSelectedObjectiveObj(selectedIndex).sub_objectives)){
                const selectedObj = getSelectedObjectiveObj(selectedIndex)
                history.push(`/vcf?mode=${COMPONENTS_TYPE.listSubObjs}&parent_id=${selectedObj._id}&lvl=${selectedObj.level + 1}`)
                setSelectedIndex(undefined)
            } else {
                toast.info('No sub-objectives to view', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            if(getMaxLevel(objectivesState.response) >= parseInt(queryParams.lvl ? queryParams.lvl : 0)){
                history.push(`/vcf?mode=${COMPONENTS_TYPE.listObjBylvl}&lvl=${parseInt(queryParams.lvl ? queryParams.lvl : 0) + 1}`)
            }
        }
    }

    function getPreviousLevel(){
        if(0<parseInt(queryParams.lvl)){
            history.push(`/vcf?mode=${COMPONENTS_TYPE.listObjBylvl}&lvl=${parseInt(queryParams.lvl)-1}`)
        }
    }

    function handleCreateVcfClick(){
        history.push("/vcf/create")
    }

    function aggregateCards(variant){
        const appropriateLevelObjectives = objectivesState.response[ACTIVE_LEVEL_KEY_MAPPING[queryParams.lvl ? parseInt(queryParams.lvl) : 0]]
        if(!isEmpty(appropriateLevelObjectives)){
            return (
                appropriateLevelObjectives.map((obj , index) => (
                    <Grid item xs={6} sm={4} md={4} xl={3} lg={3} key={obj._id} >
                        <AggregateCard 
                            containerClassName="pt-cursor"
                            wallpaper={ obj.wallpaper ? obj.wallpaper : "" }
                            icon={ obj.icon? obj.icon : "" }
                            name={ obj.name }
                            level={obj.level}
                            variant={variant}
                            maxLevel={obj.max_level}
                            selected={selectedIndex === index}
                            onClick={(e) => handleCardClick(e, {index: index})}
                        />
                    </Grid>
                ))
            )
        } else {
            return null
        }
    }

    switch(props.variant){
        case 'desktop':
            return (
                !objectivesState.loading ?
                    <>
                        {
                            (queryParams.mode === COMPONENTS_TYPE.listObjBylvl || isEmpty(queryParams) ) && !isEmpty(objectivesState.response) && (!isEmpty(objectivesState.response.level_zero_objectives) ||!isEmpty(objectivesState.response.level_one_objectives) || !isEmpty(objectivesState.response.level_two_objectives) || !isEmpty(objectivesState.level_three_objectives)) ?
                            <ProcessContentArea>
                                <Grid container spacing={{md: 3, lg: 3, xl: 4 }} >
                                    {aggregateCards("desktop")}
                                </Grid>
                            </ProcessContentArea>
                            : 
                            <div className='py-3'>
                                <p className="m-0 text-center lato-fs20-normal-lh28">
                                    Hey, You don't have any VCF added yet
                                </p>
                                <div className='d-flex justify-content-center mt-3'>
                                    <CustomizeButton variant="primary-start-icon-button" startIcon={<AddIcon />} type="button" handleClick={handleCreateVcfClick} label="Create VCF" />
                                </div>
                            </div>
                        }
                        <DrillDown 
                            arrowUpDisabled={parseInt(queryParams.lvl ? queryParams.lvl : 0) === 0}
                            onArrowUpClick={getPreviousLevel}
                            arrowDownDisabled={!(getMaxLevel(objectivesState.response) > 0 && parseInt(queryParams.lvl ? queryParams.lvl : 0) < getMaxLevel(objectivesState.response))}
                            onArrowDownClick={getNextLevel}
                        />
                    </>
                : null
            )
        default:
            return (
                !objectivesState.loading ?
                    <div className="process-aggregate-height" >
                        {
                            (queryParams.mode === COMPONENTS_TYPE.listObjBylvl || isEmpty(queryParams) ) && !isEmpty(objectivesState.response) && (!isEmpty(objectivesState.response.level_zero_objectives) ||!isEmpty(objectivesState.response.level_one_objectives) || !isEmpty(objectivesState.response.level_two_objectives) || !isEmpty(objectivesState.level_three_objectives)) ?
                                <>
                                    <div className='p-3 overflow-auto h-100'>
                                        <Grid container spacing={{ xs: 1, md: 2 }} >
                                            {aggregateCards()}
                                        </Grid>
                                    </div>
                                    <DrillDown
                                        variant="mobile"
                                        arrowUpDisabled={parseInt(queryParams.lvl ? queryParams.lvl : 0) === 0}
                                        onArrowUpClick={getPreviousLevel}
                                        arrowDownDisabled={!(getMaxLevel(objectivesState.response) > 0 && parseInt(queryParams.lvl ? queryParams.lvl : 0) < getMaxLevel(objectivesState.response))}
                                        onArrowDownClick={getNextLevel}
                                    />
                                </>
                                : <div className='d-flex p-3 h-100 justify-content-center align-items-center flex-column'>
                                    <p className="m-0 lato-fs20-normal-lh28 text-center">
                                        Hey, You don't have any VCF added yet
                                    </p>
                                    <CustomizeButton
                                        className="mt-3"
                                        variant="primary-start-icon-button" 
                                        startIcon={<AddIcon />} 
                                        type="button" 
                                        handleClick={handleCreateVcfClick} 
                                        label="Create VCF"
                                    />
                                </div>
                        }
                    </div>
                : null
            )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        objectivesState : state.fetchObjectivesData,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveObjectives : (response) => dispatch(saveSpecificObjectiveForReview(response))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ObjectiveAggregate)