import { REQUEST_OBJECTIVE_UNDER_REVIEW, 
        FETCH_OBJECTIVE_UNDER_REVIEW_SUCCESS,
        FETCH_OBJECTIVE_UNDER_REVIEW_FAILURE,
        CLEAR_OBJECTIVE_UNDER_REVIEW_STATE,
        REVIEW_ACTION_CHANGED_UNDER_REVIEW } from './objectiveUnderReview.types'

export const requestObjectiveUnderReview = () => {
    return {
        type : REQUEST_OBJECTIVE_UNDER_REVIEW
    }
}

export const fetchObjectiveUnderReviewSuccess = (response) => {
    return {
        type : FETCH_OBJECTIVE_UNDER_REVIEW_SUCCESS,
        payload : response
    }
}

export const fetchObjectiveUnderReviewFailure = (error) => {
    return {
        type : FETCH_OBJECTIVE_UNDER_REVIEW_FAILURE,
        payload : error
    }
}

export const clearObjectiveUnderReviewState = () => {
    return {
        type : CLEAR_OBJECTIVE_UNDER_REVIEW_STATE
    }
}

export const actionChangedUnderReview = () => {
    return {
        type : REVIEW_ACTION_CHANGED_UNDER_REVIEW
    }
}