import { requestCUDepartments, saveCUDepartments, cuDepartmentsFailure } from "../redux/CU-department/CUDepartment.action"
import { requestDepartments, saveDepartments, fetchDepartmentsFailure } from "../redux/department/Department.action"
import ProtectedWebClient from "../utils/protected-web-client"

export const createDepartment = (payload) => {
    return async (dispatch) => {
        try{
            dispatch(requestCUDepartments())
            const response = await ProtectedWebClient.post(`/department/create`,payload)
            dispatch(saveCUDepartments(response.data))
        }catch(error){
            dispatch(cuDepartmentsFailure(error.response))
        }
    }
}

export const updateDepartment = (id,payload) => {
    return async (dispatch) => {
        try{
            dispatch(requestCUDepartments())
            const response = await ProtectedWebClient.put(`/department/${id}/edit`,payload)
            dispatch(saveCUDepartments(response.data))
        }catch(error){
            dispatch(cuDepartmentsFailure(error.response))
        }
    }
}

export const fetchDepartments = () => {
    return async (dispatch) => {
        try{
            dispatch(requestDepartments())
            const response = await ProtectedWebClient.get(`/department/read`)
            dispatch(saveDepartments(response.data))
        }catch(error){
            dispatch(fetchDepartmentsFailure(error.response))
        }
    }
}