import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import PopUp from '../../common/popup/PopUp'
import './action-popup.css'
import ObjectiveSelect from '../objective-select/ObjectiveSelect'
import GoalSelect from '../GoalSelect/GoalSelect'
import MetricSelect from '../metric-select/MetricSelect'
import DevelopmentAreaSelect from '../development-area-select/DevelopmentAreaSelect'
import CourseSelect from '../course-select/CourseSelect'

const ActionPopup = (props) => {

    const isMobile = useMediaQuery('(max-width : 480px)')

    function processName(action){
        if(action === "objectives"){
            return "VCF"
        }else if(action === "goals"){
            return "Goals"
        }else if(action === "metrics"){
            return "Metrics"
        }else if(action === "learning_areas"){
            return "Learning Areas"
        }else{
            return "Courses"
        }
    }

    return (
        <PopUp handleClose={props.handleClose} open={props.open} fullWidth={isMobile} >
            <div className={isMobile? "p-3 d-flex flex-column" : "d-flex flex-column p-3 min-width-350px"}>
                <div className='ms-auto'>
                    <IconButton onClick={props.handleClose} sx={{padding : "0px"}}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className='d-flex align-items-center mb-3'>
                    <h2 className='lato-fs22-normal-lh32 w-100 mb-0 primary-text-color font-bold text-center'>{`Select ${processName(props.action)}`}</h2>
                </div>
                { props.action === "objectives" ? 
                    <ObjectiveSelect onCancel={props.handleClose} selectedObjectives={props.selectedActions} setSelectedObjectives={props.setSelectedActions} removeSelectedObjective={props.removeSelectedAction}/> : ""}
                {props.action === "goals" ? 
                    <GoalSelect onCancel={props.handleClose} selectedValue={props.selectedActions} setSelectedValues={props.setSelectedActions} removeSelectedObjective={props.removeSelectedAction} /> : ""}
                {props.action === "metrics" ? 
                    <MetricSelect onCancel={props.handleClose} selectedValue={props.selectedActions} setSelectedValues={props.setSelectedActions} removeSelectedObjective={props.removeSelectedAction} /> : ""}
                {props.action === "learning_areas" ?
                    <DevelopmentAreaSelect onCancel={props.handleClose} selectedValue={props.selectedActions} setSelectedValues={props.setSelectedActions} /> 
                    : null }
                {props.action === "courses" ? 
                    <CourseSelect onCancel={props.handleClose} selectedValue={props.selectedActions} setSelectedValues={props.setSelectedActions} /> : ""}
            </div>        
        </PopUp>
    )
}

export default ActionPopup