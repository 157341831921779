import ProtectedWebClient from "../utils/protected-web-client"
import { requestMembers, fetchedMemberFailure, fetchedMemberSuccess } from '../redux/user/Members/members.action'
import { fetchedInvitedMemberFailure, fetchedInvitedMemberSuccess, requestInvitedMembers } from "../redux/user/invited-member/invitedMembers.action"
import { requestMemberDetail, fetchedMemberDetailSuccess, fetchedMemberDetailFailure } from "../redux/user/member-detail/membersDetail.action"
import { fetchedNonInvitedMemberSuccess, requestNonInvitedMembers } from "../redux/user/non-invited-member/nonInvitedMembers.action"

export const getRoleMembers = (value,userId) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.get( userId ? `/user-profile/users?search=${value}&&user=${userId}` : `/user-profile/users?search=${value}` )
            .then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            }  
    )})
}

export const createUser = (inviteState,payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/user-profile/create-user?invite=${inviteState}`, payload)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const updateUser = (userId,payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.put(`/user-profile/${userId}/edit-user`, payload)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const fetchMembers = (payload) => {
    return async (dispatch) => {
        try{
            dispatch(requestMembers())
            const response = await ProtectedWebClient.post('user-profile/user-list?selected=active',payload)
            dispatch(fetchedMemberSuccess(response.data))
        }catch(error){
            dispatch(fetchedMemberFailure(error.response))
        }
    }
}

export const fetchInvitedMembers = (action) => {
    return async (dispatch) => {
        try{
            dispatch(requestInvitedMembers())
            const response = await ProtectedWebClient.post('/user-profile/user-list?selected=invited')
            dispatch(fetchedInvitedMemberSuccess(response.data))
        }catch(error){
            dispatch(fetchedInvitedMemberFailure(error.response))
        }
    }
}

export const fetchNonInvitedMembers = (action) => {
    return async (dispatch) => {
        try{
            dispatch(requestNonInvitedMembers())
            const response = await ProtectedWebClient.post('/user-profile/user-list?selected=uninvited')
            dispatch(fetchedNonInvitedMemberSuccess(response.data))
        }catch(error){
            dispatch(fetchedInvitedMemberFailure(error.response))
        }
    }
}

export const fetchMemberDetail = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(requestMemberDetail())
            const response = await ProtectedWebClient.get(`/user-profile/${userId}/read`)
            dispatch(fetchedMemberDetailSuccess(response.data))
        }catch(error){
            dispatch(fetchedMemberDetailFailure(error.response))
        }
    }
}

export const inviteUsers = (payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/user-profile/invite-user`, payload)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const updateMemberSettings = (userId,payload) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.put(`/settings/${userId}/review-edit`, payload)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const searchUser = ( type, payload ) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/search/organisation?search=${payload}&type=${type}`)
        .then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}