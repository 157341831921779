import { REQUEST_METRIC_DATA,
         METRIC_DATA_SUCCESS,
         METRIC_DATA_FAILURE,
         METRIC_ACTION_CHANGES,
         CLEAR_METRICS } from './FetchMetricTypes'

export const requestMetricData = () => {
    return {
        type : REQUEST_METRIC_DATA
    }
}

export const fetchMetricSuccess = (res) => {
    return {
        type : METRIC_DATA_SUCCESS,
        payload: res
    }
} 

export const fetchMetricFailure = (error) => {
    return {
        type : METRIC_DATA_FAILURE,
        payload : error
    }
}

export const metricActionChanges = () => {
    return {
        type : METRIC_ACTION_CHANGES
    }
}

export const clearFetchMetrics = () => {
    return {
        type : CLEAR_METRICS
    }
}