import { requestCreateMetric, 
         createMetricSuccess,
         createMetricFailure } from '../redux/create-metric/CreateMetricAction'
import { requestFetchCreateMetricData, 
         fetchCreateMetricDataSuccess, 
         fetchCreateMetricDataFailure } from '../redux/fetch-create-metric-data/FetchCreateMetricDataAction'
import { requestDeleteMetric,
         deleteMetricSuccess,
         deleteMetricFailure } from '../redux/delete-metric/DeleteMetricAction'
import { fetchGoalsForMetric, fetchGraphType } from './constants.service'
import { requestUpdateComputeProgressMetric, 
         successUpdateComputeProgressMetric,
         failureUpdateComputeProgressMetric } from '../redux/goal-progress/update-compute-progress-metric/UpdateComputeProgressMetricAction'
import { failureComputeMetricProgress, requestComputeMetricProgress, successComputeMetricProgress } from '../redux/goal-progress/compute-metric-progress/ComputeMetricProgressAction'
import ProtectedWebClient from '../utils/protected-web-client'
import { requestSpecificMetric, specificMetricFailure, specificMetricSuccess } from '../redux/specific-metric/specificMetric.action'

export const createMetrics = (metricDetails) => {
    return async (dispatch) => {
        try{
            dispatch(requestCreateMetric())
            const response = await ProtectedWebClient.post("/graph/create", metricDetails)
            dispatch(createMetricSuccess(response.data))
        }catch(error){
            dispatch(createMetricFailure(error.response))
        }
    }
}

export const fetchCreateMetricData = () => {
    return async (dispatch) => {
        try {
            dispatch(requestFetchCreateMetricData())
            const [goalsRes] = await Promise.all([fetchGoalsForMetric()])
            dispatch(fetchCreateMetricDataSuccess({
                goals : goalsRes.data.objectives,
            }))
        }catch(err){
            dispatch(fetchCreateMetricDataFailure(err.response))
        }
    }
}


export const updateMetric = (graphDetails, graphId) => {
    return async (dispatch) => {
        try {
            dispatch(requestCreateMetric())
            const response = await ProtectedWebClient.put(`/graph/${graphId}/edit`, graphDetails)
            dispatch(createMetricSuccess(response.data))
        } catch(error) {
            dispatch(createMetricFailure(error.response))
        }
    }
}

export const deleteMetric = (graphId) => {
    return async (dispatch) => {
        try {
            dispatch(requestDeleteMetric())
            const response = await ProtectedWebClient.delete(`/graph/${graphId}/delete`)
            dispatch(deleteMetricSuccess(response.data))
        }catch(error){
            dispatch(deleteMetricFailure(error.response))
        }
    }
}

export const editEnableProgress = (metrics) => {
    return async (dispatch) => {
        try {
            dispatch(requestUpdateComputeProgressMetric())
            const response = await ProtectedWebClient.put('graph/compute-progress-edit', metrics)
            dispatch(successUpdateComputeProgressMetric(response.data))
        } catch (error) {
            dispatch(failureUpdateComputeProgressMetric(error.response))
        }
    }
}

export const fetchComputedGoalProgress = () => {
    return async (dispatch) => {
        try {
            dispatch(requestComputeMetricProgress())
            const response = await ProtectedWebClient.get("/graph/compute-progress")
            dispatch(successComputeMetricProgress(response.data))
        }catch(error) {
            dispatch(failureComputeMetricProgress(error.response))
        }
    }
}

export const specificMetric = (graphId) => {
    return async (dispatch) => {
        try{
            dispatch(requestSpecificMetric())
            const response = await ProtectedWebClient.get(`/graph/${graphId}/read`)
            dispatch(specificMetricSuccess(response.data))
        }catch(error){
            dispatch(specificMetricFailure(error.response))
        }
    }
}

export const deleteMetricPromise = (graphId) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.delete(`/graph/${graphId}/delete`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}