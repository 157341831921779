import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import useMediaQuery from '@mui/material/useMediaQuery'
import qs from 'query-string'

import BottomNavigation from '../../components/bottom-navgation/BottomNavigation' 
import CreateBranchPopup from '../../components/create-branch-popup/CreateBranchPopup'
import { saveBranches } from '../../redux/Branch/branches/Branches.action'
import { fetchBranches, updateBranch } from '../../services/branch.service'
import BodyContainer from '../../common/body-container/BodyContainer';
import './branches.css'
import HeaderContainer from '../../common/header-container/HeaderContainer';
import Container from '../../components/desktop-layout/container/Container';
import Navbar from '../../components/desktop-layout/navbar/Navbar';
import ProcessSectionContainer from '../../components/desktop-layout/process-section-container/ProcessSectionContainer';
import LeftVerticalMenuBar from '../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar';
import ProcessMainContainer from '../../components/desktop-layout/process-main-container/ProcessMainContainer';
import SubMainContainer from '../../components/desktop-layout/sub-main-container/SubMainContainer';
import CustomizeButton from '../../common/customize-button/CustomizeButton';
import Box from '@mui/material/Box'
import SimpleTab from '../../components/tabs/simple-tab/simple-tab/SimpleTab';
import SimpleTabPanel from '../../components/tabs/simple-tab/Simple-tab-panel/SimpleTabPanel';

const Branches = (props) => {

    const { cuBranchState, branchesState, fetchBranch, updateBranch, saveBranch} = props

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const location = useLocation()
    const queryParams = qs.parse(location.search)

    const [ createBranchPopup, setCreateBranchPopUp ] = useState(false)
    const [ editBranchPopup, setEditBranchPopUp ] = useState(false)
    const [ branchIndex, setBranchIndex ] = useState(0)
    const [ pageLoading, setPageLoading ] = useState(true)
    const [ drawerOpen, setDrawerOpen ] = useState(false)
    const [activeBranchAnchorElArr, setActiveBranchAnchorElArr] = useState([]);
    const [inactiveBranchAnchorElArr, setInactiveBranchAnchorElArr] = useState([]);

    const [ menu, setMenu ] = useState(false)

    const open = Boolean(menu);

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenu(null);
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    const handleActiveBranchClick = (event, index) => {
        setActiveBranchAnchorElArr(ps => {
            return ps.map((branch, i) => {
                if(index === i){
                    return event.currentTarget
                }
            })
        });
    };

    const handleActiveBranchClose = (index) => {
        setActiveBranchAnchorElArr(ps => {
            return ps.map((branch, i) => {
                if(index === i){
                    return null
                }
            })
        });
    };

    const handleInactiveBranchClick = (event, index) => {
        setInactiveBranchAnchorElArr(ps => {
            return ps.map((branch, i) => {
                if(index === i){
                    return event.currentTarget
                }
            })
        });
    };

    const handleInactiveBranchClose = (index) => {
        setInactiveBranchAnchorElArr(ps => {
            return ps.map((branch, i) => {
                if(index === i){
                    return null
                }
            })
        });
    };

    const activeBranch = branchesState.response.active_branches
    const inActiveBranch = branchesState.response.inactive_branches

    function createBranch(){
        setCreateBranchPopUp(true)
    }

    function handleCreateBranchClose(event, reason){
        // if (reason && reason == "backdropClick") return
        // else 
        setCreateBranchPopUp(false)
    }

    function editBranch(index){
        setEditBranchPopUp(true)
        setBranchIndex(index)
        handleActiveBranchClose()
    }

    function handleEditBranchClose(event, reason){
        // if (reason && reason == "backdropClick") return
        // else 
        setEditBranchPopUp(false)
    }

    function handleBranchStatus(id,status){
        updateBranch(id,{status : status})
        handleActiveBranchClose()
    }

    function handleBackArrow(){
        history.goBack()
    }

    function getLocation(address){
        let add = ""
        if(address.door_no){
            add += `${address.door_no},`
        }
        if(address.area){
            add += `${address.area},`
        }
        if(address.city){
            add += `${address.city},`
        }
        if(address.state){
            add += `${address.state}-`
        }
        if(address.pincode){
            add += `${address.pincode},`
        }
        if(address.country){
            add += `${address.country}.`
        }
        return add
    }

    const handleChange = (event, newValue) => {
        if(newValue){
            history.push(`/location?selected=${newValue}`)
        }
    }

    useEffect(() => {
        if(isEmpty(branchesState.response)){
            fetchBranch()
        }
        if(!['active-locations', 'inactive-locations'].includes(queryParams.selected)){
            history.push(`/location?selected=active-locations`)
        }
    },[])

    useEffect(() => {
        if(!cuBranchState.loading && !isEmpty(cuBranchState.response)){
            // console.log(cuBranchState.response)
            saveBranch(cuBranchState.response)
        }
        if(!cuBranchState.loading && !isEmpty(cuBranchState.error)){
            console.log(cuBranchState.error)
            if(cuBranchState.error?.data){
                toast.error(cuBranchState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[cuBranchState])

    useEffect(() => {
        if(!branchesState.loading && !isEmpty(branchesState.response)){
            // console.log(branchesState.response)
            if(!isEmpty(activeBranch)) setActiveBranchAnchorElArr(ps => {
                return activeBranch.map(branch => null)
            })
            if(!isEmpty(inActiveBranch)) setInactiveBranchAnchorElArr(ps => {
                return inActiveBranch.map(branch => null)
            })
            setPageLoading(false)
        }
        if(!branchesState.loading && !isEmpty(branchesState.error)){
            console.log(branchesState.error)
            if(branchesState.error?.data){
                toast.error(branchesState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[branchesState])

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }
    
    return (
        <>
            <Container>
                {
                    isDesktop ?
                        <>
                            <Navbar 
                                open={open}
                                menu={menu}
                                handlePopoverClose={handleMenuClose}
                                handlePopoverOpen={handleMenuOpen} 
                                enableMore={true} 
                                enableLogout={true} 
                                enableProfileButton={true}
                                enableNotification={true}
                                notificationOnClick={onClickNotification}
                            />
                            <ProcessSectionContainer>
                                <LeftVerticalMenuBar 
                                    addIcon={true} 
                                    addIconTooltip="Create Location"
                                    onAddIconClick={createBranch}
                                />
                                <ProcessMainContainer 
                                    variant='noBackground'
                                >
                                    {
                                        !pageLoading ?
                                            <>
                                                <Box sx={{paddingLeft: '5%', paddingRight: '5%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}} >
                                                    <SimpleTab
                                                        value={queryParams.selected ? queryParams.selected : "members"} 
                                                        onChange={handleChange}
                                                        tabArray={[ 
                                                            {label:"Locations", value:"active-locations"},
                                                            {label:"Inactive Locations", value:"inactive-locations"}
                                                        ]}
                                                        variant="desktop"
                                                    />
                                                </Box>
                                                <SimpleTabPanel 
                                                    value="active-locations"
                                                    selectedTab={queryParams.selected}
                                                >
                                                    <div 
                                                        style={{
                                                            height: 'calc(100vh - 113px)',
                                                            backgroundColor: 'var(--tertiary-color-v2)',
                                                            overflowY: 'auto'
                                                        }}
                                                        className='d-flex'
                                                    >
                                                        <SubMainContainer>
                                                            {
                                                                !isEmpty(activeBranch) ?
                                                                    <div className="py-4">
                                                                        {/* <h2 className="lato-fs22-normal-lh32 mb-3">Active Locations</h2> */}
                                                                        <div>
                                                                                {activeBranch.map((branch, branchIndex) => (
                                                                                    <div key={branchIndex} className='mb-3'>
                                                                                        <div className="d-flex align-items-center" >
                                                                                            <div className='flex-fill'>
                                                                                                <h5 className="mb-2 lato-fs24-normal-lh32 font-bold">{branch.city}</h5>
                                                                                                <div>
                                                                                                    {
                                                                                                        branch?.door_no || branch?.area ?
                                                                                                            <div>
                                                                                                                {
                                                                                                                    branch?.door_no ?
                                                                                                                        <span className="lato-fs17-normal-lh20" >{`${branch?.door_no}, `}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    branch?.area ?
                                                                                                                        <span className="lato-fs17-normal-lh20" >{`${branch?.area},`}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div> :
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        branch?.city ?
                                                                                                            <div>
                                                                                                                <span className="lato-fs17-normal-lh20">{`${branch?.city},`}</span>
                                                                                                            </div> :
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        branch?.state || branch?.pincode ?
                                                                                                            <div>
                                                                                                                {
                                                                                                                    branch?.state ?
                                                                                                                        <span className="lato-fs17-normal-lh20">{`${branch?.state} - `}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    branch?.pincode ?
                                                                                                                        <span className="lato-fs17-normal-lh20">{branch?.pincode}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div> :
                                                                                                            null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='ms-2' >
                                                                                                <CustomizeButton 
                                                                                                    label="Edit"
                                                                                                    variant="primary-color-button"
                                                                                                    handleClick={() => {editBranch(branchIndex)}}
                                                                                                />
                                                                                                <CustomizeButton
                                                                                                    className="ms-3"
                                                                                                    label="Deactivate"
                                                                                                    variant="red-color-button"
                                                                                                    handleClick={() => handleBranchStatus(branch._id,"inactive")}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                        </div>
                                                                    </div> 
                                                                    : <div className='py-4'>
                                                                        <p className="lato-fs17-normal-lh20 mb-0">You don't have any inactive locations</p>
                                                                    </div>
                                                            }
                                                        </SubMainContainer>
                                                    </div>
                                                </SimpleTabPanel>
                                                <SimpleTabPanel 
                                                    value="inactive-locations"
                                                    selectedTab={queryParams.selected} 
                                                >
                                                    <div 
                                                        style={{
                                                            height: 'calc(100vh - 113px)',
                                                            backgroundColor: 'var(--tertiary-color-v2)',
                                                            overflowY: 'auto'
                                                        }}
                                                        className='d-flex'
                                                    >
                                                        <SubMainContainer>
                                                            {
                                                                !isEmpty(inActiveBranch) ?
                                                                    <div className="py-4">
                                                                        {/* <h2 className="lato-fs22-normal-lh32 mb-3">Inactive Locations</h2> */}
                                                                        <div>
                                                                                {inActiveBranch.map((branch, branchIndex) => (
                                                                                    <div key={branchIndex} className='mb-3'>
                                                                                        <div className="d-flex align-items-center" >
                                                                                            <div className='flex-fill'>
                                                                                                <h5 className="mb-2 lato-fs24-normal-lh32 font-bold">{branch.city}</h5>
                                                                                                <div>
                                                                                                    {
                                                                                                        branch?.door_no || branch?.area ?
                                                                                                            <div>
                                                                                                                {
                                                                                                                    branch?.door_no ?
                                                                                                                        <span className="lato-fs17-normal-lh20" >{`${branch?.door_no}, `}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    branch?.area ?
                                                                                                                        <span className="lato-fs17-normal-lh20" >{`${branch?.area},`}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div> :
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        branch?.city ?
                                                                                                            <div>
                                                                                                                <span className="lato-fs17-normal-lh20">{`${branch?.city},`}</span>
                                                                                                            </div> :
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        branch?.state || branch?.pincode ?
                                                                                                            <div>
                                                                                                                {
                                                                                                                    branch?.state ?
                                                                                                                        <span className="lato-fs17-normal-lh20">{`${branch?.state} - `}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                                {
                                                                                                                    branch?.pincode ?
                                                                                                                        <span className="lato-fs17-normal-lh20">{branch?.pincode}</span>
                                                                                                                        : null
                                                                                                                }
                                                                                                            </div> :
                                                                                                            null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='ms-2'>
                                                                                                <CustomizeButton
                                                                                                    label="Activate"
                                                                                                    variant="green-color-button"
                                                                                                    handleClick={() => handleBranchStatus(branch._id,"active")}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                        </div>
                                                                    </div> 
                                                                    : <div className='py-4'>
                                                                        <p className="lato-fs17-normal-lh20 mb-0">You don't have any inactive locations</p>
                                                                    </div>
                                                            }
                                                        </SubMainContainer>
                                                    </div>
                                                </SimpleTabPanel>
                                            </>
                                            : null
                                    }
                                </ProcessMainContainer>
                            </ProcessSectionContainer>
                        </> 
                        : <>
                            <Navbar 
                                variant="mobile"
                                enableNotificationsIcon={true}
                                onNotificationsIconClick={onClickNotification}
                                enableLogout={true}
                                enableDrawer={true}
                                drawerOpened={drawerOpen}
                                handleDrawerClose={toggleDrawer}
                                onDrawerClick={toggleDrawer}
                            />
                            {
                                !pageLoading ?
                                    <div className='content-area-height'>
                                        <Box>
                                            <SimpleTab
                                                value={queryParams.selected ? queryParams.selected : "members"} 
                                                onChange={handleChange}
                                                tabArray={[ 
                                                    {label:"Locations", value:"active-locations"},
                                                    {label:"Inactive Locations", value:"inactive-locations"}
                                                ]}
                                                variant="mobile"
                                                tabVariant="fullWidth"
                                            />
                                        </Box>
                                        <hr style={{margin: 0, borderTop: '1px solid rgba(0, 0, 0, 0.1)'}}/>
                                        <div className="content-area-height-members-for-users bg-tertiary-color-v2 overflow-auto" >
                                            <SimpleTabPanel 
                                                value="active-locations"
                                                selectedTab={queryParams.selected}
                                            >
                                                <SubMainContainer className="container pb-70 px-3">
                                                    {
                                                        !isEmpty(activeBranch) ?
                                                            <div className="py-4">
                                                                {/* <h2 className="lato-fs22-normal-lh32 mb-3">Active Locations</h2> */}
                                                                <div>
                                                                        {activeBranch.map((branch, branchIndex) => (
                                                                            <div key={branchIndex} className='mb-3'>
                                                                                <div className="d-flex align-items-center" >
                                                                                    <div className='flex-fill'>
                                                                                        <h5 className="mb-2 lato-fs24-normal-lh32 font-bold">{branch.city}</h5>
                                                                                        <div>
                                                                                            {
                                                                                                branch?.door_no || branch?.area ?
                                                                                                    <div>
                                                                                                        {
                                                                                                            branch?.door_no ?
                                                                                                                <span className="lato-fs17-normal-lh20" >{`${branch?.door_no}, `}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                        {
                                                                                                            branch?.area ?
                                                                                                                <span className="lato-fs17-normal-lh20" >{`${branch?.area},`}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                    </div> :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                branch?.city ?
                                                                                                    <div>
                                                                                                        <span className="lato-fs17-normal-lh20">{`${branch?.city},`}</span>
                                                                                                    </div> :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                branch?.state || branch?.pincode ?
                                                                                                    <div>
                                                                                                        {
                                                                                                            branch?.state ?
                                                                                                                <span className="lato-fs17-normal-lh20">{`${branch?.state} - `}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                        {
                                                                                                            branch?.pincode ?
                                                                                                                <span className="lato-fs17-normal-lh20">{branch?.pincode}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                    </div> :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <CustomizeButton 
                                                                                            className="mx-1 my-1"
                                                                                            label="Edit"
                                                                                            variant="primary-color-button"
                                                                                            handleClick={() => {editBranch(branchIndex)}}
                                                                                        />
                                                                                        <CustomizeButton
                                                                                            className="mx-1 my-1"
                                                                                            label="Deactivate"
                                                                                            variant="red-color-button"
                                                                                            handleClick={() => handleBranchStatus(branch._id,"inactive")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div> 
                                                            : <div className='py-4'>
                                                                <p className="lato-fs17-normal-lh20 mb-0">You don't have any inactive locations</p>
                                                            </div>
                                                    }
                                                </SubMainContainer>
                                            </SimpleTabPanel>
                                            <SimpleTabPanel 
                                                value="inactive-locations"
                                                selectedTab={queryParams.selected} 
                                            >
                                                <SubMainContainer className="container pb-70 px-3">
                                                    {
                                                        !isEmpty(inActiveBranch) ?
                                                            <div className="py-4">
                                                                {/* <h2 className="lato-fs22-normal-lh32 mb-3">Inactive Locations</h2> */}
                                                                <div>
                                                                        {inActiveBranch.map((branch, branchIndex) => (
                                                                            <div key={branchIndex} className='mb-3'>
                                                                                <div className="d-flex align-items-center" >
                                                                                    <div className='flex-fill'>
                                                                                        <h5 className="mb-2 lato-fs24-normal-lh32 font-bold">{branch.city}</h5>
                                                                                        <div>
                                                                                            {
                                                                                                branch?.door_no || branch?.area ?
                                                                                                    <div>
                                                                                                        {
                                                                                                            branch?.door_no ?
                                                                                                                <span className="lato-fs17-normal-lh20" >{`${branch?.door_no}, `}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                        {
                                                                                                            branch?.area ?
                                                                                                                <span className="lato-fs17-normal-lh20" >{`${branch?.area},`}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                    </div> :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                branch?.city ?
                                                                                                    <div>
                                                                                                        <span className="lato-fs17-normal-lh20">{`${branch?.city},`}</span>
                                                                                                    </div> :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                branch?.state || branch?.pincode ?
                                                                                                    <div>
                                                                                                        {
                                                                                                            branch?.state ?
                                                                                                                <span className="lato-fs17-normal-lh20">{`${branch?.state} - `}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                        {
                                                                                                            branch?.pincode ?
                                                                                                                <span className="lato-fs17-normal-lh20">{branch?.pincode}</span>
                                                                                                                : null
                                                                                                        }
                                                                                                    </div> :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='ms-2'>
                                                                                        <CustomizeButton
                                                                                            label="Activate"
                                                                                            variant="green-color-button"
                                                                                            handleClick={() => handleBranchStatus(branch._id,"active")}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div> 
                                                            : <div className='py-4'>
                                                                <p className="lato-fs17-normal-lh20 mb-0">You don't have any inactive locations</p>
                                                            </div>
                                                    }
                                                </SubMainContainer>
                                            </SimpleTabPanel>
                                        </div>
                                    </div>
                                    : null
                            }
                            <BottomNavigation
                                enableAddIcon={true}
                                onAddIconClick={createBranch}
                            />
                        </>
                }
            </Container>
            { 
                createBranchPopup ?
                    <CreateBranchPopup
                        open={createBranchPopup}
                        handleClose={handleCreateBranchClose}
                        heading="Create"
                        buttonLabel="Create" />
                    : null
            }   
            {
                editBranchPopup ?
                    <CreateBranchPopup
                        open={editBranchPopup}
                        handleClose={handleEditBranchClose}
                        heading="Edit"
                        buttonLabel="Update" 
                        editBranch={true}
                        branchIndex={branchIndex}     
                    />
                    : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        branchesState : state.branches,
        cuBranchState : state.cuBranch
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBranch : () => dispatch(fetchBranches()),
        saveBranch : (response) => dispatch(saveBranches(response)),
        updateBranch : (id,branchDetails) => dispatch(updateBranch(id,branchDetails)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Branches)