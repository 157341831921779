import React from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import LevelIndicator from '../level-indicator/LevelIndicator'

import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import wallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png'

import './aggregate-card.css'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import { isEmpty } from 'lodash'

const AggregateCard = (props) => {

    switch(props.variant){
        case "desktop":
            return (
                <Card
                    className={props.selected ? `vcf-card-square-desktop pt-cursor ${props.containerClassName} selected` : `vcf-card-square-desktop pt-cursor ${props.containerClassName}`} 
                    onClick={props.onClick} >
                    <div className='p-relative'>
                        <img 
                            style={{
                                aspectRatio: '9/5',
                                width: '100%',
                                height: 'auto',
                                objectFit: "cover",
                                objectPosition: 'center'
                            }}
                            src={props.wallpaper || wallpaperPlaceholder}
                            alt=""
                        />
                        { 
                            !isEmpty(props.status) ? 
                                <div className='shared-status-container'>
                                    { props.status === 'modified' ? <CustomizeButton label="Modified" variant="acent-small-button" /> : null }
                                    { props.status ==="accepted" ? <CustomizeButton label="Accepted" variant="green-small-button" />  : null } 
                                    { props.status ==="rejected" ?<CustomizeButton label="Rejected" variant="red-small-button" /> : null }
                                </div>
                                : null
                        }
                        {
                            !Boolean(props.hideIcon) &&
                                <Avatar 
                                    sx={{
                                        position: "absolute", 
                                        bottom: "66px",
                                        left: "20px", 
                                        width: "50px",
                                        height: "50px",
                                        boxShadow: "0px 0px 4px 3px grey",
                                        zIndex: '2',
                                        background: "white"
                                    }}
                                    src={props.icon || iconPlaceholder}
                                />
                        }
                        <div
                            style={{
                                ...(props.hideIcon ? {
                                    padding: '15px',
                                    paddingBottom : "8px",
                                    display: "flex", 
                                    alignItems: "center",
                                    height: "80px"
                                } : {
                                    padding: '15px',
                                    paddingTop : "28px",
                                    paddingBottom : "8px",
                                    display: "flex", 
                                    alignItems: "center",
                                    height: "90px"
                                })
                            }}
                        >
                            <Typography 
                                component="div" 
                                variant="p"
                                sx={{
                                    fontSize : "20px",
                                    color : "var(--primary-text-color-v2)",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp :2 ,
                                    WebkitBoxOrient:"vertical",
                                    lineHeight:"28px",
                                    textOverflow: "ellipsis",
                                    fontFamily: '"Lato", sans-serif',
                                    fontWeight: "400",
                                    flex : 1,
                                    minWidth: 0,
                                }}>
                                    {props.name}
                            </Typography>
                            <div className="ms-auto">
                                <LevelIndicator 
                                    level={props.level}
                                    maxLevel={props.maxLevel}
                                    containerClassName="d-flex flex-column align-items-center justify-content-center ps-1" 
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            )
        
        default: 
            return (
                <Card
                    className={props.selected ? `vcf-card-square ${props.containerClassName} selected` : `vcf-card-square ${props.containerClassName}`} 
                    onClick={props.onClick} >
                    <div className='p-relative'>
                        <img 
                            style={{
                                aspectRatio: '9/5',
                                width: '100%',
                                height: 'auto',
                                objectFit: "cover",
                                objectPosition: 'center'
                            }}
                            src={props.wallpaper || wallpaperPlaceholder}
                            alt=""
                        />
                        { 
                            !isEmpty(props.status) ? 
                                <div className='shared-status-container'>
                                    { props.status === 'modified' ? <CustomizeButton label="Modified" variant="acent-small-button" /> : null }
                                    { props.status ==="accepted" ? <CustomizeButton label="Accepted" variant="green-small-button" />  : null } 
                                    { props.status ==="rejected" ?<CustomizeButton label="Rejected" variant="red-small-button" /> : null }
                                </div>
                                : null
                        }
                        {
                            !Boolean(props.hideIcon) &&
                                <Avatar 
                                    sx={{
                                        position: "absolute", 
                                        bottom: "50px", 
                                        left: "10px",
                                        boxShadow: "0px 0px 4px 3px grey",
                                        zIndex: '2',
                                        background: "white"
                                    }} 
                                    src={props.icon ? props.icon : iconPlaceholder}
                                />
                        }
                        <div
                            style={{
                                ...(props.hideIcon ? {
                                    padding: '8px',
                                    paddingBottom : "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "72px"
                                } : {
                                    padding: '12px',
                                    paddingTop : "20px",
                                    paddingBottom : "2px",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "72px"
                                })
                            }}
                        >
                            <Typography 
                                component="div" 
                                variant="p"
                                sx={{
                                    fontSize : "17px",
                                    color : "var(--primary-text-color-v2)",
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitLineClamp :2 ,
                                    WebkitBoxOrient:"vertical",
                                    lineHeight:"20px",
                                    textOverflow: "ellipsis",
                                    fontFamily: '"Lato", sans-serif',
                                    fontWeight: "400",
                                    flex : 1,
                                    minWidth: 0,
                                }}
                            >
                                {props.name}
                            </Typography>
                            <div className="ms-auto">
                                <LevelIndicator
                                    level={props.level}
                                    maxLevel={props.maxLevel}
                                    containerClassName="d-flex flex-column align-items-center justify-content-center ps-1" />
                            </div>
                        </div>
                    </div>
                </Card>
            )
    }
}

export default AggregateCard