import React,{ useState, useEffect } from 'react'
import PopUp from '../../common/popup/PopUp'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { useFormik } from 'formik';
import * as yup from 'yup'
import {getObjById} from '../../pages/notification/modify-objective/utils'
import useMediaQuery from '@mui/material/useMediaQuery'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const AddToObjPopup = (props) => {

    const {modifyObjectiveState, ownProps} = props

    const objectives = [modifyObjectiveState.response.objective]
    const isDesktop = useMediaQuery('(min-width:992px)')

    let levelCountOfObjFor = getMaxLevel(ownProps.objFor)
    let objectiveFrom = fetchObjFrom(modifyObjectiveState.response.objective)

    const formik = useFormik({
        initialValues : {
            objectiveId : ''
        },
        validationSchema : yup.object().shape({
            objectiveId : yup.string().required('Please enter the objective')
        }),
        onSubmit : values => {
            const appropriateObj = getObjById(values.objectiveId, modifyObjectiveState.response.objective)
            ownProps.onAddedTo(appropriateObj)
            ownProps.handleClose()
        }
    })

    function validateAddTo(objective) {
        let levelValidity = false 
        if(objective.level + levelCountOfObjFor < 4) {
            levelValidity = true
        }

        let objNameValidity = true
        if(!isEmpty(objective.sub_objectives)){
            objective.sub_objectives.some(subObj => {
                if(subObj.name === objective.name){
                    objNameValidity = false
                    return true
                }
            })
        }

        if(levelValidity && objNameValidity){
            return true
        } else {
            return false
        }
    }

    function fetchObjFrom(objective) {
        let objFrom = {}
        function func(obj) {
            if (isEmpty(obj.sub_objectives) || !isEmpty(objFrom)) {
                return
            }

            obj.sub_objectives.some(subObj => {
                if((subObj._id === ownProps.objFor._id)){
                    objFrom = obj
                    return true
                }
            })

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }
        func(objective)
        return objFrom
    }

    function getMaxLevel(objective) {
        let levels = [] 
        function func(obj) {
            if(!levels.includes(obj.level)){
                levels.push(obj.level)
            }

            if (isEmpty(obj.sub_objectives)) {
                return
            }

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }
        func(objective)
        return levels.length
    }

    function disableOption(objective){
        if((ownProps.objFor._id !== objective._id) && validateAddTo(objective) && (objectiveFrom._id !== objective._id)){
            return false
        } else {
            return true
        }
    }

    function objectiveList(objectives) {

        let objectiveOptions = []
        
        objectives.forEach((objective, index) => {
            objectiveOptions.push(<option key={index} disabled={disableOption(objective)} value={objective._id}>{objective.name}</option>)  // level 0
            if(!isEmpty(objective.sub_objectives)){
                objective.sub_objectives.forEach((subObjOne) => {
                    objectiveOptions.push(<option key={subObjOne._id} disabled={disableOption(subObjOne)} value={subObjOne._id}>&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjOne.name}`}</option>) // level 1
                    if(!isEmpty(subObjOne.sub_objectives)){
                        subObjOne.sub_objectives.forEach(subObjTwo => {
                            objectiveOptions.push(<option key={subObjTwo._id} disabled={disableOption(subObjTwo)} value={subObjTwo._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjTwo.name}`}</option>)  // level 2
                            if(!isEmpty(subObjTwo.sub_objectives)){
                                subObjTwo.sub_objectives.forEach(subObjThree => {
                                    objectiveOptions.push(<option key={subObjThree._id} disabled={disableOption(subObjThree)} value={subObjThree._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjThree.name}`}</option>)  // level 3
                                })
                            }
                        })
                    }
                })
            }
        })

        return objectiveOptions
    }

    return (
        <PopUp handleClose={props.handleClose} open={props.open}>
            <div className={isDesktop ? 'p-3 min-width-300px' : 'p-3 min-width-250px'}>
                <form onSubmit={formik.handleSubmit}>
                    <h6 className="lato-fs22-normal-lh32 text-center primary-text-color font-bold pb-2">{`Add ${ownProps.objFor.name} to`}</h6>
                    <select name="objective" className="grey-select-box" onChange={formik.handleChange} name='objectiveId' value={formik.values.objectiveId}>
                        <option value="" hidden>Select a objective to add to</option>
                        {objectiveList(objectives)}
                    </select>
                    <div className="mt-3 d-flex justify-content-center align-items-center">
                        <CustomizeButton type="submit" variant="primary-color-button" label="Add" />
                    </div>
                </form>
            </div>
        </PopUp>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        modifyObjectiveState : state.modifyObjective,
        ownProps : ownProps
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         requestMaxLevelObjective : (maxLevel) => dispatch(maxLevelObjective(maxLevel)),
//         clearObjectives : () => dispatch(clearChoosePathObjective())
//     }
// }

export default connect(mapStateToProps, null)(AddToObjPopup)