import { REQUEST_CHOOSE_PATH,
         CHOOSE_PATH_SUCCESS,
         CHOOSE_PATH_FAILURE,
         CLEAR_CHOOSE_PATH_STATE } from './ChoosePathObjective.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const choosePathObjectiveReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_CHOOSE_PATH :
            return {
                ...state,
                loading : true,
                error : null
            }

        case CHOOSE_PATH_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case CHOOSE_PATH_FAILURE : 
            return {
                ...state,
                loading: false,
                error : action.payload
            }

        case CLEAR_CHOOSE_PATH_STATE :
            return {
                ...state,
                loading: false,
                response : {},
                error : null
            }

        default :
            return state
    }
}

export default choosePathObjectiveReducer