import React from 'react'
import { useHistory } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import useMediaQuery from '@mui/material/useMediaQuery';

import './goal-list-popup.css'
import PopUp from '../../common/popup/PopUp'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import SelectWithGroupedOption from '../../common/select-with-grouped-option/SelectWithGroupedOption'

const GoalListPopup = (props) => {

    const history = useHistory()
    const isMobile = useMediaQuery('(max-width:480px)')

    function onClickCreateGoal(){
        history.push("/goal/create")
    }

    function goalList(objectives){
        let goalOption = []
            for(let objective of objectives){
                let goals=[]
                for( let goal of objective.goals){
                    goals.push(<MenuItem sx={{fontSize:"15px",fontFamily:"'Lato',sans-serif",color:"#444444"}} value={goal._id} key={goal._id}>{goal.name}</MenuItem>)
                }
                goalOption.push(<ListSubheader sx={{fontSize:"13px",
                                                    fontFamily:"'Lato',sans-serif",
                                                    lineHeight:"30px",
                                                    color:"#858585"}} key={objective._id} >{objective.name}</ListSubheader>,goals)
            }
        return goalOption
    }

    const className = isMobile ? 'p-3' : 'p-4 min-width-350px'

    return (
        <PopUp handleClose={props.handleClose} open={props.open} fullWidth={isMobile} >
            <div className={className} >
                <h2 className="lato-fs-20-normal-lh32 font-bold primary-text-color text-center">Select Goal</h2>
                <div style={{minWidth: "120px", marginTop:"10px"}}>
                    <SelectWithGroupedOption 
                        id="grouped-select"
                        label="Goal"
                        selectProps={{
                            name: props.name,
                            onChange: props.onChange,
                            onBlur: props.onBlur,
                            value: props.value
                        }}
                        options={goalList(props.options)}
                    />
                </div>
                <hr/>
                <div className="d-flex justify-content-center align-item-center mb-2">
                    <CustomizeButton variant="primary-color-button" handleClick={onClickCreateGoal} label="Create Goal"  />
                </div>
            </div>
        </PopUp>
    )
}

export default GoalListPopup
