import React from 'react'
import './simple-tab-panel.css'

const SimpleTabPanel = (props) => {

  const { children, value, containerClassname } = props;

  const style = props.style ? props.style : {} 

  return (
      <div 
        role="tabpanel"
        hidden={props.selectedTab !== value}
        style={style}
        className={ containerClassname ? containerClassname : ''} >
          {
            value === props.selectedTab ?
              children
              : null
          }
      </div>
  )
}

export default SimpleTabPanel