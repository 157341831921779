import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

import InputBox from '../../common/Input-box/InputBox'
import TextAreaBox from '../../common/textarea-box/TextAreaBox'
import PopUp from '../../common/popup/PopUp'
import { fetchCountries } from '../../services/constants.service'
import './create-branch-popup.css'
import SelectBox from '../../common/select-box/SelectBox'
import { saveBranches } from '../../redux/Branch/branches/Branches.action'
import { createBranch, fetchBranches, updateBranch } from '../../services/branch.service'
import { clearCUBranchState } from '../../redux/Branch/CU-branch/CUBranch.action'
import { toast } from 'react-toastify'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const CreateBranchPopup = (props) => {

    const { 
        ownProps, 
        requestCreateBranch, 
        clearCUBranchState,
        cuBranchState, 
        branchesState,
        saveBranch,
        fetchBranch,
        updateBranch
    } = props

    const [ countries,setCountries ] = useState([])
    const [ pageLoading, setPageLoading ] = useState({
        country : true,
        branches : true
    })

    const activeBranch = branchesState.response.active_branches

    const initialValues = {
        door_no: ownProps.editBranch == true && !isEmpty(activeBranch) && !isEmpty(activeBranch[ownProps.branchIndex]) && activeBranch[ownProps.branchIndex].door_no ? activeBranch[ownProps.branchIndex].door_no : "",
        area: ownProps.editBranch == true && !isEmpty(activeBranch) && !isEmpty(activeBranch[ownProps.branchIndex]) && activeBranch[ownProps.branchIndex].area ? activeBranch[ownProps.branchIndex].area : "",
        city: ownProps.editBranch == true && !isEmpty(activeBranch) && !isEmpty(activeBranch[ownProps.branchIndex]) && activeBranch[ownProps.branchIndex].city ? activeBranch[ownProps.branchIndex].city : "",
        state: ownProps.editBranch == true && !isEmpty(activeBranch) && !isEmpty(activeBranch[ownProps.branchIndex]) && activeBranch[ownProps.branchIndex].state ? activeBranch[ownProps.branchIndex].state: "",
        country: ownProps.editBranch == true && !isEmpty(activeBranch) && !isEmpty(activeBranch[ownProps.branchIndex]) && activeBranch[ownProps.branchIndex].country ? activeBranch[ownProps.branchIndex].country : "",
        pincode: ownProps.editBranch == true && !isEmpty(activeBranch) && !isEmpty(activeBranch[ownProps.branchIndex]) && activeBranch[ownProps.branchIndex].pincode ? activeBranch[ownProps.branchIndex].pincode : ""
    }

    const validationSchema = Yup.object({
        door_no : Yup.string().required("Please enter door no"),
        area : Yup.string().required("Please enter area"),
        city: Yup.string().required("Please enter your city"),
        state: Yup.string().required("Please enter your State/Province/Region"),
        country: Yup.string().required("Please enter your Country"),
        pincode : Yup.string().required('Please enter your ZIP/Postal Code')
    })

    const onSubmit = (values) => {
        if(ownProps.editBranch == true){
            updateBranch(activeBranch[ownProps.branchIndex]._id,values)
        }else{
            requestCreateBranch(values)
        }
    }

    const formik = useFormik({
        // enableReinitialize = true,
        initialValues,
        validationSchema,
        onSubmit
    })

    function requestState(){
        fetchCountries()
        .then((res) => {
            setCountries(res.data.countries)
            setPageLoading(ps => {
                return {
                    ...ps,
                    country : false
                }
            })
        }).catch((error) => {
            console.log(error.response)
        })
    }

    function getPageLoading(){
        if(ownProps.editBranch == true){
            return !pageLoading.country && !pageLoading.branches
        }else{
            return !pageLoading.country
        }
    }

    useEffect(() => {
        if( ownProps.editBranch == true ){
            requestState()
            if(isEmpty(branchesState.response)){
                fetchBranch()
            }
        }else{
            requestState()
        }

        return() => {
            clearCUBranchState()
        }
    },[])

    useEffect(() => {
        if(!cuBranchState.loading && !isEmpty(cuBranchState.response)){
            saveBranch(cuBranchState.response)
            ownProps.handleClose()
        }
        if(!cuBranchState.loading && !isEmpty(cuBranchState.error)){
            console.log(cuBranchState.error)
            if(cuBranchState.error?.data){
                toast.error(cuBranchState.error?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[cuBranchState])

    useEffect(() => {
        if(!branchesState.loading && !isEmpty(branchesState.response)){
            setPageLoading(ps => {
                return {
                    ...ps,
                    branches : false
                }
            })
        }
        if(!branchesState.loading && !isEmpty(branchesState.error)){
            console.log(branchesState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    branches : false
                }
            })
            if(branchesState.error?.data){
                toast.error(branchesState.error?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[branchesState])

    return (
        <PopUp
            open={ownProps.open}
            handleClose={ownProps.handleClose} >
                { 
                    getPageLoading() ?
                    <div className='p-3'>
                        <div className='d-flex justify-content-center align-items-center mb-3'>
                            <span className="lato-fs24-bold-lh32 text-center">{`${ownProps.heading} Location`}</span>
                            {/* <div className='close-btn-right'>
                                <IconButton onClick={ownProps.handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div> */}
                        </div>
                        <div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12 mt-3">
                                        <InputBox type="text"
                                            name="door_no"
                                            label="Address Line 1"
                                            placeholder="Enter Apartment, suite, unit, building, floor, etc.,"
                                            value={formik.values.door_no}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMgs={formik.touched.door_no && formik.errors.door_no ? formik.errors.door_no : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mt-3">
                                        <TextAreaBox 
                                            name="area"
                                            label="Address Line 2"
                                            placeholder="Enter Street address, P.O. box, company name, c/o"
                                            value={formik.values.area}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errormgs={formik.touched.area && formik.errors.area ? formik.errors.area : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <InputBox type="text"
                                            name="city"
                                            label="City"
                                            placeholder="Enter city"
                                            value={formik.values.city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMgs={formik.touched.city && formik.errors.city ? formik.errors.city : ""}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <InputBox type="text"
                                            name="state"
                                            label="State/Province/Region"
                                            placeholder="Please enter State/Province/Region"
                                            value={formik.values.state}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMgs={formik.touched.state && formik.errors.state ? formik.errors.state : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <InputBox type="text"
                                            name="pincode"
                                            placeholder="Enter ZIP/Postal Code"
                                            label="ZIP/Postal Code"
                                            value={formik.values.pincode}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMgs={formik.touched.pincode && formik.errors.pincode ? formik.errors.pincode : ""}
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <SelectBox  
                                            options={countries}
                                            label="Country"
                                            name="country"
                                            value={formik.values.country}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            errorMgs={formik.errors.country && formik.touched.country ? formik.errors.country : ""}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center mt-4 mb-3" >
                                    <CustomizeButton
                                        type="submit"
                                        label={`${ownProps.buttonLabel} Location`}
                                        variant="primary-color-button"
                                    />
                                </div>
                            </form>
                        </div>
                    </div> : null 
                }
        </PopUp>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        ownProps : ownProps,
        cuBranchState : state.cuBranch,
        branchesState : state.branches
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestCreateBranch : (branchDetails) => dispatch(createBranch(branchDetails)),
        saveBranch : (response) => dispatch(saveBranches(response)),
        updateBranch : (id,branchDetails) => dispatch(updateBranch(id,branchDetails)),
        clearCUBranchState : () => dispatch(clearCUBranchState()),
        fetchBranch : () => dispatch(fetchBranches())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateBranchPopup)