import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { isEmpty } from 'lodash'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { styled } from "@mui/material/styles"

import PopUp from '../../common/popup/PopUp'
import { fetchMemberDetail, getRoleMembers } from '../../services/user.service'
import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import { replaceTeamMember, teamList } from '../../services/teamMember.service'
import './replace-team-member.css'
import { clearTeamMemberState } from '../../redux/team-member/teamMember.action'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const useStyles = makeStyles({
    profileImg:{
        width:50,
        height:50,
        borderRadius:50,
        objectFit:"cover",
    },
    memberDetail:{
        display:"flex",
        flexDirection:"column"
    },
    labelName:{
        display:"inline-block",
        fontSize:12,
        whiteSpace :"nowrap",
        marginTop:1,
    },
    labelContainer:{
        marginLeft:8,
        fontSize:0,
    }
})

const CssTextField = styled(TextField)({
    "& label" : {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
    },
    // input label when focused
    "& label.Mui-focused": {
        color: "var(--primary-color-v2) !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
        "&.Mui-focused fieldset": {
            borderColor: "var(--primary-color-v2) !important"
        }
    }
})

const ReplaceTeamMember = (props) => {

    const { memberDetailState, teamMembersState, requestTeamMember, ownProps, clearTeamMemberState, requestMemberDetail  } = props

    const memberDetail = memberDetailState.response
    const teamMembers = teamMembersState.response.team_members

    const classes = useStyles()
    const params = useParams()
    const history = useHistory()
    
    const [ members, setMembers ] = useState([])
    const [ pageLoading, setPageLoading ] = useState(true)

    const initialValues = {
        existing_user : params.memberId,
        replacing_user : "",
        assignees : !isEmpty(memberDetail) && !isEmpty((memberDetail.user_subscriber_mapping[0])) ? selectAssignee() : [],
        existing_team : !isEmpty(memberDetail) && !isEmpty(memberDetail.user_subscriber_mapping[0]) && memberDetail.user_subscriber_mapping[0].assignees.length == 1 ? selectAllCheckbox() : []
    }

    const onSubmit = (values) => {
        const valuesCopy = {...values}
        valuesCopy.replacing_user = values.replacing_user._id
        // console.log(valuesCopy)
        replaceTeamMember(valuesCopy)
        .then(res => {
            // console.log(res)
            history.goBack()
            toast.success("Successfully replaced" , {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch((err) =>{
            console.log(err)
            if(err && err?.response && err?.response.data){
                toast.error(err.response.data.error , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    function selectAssignee(){
        if(memberDetail){
            if(memberDetail.user_subscriber_mapping){
                if(!isEmpty(memberDetail.user_subscriber_mapping[0].assignees)){
                    if(memberDetail.user_subscriber_mapping[0].assignees.length == 1){
                        return [memberDetail.user_subscriber_mapping[0].assignees[0]._id]
                    }
                }
            }
        }
    }

    function selectAllCheckbox(){
        if(!isEmpty(teamMembers)){
            return teamMembers.map((member) => {
                return member._id
            })
        }
    }

    async function handleInputChange(event,value){
        if(value.length > 1){
            try {
                const emailSuggestions = await getRoleMembers(value,params.memberId)
                setMembers(emailSuggestions.data.users) 
            } catch (error) {
                console.log(error)
                if(error && error?.response && error?.response?.data){
                    toast.error(error.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    }

    useEffect(()=>{
        requestTeamMember(false,params.memberId)
        return () => {
            clearTeamMemberState()
        }
    },[])

    useEffect(() => {
        if(!teamMembersState.loading && !isEmpty(teamMembersState.response)){
            // console.log("team",teamMembersState.response)
            setPageLoading(false)
        }

        if(!teamMembersState.loading && !isEmpty(teamMembersState.error)){
            console.log(teamMembersState.error)
            setPageLoading(false)
            if(teamMembersState.error?.data){
                toast.error(teamMembersState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }

        }
    },[teamMembersState])

    return (
        <PopUp 
            open={props.open} 
            handleClose={ownProps.handleClose}    
        >
            { !pageLoading ?
                <div className='min-width-300px p-4'>
                    <h4 className="lato-fs22-normal-lh32 font-bold text-center">Replace this member</h4>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit} >
                            {(props) => {
                                const { setFieldValue, values } =props
                                return(
                                    <Form>
                                    { !isEmpty(memberDetail) && !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0].assignees) && memberDetail.user_subscriber_mapping[0].assignees.length > 1 ?
                                        <>
                                            <h6 className="secondary-color fs18-lh20 ">Reviewer: </h6>
                                            <div role="group" aria-labelledby="checkbox-group">
                                                { memberDetail.user_subscriber_mapping[0].assignees.map((member, index) => (
                                                    <div className="non-invited-member-container" key={index}>
                                                        <Field type="checkbox" name="assignees" value={member._id} />
                                                        <label className="ms-3 mb-0">
                                                            <span>
                                                                {!isEmpty(member.person_name) && member.person_name.first_name ? member.person_name.first_name : "" }  
                                                            </span>
                                                            <span className="ms-1">
                                                                {!isEmpty(member.person_name) && member.person_name.last_name ? member.person_name.last_name : "" }
                                                            </span>
                                                            <br/>
                                                            <span>{!isEmpty(member.user_subscriber_mapping) && !isEmpty(member.user_subscriber_mapping[0].job_role) ? member.user_subscriber_mapping[0].job_role : ""}</span>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </> : null }
                                        <div>
                                            <h6 className="mb-0 mt-3 lato-fs16-normal-lh20 tertiary-text-color-v2">Replacing Member </h6>
                                            <Autocomplete
                                                options={members}
                                                name='replacing_user'
                                                id="tags-outlined"
                                                getOptionLabel={(suggestion) => suggestion.email.id}
                                                renderOption={
                                                    (props, option)=> {
                                                        return (
                                                            <div key={option._id} className="d-flex ps-2" {...props}>
                                                                <img className={classes.profileImg} src={option.profile_pic ? option.profile_pic : iconPlaceholder}></img>
                                                                <div className={classes.labelContainer}>
                                                                    {console.log("option",option)}
                                                                    { !isEmpty(option.person_name) ?
                                                                        <span className={classes.labelName}>{`${option.person_name.first_name}  ${option.person_name.last_name}`}</span> : ""}<br/>
                                                                    { !isEmpty(option.email) ?
                                                                        <span className={classes.labelName}>{option.email.id}</span> : ""}<br/>
                                                                    { !isEmpty(option.user_subscriber_mapping) && !isEmpty(option.user_subscriber_mapping[0]) && option.user_subscriber_mapping[0].job_role ? <span className={classes.labelName}>{option.user_subscriber_mapping[0].job_role}</span> : ""}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }
                                                onInputChange={handleInputChange}
                                                onChange={(event, value) => {
                                                    setFieldValue('replacing_user', value)
                                                }}
                                                freeSolo
                                                renderInput={(params) => {
                                                    const inputProps = params.inputProps;
                                                    inputProps.autoComplete = "off";
                                                    return (
                                                        <CssTextField
                                                            {...params}
                                                            inputProps={inputProps}
                                                            name='replacing_user'
                                                            variant="outlined"
                                                            label="Email Id"
                                                            placeholder="Enter email id"
                                                            margin="normal"
                                                            fullWidth
                                                        />
                                                    )
                                                }}
                                            />
                                        </div>
                                        { !isEmpty(teamMembers) && memberDetail.user_subscriber_mapping[0].assignees.length > 1 ?
                                            <div className="mt-2">
                                                <h6 className="lato-fs16-normal-lh20 tertiary-text-color-v2">Team Members</h6>
                                                <div role="group" aria-labelledby="checkbox-group">
                                                    { teamMembers.map((member, index) => (
                                                        <div className="non-invited-member-container" key={index}>
                                                            <Field type="checkbox" name="existing_team" value={member._id} />
                                                            <label className="ms-3 mb-0">
                                                                <span>
                                                                    {!isEmpty(member.person_name) && member.person_name.first_name ? member.person_name.first_name : "" }  
                                                                </span>
                                                                <span className="ms-1">
                                                                    {!isEmpty(member.person_name) && member.person_name.last_name ? member.person_name.last_name : "" }
                                                                </span>
                                                                <br/>
                                                                <span>{!isEmpty(member.user_subscriber_mapping) && !isEmpty(member.user_subscriber_mapping[0].job_role) ? member.user_subscriber_mapping[0].job_role : ""}</span>
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div> : null }
                                        <div className="mt-3 d-flex justify-content-center">
                                            <CustomizeButton variant="secondary-color-button" handleClick={ownProps.handleClose} label="Cancel" />
                                            <CustomizeButton variant="primary-color-button" className="ms-3" type="submit" label="Replace Member" />
                                        </div> 
                                </Form>
                                )}}
                        </Formik>
                    </div>
                </div>
            : null }
        </PopUp>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        memberDetailState : state.memberDetail,
        teamMembersState : state.teamMembers,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestTeamMember : (wfmboolean,userId) => dispatch(teamList(wfmboolean,userId)),
        clearTeamMemberState : () => dispatch(clearTeamMemberState()),
        requestMemberDetail : (memberId) => dispatch(fetchMemberDetail(memberId))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReplaceTeamMember)
