import React,{ useState, useEffect, useRef } from 'react'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'

import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import DatePicker from '../../../common/date-picker/DatePicker'
import { modifyLearningAreaSuccess, saveLearningAreaIcon, saveLearningValidationError } from '../../../redux/notification/modify-learning-area/ModifyLearningArea.action'
import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import editIcon from '../../../assets/icons/edit_white.svg'
import { formatDate } from '../../../utils/utils'
import {learningAreaValidationSchema} from './ModifyLearning.utils'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import ImgMenu from '../../../common/img-menu/ImgMenu'

const ModifyLearningAreaForm = (props) => {

    const { 
        modifyLearningAreaState, 
        saveEditedLearningArea, 
        saveLearningAreaIcon, 
        ownProps,
        saveLearningAreaValidationError
    } = props

    const iconImageRef = useRef()
    const formRef = useRef()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [iconImageFile,setIconImageFile] = useState('')
    const [iconPreviewImage, setIconPreviewImage] = useState('')
    const [iconAnchorEl, setIconAnchorEl] = useState(null)

    const learningArea = modifyLearningAreaState.response?.learning_area

    const initialValues = {
        name: !isEmpty(learningArea) && learningArea.name ? learningArea.name : "",
        description: !isEmpty(learningArea) && learningArea.description ? learningArea.description : "",
        start_date: !isEmpty(learningArea) && learningArea.start_date ? formatDate(learningArea.start_date) : "",
        end_date: !isEmpty(learningArea) && learningArea.end_date ? formatDate(learningArea.end_date) : "",
    }

    const onSubmit = (values) =>{
        const editedLearningArea = editLearningArea(values)
        saveEditedLearningArea({learning_area: editedLearningArea})
        if(iconImageFile){
            saveLearningAreaIcon(iconPreviewImage, iconImageFile)
        }
        saveLearningAreaValidationError({id: learningArea._id, hasErrors: false})
        handleGoBack()
    }

    function editLearningArea(value){
        const learningAreaCopy = JSON.parse(JSON.stringify(learningArea))
        if(value.name !== learningAreaCopy.name){
            learningAreaCopy.name = value.name
        }
        if(value.description !== learningAreaCopy.description){
            learningAreaCopy.description = value.description
        }
        if(value.start_date !== learningAreaCopy.start_date){
            learningAreaCopy.start_date = value.start_date
        }
        if(value.end_date !== learningAreaCopy.end_date){
            learningAreaCopy.end_date = value.end_date
        }
        if(!iconPreviewImage){
            learningAreaCopy.icon = ""
        }
        if(iconImageFile){
            if(learningAreaCopy.icon){
                delete learningAreaCopy.icon
            }
        }
        return learningAreaCopy
    }

    const iconMenuOpen = Boolean(iconAnchorEl)

    const handleEditIconClick = (event) => {
        setIconAnchorEl(event.currentTarget)
    }

    const handleIconMenuClose = () => {
        setIconAnchorEl(null)
    }

    const onRemoveIconClick = () => {
        if(iconImageFile) setIconImageFile('')
        if(iconPreviewImage) setIconPreviewImage('')
        if(learningArea?.icon && !iconPreviewImage){
            return learningArea.icon = ""
        }
    }

    useEffect(() => {
        setIconPreviewImage(modifyLearningAreaState.icon?.iconPreview || learningArea.icon)
    }, [])

    useEffect(() => {
        if(!isEmpty(modifyLearningAreaState.validationError)){
            const appropriateObj = modifyLearningAreaState.validationError.find(obj => obj.id === learningArea._id)
            if(!isEmpty(appropriateObj) && appropriateObj.hasErrors && !isEmpty(formRef.current)){
                formRef.current.validateForm()
                formRef.current.setFieldTouched('name')
                formRef.current.setFieldTouched('description')
                formRef.current.setFieldTouched('start_date')
                formRef.current.setFieldTouched('end_date')
            }
        }
    }, [modifyLearningAreaState.validationError])

    function handleGoBack(){
        ownProps.cancel()
    }

    useEffect(() =>{
        if(iconImageFile){
            const reader = new FileReader()
            reader.onloadend = () => {
                setIconPreviewImage(reader.result)
            }
            reader.readAsDataURL(iconImageFile)
        }
    },[iconImageFile])

    return (
        <div className="ml-10 mr-10 mb-4">
            <div className="icon-preview-container ms-0 mt-1">
                <img className="icon-preview-image" src={iconPreviewImage ? iconPreviewImage : iconPlaceholder} alt=""/>
                <button 
                    id="icon-edit-icon"
                    aria-controls={iconMenuOpen ? 'icon-edit-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={iconMenuOpen ? 'true' : undefined}
                    className="image-button" 
                    onClick={handleEditIconClick}>
                        <img src={editIcon} alt=""></img>
                </button>
                <ImgMenu 
                    id="icon-edit-menu"
                    ariaLabelledBy="icon-edit-icon"
                    anchorEl={iconAnchorEl}
                    open={iconMenuOpen}
                    onClose={handleIconMenuClose}
                    onUploadClick={(event) => {
                        event.preventDefault();
                        iconImageRef.current.click();
                        handleIconMenuClose()
                    }}
                    onRemoveClick={() => {
                        onRemoveIconClick()
                        handleIconMenuClose()
                    }}
                    disableRemove={Boolean(!iconPreviewImage)}
                />
            </div>
            <input type="file"
                style={{display:"none"}} 
                ref={iconImageRef} 
                accept="image/*"
                name="objective_icon"
                onClick={(event) => {
                    event.target.value = null
                }}
                onChange={(event) =>{
                    const file = event.target.files[0];
                    if(file && file.type.substr(0,5) === "image"){
                        setIconImageFile(file)
                    }else {
                        setIconImageFile(null)
                    }
                }}
            />
            <Formik 
                enableReinitialize={true}
                initialValues={initialValues}
                innerRef={formRef}
                validationSchema={learningAreaValidationSchema()}
                onSubmit={onSubmit}>
                {props => {
                    const { values, touched, errors, handleBlur, handleChange } = props
                    return (
                        <Form>
                            <Grid container spacing={2} sx={{marginTop:"12px"}}>
                                <Grid item md={4} xs={12}>
                                    <InputBox type="text" 
                                        name="name"
                                        label="Name"
                                        placeholder="Enter the learning area name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMgs={touched.name && errors.name ? errors.name : "" }
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <DatePicker type="date" 
                                        label="Start Date"
                                        name="start_date"
                                        value={values.start_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMgs={touched.start_date && errors.start_date ? errors.start_date : "" }
                                    />
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <DatePicker type="date" 
                                        label="End Date"
                                        name="end_date"
                                        min={moment().format("YYYY-MM-DD")}
                                        value={values.end_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMgs={touched.end_date && errors.end_date ? errors.end_date : "" }
                                    />
                                </Grid>
                            </Grid>
                            <div className="mt-3">
                                <TextAreaBox name="description" 
                                    label="Description"
                                    placeholder = "Enter the learning area description"
                                    rows={isDesktop ? 4 : ""}
                                    value = {values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errormgs={touched.description && errors.description ? errors.description : "" }
                                />
                            </div>
                            <div className="d-flex justify-content-center align-center mt-4">
                                <CustomizeButton variant="secondary-color-button" label="Cancel" handleClick={handleGoBack} className="me-4" />
                                <CustomizeButton variant="primary-color-button" label="Save" type="submit" />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        modifyLearningAreaState : state.modifyLearningArea,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveEditedLearningArea : (editedLearningArea) => dispatch(modifyLearningAreaSuccess(editedLearningArea)),
        saveLearningAreaIcon : (iconPreview, file) => dispatch(saveLearningAreaIcon(iconPreview, file)),
        saveLearningAreaValidationError : (payload) => dispatch(saveLearningValidationError(payload)) 
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModifyLearningAreaForm)
