import { isEmpty } from 'lodash'
import React,{ useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { toast } from 'react-toastify'

import { requestInvites } from '../../services/videoConference.service'
import VideoConferenceCard from '../video-conference-card/VideoConferenceCard'

const Invites = (props) => {

    const { requestInvite, invitesState } = props

    const invites = invitesState.response.invites

    const history =  useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')
    
    const [pageLoading,setPageLoading] = useState(true)
    const [anchorEls, setAnchorEls] = useState({})

    const handleClick = (event, id) => {
        setAnchorEls({[id]: event.currentTarget})
    }

    const handleClose = () => {
        setAnchorEls({})
    }

    useEffect(() => {
        requestInvite()
    },[])
    
    useEffect(() => {
        if(!invitesState.loading && !isEmpty(invitesState.response)){
            // console.log(invitesState.response)
            setPageLoading(false)
        }

        if(!invitesState.loading && !isEmpty(invitesState.error)){
            console.log(invitesState.error)
            setPageLoading(false)
            if(!isEmpty(invitesState.error)){
                if(invitesState.error.data){
                    toast.error(invitesState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[invitesState])

    return (
        !pageLoading ?
            <div className={isDesktop ? "pt-5" : "container pt-4 px-4"}>
                { 
                    !isEmpty(invites.past) || !isEmpty(invites.present) || !isEmpty(invites.future) ?
                        <>
                            {
                                !isEmpty(invites.present) ?
                                    <div>
                                        <h4 className='lato-fs16-normal-lh20 secondary-text-color mb-3'>Meetings today</h4>
                                        {
                                            invites.present.map((meeting, index) => (
                                                <div key={meeting._id} className="mb-3">
                                                    <VideoConferenceCard
                                                        meetingDate = {moment(meeting.scheduled_at).format('DD MMM YYYY')}
                                                        meetingTime = {moment(meeting.scheduled_at).format('hh:mm A')}
                                                        subject = {meeting.subject}
                                                        buttonLabel="Join"
                                                        enableButton={true}
                                                        anchorEl = {anchorEls[meeting._id] ? anchorEls[meeting._id] : null}
                                                        handleClick = {(event) => handleClick(event, meeting._id)}
                                                        handleClose= {handleClose}
                                                        conferenceId = {meeting.channel_id}
                                                        memberPopUpId={meeting._id}
                                                        FromMeetingMember={true}
                                                        memberProfile={meeting.from?.user?.profile_pic}
                                                        memberName={`${meeting.from?.user?.person_name?.first_name} ${meeting.from?.user?.person_name?.middle_name} ${meeting.from?.user?.person_name?.last_name}`}
                                                        memberEmail={meeting.from?.user?.email?.id}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : null
                            }
                            {
                                !isEmpty(invites.future) ?
                                    <div>
                                        <h4 className='lato-fs16-normal-lh20 secondary-text-color mb-3'>Future meetings</h4>
                                        {
                                            invites.future.map((meeting, index) => (
                                                <div key={meeting._id} className="mb-3">
                                                    <VideoConferenceCard
                                                        meetingDate = {moment(meeting.scheduled_at).format('DD MMM YYYY')}
                                                        meetingTime = {moment(meeting.scheduled_at).format('hh:mm A')}
                                                        subject = {meeting.subject}
                                                        buttonLabel="Join"
                                                        enableButton={true}
                                                        anchorEl = {anchorEls[meeting._id] ? anchorEls[meeting._id] : null}
                                                        handleClick = {(event) => handleClick(event, meeting._id)}
                                                        handleClose= {handleClose}
                                                        conferenceId = {meeting.channel_id}
                                                        memberPopUpId={meeting._id}
                                                        FromMeetingMember={true}
                                                        memberProfile={meeting.from?.user?.profile_pic}
                                                        memberName={`${meeting.from?.user?.person_name?.first_name} ${meeting.from?.user?.person_name?.middle_name} ${meeting.from?.user?.person_name?.last_name}`}
                                                        memberEmail={meeting.from?.user?.email?.id}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : null
                            }
                            {
                                !isEmpty(invites.past) ?
                                    <div>
                                        <h4 className='lato-fs16-normal-lh20 secondary-text-color mb-3'>Past meetings</h4>
                                        {
                                            invites.past.map((meeting, index) => (
                                                <div key={meeting} className="mb-3">
                                                    <VideoConferenceCard
                                                        meetingDate = {moment(meeting.scheduled_at).format('DD MMM YYYY')}
                                                        meetingTime = {moment(meeting.scheduled_at).format('hh:mm A')}
                                                        subject = {meeting.subject}
                                                        buttonLabel="Join"
                                                        anchorEl = {anchorEls[meeting._id] ? anchorEls[meeting._id] : null}
                                                        handleClick = {(event) => handleClick(event, meeting._id)}
                                                        handleClose= {handleClose}
                                                        conferenceId = {meeting.channel_id}
                                                        memberPopUpId={meeting._id}
                                                        FromMeetingMember={true}
                                                        memberProfile={meeting.from?.user?.profile_pic}
                                                        memberName={`${meeting.from?.user?.person_name?.first_name} ${meeting.from?.user?.person_name?.middle_name} ${meeting.from?.user?.person_name?.last_name}`}
                                                        memberEmail={meeting.from?.user?.email?.id}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : null
                            }
                        </>
                        : <div className={isDesktop ? "d-flex" : "d-flex justify-content-center"}>
                            <p className="lato-fs18-normal-lh25 text-center mb-0">You don't have any invites yet</p>
                        </div>
                }
            </div>
            : null 
    )
}

const mapStateToProps = (state) => {
    return {
        invitesState : state.videoConferenceInvites
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestInvite : () => dispatch(requestInvites())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Invites)