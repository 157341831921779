export const REQUEST_CREATE_OBJECTIVE = "request_create_objective"
export const CREATE_OBJECTIVE_SUCCESS = "create_objective_success"
export const CREATE_OBJECTIVE_FAILURE = "create_objective_failure"

export const REQUEST_EDIT_OBJECTIVE = "request_edit_objective"
export const EDIT_OBJECTIVE_SUCCESS = "edit_objective_success"
export const EDIT_OBJECTIVE_FAILURE = "edit_objective_failure"

export const REQUEST_DELETE_OBJECTIVE = "request_delete_objective"
export const DELETE_OBJECTIVE_SUCCESS = "delete_objective_success"
export const DELETE_OBJECTIVE_FAILURE = "delete_objective_failure"

export const CLEAR_OBJECTIVE_STATE = "clear_objective_state"
export const CLEAR_OBJECTIVE_ERROR = "clear_objective_error"