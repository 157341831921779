import React from 'react';

const ProcessContentArea = (props) => {

    const className = props.className ? `mb-4 ${props.className}` : 'mb-4'

    return (
        <div
            className={className} 
            style={{ 
                marginTop: '-220px',
                position: 'relative',
                zIndex: "3",
                width: "85%",
                marginLeft: "auto",
                marginRight: "auto",
            }}>
            {props.children}
        </div>
    )
};

export default ProcessContentArea;
