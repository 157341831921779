import React from 'react'
import { faBuilding, faListAlt, faPlusCircle, faSignal, faThLarge, faTrophy, faVideo } from '@fortawesome/free-solid-svg-icons'
import IconButton from '@mui/material/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink, useLocation } from 'react-router-dom'

import activeGoalIcon from '../../../assets/icons/bullseye-white.svg'
import goalIcon from '../../../assets/icons/bullseye-smoky-white.svg'
import './left-vertical-menu-bar.css'
import LightTooltip from '../../../common/light-tooltip/LightTooltip'

const LeftVerticalMenuBar = (props) => {

    const location = useLocation()
    const currentLocation = location.pathname.split('/')

    return (
        <div className='left-vertical-menu-bar'>
            <div style={{width: '100%'}}>
                <div className='process-bg mt-2'>
                    <NavLink 
                        exact
                        className='process-navlink d-flex flex-column align-items-center py-3'
                        activeClassName="active"
                        isActive={() => currentLocation[1] === "vcf"}
                        to="/vcf?mode=list-vcfs-by-lvl&lvl=0" >
                        <LightTooltip title="VCF" placement="right">
                            <div>
                                <FontAwesomeIcon icon={faThLarge} style={{color: `${currentLocation[1] === "vcf" ? "#ffffff" : "#b0b0b0"}`, fontSize:"22px"}}/>
                            </div>
                        </LightTooltip>
                    </NavLink>
                    <NavLink
                        exact
                        className='process-navlink d-flex flex-column align-items-center py-3'
                        activeClassName="active"
                        isActive={() => currentLocation[1] === "goal"} 
                        to="/goal" >
                        <LightTooltip title="Goals" placement="right">
                            <div>
                                <img src={currentLocation[1] === "goal" ? activeGoalIcon : goalIcon} style={{width:"22px", height:"22px"}} alt="goal icon"/>
                            </div>
                        </LightTooltip>
                    </NavLink>
                    <NavLink
                        exact
                        className='process-navlink d-flex flex-column align-items-center py-3'
                        activeClassName="active"
                        isActive={() => currentLocation[1] === "metric"}
                        to="/metric" >
                        <LightTooltip title="Metrics" placement="right">
                            <div>
                                <FontAwesomeIcon icon={faSignal} style={{color: `${currentLocation[1] === "metric" ? "#ffffff" : "#b0b0b0"}`, fontSize:"22px"}}/>
                            </div>
                        </LightTooltip>
                    </NavLink>
                    <NavLink
                        exact
                        className='process-navlink d-flex flex-column align-items-center py-3'
                        activeClassName="active"
                        isActive={() => currentLocation[1] === "learning"} 
                        to="/learning?selected=focus-of-learning" >
                        <LightTooltip title="Learning" placement="right">
                            <div>
                                <FontAwesomeIcon icon={faListAlt} style={{color: `${currentLocation[1] === "learning" ? "#ffffff" : "#b0b0b0"}`, fontSize:"22px"}} />
                            </div>
                        </LightTooltip>
                    </NavLink>
                </div>
                { 
                    props.addIcon ?
                    <>
                        <div className='d-flex flex-column align-items-center mt-2'>
                            <LightTooltip
                                title={props.addIconTooltip}
                                placement="right">
                                <IconButton onClick={props.onAddIconClick}>
                                    <FontAwesomeIcon 
                                        icon={faPlusCircle}
                                        style={{
                                            color:"#e5e5e5",
                                            fontSize:"28px"
                                        }} />
                                </IconButton>
                            </LightTooltip>
                        </div>
                    </> : null 
                }
                { props.buildingIcon ?
                    <NavLink
                        exact 
                        className='process-navlink d-flex flex-column align-items-center py-3'
                        activeClassName="active"
                        isActive={() => currentLocation[1] === "company-view"} 
                        to="/company-view?lvl=0" >
                        <LightTooltip title="Company View" placement="right">
                            <div>
                                <FontAwesomeIcon icon={faBuilding} style={{color: `${currentLocation[1] === "company-view" ? "#ffffff" : "#b0b0b0"}`, fontSize:"22px"}}  />
                            </div>
                        </LightTooltip>
                    </NavLink> : null }
                {props.videoIcon ? 
                    <NavLink
                        exact
                        className='process-navlink d-flex flex-column align-items-center py-3'
                        to="/video-conference?selected=your-meeting" >
                        <LightTooltip title="Video Conference" placement="right">
                            <div>
                                <FontAwesomeIcon icon={faVideo} style={{color:"#b0b0b0", fontSize:"22px"}} />
                            </div>
                        </LightTooltip>
                    </NavLink> : null
                }
                {
                    props.awardsIcon ? 
                    <div className='d-flex flex-column align-items-center py-3' onClick={props.onAwardsIconClick} >
                        <LightTooltip title="Awards" placement="right">
                            <div>
                                <FontAwesomeIcon icon={faTrophy} style={{color:"#b0b0b0", fontSize:"22px"}} />
                            </div>
                        </LightTooltip>
                    </div> : null
                }
            </div>
            {/* bottom div */}
            <div style={{display:"flex", justifyContent:"flex-end"}}></div>
        </div>
    )
}

export default LeftVerticalMenuBar