import React from 'react'
import Dialog from '@mui/material/Dialog'

const FullScreenPopup = (props) => {

    return (
        <Dialog
            fullScreen
            open={props.popupState}
            onClose={props.handleClose}
        >
            {props.children}
        </Dialog>
    )
}

export default FullScreenPopup