import { 
    REQUEST_MODIFY_OBJECTIVE,
    MODIFY_OBJECTIVE_SUCCESS, 
    MODIFY_OBJECTIVE_FAILURE,
    CLEAR_MODIFY_OBJECTIVE_STATES,
    ADD_OBJECTIVE_REFERENCE_FILE,
    ADD_OBJECTIVE_ICON_FILE,
    ADD_OBJECTIVE_WALLPAPER_FILE,
    REMOVE_OBJECTIVE_REFERENCE_FILE,
    REMOVE_OBJECTIVE_WALLPAPER_FILE,
    REMOVE_OBJECTIVE_ICON_FILE,
    SAVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR,
    REMOVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR,
    SAVE_MANY_MODIFY_OBJECTIVE_VALIDATION_ERROR
} from './ModifyObjective.types'
import { removeItemFromArray, updateArray, updateArrayItem, saveOne, saveMany, removeOne } from './ModifyObjective.utils'

const initialState = {
    loading : false,
    response : {},
    referenceFiles : [],
    wallpapers : [],
    icons : [],
    validationError : [
        // {
        //     id: '',
        //     hasErrors: true/false 
        // }
    ],
    error : null
}

const modifyActionReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_MODIFY_OBJECTIVE :
            return {
                ...state,
                loading : true,
                error : null
            }

        case MODIFY_OBJECTIVE_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case MODIFY_OBJECTIVE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case ADD_OBJECTIVE_REFERENCE_FILE:
            return {
                ...state,
                referenceFiles : updateArrayItem(state.referenceFiles, action.objectiveId, action.referenceFiles)
            }

        case ADD_OBJECTIVE_WALLPAPER_FILE:
            return {
                ...state,
                wallpapers : updateArray(state.wallpapers, action.objectiveId, action.newFile, action.data)
            }

        case ADD_OBJECTIVE_ICON_FILE:
            return {
                ...state,
                icons : updateArray(state.icons, action.objectiveId, action.newFile, action.data)
            }

        case REMOVE_OBJECTIVE_REFERENCE_FILE:
            return {
                ...state,
                referenceFiles : removeItemFromArray(state.referenceFiles, action.objectiveId)
            }

        case REMOVE_OBJECTIVE_WALLPAPER_FILE:
            return {
                ...state,
                wallpapers : removeItemFromArray(state.wallpapers, action.objectiveId)
            }

        case REMOVE_OBJECTIVE_ICON_FILE:
            return {
                ...state,
                icons : removeItemFromArray(state.icons, action.objectiveId)
            }

        case SAVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR:
            return {
                ...state,
                validationError : saveOne(state.validationError, action.payload)
            }
        
        case REMOVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR:
            return {
                ...state,
                validationError : removeOne(state.validationError, action.payload)
            }

        case SAVE_MANY_MODIFY_OBJECTIVE_VALIDATION_ERROR:
            return {
                ...state,
                validationError: saveMany(state.validationError, action.payload)
            }

        case CLEAR_MODIFY_OBJECTIVE_STATES:
            return initialState

        default :
            return state
    }
}

export default modifyActionReducer