import React from 'react'
import { makeStyles } from '@mui/styles'
import Card  from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import Avatar from '@mui/material/Avatar'
import config from '../../config'


import CustomizeButton from '../../common/customize-button/CustomizeButton'
import './video-conference-card.css'

const {CLIENT_URL} = config()

const useStyles = makeStyles({
    root : {
        maxWidth: 500,
    },
    twoLineHidden : {
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        wordBreak: "break-word",
        overflow: "hidden",
        color: "#5c5c5c",
        fontFamily : "'Lato',sans-serif !important"
    },
    cardHeading : {
        lineHeight : "0.85 !important",
        fontFamily:"'Lato',sans-serif !important",
        fontSize : "20px !important",
        paddingBottom: "16px",
        fontWeight : 600,
    },
    divider :{
        marginTop : 10,
        marginBottom : 10,
    },
    meetingTime :{
        fontSize : "20px !important",
        fontFamily :"'Lato',sans-serif !important"
    },
    contentPadding : {
        paddingTop : 16,
        paddingBottom : 16,
        paddingLeft : 16,
        paddingBottom :0,
    },
    actionName : {
        fontSize : "18px !important",
        fontFamily : "'Lato',sans-serif !important"
    },
    actionbutton:{
        color : "#14213d !important",
        fontFamily : "'Lato',sans-serif !important"
    },
    member:{
        fontSize : "14px !important",
        fontFamily : "'Lato',sans-serif !important",
        cursor: "pointer"
    }
})

const VideoConferenceCard = (props) => {

    const classes = useStyles()

    return (
        <Card className={classes.root} >
            <CardContent 
                sx={{
                    padding: "0px",
                    "&:last-child": {
                        paddingBottom:"10px"
                    }
                }}
            >
                <div 
                    style={{
                        paddingTop:"16px",
                        paddingLeft:"16px",
                        paddingRight:"16px"
                    }}
                >
                    <Typography 
                        variant="h6" 
                        className={classes.cardHeading}
                    >
                        {props.meetingDate} - {props.meetingTime}
                    </Typography>
                    {
                        props.subject?.map((action,index) => (
                            !isEmpty(action.action) ?
                                <>
                                    <Typography
                                        variant="h6"
                                        className={classes.actionName} 
                                    >
                                        { 
                                            action.action_of === "courses" ?
                                                action.action?.courses?.[0].name 
                                                : action.action_of === 'graphs' ? 
                                                    action.action?.metric?.name
                                                    : action.action?.name
                                        }
                                    </Typography>
                                    <Typography 
                                        variant="body2" 
                                        className={classes.twoLineHidden} 
                                        gutterBottom 
                                        component="p"
                                    >
                                        { 
                                            action.action_of === 'courses' ?
                                                action.action?.courses?.[0].description 
                                                : action.action_of === "graphs" ? 
                                                    action.action?.metric_description 
                                                    : action.action?.description
                                        }
                                    </Typography>
                                    <Divider 
                                        variant="middle" 
                                        sx={{ 
                                            marginTop:"14px",
                                            marginBottom:"14px", 
                                            marginLeft:"10%",
                                            marginRight:"10%",
                                            display: props.subject.length > 1 ? index === 1 ? "none" : "block" : "none" 
                                        }}    
                                    />
                                </>  
                                : <div className='no-action'>
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                        <Typography 
                                            sx={{
                                                color:"var(--primary-text-color-v2)",
                                                fontSize:"16px",
                                                fontFamily:"'Lato',sans-serif"
                                            }} 
                                        >
                                            No action available
                                        </Typography>
                                    </div>
                                    <hr className='vc-hl-line'/>
                                </div>
                        ))
                    }
                </div>
                <hr style={{marginBottom:"10px"}} />
                <div 
                    className='d-flex align-items-center' 
                    style={{
                        paddingLeft:"16px",
                        paddingRight:"16px"
                    }}
                >
                    <Typography 
                        variant="subtitle2" 
                        color="textSecondary" 
                        className={classes.member} 
                        onClick={props.handleClick} 
                    >
                        {props.member}
                    </Typography>
                    {
                        props.FromMeetingMember ?
                            <div>
                                <p className='mb-0 primary-text-color lato-fs13-normal-lh18 pb-1'>Invited by</p>
                                <div className='d-flex align-items-center overflow-hidden me-2'>
                                    <Avatar 
                                        sx={{
                                            height: '30px',
                                            width: '30px'
                                        }}
                                        src={props.memberProfile} 
                                    />
                                    <div className='ms-2 overflow-hidden'>
                                        <p className='mb-0 one-line-wrap primary-text-color lato-fs15-normal-lh20'>{props.memberName}</p>
                                        <p className='mb-0 one-line-wrap primary-text-color lato-fs13-normal-lh18'>{props.memberEmail}</p>
                                    </div>
                                </div>
                            </div> 
                            : null
                    }
                    {
                        Boolean(props.enableButton) ?
                            <div className='ms-auto'>
                                <CustomizeButton 
                                    variant="small-primary-button" 
                                    target="_blank" 
                                    label={props.buttonLabel} 
                                    href={`${CLIENT_URL}/video-conference/${props.conferenceId}`}
                                />
                            </div>
                            : null
                    }
                </div>
                {
                    !isEmpty(props.meetingMember) ?
                        <Menu
                            id={props.memberPopUpId}
                            anchorEl={props.anchorEl}
                            keepMounted
                            open={Boolean(props.anchorEl)}
                            onClose={props.handleClose}
                        >
                            {
                                props.meetingMember.map((member,index) => (
                                    <MenuItem key={index}>
                                        <div className='d-flex overflow-hidden'>
                                            <Avatar src={member.user?.profile_pic} alt={`${member.user?.person_name?.first_name} profile img`} />
                                            <div className='ms-2 overflow-hidden'>
                                                <p className='mb-0 lato-fs16-normal-lh20 primary-text-color one-line-wrap'>{member.user?.person_name?.first_name} {member.user?.person_name?.middle_name} {member.user?.person_name?.last_name}</p>
                                                <p className='mb-0 lato-fs16-normal-lh20 primary-text-color one-line-wrap'>{member.user?.email?.id}</p>
                                            </div>
                                        </div>
                                    </MenuItem>
                                ))
                            }
                        </Menu>
                        : null
                }
            </CardContent>
        </Card>
    )
}

export default VideoConferenceCard