import { REQUEST_INVITES, 
         INVITES_SUCCESS,
         INVITES_FAILURE, 
         CLEAR_INVITES_STATE } from './Invites.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const invitesReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_INVITES :
            return {
                ...state,
                loading : true,
                error :null
            }

        case INVITES_SUCCESS : 
            return{
                ...state,
                loading : false,
                response : action.payload
            }
        
        case INVITES_FAILURE : 
            return{
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_INVITES_STATE :
            return {
                ...state,
                loading: false,
                response: {},
                error : null
            }

        default : 
            return state
    }
}

export default invitesReducer