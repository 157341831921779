import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'
import { isEmpty, size } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Avatar from '@mui/material/Avatar'

import PopUp from '../../common/popup/PopUp'
import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import { teamList, removeTeamMember } from '../../services/teamMember.service'
import { clearTeamMemberState } from '../../redux/team-member/teamMember.action'
import TeamMemberCard from '../team-member-card/TeamMemberCard'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const RemoveTeamMember = (props) => {

    const { teamMembersState, requestTeamMember, clearTeamMembersState, memberDetailState, ownProps } = props

    const params = useParams()
    const history = useHistory()

    const teamMember = teamMembersState.response.team_members
    const memberDetail = memberDetailState.response

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ reviewers, setReviewers ] = useState([])
    const [ selected, setSelected ] = useState([])

    function onCheckStateChange(e){
        const isChecked = e.target.checked
        setReviewers(ps => {
            if(isChecked){
                return !isEmpty(ps) ? [...ps, { id : e.target.value, team : []}] : [{ id : e.target.value, team : []}]
            } else {
                return ps.filter(obj => obj.id !== e.target.value)
            }
        })
    }


    function formattedTeamMemberOptions(){
        if(!isEmpty(teamMember)){
            return teamMember.map((member) => {
                let obj = {
                    label : member.email.id,
                    value : member._id
                }
                if(selected.includes(member._id)){
                    obj.disabled = true
                }
                return obj
            })
        }
    }

    function handleInputChange(e, values, id){
        setReviewers(ps => {
            let psCopy = JSON.parse(JSON.stringify(ps))
            let filteredList = psCopy.filter(reviewer => reviewer.id !== id)
            const obj = {
                id : id,
                team : values
            }
            return !isEmpty(filteredList) ? [...filteredList, obj] : [obj]
        })
    }

    useEffect(() => {
        let selectedMembers = []
        for(const reviewer of reviewers){
            const team = reviewer.team.map(member => member.value)
            selectedMembers = selectedMembers.concat(team)
        }
        setSelected(ps => {
            return selectedMembers
        })
    }, [reviewers])

    useEffect(() => {
        requestTeamMember(params.memberId)
        selectAllReviewer()
        return () => {
            clearTeamMembersState()
        }
    },[])

    useEffect(() => {
        if(!teamMembersState.loading && !isEmpty(teamMembersState.response)){
            // console.log("response",teamMembersState.response)
            setPageLoading(false)
            
        }
        if(!teamMembersState.loading && !isEmpty(teamMembersState.error)){
            console.log(teamMembersState.error)
        }
    },[teamMembersState])

    function selectAllReviewer(){
        const reviewerList = []
        for(const assignee of memberDetail?.user_subscriber_mapping?.[0]?.assignees){
            reviewerList.push({id: assignee._id, team: []})
        }
        setReviewers(reviewerList)
    }

    function isChecked(id){
        let checked = false
        const doc = reviewers.find(reviewer => reviewer.id === id)
        if(!isEmpty(doc)){
            checked = true
        }
        return checked
    }

    function getReviewerTeam(id){
        const doc = reviewers.find(reviewer => reviewer.id === id)
        return doc.teams
    }

    function onRemoveTeamMemberClick(){
        const reviewersCopy = JSON.parse(JSON.stringify(reviewers))

        for(let reviewer of reviewersCopy){
            reviewer.team = reviewer.team.map(member => member.value)
        }

        removeTeamMember({removing_user: params.memberId, remove_from: reviewersCopy})
            .then(res => {
                toast.success("Successfully removed" , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.goBack()
                props.handleClose()
            }).catch(err => {
                console.log(err)
                if(err && err?.response && err?.response?.data){
                    toast.error(err.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }

    return (
        <PopUp 
            open={ownProps.open}
            handleClose={ownProps.handleClose}
        >
            { 
                !pageLoading ?
                    <div className='min-width-300px p-4'>
                        <h4 className="lato-fs22-normal-lh32 font-bold text-center mb-3">Remove this member</h4>
                        {
                            !isEmpty(memberDetail?.user_subscriber_mapping?.[0]?.assignees) ?
                                <div className='mb-3'>
                                    <label className="lato-fs14-normal-lh20 tertiary-text-color-v2 mb-2">Reviewers</label>
                                    {
                                        memberDetail.user_subscriber_mapping[0].assignees.map((reviewer, index) => (
                                            <div className='d-flex align-items-center pb-3' key={reviewer._id}>
                                                <input type="checkbox" onChange={onCheckStateChange} value={reviewer._id} checked={(reviewers.map(reviewer => reviewer.id)?.includes(reviewer._id)) || false}/>
                                                <div className="ms-3">
                                                    <div className='d-flex align-items-center'>
                                                        <Avatar
                                                            sx={{width: '35px', height: '35px'}} 
                                                            src={reviewer.profile_pic}
                                                        />
                                                        <div className="ms-2">
                                                            { 
                                                                !isEmpty(reviewer.person_name) ?
                                                                    <div>
                                                                        {reviewer?.person_name?.first_name && <span className="lato-fs17-normal-lh20 mb-0">{reviewer.person_name.first_name}</span>}
                                                                        {reviewer?.person_name?.middle_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{reviewer.person_name.middle_name}</span>}
                                                                        {reviewer?.person_name?.last_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{reviewer.person_name.last_name}</span>}
                                                                    </div> 
                                                                    : null
                                                            }
                                                            <div>
                                                                <span className='lato-fs14-normal-lh20 tertiary-text-color-v2 mb-0'>
                                                                    {
                                                                        reviewer?.user_subscriber_mapping[0]?.job_role ?
                                                                            `${reviewer?.user_subscriber_mapping?.[0]?.job_role} (${reviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"})` 
                                                                            : reviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { 
                                                        !isEmpty(reviewer.email) ?
                                                            <div>
                                                                {reviewer?.email?.id && <span className="lato-fs15-normal-lh20 tertiary-text-color-v2 mb-0">{reviewer.email.id}</span>}
                                                            </div> 
                                                            : null
                                                    }
                                                    {
                                                        isChecked(reviewer._id) && size(teamMember) ?  
                                                            <div className="mt-2" style={{minWidth: '250px', maxWidth: '250px'}}>
                                                                <Select
                                                                    placeholder="Select team member..."
                                                                    closeMenuOnSelect={false}
                                                                    isMulti
                                                                    value={getReviewerTeam(reviewer._id)}
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), width: '100%', fontFamily: '"Lato", sans-serif !important'}}
                                                                    options={formattedTeamMemberOptions()}
                                                                    onChange={(values, e) => handleInputChange(e, values, reviewer._id)}
                                                                    isOptionDisabled={(option) => option.disabled}
                                                                />
                                                            </div> 
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                : null
                        }
                        {
                            !isEmpty(teamMember) && (size(teamMember) !== size(selected)) ?
                                <div className='mb-4'>
                                    <label className='lato-fs14-normal-lh20 tertiary-text-color-v2 mb-2'>Team Members Under <strong className='primary-text-color'>{memberDetail.person_name.first_name} {memberDetail.person_name.last_name}</strong> </label> 
                                    <ul className="p-0">
                                        {
                                            teamMember.map(member => (
                                                <li className="list-style-none mb-3" key={member._id}>
                                                    <div className={`d-flex align-items-center ${selected.includes(member._id) ? "opacity-50" : ""}`}>
                                                        <Avatar
                                                            sx={{width: '35px', height: '35px'}} 
                                                            src={member.profile_pic}
                                                        />
                                                        <div className="ms-2">
                                                            { 
                                                                !isEmpty(member.person_name) ?
                                                                    <div>
                                                                        {member?.person_name?.first_name && <span className="lato-fs17-normal-lh20 mb-0">{member.person_name.first_name}</span>}
                                                                        {member?.person_name?.middle_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{member.person_name.middle_name}</span>}
                                                                        {member?.person_name?.last_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{member.person_name.last_name}</span>}
                                                                    </div> 
                                                                    : null
                                                            }
                                                            <div>
                                                                <span className='lato-fs14-normal-lh20 tertiary-text-color-v2 mb-0'>
                                                                    {
                                                                        member?.user_subscriber_mapping[0]?.job_role ?
                                                                            `${member?.user_subscriber_mapping?.[0]?.job_role} (${member?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"})` 
                                                                            : member?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { 
                                                        !isEmpty(member.email) ?
                                                            <div className={selected.includes(member._id) ? "opacity-50" : ""}>
                                                                {member?.email?.id && <span className="lato-fs15-normal-lh20 tertiary-text-color-v2 mb-0">{member.email.id}</span>}
                                                            </div> 
                                                            : null
                                                    }
                                                    <hr className='mx-4' style={{ marginBottom: 0, marginTop: '1rem', backgroundColor: 'rgba(0,0,0,0.1)', opacity: 1}}/>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                : null
                        }
                        <div className="d-flex justify-content-center">
                            <CustomizeButton variant="secondary-color-button" handleClick={ownProps.handleClose} label="Cancel" />
                            <CustomizeButton variant="primary-color-button" className="ms-3" label="Remove Member" type="button" handleClick={onRemoveTeamMemberClick} />
                        </div>
                    </div> 
                    : null
            }
        </PopUp>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        teamMembersState : state.teamMembers,
        memberDetailState : state.memberDetail,
        ownProps: ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestTeamMember : (userId) => dispatch(teamList(false,userId)),
        clearTeamMembersState : () => dispatch(clearTeamMemberState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RemoveTeamMember)