import React from 'react'
import { useHistory } from 'react-router-dom'

import BodyContainer from '../../common/body-container/BodyContainer'
import HeaderContainer from '../../common/header-container/HeaderContainer'

const TermsAndCondition = () => {
    
    const history = useHistory()

    function handleBackArrowEvent(){
        history.goBack()
    }

    return (
        <div className="container d-flex flex-column vh-100 px-0">
           <HeaderContainer heading="Terms and Condition" BackArrowEvent={ handleBackArrowEvent } />
           <BodyContainer className="px-3">
                <p className="mb-0 fs15-lh20">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Debitis, nesciunt reiciendis. Voluptatem rerum numquam similique commodi quaerat sit doloribus eveniet, laboriosam modi aut dignissimos quia quos placeat doloremque sed tempora impedit dolorem ullam? Autem incidunt beatae doloremque omnis cupiditate deserunt alias? Praesentium molestiae minima facere dicta reiciendis repellat delectus accusamus. In, iure molestiae! Et sed doloremque illum soluta mollitia quia suscipit vitae, quam commodi autem praesentium provident pariatur esse quod sit? Fugiat cupiditate nulla iure voluptatibus beatae consectetur culpa deserunt hic! Fuga ipsam quia aperiam obcaecati optio, quo vero assumenda ea corrupti nulla, architecto quos impedit, placeat dolore expedita iure!
                </p>
           </BodyContainer>
        </div>
    )
}

export default TermsAndCondition