import React,{useEffect, useState, useRef} from 'react'
import { useHistory } from 'react-router-dom'
import { fetchGoals } from '../../../services/fetchGoals.service'
import { connect } from 'react-redux'
import { isEmpty, toArray, size } from 'lodash'
import { toast } from 'react-toastify'

import './goals.css'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import ProcessHeaderCard from '../../../components/process-header-card/ProcessHeaderCard'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import { clearSpecificGoalStates } from '../../../redux/specific-goal/specificGoal.action'
import withSupportDoubleClick from '../../../utils/with-support-double-click'
import { clearFetchGoals } from '../../../redux/fetch-goals/FetchGoalsAction'
import AggregateCard from '../../../components/aggregate-card/AggregateCard'
import useMediaQuery from '@mui/material/useMediaQuery';
import ProcessSearch from '../../../components/search/process-search/ProcessSearch'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSearchBar from '../../../components/desktop-layout/process-search-bar/ProcessSearchBar'
import Container from '../../../components/desktop-layout/container/Container'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import SlantContainer from '../../../components/desktop-layout/slant-container/SlantContainer'
import GoalCoverImage from '../../../assets/images/goal.png'
import AddIcon from '@mui/icons-material/Add';
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import CarouselSlider from '../../../common/carousel-slider/CarouselSlider'
import GlassyCard from '../../../common/glassy-card/GlassyCard'
import ProcessContentArea from '../../../components/desktop-layout/process-content-area/ProcessContentArea'
import MainContainer from '../../../components/main-container/MainContainer'

const Goals = (props) => {

    const {fetchGoalsState, fetchGoals, specificGoalState, clearSpecificGoalState, clearGoalState } = props

    const history = useHistory()
    const slideRef = useRef(null)
    
    const [contentLoading, setContentLoading] = useState(true)
    const [ selectedIndex, setSelectedIndex ] = useState([])
    const [ drawerOpen, setDrawerOpen ] = useState(false)
    const [ searchPopup, setSearchPopup] = useState(false)
    const [ menu, setMenu ] = useState(false)

    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    let objectives = fetchGoalsState.response.objectives
    
    const handleAddButtonEvent = () => {
        history.push("/goal/create")
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    const onClickNotification = () => {
        history.push(`/notification-reference?selected=notification&tab=inbox&action=goals`)
    }

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick: (e, payload) => {
            history.push(`/goal/${payload.goal._id}`)
        },
        onSingleClick: (e, index) => {
            let row = index.rowIndex
            let col = index.columnIndex
            setSelectedIndex( ps => {
                let psCopy = JSON.parse(JSON.stringify(ps))
                psCopy[row] = col
                return psCopy
            })
        },
    })

    function searchPopupOpen(){
        setSearchPopup(true)
    }

    function searchPopupClose(){
        setSearchPopup(false)
    }

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    useEffect(() => {
        if(isEmpty(fetchGoalsState.response)){
            fetchGoals()
        } 
        if(!isEmpty(specificGoalState.response)){
            clearSpecificGoalState()
        }

        return () => {
            clearGoalState()
        }
    }, [])


    useEffect(() => {
        if(!fetchGoalsState.loading && !isEmpty(fetchGoalsState.response)){
            // console.log(fetchGoalsState.response)
            setContentLoading(false)
            setSelectedIndex(Array(fetchGoalsState.response.objectives.length).fill(0))
        }

        if(!fetchGoalsState.loading && !isEmpty(fetchGoalsState.error)){
            setContentLoading(false)
            console.log(fetchGoalsState.error)
            if(!isEmpty(fetchGoalsState.error)){
                if(fetchGoalsState.error.data){
                    toast.error(fetchGoalsState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }else{
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
            setContentLoading(false)
        }

        if(fetchGoalsState.goal_action_changes && fetchGoalsState.loading){
            fetchGoals()
        }

    },[fetchGoalsState])

    return (
        <>
            {isDesktop ? 
                <Container>
                    <Navbar
                        enableSearchBar={true}
                        searchAutocomplete={<ProcessSearchBar />} 
                        enableNotification={true}
                        enableMore={true}
                        open={open}
                        menu={menu}
                        logo={true}
                        enableLogout={true}
                        enableProfileButton={true}
                        notificationOnClick={onClickNotification}
                        handlePopoverClose={handleMenuClose}
                        handlePopoverOpen={handleMenuOpen} />
                    <ProcessSectionContainer>
                        <LeftVerticalMenuBar addIcon={true} addIconTooltip="Create Goal" onAddIconClick={handleAddButtonEvent} buildingIcon={false} />
                        <ProcessMainContainer>
                            <SlantContainer punchLine="Tiny drops making a mighty ocean" coverImgSrc={GoalCoverImage} />
                            { 
                                !contentLoading ? 
                                    isEmpty(objectives) ? 
                                    <div className='py-3'>
                                        <p className="m-0 text-center lato-fs20-normal-lh28">
                                            You don't have any goals added
                                        </p>
                                        <div className='d-flex justify-content-center mt-3'>
                                            <CustomizeButton 
                                                variant="primary-start-icon-button" 
                                                startIcon={<AddIcon />} 
                                                type="button" 
                                                handleClick={() => history.push("/goal/create")} 
                                                label="Create Goal" 
                                            />
                                        </div>
                                    </div> : 
                                    <ProcessContentArea>
                                        {
                                            objectives.map((objective, rowIndex) => (
                                                <GlassyCard className={rowIndex === 0 ? "pt-4" : "mt-4 pt-4"}>
                                                    <CarouselSlider
                                                        breadcrumbs={
                                                            <BreadCrumbs 
                                                                icon={faThLarge}
                                                                path={toArray(objective.aggregate_goals[selectedIndex[rowIndex]].objective_id.path)}
                                                                activeId={objective.aggregate_goals[selectedIndex[rowIndex]].objective_id._id}
                                                                truncate={isMobile} />
                                                            }
                                                        size={size(objective.aggregate_goals)}>
                                                            {
                                                                objective.aggregate_goals.map((goal, colIndex) => (
                                                                    <div key={ goal._id }>
                                                                        <div className='mx-3 pb-4'>
                                                                        <AggregateCard 
                                                                            variant="desktop"
                                                                            name={goal.name} icon={goal.icon} 
                                                                            wallpaper={goal.wallpaper} 
                                                                            selected={selectedIndex[rowIndex] === colIndex}
                                                                            onClick={(e) => handleCardClick(e,{rowIndex : rowIndex,columnIndex : colIndex,goal : goal})} />
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                    </CarouselSlider>
                                                </GlassyCard>
                                            ))
                                        }
                                    </ProcessContentArea> 
                                : null
                            }
                        </ProcessMainContainer>
                    </ProcessSectionContainer>
                </Container>
                : <Container>
                    <Navbar
                        variant="mobile"
                        enableSearchIcon={true}
                        onSearchIconClick={searchPopupOpen}
                        enableNotificationsIcon={true}
                        onNotificationsIconClick={onClickNotification}
                        enableLogout={true}
                        enableDrawer={true}
                        logo={true}
                        drawerOpened={drawerOpen}
                        handleDrawerClose={toggleDrawer}
                        onDrawerClick={toggleDrawer}
                    />
                    <MainContainer className="bg-tertiary-color-v2">
                        <ProcessHeaderCard
                            punchLine="Tiny drops making a mighty ocean"
                            coverImgSrc={GoalCoverImage}
                        />
                        <div className='process-aggregate-height overflow-auto'>
                            { 
                                !contentLoading ?
                                (
                                    isEmpty(objectives) ? 
                                        <div className="h-100 d-flex align-items-center justify-content-center flex-column py-3">
                                            <p className="m-0 text-center lato-fs20-normal-lh28">
                                                You don't have any goals added
                                            </p>
                                            <CustomizeButton 
                                                className="mt-3"
                                                variant="primary-start-icon-button" 
                                                startIcon={<AddIcon />} 
                                                type="button" 
                                                handleClick={() => history.push("/goal/create")} 
                                                label="Create Goal"
                                            />
                                        </div> : 
                                        <div className='pt-4'>
                                            {
                                                objectives.map((objective, rowIndex) => (
                                                    <div>
                                                        <BreadCrumbs 
                                                            containerClassName="ml-20 mr-20 mt-2"
                                                            listClassName="bg-white"
                                                            path={toArray(objective.aggregate_goals[selectedIndex[rowIndex]].objective_id.path)} 
                                                            activeId={objective.aggregate_goals[selectedIndex[rowIndex]].objective_id._id}
                                                            icon={faThLarge}
                                                            truncate={isMobile}
                                                        />
                                                        <div className="scroll-container" key={objectives._id}>
                                                            {
                                                                objective.aggregate_goals.map((goal,colIndex) => (  
                                                                    <div className="mb-4 goal-card-container me-3" key={goal._id}>
                                                                        <AggregateCard
                                                                            containerClassName="min-width-250px max-width-250px"
                                                                            name={goal.name} icon={goal.icon} 
                                                                            wallpaper={goal.wallpaper} 
                                                                            selected={selectedIndex[rowIndex] === colIndex}
                                                                            onClick={(e) => handleCardClick(e,{rowIndex : rowIndex,columnIndex : colIndex,goal : goal})} />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                )
                                : null
                            }
                        </div>
                    </MainContainer>
                    <BottomNavigation
                        enableAddIcon={true}
                        onAddIconClick={handleAddButtonEvent}
                    />
                    {
                        searchPopup ? 
                            <ProcessSearch 
                                handleClose={searchPopupClose} 
                                popupState={searchPopup} 
                                selectedAction="goals"
                            />
                            : null
                    }
                </Container>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        fetchGoalsState : state.fetchGoals,
        specificGoalState : state.specificGoal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGoals : () => dispatch(fetchGoals()),
        clearSpecificGoalState : () => dispatch(clearSpecificGoalStates()),
        clearGoalState : () => dispatch(clearFetchGoals())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Goals)