import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useHistory, useLocation } from 'react-router-dom'

import './sign-up.css'
import IndividualSignUp from '../../../components/individual-signup/IndividualSignUp'
import CorporateSignup from '../../../components/corporate-signup/CorporateSignup'
import SelectBoxValueById from '../../../common/select-box-value-id/SelectBoxValueById'
import { requestPlan,
        fetchHighestQualifications,
        fetchSubcriptionTypes, fetchIndustries, fetchCountries } from '../../../services/constants.service'
import Footer from '../../../components/footer/Footer'
import Container from '../../../components/desktop-layout/container/Container'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Just4Logo from '../../../assets/icons/Just_4_logo_blue.svg'
import PublicNavbar from '../../home-page/public-navbar/PublicNavbar'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'

const SignUp = (props) => {

    const [pageLoading, setPageLoading] = useState(true);
    const [ planLoading, setPlanLoading ] = useState({
        openOrg : true,
        closedOrg : true
    })
    const [constantData , setConstantData] = useState({});
    const [ openOrgPlan, setOpenOrgPlan ] = useState({})
    const [ closedOrgPlan, setClosedOrgPlan ] = useState({})
    const [ subscriptionType, setSubscriptionType ] = useState("open_org")
    const [ drawer, setDrawer ] = useState(false)

    const isDesktop = useMediaQuery('(min-width:992px)')
    const history = useHistory()
    const location = useLocation()
    const endPoint = location.pathname.split('/')[1]

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    useEffect(() => {
        if(endPoint === 'sign-up'){
            Promise.all([fetchHighestQualifications(), fetchSubcriptionTypes(), fetchIndustries(), fetchCountries()])
            .then(([highestQualificationsRes, subscriptionRes, industriesRes, countryRes ]) => {
                setConstantData({
                    highestQualifications : highestQualificationsRes.data.highest_qualifications,
                    subscriptionTypes: subscriptionRes.data.subscription_types,
                    industries: industriesRes.data.industries,
                    countries : countryRes.data.countries
                })
                setPageLoading(false) 
            }).catch((err) => {
                console.log(err)
                setPageLoading(false)
                if(err && !isEmpty(err.response) && err?.response?.data){
                    toast.error(err.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
        }
    }, [])

    useEffect(() => {
        if(endPoint === 'sign-up'){
            if(subscriptionType === "open_org" && isEmpty(openOrgPlan)){
                requestPlan(subscriptionType)
                .then((response) => {
                    setOpenOrgPlan(response.data.plans)
                    setPlanLoading( ps => {
                        return {
                            ...ps,
                            openOrg : false
                        }
                    })
                }).catch((err) => {
                    console.log(err)
                    if(err && err?.response && err?.response?.data){
                        toast.error(err.response?.data?.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                })
            }else if(subscriptionType === "closed_org" && isEmpty(closedOrgPlan)){
                requestPlan(subscriptionType)
                .then((response) => {
                    setClosedOrgPlan(response.data.plans)
                    setPlanLoading(ps => {
                        return {
                            ...ps,
                            closedOrg : false
                        }
                    })
                }).catch((err) => {
                    console.log(err)
                    if(err && err?.response && err.response?.data){
                        toast.error(err.response?.data?.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                })
            }
        }
    },[subscriptionType])

    function onSubscriptionTypeChange(event){
        setSubscriptionType(event.target.value)
        // to do update index
        // formik.handleChange(event)
    }

    return (
        <Container>
            <PublicNavbar 
                enableHome={true}
                enableProduct={true}
                enableConsult={true}
                // enableLogin={true}
                enableAboutUs={true}
                variant={isDesktop ? "" : "mobile" }
                enableDrawer={true}
                drawerOpened={drawer}
                logo={true}
                handleDrawerClose={toggleDrawer}
                onDrawerClick={toggleDrawer}
                drawerHome={true}
            />
            <SubMainContainer className="flex-fill pt-4 pb-4 px-4">
                {/* <div className='d-flex justify-content-center align-items-center mb-3'>
                    <img src={Just4Logo} style={{width : "200px"}} alt="just 4 icon" />
                </div> */}
                {
                    endPoint === 'sign-up' ?
                        !pageLoading ?
                            <>
                                <h1 className="lato-fs26-bold-lh32 font-bold mb-4">Subscribe</h1>
                                <div>
                                    <SelectBoxValueById
                                        containerClassName='max-width-385px mb-4'
                                        options={constantData.subscriptionTypes}
                                        label="Subscription type"
                                        name="subscription_type"
                                        value={subscriptionType}
                                        onChange={(e) => onSubscriptionTypeChange(e)} />
                                    { 
                                        subscriptionType === "open_org" && !planLoading.openOrg ?
                                        <IndividualSignUp 
                                            constantData={constantData} 
                                            plans={openOrgPlan} /> 
                                        : null 
                                        }
                                    { 
                                        subscriptionType === "closed_org" && !planLoading.closedOrg ? 
                                        <CorporateSignup 
                                            constantData={constantData} 
                                            subscriptionType={subscriptionType} 
                                            plans={closedOrgPlan} /> 
                                        : null
                                    }
                                </div>
                            </> 
                            : null
                        : <div className='d-flex justify-content-center align-items-center h-100 flex-column'>
                            <p className='mb-3 lato-fs36-normal-lh42 primary-text-color text-center'>Launching soon.</p>
                            <p className='mb-4 lato-fs20-normal-lh28 primary-text-color text-center'>Consult us if interested in subscribing.</p>
                            <CustomizeButton 
                                variant="primary-color-button"
                                label="Consult Us"
                                handleClick={() => history.push('/consult-us')}
                            />
                        </div>
                }
            </SubMainContainer>
            {/* <Footer /> */}
        </Container>
    )
}

export default SignUp