import { REQUEST_SPECIFIC_OBJECTIVE,
         SPECIFIC_OBJECTIVE_SUCCESS, 
         SPECIFIC_OBJECTIVE_FAILURE, 
         CLEAR_SPECIFIC_OBJECTIVE } from './SpecificObjectiveTypes'

export const requestSpecificObjective = () => {
    return {
        type : REQUEST_SPECIFIC_OBJECTIVE
    }
}

export const specificObjectiveSuccess = (response) => {
    return {
        type : SPECIFIC_OBJECTIVE_SUCCESS,
        payload : response
    }
}

export const specificObjectiveFailure = (error) => {
    return {
        type : SPECIFIC_OBJECTIVE_FAILURE,
        payload : error
    }
}

export const clearSpecificObjectiveState = () => {
    return {
        type : CLEAR_SPECIFIC_OBJECTIVE
    }
}