import { REQUEST_DELETE_METRIC, 
         DELETE_METRIC_SUCCESS, 
         DELETE_METRIC_FAILURE, 
         CLEAR_DELETE_METRIC_DATA } from './DeleteMetricTypes'

export const requestDeleteMetric = () => {
    return {
        type : REQUEST_DELETE_METRIC
    }
}

export const deleteMetricSuccess = (resData) => {
    return {
        type : DELETE_METRIC_SUCCESS,
        payload : resData
    }
}

export const deleteMetricFailure = (error) => {
    return {
        type : DELETE_METRIC_FAILURE,
        payload : error
    }
}

export const clearDeleteMetricData = () => {
    return { 
        type : CLEAR_DELETE_METRIC_DATA
    }
}