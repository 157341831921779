import { 
    REQUEST_OBJECTIVE, 
    OBJECTIVE_SUCCESS,
    OBJECTIVE_FAILURE,
    CLEAR_OBJECTIVE_STATE,
    OBJECTIVE_ACTION_CHANGES 
} from './Objective.types'

export const requestObjective = () => {
    return {
        type : REQUEST_OBJECTIVE
    }
}

export const objectiveSuccess = (response) => {
    return {
        type : OBJECTIVE_SUCCESS,
        payload : response
    }
}

export const objectiveFailure = (error) => {
    return {
        type : OBJECTIVE_FAILURE,
        payload : error
    }
}

export const objectiveSelectActionChanges = () => {
    return {
        type : OBJECTIVE_ACTION_CHANGES
    }
}

export const clearObjectiveState = () => {
    return {
        type : CLEAR_OBJECTIVE_STATE
    }
}