import { REQUEST_METRICS_FOR_SELECT,
        METRICS_FOR_SELECT_SUCCESS,
        METRICS_FOR_SELECT_FAILURE,
        CLEAR_METRICS_FOR_SELECT_STATE } from './Metric.types'

export const requestMetricsForSelect = () => {
    return {
        type : REQUEST_METRICS_FOR_SELECT
    }
}

export const metricsForSelectSuccess = (response) => {
    return {
        type : METRICS_FOR_SELECT_SUCCESS,
        payload : response
    }
}

export const metricsForSelectFailure = (error) => {
    return {
        type : METRICS_FOR_SELECT_FAILURE,
        payload : error
    }
}

export const clearMetricsForSelectState = () => {
    return {
        type : CLEAR_METRICS_FOR_SELECT_STATE
    }
}