import React from 'react';
import './slant-container.css'

const SlantContainer = (props) => {
    
    return (
        <div className="slant d-flex">
            <div style={{flex:"1", paddingLeft: "7%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h1 className='lato-fs-42-normal-lh54'>{props.punchLine}</h1>
            </div>
            <div style={{flex:"1", display: "flex", justifyContent: "flex-end", paddingRight: "12%", paddingTop: "2%"}}>
                <img style={{height: "280px"}} alt="" src={props.coverImgSrc}/>
            </div>
        </div>
    )
};

export default SlantContainer;
