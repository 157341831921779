import { REQUEST_OBJECTIVE, 
        OBJECTIVE_SUCCESS,
        OBJECTIVE_FAILURE,
        CLEAR_OBJECTIVE_STATE,
        OBJECTIVE_ACTION_CHANGES } from './Objective.types'

const initialState = {
    loading : false,
    action_changes : false,
    response : {},
    error : null
}

const objectiveForSelectReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_OBJECTIVE :
            return {
                ...state,
                loading : true,
                action_changes : false,
                error :null
            }

        case OBJECTIVE_SUCCESS : 
            return {
                ...state,
                loading : false,
                action_changes : false,
                response : action.payload
            }

        case OBJECTIVE_FAILURE : 
            return {
                ...state,
                loading : false,
                action_changes : false,
                error : action.payload
            }

        case OBJECTIVE_ACTION_CHANGES : 
            return {
                ...state,
                loading : true,
                action_changes : true,
            }

        case CLEAR_OBJECTIVE_STATE :
            return {
                ...state,
                loading : false,
                action_changes : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default objectiveForSelectReducer