import { REQUEST_SPECIFIC_LEARNING_AREA,
         SPECIFIC_LEARNING_AREA_SUCCESS,  
         SPECIFIC_LEARNING_AREA_FAILURE,
         CLEAR_SPECIFIC_LEARNING_AREA } from './specificLearningArea.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const specificLearningAreaReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_SPECIFIC_LEARNING_AREA : 
            return {
                ...state,
                loading: true,
                error: null
            }
        
        case SPECIFIC_LEARNING_AREA_SUCCESS : 
            return {
                ...state,
                loading: false,
                response : action.payload
            }

        case SPECIFIC_LEARNING_AREA_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_SPECIFIC_LEARNING_AREA:
            return {
                ...state,
                loading : false,
                response: {},
                error : null
            }
        
        default : 
            return state
    }
}

export default specificLearningAreaReducer