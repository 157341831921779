import React from 'react'
import { useHistory } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { styled, alpha } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import Grid from '@mui/material/Grid'
import LightTooltip from '../../../common/light-tooltip/LightTooltip'
import Drawer from '@mui/material/Drawer'
import DrawerItems from '../../drawer-items/DrawerItems'
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import './navbar.css'

import MorePopup from '../more-popup/MorePopup'
import { connect } from 'react-redux'
import { fetchProfileData } from '../../../services/ProfileData.service'
import { removeUserCredentials, global } from '../../../utils/utils'
import Button from '@mui/material/Button'
import ProfileButton from '../../profile-button/ProfileButton'
import Just4WhiteIcon from '../../../assets/icons/Just_4_icon_white.svg'
import Just4Logo from '../../../assets/icons/Just_4_logo_white.svg'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    color:"white",
    alignItems: 'center',
    justifyContent: 'center',
}))

const Navbar = (props) => {

    const { userLogout } = props

    const history = useHistory()

    function ElevationScroll(props) {
        const { children, window } = props;
        // Note that you normally won't need to set the window ref as useScrollTrigger
        // will default to window.
        // This is only being set here because the demo is in an iframe.
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
            target: window ? window() : undefined,
        })
    
        return React.cloneElement(children, {
            elevation: trigger ? 4 : 0,
        })
    }

    const handleLogout = () => {
        userLogout()
        removeUserCredentials()
        history.push("/sign-in")
    }

    switch(props.variant){
        case "mobile":
            return (
                <>
                    <ElevationScroll {...props}>
                        <AppBar sx={{backgroundColor : "#14213d"}}>
                        <Toolbar>
                            <Grid container >
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {
                                        props.enableDrawer ? 
                                            <IconButton
                                                color="inherit"
                                                style={{color : "#fff"}}
                                                aria-label="open drawer"
                                                edge="start"
                                                onClick={props.onDrawerClick}
                                                sx={{ marginRight:"5px" }}
                                            >
                                                <MenuIcon />
                                            </IconButton> 
                                            : null

                                    }
                                    <div className='d-flex align-items-center'>
                                        {
                                            props.logo ? 
                                                <img src={Just4Logo} style={{width:"98px"}} alt="just 4 logo"/> 
                                                : <img src={Just4WhiteIcon} style={{width:"35px",height:"35px"}} alt="just 4 icon"/>
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={4} style={{display: 'flex', alignItems: "center", justifyContent: "flex-end"}}>
                                    {
                                        props.enableSearchIcon ?
                                            <IconButton onClick={props.onSearchIconClick} >
                                                <SearchIcon sx={{color:"white"}} />
                                            </IconButton>
                                            : null
                                    }
                                    {
                                        props.enableNotificationsIcon ?
                                            <IconButton onClick={props.onNotificationsIconClick}>
                                                <NotificationsIcon sx={{color: "white"}} />
                                            </IconButton>
                                            : null
                                    }
                                    {
                                        props.enableLogout ?
                                            <IconButton onClick={handleLogout} >
                                                <LogoutIcon style={{color: "white"}} />
                                            </IconButton>
                                            : null
                                    }
                                    {
                                        props.enableSignIn ?
                                            <Button 

                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none"}}
                                                variant="text"
                                                onClick={() => history.push("/sign-in")}>
                                                Sign In
                                            </Button> :
                                            null
                                    }
                                    {
                                        props.enableSignUp ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none"}}
                                                variant="text"
                                                onClick={() => history.push("/sign-up")}>
                                                Sign Up
                                            </Button> :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </Toolbar>
                        </AppBar>
                    </ElevationScroll>
                    <Toolbar />
                    {
                        props.enableDrawer ?
                            <Drawer
                                variant="temporary"
                                anchor="left"
                                open={props.drawerOpened}
                                onClose={props.handleDrawerClose}
                                ModalProps={{
                                    keepMounted: true
                                }}
                                sx={{
                                    display: { xs: 'block' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                                }}
                            >
                                <DrawerItems/>
                            </Drawer>
                            : null
                    }
                </>
            )
        default:
            return (
                <>
                    <ElevationScroll {...props}>
                        <AppBar sx={{backgroundColor : "#14213d", paddingRight: "5%", paddingLeft: "5%"}}>
                        <Toolbar>
                            <Grid container >
                                <Grid item xs={8} style={{display: 'flex', alignItems: "center"}}>
                                    {/* <Typography variant="h6" component="div" sx={{fontWeight: 400, fontSize:24, fontFamily:"'Lato', sans-serif" }}>
                                        {global.app_name.toUpperCase()}
                                    </Typography> */}
                                    <div className='d-flex align-items-center'>
                                        {
                                            props.logo ? 
                                                <img src={Just4Logo} style={{width:"115px"}} alt="just 4 icon"/> 
                                                : <img src={Just4WhiteIcon} style={{width:"40px",height:"40px"}} alt="just 4 icon"/>
                                        }
                                    </div>
                                    {
                                        props.enableSearchBar ?
                                            <Search>
                                                <SearchIconWrapper>
                                                    <SearchIcon />
                                                </SearchIconWrapper>
                                                {props.searchAutocomplete}
                                            </Search> : null
                                    }
                                </Grid>
                                <Grid item xs={4} style={{display: 'flex', alignItems: "center", justifyContent: "flex-end"}}>
                                    {
                                        props.enableProfileButton ?
                                            <ProfileButton />
                                            : null
                                    }
                                    {
                                        props.enableMore ?
                                        <div>
                                            <IconButton sx={{marginLeft: '10px'}} onClick={props.handlePopoverOpen}>
                                                <ExpandCircleDownIcon sx={{color: 'white', fontSize: '28px'}} />
                                            </IconButton>
                                            <Menu
                                                anchorEl={props.menu}
                                                open={props.open}
                                                onClose={props.handlePopoverClose}
                                                onClick={props.handlePopoverClose}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        top : "42px !important",
                                                        right : props.moreRight ? props.moreRight : "188px",
                                                        left : "auto !important",
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                    },
                                                }}
                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                            >
                                                <MorePopup />
                                            </Menu>
                                        </div> : null
                                    }
                                    {
                                        props.enableNotification ? 
                                        <LightTooltip title="Notifications" placement="bottom">
                                            <IconButton sx={{marginLeft: '10px'}} onClick={props.notificationOnClick} >
                                                <NotificationsIcon sx={{color: "white", fontSize: '28px'}} />
                                                {/* <FontAwesomeIcon icon={faBell} style={{color:"white", fontSize: 22}} onClick={props.notificationOnClick} /> */}
                                            </IconButton>
                                        </LightTooltip> : null
                                    }
                                    {
                                        props.enableLogout ?
                                        <LightTooltip title="Log Out" placement="bottom">
                                            <IconButton sx={{marginLeft: '10px'}} onClick={handleLogout} >
                                                <LogoutIcon style={{color: "white", fontSize: '28px'}} />
                                                {/* <FontAwesomeIcon icon={faSignOutAlt} style={{color:"white", fontSize: 22}}  /> */}
                                            </IconButton>
                                        </LightTooltip> :
                                        null
                                    }
                                    {
                                        props.enableSignIn ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none"}}
                                                variant="text"
                                                onClick={() => history.push("/sign-in")}>
                                                Sign In
                                            </Button> :
                                            null
                                    }
                                    {
                                        props.enableSignUp ?
                                            <Button 
                                                sx={{marginLeft: '10px', color: 'white', fontSize: "17px", fontFamily: "'Lato', sans-serif", textTransform: "none"}}
                                                variant="text"
                                                onClick={() => history.push("/sign-up")}>
                                                Sign Up
                                            </Button> :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </Toolbar>
                        </AppBar>
                    </ElevationScroll>
                    <Toolbar />
                </>
            )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfile : () => dispatch(fetchProfileData()),
        userLogout : () => dispatch({
            type :"USER_LOGOUT"
        }),
    }
}

export default connect(null, mapDispatchToProps)(Navbar)