import React,{ useState, useEffect } from 'react'
import { toArray, isEmpty, get } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import Badge from '@mui/material/Badge';
import { faThLarge } from '@fortawesome/free-solid-svg-icons'

import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import wallpaperPlaceholder from '../../../assets/images/wallpaper-placeholder.png'
import './subobjective-review.css'
import pendingIcon from '../../../assets/icons/pending-clock.svg'
import approvedIcon from '../../../assets/icons/on-time.svg'

import withSupportDoubleClick from '../../../utils/with-support-double-click'
import { requestObjectiiveReviewAll } from '../../../services/review.service'
import { saveSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import { COMPONENTS_TYPE } from '../objective-all/objectiveAll.utils'
import BreadCrumbs from '../../breadcrumb/BreadCrumbs'
import { PROCESS_STATUS, REVIEW_TYPES } from '../../../utils/constants'

const SubObjectiveReview = (props) => {

    const { reviewObjectiveAllState, ownProps, requestObjectiveReview, saveObjectives } = props

    const ACTIVE_LEVEL_KEY_MAPPING = {
        0 : 'level_zero_objectives',
        1 : 'level_one_objectives',
        2 : 'level_two_objectives',
        3 : 'level_three_objectives'
    }

    const PATH_KEY_MAPPING = {
        0 : 'level_zero',
        1 : 'level_one',
        2 : 'level_two',
        3 : 'level_three'
    }

    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const queryParams = qs.parse(location.search)

    const [pageLoading, setPageLoading] = useState(true)
    const [selectedIndex, setSelectedIndex] = useState(undefined)
    const [subObjectives, setSubObjectives] = useState([])

    function getSubObjectives(parentObjectiveId, level){
        return new Promise((resolve, reject) => {
            let objectivesByLvl = reviewObjectiveAllState.response[ACTIVE_LEVEL_KEY_MAPPING[level]]
            if(!isEmpty(objectivesByLvl)){
                let subObjs = []
                for(const obj of objectivesByLvl){
                    if(obj.path[PATH_KEY_MAPPING[level - 1]]._id === parentObjectiveId){
                        subObjs.push(obj)
                    }
                }
                resolve(subObjs)
            } else {
                resolve([])
            }
        })
    }

    function getSelectedObjectiveObj(index) {
        return subObjectives[index]
    }

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick: (e, index) => {
            saveObjectives({objective : getSelectedObjectiveObj(index)})
            history.push(`/review/${params.userId}/action/${getSelectedObjectiveObj(index)._id}?role=${queryParams.role}&action=objective`)
        },
        onSingleClick: (e, index) => {
            setSelectedIndex(index)
            if(index === selectedIndex){
                setSelectedIndex(undefined)
            }
        },
    })

    useEffect(() => {
        changeSubObjectives(queryParams.parent_id, parseInt(queryParams.lvl))
    }, [])

    function changeSubObjectives(parentId, level){
        if(queryParams.mode === COMPONENTS_TYPE.listSubObjs){
            getSubObjectives(parentId, level).then((res) => {
                setSubObjectives(res)
                setPageLoading(false)
            }).catch(err => {
                setPageLoading(false)
                console.log(err)
            })
        }
    }

    function getNextLevel(){
        if(selectedIndex !== undefined ){
            if(!isEmpty(getSelectedObjectiveObj(selectedIndex).sub_objectives)){
                history.push(`/review/${params.userId}?role=${queryParams.role}&action=vcf&tab=all&mode=${COMPONENTS_TYPE.listSubObjs}&parent_id=${getSelectedObjectiveObj(selectedIndex)._id}&lvl=${getSelectedObjectiveObj(selectedIndex).level + 1}`)
                setPageLoading(true)
                changeSubObjectives(getSelectedObjectiveObj(selectedIndex)._id, getSelectedObjectiveObj(selectedIndex).level + 1)
                setSelectedIndex(undefined)
            } else {
                toast.info('No sub-objectives to view', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    function getPreviousLevel(){
        if(parseInt(queryParams.lvl)-2 >= 0){
            history.push(`/review/${params.userId}?role=${queryParams.role}&action=vcf&tab=all&mode=${COMPONENTS_TYPE.listSubObjs}&parent_id=${subObjectives[0].path[PATH_KEY_MAPPING[parseInt(queryParams.lvl)-2]]._id}&lvl=${parseInt(queryParams.lvl)-1}`)
            setPageLoading(true)
            changeSubObjectives(subObjectives[0].path[PATH_KEY_MAPPING[parseInt(queryParams.lvl)-2]]._id, parseInt(queryParams.lvl)-1)
        } else {
            history.push(`/review/${params.userId}?role=${queryParams.role}&action=vcf&tab=all&mode=${COMPONENTS_TYPE.listObjBylvl}&lvl=0`)
        }
    }

    function actionStatus(status){
        if(queryParams.role == "reviewer"){
            if(status.reviewer_needed == true){
                if(status.reviewer_status == "pending"){
                    return "pending"
                }else if(status.reviewer_status == "approved" || status.reviewer_status == "modified_and_approved"){
                    return "approved"
                }
            }else{
                return "not_needed"
            }
        }else{
            if(status.super_reviewer_needed == true){
                if(status.super_reviewer_status == "pending"){
                    return "pending"
                }else if(status.super_reviewer_status == "approved" || status.super_reviewer_status == "modified_and_approved"){
                    return "approved"
                }
            }else{
                return "not_needed"
            }
        }
    }

    function getPath(){
        if(!isEmpty(subObjectives)){
            if(!isEmpty(subObjectives[0])){
                let pathArray =  toArray(subObjectives[0].path)
                return pathArray.splice(0,pathArray.length-1)
            }
        }
    }

    function getValue(key, objective){
        if((objective.status === PROCESS_STATUS.needsApproval) && (objective.review_status.review_type === REVIEW_TYPES.editRequest)){
            return objective.edits[key]
        } else {
            return objective[key]
        }
    }

    return (
        !pageLoading ?
            !isEmpty(subObjectives) && queryParams.mode === COMPONENTS_TYPE.listSubObjs ?
            <div>
                <div className="ml-10">
                    <BreadCrumbs icon={faThLarge} path={getPath()} activeId={queryParams.parent_id} />
                </div>
                <div className="scroll-container">
                    { 
                        subObjectives.map((subObjective, index) => (
                            <>
                                <div key={
                                        subObjective._id} 
                                        className={(selectedIndex && selectedIndex) === index ? 
                                        "sub-obj-container mt-2 me-2 position-relative selected" : 
                                        "sub-obj-container mt-2 me-2 position-relative"
                                    } 
                                    onClick={(e) => handleCardClick(e,index)}>
                                    <img className="card-img-top sub-obj-wallpaper pt-cursor"
                                        src={ getValue('wallpaper',subObjective) ? getValue('wallpaper',subObjective) : wallpaperPlaceholder }
                                        alt="card placeholder"/>
                                        { subObjective.status == "needs_approval" ?
                                            <div className="sub-obj-action-container">
                                            {actionStatus(subObjective.review_status) == "pending" ? 
                                                <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                                                    <img className="action-img-bardge" src={pendingIcon}></img>
                                                </Badge> : null
                                            }
                                            {actionStatus(subObjective.review_status) == "not_needed" ? 
                                                <img className="action-img" src={pendingIcon} /> :null
                                            }
                                            {actionStatus(subObjective.review_status) == "approved" ? 
                                                <img className="action-img" src={approvedIcon} /> :null
                                            }
                                        </div> : null}
                                        <div className="card sub-obj-detail shadow pt-cursor">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <img className="sub-icon me-2" 
                                                    alt="sub-objective-icon"
                                                    src={getValue('icon',subObjective) ? getValue('icon',subObjective) : iconPlaceholder} />
                                                <h6 className="fs18-lh20 ms-1 one-line-wrap pb-1 mb-0">{getValue('name',subObjective)}</h6>
                                            </div>
                                        </div>
                                </div>
                            </>
                        ))
                    }
                </div>
                <div className="drill-button-container">
                    <Button className="drill-button">
                        <FontAwesomeIcon  icon={faArrowUp} onClick={getPreviousLevel}/>
                    </Button>
                    {
                        (selectedIndex!==undefined && !isEmpty(subObjectives[selectedIndex].sub_objectives)) ?
                            <Button className="ms-2 drill-button">
                                <FontAwesomeIcon icon={faArrowDown} onClick={getNextLevel}/>
                            </Button>
                            : null
                    }
                </div>
            </div> 
            : null
        : null
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        reviewObjectiveAllState : state.objectiveReviewAll,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjectiveReview : (userId) => dispatch(requestObjectiiveReviewAll(userId)),
        saveObjectives : (response) => dispatch(saveSpecificObjectiveForReview(response))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SubObjectiveReview)
