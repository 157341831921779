import React from 'react'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'

import LevelIndicator from '../../level-indicator/LevelIndicator'
import wallPaperPlaceholder from '../../../assets/images/wallpaper-placeholder.png'
import IconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import './process-wallpaper-icon.css'

const ProcessWallpaperIcon = (props) => {
    switch(props.variant){
        case "mobile":
            return (
                <>
                    <div
                        className={`obj-wallpaper-container mt-3 overflow-hidden d-flex justify-content-center align-items-center ${props.mainContainerClassName}`}
                        style={{maxWidth:"560px", marginLeft:"auto",marginRight:"auto"}}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                position: 'absolute',
                                ...(props.wallpaper && {
                                    backgroundImage: `url(${props.wallpaper})`,
                                    filter: 'blur(8px)',
                                    '-webkit-filter': 'blur(8px)',
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    zIndex: 0
                                })
                            }}
                        />
                        <img 
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                zIndex: '1',
                                ...(props.wallpaper ? {
                                    width: "auto", 
                                    height: "auto"
                                } : {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: "cover",
                                    objectPosition: 'center'
                                })
                            }}
                            src={props.wallpaper ? props.wallpaper : wallPaperPlaceholder} 
                            alt=""
                        />
                    </div>
                    <div 
                        style={{
                            zIndex: '4',
                            width: "75px",
                            height: "75px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "-40px",
                            position: "relative"
                        }}
                    >
                        <img className='process-icon-detail' style={{backgroundColor: 'white'}} src={props.icon ? props.icon : IconPlaceholder} alt="img" />
                    </div>
                </>
            )
        case 'metric':
            return(
                <div
                    className="obj-wallpaper-container overflow-hidden ms-auto me-auto"
                    style={{ maxWidth:"560px", height: "250px"}}
                    {...props.containerProps}
                >
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            )
        default:
            return (
                <div style={{maxWidth: "540px"}} className="mx-auto">
                    <div 
                        className={`obj-wallpaper-container overflow-hidden d-flex justify-content-center align-items-center ${props.mainContainerClassName}`}
                    >
                        <img
                            style={{
                                zIndex: '1',
                                width: '100%',
                                height: 'auto',
                                objectFit: "cover",
                                objectPosition: 'center'
                            }}
                            src={props.wallpaper ? props.wallpaper : wallPaperPlaceholder} 
                            alt=""
                        />
                    </div>
                    <div 
                        style={{
                            zIndex: '4',
                            width: "80px",
                            height: "80px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "-50px",
                            position: "relative"
                        }}
                    >
                        <img className='process-icon-detail' style={{backgroundColor: 'white'}} src={props.icon ? props.icon : IconPlaceholder} alt="img" />
                    </div>
                </div>
            )
    }
}

export default ProcessWallpaperIcon