import React from 'react'
import './objective-path-card.css'

const ObjectivePathCard = (props) => {
    return (
        <div className="obj-path-card">
            <p className="d-flex justify-content-center align-items-center m-0 fs16-lh20">{props.name}</p>
        </div>
    )
}

export default ObjectivePathCard