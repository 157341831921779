import { requestCreateObjective, 
        createObjectiveSuccess, 
        createObjectiveFailure, 
        requestEditObjective,
        editObjectiveSuccess,
        editObjectiveFailure } from '../redux/objectives/ObjectivesAction'
import { requestCompanyView, companyViewSuccess, companyViewFailure } from '../redux/company-view/CompanyView.action'
import ProtectedWebClient from '../utils/protected-web-client'
import { requestSpecificObjective, specificObjectiveSuccess, specificObjectiveFailure } from '../redux/specific-objective/SpecificObjectiveAction'
import { requestModifyObjective, modifyObjectiveSuccess, modifyObjectiveFailure } from '../redux/notification/modify-objective/ModifyObjective.action'

export const createObjectives = (objectiveDetails, objectiveId) => {
    return async (dispatch) =>{
        try { 
            dispatch(requestCreateObjective())
            const response = await ProtectedWebClient.post( 
                objectiveId ? `/objective/create?objective=${objectiveId}` : '/objective/create', 
                objectiveDetails)
            dispatch(createObjectiveSuccess(response.data))
        } catch(error) {
            dispatch(createObjectiveFailure(error.response))
        }
    }
}

export const updateObjectives = (objectiveDetails,objectiveId) => {
    return async (dispatch) => {
        try {
            dispatch(requestEditObjective())
            const response = await ProtectedWebClient.put(`/objective/${objectiveId}/edit`, objectiveDetails)
            dispatch(editObjectiveSuccess(response.data))
        } catch (error) {
            dispatch(editObjectiveFailure(error.response))
        }
    }
}

export const companyViewObjectives = (objectiveId) => {
    return async (dispatch) => {
        try{
            dispatch(requestCompanyView())
            const response = await ProtectedWebClient.get(`/objective/company-view`)
            dispatch(companyViewSuccess(response.data))
        }catch(error){
            dispatch(companyViewFailure(error.response))
        }
    }
}

export const deleteObjectivePromise = (objectiveId) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.delete(`/objective/${objectiveId}/delete`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const specificObjectives = (objectiveId, inModifyObjectiveState=false) => {
    return async (dispatch) => {
        try {
            if(inModifyObjectiveState){
                dispatch(requestModifyObjective())
            } else {
                dispatch(requestSpecificObjective())
            }
            const response = await ProtectedWebClient.get(`/objective/${objectiveId}/read?sub_objectives=true`)
            if(inModifyObjectiveState){
                dispatch(modifyObjectiveSuccess(response.data))
            } else {
                dispatch(specificObjectiveSuccess(response.data))
            }
        }catch(error){
            if(inModifyObjectiveState){
                dispatch(modifyObjectiveFailure(error))
            } else {
                dispatch(specificObjectiveFailure(error))
            }
        }
    }
}

export const objectiveDateValidation = ( objectiveId, dateType, date ) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.get(`/objective/${objectiveId}/validate-date?type=${dateType}&date=${date}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const processSearch = ( payload ) => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.post(`/search/process?search=${payload}`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}