import React from 'react'
// import './input-box.css'
import TextField from '@mui/material/TextField'
import { styled } from "@mui/material/styles"

const CssTextField = styled(TextField)({
    // input label when focused
    "& label.Mui-focused": {
        color: "#14213d !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
        borderColor: "#14213d !important"
        }
    }
})

const InputBox = (props) => {

    switch(props.variant){
        case "withOnClick":
            return (
                <div className={props.containerClassName}>
                    <CssTextField 
                        type={props.type}
                        variant="outlined"
                        label={props.label}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        min={props.min}
                        required={props.required}
                        onClick={props.onClick}
                        onWheel={props.onWheel}
                        fullWidth
                        InputProps={{
                            style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                        }}
                        InputLabelProps={{
                            style:{fontFamily:"'Lato',sans-serif",fontSize:"15px"}
                        }}
                        readOnly= {props.readOnly ? true : false}
                        autoComplete="off"  
                    />
                    {
                        props.errorMgs ? 
                            <div className="ms-1">
                                <span className="error-message">{props.errorMgs}</span>
                            </div>
                            : null
                    }
                </div>
            )

        default :
            return (
                <div className={props.containerClassName}>
                    <CssTextField 
                        type={props.type}
                        variant="outlined"
                        label={props.label}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={props.value}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        min={props.min}
                        required={props.required}
                        onWheel={props.onWheel}
                        fullWidth
                        InputProps={{
                            style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                        }}
                        InputLabelProps={{
                            style:{fontFamily:"'Lato',sans-serif",fontSize:"15px"}
                        }}
                        readOnly= {props.readOnly ? true : false}
                        autoComplete="off"  
                    /> 
                    {
                        props.errorMgs ? 
                            <div className="ms-1">
                                <span className="error-message">{props.errorMgs}</span>
                            </div>
                            : null
                    }
                </div>
            )
    }
}

export default InputBox