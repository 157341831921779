import React,{useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery'

import './notification.css'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import Sent from '../sent/Sent'
import Inbox from '../inbox/Inbox'
import Reference from '../reference/Reference'
import Draft from '../../../components/draft/Draft'
import SimpleTab from '../../../components/tabs/simple-tab/simple-tab/SimpleTab'
import SimpleTabPanel from '../../../components/tabs/simple-tab/Simple-tab-panel/SimpleTabPanel'
import BgFilledTab from '../../../components/tabs/bg-filled-tab/bg-filled-tab-header/BgFilledTab'
import CustomizeInputBox from '../../../common/customize-input-box/CustomizeInputBox'
import useDebounce from '../../../hooks/useDebounce'
import { searchNotification, searchReference } from '../../../services/notification.service'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import DesktopSearchBarTwo from '../../../components/desktop-layout/desktop-search-bar-two/DesktopSearchBarTwo'
import StandardSelectBoxById from '../../../common/standard-select-box-by-id/StandardSelectBoxById'
import LightTooltip from '../../../common/light-tooltip/LightTooltip'
import MobSearchBox from '../../../components/search/mob-search-box/MobSearchBox'

const Notification = (props) => {

    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [ addNote, setAddNote ] = useState(false)
    const [ search, setSearch ] = useState(false)
    const [ searchedValue, setSearchedValue ] = useState({
        value : "",
        typing : false,
        data : []
    })
    const [ drawer, setDrawer ] = useState(false)

    const [action, setAction] = useState(queryParams.action)
    const [ menu, setMenu ] = useState(false)

    function onClickComposeEvent(){
        history.push('/compose')
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function handleBackArrowEvent(){
        // if(queryParams.action === "objectives"){
        //     history.push("/vcf?mode=list-vcfs-by-lvl&lvl=0")
        // }else if(queryParams.action === "goals"){
        //     history.push('/goal')
        // }else if(queryParams.action === "metrics"){
        //     history.push("/metric")
        // }else{
        //     history.push('/learning?selected=focus-of-learning')
        // }
        history.goBack()
    }

    const process = [
        {
            label : "VCF",
            value : "objectives"
        },{
            label : "Goals",
            value : "goals"
        },{
            label : "Metrics",
            value : "metrics"
        },{
            label : "Learnings",
            value : "learnings"
        }
    ]

    function handleChange(e){
        if(queryParams.selected === "notification"){
            history.push(`/notification-reference?selected=notification&tab=${queryParams.tab}&action=${e.target.value}`)
        } else {
            history.push(`/notification-reference?selected=reference&action=${e.target.value}`)
        }
    }

    function searchOpen(){
        setSearch(true)
    }

    function searchClose(){
        setSearch(false)
        setSearchedValue({value : "", typing : false, data:[]})
    }

    useEffect(() => {
        setAction(queryParams.action)
        if(searchedValue.value){
            setSearchedValue({value : "", typing: false, data :[]})
        }
    }, [queryParams.action])

    useEffect(() => {
        if(search){
            setSearch(false)
            setSearchedValue({value : "", typing: false, data :[]})
        }
    },[queryParams.selected, queryParams.tab])

    function handleTabEvent(event, key){
        history.push(`/notification-reference?selected=notification&tab=${key}&action=${queryParams.action}`)
    }

    function handleMainTabsChange(event,key){
        if(isDesktop){
            if(key === "notification" && !searchedValue.typing){
                history.push(`/notification-reference?selected=${key}&tab=inbox&action=${queryParams.action}`)
            }else if(key === "reference" && !searchedValue.typing){
                history.push(`/notification-reference?selected=${key}&action=${queryParams.action}`)
            }
        }else{
            if(key === "notification"){
                history.push(`/notification-reference?selected=${key}&tab=inbox&action=${queryParams.action}`)
            }else{
                history.push(`/notification-reference?selected=${key}&action=${queryParams.action}`)
            }
        }
    }

    function toggleAddNote(){
        setAddNote(!addNote)
    }

    function handleSearchInputChange(e){
        setSearchedValue(ps => {
            return {
                ...ps,
                value : e.target.value,
                typing : true
            }
        })
        if(queryParams.selected === "notification"){
            searchDebounce(e.target.value, queryParams.action, queryParams.tab)
        }else if(queryParams.selected === "reference"){
            referenceSearchDebounce(e.target.value, queryParams.action)
        }
    }

    const searchDebounce = useDebounce((nextString, action, tab) => {
        if(nextString.length > 0){
            searchNotification(action,tab,nextString)
            .then((res) => {
                setSearchedValue(ps => {
                    return {
                        ...ps,
                        data : res.data?.data,
                        typing: false
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        }else{
            setSearchedValue(ps => {
                return {
                    ...ps,
                    value : "",
                    typing : false,
                    data : []
                }
            })
        }
    }, 1000)

    function referenceInputChange(e){
        setSearchedValue(ps => {
            return {
                ...ps,
                value : e.target.value,
                typing : true
            }
        })
        referenceSearchDebounce(e.target.value, queryParams.action)
    }

    const referenceSearchDebounce = useDebounce((nextString,process) => {
        if(nextString.length > 0){
            searchReference(process,nextString)
            .then((res) => {
                setSearchedValue(ps => {
                    return {
                        ...ps,
                        data : res.data?.data,
                        typing : false
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        }else{
            setSearchedValue(ps => {
                return {
                    ...ps,
                    value : "",
                    typing : false,
                    data : []
                }
            })
        }
    }, 1000)

    const open = Boolean(menu);

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget);
    }

    const handleMenuClose = () => {
        setMenu(null);
    }

    function getContent(){
        return (
            <div style={{width: "100%", height: '100%', display: 'flex', flexDirection: 'column'}}>
                <div 
                    style={{
                        width: "100%",
                        zIndex: "111",
                        background:" white",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <SimpleTab
                        value={queryParams.selected ? queryParams.selected : "notification"}
                        onChange={handleMainTabsChange}
                        tabArray={[ 
                            {label:"Notifications", value:"notification"},
                            {label:"References", value:"reference"}
                        ]}
                        variant="desktop"
                        additionalComponent = {
                            isDesktop ? 
                                <div style={{display:"flex", alignItems: 'center', marginLeft:"auto"}}>
                                    <div className='me-2'>
                                        <DesktopSearchBarTwo 
                                            inputBox={{
                                                name: "search",
                                                placeholder: "Search",
                                                onChange: handleSearchInputChange, 
                                                value: searchedValue.value
                                            }}
                                            showCloseIcon={Boolean(searchedValue.value)}
                                            onSearchCloseClick={searchClose}
                                        />
                                    </div>
                                    <StandardSelectBoxById options={process} size="small" value={action} onChange={handleChange} />
                                </div>
                                : null
                        }
                    />
                </div>
                <SimpleTabPanel
                    style={{height: "calc(100vh - 113px)"}}
                    containerClassname="bg-tertiary-color-v2 overflow-auto"
                    value="notification"
                    selectedTab={queryParams.selected}
                >
                    <SubMainContainer className="pt-3 pb-5">
                        <Box 
                            sx={{ 
                                background:"white",
                                borderRadius:"10px",
                                position: "sticky", 
                                top: "1rem",
                                zIndex: "111"
                            }}
                        >
                            <BgFilledTab
                                variant="desktop"
                                value={queryParams.tab ? queryParams.tab : "inbox"} 
                                onChange={handleTabEvent}
                                tabArray={[ 
                                    {label:"Inbox", value:"inbox"},
                                    {label:"Sent", value:"sent"},
                                    {label:"Draft", value:"draft"}
                                ]} 
                            />
                        </Box>
                        <SimpleTabPanel
                            value="inbox" 
                            selectedTab={queryParams.tab} 
                            containerClassname="flex-none"
                            style={{overflow: 'unset'}}
                        >
                            <Inbox 
                                action={queryParams.action} 
                                search={search} 
                                searchedValue={searchedValue}
                            />
                        </SimpleTabPanel>
                        <SimpleTabPanel
                            value="sent"
                            selectedTab={queryParams.tab}
                            containerClassname="flex-none"
                            style={{overflow: 'unset'}}
                        >
                            <Sent 
                                action={queryParams.action} 
                                search={search} 
                                searchedValue={searchedValue} 
                            />
                        </SimpleTabPanel>
                        <SimpleTabPanel 
                            value="draft" 
                            selectedTab={queryParams.tab}
                            containerClassname="flex-none"
                            style={{overflow: 'unset'}}
                        >
                            <Draft 
                                search={search} 
                            />
                        </SimpleTabPanel>
                        <div
                            style={{
                                position: 'fixed', 
                                bottom: 0, 
                                right: 0, 
                                backgroundColor: 'var(--primary-color-v2)', 
                                padding: '1rem 2rem',
                                borderTopLeftRadius: '2rem'
                            }}
                        >
                            <LightTooltip
                                title="Compose"
                                placement="top-start"
                            >
                                <div>
                                    <IconButton 
                                        onClick={onClickComposeEvent} 
                                    >
                                        <FontAwesomeIcon
                                            color='white'
                                            style={{
                                                fontSize: '26px'
                                            }}
                                            icon={faPenToSquare}
                                        />
                                    </IconButton>
                                </div>
                            </LightTooltip>
                        </div>
                    </SubMainContainer>
                </SimpleTabPanel>
                <SimpleTabPanel 
                    value="reference" 
                    selectedTab={queryParams.selected}
                    style={{height: "calc(100vh - 113px)",}}
                    containerClassname="bg-tertiary-color-v2 overflow-auto"
                >
                    <SubMainContainer className="pt-3 pb-5">
                        <Reference
                            action={queryParams.action} 
                            actionState={action} 
                            addRef={addNote} 
                            toggleAddRef={toggleAddNote}
                            searchedValue={searchedValue}
                        />
                    </SubMainContainer>
                </SimpleTabPanel>
            </div>
        )
    }

    return (
        isDesktop ? 
            <Container>
                <Navbar 
                    enableMore={true} 
                    enableLogout={true}
                    enableProfileButton={true}
                    moreRight="70px"
                    menu={menu}
                    open={open}
                    moreIcon={true}
                    handlePopoverClose={handleMenuClose}
                    handlePopoverOpen={handleMenuOpen} />
                <ProcessSectionContainer>
                    <LeftVerticalMenuBar addIcon={queryParams.selected === "reference" ? true : false} 
                        addIconTooltip={queryParams.action === "learnings" ? "Create Course" : "Create Reference"} onAddIconClick={toggleAddNote}/>
                    <ProcessMainContainer className="white-bg">
                        {getContent()}
                    </ProcessMainContainer>
                </ProcessSectionContainer>
            </Container>
            : <Container>
                <Navbar 
                    variant="mobile" 
                    enableLogout={true}
                    enableDrawer={true}
                    drawerOpened={drawer}
                    handleDrawerClose={toggleDrawer}
                    onDrawerClick={toggleDrawer}
                />
                <div className='content-area-height'>
                    <div className="d-flex px-3 py-1 align-items-center justify-content-between" style={{backgroundColor: 'white'}} >
                        <IconButton onClick={handleBackArrowEvent}>
                            <ArrowBackIcon />
                        </IconButton>
                        <div className='d-flex align-items-center'>
                            {
                                !search ?
                                    <IconButton className='me-2' onClick={searchOpen}>
                                        <SearchIcon />
                                    </IconButton>
                                    : null
                            }
                            <StandardSelectBoxById options={process} size="small" value={action} onChange={handleChange} />
                        </div>
                    </div>
                    <Box
                        sx={{  
                            width: "100%",
                            background:" white",
                            paddingLeft: "5%",
                            paddingRight: "5%",
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <SimpleTab
                            value={queryParams.selected ? queryParams.selected : "notification"} 
                            tabVariant="fullWidth"
                            onChange={handleMainTabsChange}
                            tabArray={[
                                {label:"Notifications", value:"notification"},
                                {label:"References", value:"reference"}
                            ]}
                            variant="mobile" />
                    </Box>
                    <SimpleTabPanel
                        value="notification"
                        selectedTab={queryParams.selected} 
                        containerClassname="content-area-height-not-and-ref bg-tertiary-color-v2 overflow-auto"
                    >
                        <>
                            {
                                search ?
                                    <div className='container d-flex justify-content-end'>
                                        <MobSearchBox 
                                            name="search"
                                            placeholder="Search"
                                            onChange={handleSearchInputChange} 
                                            value={searchedValue.value}
                                            autoFocus={true}
                                            onSearchCloseClick={searchClose}
                                            showCloseIcon={true}
                                        />
                                    </div>
                                    : null
                            }
                            <div className='container px-4'>
                                <Box 
                                    sx={{ 
                                        background:"white",
                                        marginTop:"8px",
                                        borderRadius:"10px",
                                        position: "sticky", 
                                        top: "8px",
                                        zIndex: "111"
                                    }}
                                >
                                    <BgFilledTab
                                        value={queryParams.tab ? queryParams.tab : "inbox"}
                                        onChange={handleTabEvent}
                                        tabArray={[
                                            {label:"Inbox", value:"inbox"},
                                            {label:"Sent", value:"sent"},
                                            {label:"Draft", value:"draft"}
                                        ]}
                                    />
                                </Box>
                                <SimpleTabPanel 
                                    value="inbox" 
                                    selectedTab={queryParams.tab} 
                                >
                                    <Inbox 
                                        action={queryParams.action} 
                                        search={search} 
                                        searchedValue={searchedValue}
                                    />
                                </SimpleTabPanel>
                                <SimpleTabPanel
                                    value="sent"
                                    selectedTab={queryParams.tab}
                                >
                                    <Sent 
                                        action={queryParams.action}
                                        search={search}
                                        searchedValue={searchedValue}
                                    />
                                </SimpleTabPanel>
                                <SimpleTabPanel 
                                    value="draft" 
                                    selectedTab={queryParams.tab} 
                                >
                                    <Draft 
                                        search={search}
                                    />
                                </SimpleTabPanel>
                            </div>
                            <Box
                                sx={{
                                    position : 'fixed',
                                    bottom: {
                                        xs: '64px'
                                    },
                                    right: {
                                        xs: '1rem',
                                        md: '2rem'
                                    },
                                }}
                            >
                                <IconButton 
                                    sx={{
                                        backgroundColor: 'var(--primary-color-v2)',
                                        padding: '0.75rem',
                                        '&:hover' : {
                                            backgroundColor: 'var(--primary-color-v2)'
                                        }
                                    }}
                                    onClick={onClickComposeEvent}
                                >
                                    <FontAwesomeIcon 
                                        className="white-color"
                                        style={{
                                            fontSize: "20px"
                                        }}
                                        icon={faPenToSquare}
                                    />
                                </IconButton>
                            </Box>
                        </>
                    </SimpleTabPanel>
                    <SimpleTabPanel 
                        value="reference"
                        selectedTab={queryParams.selected} 
                        containerClassname="content-area-height-not-and-ref bg-tertiary-color-v2 overflow-auto" 
                    >
                        {
                            search ?
                                <div className='container d-flex justify-content-end'>
                                    <MobSearchBox 
                                        name="search"
                                        placeholder="Search"
                                        onChange={referenceInputChange}
                                        value={searchedValue.value}
                                        autoFocus={true}
                                        onSearchCloseClick={searchClose}
                                        showCloseIcon={true}
                                    /> 
                                </div>
                                : null
                        }
                        <div className='container px-4'>
                            <Reference action={queryParams.action} 
                                actionState={action} 
                                addRef={addNote}
                                toggleAddRef={toggleAddNote}
                                searchedValue={searchedValue} />
                        </div>
                    </SimpleTabPanel>
                </div>
                <BottomNavigation 
                    enableAddIcon={Boolean(queryParams.selected === 'reference')} 
                    onAddIconClick={toggleAddNote}
                />
            </Container>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        userLogout : () => dispatch({
            type :"USER_LOGOUT"
        }),
    }
}

export default connect(null, mapDispatchToProps)(Notification)