import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';

const AcceptButton = styled(Button)({
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgba(255,128,106,255)',
    borderColor: 'rgba(255,128,106,255)',
    fontFamily : "'oxygen',sans-serif",
    letterSpacing : 0,
    '&:hover': {
        backgroundColor: 'rgba(255,128,106,255)',
        borderColor: 'rgba(255,128,106,255)',
    }
})

const RejectButton = styled(Button)({
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'rgb(204 204 222)',
    borderColor: 'rgb(204 204 222)',
    fontFamily : "'oxygen',sans-serif",
    letterSpacing : 0,
    '&:hover': {
        backgroundColor: 'rgb(204 204 222)',
        borderColor: 'rgb(204 204 222)',
    }
})

const PrimaryColorButton = styled(Button)({
    fontSize : 16,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : '#14213d',
    // borderColor: "#14213d",
    padding:'6px 12px',
    color:"white",
    '&:hover': {
        backgroundColor: '#14213d',
        borderColor: '#14213d',
    }
})

const SecondaryColorButton = styled(Button)({
    fontSize : 16,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : 'white',
    border:"1px solid white",
    padding:'6px 12px',
    color:"black",
    boxShadow :"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    '&:hover': {
        backgroundColor: 'white',
        borderColor: 'white',
    }
})

const RedColorButton = styled(Button)({
    fontSize : 16,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : 'red',
    border:"1px solid red",
    padding:'6px 12px',
    color:"white",
    boxShadow :"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    '&:hover': {
        backgroundColor: 'red',
        borderColor: 'red',
    }
})

const GreenColorButton = styled(Button)({
    fontSize : 16,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : 'green',
    border:"1px solid green",
    padding:'6px 12px',
    color:"white",
    boxShadow :"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    '&:hover': {
        backgroundColor: 'green',
        borderColor: 'green',
    }
})

const AcentColorButton = styled(Button)({
    fontSize : 12,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : 'rgb(252, 163, 17)',
    border:"1px solid rgb(252, 163, 17)",
    padding:'2px 6px',
    color:"white",
    fontWeight:"600",
    zIndex:"1",
    boxShadow :"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    '&:hover': {
        backgroundColor: 'rgb(252, 163, 17)',
        borderColor: 'rgb(252, 163, 17)',
    }
})

const GreenSmallColorButton = styled(Button)({
    fontSize : 12,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : 'green',
    border:"1px solid green",
    padding:'2px 6px',
    color:"white",
    fontWeight:"600",
    zIndex:"1",
    boxShadow :"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    '&:hover': {
        backgroundColor: 'green',
        borderColor: 'green',
    }
})

const RedSmallColorButton = styled(Button)({
    fontSize : 12,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : 'red',
    border:"1px solid red",
    padding:'2px 6px',
    color:"white",
    fontWeight:"600",
    boxShadow :"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    zIndex:"1",
    '&:hover': {
        backgroundColor: 'red',
        borderColor: 'red',
    }
})

const SmallPrimaryColorButton = styled(Button)({
    fontSize : 12,
    textTransform:"none",
    fontFamily: "'Lato',sans-serif",
    backgroundColor : '#14213d',
    // borderColor: "#14213d",
    padding:'4px 8px',
    color:"white",
    fontWeight:"600",
    '&:hover': {
        backgroundColor: '#14213d',
        borderColor: '#14213d',
        color:"white",
    }
})

const CustomizeButton = (props) => {

    switch(props.variant){
        case "accept-button" : 
            return (
                <AcceptButton variant="contained" className={props.className} type={props.type} onClick={props.handleClick}>{props.label}</AcceptButton>
            )

        case "reject-button" : 
            return (
                <RejectButton variant="contained" className={props.className} type={props.type} onClick={props.handleClick}>{props.label}</RejectButton>
            )

        case "primary-start-icon-button" : 
            return (
                <PrimaryColorButton variant='container' startIcon={props.startIcon} className={props.className} onClick={props.handleClick} type={props.type}>{props.label}</PrimaryColorButton>
            )

        case "end-icon-button" : 
            return (
                <PrimaryColorButton variant='container' className={props.className} endIcon={props.EndIcon} onClick={props.handleClick} type={props.type}>{props.label}</PrimaryColorButton>
            )

        case "primary-color-button" : 
            return (
                <PrimaryColorButton variant='container' className={props.className} onClick={props.handleClick} type={props.type}>{props.label}</PrimaryColorButton>
            )

        case "secondary-color-button" :
            return (
                <SecondaryColorButton variant='container' className={props.className} onClick={props.handleClick} type={props.type} >{props.label}</SecondaryColorButton>
            )

        case "secondary-start-icon-button" :
            return (
                <SecondaryColorButton variant='container' className={props.className} startIcon={props.StartIcon} onClick={props.handleClick} type={props.type}>{props.label}</SecondaryColorButton>
            )

        case "red-color-button" :
            return (
                <RedColorButton variant='container' className={props.className}  onClick={props.handleClick} type={props.type} >{props.label}</RedColorButton>
            )
        
        case "green-color-button" :
            return (
                <GreenColorButton variant='container' className={props.className}  onClick={props.handleClick} type={props.type} >{props.label}</GreenColorButton>
            )

        case "acent-small-button":
            return (
                <AcentColorButton variant='container' className={props.className} onClick={props.handleClick} type={props.type}>{props.label}</AcentColorButton>
            )

        case "green-small-button":
            return (
                <GreenSmallColorButton variant='container' className={props.className} onClick={props.handleClick} type={props.type}>{props.label}</GreenSmallColorButton>
            )

        case "red-small-button":
            return (
                <RedSmallColorButton variant='container' className={props.className} onClick={props.handleClick} type={props.type}>{props.label}</RedSmallColorButton>
            )

        case "small-primary-button":
            return (
                <SmallPrimaryColorButton variant='container' target={props.target} href={props.href} className={props.className} onClick={props.handleClick} type={props.type}>{props.label}</SmallPrimaryColorButton>
            )

        default : 
            return (
                <Button variant="contained" type={props.type} onClick={props.handleClick} >{props.label}</Button>
            )
        }

}

export default CustomizeButton