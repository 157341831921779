import { isEmpty } from 'lodash'
import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import { useHistory } from 'react-router';
import useMediaQuery from '@mui/material/useMediaQuery'

import PopUp from '../../common/popup/PopUp'
import { requestNotificationSelectForDevelopment } from '../../services/learning.service'
import { clearDevelopmentAreaState } from '../../redux/video-conference/development/Development.action';
import { toast } from 'react-toastify';
import CustomizeButton from '../../common/customize-button/CustomizeButton';

const DevelopmentPopup = (props) => {

    const { 
        fetchLearnings, 
        fetchLearningState, 
        clearLearning,
        ownProps
    } = props

    const [ pageLoading, setPageLoading ] = useState(true)

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const learningCircle = fetchLearningState.response.learning_circles

    useEffect(() => {
        if(isEmpty(fetchLearningState.response)){
            fetchLearnings()
        }
        return () => {
            clearLearning()
        }
    },[])

    useEffect(() => {
        if(!fetchLearningState.loading && !isEmpty(fetchLearningState.response)){
            setPageLoading(false)
        }

        if(!fetchLearningState.loading && !isEmpty(fetchLearningState.error)){
            console.log(fetchLearningState.error)
            setPageLoading(false)
            if(fetchLearningState.error.data){
                toast.error(fetchLearningState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    },[fetchLearningState])

    function createOption(learningCircle){
        let options = []
        for( let circle of learningCircle){
            let learningArea =[]
            for( let circleName of circle.segments){
                learningArea.push(<MenuItem sx={{fontSize:"15px",fontFamily:"'Lato',sans-serif",color:"#444444"}} disabled={ circleName.occupied ? true : false} value={circleName.value}>{circleName.value}</MenuItem>)
            }
            options.push(<ListSubheader  sx={{fontSize:"13px",
                                                fontFamily:"'Lato',sans-serif",
                                                lineHeight:"30px",
                                                color:"#858585"}}>{circle.name}</ListSubheader>,learningArea)
        }
        return options
    }

    const validationSchema = Yup.object({
        circleSegment: Yup.string().required('Pick a circle segment')
    });

    const formik = useFormik({
        initialValues: {
            circleSegment: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            ownProps.onSubmit(values.circleSegment)
            ownProps.handleClose()
            history.goBack()
        },
    });

    return (  
        <PopUp handleClose={props.handleClose} open={props.open}>
            <div className={isDesktop ? 'p-3 min-width-300px' :'p-3 min-width-250px'}>
                <h2 className="text-center lato-fs22-normal-lh32 font-bold primary-text-color pb-2">Select Learning area</h2>
                {
                    !pageLoading && !isEmpty(learningCircle) ?
                    <>
                        <form onSubmit={formik.handleSubmit}>
                            <FormControl fullWidth >
                                <InputLabel htmlFor="grouped-select" className="input-label">Select Learning area</InputLabel>
                                <Select  
                                    id="grouped-select" 
                                    label="Select Development"
                                    name="circleSegment"
                                    className="group-select"
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.circleSegment && Boolean(formik.errors.circleSegment)}
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                    value={formik.values.circleSegment}
                                    onChange={formik.handleChange}>
                                    {createOption(learningCircle)}
                                </Select>
                                {
                                    formik.touched.circleSegment && Boolean(formik.errors.circleSegment) ?
                                    <FormHelperText sx={{color : '#dc3545', fontFamily: '"oxygen", sans-serif'}}>{formik.errors.circleSegment}</FormHelperText> 
                                    : null
                                }
                            </FormControl>
                            <div className="d-flex justify-content-center mt-3">
                                <CustomizeButton variant="primary-color-button" type="submit" label="Proceed" />
                            </div>
                        </form>
                    </> : ""
                }
            </div>
        </PopUp>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        fetchLearningState : state.developmentArea,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLearnings : () => dispatch(requestNotificationSelectForDevelopment()),
        clearLearning : () => dispatch(clearDevelopmentAreaState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DevelopmentPopup)