import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom' 
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import TeamMemberCard from '../../components/team-member-card/TeamMemberCard'
import { requestReviewTeams } from '../../services/teamMember.service'
import './review-team.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const ReviewTeam = (props) => {

    const { reviewTeamState, requestReview } = props

    const [ pageLoading, setPageLoding ] = useState(true)

    const history = useHistory()
    const reviewTeam = reviewTeamState.response

    function handleCardClick(id,role){
        history.push(`/review/${id}?role=${role}&action=vcf&tab=under-your-review`)
    }

    useEffect(() => {
        requestReview()
    },[])

    useEffect(() => {
        if(!reviewTeamState.loading && !isEmpty(reviewTeamState.response)){
            setPageLoding(false)
        }

        if(!reviewTeamState.loading && !isEmpty(reviewTeamState.error)){
            console.log(reviewTeamState.error)
        }
    }, [reviewTeamState])

    return (
        !pageLoading ? 
        <div className="container pe-0 ps-0 vh-100 d-flex flex-column">
            <div className="px-3">
                <div className="pt-2 d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowLeft} className="secondary-color me-2" onClick={() => history.goBack()} />
                    <h5 className="fs20-lh32 primary-color mb-0">Review Team</h5>
                </div>
            </div>
            <div className="container-bg mt-2 review-container px-3">
                { !isEmpty(reviewTeam.levelOneMembers) ?
                    <div>
                        {reviewTeam.levelOneMembers.map((member) => (
                            <TeamMemberCard key={member._id}
                                firstName={!isEmpty(member.user) && !isEmpty(member.user.person_name) ? member.user.person_name.first_name : ""}
                                lastName={!isEmpty(member.user) && !isEmpty(member.user.person_name) ? member.user.person_name.last_name : ""}
                                pendingNumber={member.review_count}
                                jobRole={member.job_role}
                                profilePic={!isEmpty(member.user) && member.user.profile_pic ? member.user.profile_pic : ""}
                                onClick={() => handleCardClick(member.user._id,"reviewer")}/>
                        ))}
                    </div> : null }
                { !isEmpty(reviewTeam.levelTwoMembers) ?
                    <div>
                        {reviewTeam.levelTwoMembers.map((member) => (
                            <TeamMemberCard 
                                firstName={!isEmpty(member.user) && !isEmpty(member.user.person_name) ? member.user.person_name.first_name : ""} 
                                lastName={!isEmpty(member.user) && !isEmpty(member.user.person_name) ? member.user.person_name.last_name : ""}
                                pendingNumber={member.review_count}
                                profilePic={!isEmpty(member.user) && member.user.profile_pic ? member.user.profile_pic : ""}
                                jobRole={!isEmpty(member.job_role) ? member.job_role : ""} 
                                onClick={() => handleCardClick(member.user._id,"super-reviewer")}/> 
                        ))}
                    </div> : null }
            </div>
        </div> : null
    )
}

const mapStateToProps = (state) => {
    return {
        reviewTeamState : state.reviewTeam
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestReview : () => dispatch(requestReviewTeams())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReviewTeam)