import { REQUEST_BRANCHES, 
        FETCH_BRANCHES_SUCCESS,
        FETCH_BRANCHES_FAILURE,
        CLEAR_BRANCHES_STATE } from './Branches.types'

export const requestBranches = () => {
    return {
        type : REQUEST_BRANCHES
    }
}

export const saveBranches = (response) => {
    return {
        type : FETCH_BRANCHES_SUCCESS,
        payload : response
    }
}

export const fetchBranchesFailure = (error) => {
    return {
        type : FETCH_BRANCHES_FAILURE,
        payload : error
    }
}

export const clearBranchesState = () => {
    return {
        type : CLEAR_BRANCHES_STATE
    }
}