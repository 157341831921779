import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

const Header = (props) => {
    switch(props.platform){

        case "desktop":
            return (
                <div className='d-flex'>
                    {   
                        props.start ?
                            <div>{props.start}</div>
                        :null
                    }
                    <div className='ms-auto'>
                        <IconButton size='small' onClick={props.verticalEllipsisEvent}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={props.menu}
                            open={props.open}
                            onClose={props.handleClose}
                            onClick={props.handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                            <MenuItem 
                                onClick={props.editEvent} 
                                sx={{ 
                                    paddingLeft:"13px",
                                    paddingRight:"13px", 
                                    fontSize:"15px", 
                                    fontFamily:"'Lato',sans-serif", 
                                    paddingBottom:"3px" 
                                }}
                            >
                                <FontAwesomeIcon icon={faPen} className="me-2 secondary-color" style={{fontSize:"15px"}} />
                                Edit
                            </MenuItem>
                            <MenuItem 
                                onClick={props.deleteEvent} 
                                sx={{
                                    paddingLeft:"13px",
                                    paddingRight:"13px", 
                                    fontSize:"15px", 
                                    fontFamily:"'Lato',sans-serif", 
                                    paddingBottom:"3px" 
                                }}
                            > 
                                <FontAwesomeIcon icon={faTrash} className="me-2 secondary-color" style={{fontSize:"15px"}} />
                                Delete 
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            )

        default:
            return (
                <div className='d-flex align-items-center'>
                    <IconButton sx={{padding : 0}} className="me-2" size="small" onClick={props.handleBackArrowEvent}>
                        <ArrowBackIcon sx={{color:"var(--primary-text-color-v2)"}} />
                    </IconButton>
                    {
                        props.heading || props.subHeading ?
                            <div className='ms-1'>
                                {props.heading ? <span className='primary-text-color mb-0 lato-fs-20-normal-lh32 font-bold'>{props.heading}</span> : null}
                                {props.subHeading ? <span className='secondary-text-color lato-fs16-normal-lh20'>{props.subHeading}</span> : null}
                            </div> 
                            : null
                    }
                    <div className='ms-auto'>
                        { props.moreIcon ?
                            <>
                                <IconButton onClick={props.moreIconEvent} size="small" sx={{ ml: 5 }}>
                                    <MoreVertIcon/>
                                </IconButton>
                                <Menu
                                    anchorEl={props.menu}
                                    open={props.open}
                                    onClose={props.handleClose}
                                    onClick={props.handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem 
                                        sx={{ 
                                            fontFamily:"'Lato',sans-serif", 
                                            fontSize:"16px",
                                            color:"var(--primary-text-color-v2)",
                                            minHeight: "36px" 
                                        }} 
                                        onClick={props.handleEditEvent}
                                    >
                                        <FontAwesomeIcon icon={faPen} className="me-2 secondary-color" />
                                        Edit
                                    </MenuItem>
                                    <MenuItem 
                                        sx={{ 
                                            fontFamily:"'Lato',sans-serif", 
                                            fontSize:"16px",
                                            color:"var(--primary-text-color-v2)",
                                            minHeight: "36px" 
                                        }}
                                        onClick={props.handleDeleteEvent}
                                    > 
                                        <FontAwesomeIcon icon={faTrash} className="me-2 secondary-color" />
                                        Delete 
                                    </MenuItem>
                                </Menu>
                            </> : null
                        }
                        {props.extraComponent}
                    </div>
                </div>
            )
    }
}

export default Header