import React, {useRef} from 'react'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

import CustomizeInputBox from '../../../common/customize-input-box/CustomizeInputBox';


const DesktopSearchBarTwo = (props) => {

    return (
        <Box
            sx={{
                width: '250px',
                marginLeft :"20px", 
                marginRight:"20px", 
                display:"flex",
                alignItems:"center", 
                background:"var(--tertiary-color-v2)",
                padding:"3px 4px 3px 12px",
                borderRadius:'5px'
            }} 
        >
            <SearchIcon 
                sx={{
                    fontSize: 20
                }}
            />
            <CustomizeInputBox
                className="ms-2 bg-transparent"
                type="text"
                {...props.inputBox}
            />
            {
                props.showCloseIcon ?
                    <IconButton 
                        sx={{
                            marginLeft:"5px"
                        }}
                        onClick={props.onSearchCloseClick} 
                    >
                        <CloseIcon 
                            sx={{
                                fontSize: 15
                            }}
                        />
                    </IconButton>
                    : null
            }
        </Box>
    )
}

export default DesktopSearchBarTwo