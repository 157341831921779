import React from 'react'
import { Chart } from "react-google-charts"
import { size } from 'lodash'
import './gantt-chart.css'
import moment from 'moment'
import useMediaQuery from '@mui/material/useMediaQuery'

const GanttChart = (props) => {

    const columns = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" }
    ]

    function createRows(){
        const rows = []
        const dataArrCopy = JSON.parse(JSON.stringify(props.graphDetail))
        const dataArr = dataArrCopy.slice(1, size(dataArrCopy))
        for(const data of dataArr){
            const item = []
            item.push(data.G.toString().trim())
            item.push(data.A.toString().trim())
            if(data.I !== null && data.I !== undefined){
                item.push(data.I.toString().trim())
            } else {
                item.push(null)
            }
            item.push(moment(data.E).toDate())
            item.push(moment(data.F).toDate())
            item.push(null)
            if(data.B !== null && data.B !== undefined && data.C !== null && data.C !== null){
                const percentage = Math.round((data.B/data.C)*100)
                item.push(percentage)
            } else if(data.D !== null && data.D !== undefined) {
                item.push(data.D)
            } else {
                item.push(0)
            }
            if(data.H !== null && data.H !== undefined){
                item.push(data.H.toString().trim())
            } else {
                item.push(null)
            }
            rows.push(item)
        }
        return rows
    }

    function aggregateCreateRows(){
        const rows = []
        const dataArrCopy = JSON.parse(JSON.stringify(props.graphDetail))
        const dataArr = dataArrCopy.slice(1, size(dataArrCopy))
        if(size(dataArr) > 5){
            const currentDate = moment()
            const index = dataArr.findIndex(obj => currentDate.isBetween(moment(obj.E), moment(obj.F), undefined, '[]'))
            if(index !== -1){
                if((index + 4) <= (size(dataArr) - 1)) {
                    const requiredDataArr = dataArr.slice(index, index+5)
                    for(const data of requiredDataArr){
                        const item = []
                        item.push(data.G.toString().trim())
                        item.push('')
                        if(data.I !== null && data.I !== undefined){
                            item.push(data.I.toString().trim())
                        } else {
                            item.push(null)
                        }
                        item.push(moment(data.E).toDate())
                        item.push(moment(data.F).toDate())
                        item.push(null)
                        if(data.B !== null && data.B !== undefined && data.C !== null && data.C !== null){
                            const percentage = Math.round((data.B/data.C)*100)
                            item.push(percentage)
                        } else if(data.D !== null && data.D !== undefined) {
                            item.push(data.D)
                        } else {
                            item.push(0)
                        }
                        item.push(null)
                        rows.push(item)
                    }
                } else {
                    const requiredDataArr = dataArr.slice(size(dataArr)-5, size(dataArr))
                    for(const data of requiredDataArr){
                        const item = []
                        item.push(data.G.toString().trim())
                        item.push('')
                        if(data.I !== null && data.I !== undefined){
                            item.push(data.I.toString().trim())
                        } else {
                            item.push(null)
                        }
                        item.push(moment(data.E).toDate())
                        item.push(moment(data.F).toDate())
                        item.push(null)
                        if(data.B !== null && data.B !== undefined && data.C !== null && data.C !== null){
                            const percentage = Math.round((data.B/data.C)*100)
                            item.push(percentage)
                        } else if(data.D !== null && data.D !== undefined) {
                            item.push(data.D)
                        } else {
                            item.push(0)
                        }
                        item.push(null)
                        rows.push(item)
                    }
                }
            } else {
                const formattedStartDates = dataArr.map(item => moment(item.D))
                const earliestStartDate = moment.min(formattedStartDates)

                if(earliestStartDate.isBefore(moment())){
                    const requiredDataArr = dataArr.slice(size(dataArr)-5, size(dataArr))
                    for(const data of requiredDataArr){
                        const item = []
                        item.push(data.G.toString().trim())
                        item.push('')
                        if(data.I !== null && data.I !== undefined){
                            item.push(data.I.toString().trim())
                        } else {
                            item.push(null)
                        }
                        item.push(moment(data.E).toDate())
                        item.push(moment(data.F).toDate())
                        item.push(null)
                        if(data.B !== null && data.B !== undefined && data.C !== null && data.C !== null){
                            const percentage = Math.round((data.B/data.C)*100)
                            item.push(percentage)
                        } else if(data.D !== null && data.D !== undefined) {
                            item.push(data.D)
                        } else {
                            item.push(0)
                        }
                        item.push(null)
                        rows.push(item)
                    }
                } else {
                    const requiredDataArr = dataArr.slice(0, 5)
                    for(const data of requiredDataArr){
                        const item = []
                        item.push(data.G.toString().trim())
                        item.push('')
                        if(data.I !== null && data.I !== undefined){
                            item.push(data.I.toString().trim())
                        } else {
                            item.push(null)
                        }
                        item.push(moment(data.E).toDate())
                        item.push(moment(data.F).toDate())
                        item.push(null)
                        if(data.B !== null && data.B !== undefined && data.C !== null && data.C !== null){
                            const percentage = Math.round((data.B/data.C)*100)
                            item.push(percentage)
                        } else if(data.D !== null && data.D !== undefined) {
                            item.push(data.D)
                        } else {
                            item.push(0)
                        }
                        item.push(null)
                        rows.push(item)
                    }
                }
            }
        } else {
            for(const data of dataArr){
                const item = []
                item.push(data.G.toString().trim())
                item.push('')
                if(data.I !== null && data.I !== undefined){
                    item.push(data.I.toString().trim())
                } else {
                    item.push(null)
                }
                item.push(moment(data.E).toDate())
                item.push(moment(data.F).toDate())
                item.push(null)
                if(data.B !== null && data.B !== undefined && data.C !== null && data.C !== null){
                    const percentage = Math.round((data.B/data.C)*100)
                    item.push(percentage)
                } else if(data.D !== null && data.D !== undefined) {
                    item.push(data.D)
                } else {
                    item.push(0)
                }
                item.push(null)
                rows.push(item)
            }
        }
        return rows
    }

    const isMobile = useMediaQuery('(max-width:480px)')

    const rows = createRows()

    const data = [columns, ...rows]

    const aggregateRow = aggregateCreateRows()

    const aggregateData = [columns, ...aggregateRow]

    const trackHeight = 30
    const bottomLabelSpacing = 40

    // const options = {
    //     height: (trackHeight * rows.length) + bottomLabelSpacing,
    //     gantt: {
    //         trackHeight: trackHeight,
    //         labelStyle: {
    //             fontName: "'oxygen',sans-serif",
    //             fontSize: 14,
    //         }
    //     },
    // }

    const previewOptions = {
        height: (trackHeight * rows.length) + bottomLabelSpacing,
        gantt: {
            trackHeight: trackHeight,
            barHeight: isMobile ? 18 : 25,
            labelStyle: {
                fontName: "'oxygen',sans-serif",
                fontSize: isMobile ? 13 : 14,
            }
        }
    }

    const aggregateGraphoptions = {
        height: (trackHeight * rows.length),
        gantt: {
            trackHeight: trackHeight,
            labelStyle: {
                fontName: "'oxygen',sans-serif",
                fontSize: 14,
            },
            criticalPathEnabled: false,
            innerGridHorizLine: {
            stroke: "#fff",
                strokeWidth: 2,
            },
            innerGridTrack: { fill: "#fff" },
            innerGridDarkTrack: { fill: "#fff" }
        },
    }

    const expandedGraphTrackHeight = 50

    const expandedGraphOptions = {
        height: (expandedGraphTrackHeight * rows.length) + bottomLabelSpacing,
        gantt: {
            trackHeight: expandedGraphTrackHeight,
            labelStyle: {
                fontName: "'oxygen',sans-serif",
                fontSize: isMobile ? 12 : 16,
            },
        },
    }

    switch(props.variant){
        case "preview":
            return(
                <div className='mt-3'>
                    <Chart chartType="Gantt"
                        width="100%" 
                        height="100%"
                        data={data}
                        options={previewOptions}
                    />
                </div>
            )

        case "expanded-graph":
            return (
                <div className='mt-4'>
                    <Chart chartType="Gantt"
                        width="100%"
                        height="100%"
                        data={data}
                        options={expandedGraphOptions}
                    />
                </div>
            )

        default:
            return(
                <div className='gantt-chart'>
                    <Chart chartType="Gantt"
                        width="100%" 
                        height="100%"
                        data={aggregateData}
                        options={aggregateGraphoptions}
                    />
                </div>
            )
    }
}

export default GanttChart