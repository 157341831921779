import React,{ useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash';
import {FieldArray, Form, Formik, Field} from 'formik'
import * as Yup from 'yup'
import moment from 'moment';
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import editIcon from '../../../assets/icons/edit_white.svg'
import { formatDate, formatYearAndMonth, formatYearAndMonth2 } from '../../../utils/utils'
import imagePlaceholder from '../../../assets/images/profile-placeholder.png'
import InputBox from '../../../common/Input-box/InputBox';
import DatePicker from '../../../common/date-picker/DatePicker';
import TextAreaBox from '../../../common/textarea-box/TextAreaBox';
import SelectBox from '../../../common/select-box/SelectBox';
import { updateProfileData } from '../../../services/ProfileData.service';
import { clearUpdateProfileDataStates } from '../../../redux/update-profile/updateProfile.action';
import { saveProfileData } from '../../../redux/profile/ProfileAction'
import './user-profile.css'
import { toast } from 'react-toastify';
import CustomizeButton from '../../../common/customize-button/CustomizeButton';
import Grid from '@mui/material/Grid';
import ImgMenu from '../../../common/img-menu/ImgMenu';

const UserProfile = (props) => {

    const { 
        updateProfileData, 
        ownProps, 
        updateProfileState, 
        clearUpdateProfileStates, 
        saveUpdatedProfileData 
    } = props

    const emptyQualification = {
        degree: "",
        year_of_passing:""
    }

    const emptyWorkExperience = {
        start_date : "",
        end_date:"",
        job_role:"",
        organisation:"",
        remarks:"",
    }

    let currentyear = moment().year()

    const userProfileData = ownProps.profileData.profile_data

    const imageRef = useRef();
    const [profileImage, setProfileImage] = useState("")
    const [imageFile, setImageFile] = useState("")
    const [isPresent, setIsPresent] = useState([false])
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)

    function initPresentWorkingState(workExperiences){
        let workingStatus = []
        for(const workExperience of workExperiences){
            if(workExperience.end_date){
                workingStatus.push(false)
            } else {
                workingStatus.push(true)
            }
        }
        setIsPresent(workingStatus)
    }

    function init(profileData){
        if(!isEmpty(profileData.work_experiences) && !isEmpty(profileData.work_experiences.experience_data)) {
            initPresentWorkingState(profileData.work_experiences.experience_data)
        }
    }

    useEffect(() => {
        init(userProfileData)
        setProfileImage( ps => {
            if(!isEmpty(userProfileData) && userProfileData.profile_pic) {
                return userProfileData.profile_pic
            } else {
                return ''
            }
        })
    }, [])

    const initialValues={
        person_name : {
            first_name : !isEmpty(userProfileData) && 
                        !isEmpty(userProfileData.person_name) && 
                        userProfileData.person_name.first_name ? userProfileData.person_name.first_name : "",
            middle_name: !isEmpty(userProfileData) && 
                        !isEmpty(userProfileData.person_name) && 
                        userProfileData.person_name.middle_name ? userProfileData.person_name.middle_name : "",
            last_name: !isEmpty(userProfileData) && 
                        !isEmpty(userProfileData.person_name) && 
                        userProfileData.person_name.last_name ? userProfileData.person_name.last_name : ""
        },
        email: !isEmpty(userProfileData) && !isEmpty(userProfileData.email) && userProfileData.email.id ? userProfileData.email.id : "", 
        address : {
            door_no : !isEmpty(userProfileData) && 
                    !isEmpty(userProfileData.address) && 
                    userProfileData.address.door_no ? userProfileData.address.door_no :"",
            area: !isEmpty(userProfileData) && 
                !isEmpty(userProfileData.address) && 
                userProfileData.address.area ? userProfileData.address.area : "",
            city: !isEmpty(userProfileData) && 
                !isEmpty(userProfileData.address) && 
                userProfileData.address.city ? userProfileData.address.city : "",
            state: !isEmpty(userProfileData) && 
                    !isEmpty(userProfileData.address) && 
                    userProfileData.address.state ? userProfileData.address.state : "",
            pincode: !isEmpty(userProfileData) && 
                    !isEmpty(userProfileData.address) && 
                    userProfileData.address.pincode ? userProfileData.address.pincode : "",
            country: !isEmpty(userProfileData) && 
                    !isEmpty(userProfileData.address) && 
                    userProfileData.address.country ? userProfileData.address.country : ""
        },
        work_experiences: !isEmpty(userProfileData) && 
                        !isEmpty(userProfileData.work_experiences) && !isEmpty(userProfileData.work_experiences.experience_data) ? 
                        generateWorkExperience(userProfileData.work_experiences.experience_data) : [emptyWorkExperience],
        dob: !isEmpty(userProfileData) && userProfileData.dob ? formatDate(userProfileData.dob) : "",
        qualifications: !isEmpty(userProfileData) && !isEmpty(userProfileData.qualifications) ? 
                        generateQualifications(userProfileData.qualifications) : [emptyQualification],
        gender : !isEmpty(userProfileData) && userProfileData.gender ? userProfileData.gender : "",
        mobile_number : !isEmpty(userProfileData) && !isEmpty(userProfileData.mobile) && userProfileData.mobile.number ? userProfileData.mobile.number : "",
    }

    function generateQualifications(qualifications) {
        let qualificationsArray = [];
        for(const qualification of qualifications) {
            qualificationsArray.push({
                degree : qualification.degree ? qualification.degree : "",
                year_of_passing : qualification.year_of_passing ? qualification.year_of_passing : ""
            })
        } 
        return qualificationsArray
    }

    function generateWorkExperience(workExperiences) {
        let workExperienceArray = []
        for (const workExperience of workExperiences) {
            workExperienceArray.push({
                start_date : workExperience.start_date ? formatYearAndMonth(workExperience.start_date) : "",
                end_date : workExperience.end_date ? formatYearAndMonth(workExperience.end_date) : "",
                job_role : workExperience.job_role ? workExperience.job_role : "",
                organisation : workExperience.organisation ? workExperience.organisation : "",
                remarks : workExperience.remarks ? workExperience.remarks : ""
            })
        }
        return workExperienceArray
    }

    const validationSchema = Yup.object({
        person_name : Yup.object().shape({
            first_name : Yup.string()
                            .required('Please enter your first name')
                            .matches(/^[A-Za-z ]*$/, 'Invalid first name')
                            .min(2, 'Too short')
                            .max(40, 'Too long'),
            middle_name : Yup.string(),
            last_name : Yup.string()
                            .required('Please enter your last name')
                            .matches(/^[A-Za-z ]*$/, 'Invalid last name')
                            .min(2, 'Too short')
                            .max(40, 'Too long')
        }),
        email:Yup.string().required("Please enter your email").email("Invalid email"),
        dob: Yup.string().required("Please select your date of birth"),
        address: Yup.object().shape({
            door_no: Yup.string()
                    .min(0,"Too short")
                    .max(50,"Too long"),
            area: Yup.string(),
            city: Yup.string().required("Please enter your city"),
            state: Yup.string().required("Please enter your State/Province/Region"),
            country: Yup.string().required("Please enter your Country"),
            pincode : Yup.string().required('Please enter your ZIP/Postal Code')
        }),
        work_experiences: Yup.array().of(
            Yup.object().shape({
                start_date : Yup.string().required("Please enter your start date"),
                // end_date : Yup.string().when([],{
                //     is: () => isPresent == true,
                //     then: Yup.string().required("Are you currently working please select the checkbox or please select the end date"),
                //     otherwise: Yup.string().notRequired() 
                // }),
                job_role: Yup.string().required("Please select your job role"),
                organisation : Yup.string().required("Please enter your organisation name")
                                .min(2, "Invalid organisation name")
                                .max(20, "Invalid organisation name"),
                remarks:Yup.string(),
            })
        ),
        qualifications: Yup.array().of(
            Yup.object().shape({
                degree : Yup.string().required("Please select your qualification"),
                year_of_passing: Yup.string().required("Please select your passing year")
            })
        ),
        mobile_number: Yup.string().matches(/^[6789]\d{9}$/,"Invalid mobile number")
    })

    const onSubmit = (values) => {  
        // console.log(values)
        const valuesCopy = {...values}
        valuesCopy.dob = moment(values.dob).toISOString()
        valuesCopy.work_experiences[0].start_date = moment(values.work_experiences[0].start_date, 'YYYY/MM').toISOString() 
        valuesCopy.work_experiences[0].end_date = moment(values.work_experiences[0].end_date, 'YYYY/MM').toISOString() 
        if(navigator.onLine){
            if(!profileImage && userProfileData.profile_pic){
                valuesCopy.profile_pic = ""
            }
            updateProfileData(formatToFormData(valuesCopy))
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    useEffect(() => {
        if(!updateProfileState.loading && !isEmpty(updateProfileState.response)){
            saveUpdatedProfileData(updateProfileState.response)
            // setProfileEdit(ps => !ps)
            ownProps.handleEditModeChange(false)
            clearUpdateProfileStates()
            
        }
        if(!updateProfileState.loading && !isEmpty(updateProfileState.error)){
            console.log(updateProfileState.error)
            toast.error(updateProfileState.error?.data?.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }, [updateProfileState])

    const getYear = () => {
        let years = []
        for( let i=currentyear ; i >= 1960 ; i--){
            years.push(i)
        }
        return years;
    }

    function handlePresentChange(event,index){
        setIsPresent(prevArray => {
            let prevArrayCopy = prevArray.slice()
            prevArrayCopy[index] = event.target.value === "true" ? true : false
            return prevArrayCopy
        })
    }

    function insertNewExperienceColumn(push) {
        setIsPresent(prevState => {
            return [...prevState, false]
        })
        push(emptyWorkExperience)
    }

    function removeExperienceColumn(remove, index) {
        setIsPresent(prevArray => {
            let prevArrayCopy = prevArray.slice()
            let newArray = prevArrayCopy.filter((item, i) => {
                return index !== i
            })
            return newArray
        })
        remove(index)
    }

    useEffect(() => {
        if(imageFile){
            const reader = new FileReader()
            reader.onloadend =() =>{
                setProfileImage(reader.result)
            }
            reader.readAsDataURL(imageFile)
        }
    }, [imageFile])

    function formatToFormData(profileData){
        const json = JSON.stringify(profileData)
        let data = new FormData()
        data.append("profile_data", json)
        if(imageFile !== ""){
            data.append("profile_pic", imageFile)
        }
        return data
    }

    // function handleEditButton(){
    //     setProfileEdit(!profileEdit)
    //     handleClose()
    // }

    function getGender(gender){
        if(gender === "F"){
            return "Female"
        }else if(gender === "M"){
            return "Male"
        }else {
            return "Others"
        }
    }

    const profileMenuOpen = Boolean(profileAnchorEl)

    const handleProfileEditIconClick = (event) => {
        event.preventDefault()
        setProfileAnchorEl(event.currentTarget)
    }

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null)
    }

    const onRemoveProfileClick = () => {
        if(imageFile) setImageFile('')
        if(profileImage) setProfileImage('')
    }

    function getProfileDetailsForm(){
        return (
            <div>
                <div className="mb-2">
                    <div className="p-relative preview-image-container">
                        <img className="preview-image" src={profileImage ? profileImage : imagePlaceholder} alt="" />
                        <button 
                            id="profile-edit-icon"
                            aria-controls={profileMenuOpen ? 'profile-edit-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={profileMenuOpen ? 'true' : undefined}
                            className="image-button" 
                            onClick={handleProfileEditIconClick}
                        >
                            <img className="" src={editIcon} alt="" />
                        </button>
                        <ImgMenu 
                            id="profile-edit-menu"
                            ariaLabelledBy="profile-edit-icon"
                            anchorEl={profileAnchorEl}
                            open={profileMenuOpen}
                            onClose={handleProfileMenuClose}
                            onUploadClick={(event) => {
                                event.preventDefault()
                                imageRef.current.click()
                                handleProfileMenuClose()
                            }}
                            onRemoveClick={() => {
                                onRemoveProfileClick()
                                handleProfileMenuClose()
                            }}
                            disableRemove={Boolean(!profileImage)}
                        />
                    </div>
                    <input 
                        type="file"
                        style={{display:"none"}} 
                        ref={imageRef} 
                        accept="image/png, image/jpeg, image/jpg"
                        name="banner"
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        onChange={(event) => {
                            const file = event.target.files[0];
                            if(file && file.type.substr(0,5) === "image"){
                                setImageFile(file)
                            }
                        }}
                    />
                </div>
                <Formik 
                    initialValues = {initialValues}
                    validationSchema = {validationSchema}
                    enableReinitialize={true}
                    onSubmit = {onSubmit} >
                    <Form>
                        <div className="row pt-3">
                            <div className="col-md-4 mb-3">
                                <Field name="person_name.first_name" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <InputBox type="text"
                                                    label="First Name"
                                                    placeholder="Enter your first name" 
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field> 
                            </div>
                            <div className="col-md-4 mb-3">
                                <Field name="person_name.middle_name" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <InputBox type="text"
                                                    label="Middle Name"
                                                    placeholder="Enter your middle name" 
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field> 
                            </div>
                            <div className="col-md-4 mb-3">
                                <Field name="person_name.last_name" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <InputBox type="text"
                                                    label="Last Name"
                                                    placeholder="Enter your last name" 
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field> 
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-md-6 mb-3">
                                <label className="lato-fs13-normal-lh18 tertiary-text-color-v2 mb-2">Gender</label>
                                <>
                                    <div className='d-flex' role="group" aria-labelledby="my-radio-group">
                                        <label className="d-flex align-items-center me-2 mb-0 lato-fs15-normal-lh20">
                                            <Field className="me-1 my-1" type="radio" name="gender" value="M" />
                                            Male
                                        </label>
                                        <label className="d-flex align-items-center me-2 mb-0 lato-fs15-normal-lh20">
                                            <Field className="me-1 my-1" type="radio" name="gender" value="F" />
                                            Female
                                        </label>
                                        <label className="d-flex align-items-center mb-0 lato-fs15-normal-lh20">
                                            <Field className="me-1 my-1" type="radio" name="gender" value="O" />
                                            Others
                                        </label>
                                    </div>   
                                </>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Field name="dob" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <DatePicker type="date"
                                                    label="Date of Birth"
                                                    placeholder="Enter your Date of birth" 
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-md-6 mb-3">
                                <Field name="email" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <>
                                            <InputBox type="text"
                                                    label="Email Id"
                                                    placeholder="Enter your email id" 
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                            </>
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Field name="mobile_number" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <>
                                            <InputBox type="tel"
                                                    label="Mobile Number"
                                                    placeholder="Enter your mobile number" 
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                            </>
                                        )
                                    }}
                                </Field>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-md-12 mb-3">
                                <Field name="address.door_no" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <InputBox type="text"
                                                    label="Address Line 1"
                                                    placeholder="Enter Apartment, suite, unit, building, floor, etc.,"
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className="col-md-12 mb-3">
                                <Field name="address.area" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <TextAreaBox 
                                                    label="Address Line 2"
                                                    placeholder="Enter Street address, P.O. box, company name, c/o"
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Field name="address.city" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <InputBox type="text"
                                                    placeholder="Enter your city" 
                                                    label="City"
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Field name="address.state" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <InputBox type="text"
                                                label="State/Province/Region"
                                                placeholder="Please enter State/Province/Region"
                                                {...field}
                                                errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Field name="address.pincode" >
                                    {props => {
                                        const {field, form, meta} = props
                                        return (
                                            <>
                                            <InputBox type="text"
                                                    placeholder="Enter ZIP/Postal Code"
                                                    label="ZIP/Postal Code"
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                            </>
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className="col-md-6 mb-3">
                                <Field name="address.country" >
                                        {props => {
                                            const {field, form, meta} = props
                                            return (
                                                <SelectBox options={ownProps.countries}
                                                    placeholder="Select your country" 
                                                    label="Country"
                                                    {...field}
                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                            )
                                        }}
                                </Field> 
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-md-12">
                                <h6 className="lato-fs20-normal-lh28 font-bold">Qualifications:</h6>
                                <FieldArray name="qualifications">
                                    { fieldArrayProps => {
                                        const { push, remove, form } = fieldArrayProps
                                        const { values } = form
                                        const { qualifications } = values
                                        return (
                                            <div>
                                                {
                                                    qualifications.map((qualification, index) => (
                                                        <div key={index} className="row pt-3">
                                                            <div className="col-md-6 mb-3">
                                                                <Field name={`qualifications[${index}].degree`} >
                                                                    {props => {
                                                                        const {field, meta} = props
                                                                        return (
                                                                            <SelectBox options={ownProps.highestQualification}
                                                                                    placeholder="Select your qualification" 
                                                                                    label="Degree"
                                                                                    {...field}
                                                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} />
                                                                        )
                                                                    }}
                                                                </Field>
                                                            </div>
                                                            <div className="d-flex col-md-6 mb-3">
                                                                <Field name={`qualifications[${index}].year_of_passing`} >
                                                                    {props => {
                                                                        const {field, meta} = props
                                                                        return (
                                                                            <SelectBox
                                                                                containerClassName="flex-fill"
                                                                                fullWidth={false}
                                                                                options={getYear()}
                                                                                placeholder="Select your passing year"
                                                                                label="Year of passing" 
                                                                                errorMgs={(meta.touched && meta.error) ? meta.error : null} 
                                                                                {...field}
                                                                            />
                                                                        )
                                                                    }}
                                                                </Field>
                                                                {
                                                                    index > 0 ?
                                                                        <div className='d-flex align-items-center ms-2'>
                                                                            <IconButton onClick={() => remove(index)}>
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                <div className="mb-3">
                                                    <CustomizeButton
                                                        variant="secondary-start-icon-button"
                                                        StartIcon={<AddIcon />}
                                                        type="button" 
                                                        handleClick={() => push(emptyQualification)}
                                                        label="Add qualification"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }}
                                </FieldArray>
                            </div>
                        </div>
                        <div className="row pt-4">
                            <div className="col-md-12">
                                <h6 className="lato-fs20-normal-lh28 font-bold mb-0">Work Experiences:</h6>
                                    <FieldArray name="work_experiences">
                                        {fieldArrayProps => {
                                            const { push, remove, form } = fieldArrayProps
                                            const { values } = form
                                            const { work_experiences } = values
                                            return (
                                                <div>
                                                    {
                                                        work_experiences.map((experience, index) => (
                                                            <div key={index} className="row pt-4 pb-4">
                                                                <div className="col-md-6 mb-3">
                                                                    <Field name={`work_experiences[${index}].organisation`} >
                                                                        {props => {
                                                                            const {field, form, meta} = props
                                                                            return (
                                                                                <InputBox 
                                                                                    type="text"
                                                                                    placeholder="Enter your organisation" 
                                                                                    label="Organisation"
                                                                                    {...field}
                                                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null}
                                                                                />
                                                                            )
                                                                        }}
                                                                    </Field> 
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <Field name={`work_experiences[${index}].job_role`} >
                                                                        {props => {
                                                                            const {field, form, meta} = props
                                                                            return (
                                                                                <InputBox 
                                                                                    type="text"
                                                                                    placeholder="Enter your job role" 
                                                                                    label="Job Role"
                                                                                    {...field}
                                                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null} 
                                                                                />
                                                                            )
                                                                        }}
                                                                    </Field> 
                                                                </div>
                                                                <div className="col-md-12 mb-3">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        checked={isPresent[index]} 
                                                                        value={!isPresent[index]}
                                                                        onChange={(event) => handlePresentChange(event, index)} 
                                                                    />
                                                                    <label className="mb-0 ms-1 lato-fs15-normal-lh20">Currently working</label>
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <Field name={`work_experiences[${index}].start_date`} >
                                                                        {props => {
                                                                            const {field, form, meta} = props
                                                                            return (
                                                                                <DatePicker
                                                                                    type="month"
                                                                                    placeholder="Select your start date" 
                                                                                    label="Start Date"
                                                                                    {...field}
                                                                                    errorMgs={(meta.touched && meta.error) ? meta.error : null}    
                                                                                />
                                                                            )
                                                                        }}
                                                                    </Field> 
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    { isPresent[index] === true ? 
                                                                        <div>
                                                                            <label className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">End Date</label>
                                                                            <p className="lato-fs15-normal-lh20 mb-0">Present</p>
                                                                        </div> :
                                                                        <Field name={`work_experiences[${index}].end_date`} >
                                                                            {props => {
                                                                                const {field, meta} = props
                                                                                return (
                                                                                    <DatePicker 
                                                                                        type="month"
                                                                                        placeholder="Select your end date" 
                                                                                        label="End Date"
                                                                                        {...field}
                                                                                        errorMgs={(meta.touched && meta.error) ? meta.error : null} 
                                                                                    />
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    }
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <Field name={`work_experiences[${index}].remarks`} >
                                                                        {props => {
                                                                            const {field, form, meta} = props
                                                                            return (
                                                                                <>
                                                                                    <TextAreaBox 
                                                                                        placeholder="Enter your remarks" 
                                                                                        className="textarea-container"
                                                                                        label="Remarks"
                                                                                        {...field} />
                                                                                    {
                                                                                        meta.touched && meta.error ? 
                                                                                            <div>
                                                                                                <span className="error-message">{meta.error}</span>
                                                                                            </div> 
                                                                                            : null
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }}
                                                                    </Field>
                                                                </div>
                                                                <div className="ms-auto me-1">
                                                                {
                                                                    index > 0 ?
                                                                        <div className="py-2">
                                                                            <IconButton onClick={() => removeExperienceColumn(remove, index)}>
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                        </div> 
                                                                        : null
                                                                }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    <CustomizeButton
                                                        variant="secondary-start-icon-button"
                                                        StartIcon={<AddIcon />}
                                                        type="button" 
                                                        handleClick={() => insertNewExperienceColumn(push)}
                                                        label="Add experience"
                                                    />
                                                </div>
                                            )
                                        }}
                                    </FieldArray> 
                            </div>
                        </div>
                        <div className="button-container">
                            <div className='me-3'>
                                <CustomizeButton
                                    variant="secondary-color-button"
                                    type="button" 
                                    handleClick={() => ownProps.handleEditModeChange(false)}
                                    label="Cancel"
                                />
                            </div>
                            <div className='ms-3'>
                                <CustomizeButton
                                    variant="primary-color-button"
                                    type="submit"
                                    label="Update"
                                />
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        )
    }

    function getProfileDetails(){
        return (
            <div className='container'>
                <Grid container>
                    <Grid item xs={12} sm={4} md={3} lg={3} xl={3} className="d-flex align-items-center" >
                        <div style={{width: '150px', height: '150px'}}>
                            <img style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: "center", borderRadius: '50%'}} src={profileImage ? profileImage : imagePlaceholder} alt="" />
                        </div>
                    </Grid>
                    <Grid item className='d-flex flex-column justify-content-center'>
                        <div className='mb-2'>
                            {
                                userProfileData?.person_name?.first_name ?
                                    <span className='lato-fs26-bold-lh32'>{`${userProfileData?.person_name?.first_name} `}</span>
                                    : null
                            }
                            {
                                userProfileData?.person_name?.middle_name ?
                                    <span className='lato-fs26-bold-lh32'>{`${userProfileData?.person_name?.middle_name} `}</span>
                                    : null
                            }
                            {
                                userProfileData?.person_name?.last_name ?
                                    <span className='lato-fs26-bold-lh32'>{`${userProfileData?.person_name?.last_name}`}</span>
                                    : null
                            }
                        </div>
                        <div className='mb-1'>
                            <FontAwesomeIcon className="primary-color-v2 me-2" style={{fontSize: '17px'}} icon={faEnvelope}  />
                            <span className="lato-fs17-normal-lh20 mb-0">{userProfileData.email?.id}</span>
                        </div>
                        { 
                            userProfileData?.mobile?.number ?
                            <div className='mb-1'>
                                <FontAwesomeIcon className="primary-color-v2 me-2" style={{fontSize: '17px'}} icon={faPhoneAlt} />
                                <span className="lato-fs17-normal-lh20 mb-0">{userProfileData?.mobile?.number}</span>
                            </div> : ""
                        }
                        {
                            !isEmpty(userProfileData.address) ?
                            <div className="d-flex">
                                <FontAwesomeIcon className="primary-color-v2 me-2 mt-1" style={{fontSize: '20px'}} icon={faMapMarkerAlt} />
                                <div>
                                    {
                                        userProfileData?.address?.door_no || userProfileData?.address?.area ?
                                            <div>
                                                {
                                                    userProfileData?.address?.door_no ?
                                                        <span className="lato-fs17-normal-lh20" >{`${userProfileData?.address?.door_no}, `}</span>
                                                        : null
                                                }
                                                {
                                                    userProfileData?.address?.area ?
                                                        <span className="lato-fs17-normal-lh20" >{`${userProfileData?.address?.area},`}</span>
                                                        : null
                                                }
                                            </div> :
                                            null
                                    }
                                    {
                                        userProfileData?.address?.city ?
                                            <div>
                                                <span className="lato-fs17-normal-lh20">{`${userProfileData?.address?.city},`}</span>
                                            </div> :
                                            null
                                    }
                                    {
                                        userProfileData?.address?.state || userProfileData?.address.pincode ?
                                            <div>
                                                {
                                                    userProfileData?.address?.state ?
                                                        <span className="lato-fs17-normal-lh20">{`${userProfileData?.address?.state} - `}</span>
                                                        : null
                                                }
                                                {
                                                    userProfileData?.address.pincode ?
                                                        <span className="lato-fs17-normal-lh20">{userProfileData?.address.pincode}</span>
                                                        : null
                                                }
                                            </div> :
                                            null
                                    }
                                </div>
                            </div> : null 
                        }
                    </Grid>
                </Grid>
                <hr className="small-hl"/>
                <div className='row'>
                    <div className='col'>
                        <h5 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">DOB:</h5>
                        <p className="mb-0 lato-fs17-normal-lh20">{moment.utc(userProfileData?.dob).local().format('DD-MM-YYYY')}</p>
                    </div>
                    <div className='col'>
                        {
                            userProfileData.gender ?
                            <>
                                <h5 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Gender:</h5>
                                <p className="mb-0 lato-fs17-normal-lh20">{getGender(userProfileData?.gender)}</p>
                            </> : ""
                        }
                    </div>
                </div>
                { 
                    !isEmpty(userProfileData.work_experiences) ?
                    <div>
                        <hr className="hl-long" />
                        <div>
                            <h5 className="lato-fs13-normal-lh18 tertiary-text-color-v2 mb-2">Work Experiences:</h5>
                            {userProfileData?.work_experiences?.experience_data.map((experience) => (
                                <div className="education-container" key={experience._id}>
                                    <span className="lato-fs17-normal-lh20 font-bold">{experience.job_role}</span><br/>
                                    <span className="lato-fs15-normal-lh20">{experience.organisation}</span>
                                    { 
                                        experience.start_date ? 
                                            <>
                                                <span>{` • `}</span>
                                                <span className="lato-fs15-normal-lh20">{formatYearAndMonth2(experience.start_date)}</span>
                                                <span className="lato-fs15-normal-lh20"> - { experience.end_date ? formatYearAndMonth2(experience.end_date) : "Present"}</span>
                                            </> 
                                            : null
                                    }
                                    {
                                        experience.remarks ?
                                            <p className='lato-fs16-normal-lh20 tertiary-text-color-v2 mt-2'>{experience.remarks}</p>
                                            : null
                                    }
                                    <hr className="left-aligned-hl"/>
                                </div>
                            ))}
                        </div>
                    </div> : null 
                }
                { 
                    !isEmpty(userProfileData.qualifications) ?
                        <div>
                            <hr className="hl-long" />
                            <div className="mb-4">
                                <h5 className="lato-fs13-normal-lh18 tertiary-text-color-v2 mb-2">Qualifications:</h5>
                                {userProfileData?.qualifications.map((qualification) => (
                                    <div className="qualification-container">
                                        <p className="mb-0 lato-fs17-normal-lh20 font-bold">{qualification.degree}</p>
                                        {
                                            qualification.year_of_passing ?
                                                <p className="lato-fs15-normal-lh20 mb-0">Passed out on {qualification.year_of_passing}</p>
                                                : null
                                        }
                                        <hr className="left-aligned-hl" />
                                    </div>
                                ))}
                            </div>
                        </div> 
                        : null
                }
            </div>
        )
    }

    return (
        <div>
            {
                !ownProps.editMode.inEdit ? 
                    <div className="d-flex justify-content-end" >
                        <IconButton 
                            onClick={() => ownProps.handleEditModeChange(true, 'userProfileEdit')} 
                        >
                            <EditIcon />
                        </IconButton>
                    </div>
                    : null
            }
            {
                !ownProps.editMode.inEdit ?
                    getProfileDetails()
                    : ownProps.editMode.component === "userProfileEdit" ?
                        getProfileDetailsForm()
                        : null
            }
        </div>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        updateProfileState : state.updateProfileData,
        ownProps : ownProps,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProfileData : (profileData) => dispatch(updateProfileData(profileData)),
        clearUpdateProfileStates : () => dispatch(clearUpdateProfileDataStates()),
        saveUpdatedProfileData : (profileData) => dispatch(saveProfileData(profileData))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserProfile)
