import React,{ useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'

import Image from '../../assets/images/philip.jpg'
import PublicNavbar from '../home-page/public-navbar/PublicNavbar'
import './about-us.css'
import Footer from '../../components/footer/Footer'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const AboutUs = () => {

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [ drawer, setDrawer ] = useState(false)

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleDrawer(){
        setDrawer( ps => ! ps )
    }

    return (
        <div className='d-flex vh-100 flex-column'>
            <PublicNavbar 
                variant={ isDesktop ? "" : "mobile" }
                logo={true} 
                enableConsult={true} 
                enableSubscribe={true} 
                enableProduct={true}
                // enableLogin={true}
                enableHome={true}
                enableDrawer={true}
                drawerOpened={drawer}
                handleDrawerClose={toggleDrawer}
                onDrawerClick={toggleDrawer}
                drawerHome={true}
            />
            <article>
                <section className='d-flex align-items-center position-relative mx-auto' style={{minHeight:"91vh",width:"86%"}}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12} className='d-flex align-items-center mx-auto justify-content-center position-relative'>
                            <div className='about-us-outer-image-container'></div>
                            <div className='position-absolute'>
                                <img src={Image} className='about-us-image' alt="" />
                            </div>
                        </Grid>
                        <Grid item md={6} xs={12} className='d-flex flex-column justify-content-center mb-5'>
                            <p className='lato-fs20-normal-lh28 mb-0 primary-text-color' style={{textAlign:"justify"}}>Philip Mathew is a HR solutions innovator and architect of the people management solution, JUST 4. This a masterly crafted solution offered along with a software application to enable implementation in practice. As a HR practitioner he has almost 30 years of work experience and was the Chief People Officer at a leading bank in India. He is currently an Independent Director on the board of a well-known bank. He has also taught as a guest faculty at MDI, Gurgaon. At present he is scouting for a pilot implementation opportunity before he can venture to launch the product on a commercial basis.</p>
                            <div className='mt-4'>
                                <CustomizeButton 
                                    variant="primary-color-button"
                                    label="Book Your Consultation"
                                    handleClick={() => history.push('/consult-us')}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </section>
            </article>
        </div>
    )
}

export default AboutUs
