import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik, Form, FieldArray, Field } from 'formik'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import qs from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'

import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import { fetchCourse } from '../../../services/learning.service'
import { requestDevelopmentAreaForSelect } from '../../../services/videoConference.service'
import { clearDevelopmentAreaState } from '../../../redux/video-conference/development/Development.action'
import { modifyAction } from '../../../services/notification.service'
import { mailActionChanges } from '../../../redux/notification/notification-detail/notificationDetail.action'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import UploadFiles from '../../../components/upload-files/UploadFiles'
import Header from '../../../common/header/Header'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'

const LEARNING_COURSE_ATTACHMENT_NAMES = {
    data : "action_data",
    reference : "action_course_content"
}

const ModifyLearning = (props) => {

    const { readCourseState, requestCourse, developmentAreaState, requestDevelopemntArea, notificationMailChanges } = props

    const history = useHistory()
    const referenceFileRef = useRef()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [ pageLoading, setPageLoading ] = useState({ circle : true, courseDetail : true})
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const courseDetail = readCourseState.response.course
    const learningCircle = developmentAreaState.response.learning_circles

    const initialValues = {
        learning_area : !isEmpty(courseDetail) && !isEmpty(learningCircle) && courseDetail.parent_learning_area ? findLearningArea() : "",
        name: !isEmpty(courseDetail) && courseDetail.name ? courseDetail.name : "",
        description: !isEmpty(courseDetail) && courseDetail.description ? courseDetail.description : "",
        course_content_links : !isEmpty(courseDetail) && courseDetail.course_content_links ? getLinks(courseDetail.course_content_links) : [""]
    }

    const onSubmit = (values) =>{
        if(navigator.onLine){
            modifyAction(queryParams.actionId, "course", addFormData(values))
            .then((res) => {
                notificationMailChanges()
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                history.goBack()
                history.goBack()
            }).catch((err) => {
                console.log(err)
                if(err){
                    if(err.response){
                        if(err.response.data){
                            toast.error(err.response.data.error, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    }
                }
            })
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    const validationSchema = Yup.object({
        learning_area : Yup.string().required("Please select a learning area"),
        name: Yup.string().required("Please enter the course name"),
        description: Yup.string(),
        course_content_links : Yup.array().when('name', {
            is: (value) => {
                if(!isEmpty(value)){
                    return isEmpty(existingReferences) || isEmpty(referenceFiles)
                } else {
                    return true
                }
            },
            then: Yup.array().of(Yup.string().required('Pick or fill at least one course content file or link').url('Invalid url')),
            otherwise : Yup.array().of(Yup.string().url('Invalid url'))
        })
    })

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.map((link) => (
            contentLink.push(link.url)
        ))
        return contentLink
    }

    const navbarOpen = Boolean(navbarMoreMenu);

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null);
    }

    useEffect(() => {
        if(isEmpty(readCourseState.response)){
            requestCourse(queryParams.course_id)
        }

        if(isEmpty(developmentAreaState.response)){
            requestDevelopemntArea()
        }
    },[])

    useEffect(() => {
        if(!readCourseState.loading && !isEmpty(readCourseState.response)){
            setExistingReferences( !isEmpty(readCourseState.response.course) && readCourseState.response.course.course_content_files ? 
                readCourseState.response.course.course_content_files : [""])
            setPageLoading(ps => {
                return {
                    ...ps,
                    courseDetail : false
                }
            })
        }

        if(!readCourseState.loading && !isEmpty(readCourseState.error)){
            console.log(readCourseState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    courseDetail : false
                }
            })
            if(readCourseState.error?.data){
                toast.error(readCourseState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[readCourseState])

    useEffect(() => {
        if(!developmentAreaState.loading && !isEmpty(developmentAreaState.response)){
            setPageLoading(ps => {
                return {
                    ...ps,
                    circle : false
                }
            })
        }

        if(!developmentAreaState.loading && !isEmpty(developmentAreaState.error)){
            console.log(developmentAreaState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    circle : false
                }
            })
            if(developmentAreaState.error?.data){
                toast.error(developmentAreaState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[developmentAreaState])

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index != i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index != i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }


    function addFormData(courseData){

        let courseDataCopy = JSON.parse(JSON.stringify(courseData))
        let courseFormData = new FormData()

        if(!isEmpty(existingReferences)){
            courseDataCopy.course_content_files = existingReferences.map(contentFile => {
                return contentFile._id
            })
        } else {
            courseDataCopy.course_content_files = []
        }

        courseFormData.append(LEARNING_COURSE_ATTACHMENT_NAMES.data, JSON.stringify(courseDataCopy))
        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                courseFormData.append(LEARNING_COURSE_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }
        return courseFormData
    }

    function foramttedOptions(array){
        let option = []
            for(let circle of array){
                let developmentAreaArray=[]
                for( let developmentArea of circle.learning_areas){
                    developmentAreaArray.push(<MenuItem sx={{fontSize:"15px",fontFamily:"'Lato',sans-serif",color:"#444444"}} value={developmentArea._id} key={developmentArea._id}>{developmentArea.name}</MenuItem>)
                }
                option.push(<ListSubheader sx={{fontSize:"13px",
                                                fontFamily:"'Lato',sans-serif",
                                                lineHeight:"30px",
                                                color:"#858585"}} key={circle._id} >{circle.name}</ListSubheader>,developmentAreaArray)
            }
        return option
    }

    function findLearningArea(){
        const appropriateLearningAreaDoc = getLearningAreaByName(learningCircle, courseDetail.parent_learning_area.name)
        if(!isEmpty(appropriateLearningAreaDoc)){
            return appropriateLearningAreaDoc._id
        } else {
            return ""
        }
    }

    function getLearningAreaByName(learningCircles, name){
        let appropriateLearningAreaDoc = {}
        exit_loops:
        for(const learningCircle of learningCircles){
            if(!isEmpty(learningCircle.learning_areas)){
                for(const learningArea of learningCircle.learning_areas){
                    if(learningArea.name === name){
                        appropriateLearningAreaDoc = learningArea
                        break exit_loops
                    }
                }
            }
        }
        return appropriateLearningAreaDoc
    }

    function getDetail(){
        return (
            <>
                <Formik 
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                        {props => {
                            const { values,touched,errors, handleChange, handleBlur } = props
                            return (
                                <>
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} sm={6}>
                                                <FormControl variant="outlined" fullWidth  >
                                                    <InputLabel htmlFor="grouped-select" className="input-label">Learning Area</InputLabel>
                                                    <Select id="grouped-select" 
                                                        label="Learning Area"
                                                        name="learning_area"
                                                        className="group-select"
                                                        onChange={handleChange} 
                                                        onBlur={handleBlur} 
                                                        value={values.learning_area}>
                                                        {foramttedOptions(learningCircle)}
                                                    </Select>
                                                </FormControl>
                                                { errors.learning_area && touched.learning_area ?
                                                    <div className="ms-1">
                                                        <span className="error-message">{errors.learning_area}</span>
                                                    </div> : null }
                                            </Grid>
                                            <Grid item md={6} xs={12} sm={6}>
                                                <InputBox type="text"
                                                    name="name"
                                                    label="Name"
                                                    placeholder="Enter the course name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.name && errors.name ? errors.name : ""}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className="mt-3">
                                            <TextAreaBox name ="description"
                                                placeholder="Enter the course description"
                                                label="Course Description"
                                                rows={ isDesktop ? 4 : "" }
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errormgs={touched.description && errors.description ? errors.description : ""}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <FieldArray name="course_content_links" >
                                                {fieldArrayProps => {
                                                    const { push, remove, form } = fieldArrayProps
                                                    const { values } = form
                                                    const { course_content_links } = values
                                                    return (
                                                        <div>
                                                            <Grid container>
                                                                {course_content_links.map((metric,index) => (
                                                                    <> 
                                                                        <Grid item  xs={index > 0 ? 11 : 12} md={ index > 0 ? 5 : 6} sx={{paddingRight :{xs:0 ,md: index/2 === 0 ? "10px": "0px"},marginBottom:"10px"}}>
                                                                            <Field name={`course_content_links[${index}]`}>
                                                                                {props => {
                                                                                    const {field,meta} = props
                                                                                    return (
                                                                                        <>
                                                                                            <InputBox type="url" 
                                                                                                label="Course Content Links"
                                                                                                placeholder="http://www.example.com"
                                                                                                {...field}
                                                                                                errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                            />
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            </Field>
                                                                        </Grid>
                                                                        {index > 0 ?
                                                                            <Grid item xs={1} sx={{display: "flex", alignItems:"center", justifyContent:"center",marginBottom:"10px"}}>
                                                                                <IconButton onClick={() => remove(index)} >
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </Grid> : null 
                                                                        }
                                                                    </>
                                                                ))}
                                                            </Grid>
                                                            <div className='mt-2'>
                                                                <CustomizeButton variant="secondary-start-icon-button" 
                                                                    StartIcon={<AddIcon/>} 
                                                                    handleClick={() => push('')} 
                                                                    label="Add Link" />
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </FieldArray>
                                        </div>
                                        <div>
                                            <div className='mt-3'>
                                                { !isEmpty(existingReferences) ? existingReferences.map((reference, index) => ( 
                                                        <div key={index} className="d-flex mb-2">
                                                            <p className="filename lato-fs15-normal-lh20 primary-text-color mb-0 one-line-wrap"> {reference.filename}</p>
                                                            <FontAwesomeIcon className=" ms-auto me-2 secondary-color" 
                                                                onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                        </div>
                                                    )
                                                ) : null }
                                                { !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? referenceFiles.files.map((file,index)=>(
                                                        <div key={index} className="mb-2 d-flex">
                                                            <p className="mb-0 one-line-wrap lato-fs15-normal-lh20 primary-text-color">{file.name}</p>
                                                            <FontAwesomeIcon className="ms-auto me-2 secondary-color" 
                                                                onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                        </div>
                                                )) : null}
                                            </div>
                                            <div className='mt-3'>
                                                <UploadFiles 
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        referenceFileRef.current.click();
                                                    }} 
                                                    label="Click to upload attachment"
                                                    variant={isDesktop ? "" : "mobile-view"}
                                                />
                                            </div>
                                        </div>
                                        <input type="file"
                                            style={{display:"none"}} 
                                            ref={referenceFileRef} 
                                            multiple
                                            accept="video/mp4,video/x-m4v,video/*,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain, application/pdf,image/png, image/jpeg, image/jpg,.mp3,audio/*,csv,.xlsx,.xls"
                                            name="course_reference"
                                            onChange={(event) =>{
                                                const file = event.target.files
                                                if(file){
                                                    handleReferenceFileAddition(file)
                                                }
                                        }} />
                                        <div className="d-flex justify-content-center align-center mt-4">
                                            <CustomizeButton variant="primary-color-button" type="submit" label="Modify Course" />
                                        </div>
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableLogout={true}
                            enableProbileButton={true}
                            enableMore={true}
                            moreRight="88px" 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer>
                                    {!pageLoading.circle && !pageLoading.courseDetail ? 
                                        <>
                                            <div className='mt-3'>
                                                <h2 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>Modify Course</h2>
                                            </div>
                                            <div className='mt-3'>
                                                {getDetail()}
                                            </div>
                                        </> : null 
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </> : 
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className="container">
                            <div className='mt-3 px-3'>
                                <Header heading="Modify Course" handleBackArrowEvent={handleBackArrowEvent} />
                            </div>
                            <div className="px-3 pb-70 mt-3">
                                {!pageLoading.circle && !pageLoading.courseDetail ? 
                                    <div>
                                        {getDetail()}
                                    </div>
                                : null}
                            </div>
                        </div>
                        <BottomNavigation />
                    </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        readCourseState : state.readCourse,
        developmentAreaState : state.developmentArea,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestCourse : (courseId) => dispatch(fetchCourse(courseId)),
        requestDevelopemntArea : () => dispatch(requestDevelopmentAreaForSelect()),
        clearDevelopmentArea : () => dispatch(clearDevelopmentAreaState()),
        notificationMailChanges : () => dispatch(mailActionChanges())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModifyLearning)