import { REQUEST_ADD_NOTE,
        ADD_NOTE_SUCCESS,
        ADD_NOTE_FAILURE, 
        CLEAR_ADD_NOTE_STATE } from './AddNote.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const addNoteReducer = ( state = initialState, action ) =>{
    switch(action.type){
        case REQUEST_ADD_NOTE :
            return {
                ...state,
                loading : true,
                error : null
            }

        case ADD_NOTE_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case ADD_NOTE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_ADD_NOTE_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default :
            return state
        
    }
}

export default addNoteReducer