import { REQUEST_GOAL_PROGRESS, 
         GOAL_PROGRESS_SUCCESS,  
         GOAL_PROGRESS_FAILURE,  
         CLEAR_GOAL_PROGRESS } from './GoalProgressTypes'

export const requestGoalProgress = () => {
    return {
        type : REQUEST_GOAL_PROGRESS
    }
}

export const successGoalProgress = (resData) => {
    return {
        type : GOAL_PROGRESS_SUCCESS,
        payload : resData
    }
}

export const failureGoalProgress = (error) => {
    return {
        type : GOAL_PROGRESS_FAILURE,
        payload : error
    }
}

export const clearGoalProgress = () => {
    return {
        type : CLEAR_GOAL_PROGRESS
    }
}