import { REQUEST_CULEARNING,
         SUCCESS_CULEARNING, 
         FAILURE_CULEARNING, 
         CLEAR_CULEARNING } from './CULearningTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const CULearningReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_CULEARNING: 
            return{
                ...state,
                loading : true,
                error : null
            }

        case SUCCESS_CULEARNING: 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case FAILURE_CULEARNING: 
            return {
                ...state,
                loading : false,
                error : action.payload  
            }

        case CLEAR_CULEARNING: 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        default : 
            return state
    }
}

export default CULearningReducer