import { REQUEST_REFERENCE, 
        REFERENCE_SUCCESS,
        REFERENCE_FAILURE,
        CLEAR_REFERENCE_CLEAR,
        INSERT_NOTES_IN_REFERENCE, 
        REMOVE_REFERENCE} from './Reference.types' 

export const requestReferences = () => {
    return {
        type : REQUEST_REFERENCE
    }
}

export const referencesSuccess = (resData) => {
    return {
        type : REFERENCE_SUCCESS,
        payload : resData
    }
}

export const referenceFailure = (error) => {
    return {
        type : REFERENCE_FAILURE,
        payload : error
    }
}

export const clearReferenceState = () => {
    return {
        type : CLEAR_REFERENCE_CLEAR
    }
}

export const insertNoteInReference = (payload) => {
    return {
        type : INSERT_NOTES_IN_REFERENCE,
        payload : payload
    }
}

export const removeReferenceAtIndex = (groupIndex, referenceIndex, referenceType) => {
    return {
        type : REMOVE_REFERENCE,
        payload : { 
            groupIndex,
            referenceIndex,
            referenceType
        }
    }
}