import { isEmpty, toArray } from 'lodash'
import React,{ useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useMediaQuery from '@mui/material/useMediaQuery'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import './metric-details.css'
import { deleteMetric, deleteMetricPromise, specificMetric } from '../../../services/metric.service'
import { clearFetchMetrics, fetchMetricSuccess } from '../../../redux/fetch-metric/FetchMetricAction'
import ReferenceFiles from '../../../components/reference-files/ReferenceFiles'
import PieChart from '../../../components/chart/pie-chart/PieChart'
import DoughnutChart from '../../../components/chart/doughnut-chart/DoughnutChart'
import LineChart from '../../../components/chart/line-chart/LineChart'
import BarChart from '../../../components/chart/bar-chart/BarChart'
import DeletePopUp from '../../../components/delete-popup/DeletePopUp'
import { fetchMetrics } from '../../../services/fetchMetric.service'
import GanttChart from '../../../components/chart/gantt-chart/GanttChart'
import Container from '../../../components/desktop-layout/container/Container'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../../components/desktop-layout/description/Description'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import GoalIcon from '../../../assets/icons/active-bullseye.svg'
import Header from '../../../common/header/Header'
import ProcessWallpaperIcon from '../../../components/desktop-layout/process-wallpaper-icon/ProcessWallpaperIcon'
import Name from '../../../components/name/Name'

const MetricDetails = (props) => {

    const { specificMetricState,
            requestSpecificMetric,
            requestMetric } = props

    const params = useParams()
    const history = useHistory()
    const location = useLocation()

    const action = location.pathname.split('/')[3]
    const goal = specificMetricState.response
    const isDesktop = useMediaQuery('(min-width:992px)')
    const isMobile = useMediaQuery('(max-width:480px)')

    const [pageLoading,setPageLoading] = useState(true)
    const [ menu, setMenu ] = useState(null)
    const [ deletePopup, setDeletePopup ] = useState(false)
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const open = Boolean(menu)

    const handleClick = (event) => {
        setMenu(event.currentTarget)
    }

    const handleClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const toggleDeletePopup = () => {
        setDeletePopup(ps => !ps)
    }

    function handleEditTrigger(){
        history.push(`/metric/edit?metric_id=${params.metricId}`)
    }

    function handleAddClickEvent(){
        history.push('/metric/create')
    }

    function handleDeleteTrigger(){
        deleteMetricPromise(params.metricId)
        .then(res => {
            requestMetric()
            history.push('/metric')
        }).catch(err => {
            console.log(err)
            if(err.response?.data){
                toast.error(err.response.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        })
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    useEffect(()=> {
        if(!specificMetricState.loading && !isEmpty(specificMetricState.response)){
            // console.log(specificMetricState.response)
            setPageLoading(false)
        }
        if(!specificMetricState.loading && !isEmpty(specificMetricState.error)){
            console.log(specificMetricState.error)
            setPageLoading(false)
            if(specificMetricState.error?.data){
                toast.error(specificMetricState.error.data.error,{
                    position:"bottom-right",
                    autoClose:3000,
                    hideProgressBar:false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        }
    },[specificMetricState])

    useEffect(() => {
        if(isEmpty(specificMetricState.response)){
            requestSpecificMetric(params.metricId)
        }
    },[])

    function graphType(graph, objectives){
        switch(graph){
            case "Bar chart" :
                return (
                    <BarChart variant="expanded-graph" graphDetail={objectives.graph.data} />
                )

            case "Gantt chart" :
                return (
                    <GanttChart variant="expanded-graph" graphDetail={objectives.graph.data} />
                )
            
            case "Pie chart" :
                return (
                    <PieChart variant="expanded-graph" graphDetail={objectives.graph.data} />
                )

            case "Doughnut chart":
                return (
                    <DoughnutChart variant="expanded-graph" graphDetail={objectives.graph.data} />
                )

            default : 
                return (
                    <LineChart variant="expanded-graph" graphDetail={objectives.graph.data}/>
                )
        }
    }

    function handleNotification(){
        history.push(`/notification-reference?selected=notification&tab=inbox&action=metrics`)
    }

    function previewGraphType(graph, graphDetail){
        switch(graph){
            case "Gantt chart":
                return (
                    <div className={isDesktop ? 'me-4 pt-4' : "me-4 py-2"}>
                        <GanttChart graphDetail={graphDetail?.graph?.data}/>
                    </div>
                )

            case "Bar chart":
                return (
                    <BarChart graphData={graphDetail.graph.data} />
                )
            
            case "Pie chart": 
                return (
                    <PieChart graphDetail={graphDetail.graph.data} />
                )

            case "Doughnut chart":
                return (
                    <DoughnutChart graphDetail={graphDetail.graph.data} />
                )

            default : 
                return (
                    <LineChart graphData={graphDetail.graph.data}/>
                )
        }
    }


    function getDetail(){
        return (
            <>
                {
                    action !== 'expanded-graph' && isDesktop ?
                        <div>
                            <Header 
                                platform="desktop"
                                verticalEllipsisEvent={handleClick}
                                handleClose={handleClose}
                                menu={menu}
                                open={open}
                                editEvent={handleEditTrigger}
                                deleteEvent={toggleDeletePopup}
                                start={
                                    <>
                                        <BreadCrumbs
                                            path={toArray(goal.goal?.objective_id?.path)}
                                            activeId={goal.goal?.objective_id?._id}
                                            icon={faThLarge}
                                            truncate={isMobile} />
                                        <div>
                                            <BreadCrumbs 
                                                path={[{name : goal.goal?.name,_id : goal.goal?._id }]}
                                                img={GoalIcon} 
                                                activeId={goal.goal?._id} />
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    :null
                }
                {
                    action !== 'expanded-graph' ?
                        !isDesktop ?
                            <div className='mt-2'>
                                <div>
                                    <BreadCrumbs
                                            path={toArray(goal.goal?.objective_id?.path)}
                                            activeId={goal.goal?.objective_id?._id}
                                            icon={faThLarge}
                                            truncate={isMobile} />
                                    <div>
                                        <BreadCrumbs 
                                            path={[{name : goal.goal?.name,_id : goal.goal?._id }]}
                                            img={GoalIcon} 
                                            activeId={goal.goal?._id} />
                                    </div>
                                </div>
                            </div> 
                        : null
                        : isDesktop ?
                            <h3 className='lato-fs22-normal-lh32 font-bold'>
                                {goal.metric?.name}
                            </h3>
                            : null
                }
                <div className='pt-3'>
                    { 
                        action !== 'expanded-graph' ?
                            <ProcessWallpaperIcon 
                                variant="metric"
                                containerProps={{
                                    style: {height: isDesktop ? "250px" : isMobile ? '165px' : '200px', maxWidth:"560px" }
                                }}
                            >
                                { 
                                    !isEmpty(goal?.graph?.data) ?
                                        <div 
                                            className='ms-auto max-width-500px me-auto h-100'
                                            style={{aspectRatio: goal.graph_type === 'Pie chart' || goal.graph_type === 'Doughnut chart' ? '1/1' : '9/5'}}
                                        >
                                            {previewGraphType(goal.graph_type, goal)}
                                        </div>
                                        : <div className='h-100 w-100 d-flex align-items-center justify-content-center lato-fs-20-normal-lh32'>
                                            No graph data available
                                        </div>
                                }
                            </ProcessWallpaperIcon>
                            : null
                    }
                    { 
                        action === 'expanded-graph' ? 
                            <div className='mt-3'>
                                {
                                    !isEmpty(goal.graph?.data) ? 
                                        graphType(goal.graph_type, goal)
                                    : <div>
                                        <p className="lato-fs18-normal-lh22 primary-text-color mb-0">No graph data available</p>
                                    </div>
                                }
                            </div>
                            :
                            <>
                                {
                                    goal.metric.name ?
                                        <div className='mt-3'>
                                            <Name name={goal?.metric?.name} />
                                        </div>
                                    : null
                                }
                                {
                                    !isEmpty(goal.metric_description) ? 
                                        <div className='mt-2'>
                                            <Description description={goal.metric_description} />
                                        </div> 
                                        : null
                                }
                                {
                                    !isEmpty(goal.reference_files) || !isEmpty(goal.reference_links) ?
                                        <div className='mt-5'>
                                            <ReferenceFiles referenceFiles={goal.reference_files} referenceLinks={goal.reference_links} />
                                        </div> 
                                        : null 
                                }
                            </>
                    }
                </div>
            </>
        )
    }

    return (
        <Container>
            {
                isDesktop ? 
                    <>
                        <Navbar enableMore={true} 
                            enableProfileButton={true}
                            enableLogout={true} 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen}  
                            enableNotification={true}
                            notificationOnClick={handleNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar addIcon={true} addIconTooltip="Create Metric" onAddIconClick={handleAddClickEvent} />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="py-4">
                                    { !pageLoading && !isEmpty(goal) ? 
                                        <>
                                            {getDetail()}
                                        </> : null}
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    : <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer} 
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={handleNotification}
                        />
                        <div className="container pb-60px">
                            {
                                !pageLoading && !isEmpty(goal) ? 
                                    <>
                                        <div className='mt-3 px-3'>
                                            <Header handleBackArrowEvent={handleBackArrowEvent} 
                                                heading={action === 'expanded-graph' ? goal.metric?.name : ''}
                                                moreIcon={action !== 'expanded-graph' ? true : false}
                                                moreIconEvent={handleClick}
                                                open={open}
                                                menu={menu}
                                                handleClose={handleClose}
                                                handleEditEvent={handleEditTrigger}
                                                handleDeleteEvent={toggleDeletePopup}
                                            />
                                        </div>
                                        <div className='px-3'>
                                            {getDetail()}
                                        </div>
                                    </> 
                                    :null
                            }
                        </div>
                        <BottomNavigation onAddIconClick={handleAddClickEvent} enableAddIcon={true} />
                    </>
            }
            { 
                deletePopup && 
                    <DeletePopUp  
                        msg={`${goal.metric?.name} will be deleted permanently. Are you sure?`} 
                        handleClose={toggleDeletePopup} 
                        onClick={handleDeleteTrigger} 
                        open={deletePopup} 
                    />
            }
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        specificMetricState : state.specificMetric
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        deleteMetric : (metricId) => dispatch(deleteMetric(metricId)),
        clearMetricState : () => dispatch(clearFetchMetrics()),
        saveFetchMetric : (metricDetails) => dispatch(fetchMetricSuccess(metricDetails)),
        requestSpecificMetric : (graphId) => dispatch(specificMetric(graphId)),
        requestMetric : () => dispatch(fetchMetrics())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MetricDetails)