import { REQUEST_SPECIFIC_GOALS, 
         SPECIFIC_GOAL_SUCCESS,
         SPECIFIC_GOAL_FAILURE, 
         CLEAR_SPECIFIC_GOALS_STATES } from './specificGoal.type'

const initialState = {
    loading: false,
    response: {},
    error: null
}

const specificGoalReducer = (state = initialState ,action) => {

    switch(action.type){
        case REQUEST_SPECIFIC_GOALS : 
            return {
                ...state,
                loading: true,
                error : null
            }

        case SPECIFIC_GOAL_SUCCESS : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case SPECIFIC_GOAL_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_SPECIFIC_GOALS_STATES : 
            return {
                loading: false,
                response : {}, 
                error: null
            }

        default : 
            return state
    }
}

export default specificGoalReducer