import React from 'react'
import './popup.css'
import Dialog from '@mui/material/Dialog'

const PopUp = (props) => {

    return (
        <> 
            <Dialog onClose={props.handleClose} open={props.open} maxWidth={props.maxWidth} fullWidth={props.fullWidth}>
                {props.children}
            </Dialog>
        </>
    )
}

export default PopUp
