import React, { useEffect, useState } from 'react'
import { isEmpty, toArray } from 'lodash'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField'

import SelectBox from '../../common/select-box/SelectBox'
import InputBox from '../../common/Input-box/InputBox'
import PasswordInput from '../../common/password-input/PasswordInput'
import { individualSignup, updateSubscription } from '../../services/IndividualAuthentication.service'
import { clearAuthStates } from '../../redux/authentication/AuthenticationAction'
import TextAreaBox from '../../common/textarea-box/TextAreaBox'
import DatePicker from '../../common/date-picker/DatePicker'
import { storeUserCredentials } from '../../utils/utils'
import SelectWithGroupedOption from '../../common/select-with-grouped-option/SelectWithGroupedOption'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const IndividualSignUp = (props) => {

    const { authenticateIndividual, requestSignUpState, clearAuthenticationState, ownProps} = props;

    const [showPassword, setShowPassword] = useState(false)

    const history = useHistory()

    const initialValues = {
        first_name:"",
        middle_name:"",
        last_name:"",
        email:"",
        dob:"",
        job_role:"",
        job_title:"",
        industry:"",
        address :{
            door_no : "",
            area : "",
            city:"",
            state:"",
            pincode:"",
            country: "",
        },
        highest_qualification:"",
        organisation:"",
        total_work_experience:"",
        password:"",
    }

    const validationSchema = Yup.object({
        first_name : Yup.string()
                        .required("Please enter your first name")
                        .matches(/^[A-Za-z ]*$/, "Invalid first name")
                        .min(2,"Too short")
                        .max(40, "Too Long"),
        middle_name: Yup.string(),
        last_name : Yup.string()
                    .required("Please enter your last name")
                    .matches(/^[A-Za-z ]*$/, "Invalid last name")
                    .min(2,"Too short")
                    .max(40, "Too Long"),
        email: Yup.string().required("Please enter your email").email("Invalid email"),
        dob: Yup.string().required("Please enter your date of birth"),
        job_role: Yup.string().required("Please enter your job role"),
        job_title: Yup.string()
                        .max(40, "Job title can't be this long"),
        address : Yup.object().shape({
            door_no: Yup.string()
                    .min(0,"Too short")
                    .max(50,"Too long"),
            area: Yup.string(),
            city: Yup.string().required("Please enter your city"),
            state: Yup.string().required("Please enter your State/Province/Region"),
            country: Yup.string().required("Please enter your Country"),
            pincode : Yup.string().required('Please enter your ZIP/Postal Code')
        }),
        organisation : Yup.string()
                            .max(100, "Imvalid organisation name"),
        total_work_experience: Yup.number().required("Please enter the work_experience"),
        password : Yup.string()
                    .required('Please enter your password'),
    })

    const handleClickShowPassword = () => {
        setShowPassword(ps => !ps)
    };

    const onSubmit = (values) => {
        const valuesCopy = {...values}
        valuesCopy.dob = moment(values.dob).toISOString()
        valuesCopy.plan_id = props.plans[0]._id
        if(navigator.onLine){
            authenticateIndividual(valuesCopy)
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit,
        validationSchema
    })

    function createOptionForIndustry(industries){
        let options = []
        for( let industry of industries){
            let subIndustry=[]
            for( let subIndustries of industry.subIndustries){
                subIndustry.push(<MenuItem value={subIndustries.industryName}>{subIndustries.industryName}</MenuItem>)
            }
            options.push(<ListSubheader>{industry.industry.industryName}</ListSubheader>,subIndustry)
        }
        return options
    }

    //razor pay integration
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(payload) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            toast.error("Razorpay SDK failed to load. Are you online?", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        // const response = authenticateIndividual(data)

        // if(!response){
        //     toast.error("Server error. Are you online?", {
        //         position: "bottom-right",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     return ;
        // }

        const options = {
            key: payload.key,
            order_id: payload.order.id ,
            name: "HR App",
            amount: payload.order?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: payload.order?.currency,
            // description: "Monthly Test Plan",
            // image: "/your_logo.png",
            // payment response
            handler: function(res) {
                updateSubscription(payload.order.id, res, payload.token)
                .then((res) => {
                    storeUserCredentials({
                        token : payload.token,
                        role :  payload.role,
                        userId : payload._id
                    })
                    history.push("/vcf?mode=list-vcfs-by-lvl&lvl=0")
                    clearAuthenticationState()
                })
            },
            prefill: {
                name: `${formik.values.first_name} ${formik.values.last_name}`,
                email: formik.values.email,
                contact: ""
            },
            // notes: {
            //     note_key_1: "Tea. Earl Grey. Hot",
            //     note_key_2: "Make it so."
            // },
            theme: {
                color: "#14213d"
            }
        }

        const paymentObject = new window.Razorpay(options);
        // payment error
        // paymentObject.on("payment.failed",function(error){
        //     console.log("error",error)
        // })
        paymentObject.open();
    }

    useEffect(() => {
        if(!isEmpty(requestSignUpState.response)){
            displayRazorpay(requestSignUpState.response)
            // history.push("/objective?mode=list-vcfs-by-lvl&lvl=0")
            // clearAuthenticationState()
        }

        if(!isEmpty(requestSignUpState.error)){
            // console.log(requestSignUpState.error.data.error)
            toast.error(requestSignUpState.error.data.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    },[requestSignUpState])

    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="row">
                    <div className="col-md-4">
                        <div className="mt-3">
                        <InputBox type="text"
                            name="first_name" 
                            label="First Name"
                            required={true}
                            placeholder="Enter your first name" 
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMgs={formik.touched.first_name && formik.errors.first_name ? formik.errors.first_name : ""} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-3">
                        <InputBox type="text" 
                            name="middle_name" 
                            label="Middle Name"
                            placeholder="Enter your middle name"
                            value={formik.values.middle_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMgs={formik.touched.middle_name && formik.errors.middle_name ? formik.errors.middle_name : ""} />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-3">
                        <InputBox type="text" 
                            name="last_name" 
                            label="Last Name"
                            required={true}
                            placeholder="Enter your last name" 
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMgs={formik.touched.last_name && formik.errors.last_name ? formik.errors.last_name : ""}  />
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="mt-3">
                        <InputBox type="email" 
                            name="email" 
                            label="Email Id"
                            required={true}
                            placeholder="Enter your email id" 
                            value={formik.values.email}
                            onChange = {formik.handleChange} 
                            onBlur={formik.handleBlur}
                            errorMgs={formik.touched.email && formik.errors.email ? formik.errors.email : ""} 
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-3">
                            <PasswordInput 
                                label="Password *"
                                name="password" 
                                placeholder="Enter your password"
                                showPassword={showPassword}
                                handleClickShowPassword={handleClickShowPassword}
                                value={formik.values.password} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.password && formik.errors.password ? formik.errors.password : ""} />
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="mt-4">
                        <DatePicker type="date" 
                            name="dob" 
                            label="Date of Birth"
                            required={true}
                            value={formik.values.dob}
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            errorMgs={formik.touched.dob && formik.errors.dob ? formik.errors.dob : ""} 
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-4">
                            <SelectWithGroupedOption
                                selectProps={{
                                    id: "grouped-select",
                                    name: "industry",
                                    defaultValue: "",
                                    value: formik.values.industry,
                                    onChange: formik.handleChange,
                                    onBlur: formik.handleBlur
                                }}
                                label="Industry"
                                options={createOptionForIndustry(ownProps.constantData?.industries)}
                            />
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-6">
                        <div className="mt-4">
                            <InputBox type="text" 
                                label="Job Role"
                                name="job_role" 
                                required={true}
                                placeholder="Enter your job role"
                                value={formik.values.job_role} 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.job_role && formik.errors.job_role ? formik.errors.job_role : ""} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mt-4">
                            <InputBox type="text" 
                                name="job_title" 
                                label="Job Title"
                                required={true}
                                placeholder="Enter the job title" 
                                value={formik.values.job_title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.job_title && formik.errors.job_title ? formik.errors.job_title : ""}  />
                        </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* <h2 className="fs20-lh32 mb-0 mt-2 col"> Address : </h2> */}
                        <div className="col-md-12">
                            <div className="mt-3">
                                <InputBox type="text" 
                                    name="address.door_no"
                                    label="Address Line 1"
                                    placeholder="Enter Apartment, suite, unit, building, floor, etc.,"
                                    value={formik.values.address.door_no}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.address && formik.touched.address.door_no && formik.errors.address && formik.errors.address.door_no ? formik.errors.address.door_no : "" }
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-4">
                                <TextAreaBox name="address.area"
                                    label="Address Line 2"
                                    placeholder="Enter Street address, P.O. box, company name, c/o"
                                    value={formik.values.address.area}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errormgs={ formik.touched.address && formik.touched.address.area && formik.errors.address && formik.errors.address.area ? formik.errors.address.area : "" }
                                    />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <InputBox type="text" 
                                    name="address.city"
                                    label="City"
                                    placeholder="Enter city"
                                    required={true}
                                    value={formik.values.address.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.address && formik.touched.address.city && formik.errors.address && formik.errors.address.city ? formik.errors.address.city : ""} 
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <InputBox 
                                    name="address.state"
                                    type="text"
                                    label="State/Province/Region"
                                    placeholder="Please enter State/Province/Region"
                                    required={true}
                                    value={formik.values.address.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.address && formik.touched.address.state && formik.errors.address && formik.errors.address.state ? formik.errors.address.state : ""}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <InputBox type="text" 
                                    name="address.pincode"
                                    label="ZIP/Postal Code"
                                    required={true}
                                    placeholder="Enter ZIP/Postal Code"
                                    value={formik.values.address.pincode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.address && formik.touched.address.pincode && formik.errors.address && formik.errors.address.pincode ? formik.errors.address.pincode : ""} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <SelectBox options={ownProps.constantData.countries} name="address.country"
                                    placeholder="Select your country"
                                    label="Country"
                                    required={true}
                                    value={formik.values.address.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.address && formik.touched.address.country && formik.errors.address && formik.errors.address.country ? formik.errors.address.country : ""}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <SelectBox options={ownProps.constantData.highestQualifications} name="highest_qualification" 
                                    placeholder="Select the higest qualification"
                                    label="Highest Qualification"
                                    value={formik.values.highest_qualification}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.highest_qualification && formik.errors.highest_qualification ? formik.errors.highest_qualification : ""} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-4">
                                <InputBox type="text" 
                                    name="organisation"
                                    label="Organisation"
                                    placeholder="Enter your organisation" 
                                    values={formik.values.organisation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.organisation && formik.errors.organisation ?  formik.errors.organisation : ""}/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mt-4 max-width-385px">
                                <InputBox type="number" 
                                    name="total_work_experience" 
                                    label="Work Experience Years"
                                    required={true}
                                    placeholder="Enter your Experience" 
                                    onWheel={ event => event.target.blur() }
                                    value={formik.values.total_work_experience}
                                    onChange={formik.handleChange}onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.total_work_experience && formik.errors.total_work_experience ? formik.errors.total_work_experience : ""} />
                            </div> 
                        </div>
                </div>
                {
                    !isEmpty(props.plans) && !isEmpty(props.plans[0])?
                        <div className="card plan-card mt-3" style={{maxWidth: 'fit-content'}} >
                            <h3 className="lato-fs18-normal-lh22 primary-color mb-0 pb-1">{props.plans[0].plan_info?.item?.name}</h3>
                            <h4 className="lato-fs20-normal-lh28 primary-color pb-1 mb-0 font-bold">{props.plans[0].plan_info?.item?.currency === "INR" ? "₹" : "$" }{props.plans[0].plan_info?.item?.amount}/{props.plans[0].plan_info?.period}</h4>
                            <p className="secondary-color lato-fs15-normal-lh20 mb-0">{props.plans[0].plan_info?.item?.description}</p>
                            {props.plans[0].plan_info.notes ?
                                <>
                                    <span className="lato-fs13-normal-lh18 font-bold">Note</span>
                                    {toArray(props.plans[0].plan_info.notes).map((note,index) => (
                                        <span className="fs12-lh15 primary-color" key={index}>{note}</span>
                                    ))}
                                </> : null}
                        </div> : ""
                    }
                <div className="button-container">
                    <CustomizeButton variant="primary-color-button" type="submit" label="Proceed"/>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        requestSignUpState : state.individualAuthentication,
        ownProps : ownProps
    }
}

const mapDispatchToProps = dispatch => {
    return {
        authenticateIndividual : (signUpDetail) => dispatch(individualSignup(signUpDetail)),
        clearAuthenticationState : () => dispatch(clearAuthStates())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(IndividualSignUp)