import {isEmpty} from 'lodash'

export const save = (prevState, payload) => {
    if(!isEmpty(prevState)){
        const appropriateObj = prevState.find(obj => obj.id === payload.id)
        if(!isEmpty(appropriateObj)){
            const filteredList = prevState.filter(obj => obj.id !== payload.id)
            if(!isEmpty(filteredList)){
                return [...filteredList, payload]
            } else {
                return [payload]
            }
        } else {
            return [...prevState, payload]
        }
    } else {
        return [payload]
    }
}

export const remove = (prevState, id) => {
    if(!isEmpty(prevState)){
        return prevState.filter(obj => obj.id !== id)
    } else {
        return prevState
    }
}