import { REQUEST_INVITES, 
         INVITES_SUCCESS, 
         INVITES_FAILURE , 
         CLEAR_INVITES_STATE } from './Invites.types'

export const requestInvite = () => {
    return {
        type : REQUEST_INVITES
    }
}

export const invitesSuccess = (resData) => {
    return {
        type : INVITES_SUCCESS,
        payload : resData
    }
}

export const invitesFailure = (error) => {
    return {
        type : INVITES_FAILURE,
        payload : error
    }
}

export const clearInvitesState = () => {
    return {
        type : CLEAR_INVITES_STATE
    }
}