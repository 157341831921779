import React from 'react'

const Name = (props) => {
    return (
        <>
            <h2 className='mb-0 fs20-lh32 primary-text-color' style={{wordBreak:"break-word"}}>{props.name}</h2>
        </>
    )
}

export default Name