import React from 'react'

const Draft = (props) => {
    return (
        <div className="d-flex align-items-center flex-column pt-4 pb-70">
            <h6 className="lato-fs20-normal-lh28 mb-0 tertiary-text-color-v2">No saved mails</h6>
            <p className='lato-fs13-normal-lh18 mt-2 tertiary-text-color-v2'>Under development</p>
        </div>
    )
}

export default Draft
