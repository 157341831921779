import { requestFetchGoals, 
        successFetchGoals, 
        failureFetchGoals } from '../redux/fetch-goals/FetchGoalsAction'
import ProtectedWebClient from '../utils/protected-web-client'

export const fetchGoals = () => {
    return async (dispatch) => {
        try {
            dispatch(requestFetchGoals())
            const response = await ProtectedWebClient.post("/goal/aggregate",{})
            dispatch(successFetchGoals(response.data))
        }catch(error){
            dispatch(failureFetchGoals(error.response))
        }
    }
}