import { REQUEST_TEAM_MEMBERS,
        SAVE_TEAM_MEMBERS,
        TEAM_MEMBERS_FAILURE,
        CLEAR_TEAM_MEMBER_STATE } from './teamMember.types'

export const requestTeamMembers = () => {
    return {
        type : REQUEST_TEAM_MEMBERS
    }
}

export const fetchTeamMemberSuccess = (response) => {
    return {
        type : SAVE_TEAM_MEMBERS,
        payload : response
    }
}

export const fetchTeamMemberFailure = (error) => {
    return {
        type : TEAM_MEMBERS_FAILURE,
        payload : error
    }
}

export const clearTeamMemberState = () => {
    return {
        type : CLEAR_TEAM_MEMBER_STATE
    }
}