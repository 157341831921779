import * as Yup from 'yup'
import {isEmpty} from 'lodash'
import moment from 'moment'
import { checkObjectiveDuplicationPromise } from '../../../services/fetchObjectives.service'
import { objectiveDateValidation } from '../../../services/objectives.service'

export const createObjectiveValidationSchema = (objective) => {
    return Yup.object({
        name: Yup.string().required("Please enter objective name")
                            .test("name-duplication","Already exists", (name) => {
                                if(name){
                                    return new Promise((resolve, reject) => {
                                        checkObjectiveDuplicationPromise({level : !isEmpty(objective) ? parseInt(objective.level)+1 : 0 , name : name}).then(res => {
                                            resolve(!res.already_present)
                                        }).catch(err => {
                                            console.log(err)
                                            resolve(true)
                                        })
                                    })
                                } else {
                                    return true
                                }
                            }),
        description: Yup.string(),
        start_date: Yup.date().required("Please select start date"),
                    // .test("check range","Start date is not in range",(value) => {
                    //     if(!isEmpty(objective)){
                    //         return moment(value).isBetween(objective.start_date, objective.end_date, undefined, '[)');
                    //     }else{
                    //         return true
                    //     }
                    // }),
        end_date:Yup.date().required("Please select end date")
                .min(Yup.ref('start_date'),"End date can't be before Start date")
                .test('check for past dates', "End date can't be in past", (value) => {
                    if(value){
                        return (moment(value).endOf("day")).isSameOrAfter(moment())
                    } else {
                        return false
                    }
                }),
                // .test({
                //     name: "same",
                //     exclusive: false,
                //     params: {},
                //     message: "End date cannot be same as the start date",
                //     test: function(value) {
                //     const startDate = moment(this.parent.start_date).format("YYYY-MM-DD")
                //     const endDate = moment(value).format("YYYY-MM-DD")
                //     return !moment(startDate).isSame(moment(endDate))
                //     },
                // })
                // .test("check range","End date is not in range", (value) => {
                //     if(!isEmpty(objective)){
                //         return moment(value).isBetween(objective.start_date, objective.end_date, undefined, '(]');
                //     } else {
                //         return true
                //     }
                // }),
        reference_links : Yup.array().of(
            Yup.object().shape({
                originalname : Yup.string(),
                url : Yup.string().when("originalname", {
                    is: (value) => !isEmpty(value),
                    then: Yup.string().required("Please enter the url").url("Invalid url"),
                    otherwise : Yup.string().url("Invalid url")
                })
            })
        ),
    })
}

export const editObjectiveValidationSchema = (objective) => {
    return Yup.object({
        name: Yup.string().required("Please enter objective name")
                            .test("name-duplication","Already exists", async (name) => {
                                if(name){
                                    return new Promise((resolve, reject) => {
                                        checkObjectiveDuplicationPromise({
                                            level : !isEmpty(objective) ? parseInt(objective.level) : 0 ,
                                            name : name,
                                            exceptions : [ `${objective._id}` ]
                                        }).then(res => {
                                            resolve(!res.already_present)
                                        }).catch(err => {
                                            console.log(err)
                                            resolve(true)
                                        })
                                    })
                                } else {
                                    return true
                                }
                            }),
        description: Yup.string(),
        start_date: Yup.date().required("Please select start date")
                    // .test("check range","Start date is not in range",(value) => {
                    //     if(!isEmpty(objective) && parseInt(objective.level)> 0){
                    //         return moment(value).isBetween(objective.start_date, objective.end_date, undefined, '[)');
                    //     }else{
                    //         return true
                    //     }
                    // })
                    .test("validate date","Invalid date", async(value) => {
                        if(value){
                            const startDate = (moment(value).startOf("day")).toISOString()
                            return new Promise((resolve, reject) => {
                                objectiveDateValidation(objective._id, "start_date", startDate).then(res => {
                                    resolve(res.data.valid)
                                }).catch(err => {
                                    console.log(err)
                                    resolve(true)
                                })
                            })
                        }else{
                            return true
                        }
                        
                    }),
        end_date:Yup.date().required("Please select end date")
                .min(Yup.ref('start_date'),"End date can't be before Start date")
                .test('check for past dates', "End date can't be in past", (value) => {
                    if(value){
                        return (moment(value).endOf("day")).isSameOrAfter(moment())
                    } else {
                        return false
                    }
                })
                // .test({
                //     name: "same",
                //     exclusive: false,
                //     params: {},
                //     message: "End date cannot be same as the start date",
                //     test: function(value) {
                //     const startDate = moment(this.parent.start_date).format("YYYY-MM-DD")
                //     const endDate = moment(value).format("YYYY-MM-DD")
                //     return !moment(startDate).isSame(moment(endDate))
                //     },
                // })
                // .test("check range","End date is not in range", (value) => {
                //     if(!isEmpty(objective) && parseInt(objective.level)> 0){
                //         return moment(value).isBetween(objective.start_date, objective.end_date, undefined, '(]');
                //     } else {
                //         return true
                //     }
                // })
                .test("validate date","Invalid date", async(value) => {
                    if(value){
                        const endDate = (moment(value).endOf('day')).toISOString()
                        return new Promise((resolve, reject) => {
                            objectiveDateValidation(objective._id,"end_date",endDate).then(res => {
                                resolve(res.data.valid)
                            }).catch(err => {
                                console.log(err)
                                resolve(true)
                            })
                        })
                    }else{
                        return true
                    }
                }),
        reference_links : Yup.array().of(
            Yup.object().shape({
                originalname : Yup.string(),
                url : Yup.string().when("originalname", {
                    is: (value) => !isEmpty(value),
                    then: Yup.string().required("Please enter the url").url("Invalid url"),
                    otherwise : Yup.string().url("Invalid url")
                })
            })
        ),
    })
}