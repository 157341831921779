import React,{ useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import Chip from '@mui/material/Chip'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/styles'
import Box from '@mui/material/Box'

import { getEmailSuggestion } from '../../../services/share.service'
import DatePicker from '../../../common/date-picker/DatePicker'
import VideoConferenceAboutPopup from '../../../components/video-conference-about-popup/VideoConferenceAboutPopup'
import { createVideoConference } from '../../../services/videoConference.service'
import './create-video-conference.css'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import { clearObjectiveState } from '../../../redux/video-conference/objective/Objective.action'
import { clearGoalSelectState } from '../../../redux/goal-select/goalSelect.action'
import { clearMetricsForSelectState } from '../../../redux/video-conference/metric/Metric.action'
import { clearDevelopmentAreaState } from '../../../redux/video-conference/development/Development.action'
import { clearCoursesState } from '../../../redux/video-conference/course/Course.action'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import Header from '../../../common/header/Header'
import useDebounce from '../../../hooks/useDebounce'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'

const CssTextField = styled(TextField)({
    "& label" : {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
    },
    // input label when focused
    "& label.Mui-focused": {
        color: "var(--primary-color-v2) !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        fontFamily: "'Lato',sans-serif",
        fontSize: "15px",
        "&.Mui-focused fieldset": {
            borderColor: "var(--primary-color-v2) !important"
        }
    }
})

const StyledChip = styled(Chip)({
    fontSize: 14,
    padding: '6px 12px',
    lineHeight: 1.5,
    fontFamily : "'Lato',sans-serif",
    minHeight: "32px",
    whiteSpace: "unset !important",
    marginBottom:"5px"
})

const CreateVideoConference = (props) => {

    const { clearObjectiveState, clearGoalState, clearMetricState, clearDevelopmentArea, clearCourseState } = props

    const [conferenceAboutPopup,setConferenceAboutPopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ drawer, setDrawer ] = useState(false)
    const [searchedValue, setSearchedValue] = useState({
        value : "",
        typing: false,
        data : []
    })
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)

    const history=  useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')
    
    const initialValues = {
        subject: [{
            action : "",
            action_of : "",
            label : ""
        }],
        to : [""],
        scheduled_at : ""
    }

    const validationSchema = Yup.object({
        to : Yup.array().of(
            Yup.object({}).required("Please enter email id")
        ),
        scheduled_at : Yup.string().required("Please select conference date and time")
    })

    function getUserIds(users){
        return users.map((user) => user._id)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function getSubjectDetails(subjects){
        return subjects.map((action) => {
            return {
                action : action.action,
                action_of : action.action_of
            }
        })
    }

    const onSubmit = (values) => {
        const valuesCopy = {...values}
        valuesCopy.subject = getSubjectDetails(valuesCopy.subject)
        valuesCopy.to = getUserIds(valuesCopy.to)
        valuesCopy.scheduled_at = moment(values.scheduled_at).toISOString()
        setLoading(true)
        createVideoConference(valuesCopy)
        .then((res) => {
            if(res){
                history.goBack()
                setLoading(false)
            }
        }).catch(error => {
            if(error){
                if(error.response){
                    if(error.response.data){
                        console.log(error.response.data)
                    }
                }
            }
        })
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    function handleInputChange(event, value){
        setSearchedValue( ps => {
            return {
                ...ps,
                value: event.target.value,
                typing: true,
                data: []
            }
        })
        searchDebounce(event.target.value)
    }

    const searchDebounce = useDebounce((nextString) => {
        if(nextString && nextString.length > 0){
            getEmailSuggestion({search : nextString})
                .then(res => {
                    setSearchedValue(ps => {
                        return {
                            ...ps,
                            data : res.data?.users,
                            typing: false
                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setSearchedValue(ps => {
                return {
                    ...ps,
                    value : "",
                    typing : false,
                    data : []
                }
            })
        }
    }, 1000)

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleConferenceAboutPopup(event,reason){
        if(reason && reason === "backdropClick") return
        else setConferenceAboutPopup( ps => !ps )
    }

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    useEffect(() => {
        return () => {
            clearObjectiveState()
            clearGoalState()
            clearMetricState() 
            clearDevelopmentArea() 
            clearCourseState()
        }
    },[])

    const [ actions,setActions ] = useState({
        objectives : getSpecificActions("objectives"),
        goals : getSpecificActions("goals"),
        graphs : getSpecificActions("graphs"),
        learningareas : getSpecificActions("learningareas"),
        courses : getSpecificActions("courses")
    })

    function getSpecificActions(actionType){
        const filteredActions = formik?.values?.subject.filter((action) => action.action_of === actionType)
        if(!isEmpty(filteredActions)){
            return filteredActions.map(action => {
                return {
                    value : action.action,
                    label : action.label
                }
            })
        } else {
            return []
        }
    }

    function removeSelectedAction(actionType,index){
        if(actionType === "objectives"){
            if(index.length > 1){
                setActions((ps => {
                    return{
                        ...ps,
                        objectives : ps.objectives.filter((objectives) => objectives.value !== index)
                    }
                }))
            }else{
                setActions((prevState) => {
                    return {
                        ...prevState,
                        objectives : prevState.objectives.filter((objective,i) => index !== i)
                    }
                })
            }
        }
        if(actionType === "goals"){
            if(index.length > 1 ){
                setActions((prevState) => {
                    return {
                        ...prevState,
                        goals : prevState.goals.filter((goal) => goal.value !== index)
                    }
                })
            }else{
                setActions((prevState) => {
                    return {
                        ...prevState,
                        goals : prevState.goals.filter((goal,i) => index !== i)
                    }
                })
            }
        }
        if(actionType === "graphs"){
            if(index.length > 1){
                setActions((prevState) => {
                    return {
                        ...prevState,
                        graphs : prevState.graphs.filter((metric) => metric.value !== index)
                    }
                })
            }else{
                setActions((prevState) => {
                    return {
                        ...prevState,
                        graphs : prevState.graphs.filter((metric,i) => index !== i)
                    }
                })
            }
        }
        if(actionType === "learningareas"){
            if(index.length > 1){
                setActions((prevState) => {
                    return {
                        ...prevState,
                        learningareas : prevState.learningareas.filter((developmentArea) => developmentArea.value !== index)
                    }
                })
            }else{
                setActions((prevState) => {
                    return {
                        ...prevState,
                        learningareas : prevState.learningareas.filter((developmentArea,i) => index !== i)
                    }
                })
            }
        }
        if(actionType === "courses"){
            if(index.length > 1){
                setActions((prevState) => {
                    return {
                        ...prevState,
                        courses : prevState.courses.filter((course) => course.value !== index)
                    }
                })
            }else{
                setActions((prevState) => {
                    return {
                        ...prevState,
                        courses : prevState.courses.filter((course,i) => index !== i)
                    }
                })
            }
        }
    }

    function removeSelectedSubject(index,actionType,actionId){
        if(formik.values?.subject){
            let newSubject = formik.values?.subject.filter((action,i) => index !== i )
            removeSelectedAction(actionType,actionId)
            if(!isEmpty(newSubject)){
                return formik.setFieldValue('subject',[...newSubject])
            }else{
                return formik.setFieldValue('subject', [{
                    action : "",
                    action_of : "",
                    label : ""
                }])
            }
        }else{
            return formik.setFieldValue('subject', [{
                action : "",
                action_of : "",
                label : ""
            }])
        }
    }

    function getDetail(){
        return (
            <form onSubmit={formik.handleSubmit}>
                <div className="inputbox mb-0 px-3 py-2" onClick={toggleConferenceAboutPopup} >
                    {
                        !isEmpty(formik.values.subject[0].action)?
                            formik.values.subject.map((action,index) => (
                                <Chip className="me-2"
                                    label={action.label} 
                                    key={index} 
                                    onDelete={() => removeSelectedSubject(index,action.action_of,action.action)} 
                                />
                            ))
                        :null 
                    }
                    <label className="lato-fs15-normal-lh20 tertiary-text-color-v2 my-2">Select Conference About</label>
                </div>
                <div className='mt-3'>
                    <Autocomplete
                        multiple
                        options={searchedValue.data || []}
                        name='to'
                        getOptionLabel={(suggestion) => suggestion.email.id}
                        onInputChange={handleInputChange}
                        onChange={(event, value) => {
                            formik.setFieldValue('to', value)
                        }}
                        freeSolo
                        renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "off";
                            return (
                                <CssTextField
                                    {...params}
                                    inputProps={inputProps}
                                    name='to'
                                    variant="outlined"
                                    label="Email Id"
                                    placeholder="Enter email id"
                                    margin="normal"
                                    className='my-0'
                                    fullWidth
                                />
                            )
                        }}
                    />
                    { formik.errors.to && formik.touched.to ?
                        <div>
                            <span className="error-message">{formik.errors.to}</span>
                        </div> : null }
                </div>
                <div className='mt-3'>
                    <DatePicker type="datetime-local"
                        placeholder="please enter tour date"
                        label="Date"
                        name="scheduled_at"
                        min={moment().format('YYYY-MM-DDThh:mm')}
                        value={formik.values.scheduled_at}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errorMgs={ formik.touched.scheduled_at && formik.errors.scheduled_at ? formik.errors.scheduled_at : "" }
                    />
                </div>
                <div className={isDesktop ? "d-flex mt-4" : "d-flex justify-content-center align-items-center mt-3 pb-2"}>
                    <CustomizeLoadingButton variant="with-start-icon-button" label="Schedule Conference" type="submit" startIcon={<FontAwesomeIcon icon={faClockRotateLeft} />} loading={loading} />
                </div>
            </form>
        )
    }
    
    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar 
                            enableProfileButton={true} 
                            enableLogout={true}
                            enableMore={true}
                            moreRight="135px" 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <div className='desktop-compose-container'>
                                    <div className='mt-4'>
                                        <h5 className='primary-text-color font-bold lato-fs22-normal-lh32 mb-0'>Create Video Conference</h5>
                                    </div>
                                    <div className='mt-3'>
                                        {getDetail()}
                                    </div>
                                </div>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    : 
                    <>
                        <Navbar 
                            variant="mobile"
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={() => history.push(`/notification-reference?selected=notification&tab=inbox&action=objectives`)}
                        />
                        <div className="container">
                            <Box sx={{
                                marginTop:"16px",
                                maxWidth:"450px",
                                marginLeft:{
                                    xs: 0,
                                    sm: "10%"
                                },
                                paddingLeft:{
                                    xs: "16px",
                                    sm: 0
                                },
                                paddingRight:{
                                    xs: "16px",
                                    sm: 0
                                }
                            }}>
                                <div>
                                    <Header 
                                        heading="Create Video Conference" 
                                        handleBackArrowEvent={handleBackArrowEvent}
                                    />
                                </div>
                                <div className='mt-3 pb-70'>
                                    {getDetail()}
                                </div>
                            </Box>
                        </div>
                        <BottomNavigation />
                    </>
                }
                { conferenceAboutPopup && 
                    <VideoConferenceAboutPopup 
                        open={conferenceAboutPopup}
                        selected={formik.values.subject}
                        setSelected={formik.setFieldValue}
                        handleClose={toggleConferenceAboutPopup}
                        actions={actions}
                        setActions={setActions}
                        removeSelectedAction={removeSelectedAction} />
                }
            </Container>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearObjectiveState : () => dispatch(clearObjectiveState()),
        clearGoalState : () => dispatch(clearGoalSelectState()),
        clearMetricState : () => dispatch(clearMetricsForSelectState()),
        clearDevelopmentArea : () => dispatch(clearDevelopmentAreaState()),
        clearCourseState : () => dispatch(clearCoursesState())
    }
}

export default connect(null, mapDispatchToProps)(CreateVideoConference)