import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './breadcrumbs.css'
import {isEmpty, size} from 'lodash'
import { v4 as uuidv4 } from 'uuid';

const BreadCrumbs = (props) => {

    const [state, setState] = useState(false)

    const containerClassName = props.containerClassName ? `breadcrumb-new ${props.containerClassName}` : "breadcrumb-new "
    
    function getBreadCrumbs(){
        if(!isEmpty(props.path)){
            if(props.truncate && !state && size(props.path)>1){
                return (
                    <>
                        <a key={uuidv4()} href="#" className={`${props.listClassName}`}>
                            <span className="one-line-ellipsis" onClick={() => setState(ps => !ps)}>...</span>
                        </a>
                        <a key={props.path[props.path.length - 1]._id} href="#" className={props.path[props.path.length - 1]._id == props.activeId ? `b-active ${props.listClassName}`  : `${props.listClassName}`}>
                            <span className='one-line-ellipsis'>{props.path[props.path.length - 1].name}</span>
                        </a>
                    </>
                )
            } else {
                return (
                    props.path.map((path) => (
                        <a key={path._id} href="#" className={path._id == props.activeId ? `b-active ${props.listClassName}` : `${props.listClassName}`}>
                            <span className='one-line-ellipsis'>{path.edits && path.edits.name ? path.edits.name : path.name}</span>
                        </a>
                    ))
                )
            }
        } else {
            return null
        }
    }

    return (
        <div className={containerClassName}>
            {props.icon ? <a className={`${props.listClassName}`}><FontAwesomeIcon icon={props.icon} className={`primary-color-v2`}/></a> : ""}
            {props.img ? <a className={`${props.listClassName}`}><img className="breadcrumb-new-img" src={props.img}/></a> : ""}
            {getBreadCrumbs()}
        </div>
    )
}

export default BreadCrumbs