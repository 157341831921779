import { REQUEST_DEVELOPMENT_AREA, 
         DEVELOPMENT_AREA_SUCCESS,
         DEVELOPMENT_AREA_FAILURE,
         CLEAR_DEVELOPMENT_AREA_STATE } from './Development.types'

export const requestDevelopmentArea = () => {
    return {
        type : REQUEST_DEVELOPMENT_AREA
    }
}

export const developmentAreaSuccess = (response) => {
    return {
        type : DEVELOPMENT_AREA_SUCCESS,
        payload : response
    }
}

export const developmentAreaFailure = (error) => {
    return {
        type : DEVELOPMENT_AREA_FAILURE,
        payload : error
    }
}

export const clearDevelopmentAreaState = () => {
    return {
        type : CLEAR_DEVELOPMENT_AREA_STATE
    }
}