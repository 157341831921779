import React,{ useState } from 'react'
import Grid from '@mui/material/Grid'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useHistory } from 'react-router-dom'

import Container from '../../../components/desktop-layout/container/Container'
import PublicNavbar from '../public-navbar/PublicNavbar'
import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import { requestConsultWithUs } from '../../../services/constants.service'
import Footer from '../../../components/footer/Footer'

const Consult = () => {

    const [ drawer, setDrawer ] = useState(false)

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const initialValues={
        email_id:"",
        message :""
    }

    const onSubmit = (values) => {
        requestConsultWithUs(values)
        .then((res) => {
            if(res.data){
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                history.push('/')
            }
        })
        .catch((err) => {
            console.log(err)
            if(err.response.data){
                toast.error(err.response.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        })
    }

    const validationSchema = Yup.object({
        email_id: Yup.string()
                    .required("Please enter your email Id")
                    .email("Invalid email")
                    .test("domain_check", "Organisational email Ids only", (value) => {
                        if(value) {
                            const domain = value.substring(value.lastIndexOf("@") + 1);
                            return !["gmail.com", "yahoo.com", "outlook.com", "protonmail.com", "aol.com", "mail.com", "zohomail.in"].includes(domain)
                        } else {
                            return false
                        }
                    }),
        message: Yup.string().required("Please enter your message")
                    .max(100, "Too long")
    })

    return (
        <Container>
            <PublicNavbar 
                variant={isDesktop ? "" :"mobile"}
                logo={true} 
                enableProduct={true} 
                enableSubscribe={true} 
                enableAboutUs={true}
                enableHome={true}
                enableDrawer={true}
                drawerOpened={drawer}
                handleDrawerClose={toggleDrawer}
                onDrawerClick={toggleDrawer}
                drawerHome={true}
            />
            <article>
                <section className='d-flex align-items-center' style={{minHeight:"91vh"}}>
                    <div className='mx-auto py-5' style={{width:"86%"}}>
                        <Grid container spacing={2}>
                            <Grid className='d-flex flex-column justify-content-center' item md={6}>
                                <div className={isDesktop ? 'px-5' : 'px-3'}>
                                    <div>
                                        <h2 className='mb-4 lato-fs36-normal-lh42'>Book your <span className='lato-fs36-normal-lh42 font-bold' style={{color: 'var(--primary-color-v2)'}}>consultation</span></h2>
                                    </div>
                                    <Formik 
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        validateOnChange={false}
                                        onSubmit={onSubmit}
                                    >
                                        {props => {
                                            const {touched, values, errors, handleBlur, handleChange} = props
                                            return (
                                                <Form>
                                                    <div className='mt-4'>
                                                        <InputBox 
                                                            type="text"
                                                            name="email_id"
                                                            label="Your Email Id"
                                                            placeholder="Enter your email Id"
                                                            value={values.email_id}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMgs={touched.email_id && errors.email_id ? errors.email_id : ""}
                                                        />
                                                    </div>
                                                    <div className='mt-3'>
                                                        <TextAreaBox 
                                                            name="message"
                                                            label="Message"
                                                            placeholder="Enter message"
                                                            maxLength={100}
                                                            value={values.message}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            errormgs={touched.message && errors.message ? errors.message : ""}
                                                        />
                                                    </div>
                                                    <CustomizeButton 
                                                        className="mt-3" 
                                                        type="submit" 
                                                        variant="primary-color-button" 
                                                        label="Send" />
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </div>
                            </Grid>
                            <Grid className='d-flex align-items-center justify-content-center' item md={6}>
                                <img style={{maxHeight:"400px", width: isDesktop ? "" : "100%"}} src="https://www.amratpal.com/wp-content/uploads/2020/12/personal-consultation.png" alt=""/>
                            </Grid>
                        </Grid>
                    </div>
                </section>
            </article>
        </Container>
    )
}

export default Consult