import { INDIVIDUAL_SIGNUP_REQUEST,
         INDIVIDUAL_SIGNUP_SUCCESS,
         INDIVIDUAL_SIGNUP_FAILURE, 
         INDIVIDUAL_SIGNIN_REQUEST,
         INDIVIDUAL_SIGNIN_SUCCESS,
         INDIVIDUAL_SIGNIN_FAILURE,
         CLEAR_AUTH_STATES } from './AuthenticationTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const individualAuthenticationReducer = (state = initialState, action) => {
    switch(action.type) {
        case INDIVIDUAL_SIGNUP_REQUEST : 
            return {
                ...state,
                ...initialState
            }

        case INDIVIDUAL_SIGNUP_SUCCESS : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case INDIVIDUAL_SIGNUP_FAILURE : 
            return{
                ...state,
                loading: false,
                response:{},
                error: action.payload
            }

        case INDIVIDUAL_SIGNIN_REQUEST : 
            return {
                ...state,
                ...initialState
            }

        case INDIVIDUAL_SIGNIN_SUCCESS :
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case INDIVIDUAL_SIGNIN_FAILURE :
            return {
                ...state,
                loading: false,
                response:{},
                error: action.payload
            }

        case CLEAR_AUTH_STATES : 
            return{
                ...state,
                ...initialState
            }

        default :
            return state
    };
}

export default individualAuthenticationReducer