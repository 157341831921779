import { REQUEST_COURSE_PERFORMANCE,
         COURSE_PERFORMANCE_SUCCESS, 
         COURSE_PERFORMANCE_FAILURE,
         COURSE_PERFORMANCE_TABLE } from './CoursePerformanceType'

const initialState = {
    loading : false,
    response: {},
    error : null
}

const learningDetailsTableReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_COURSE_PERFORMANCE :
            return {
                ...state,
                loading : true,
                error : null
            }

        case COURSE_PERFORMANCE_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case COURSE_PERFORMANCE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case COURSE_PERFORMANCE_TABLE : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }
        
        default : 
            return state
    }
}

export default learningDetailsTableReducer