import React from 'react';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';

import './select-with-grouped-option.css'

const ErrorFormHelperText = styled(FormHelperText)({
    color: 'red',
    fontFamily: "'Lato', sans-serif"
})

const SelectWithGroupedOption = (props) => {
    return (
        <FormControl variant="outlined" fullWidth >
            <InputLabel htmlFor={props.id} className="input-label">{props.label}</InputLabel>
            <Select
                label={props.label}
                className="group-select"
                InputProps={{
                    style:{fontFamily:'"Lato", sans-serif', fontSize:"15px"}
                }}
                InputLabelProps={{
                    style:{fontFamily:'"Lato", sans-serif', fontSize:"15px"}
                }}
                autoComplete="off"
                {...props.selectProps} >
                {props.options}
            </Select>
            {
                Boolean(props.errorMsg) ?
                <ErrorFormHelperText>{props.errorMsg}</ErrorFormHelperText> :
                null
            }
        </FormControl>
    )
};

export default SelectWithGroupedOption;
