import React from 'react'
import { Line } from 'react-chartjs-2'
import { isEmpty, size } from 'lodash'
import moment from 'moment'
import useWindowDimension from '../../../hooks/useWindowDimension'
import useMediaQuery from '@mui/material/useMediaQuery'

import { formatDate } from '../../../utils/utils'

const GRAPH_COLORS = [
    {
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
    },
    {
        backgroundColor: "rgba(0,128,0,1)",
        borderColor: "rgba(0,128,0,1)",
    },
    {
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
    }
]

const LineChart = (props) => {

    const { height, width } = useWindowDimension();
    const isMobile = useMediaQuery('(max-width:480px)')

    function formatGraphDataForAggregate(graphData) {
        const curtailedGraphData = size(graphData) > 5 ? graphData.slice((graphData.length - 5), graphData.length) : graphData.slice(1, graphData.length)
        return {
            labels: getGraphLabel(curtailedGraphData, 'A'),
            datasets : getDatasets(curtailedGraphData, graphData[0])
        }
    }

    function getGraphLabel(datas, key){
        let graphLabel = []
        for(let i = 0; i<size(datas); i++ ){
            // graphLabel.push(checkValidDate(datas[i][key]) ? formatDate(datas[i][key]) : datas[i][key])
            graphLabel.push(datas[i][key])
        }
        return graphLabel
    }

    function checkValidDate(dateString){
        return moment(dateString, moment.ISO_8601, true).isValid()
    }

    function formatGraphData(graphData) {
        const graphDataCopy = graphData.slice(1, graphData.length)
        return {
            labels: getGraphLabel(graphDataCopy, 'A'),
            datasets : getDatasets(graphDataCopy, graphData[0])
        }
    }

    function getDatasets(datas, labels) {
        let dataset = []
        const firstData = datas[0]
        if(firstData.B !== null && firstData.B !== undefined){
            if(firstData.C !== null && firstData.C !== undefined){
                dataset.push({
                    label : labels.B,
                    fill : false,
                    lineTension: 0.5,
                    backgroundColor: GRAPH_COLORS[0].backgroundColor,
                    borderColor: GRAPH_COLORS[0].borderColor,
                    borderWidth: 2,
                    data: getGraphLabel(datas, "B")
                })
                dataset.push({
                    label : labels.C,
                    fill : false,
                    lineTension: 0.5,
                    backgroundColor: GRAPH_COLORS[1].backgroundColor,
                    borderColor: GRAPH_COLORS[1].borderColor,
                    borderWidth: 2,
                    data: getGraphLabel(datas, "C")
                })
            } else {
                dataset.push({
                    label : labels.B,
                    fill : false,
                    lineTension: 0.5,
                    backgroundColor: GRAPH_COLORS[0].backgroundColor,
                    borderColor: GRAPH_COLORS[0].borderColor,
                    borderWidth: 2,
                    data: getGraphLabel(datas, "B")
                })
            }
        } else {
            dataset.push({
                label : labels.D,
                fill : false,
                lineTension: 0.5,
                backgroundColor: GRAPH_COLORS[0].backgroundColor,
                borderColor: GRAPH_COLORS[0].borderColor,
                borderWidth: 2,
                data: getGraphLabel(datas, "D")
            })
        }
        return dataset
    }

    // function formatGraphDataForExpandedGraph(graphData) {
    //     let graphDataCopy = graphData.slice()
    //     const maskedLabelValues = graphDataCopy.shift()
    //     return {
    //         labels: getGraphLabelForExpandedGraph(graphDataCopy, 'A'),
    //         datasets : getDatasets(graphDataCopy, maskedLabelValues)
    //     }
    // }

    // function getGraphLabelForExpandedGraph(datas, key){
    //     let graphLabel = []
    //     for(let i = 0; i<size(datas); i++ ){
    //         graphLabel.push(checkValidDate(datas[i][key]) ? formatDate(datas[i][key]) : datas[i][key])
    //     }
    //     return graphLabel
    // }

    function calculateWidth(){
        const chartWidth = (size(props.graphDetail) - 1)*25
        if(width < chartWidth){
            return `${chartWidth}px`
        } else {
            return `100%`
        }
    }

    switch(props.variant){
        case "preview" : 
            return (
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div style={{width: `${calculateWidth()}`, height: isMobile ? '300px' : '400px'}}>
                        <Line
                            className='p-1 w-100 h-100'
                            data={formatGraphData(props.graphDetail)}
                            options={{
                                legend : {
                                    display: true,
                                    position: 'bottom',
                                    labels: {
                                        usePointStyle:true,
                                        fontColor: "#323130",
                                        fontSize: 14,
                                        fontFamily:"'Lato',sans-serif"
                                    }
                                },
                                animation :{
                                    duration : 0  
                                },
                                responsive : true,
                                maintainAspectRatio: false,
                                scales: {                  
                                    x: {
                                        grid : {
                                            display:false,   //hide the label and border
                                        },
                                        ticks : {
                                            autoSkip : true,
                                            maxTicksLimit : 5,
                                            align : 'center',
                                            font: {
                                                size : 9
                                            }
                                        }
                                    },
                                    y: {
                                        grid : {
                                            display : false, //hide the label and border
                                        },
                                        ticks : {
                                            align : 'center',
                                            font: {
                                                size : 9
                                            }
                                        }
                                    },
                                }                  
                            }} />
                    </div>
                </div>
            )

        case "expanded-graph":
            return (
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div style={{width: `${calculateWidth()}`, height: "70vh"}}>
                        <Line
                            data={formatGraphData(props.graphDetail)}
                            className='p-1 w-100 h-100'
                            options={{
                                // plugins : {
                                //     title : {
                                //         display : true,
                                //         text : 'custom chart title'
                                //     }
                                // },
                                responsive: true,
                                maintainAspectRatio: false,
                                legend:{
                                    display: true,
                                    position: "bottom",
                                    labels: {
                                        fontColor: "rgba(68,68,68,255)",
                                        fontSize: 11
                                    }
                                },
                                scales : {
                                    x : {
                                        title : {
                                            display : true,
                                            text : props.graphDetail[0].A,
                                            color: "rgba(68,68,68,255)",
                                            font : {
                                                size : 14,
                                                family : "'Lato',sans-serif",
                                            }
                                        },
                                        ticks : {
                                            autoSkip : true,
                                            maxTicksLimit : 5,
                                            color : 'rgba(68,68,68,255)',
                                            fontFamily : "'Lato',sans-serif",
                                            font : {
                                                size : 11
                                            }
                                        }
                                    },
                                    y : {
                                        title : {
                                            display : true,
                                            text : props.graphDetail[0].B,
                                            color: "rgba(68,68,68,255)",
                                            font : {
                                                size : 14,
                                                family : "'Lato',sans-serif"
                                            }
                                        }
                                    }
                                }          
                            }}
                        />
                    </div>
                </div>
            )

        default : 
                return (
                    <Line
                    className='p-4 h-100 w-100'
                    data={formatGraphDataForAggregate(props.graphData)}
                    options={{
                        plugins: {              // hide the color representation
                            legend: {
                                display: false,
                            },
                            tooltip:{
                                enabled: false
                            }
                        },
                        responsive : true,
                        animation :{
                            duration : 0  
                        },
                        scales: {                  
                            x: {
                                display:false,  //hide the label and border
                            },
                            y: {
                                display : false, //hide the label and border
                            }
                        }                  
                    }} /> 
                )
    }
}

export default LineChart