import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'

const StyledTabs = styled(Tabs)({
    // borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#14213d',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(1),
    color: 'rgba(156,155,185,255)',
    fontFamily: '"oxygen",sans-serif',
    fontSize:"15px",
    '&.Mui-selected': {
      color: '#14213d',
      fontWeight: 600,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
}));

const ScrollableTabheader = (props) => {
    return (
        <Box sx={{ maxWidth: 480 }}>
            <StyledTabs
            value={props.value}
            onChange={props.onChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{style: {background:'#14213d'}}}
            >
                { props.tabArray.map((tab) => (
                    <StyledTab label={tab.name} value={tab.value} key={uuidv4()} />
                ))}
            </StyledTabs>
      </Box>
    )
}

export default ScrollableTabheader