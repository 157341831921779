import React,{useState, useEffect} from "react";
import { isEmpty, size } from "lodash";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Avatar from "@mui/material/Avatar";
import { useSelector } from 'react-redux'

import './videos.css'

import useWindowDimension from '../../hooks/useWindowDimension'
import VCVideoPlayer from "../vc-video-player/VCVideoPlayer";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, drawerWidth }) => ({
      // flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight:`-${drawerWidth}px`,
      ...(open && {
          transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
          }),
          marginRight: 0,
      }),
  }),
)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Videos = (props) => {

  const videoChatState = useSelector((state) => state.videoChat)

  const { tracks, trackState, drawerOpen, setDrawerOpen } = props;
  const remoteUsers = videoChatState.remoteUsers
  const localVC = videoChatState.localVC
  const screenSharingUser = videoChatState.screenSharingUser

  const isDesktop = useMediaQuery('(min-width:992px)')

  const [dimensions, setDimensions] = useState({width: 0, height: 0})
  
  const {width, height} = useWindowDimension()

  const drawerWidth = 375;

  // Drawer close
  const handleDrawerClose = () => {
      setDrawerOpen(false)
  }

  useEffect(() => {
    setContainerDimensions()
  }, [drawerOpen, screenSharingUser, remoteUsers, localVC, width, height])

  function setContainerDimensions(){
    const ratio = determineContainerRatio()
    getContainerDimensions(ratio.widthBasedRatio, ratio.heightBasedRatio).then(res => {
        setDimensions(res)
    })
  }

  function getContainerDimensions(widthBasedRatio, heightBasedRatio){
    return new Promise((resolve, reject) => {
        const marginHorizontal = Math.floor(0.05*2*width)
        const marginVertical = (2*16)
        const bottomBar = 56
        const contentWidth = drawerOpen ? (width - drawerWidth - marginHorizontal) : width - marginHorizontal
        const contentHeight = height - bottomBar - marginVertical

        if(contentWidth > contentHeight){
            resolve(getWidthFromHeight(contentWidth, contentHeight, heightBasedRatio))
        } else {
            resolve(getHeightFromWidth(contentWidth, contentHeight, widthBasedRatio))
        }
    })
  }

  // width based
  function getHeightFromWidth(maxWidth, maxHeight, ratio){
    let mHeight = 0
    let mWidth = maxWidth
    mHeight = Math.floor(maxWidth / ratio)
    if(mHeight > maxHeight){
        mHeight = maxHeight
        mWidth = Math.floor(maxHeight*ratio)
    }
    return {
        width: mWidth,
        height: mHeight
    }
  }

  // height based
  function getWidthFromHeight(maxWidth, maxHeight, ratio){
      let mHeight = maxHeight
      let mWidth = 0
      mWidth = Math.floor(ratio * mHeight)
      if(mWidth > maxWidth){
          mWidth = maxWidth
          mHeight = Math.floor(maxWidth/ratio)
      }
      return {
          width: mWidth,
          height: mHeight
      }
  }

  function determineContainerRatio(){
    if(!isEmpty(remoteUsers)){
      if(!isEmpty(screenSharingUser)){
        if(size(remoteUsers) > 1){
          // console.log('dim: block 1')
          // one local user, one screen sharing user and two - five remote users
          return {widthBasedRatio: 8/9, heightBasedRatio: 2/1}
        } else {
          // console.log('dim: block 2')
          // one local user, one screen sharing user and one remote users
          return {widthBasedRatio: 8/9, heightBasedRatio: 2/1}
        }
      } else {
        if(size(remoteUsers) > 3){
          // console.log('dim: block 3')
          //one local user and four - five remote user
          return {widthBasedRatio: 8/9, heightBasedRatio: 2/1}
        } else if(size(remoteUsers) > 1) {
          // console.log('dim: block 4')
          //one local user and two - three remote user
          return {widthBasedRatio: 4/3, heightBasedRatio: 4/3}
        } else {
          // console.log('dim: block 5')
          //one local user and one remote user
          return {widthBasedRatio: 2/3, heightBasedRatio: 8/3}
        }
      }
    } else {
      if(!isEmpty(localVC) && !isEmpty(screenSharingUser)){
        // one local user and one screen sharing user
        // console.log('dim: block 6')
        return {widthBasedRatio: 8/9, heightBasedRatio: 131/54}
      } else if(!isEmpty(localVC)) {
        // console.log('dim: block 7')
        //oneLocalUser
        return {widthBasedRatio: 4/3, heightBasedRatio: 4/3}
      } else {
        // console.log('dim: block 8')
        // exception
        return {widthBasedRatio: 1, heightBasedRatio: 1}
      }
    }
  }

  function getContent(){
    if(!isEmpty(remoteUsers)){
      if(!isEmpty(screenSharingUser)){
        if(size(remoteUsers) > 1){
          //one local user, one screen sharing user and two - five remote users
          // console.log('content: block 1')
          let limitedRemoteUsers = remoteUsers
          let excessMembersCount = 0
          if(remoteUsers > 5){
            limitedRemoteUsers = remoteUsers.slice(0, 6)
            excessMembersCount = size(remoteUsers) - 5
          }
          return (
            <div
                className={`grid-container ${dimensions.width > dimensions.height ? "three-cross-four-grid" : "four-cross-two-grid"}`}
                style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`
                }}
            >
              <div className={ dimensions.width > dimensions.height ? 'span-three-wide span-two-tall' : 'span-two-wide'}>
                <VCVideoPlayer
                  variant="screen_share"
                  videoTrack={screenSharingUser?.user?.videoTrack}
                  userName={screenSharingUser?.profileInfo?.user?.person_name?.first_name}
                />
              </div>
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(trackState?.video)}
                  videoTrack={tracks?.[1]}
                  userName={localVC?.vc_data?.user?.person_name?.first_name}
                  micOn={Boolean(trackState.audio)}
                  userPic={localVC?.vc_data?.user?.profile_pic}
                />
              </div>
              {
                limitedRemoteUsers.map((remoteUser) => (
                  <div 
                    key={remoteUser.user.uid} 
                    style={{width: "100%", height: "100%"}}
                  > 
                    <VCVideoPlayer
                      isVideoTrackPresent={Boolean(remoteUser?.user?.videoTrack)}
                      videoTrack={remoteUser?.user?.videoTrack}
                      userName={remoteUser?.profileInfo?.user?.person_name?.first_name}
                      micOn={Boolean(remoteUser?.user?.audioTrack)}
                      userPic={remoteUser?.profileInfo?.user?.profile_pic}
                      excessMembersCount={size(limitedRemoteUsers) - 1 === 5 ? excessMembersCount : 0}
                    />
                  </div>
                ))
              }
              </div>
          )
        } else {
          //one local user, one screen sharing user and one remote users
          // console.log('content: block 2')
          return (
            <div
                className={`grid-container ${dimensions.width > dimensions.height ? "two-cross-three-grid" : "three-cross-two-grid"}`}
                style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`
                }}
            >
              <div className='span-two-wide span-two-tall'>
                <VCVideoPlayer
                  variant="screen_share"
                  videoTrack={screenSharingUser?.user?.videoTrack}
                  userName={screenSharingUser?.profileInfo?.user?.person_name?.first_name}
                />
              </div>
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(trackState?.video)}
                  videoTrack={tracks?.[1]}
                  userName={localVC?.vc_data?.user?.person_name?.first_name}
                  micOn={Boolean(trackState.audio)}
                  userPic={localVC?.vc_data?.user?.profile_pic}
                />
              </div>
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(remoteUsers?.[0]?.user?.videoTrack)}
                  videoTrack={remoteUsers?.[0]?.user?.videoTrack}
                  userName={remoteUsers?.[0]?.profileInfo?.user?.person_name?.first_name}
                  micOn={Boolean(remoteUsers?.[0]?.user?.audioTrack)}
                  userPic={remoteUsers?.[0]?.profileInfo?.user?.profile_pic}
                />
              </div>
            </div>
          )
        }
      } else {
        if(size(remoteUsers) > 3){
          //one local user and four - five remote user
          // console.log('content: block 3')
          let limitedRemoteUsers = remoteUsers
          let excessMembersCount = 0
          if(remoteUsers > 5){
            limitedRemoteUsers = remoteUsers.slice(0, 6)
            excessMembersCount = size(remoteUsers) - 5
          }
          return  (
            <div
                className={`grid-container ${dimensions.width > dimensions.height ? "two-cross-three-grid" : "three-cross-two-grid"}`}
                style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`
                }}
            >
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(trackState?.video)}
                  videoTrack={tracks?.[1]}
                  userName={localVC?.vc_data?.user?.person_name?.first_name}
                  micOn={Boolean(trackState.audio)}
                  userPic={localVC?.vc_data?.user?.profile_pic}
                />
              </div>
              {
                limitedRemoteUsers.map((remoteUser) => (
                  <div 
                    key={remoteUser.user.uid}
                    style={{width: "100%", height: "100%"}}
                  > 
                    <VCVideoPlayer
                      isVideoTrackPresent={Boolean(remoteUser?.user?.videoTrack)}
                      videoTrack={remoteUser?.user?.videoTrack}
                      userName={remoteUser?.profileInfo?.user?.person_name?.first_name}
                      micOn={Boolean(remoteUser?.user?.audioTrack)}
                      userPic={remoteUser?.profileInfo?.user?.profile_pic}
                      excessMembersCount={size(limitedRemoteUsers) - 1 === 5 ? excessMembersCount : 0}
                    />
                  </div>
                ))
              }
            </div>
          )
        } else if(size(remoteUsers) > 1) {
          // console.log('content: block 4')
          //one local user and two - three remote user
          return (
            <div
                className="grid-container two-cross-two-grid"
                style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`
                }}
            >
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(trackState?.video)}
                  videoTrack={tracks?.[1]}
                  userName={localVC?.vc_data?.user?.person_name?.first_name}
                  micOn={Boolean(trackState.audio)}
                  userPic={localVC?.vc_data?.user?.profile_pic}
                />
              </div>
              {
                remoteUsers.map((remoteUser) => (
                  <div 
                    key={remoteUser.user.uid}
                    style={{width: "100%", height: "100%"}}
                  > 
                    <VCVideoPlayer
                      isVideoTrackPresent={Boolean(remoteUser?.user?.videoTrack)}
                      videoTrack={remoteUser?.user?.videoTrack}
                      userName={remoteUser?.profileInfo?.user?.person_name?.first_name}
                      micOn={Boolean(remoteUser?.user?.audioTrack)}
                      userPic={remoteUser?.profileInfo?.user?.profile_pic}
                    />
                  </div>
                ))
              }
            </div>
          )
        } else {
          // one local user and one remote user
          // console.log('content: block 5')
          return (
            <div
                className={`grid-container ${dimensions.width > dimensions.height ? "one-cross-two-grid" : "two-cross-one-grid"}`}
                style={{
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`
                }}
            >
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(trackState?.video)}
                  videoTrack={tracks?.[1]}
                  userName={localVC?.vc_data?.user?.person_name?.first_name}
                  micOn={Boolean(trackState.audio)}
                  userPic={localVC?.vc_data?.user?.profile_pic}
                />
              </div>
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(remoteUsers?.[0]?.user?.videoTrack)}
                  videoTrack={remoteUsers?.[0]?.user?.videoTrack}
                  userName={remoteUsers?.[0]?.profileInfo?.user?.person_name?.first_name}
                  micOn={Boolean(remoteUsers?.[0]?.user?.audioTrack)}
                  userPic={remoteUsers?.[0]?.profileInfo?.user?.profile_pic}
                />
              </div>
            </div>
          )
        }
      }
    } else {
      if(!isEmpty(localVC) && !isEmpty(screenSharingUser)){
        //one local user and one screen sharing user
        // console.log('content: block 6')
        return  (
          <div
              className={`grid-container ${dimensions.width > dimensions.height ? "two-cross-three-grid" : "three-cross-two-grid"}`}
              style={{
                  width: `${dimensions.width}px`,
                  height: `${dimensions.height}px`
              }}
          >
            <div className='span-two-wide span-two-tall'>
              <VCVideoPlayer
                variant="screen_share"
                videoTrack={screenSharingUser?.user?.videoTrack}
                userName={screenSharingUser?.profileInfo?.user?.person_name?.first_name}
              />
            </div>
            <div style={{width: "100%", height: "100%"}}>
              <VCVideoPlayer
                isVideoTrackPresent={Boolean(trackState?.video)}
                videoTrack={tracks?.[1]}
                userName={localVC?.vc_data?.user?.person_name?.first_name}
                micOn={Boolean(trackState.audio)}
                userPic={localVC?.vc_data?.user?.profile_pic}
              />
            </div>
          </div>
        )
      } else if (!isEmpty(localVC)) {
        // one local user
        // console.log('content: block 7')
        return  (
          <div
            style={{
                width: `${dimensions.width}px` || 0, 
                height: `${dimensions.height}px` || 0,
            }}
          >
              <div style={{width: "100%", height: "100%"}}>
                <VCVideoPlayer
                  isVideoTrackPresent={Boolean(trackState?.video)}
                  videoTrack={tracks?.[1]}
                  userName={localVC?.vc_data?.user?.person_name?.first_name}
                  micOn={Boolean(trackState.audio)}
                  userPic={localVC?.vc_data?.user?.profile_pic}
                />
              </div>
          </div>
        )
      } else {
        // exception
        // console.log('content: block 8')
        return null
      }
    }
  }

  function getPeopleList(){
    return (
      <div className="py-3">
        <div className="d-flex justify-content-between align-items-center px-4 pb-2">
          <h2 className="lato-fs24-normal-lh32 font-bold">People</h2>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="pt-2">
          {
            !isEmpty(localVC) ?
              <div className="d-flex align-items-center px-3 py-2">
                <Avatar src={localVC?.vc_data?.user?.profile_pic}/>
                <div className="d-flex flex-column ms-3 overflow-hidden">
                  <h5 className="lato-fs18-normal-lh25 mb-0 one-line-wrap">
                    <span className="me-1">{localVC?.vc_data?.user?.person_name?.first_name}</span>
                    <span className="me-1">{localVC?.vc_data?.user?.person_name?.last_name}</span>
                    <span>(You)</span>
                  </h5>
                  {
                    localVC?.vc_data?.is_host ?
                      <h6 className="lato-fs15-normal-lh20 mb-0">Host</h6>
                      : null 
                  }
                </div>
              </div>
              : null
          }
          {
            !isEmpty(screenSharingUser) ?
              <div className="d-flex align-items-center px-3 py-2">
                <Avatar src={screenSharingUser?.profileInfo?.user?.profile_pic}/>
                <div className="d-flex flex-column ms-3 overflow-hidden">
                  <h5 className="lato-fs18-normal-lh25 mb-0 one-line-wrap">
                    <span className="me-1">{screenSharingUser?.profileInfo?.user?.person_name?.first_name}</span>
                    <span className="me-1">{screenSharingUser?.profileInfo?.user?.person_name?.last_name}</span>
                  </h5>
                  <h6 className="lato-fs15-normal-lh20 mb-0">Presenting</h6>
                </div>
              </div> 
              : null
          }
          {
            remoteUsers.map(remoteUser => (
              <div className="d-flex align-items-center px-3 py-2">
                <Avatar src={remoteUser?.profileInfo?.user?.profile_pic} />
                <div className="d-flex flex-column ms-3 overflow-hidden">
                  <h5 className="lato-fs18-normal-lh25 mb-0 one-line-wrap">
                    {remoteUser?.profileInfo?.user?.person_name?.first_name}
                    {remoteUser?.profileInfo?.person_name?.last_name}
                  </h5>
                  {
                    remoteUser?.profileInfo?.is_host ?
                      <h6 className="lato-fs15-normal-lh20 mb-0">Host</h6>
                      : null 
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  return (
    isDesktop ?
      <Box sx={{ display: 'flex' }} className="w-100 h-100" >
        <Main open={drawerOpen} sx={{flex:"1"}} className="d-flex justify-content-center align-items-center" drawerWidth={drawerWidth}>
            {getContent()}
        </Main>
        <Drawer
            sx={{
                width: drawerWidth,
                overflow: 'hidden',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: "100%",
                    boxSizing: 'border-box',
                    position: "relative",
                    height: '100%',
                    padding: '1rem',
                    backgroundColor: 'transparent'
                }
            }}
            variant="persistent"
            anchor="right"
            open={drawerOpen}
        >
          <div style={{backgroundColor: 'white', width: "100%", height: "100%", borderRadius: '1rem'}}>
            {getPeopleList()} 
          </div>
        </Drawer>
      </Box>
      : <div className="p-4 d-flex justify-content-center align-items-center w-100 h-100">
        {getContent()}
        <Dialog
          fullScreen
          open={drawerOpen}
          onClose={handleDrawerClose}
          TransitionComponent={Transition}
        >
          <div>
            {getPeopleList()}
          </div>
        </Dialog>
      </div>
  )
};

export default Videos