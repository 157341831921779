import { Formik, Form, FieldArray, Field } from 'formik'
import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { useHistory,useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty, size } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faDownload } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import XLSX from 'xlsx'
import axios from 'axios'
import moment from 'moment'
import qs from 'query-string'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DownloadIcon from '@mui/icons-material/Download'

import GoalListPopup from '../../../components/goal-list-popup/GoalListPopup'
import { formatDate, isDate } from '../../../utils/utils'
import SelectBox from '../../../common/select-box/SelectBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import { createMetrics, fetchCreateMetricData, specificMetric, updateMetric } from '../../../services/metric.service'
import SelectBoxValueById from '../../../common/select-box-value-id/SelectBoxValueById'
import { metricActionChanges } from '../../../redux/fetch-metric/FetchMetricAction'
import { clearCreateMetrics } from '../../../redux/create-metric/CreateMetricAction'
import PublicWebClient from '../../../utils/public-web-client'
import DatePicker from '../../../common/date-picker/DatePicker'
import InputBox from '../../../common/Input-box/InputBox'
import { clearSpecificMetricState } from '../../../redux/specific-metric/specificMetric.action'
import PieChart from '../../../components/chart/pie-chart/PieChart'
import DoughnutChart from '../../../components/chart/doughnut-chart/DoughnutChart'
import BarChart from '../../../components/chart/bar-chart/BarChart'
import LineChart from '../../../components/chart/line-chart/LineChart'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import { clearFetchCreateMetricData } from '../../../redux/fetch-create-metric-data/FetchCreateMetricDataAction'
import GanttChart from '../../../components/chart/gantt-chart/GanttChart'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import UploadFiles from '../../../components/upload-files/UploadFiles'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import Header from '../../../common/header/Header'

const METRICS_ATTACHMENT_NAMES = {
    data: 'graph_data',
    reference: 'graph_references',
    graph_data: 'graph_data_file'
}

const CreateOrEditMetric = (props) => {

    const { 
        createMetric, 
        prerequisiteMetricData,
        prerequisiteMetricDataState, 
        alterMetricState, 
        clearAlterState,
        updateMetric,
        specificMetricState,
        requestSpecificMetric,
        clearSpecificMetricState,
        fetchMetricChanges,
        clearPrerequisiteMetricData
    } = props

    let history = useHistory()
    let location = useLocation()
    const referenceFileRef = useRef();
    const graphDataFileRef = useRef();
    const queryParams = qs.parse(location.search)
    const action = location.pathname.split('/')[2]

    const [validate, setValidate] = useState({isValidating: false, isValid: false})
    const [pageLoading, setPageLoading] = useState({
        prerequisiteMetricData :true,
        metric : true 
    })

    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [graphDataFile, setGraphDataFile] = useState(null)
    const [goalPopup, setGoalPopup] = useState(false)
    const [isReset, setIsReset] = useState(false)
    const [graph, setGraph] = useState({})
    const [currentGoal,setCurrentGoal] = useState({})
    const [loading, setLoading] = useState(false)
    const [graphPreviewData, setGraphPreviewData] = useState({
        loading : false,
        data : []
    })
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const goals = prerequisiteMetricDataState.response.goals
    const isDesktop = useMediaQuery('(min-width:992px)')

    const emptyLinkObj = {
        originalname : "",
        url : ""
    }

    const initialValues = {
        goal: action === "edit" && !isEmpty(graph) && !isEmpty(graph.goal) && graph.goal._id  ? (!isReset ? graph.goal._id : "") : "",
        metric: action === "edit" && !isEmpty(graph.metric) ? ( !isReset ? graph.metric._id : "" ): "",
        graph_type: action === "edit" && !isEmpty(graph) && 
            graph.graph_type ? (!isReset ? graph.graph_type : "") :"",
        start_date: action === "edit" && !isEmpty(graph) && graph.start_date ? ( !isReset ? formatDate(graph.start_date) : ""):  "",
        end_date: action === "edit" && !isEmpty(graph) && graph.end_date ? (!isReset ? formatDate(graph.end_date) : "") :"",
        metric_description: action === "edit" && !isEmpty(graph) && graph.metric_description ? (!isReset ? graph.metric_description : "") : "",
        reference_links : action === "edit" && !isEmpty(graph) && !isEmpty(graph.reference_links) ? ( !isReset ? getLinks(graph.reference_links) : [emptyLinkObj]) : [emptyLinkObj] 
    }
    
    const validationSchema = (goal) => {
        return Yup.object({
            goal: Yup.string().required("Please select a goal"),
            metric: Yup.string().required(isEmpty(goal) ? "Please select a goal" : "Please select a metric"),
            graph_type: Yup.string()
            .when("goal", {
                is: () => {
                    if(graphDataFile){
                        return true
                    } else {
                        return false
                    }
                },
                then: Yup.string().required("Please select a graph type"),
                otherwise: Yup.string()
            }),
            metric_description: Yup.string(),
            start_date: Yup.date().required("Please select the start date")
                            .test("check range", "Invalid date", (value) => {
                                if(!isEmpty(goal)){
                                    return moment(value).isBetween(goal.start_date, goal.end_date, undefined, '[]');
                                } else {
                                    return false
                                }
                            })
                            .test("check goal","Please select a goal", (value) => {
                                if(!isEmpty(goal)){
                                    if(value){
                                        return true
                                    }else{
                                        return false
                                    }
                                }else{
                                    return false
                                }
                            }),
                            
            end_date: Yup.date().required("Please select the end date")
                        .min(Yup.ref('start_date'),"End date can't be before Start date")
                        .test("check goal","Please select a goal", (value) => {
                            if(!isEmpty(goal)){
                                if(value){
                                    return true
                                }else{
                                    return false
                                }
                            }else{
                                return false
                            }
                        })
                        .test('check for past dates', "End date can't be in past", (value) => {
                            if(value){
                                return (moment(value).endOf("day")).isSameOrAfter(moment())
                            } else {
                                return false
                            }
                        })
                        .test("check range","Invalid date", (value) => {
                            if(!isEmpty(goal)){
                                return moment(value).isBetween(goal.start_date, goal.end_date, undefined, '[]');
                            } else {
                                return false
                            }
                        }),
            reference_links: Yup.array().of(
                Yup.object().shape({
                    originalname: Yup.string(),
                    url : Yup.string().when("originalname", {
                        is: (value) => !isEmpty(value),
                        then: Yup.string().required("Please enter the url").url("Invalid url"),
                        otherwise : Yup.string().url("Invalid url")
                    })
                })
            )
        })
    } 

    function parseExcelFiles(file){
        var reader = new FileReader();
        setGraphPreviewData(prevState => {
            return {
                ...prevState,
                loading : true
            }
        })
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary', cellDates: true});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
    
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {
                blankrows: false,
                header: 'A',
                raw: false,
                rawNumbers: true
            });
            setGraphPreviewData(prevState => {
                return {
                    loading : false,
                    data : dataParse
                }
            })
        }
        reader.readAsBinaryString(file)
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.forEach((link) => (
            contentLink.push({ url: link.url, originalname: link.originalname})
        ))
        return contentLink
    }

    const onSubmit = (values) => {
        if(graphDataFile && !validate.isValid){
            return
        }
        const valuesCopy = JSON.parse(JSON.stringify(values))
        valuesCopy.start_date = (moment(valuesCopy.start_date).startOf('day')).toISOString()
        valuesCopy.end_date = (moment(valuesCopy.end_date).endOf('day')).toISOString()
        valuesCopy.reference_links = valuesCopy.reference_links.filter(item => item.original_name || item.url)
        if(navigator.onLine){
            setLoading(true)
            if(action === "edit"){
                updateMetric(addFormData(valuesCopy), queryParams.metric_id)
            } else {
                createMetric(addFormData(valuesCopy))
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    const handleBackArrowEvent = () => {
        history.goBack()
    }

    function toggleGoalPopup(){
        setGoalPopup(!goalPopup)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function getGoalById(objectives, id){
        return new Promise((resolve, reject) => {
            let appropriateGoalDoc = {}
            exit_loops:
            for(const objective of objectives){
                if(!isEmpty(objective.goals)){
                    for(const goal of objective.goals){
                        if(goal._id === id){
                            appropriateGoalDoc = goal
                            break exit_loops
                        }
                    }
                }
            }
            resolve(appropriateGoalDoc)
        })
    }

    function updateCurrGoal(id){
        getGoalById(goals, id).then(goal => {
            setCurrentGoal(goal)
        })
    }

    function getmetric(merticArray){
        return merticArray.map((metric) => {
            return {
                value : metric._id,
                label : metric.name
            }
        })
    }

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index !== i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index !== i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }

    function addFormData(goalData){

        let metricDataCopy = JSON.parse(JSON.stringify(goalData))

        let metricFormData = new FormData()
        if(!isEmpty(existingReferences)){
            metricDataCopy.reference_files = existingReferences.map((referenceFile) => referenceFile._id)
        }
        metricFormData.append(METRICS_ATTACHMENT_NAMES.data, JSON.stringify(metricDataCopy))

        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                metricFormData.append(METRICS_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }

        if(graphDataFile){
            metricFormData.append(METRICS_ATTACHMENT_NAMES.graph_data, graphDataFile)
        }
        return metricFormData
    }

    function onclickDownloadTemplate(){
        PublicWebClient.get('/data-template/download', {
            responseType: 'blob',
            headers : {
                'Content-Type' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data-template.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err)
            })
    }

    function onExistingDownloadFileClick(){
        axios.get(graph.graph.url,{
            responseType: 'blob',
            headers : {
                'Content-Type' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'data-template.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err)
        })
    }

    function getLoadingCondition(){
        if(action === "edit"){
            return !pageLoading.prerequisiteMetricData && !pageLoading.metric
        }else{
            return !pageLoading.prerequisiteMetricData
        }
    }

    function handleResetForm(resetForm) {
        setIsReset(true)
        resetForm()
        setReferenceFiles({files : []})
        setGraphDataFile(null)
        setExistingReferences([])
        setCurrentGoal({})
        setValidate({isValidating: false, isValid: false})
    }

    useEffect(() => {
        if(action === "edit"){
            if(isEmpty(prerequisiteMetricDataState.response)){
                prerequisiteMetricData()
            }
            if(isEmpty(specificMetricState.response)){
                requestSpecificMetric(queryParams.metric_id)
            }
        }else{
            if(isEmpty(prerequisiteMetricDataState.response)){
                prerequisiteMetricData()
                setPageLoading(prevState => {
                    return {
                        ...prevState,
                        prerequisiteMetricData : false
                    }
                })
            } 
        }
        if(!isEmpty(specificMetricState.response)){
            clearSpecificMetricState()
        }
        return() => {
            clearAlterState()
            clearPrerequisiteMetricData()
        }
    },[])

    useEffect(() => {
        if(!alterMetricState.loading && !isEmpty(alterMetricState.response)){
            fetchMetricChanges()
            if(action === "edit"){
                clearSpecificMetricState()
            }
            clearSpecificMetricState()
            setLoading(false)
            toast.success(action === "edit" ? "Metric updated successfully" :'Metric created successfully',{
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.goBack()
        }

        if(!alterMetricState.loading && !isEmpty(alterMetricState.error)){
            setLoading(false)
            if(!isEmpty(alterMetricState.error?.data)){
                toast.error( alterMetricState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } 
        }
    },[alterMetricState])

    useEffect(()=>{
        if(!specificMetricState.loading && !isEmpty(specificMetricState.response)){
            if(action === "edit"){
                setGraph(specificMetricState.response)
                setExistingReferences( !isEmpty(specificMetricState) && !isEmpty(specificMetricState.response.reference_files) ?
                                        specificMetricState.response.reference_files: [])
                setCurrentGoal(specificMetricState.response.goal)
                setPageLoading(prevState => {
                    return {
                        ...prevState,
                        metric: false
                    }
                })
            }
            
        }
    },[specificMetricState])

    useEffect(() => {
        if(!prerequisiteMetricDataState.loading ){
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    prerequisiteMetricData : false
                }
            })
        }
        
        if(!prerequisiteMetricDataState.loading && !isEmpty(prerequisiteMetricDataState.error)){
            console.log(prerequisiteMetricDataState.error)
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    prerequisiteMetricData : false
                }
            })
            if(prerequisiteMetricData.error?.data){
                toast.error(prerequisiteMetricData.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    },[prerequisiteMetricDataState])

    function validateData(graphType){

        setValidate(prevState => {
            return {
                ...prevState,
                isValidating: true
            }
        })

        function isPresent(dataArr, variable){
            let present = false
            for(const data of dataArr){
                if(data[variable] !== null && data[variable] !== undefined){
                    present = true
                    break;
                }
            }
            return present
        }

        function lineAndBarChartValidation(){
            if(size(graphPreviewData.data) > 1){
                if(graphPreviewData.data[0].A && (graphPreviewData.data[0].B || graphPreviewData.data[0].D)){
                    const dataArr = graphPreviewData.data.slice(1, size(graphPreviewData.data))
                    if(graphPreviewData.data[0].B && isPresent(dataArr, "B")){
                        if(graphPreviewData.data[0].C && isPresent(dataArr, "C")){
                            let isValid = true
                            for(const dataItem of dataArr){
                                if(dataItem.A === null || dataItem.A === undefined){
                                    isValid = false
                                    break;
                                }
                                if(dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                                    isValid = false
                                    break;
                                }
                                if(dataItem.C === null || dataItem.C === undefined || typeof dataItem.C !== 'number'){
                                    isValid = false
                                    break;
                                }
                            }
                            return isValid
                        } else {
                            let isValid = true
                            for(const dataItem of dataArr){
                                if(dataItem.A === null || dataItem.A === undefined){
                                    isValid = false
                                    break;
                                }
                                if(dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                                    isValid = false
                                    break;
                                }
                            }
                            return isValid
                        }
                    } else if(graphPreviewData.data[0].D && isPresent(dataArr, "D")){
                        let isValid = true
                        for(const dataItem of dataArr){
                            if(dataItem.A === null || dataItem.A === undefined){
                                isValid = false
                                break;
                            }
                            if(dataItem.D === null || dataItem.D === undefined || typeof dataItem.D !== 'number'){
                                isValid = false
                                break;
                            }
                        }
                        return isValid
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        function pieAndDoughtnutChartValidation(){
            if(size(graphPreviewData.data) > 1){
                if(graphPreviewData.data[0].A && (graphPreviewData.data[0].B || graphPreviewData.data[0].D)){
                    const dataArr = graphPreviewData.data.slice(1, size(graphPreviewData.data))
                    if(graphPreviewData.data[0].B && isPresent(dataArr, "B")){
                        let isValid = true
                        for(const dataItem of dataArr){
                            if(dataItem.A === null || dataItem.A === undefined){
                                isValid = false
                                break;
                            }
                            if(dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                                isValid = false
                                break;
                            }
                        }
                        return isValid
                    } else {
                        let isValid = true
                        for(const dataItem of dataArr){
                            if(dataItem.A === null || dataItem.A === undefined){
                                isValid = false
                                break;
                            }
                            if(dataItem.D === null || dataItem.D === undefined || typeof dataItem.D !== 'number'){
                                isValid = false
                                break;
                            }
                        }
                        return isValid
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        function ganttChartValidation(){
            if(size(graphPreviewData.data) > 1){
                if(graphPreviewData.data[0].A && graphPreviewData.data[0].B && graphPreviewData.data[0].C && graphPreviewData.data[0].D && graphPreviewData.data[0].E && graphPreviewData.data[0].F && graphPreviewData.data[0].G){
                    const dataArr = graphPreviewData.data.slice(1, size(graphPreviewData.data))
                    let isValid = true
                    for(const dataItem of dataArr){
                        if(dataItem.A === null || dataItem.A === undefined){
                            isValid = false
                            break;
                        }
                        if(dataItem.C === null || dataItem.C === undefined || typeof dataItem.C !== 'number' || dataItem.B === null || dataItem.B === undefined || typeof dataItem.B !== 'number'){
                            if(dataItem.D === null || dataItem.D === undefined || typeof dataItem.D !== 'number'){
                                isValid = false
                                break;
                            }
                        }
                        if(dataItem.E === null || dataItem.E === undefined || !isDate(dataItem.E)){
                            isValid = false
                            break;
                        }
                        if(dataItem.F === null || dataItem.F === undefined || !isDate(dataItem.F)){
                            isValid = false
                            break;
                        }
                        if(dataItem.G === null || dataItem.G === undefined){
                            isValid = false
                            break;
                        }
                    }
                    return isValid
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        if(size(graphPreviewData) <= 1){
            setValidate({
                isValidating: false,
                isValid: false
            })
            return
        }

        switch(graphType){

            case "Pie chart":
            case "Doughnut chart":{
                const result = pieAndDoughtnutChartValidation()
                setValidate({
                    isValidating: false,
                    isValid: result
                })
            } break;

            case "Gantt chart":{
                const result = ganttChartValidation()
                setValidate({
                    isValidating: false,
                    isValid: result
                })
            }break;

            default:{
                // line and bar chart
                const result = lineAndBarChartValidation()
                setValidate({
                    isValidating: false,
                    isValid: result
                })
            }
        }
    }

    function getGraphPreviewComponent(graph){
        switch(graph){
            case "Bar chart" :
                return (
                    <BarChart graphDetail={graphPreviewData.data} variant="preview" />
                )
            
            case "Pie chart" :
                return (
                    <PieChart graphDetail={graphPreviewData.data} variant="preview" />
                )

            case "Doughnut chart" : 
                return (
                    <DoughnutChart variant="preview" graphDetail={graphPreviewData.data} />
                )

            case "Gantt chart" :
                return (
                    <GanttChart graphDetail={graphPreviewData.data} variant="preview" />
                )

            default : 
                return (
                    <LineChart graphDetail={graphPreviewData.data} variant="preview"/>
                )
        }
    }

    function graphList(graphData){
        let graphLists = []
        if(action === "edit"){
            if(graphDataFile){
                if(size(graphData[0]) >=6){
                    if(size(graphData) <= 11){
                        graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart", "Gantt chart")
                    } else {
                        graphLists.push("Line chart", "Bar chart", "Gantt chart")
                    }
                }else if(size(graphData) <= 11){
                    graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart")
                }else {
                    graphLists.push("Line chart", "Bar chart")
                }
            }else{
                if(!isEmpty(graph) && !isEmpty(graph.graph)){
                    if(size(graph.graph?.data[0]) >= 6){
                        if(size(graph.graph?.data) <= 11){
                            graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart", "Gantt chart")
                        } else {
                            graphLists.push("Line chart", "Bar chart", "Gantt chart")
                        }
                    }else if(size(graph.graph?.data) <= 11){
                        graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart")
                    }else{
                        graphLists.push("Line chart", "Bar chart")
                    }
                }
            }
        }else{
            if(size(graphData[0]) >=6){
                if(size(graphData) <= 11){
                    graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart", "Gantt chart")
                } else {
                    graphLists.push("Line chart", "Bar chart", "Gantt chart")
                }
            } else if(size(graphData) <= 11){
                graphLists.push("Line chart", "Bar chart", "Pie chart", "Doughnut chart")
            } else {
                graphLists.push("Line chart", "Bar chart")
            }
        }
        return graphLists
    }

    function showGraphList(){
        if(action === "edit" && !isEmpty(graph) && !isEmpty(graph?.graph)){
            return true
        }else {
            if(!isEmpty(graphPreviewData.data) && graphDataFile){
                return true
            }else{
                return false
            }
        }
    }

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function handleNotification(){
        history.push(`/notification-reference?selected=notification&tab=inbox&action=metrics`)
    }

    function getForm(){
        return (
            <div>
                <Formik 
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema= {validationSchema(currentGoal)}
                    onSubmit={onSubmit}
                    >
                    {props => {
                        const {values, touched, errors, handleChange, handleBlur, resetForm, setFieldValue, setFieldTouched } = props
                        return (
                            <>
                                <Form>
                                    <Grid container spacing={ isDesktop ? "" : 2 }>
                                        <Grid item md={6} xs={12} sm={6} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                            <InputBox type="text"
                                                variant="withOnClick"
                                                name="goal"
                                                label="Goal"
                                                placeholder="Select a goal"
                                                onClick={toggleGoalPopup}
                                                value={ !isEmpty(currentGoal) ? currentGoal.name :""}
                                                errorMgs={errors.goal && touched.goal ? errors.goal : ""} />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                            <SelectBoxValueById options={!isEmpty(currentGoal) ? getmetric(currentGoal.metrics) : []}
                                                name="metric"
                                                label="Metric"
                                                placeholder="Select a metric"
                                                value={values.metric}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMgs={touched.metric && errors.metric ? errors.metric : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className="mt-3">
                                        <TextAreaBox name="metric_description" 
                                            placeholder="Enter the metric description"
                                            label="Description"
                                            rows={ isDesktop ? 4 : ""}
                                            value={values.metric_description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errormgs={touched.metric_description && errors.metric_description ? errors.metric_description : ''}
                                        />
                                    </div>
                                    <Grid container className={isDesktop ? "mt-3" : "mt-1"} spacing={ isDesktop ? "" : 2 }>
                                        <Grid item md={6} xs={12} sm={6} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                            <DatePicker type="date"
                                                name="start_date"
                                                label="Start Date"
                                                value={values.start_date}
                                                min={!isEmpty(currentGoal) ? formatDate(currentGoal.start_date) : ""}
                                                max={!isEmpty(currentGoal) ? formatDate(currentGoal.end_date) : ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMgs={touched.start_date && errors.start_date ? errors.start_date : ''}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12} sm={6} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                            <DatePicker type="date" 
                                                label="End Date"
                                                name="end_date"
                                                min={moment().format("YYYY-MM-DD")}
                                                max={!isEmpty(currentGoal) ? formatDate(currentGoal.end_date) : ""}
                                                value={values.end_date}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMgs={touched.end_date && errors.end_date ? errors.end_date : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className="mt-3">
                                        <FieldArray name="reference_links" >
                                            {fieldArrayProps => {
                                                const { push, remove, form } = fieldArrayProps
                                                const { values } = form 
                                                const { reference_links } = values
                                                return (
                                                    <div>
                                                        <Grid container spacing={2}>
                                                        {
                                                            reference_links.map((file, index) => (
                                                                <>
                                                                        <Grid item md={4} xs={12} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                                            <Field name={`reference_links[${index}].originalname`}>
                                                                                {
                                                                                    props => {
                                                                                        const {field,meta} = props
                                                                                        return (
                                                                                            <>
                                                                                                <InputBox type="text" 
                                                                                                    label="Link Name"
                                                                                                    placeholder="Enter the link name"
                                                                                                    {...field}
                                                                                                    errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                                />
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            </Field>
                                                                        </Grid>
                                                                        <Grid item md={8} xs={12} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                            <div className='d-flex'>
                                                                                <Field name={`reference_links[${index}].url`}>
                                                                                    {
                                                                                        props => {
                                                                                            const {field,meta} = props
                                                                                            return (
                                                                                                <InputBox type="url" 
                                                                                                    label="Link Url"
                                                                                                    placeholder="http://www.example.com"
                                                                                                    {...field}
                                                                                                    errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                                    containerClassName="flex-fill"
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                </Field>
                                                                                {
                                                                                    size(reference_links) > 1 ?
                                                                                        <div className="d-flex align-items-center ms-2">
                                                                                            <div>
                                                                                                <IconButton onClick={() => remove(index)}>
                                                                                                    <CloseIcon />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                        </Grid>
                                                                </>
                                                            ))
                                                        }
                                                        </Grid>
                                                        <div className='mt-2'>
                                                            <CustomizeButton variant="secondary-start-icon-button" label="Add Link" StartIcon={<AddIcon/>} handleClick={() => push('')} />
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </FieldArray>
                                    </div>
                                    { 
                                        showGraphList() ?
                                            <div className="mt-4" style={{ width: isDesktop ? "50%" : "100%" }}>
                                                <SelectBox 
                                                    options={graphList(graphPreviewData.data)}
                                                    label="Select Graph"
                                                    name="graph_type"
                                                    value={values.graph_type}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        validateData(event.target.value)
                                                    }}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.graph_type && errors.graph_type ? errors.graph_type : ''}
                                                />
                                            </div> 
                                            : null
                                    }
                                    <div>
                                        <>
                                            <div className={ graphDataFile ?  'mt-2' : "mt-4" }>
                                                <CustomizeButton variant="primary-start-icon-button" startIcon={<DownloadIcon/>} handleClick={onclickDownloadTemplate} label="Template"  />
                                            </div>
                                            {
                                                action === "edit" ? 
                                                    <>
                                                        { 
                                                            !isReset && !isEmpty(graph.graph) ? 
                                                                graphDataFile ? 
                                                                    null :
                                                                    <div className="d-flex mt-2">
                                                                        <p className="one-line-wrap lato-fs15-normal-lh20 mb-0">{graph?.graph?.orignalname || graph.graph?.filename}</p>
                                                                        <IconButton 
                                                                            className="ms-auto" 
                                                                            onClick={
                                                                                ()=> window.open(graph.graph.url, "_blank") 
                                                                                // onExistingDownloadFileClick
                                                                            }
                                                                        >
                                                                            <DownloadIcon/>
                                                                        </IconButton>
                                                                    </div> 
                                                                : null
                                                        }
                                                    </>
                                                    : null 
                                            }
                                            { 
                                                graphDataFile ? 
                                                    null : 
                                                    <div className='mt-3'>
                                                        <UploadFiles 
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                graphDataFileRef.current.click();
                                                            }} 
                                                            label="Click to upload graph data"
                                                            variant={ isDesktop ? "" : "mobile-view" } 
                                                        />
                                                    </div>
                                            }
                                        </>
                                        <div className="mt-2">
                                            { 
                                                graphDataFile ?
                                                    <div className='mt-3 d-flex flex-column'>
                                                        <h6 className="font-weight-bold lato-fs16-normal-lh20">Graph Data</h6>
                                                        <div className="d-flex">
                                                            <p className="mb-0 lato-fs15-normal-lh20 one-line-wrap ">{graphDataFile.name}</p> 
                                                            <FontAwesomeIcon 
                                                                className="ms-auto me-2 secondary-color pt-cursor" 
                                                                icon={faTrash} 
                                                                onClick={() => {
                                                                    setGraphDataFile(null)
                                                                    setValidate({isValidating: false, isValid: false})
                                                                    if(!isEmpty(graph) && !isEmpty(graph.graph) && graph.graph_type) {
                                                                        setFieldValue("graph_type", graph.graph_type)
                                                                        setFieldTouched("graph_type", true)
                                                                    } else {
                                                                        setFieldValue("graph_type", "")
                                                                        setFieldTouched("graph_type", true)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        { 
                                                            values?.graph_type ?
                                                                !graphPreviewData.loading ?
                                                                    <div>
                                                                        {
                                                                            validate.isValidating ? 
                                                                            null : validate.isValid ? 
                                                                            getGraphPreviewComponent(values.graph_type) :
                                                                            <h6 className='color-red'>Unprocessable file</h6>
                                                                        }
                                                                    </div>
                                                                    : null
                                                                : null
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <input 
                                        type="file"
                                        style={{display:"none"}} 
                                        ref={graphDataFileRef} 
                                        accept=".xlsx,.xls"
                                        name="graph_data"
                                        onChange={(event) =>{
                                            const file = event.target.files[0]                                                       
                                            if(file){
                                                setGraphDataFile(file)
                                                parseExcelFiles(file)
                                                setFieldValue("graph_type", "")
                                                setFieldTouched("graph_type", true)
                                            }else{
                                                setGraphDataFile(null)
                                            }
                                        }} 
                                        onClick={(event) => {
                                            event.target.value = null
                                        }} 
                                    />
                                    <div>
                                        <div className="mt-4">
                                            { 
                                                !isEmpty(existingReferences) ? 
                                                    existingReferences.map((reference, index) => (
                                                        <div key={index} className="d-flex mb-2">
                                                            <p className="filename one-line-wrap mb-0 lato-fs15-normal-lh20"> {reference.originalname}</p>
                                                            <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                        </div>
                                                    )) 
                                                    : null
                                            }
                                            { 
                                                !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? 
                                                    referenceFiles.files.map((file,index)=>(
                                                        <div key={index} className=" d-flex mb-2">
                                                            <p className="mb-0 filename one-line-wrap lato-fs15-normal-lh20">{file.name}</p>
                                                            <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                        </div>
                                                    )) 
                                                    : null
                                            }
                                        </div>
                                        <div className='mt-3'>
                                            <UploadFiles 
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    referenceFileRef.current.click();
                                                }} 
                                                label="Click to upload attachment"
                                                variant={ isDesktop ? "" : "mobile-view" }    
                                            />
                                        </div>
                                    </div>
                                    <input 
                                        type="file"
                                        style={{display:"none"}} 
                                        ref={referenceFileRef} 
                                        multiple
                                        accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx"
                                        name="reference"
                                        onChange={(event) =>{
                                            const file = event.target.files
                                            if(file){
                                                handleReferenceFileAddition(file)
                                            }
                                        }}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }} 
                                    />
                                    <div className="d-flex justify-content-center align-center mt-4 mb-2">
                                        <CustomizeButton 
                                            className={isDesktop ? "me-5" : "me-4"} 
                                            type="reset"  
                                            variant="secondary-color-button"
                                            handleClick={() => handleResetForm(resetForm)} 
                                            label="Clear All"
                                        />
                                        <CustomizeLoadingButton 
                                            variant="accept-loading-button-v2" 
                                            label={action === "edit" ? "Update Metric" : "Create Metric"}
                                            type="submit" 
                                            loading={loading}
                                        />
                                    </div>
                                    {
                                        goalPopup ? 
                                            <GoalListPopup 
                                                handleClose={toggleGoalPopup} 
                                                open={goalPopup}
                                                onBlur={handleBlur}
                                                onChange={(event) => {
                                                        updateCurrGoal(event.target.value)
                                                        handleChange(event)
                                                        toggleGoalPopup()
                                                        setFieldValue("metric","")
                                                    }}
                                                options={goals}
                                                value={values.goal}
                                                name="goal"
                                            /> 
                                            : null
                                    }
                                </Form>
                            </>
                        )
                    }}
                </Formik>
            </div>
        )
    }

    return (
        <>
            <Container>
                {
                    isDesktop ?
                    <>
                        <Navbar 
                            enableMore={true}
                            enableLogout={true}
                            enableProfileButton={true}
                            moreRight="202px" 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen}
                            enableNotification={true}
                            notificationOnClick={handleNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {
                                        getLoadingCondition() && !isEmpty(prerequisiteMetricDataState.response) ?  
                                            <>
                                                <div className='mt-3'>
                                                    <h5 className='lato-fs22-normal-lh32 primary-text-color font-bold'>{action === "edit" ? "Update Metric" : "Create Metric"}</h5>
                                                </div>
                                                <div className='mt-3'>
                                                    {getForm()}
                                                </div>
                                            </> 
                                        : null 
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer} 
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={handleNotification}
                        />
                        <div className="container pb-60px">
                            { !isEmpty(prerequisiteMetricDataState.response) ?
                                <>
                                    <div className='mt-3 px-3'>
                                        <Header heading={action === "edit" ? "Update Metric" : "Create Metric"}
                                            handleBackArrowEvent={handleBackArrowEvent} />
                                    </div>
                                    <div className='mt-3 px-3'>
                                        {getForm()}
                                    </div>
                                </>
                            : null}
                        </div>
                        <BottomNavigation />
                    </> 
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state,ownProps) => {
    return{
        ownProps : ownProps,
        prerequisiteMetricDataState : state.fetchCreateMetricData,
        alterMetricState : state.metricCUD,
        fetchMetricState : state.fetchMetricData,
        specificMetricState : state.specificMetric,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createMetric: (metricDetails) => dispatch(createMetrics(metricDetails)),
        prerequisiteMetricData: () => dispatch(fetchCreateMetricData()),
        requestSpecificMetric : (graphId) => dispatch(specificMetric(graphId)),
        clearAlterState : () => dispatch(clearCreateMetrics()),
        updateMetric : (metricDetails, metricId) => dispatch(updateMetric(metricDetails, metricId)),
        clearSpecificMetricState : () => dispatch(clearSpecificMetricState()),
        clearPrerequisiteMetricData : () => dispatch(clearFetchCreateMetricData()),
        fetchMetricChanges : () => dispatch(metricActionChanges())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditMetric)