import React from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const StyledTextField = styled(TextField)({
    width:"100%",
    fontFamily: '"Lato", sans-serif',
    fontSize: '14px',
    '& label': {
        fontFamily: '"Lato", sans-serif'
    },
    // input label when focused
    "& label.Mui-focused": {
        color: "var(--primary-color-v2) !important"
    },
    // focused color for input with variant='standard'
    '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--primary-color-v2) !important',
    }
})

const StandardInputBox = (props) => {

    return (
        <>
            <StyledTextField
                type={props.type} 
                id="standard-basic"
                variant="standard"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                min={props.min}
                required={props.required}
                fullWidth
                size={props.size}
                autoFocus
                InputLabelProps={{
                    shrink: true
                }}
                readOnly= {props.readOnly ? true : false} 
                autoComplete="off"
            />
            {
                props.errorMgs ? 
                    <div className="ms-1 mb-1">
                        <span className="error-message">{props.errorMgs}</span>
                    </div>
                    : null
            }
        </>
    )
}

export default StandardInputBox