import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import './level-indicator.css'

const LevelIndicator = (props) => {

    function getLevel(){
        let maxLevel = props.maxLevel
        let level =[];
        for(let i = 0 ; i <= maxLevel; i++){
            level.push(<FontAwesomeIcon 
                            key={i}
                            className={ props.level == i ? "me-1 mb-2px fs-8px acent-color" : "me-1 mb-2px fs-6px secondary-color-light"} 
                            icon={faCircle} />)
        }
        return level
    }

    return (
        <div className={ props.containerClassName ? props.containerClassName : "d-flex align-items-center mt-1" }>
            {getLevel()}
        </div>
    )
}

export default LevelIndicator