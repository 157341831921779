import { REQUEST_FETCH_CREATE_METRIC_DATA,  
         FETCH_CREATE_METRIC_DATA_SUCCESS,
         FETCH_CREATE_METRIC_DATA_FAILURE,
         CLEAR_FETCH_CREATE_METRIC_DATA } from './FetchCreateMetricDataTypes'

export const requestFetchCreateMetricData = () => {
    return {
        type : REQUEST_FETCH_CREATE_METRIC_DATA
    }
}

export const fetchCreateMetricDataSuccess = (resData) => {
    return {
        type : FETCH_CREATE_METRIC_DATA_SUCCESS,
        payload: resData
    }
}

export const fetchCreateMetricDataFailure = (error) => {
    return {
        type : FETCH_CREATE_METRIC_DATA_FAILURE,
        payload:  error
    }
}

export const clearFetchCreateMetricData = () => {
    return {
        type: CLEAR_FETCH_CREATE_METRIC_DATA
    }
}