import React from 'react'

const BodyContainer = (props) => {

    return (
        <div className={props.className ? `container-bg review-container ${props.className}` :"container-bg review-container px-1"}>
            {props.children}
        </div>
    )
}

export default BodyContainer