import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import Chip from '@mui/material/Chip'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import PopUp from '../../common/popup/PopUp'
import ObjectiveTab from '../about-popup-tab/ObjectiveTab'
import GoalsTab from '../about-popup-tab/GoalsTab'
import MetricTab from '../about-popup-tab/MetricTab'
import DevelopmentTab from '../about-popup-tab/DevelopmentTab'
import CourseTab from '../about-popup-tab/CourseTab'
import './video-conference-about-popup.css'
import ScrollableTabheader from '../tabs/scrollable-tab/scrollable-tab-header/ScrollableTabheader'
import SimpleTabPanel from '../tabs/simple-tab/Simple-tab-panel/SimpleTabPanel'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection : 'column',
        flexWrap: 'wrap',
    },
    chipMargin :{
        marginRight : "6px",
        marginTop: "6px",
        fontSize : "14px",
        fontFamily:"'oxygen',sans-serif"
    },
}));


const VideoConferenceAboutPopup = (props) => {

    const classes = useStyles()

    const [ value, setValue ] = useState("objectives")

    const isDesktop = useMediaQuery('(min-width:992px)')

    function handleProceed(){
        const array = formatToActionsArray()
        if(array.length > 0 && array.length <= 2){
            props.setSelected("subject", [...array])
            props.handleClose()
        }
    }

    function handleChange(event,value){
        setValue(value)
    }

    function getSelectedActionsLength(){
        return props.actions.objectives.length + props.actions.goals.length + props.actions.graphs.length + props.actions.learningareas.length + props.actions.courses.length
    }

    function formatToActionsArray(){
        let actionArr = []
        if(!isEmpty(props.actions.objectives)){
            for(const obj of props.actions.objectives){
                actionArr.push({action: obj.value, label: obj.label, action_of: "objectives"})
            }
        }
        if(!isEmpty(props.actions.goals)){
            for(const obj of props.actions.goals){
                actionArr.push({action: obj.value, label: obj.label, action_of: "goals"})
            }
        }
        if(!isEmpty(props.actions.graphs)){
            for(const obj of props.actions.graphs){
                actionArr.push({action: obj.value, label: obj.label, action_of: "graphs"})
            }
        }
        if(!isEmpty(props.actions.learningareas)){
            for(const obj of props.actions.learningareas){
                actionArr.push({action: obj.value, label: obj.label, action_of: "learningareas"})
            }
        }
        if(!isEmpty(props.actions.courses)){
            for(const obj of props.actions.courses){
                actionArr.push({action: obj.value, label: obj.label, action_of: "courses"})
            }
        }
        return actionArr
    }
    
    return (
        <PopUp handleClose={props.handleClose} open={props.open} maxWidth="xs" fullWidth={true} >
            <div className={isDesktop ? "" : "scroll-container flex-column"}
                style={{minHeight:"240px"}}>
                {getSelectedActionsLength() < 2 ? 
                    <>
                        <ScrollableTabheader value={value} 
                            onChange={handleChange}
                            tabArray={[
                                {name : "Vcfs", value:"objectives"},
                                {name : "Goals", value:"goals"},
                                {name : "Metrics", value:"metrics"},
                                {name : "Learning Areas", value:"learnings"},
                                {name : "Courses",value:"courses"}]}  />
                        <SimpleTabPanel selectedTab={value} value="objectives" containerClassname="transparent-bg" >
                            <ObjectiveTab selected={props.actions} setSelected={props.setActions} handleClose={props.handleClose} />
                        </SimpleTabPanel>
                        <SimpleTabPanel selectedTab={value} value="goals" containerClassname="transparent-bg">
                            <GoalsTab selected={props.actions} setSelected={props.setActions} handleClose={props.handleClose} />
                        </SimpleTabPanel>
                        <SimpleTabPanel selectedTab={value} value="metrics" containerClassname="transparent-bg">
                            <MetricTab selected={props.actions} setSelected={props.setActions} handleClose={props.handleClose} />
                        </SimpleTabPanel>
                        <SimpleTabPanel selectedTab={value} value="learnings" containerClassname="transparent-bg">
                            <DevelopmentTab selected={props.actions} setSelected={props.setActions} handleClose={props.handleClose} />
                        </SimpleTabPanel>
                        <SimpleTabPanel selectedTab={value} value="courses" containerClassname="transparent-bg">
                            <CourseTab selected={props.actions} setSelected={props.setActions} handleClose={props.handleClose} />
                        </SimpleTabPanel>
                    </>
                    : null 
                }
                { !isEmpty(props.actions.objectives) || !isEmpty(props.actions.goals) || !isEmpty(props.actions.metrics) || !isEmpty(props.actions.learningareas) || !isEmpty(props.actions.courses) ?
                    <div className="ps-4 pe-4 pt-3">
                        {
                            !isEmpty(props.actions.objectives)?
                            <div>
                                <h5 className="mb-0 lato-fs16-normal-lh20 primary-text-color font-bold mt-2">Selected VCF</h5>
                                {props.actions.objectives.map((objective,index) => {
                                    return (
                                        <Chip 
                                            className={classes.chipMargin} 
                                            key={index}
                                            label={objective.label}
                                            onDelete={() => props.removeSelectedAction("objectives",index)}
                                        />
                                    )
                                })}
                            </div>: null
                        }
                        {
                            !isEmpty(props.actions.goals)?
                            <div>
                                <h5 className="mb-0 lato-fs16-normal-lh20 primary-text-color font-bold mt-3">Selected Goals</h5>
                                {props.actions.goals.map((goal,index) => {
                                    return (
                                        <Chip 
                                            sx={{marginRight:"6px",marginTop:"6px",fontSize:"14px",fontFamily:"'Lato',sans-serif"}} 
                                            key={index}
                                            label={goal.label}
                                            onDelete={() => props.removeSelectedAction("goals",index)}
                                        />
                                    )
                            })}
                            </div> : null
                        }
                        {
                            !isEmpty(props.actions.graphs)?
                            <div>
                                <h5 className="mb-0 lato-fs16-normal-lh20 primary-text-color font-bold mt-2">Selected Metrics</h5>
                                {props.actions.graphs.map((metric,index) => {
                                    return (
                                        <Chip 
                                            className={classes.chipMargin} 
                                            key={index}
                                            label={metric.label}
                                            onDelete={() => props.removeSelectedAction("graphs",index)}
                                        />
                                    )
                                })} 
                            </div>: null
                        }
                        {
                            !isEmpty(props.actions.learningareas)?
                            <div>
                                <h5 className="mb-0 lato-fs16-normal-lh20 primary-text-color font-bold mt-2">Selected Learning Areas</h5>
                                {props.actions.learningareas.map((developmentArea,index) => {
                                    return (
                                        <Chip 
                                            className={classes.chipMargin} 
                                            key={index}
                                            label={developmentArea.label}
                                            onDelete={() => props.removeSelectedAction("learningareas",index)}
                                        />
                                    )
                                })} 
                            </div> : null
                        }
                        {
                            !isEmpty(props.actions.courses)?
                            <div>
                                <h5 className="mb-0 lato-fs16-normal-lh20 primary-text-color font-bold mt-2">Selected Courses</h5>
                                {props.actions.courses.map((course,index) => {
                                    return (
                                        <Chip 
                                            className={classes.chipMargin} 
                                            key={index}
                                            label={course.label}
                                            onDelete={() => props.removeSelectedAction("courses",index)}
                                        />
                                    )
                                })} 
                            </div>: null
                        }
                        
                    </div> : null
                }
                <div className="d-flex justify-content-center align-items-center mt-3 mb-3" >
                    <CustomizeButton variant="secondary-color-button" label="Close" className="me-4" handleClick={props.handleClose} />
                    <CustomizeButton variant="primary-color-button" label="Proceed" handleClick={handleProceed} />
                </div>
            </div>
        </PopUp>
    )
}

export default VideoConferenceAboutPopup