import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'

const LandingPageCard = (props) => {
    return (
        <Card className={props.containerClassName}>
            <CardContent sx={{'&:last-child':{paddingBottom:"5%"},padding:"5%"}}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{display:"flex",alignItems:"flex-end"}}>
                            <h2 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>{props.heading}</h2>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"flex-end", paddingRight:"16px"}}>
                            <img src={props.image} alt="vcf image" style={{height:"110px"}}></img>
                        </Grid>
                    </Grid>
                    <p className='pt-2 lato-fs17-normal-lh20 primary-text-color mb-2'>{props.detail}</p>
                </div>
            </CardContent>
        </Card>
    )
}

export default LandingPageCard