import React from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

const NotificationCard = (props) => {

    return (
        <>
            <Card
                className={ props.className ? `mt-3 ${props.className} pt-cursor` : "mt-3 pt-cursor"}
                onClick={props.onClick}
            >
                <CardContent 
                    style={{ 
                        padding:"0.5rem 1.5rem",
                        display: "flex",
                        flexDirection:"row",
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                    }}
                >
                    <div className="me-2 flex-fill" style={{overflow: "hidden"}}>
                        <h2 className="lato-fs-20-normal-lh32 mb-0" dangerouslySetInnerHTML={{__html: props.subject}} />
                        {
                            props.message ?
                                <Typography
                                    noWrap
                                    component="div"
                                    variant="p"
                                    sx={{
                                        fontSize : "17px",
                                        fontFamily: "'Lato', sans-serif",
                                        fontWeight:400
                                    }}
                                >
                                    {props.message}
                                </Typography> 
                                : null
                        }
                        <Typography
                            noWrap
                            component="div"
                            variant="p"
                            sx={{
                                fontSize : "15px",
                                fontFamily: "'Lato', sans-serif",
                                color : "var(--tertiary-text-color-v2)"
                            }}
                        >
                            {props.person}
                        </Typography>
                    </div>
                    <div className='min-width-100px mt-2'>
                        <span className='lato-fs14-normal-lh20'>{props.time}</span>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default NotificationCard