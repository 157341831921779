import * as Yup from 'yup'
import {isEmpty} from 'lodash'
import moment from 'moment'

export const courseValidationSchema = (existingFiles, newFiles, links) => {
    return Yup.object({
        name: Yup.string().required("Please enter your course name"),
        description: Yup.string(),
        course_content_links : Yup.array().when('name', {
            is: (value) => {
                if(!isEmpty(links)){
                    return isEmpty(existingFiles) && isEmpty(newFiles) && isEmpty(links.filter(link => !isEmpty(link)))
                } else {
                    return isEmpty(existingFiles) && isEmpty(newFiles)
                }
            },
            then: Yup.array().of(Yup.string().required('Pick or fill at least one course content file or link').url('Invalid url')),
            otherwise : Yup.array().of(Yup.string().url('Invalid url'))
        })
    })
}

export const learningAreaValidationSchema = () => {
    return Yup.object({
        name: Yup.string().required("Please enter your learning area name"),
        description: Yup.string(),
        start_date: Yup.date().required("Please select start date"),
        end_date: Yup.date()
                    .required("Please select end date")
                    .min(Yup.ref('start_date'), "End date can't be before start date")
                    .test('past end date validation', "End date cannot be in the past", (value) => {
                        const formattedValue = moment(value).endOf("day")
                        return formattedValue.isSameOrAfter(moment());
                    })
    })
}

