import React from 'react'
import Card  from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const SimpleCard = (props) => {

    return (
        <Card>
            <CardContent sx={{height:"200px"}}>
                <div className='d-flex h-100 align-items-center justify-content-center flex-column text-center'>
                    <FontAwesomeIcon icon={faTriangleExclamation} size="2x" style={{color:"var(--primary-color-v2)"}} />
                    <Typography sx={{   fontSize:"18px", 
                                        fontFamily:"'Lato',sans-serif",
                                        color:"var(--primary-text-color-v2)",
                                        marginTop:"10px"}} >{props.name}</Typography>
                </div>
            </CardContent>
        </Card>
    )
}

export default SimpleCard