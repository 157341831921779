export const REQUEST_MODIFY_OBJECTIVE = "request_modify_objective"
export const MODIFY_OBJECTIVE_SUCCESS = "modify_objective_success"
export const MODIFY_OBJECTIVE_FAILURE = "modify_objective_failure"

export const ADD_OBJECTIVE_REFERENCE_FILE = "add_objective_reference_file"
export const ADD_OBJECTIVE_WALLPAPER_FILE = "add_objective_wallpaper_file"
export const ADD_OBJECTIVE_ICON_FILE = "add_objective_icon_file"

export const REMOVE_OBJECTIVE_REFERENCE_FILE = "remove_objective_reference_file"
export const REMOVE_OBJECTIVE_WALLPAPER_FILE = "remove_objective_wallpaper_file"
export const REMOVE_OBJECTIVE_ICON_FILE = "remove_objective_icon_file"

export const SAVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR = "save_one_modify_objective_validation_error"
export const REMOVE_ONE_MODIFY_OBJECTIVE_VALIDATION_ERROR = "remove_one_modify_objective_validation_error"
export const SAVE_MANY_MODIFY_OBJECTIVE_VALIDATION_ERROR = "save_many_modify_objective_validation_error"

export const CLEAR_MODIFY_OBJECTIVE_STATES = "clear_modify_objective_states"