import { REQUEST_DELETE_METRIC,  
         DELETE_METRIC_SUCCESS, 
         DELETE_METRIC_FAILURE, 
         CLEAR_DELETE_METRIC_DATA } from './DeleteMetricTypes'

const initialState = {
    loading : false,
    response : {},
    error : null 
}

const deleteMetricReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_DELETE_METRIC: 
            return {
                ...state,
                loading: true,
                error: null
            }

        case DELETE_METRIC_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case DELETE_METRIC_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_DELETE_METRIC_DATA: 
            return {
                ...state,
                loading : false,
                response : {},
                error : null 
            }

        default : 
            return state
    }
}

export default deleteMetricReducer