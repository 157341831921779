import ProtectedWebClient from "../utils/protected-web-client"
import { requestObjectiveReview,
        fetchObjectiveReviewSuccess,
        fetchObjectiveReviewFailure } from "../redux/review-team-action/objective-review/objectiveReview.action"
import { requestObjectiveUnderReview,
        fetchObjectiveUnderReviewSuccess,
        fetchObjectiveUnderReviewFailure } from "../redux/review-team-action/objective-under-review/objectiveUnderReview.action"
import { requestSpecificObjectiveForReview,
        saveSpecificObjectiveForReview,
        specificObjectiveForReviewFailure } from "../redux/review-team-action/specific-objective/specificObjective.action"

export const requestObjectiiveReviewAll = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(requestObjectiveReview())
            const response = await ProtectedWebClient.get(`/objective/list-by-lvl?user=${userId}`)
            dispatch(fetchObjectiveReviewSuccess(response.data))
        }catch(error){
            dispatch(fetchObjectiveReviewFailure(error.response))
        }
    }
}

export const fetchObjectiveUnderReview = (userId) => {
    return async (dispatch) => {
        try {
            dispatch(requestObjectiveUnderReview())
            const response = await ProtectedWebClient.get(`/objective/${userId}/under-his-review`)
            dispatch(fetchObjectiveUnderReviewSuccess(response.data))
        }catch(error){
            dispatch(fetchObjectiveUnderReviewFailure(error.response))
        }
    }
}

export const requestSpecificObjective = (objectiveId) => {
    return async (dispatch) => {
        try {
            dispatch(requestSpecificObjectiveForReview())
            const response = await ProtectedWebClient.get(`/objective/${objectiveId}/read?sub_objectives=false`)
            dispatch(saveSpecificObjectiveForReview(response.data))
        }catch(error){
            dispatch(specificObjectiveForReviewFailure(error.response))
        }
    }
}

export const objectiveReviewAcceptAction = (id) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.get(`/review/objective/${id}/accept`)
            .then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
    })
} 

export const objectiveReviewRejectAction = (id,values) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.post(`/review/objective/${id}/reject`,values)
            .then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
    })
} 

export const objectiveReviewModifyAction = (id,values) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.put(`/review/objective/${id}/modify`,values)
            .then((res) => {
                resolve(res)
            }).catch(error => {
                reject(error)
            })
    })
}