import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router'
import { isEmpty, toArray } from 'lodash'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { faSignal, faThLarge } from '@fortawesome/free-solid-svg-icons'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import HeaderContainer from '../../../common/header-container/HeaderContainer'
import { specificMetric } from '../../../services/metric.service'
import BodyContainer from '../../../common/body-container/BodyContainer'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import MetricCard from '../../../components/metric-card/MetricCard'
import ReferenceFiles from '../../../components/reference-files/ReferenceFiles'
import { newMailAdded } from '../../../redux/notification/sent/sent.actions'
import { acceptAction } from '../../../services/notification.service'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import goalIcon from '../../../assets/icons/active-bullseye.svg'
import RejectPopup from '../../../components/reject-popup/RejectPopup'
import { clearNotificationDetailState } from '../../../redux/notification/notification-detail/notificationDetail.action'
import ActionButton from '../../../components/action-button/ActionButton'
import StatusChip from '../../../components/status-chip/StatusChip'
import Container from '../../../components/desktop-layout/container/Container'
import DesktopActionButton from '../../../components/desktop-layout/desktop-action-button/DesktopActionButton'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../../components/desktop-layout/description/Description'
import Header from '../../../common/header/Header'

const MetricInboxDetail = (props) => {

    const { specificMetricState, requestSpecificMetric, clearNotificationDetailState } = props

    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const metric = specificMetricState.response
    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [ pageLoading,setPageLoading ] = useState(true)
    const [ rejectPopup,setRejectPopup ] = useState(false)
    const [ menu, setMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    useEffect(() => {
        if(isEmpty(specificMetricState.response)){
            requestSpecificMetric(params.metricId)
        }
    },[])

    useEffect(() => {
        if(!specificMetricState.loading && !isEmpty(specificMetricState.response)){
            // console.log(specificMetricState.response)
            setPageLoading(false)
        }

        if(!specificMetricState.loading && !isEmpty(specificMetricState.error)){
            console.log(specificMetricState.error)
            setPageLoading(false)
            if(specificMetricState.error?.data){
                toast.error(specificMetricState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    },[specificMetricState])

    function handleAcceptAction(){
        acceptAction(queryParams.actionId,"graph")
        .then((res) => {
            // console.log(res)
            if(res){
                clearNotificationDetailState()
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                history.goBack()
            }
        })
        .catch((error) => 
            toast.error(error.response.data.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        )
    }

    function handleModifyAction(){
        history.push(`/notification/${params.notificationId}/modify-metric?metric_id=${metric._id}&actionId=${queryParams.actionId}`)
    }

    function toggleRejestPopup(){
        setRejectPopup( ps => !ps)
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function getDetail(){
        return (
            <>
                <BreadCrumbs 
                        path={toArray(metric.goal?.objective_id?.path)}
                        activeId={metric.goal?.objective_id?._id}
                        icon={faThLarge}
                        truncate={isMobile} />
                <div>
                    <BreadCrumbs 
                        path={[{name : metric.goal?.name,_id : metric.goal?._id }]}
                        img={goalIcon} 
                        activeId={metric.goal?._id} />
                </div>
                <div className='mt-1'>
                    <h5 className={isDesktop ? 'lato-fs22-normal-lh32 mb-0 primary-text-color font-bold': 'lato-fs20-normal-lh28 mb-0 primary-text-color font-bold'}>{metric.metric?.name}</h5>
                </div>
                {metric.metric_description ? 
                    <div className="mt-3" >
                        <Description description={metric.metric_description} />
                    </div> : null }
                { !isEmpty(metric.reference_files) || !isEmpty(metric.reference_links) ?
                    <div className='mt-5'>
                        <ReferenceFiles referenceFiles={metric.reference_files} referenceLinks={metric.reference_links} /> 
                    </div> : ""}
                { queryParams.status !== "pending"? 
                    <div>
                        <StatusChip status={queryParams.status} />
                    </div> : null }
            </>
        )
    }

    return (
        <>
            <Container>
                { isDesktop ? 
                    <>
                        <>
                            <Navbar enableLogout={true}
                                    enableMore={true}
                                    enableProfileButton={true}
                                    open={open}
                                    menu={menu}
                                    moreRight="88px"
                                    handlePopoverClose={handleMenuClose} 
                                    handlePopoverOpen={handleMenuOpen} />
                            <ProcessSectionContainer>
                                <LeftVerticalMenuBar />
                                <ProcessMainContainer className="white-bg">
                                    <SubMainContainer className="mb-40">
                                        {!pageLoading && !isEmpty(metric) ? 
                                            <>
                                                <div className='mt-3'>
                                                    {getDetail()}
                                                </div>
                                                {queryParams.status === "pending" ? 
                                                    <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} /> 
                                                : null }
                                            </> : null
                                        }
                                    </SubMainContainer>
                                </ProcessMainContainer>
                            </ProcessSectionContainer>
                        </>
                    </> :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className="container px-0 d-flex flex-column vh-100">
                            {!pageLoading && !isEmpty(metric) ? 
                                <>
                                    <div className='mt-3 px-3'>
                                        <Header handleBackArrowEvent={handleBackArrowEvent} />
                                    </div>
                                    <div className="container" >
                                        <div className="pb-70 mt-3 px-3">
                                            {getDetail()}
                                            {queryParams.status === "pending" ? 
                                                <div className='mt-3'>
                                                    <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} /> 
                                                </div>
                                            : null }
                                        </div>
                                    </div>
                                </> : null 
                            }
                        </div>
                        <BottomNavigation />
                    </> 
                }
                {rejectPopup && <RejectPopup handleClose={toggleRejestPopup} 
                                            open={rejectPopup} 
                                            actionId={queryParams.actionId}
                                            mailId={params.notificationId}/>}
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        specificMetricState : state.specificMetric,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestSpecificMetric : (graphId) => dispatch(specificMetric(graphId)),
        newMessageAdded : () => dispatch(newMailAdded()),
        clearNotificationDetailState : () => dispatch(clearNotificationDetailState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MetricInboxDetail)