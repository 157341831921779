import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import Select from 'react-select'

import { goalSelect } from '../../services/goals.service'

const GoalsTab = (props) => {

    const { fetchGoals, fetchGoalsState } = props
    
    const [pageLoading,setPageLoading] = useState(true)

    const objectives = fetchGoalsState.response.objectives

    function formattedGoalOptions(){
        let options=[]
        for( let objective of objectives ){
            let goals=[]
            for( let goal of objective.goals){
                goals.push({
                    label : goal.name,
                    value : goal._id
                })
            }
            options.push({label : objective.name ,options:goals})
        }
        return options
    }

    function handleInputChange(value){
        props.setSelected(prevState => {
            return{
                ...prevState,
                goals : value
            }
        })
    }

    useEffect(() => {
        if(!fetchGoalsState.loading && !isEmpty(fetchGoalsState.response)){
            // console.log(fetchGoalsState.response)
            setPageLoading(false)
        }

        if(!fetchGoalsState.loading && !isEmpty(fetchGoalsState.error)){
            console.log(fetchGoalsState.error)
            setPageLoading(false)
            if(!isEmpty(fetchGoalsState.error)){
                if(fetchGoalsState.error.data){
                    toast.error(fetchGoalsState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[fetchGoalsState])

    useEffect(() => {
        if(isEmpty(fetchGoalsState.response)){
            fetchGoals()
        }
    },[])

    return (
        <div className="tab-container-300px px-3 mt-3">
            <h2 className="lato-fs-20-normal-lh32 mb-0 primary-text-color font-bold text-center mb-3">Select Goal</h2>
            { !pageLoading ? 
                <Select 
                    closeMenuOnSelect={false}
                    isMulti
                    menuPortalTarget={document.body}
                    styles={{ 
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        control: (styles ,{ isFocused }) => ({
                                ...styles,
                                ':hover': { borderColor: '#14213d' }, // border style on hover
                                border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                boxShadow: isFocused ? '' : "", // no box-shadow
                            }),
                        option : (styles) => ({
                            ...styles,
                            fontSize : "15px",
                            fontFamily :"'Lato',sans-serif"
                        })
                    }}
                    options={formattedGoalOptions()}
                    onChange={handleInputChange}
                    value = {props.selected.goals}
                /> :null }
        </div> 
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        fetchGoalsState : state.goalSelect,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGoals : () => dispatch(goalSelect()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GoalsTab)