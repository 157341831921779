import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import MemberCard from '../member-card/MemberCard'
import { fetchInvitedMembers } from '../../services/user.service'
import { clearInvitedMemberState } from '../../redux/user/invited-member/invitedMembers.action'
import SubMainContainer from '../desktop-layout/sub-main-container/SubMainContainer'
import {boldString} from '../../utils/utils'
import { clearMemberDetailState } from '../../redux/user/member-detail/membersDetail.action'

const InvitedMember = (props) => {

    const { invitedMemberState, requestInvitedMember, memberDetailState, clearMemberDetailState } = props

    const history = useHistory()

    const [ pageLoading, setPageLoading ] = useState(true)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const invitedMember = invitedMemberState.response.invited_users

    useEffect(() => {
        requestInvitedMember()

        if(!isEmpty(memberDetailState.response)){
            clearMemberDetailState()
        }
    },[])

    useEffect(() => {
        if(!invitedMemberState.loading && !isEmpty(invitedMemberState.response)){
            setPageLoading(false)
        }

        if(!invitedMemberState.loading && !isEmpty(invitedMemberState.error)){
            console.log(invitedMemberState.error)
            setPageLoading(false)
            if(invitedMemberState.error?.data){
                toast.error(invitedMemberState.error?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[invitedMemberState])

    function handleCardClick(userId){
        history.push(`/organisation/member/${userId}?tab=details`)
    }

    function getName(name){
        let str = ''
        if(name.first_name){
            str += `${name.first_name} `
        }
        if(name.middle_name){
            str += `${name.middle_name} `
        }
        if(name.last_name){
            str += name.last_name
        }
        return str
    }

    function getAppropriateMemberCard(){
        if(props.searchedValue.value){
            if(!props.searchedValue.typing){
                if(!isEmpty(props.searchedValue.data)){
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            {
                                props.searchedValue.data.map((member, index) => (
                                    <div key={index}>
                                        <MemberCard
                                            handleCardClick={() => handleCardClick(member._id)}
                                            className="pt-cursor"
                                            role={
                                                member.user_subscriber_mapping[0].job_role ?
                                                    `${member.user_subscriber_mapping[0].job_role} (${member.user_subscriber_mapping[0].role === "user_cum_controller" ? "Controller" : "User"})` 
                                                    : member.user_subscriber_mapping[0].role === "user_cum_controller" ? "Controller" : "User"
                                            }
                                            name={boldString(getName(member.person_name), props.searchedValue.value)}
                                            profilePic={member.profile_pic ? member.profile_pic : "" }
                                            emailId={member.email.id}
                                        />
                                    </div>
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            <p className='lato-fs15-normal-lh20'>No members found for search results "<span className='lato-fs15-normal-lh20 font-bold'>{props.searchedValue.value}</span>"</p>
                        </>
                    )
                }
            } else {
                return (
                    <Box className="d-flex justify-content-center align-items-center">
                        <CircularProgress sx={{color: 'var(--primary-color-v2)'}} />
                    </Box>
                )
            }
        } else {
            if(!pageLoading){
                if(!isEmpty(invitedMember)){
                    return (
                        <>
                            {
                                invitedMember.map((member,index) => (
                                    <MemberCard
                                        key={index}
                                        className="pt-cursor"
                                        handleCardClick={() => handleCardClick(member._id)}
                                        name={getName(member.person_name)}
                                        role={
                                            member.user_subscriber_mapping[0].job_role ?
                                                `${member.user_subscriber_mapping[0].job_role} (${member.user_subscriber_mapping[0].role === "user_cum_controller" ? "Controller" : "User"})` 
                                                : member.user_subscriber_mapping[0].role === "user_cum_controller" ? "Controller" : "User"
                                        }
                                        profilePic={member.profile_pic ? member.profile_pic : "" }
                                        emailId={member.email.id}
                                    />
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <>
                            <p className="lato-fs18-normal-lh25">No invited members</p>
                        </>
                    )
                }
            } else {
                return null
            }
        }
    }

    return (
        isDesktop ?
            <SubMainContainer className="pb-3 pt-4">
                <div>
                    {getAppropriateMemberCard()}
                </div>
            </SubMainContainer>
            : <div className='container pt-3 pb-70 px-3'>
                {getAppropriateMemberCard()}
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        invitedMemberState : state.invitedMembers,
        memberDetailState : state.memberDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestInvitedMember : (action) => dispatch(fetchInvitedMembers(action)),
        clearStates : () => dispatch(clearInvitedMemberState()),
        clearMemberDetailState : () => dispatch(clearMemberDetailState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(InvitedMember)