import { REQUEST_INVITED_MEMBERS,
        SAVE_INVITED_MEMBERS,
        INVITED_MEMBERS_FAILURE,
        CLEAR_INVITED_MEMBER_STATE } from './invitedMembers.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const invitedMembersReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_INVITED_MEMBERS :
            return {
                ...state,
                loading : true,
                error :null
            }

        case SAVE_INVITED_MEMBERS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case INVITED_MEMBERS_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_INVITED_MEMBER_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default invitedMembersReducer