import React, { useState, useEffect } from "react";
import {useDispatch} from 'react-redux'

import './video-chat.css'

import VCJoin from '../vc-join/VCJoin';
import VideoCall from '../../components/video-call/VideoCall'
import { clearVideoChatStates } from '../../redux/video-conference/video-chat/videoChat.action'
import VCEnd from "../vc-end/VCEnd";

const VideoChat = (props) => {

  const dispatch = useDispatch()

  const [inCall, setInCall] = useState(false)
  const [endCall, setEndCall] = useState(false)
  const [trackState, setTrackState] = useState({ video: true, audio: true })

  useEffect(() => {
    return () => {
      dispatch(clearVideoChatStates())
    }
  }, [])

  const handleResetState = () => {
    setTrackState({video: true, audio: true})
    setEndCall(false)
  }

  return (
    <div>
      {
        !endCall ?
          inCall ? 
            <VideoCall
              setInCall={setInCall}
              trackState={trackState}
              setEndCall={setEndCall}
              setTrackState={setTrackState}
            />
          :
            <VCJoin
              setInCall={setInCall}
              trackState={trackState}
              setTrackState={setTrackState}
            />
        :
          <VCEnd
            resetState={handleResetState}
          />
      }
    </div>
  );
};

export default VideoChat