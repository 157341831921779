import React,{ useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { requestMetricForSelect } from '../../services/videoConference.service'

const MetricTab = (props) => {

    const { metricState, requestMetric } = props

    const graphs = metricState.response.goals

    const [ pageLoading, setPageLoading ] = useState(true)
    
    function formattedMetricsOptions(){
        let options = []
        for (let goal of graphs ){
            let metrics = []
            for(let graph of goal.graphs){
                metrics.push({
                    label : graph.metric.name,
                    value : graph._id
                })
            }
            options.push({label : goal.name, options : metrics})
        }
        return options
    }

    function handleInputChange(value){
        props.setSelected(prevState => {
            return {
                ...prevState,
                graphs : value
            }
        })
    }

    useEffect(() => {
        if(isEmpty(metricState.response)){
            requestMetric()
        }
    },[])

    useEffect(() => {
        if(!metricState.loading && !isEmpty(metricState.response)){
            // console.log(metricState.response)
            setPageLoading(false)
        }

        if(!metricState.loading && !isEmpty(metricState.error)){
            console.log(metricState.error)
            setPageLoading(false)
            if(!isEmpty(metricState.error)){
                if(metricState.error.data){
                    toast.error(metricState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[metricState])

    return (
        !pageLoading ?
            <div className='tab-container-300px mt-3 px-3'>
                <h2 className="lato-fs-20-normal-lh32 mb-0 primary-text-color font-bold text-center mb-3">Select Metric</h2>
                    <Select 
                        closeMenuOnSelect={false}
                        isMulti
                        menuPortalTarget={document.body}
                        styles={{ 
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: (styles ,{ isFocused }) => ({
                                    ...styles,
                                    ':hover': { borderColor: '#14213d' }, // border style on hover
                                    border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                    boxShadow: isFocused ? '' : "", // no box-shadow
                                }),
                            option : (styles) => ({
                                ...styles,
                                fontSize : "15px",
                                fontFamily :"'Lato',sans-serif"
                            })
                        }}
                        options={formattedMetricsOptions()}
                        onChange={handleInputChange}
                        value = {props.selected.graphs}
                    />
            </div> :null
    )
}

const mapStateToProps = (state) => {
    return {
        metricState : state.metricsForSelect
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestMetric: () => dispatch(requestMetricForSelect())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MetricTab)