import { REQUEST_SPECIFIC_OBJECTIVE_FOR_REVIEW,
         SAVE_SPECIFIC_OBJECTIVE_FOR_REVIEW, 
         SPECIFIC_OBJECTIVE_FAILURE_FOR_REVIEW,
         CLEAR_SPECIFIC_OBJECTIVE_STATE,
         SPECIFIC_OBJECTIVE_UPDATED } from './specificObjective.types'

const initialState = {
    loading : false,
    updated : false,
    response : {},
    error : null,
}

const reviewSpecificObjectiveReducer = ( state = initialState, action ) =>  {
    switch(action.type) {
        case REQUEST_SPECIFIC_OBJECTIVE_FOR_REVIEW :
            return {
                ...state,
                loading : true, 
                updated : false,
                error : null,
            }

        case SAVE_SPECIFIC_OBJECTIVE_FOR_REVIEW:
            return{
                ...state,
                loading : false,
                updated : false,
                response : action.payload
            }

        case SPECIFIC_OBJECTIVE_FAILURE_FOR_REVIEW:
            return {
                ...state,
                loading : false,
                updated : false,
                error : action.payload
            }

        case CLEAR_SPECIFIC_OBJECTIVE_STATE : 
            return {
                ...state,
                loading : false,
                response : {},
                updated : false,
                error : null,
            }

        case SPECIFIC_OBJECTIVE_UPDATED : 
            return {
                ...state,
                loading : true,
                updated : true
            }

        default : 
            return state
    }
}

export default reviewSpecificObjectiveReducer