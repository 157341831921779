import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { connect } from 'react-redux'
import { Link, useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import './custom-navbar.css'
import { faSignOutAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import { getAuthToken, removeUserCredentials, global } from '../../utils/utils'

const CustomNavbar = (props) => {

    const { userLogout } = props

    const path = useLocation().pathname
    const location = path.split("/")[1]
    const giguser = path.split("/")[2]
    const history = useHistory()

    const handleLogout = () => {
        userLogout()
        removeUserCredentials()
        history.push("/sign-in")
    }

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" variant="dark" className="nav-bg">
                { props.drawer ? 
                    <IconButton
                        color="inherit"
                        style={{color : "#fff"}}
                        aria-label="open drawer"
                        edge="start"
                        onClick={props.drawerOnClick}
                        sx={{ marginRight:"5px" }}
                    >
                        <MenuIcon />
                    </IconButton> : null}
                <Navbar.Brand className="navbar-logo" href="/">{global.app_name}</Navbar.Brand>
                <Nav className="ms-auto">
                { location == "sign-up" ? <Link className="navlink" to="/sign-in">Sign in</Link> : ""} 
                { location == "sign-in" && giguser != "gig-user" ? <Link className="navlink" to="/sign-up">Sign up</Link>: ""}
                <div>
                    {getAuthToken() && props.searchIcon ? <FontAwesomeIcon icon={faSearch} className='white-color fs-18 me-3 pt-cursor' onClick={props.searchIconEvent} />  :null}
                    {getAuthToken() ? <FontAwesomeIcon icon={faSignOutAlt} className="white-color fs-18 pt-cursor" onClick={handleLogout} /> : null}
                </div>
                </Nav>
            </Navbar>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        userLogout : () => dispatch({
            type :"USER_LOGOUT"
        }),
    }
}

export default connect(null,mapDispatchToProps)(CustomNavbar)