import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal } from '@fortawesome/free-solid-svg-icons'

import activeGoalIcon from '../../assets/icons/active-bullseye.svg'
import './goal-progress-card.css'

const GoalProgressCard = (props) => {
    
    return (
        <div className={props.error ? `card progress-card ${props.cardBackground}` : "card progress-card"}>
            <div className="d-flex align-items-center mb-2">
                <img className="progress-icon me-2" src={activeGoalIcon} alt="icon" />
                <p className="mb-0 pb-0 tertiary-text-color-v2 lato-fs13-normal-lh18">{props.goalName}</p>
            </div>
            <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon icon={faSignal} className="primary-color-v2 me-2 lato-fs15-normal-lh20" />
                <p className="mb-0 pb-0 lato-fs18-normal-lh25">{props.metricName}</p>
            </div>
            <p className={props.error ? "mb-0 color-red lato-fs15-normal-lh20 font-bold text-nowrap" : "mb-0 lato-fs15-normal-lh20 font-bold"}>{props.percentage}</p>
        </div>
    )
}

export default GoalProgressCard