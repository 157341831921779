import React,{ useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import { toast } from 'react-toastify'
import qs from 'query-string'

import PopUp from '../../common/popup/PopUp'
import { addNote} from '../../services/notification.service'
import { insertNoteInReference } from '../../redux/notification/reference/Reference.action'
import { isEmpty } from 'lodash'
import { clearAddNoteState } from '../../redux/notification/add-note/AddNote.action'
import SelectBox from '../../common/select-box/SelectBox'
import InputBox from '../../common/Input-box/InputBox'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import UploadFiles from '../upload-files/UploadFiles'

const ReferencePopup = (props) => {

    const { 
        ownProps,
        addNoteState,
        requestAddNote,
        updateReference,
        clearAddNoteState
    } = props

    const referenceFileRef =  useRef()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const location = useLocation()
    const queryParams = qs.parse(location.search)

    const [ referenceFile, setReferenceFile ] = useState(null)
    const [ hasError, setHasError ] = useState(false)

    const initialValues = {
        uploadType: "File",
        url : ""
    }

    const validationSchema = Yup.object({
        uploadType : Yup.string().required(),
        url : Yup.string().when('uploadType', {
                is: (value) => {
                    if(value === 'File'){
                        return false
                    } else {
                        return true
                    }
                },
                then: Yup.string().required("Please enter a url").url("Invalid url"),
                otherwise : Yup.string()
            })
    })

    const onSubmit = ( values ) => {
        if(values.uploadType === 'File'){
            if(referenceFile){
                let formData = new FormData()
                formData.append("reference_notes", referenceFile)
                requestAddNote(ownProps.action, formData)
            } else {
                setHasError(true)
            }
        } else {
            delete values.uploadType
            let formData = new FormData()
            formData.append("reference_data", JSON.stringify(values))
            requestAddNote(ownProps.action, formData)
        }
    }

    useEffect(() => {
        return () => {
            clearAddNoteState()
        }
    },[])

    useEffect(() => {
        if(!addNoteState.loading && !isEmpty(addNoteState.response)){
            updateReference(addNoteState.response.reference)
            toast.success("Notes added successfully" , {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            ownProps.handleClose()
        }

        if(!addNoteState.loading &&!isEmpty(addNoteState.error)){
            if(addNoteState.error){
                if(addNoteState.error.data){
                    console.log(addNoteState.error)
                    toast.error(addNoteState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[addNoteState])

    return (
        <PopUp handleClose={ownProps.handleClose} open={ownProps.open}>
            <div className='p-3' style={{minWidth: isDesktop ? "400px" : "290px"}}>
                <h3 className="lato-fs-20-normal-lh32 text-center font-bold primary-text-color">{queryParams.action === 'learnings' ? "Add Course" : "Add Reference"}</h3>
                <Formik initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}>
                    {props => {
                        const { values, touched, errors, handleBlur, handleChange, setFieldValue } = props
                        return (
                            <Form>
                                <div className="mt-3">
                                    <SelectBox 
                                        options={["File", "Link"]} 
                                        label="Attachment Type"
                                        name="uploadType"
                                        onChange={(event) => {
                                            if(event.target.value === "File"){
                                                setFieldValue('url',"")
                                            } else {
                                                setReferenceFile(null)
                                            }
                                            handleChange(event)
                                        }} 
                                        value={values.uploadType}
                                    />
                                </div>
                                { 
                                    values.uploadType === "File" ?
                                        referenceFile ?
                                            <div className="my-3">
                                                <h6 className="m-0 secondary-text-color lato-fs16-normal-lh20">Selected</h6>
                                                <div className="d-flex">
                                                    <p className="lato-fs15-normal-lh20 pb-0 primary-text-color mb-0 one-line-wrap">{referenceFile.name}</p>
                                                    <FontAwesomeIcon className="ms-auto me-2 secondary-color" 
                                                        onClick={() => setReferenceFile(null)} icon={faTrash} />
                                                </div>
                                            </div> 
                                            : <>
                                                <div className='mt-4'>
                                                    <UploadFiles label="Click to upload file" 
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            referenceFileRef.current.click();
                                                        }} />
                                                </div>
                                                {hasError ? <span className="error-message my-1">Please select a file</span> : null}
                                            </>
                                        : <div className="mt-4">
                                            <InputBox 
                                                placeholder="Enter the url" 
                                                label="Url"
                                                type="url"
                                                name="url"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.url}
                                                errorMgs={touched.url && errors.url ? errors.url : ""}
                                                />
                                        </div>
                                }    
                                <input 
                                    type="file"
                                    style={{display : "none"}}
                                    ref={referenceFileRef}
                                    accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx, text/csv"
                                    onChange={(event) => {
                                        const file = event.target.files[0]
                                        if(file){
                                            setReferenceFile(file)
                                            if(hasError) setHasError(false)
                                        }
                                    }}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />
                                <div className="d-flex justify-content-center mt-3" >
                                    <CustomizeButton variant="primary-color-button" label={queryParams.action === "learnings" ? "Add Course" : "Add Reference"} type="submit"/>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </PopUp>
    )
}

const mapStateToProps = ( state, ownProps ) => {
    return {
        addNoteState : state.addNote,
        referenceState : state.references,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestAddNote : (action, note) => dispatch(addNote(action, note)),
        updateReference : (response) => dispatch(insertNoteInReference(response)),
        clearAddNoteState : () => dispatch(clearAddNoteState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReferencePopup)
