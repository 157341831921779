import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeItem from '@mui/lab/TreeItem'
import Badge from '@mui/material/Badge'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'

import BottomNavigation from '../../components/bottom-navgation/BottomNavigation'
import AddMember from '../../components/add-member/AddMember'
import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import { teamList, teamListPromise } from '../../services/teamMember.service'
import { fetchTeamMemberSuccess, clearTeamMemberState } from '../../redux/team-member/teamMember.action'
import { getRole } from '../../utils/utils'
import './team-member.css'
import { CONTROLLER_ROLE, USER_ROLE } from '../../utils/constants'
import Container from '../../components/desktop-layout/container/Container'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../components/desktop-layout/sub-main-container/SubMainContainer'
import Header from '../../common/header/Header'

const useTreeItemStyles = makeStyles((theme) => ({
    content: {
        flexDirection: "row-reverse",
        backgroundColor: "transparent",
        color: "rgba(0, 0, 0, 0.87)",
        // WebkitTransition:" box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "4px",
        // boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        overflow: "hidden",
        // marginTop:"10px",
        paddingTop: "8px !important",
        paddingBottom: "8px !important",
        "&:hover":{
            backgroundColor : "transparent !important"
        }
    },
    labelRoot: {
        display: "flex",
        alignItems: "center",
    },
    labelText: {
        fontWeight: "inherit",
        flexGrow: 1
    },
    labelContainer : {
        display:"flex",
        alignItems:"center",
        marginBottom:6,
    },
    icon:{
        width:55,
        height:55,
        objectFit:"cover",
        borderRadius:50,
    },
    label:{
        display:"flex",
        flexDirection:"column",
        marginLeft:8,
        wordBreak:"break-word"
    },
    role:{
        fontSize:12,
        color:"grey",
        fontFamily:"'Lato',sans-serif",
        paddingTop:"5px",
        paddingBottom:"5px",
    },
    controllerContainer : {
        paddingTop:8,
        borderBottom : "1px solid rgba(0, 0, 0, 0.1);",
        paddingBottom:8,
        "&:last-child": {
            borderBottom : "none",
        }
    },
    controllerCount:{
        display: "block",
        height : 25,
        width : 25,
        lineHeight:"25px",
        borderRadius: "50%",
        backgroundColor : "rgba(0,0,0,0.1)",
        color : "black",
        textAlign:"center",
        fontSize:12,
    },
    root: {
        position: "relative",
        "&:before": {
        pointerEvents: "none",
        content: '""',
        position: "absolute",
        width: 14,
        left: -16,
        top: 14,
        }
    },
    iconContainer: {
        "& .close": {
        opacity: 0.3
        }
    },
    group: {
        marginLeft: 7,
        paddingLeft: 10,
    }
}));

    function StyledTreeItem(props) {
        const classes = useTreeItemStyles(props);
        // console.log(props);
        const { profilePic, firstName, lastName, role, handleMemberClick, email, ...other } = props;

        return (
            <TreeItem
                label={
                    <div className={classes.labelContainer} onClick={handleMemberClick}>
                        {/* <img src={profilePic ? profilePic : iconPlaceholder} className={classes.icon} alt="profile img"></img> */}
                        <Avatar src={profilePic} sx={{width:"50px",height:"50px"}} />
                        <div className={classes.label} style={{overflow:"hidden"}}>
                            <div className="d-flex">
                                <span className="pe-1 lato-fs16-normal-lh20 one-line-wrap font-bold">{`${firstName} ${lastName}`}</span>
                            </div>
                            <span className='lato-fs16-normal-lh20 one-line-wrap primary-text-color' style={{paddingTop:"5px"}}>{email}</span>
                            <span className={classes.role}>{role}</span>
                        </div>
                    </div>
                }
                classes={{
                    root: classes.root,
                    content: classes.content,
                    group: classes.group,
                    iconContainer: classes.iconContainer
                }}
            {...other}
            />
        );
    }

    function ParentTreeItem(props) {
        const classes = useTreeItemStyles(props);
        // console.log("parent",props);
        const { profilePic, firstName, lastName, role, count, email, handleMemberClick, ...other } = props;

        return (
            <TreeItem
                label={
                    <>
                        <div className={classes.labelContainer} onClick={handleMemberClick} >
                            {/* <img src={profilePic ? profilePic : iconPlaceholder} className={classes.icon} alt="profile img"></img> */}
                            <Avatar src={profilePic} sx={{width:"50px",height:"50px"}} />
                            <div className={classes.label} style={{overflow:"hidden"}}>
                                <span className='lato-fs16-normal-lh20 primary-text-color font-bold one-line-wrap'>{firstName} {lastName}</span>
                                <span className='lato-fs16-normal-lh20 primary-text-color pt-1 one-line-wrap'>{email}</span>
                                <span className={classes.role}>{role}</span>
                            </div>
                            {/* {count ?
                                <div className="ms-2">
                                    <span className={classes.controllerCount}>{`+${count}`}</span>
                                </div> : null }  */}
                        </div>
                    </>
                }
                classes={{
                    root: classes.root,
                    content: classes.content,
                    group: classes.group,
                    iconContainer: classes.iconContainer
                }}
            {...other}
            />
        );
    }

const useStyles = makeStyles((theme) => ({
    root: {
        height: "auto",
        flexGrow: 1,
        maxWidth: 800,
    }
}));

const TeamMember = (props) => {

    const { requestTeamMember, teamMembersState, saveTeamMembers, clearTeamMembersState } = props

    const [ addMemberPopup, setAddMembberPopup ] = useState(false)
    const [ pageLoading ,setPageLoading ] = useState(true)
    const [expanded, setExpanded] = useState(['1'])
    const [path, setPath] = useState([])
    const [ menu, setMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const isDesktop = useMediaQuery('(min-width:992px)')

    const userInfo = teamMembersState.response.users_info
    const teamMembers = teamMembersState.response.team_members
    const members = teamMembersState.response.members

    let paginationNodes = []

    const history = useHistory()
    const location = useLocation()
    const classes = useStyles()
    const action = location.pathname.split("/")[1]

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    const handleChange = (event, nodes) => {
        setExpanded(nodes)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const handleSelectedNode = (event, node) => {
        if(paginationNodes.map(mNode => mNode.nodeId).includes(node)) {
            const paginationNode = paginationNodes.find(mNode => mNode.nodeId === node)
            loadNext(paginationNode.userId)
        }
    };

    function handleAddMemberPopup(){
        setAddMembberPopup(ps => !ps)
    }

    useEffect(() => {
        if(isEmpty(teamMembersState.response)){
            if(action === "workflow-mappings"){
                requestTeamMember(true)
            }else {
                requestTeamMember(false)
            }
        }
        return () => {
            clearTeamMembersState()
        }
    },[])

    let i = 0
    function getNodeId(){
        i++;
        return i 
    }

    useEffect(() => {
        if(!teamMembersState.loading && !isEmpty(teamMembersState.response)){
            // console.log(teamMembersState.response)
            setPageLoading(false)
        }

        if(!teamMembersState.loading && !isEmpty(teamMembersState.error)){
            console.log(teamMembersState.error.data)
            if(teamMembersState.error.data){
                toast.error(teamMembersState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    }, [teamMembersState])

    function loadNext(userId){
        // console.log('userId', userId)
        teamListPromise(userId)
            .then((res) => {
                // console.log('res', res)
                setPath(ps => {
                    return isEmpty(ps) ? [{root:true, user: userInfo}] : [...ps, {root:false, user: userInfo}]
                })
                setExpanded(['1'])
                saveTeamMembers(res.data)
            })
            .catch(err => {
                console.log(err.response)
                toast.error(err.response?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    function loadPrevious(){
        let prevObj = path[path.length - 1]
        const userId = !prevObj.root && !isEmpty(prevObj.user) ? prevObj.user[0].user._id : undefined
        teamListPromise(userId)
            .then((res) => {
                setPath(ps => {
                    let psCopy = JSON.parse(JSON.stringify(ps))
                    psCopy.pop()
                    return psCopy
                })
                setExpanded(['1'])
                saveTeamMembers(res.data)
            })
            .catch(err => {
                console.log(err.response)
                toast.error(err.response?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    function handleMemberClick(e,id){
        history.push(`/organisation/member/${id}?tab=details`)
        e.stopPropagation()
        e.preventDefault()
    }   

    function lastElement(childMember){
        const nodeId = getNodeId().toString()
        if(!isEmpty(childMember.user_subscriber_mapping[0].incharge_of)){
            paginationNodes.push({nodeId: nodeId, userId: childMember._id})
        }
        return <>
            <div style={{background:"#e9e9e9",marginLeft:"-51px",paddingLeft:"68px"}}>
                <StyledTreeItem nodeId={nodeId} 
                    firstName={childMember.person_name.first_name} 
                    lastName={childMember.person_name.last_name}
                    email={childMember.email?.id}
                    handleMemberClick={(e) => handleMemberClick(e, childMember._id)}
                    role={childMember.user_subscriber_mapping[0].job_role}>
                    {getRole() === CONTROLLER_ROLE && action === "workflow-mappings" && !isEmpty(childMember.user_subscriber_mapping[0].incharge_of) ? <div></div> : null}
                </StyledTreeItem>
            </div>
        </>
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function handleOnNotificationsIconClick(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    return (
        <>
            <Container>
                {
                    isDesktop ?
                        <>
                            <Navbar 
                                open={open}
                                menu={menu}
                                handlePopoverClose={handleMenuClose}
                                handlePopoverOpen={handleMenuOpen}
                                enableProfileButton={true} 
                                enableMore={true} 
                                enableLogout={true}
                                enableNotification={true}
                                notificationOnClick={handleOnNotificationsIconClick}
                            />
                            <ProcessSectionContainer>
                                <LeftVerticalMenuBar 
                                    addIcon={ getRole() === USER_ROLE ? true : "" } 
                                    onAddIconClick={handleAddMemberPopup} 
                                    addIconTooltip="Add Member"
                                />
                                <ProcessMainContainer variant="noBackground" >
                                    <SubMainContainer className="mb-40">
                                        {
                                            !pageLoading ?
                                                <div className='mt-4'>
                                                    { 
                                                        !isEmpty(members) || !isEmpty(teamMembers) ?
                                                            <div className="mt-2">
                                                                <h4 className='lato-fs22-normal-lh32 font-bold mb-3'>{action === 'workflow-mappings' ? "Workflow Mappings" : "Team"}</h4>
                                                                {
                                                                    path.length ? 
                                                                        <div className="mb-2">
                                                                            <Badge badgeContent={path.length} color="primary" sx={{cursor:"pointer"}}>
                                                                                <ArrowUpwardIcon onClick={loadPrevious} />
                                                                            </Badge>
                                                                        </div> 
                                                                        : null
                                                                }
                                                                <div>
                                                                    <TreeView
                                                                        className={classes.root}
                                                                        defaultCollapseIcon={
                                                                            <IconButton sx={{paddingLeft:"10px", color:"black",paddingRight:"20px"}}>
                                                                                <ExpandMoreIcon />
                                                                            </IconButton>
                                                                        }
                                                                        defaultExpandIcon={
                                                                            <IconButton sx={{paddingLeft : "10px", color:"black", paddingRight:"20px"}} >
                                                                                <ChevronRightIcon />
                                                                            </IconButton>
                                                                        }
                                                                        // expanded={expanded}
                                                                        onNodeToggle={handleChange}
                                                                        onNodeSelect={handleSelectedNode}
                                                                    >
                                                                        {
                                                                            !isEmpty(userInfo) && !isEmpty(teamMembers) ? 
                                                                            <div style={{background:"#f8f8f8"}}>
                                                                                <ParentTreeItem nodeId={`${getNodeId()}`}
                                                                                    firstName={ !isEmpty(userInfo) && !isEmpty(userInfo.user) && !isEmpty(userInfo.user.person_name) && userInfo.user.person_name.first_name ? userInfo.user.person_name.first_name : ""}
                                                                                    lastName={ !isEmpty(userInfo) && !isEmpty(userInfo.user) && !isEmpty(userInfo.user.person_name) && userInfo.user.person_name.last_name ? userInfo.user.person_name.last_name : ""}
                                                                                    role={userInfo.job_role ? userInfo.job_role : userInfo.role === CONTROLLER_ROLE ? "Controller" : "User"}
                                                                                    email={userInfo.user?.email?.id}
                                                                                    handleMemberClick={(e) => handleMemberClick(e, userInfo.user?._id)}>
                                                                                        <div style={{background:"#f1f1f1",marginLeft:"-17px",paddingLeft:"34px"}}>
                                                                                            {teamMembers.map((teamMember,parentindex) => (
                                                                                                <StyledTreeItem 
                                                                                                    nodeId={`${getNodeId()}`}
                                                                                                    key={parentindex}
                                                                                                    firstName={teamMember?.person_name?.first_name ? teamMember.person_name.first_name : ""} 
                                                                                                    lastName={teamMember?.person_name?.last_name ? teamMember.person_name.last_name : ""}
                                                                                                    email={teamMember?.email?.id}
                                                                                                    role={teamMember.user_subscriber_mapping[0]?.job_role ? teamMember.user_subscriber_mapping[0]?.job_role : teamMember.user_subscriber_mapping[0]?.role === CONTROLLER_ROLE ? "Controller" : "User"}
                                                                                                    handleMemberClick={(e) => handleMemberClick(e, teamMember._id)}>
                                                                                                        {!isEmpty(teamMember.user_subscriber_mapping) && !isEmpty(teamMember.user_subscriber_mapping[0].incharge_of) ? 
                                                                                                            teamMember.user_subscriber_mapping[0].incharge_of.map((member,childIndex) => lastElement(member))
                                                                                                        :null }
                                                                                                </StyledTreeItem>
                                                                                            ))}
                                                                                        </div>
                                                                                </ParentTreeItem>
                                                                            </div> :
                                                                            !isEmpty(members) ?
                                                                                <div style={{background:"#f8f8f8"}}>
                                                                                    {members.map((member,parentIndex) => (
                                                                                        <ParentTreeItem 
                                                                                            nodeId={`${getNodeId()}`} 
                                                                                            key={parentIndex}
                                                                                            firstName={member.person_name?.first_name ? member.person_name.first_name : ""}
                                                                                            lastName={member.person_name?.last_name ? member.person_name.last_name : ""}
                                                                                            email={member.email?.id}
                                                                                            handleMemberClick={(e) => handleMemberClick(e, member._id)}
                                                                                            role={member.user_subscriber_mapping[0]?.job_role ? `${member.user_subscriber_mapping[0]?.job_role} (${member.user_subscriber_mapping[0]?.role === CONTROLLER_ROLE ? "Controller" : "User"})` : member.user_subscriber_mapping[0]?.role === CONTROLLER_ROLE ? "Controller" : "User"} >
                                                                                                {!isEmpty(member.user_subscriber_mapping) && !isEmpty(member.user_subscriber_mapping[0].incharge_of) ?
                                                                                                    <div style={{background:"#f1f1f1",marginLeft:"-17px",paddingLeft:"34px"}}>
                                                                                                        {member.user_subscriber_mapping[0]?.incharge_of.map((childMemberOne, childindex1) => (
                                                                                                            <StyledTreeItem 
                                                                                                                nodeId={`${getNodeId()}`} 
                                                                                                                key={childindex1} 
                                                                                                                firstName={childMemberOne.person_name?.first_name} 
                                                                                                                lastName={childMemberOne.person_name?.last_name} 
                                                                                                                email={childMemberOne.email?.id}
                                                                                                                role={childMemberOne.user_subscriber_mapping[0]?.job_role ? childMemberOne.user_subscriber_mapping[0]?.job_role : childMemberOne.user_subscriber_mapping[0]?.role === CONTROLLER_ROLE ? "Controller" : "User"} 
                                                                                                                handleMemberClick={(e) => handleMemberClick(e, childMemberOne._id)}>
                                                                                                                    {!isEmpty(childMemberOne.user_subscriber_mapping) && !isEmpty(childMemberOne.user_subscriber_mapping[0].incharge_of) ? 
                                                                                                                        childMemberOne.user_subscriber_mapping[0].incharge_of.map((childMemberTwo, childIndex2) => lastElement(childMemberTwo))
                                                                                                                    :null }
                                                                                                            </StyledTreeItem>
                                                                                                            ))} 
                                                                                                    </div>
                                                                                                    : null}
                                                                                        </ParentTreeItem>
                                                                                    ))}
                                                                                </div>
                                                                            : null
                                                                        }
                                                                    </TreeView>
                                                                </div>
                                                            </div> :
                                                            <div className="d-flex justify-content-center align-items-center h-80vh">
                                                                <p className="tertiary-text-color-v2 lato-fs18-normal-lh25">You don't have any members</p>
                                                            </div>
                                                        }
                                                </div>
                                                : null
                                        }
                                    </SubMainContainer>
                                </ProcessMainContainer>
                            </ProcessSectionContainer>
                        </> 
                        : <>
                            <Navbar 
                                variant="mobile"
                                enableLogout={true}
                                enableDrawer={true}
                                drawerOpened={drawer}
                                handleDrawerClose={toggleDrawer}
                                onDrawerClick={toggleDrawer}
                                enableNotificationsIcon={true}
                                onNotificationsIconClick={handleOnNotificationsIconClick}
                            />
                            {
                                !pageLoading ?
                                    <div className="container flex-1" style={{paddingBottom:"60px"}}>
                                        <div className='mt-3 px-3'>
                                            <Header heading={action === "workflow-mappings" ? "Your Organisation" : "Your Team Members"} 
                                                handleBackArrowEvent={handleBackArrowEvent}
                                            />
                                        </div>
                                        <div className="mx-3" style={{background:"white",}}>
                                            { !isEmpty(members) || !isEmpty(teamMembers) ?
                                                <div className="mt-2" >
                                                    {
                                                        path.length ? 
                                                        <div className="mb-2 pt-cursor">
                                                            <Badge badgeContent={path.length} color="primary">
                                                                <ArrowUpwardIcon onClick={loadPrevious} />
                                                            </Badge>
                                                        </div> : null
                                                    }
                                                    <div>
                                                        <TreeView
                                                            className={classes.root}
                                                            defaultCollapseIcon={
                                                                <div style={{paddingLeft:"10px"}}>
                                                                    <ExpandMoreIcon />
                                                                </div>
                                                                }
                                                            defaultExpandIcon={
                                                                <div style={{paddingLeft : "10px"}} >
                                                                    <ChevronRightIcon />
                                                                </div>
                                                                }
                                                            // expanded={expanded}
                                                            onNodeToggle={handleChange}
                                                            onNodeSelect={handleSelectedNode}>
                                                            {
                                                                !isEmpty(userInfo) && !isEmpty(teamMembers) ? 
                                                                <div style={{background:"#f8f8f8"}}>
                                                                    <ParentTreeItem nodeId={`${getNodeId()}`}
                                                                        firstName={ !isEmpty(userInfo) && !isEmpty(userInfo.user) && !isEmpty(userInfo.user.person_name) && userInfo.user.person_name.first_name ? userInfo.user.person_name.first_name : ""}
                                                                        lastName={ !isEmpty(userInfo) && !isEmpty(userInfo.user) && !isEmpty(userInfo.user.person_name) && userInfo.user.person_name.last_name ? userInfo.user.person_name.last_name : ""}
                                                                        email={userInfo.user?.email?.id}
                                                                        handleMemberClick={(e) => handleMemberClick(e, userInfo._id)}
                                                                        role={userInfo.job_role ? userInfo.job_role : userInfo.role === CONTROLLER_ROLE ? "Controller" : "User"}>
                                                                            <div style={{background:"#f1f1f1",marginLeft:"-17px",paddingLeft:"34px"}}>
                                                                                {teamMembers.map((teamMember,parentindex) => (
                                                                                    <StyledTreeItem 
                                                                                        nodeId={`${getNodeId()}`}
                                                                                        key={parentindex}
                                                                                        firstName={teamMember?.person_name?.first_name ? teamMember.person_name.first_name : ""} 
                                                                                        lastName={teamMember?.person_name?.last_name ? teamMember.person_name.last_name : ""}
                                                                                        email={teamMember.email?.id}
                                                                                        role={teamMember.user_subscriber_mapping[0]?.job_role ? teamMember.user_subscriber_mapping[0]?.job_role : teamMember.user_subscriber_mapping[0]?.role === CONTROLLER_ROLE ? "Controller" : "User"}
                                                                                        handleMemberClick={(e) => handleMemberClick(e, teamMember._id)}>
                                                                                            {!isEmpty(teamMember.user_subscriber_mapping) && !isEmpty(teamMember.user_subscriber_mapping[0].incharge_of) ? 
                                                                                                teamMember.user_subscriber_mapping[0].incharge_of.map((member,childIndex) => lastElement(member))
                                                                                            :null }
                                                                                    </StyledTreeItem>
                                                                                ))}
                                                                            </div>
                                                                    </ParentTreeItem>
                                                                </div> :
                                                                !isEmpty(members) ?
                                                                    <div style={{background:"#f8f8f8"}}>
                                                                        {
                                                                            members.map((member,parentIndex) => (
                                                                                <ParentTreeItem 
                                                                                    nodeId={`${getNodeId()}`} 
                                                                                    key={parentIndex}
                                                                                    email={member.email?.id}
                                                                                    firstName={member.person_name?.first_name ? member.person_name.first_name : ""}
                                                                                    lastName={member.person_name?.last_name ? member.person_name.last_name : ""}
                                                                                    handleMemberClick={(e) => handleMemberClick(e, member._id)}
                                                                                    role={member.user_subscriber_mapping[0]?.job_role ? member.user_subscriber_mapping[0]?.job_role : member.user_subscriber_mapping[0]?.role === CONTROLLER_ROLE ? "Controller" : "User"} >
                                                                                        {!isEmpty(member.user_subscriber_mapping) && !isEmpty(member.user_subscriber_mapping[0].incharge_of) ?
                                                                                            <div style={{background:"#f1f1f1",marginLeft:"-17px",paddingLeft:"34px"}}>
                                                                                                {member.user_subscriber_mapping[0]?.incharge_of.map((childMemberOne, childindex1) => (
                                                                                                    <StyledTreeItem 
                                                                                                        nodeId={`${getNodeId()}`} 
                                                                                                        key={childindex1} 
                                                                                                        email={childMemberOne.email?.id}
                                                                                                        firstName={childMemberOne.person_name?.first_name} 
                                                                                                        lastName={childMemberOne.person_name?.last_name} 
                                                                                                        role={childMemberOne.user_subscriber_mapping[0]?.job_role ? childMemberOne.user_subscriber_mapping[0]?.job_role : childMemberOne.user_subscriber_mapping[0]?.role === CONTROLLER_ROLE ? "Controller" : "User"} 
                                                                                                        handleMemberClick={(e) => handleMemberClick(e, childMemberOne._id)}>
                                                                                                            {!isEmpty(childMemberOne.user_subscriber_mapping) && !isEmpty(childMemberOne.user_subscriber_mapping[0].incharge_of) ? 
                                                                                                                childMemberOne.user_subscriber_mapping[0].incharge_of.map((childMemberTwo, childIndex2) => lastElement(childMemberTwo))
                                                                                                            :null }
                                                                                                    </StyledTreeItem>
                                                                                                ))} 
                                                                                            </div>
                                                                                            : null}
                                                                            </ParentTreeItem>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                : null
                                                            }
                                                        </TreeView>
                                                    </div>
                                                </div> :
                                                <div className="d-flex justify-content-center align-items-center h-80vh">
                                                    <p className="secondary-color fs18-lh25">You don't have any team member yet</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    : null
                            }
                            <BottomNavigation enableAddIcon={getRole() === USER_ROLE ? true : "" } onAddIconClick={ getRole() === "user" ? handleAddMemberPopup : undefined   }/>
                        </>
                }
                { 
                    addMemberPopup ? 
                        <AddMember handleClose={handleAddMemberPopup} open={addMemberPopup} /> 
                        : null 
                }
            </Container>
        </>
    )
}

const mapStateToProps = ( state ) => {
    return {
        teamMembersState : state.teamMembers
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestTeamMember : (wfmboolean) => dispatch(teamList(wfmboolean)),
        saveTeamMembers : (response) => dispatch(fetchTeamMemberSuccess(response)),
        clearTeamMembersState : () => dispatch(clearTeamMemberState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(TeamMember)