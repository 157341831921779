import React,{ useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

import { fetchProfileData } from '../../services/ProfileData.service'
import './profile-button.css'

const ProfileButton = (props) => {

    const { fetchProfile, profileDataState } = props
    const [pageLoading, setPageLoading] = useState(true)

    const profile = profileDataState.response.profile
    const history = useHistory()

    useEffect(() => {
        if(isEmpty(profileDataState.response) && !profileDataState.loading){
            fetchProfile()
        }
    },[])

    useEffect(() => {
        if(!profileDataState.loading && !isEmpty(profileDataState.response)){
            setPageLoading(false)
        }

        if(!profileDataState.loading && !isEmpty(profileDataState.error)){
            console.log(profileDataState.error)
        }
    },[profileDataState])

    return (
        !pageLoading && !isEmpty(profile)?
        <div className='d-flex align-items-center pt-cursor profile-chip' onClick={() =>history.push('/profile')}>
            <Avatar src={profile.profile_data.profile_pic} sx={{width:"27.5px",height:"27.5px"}} />
            <Typography variant='h6' component="div" sx={{ fontSize:"17px",fontFamily:"'Lato',sans-serif", marginLeft:"10px", marginRight:"10px"}}>{profile?.profile_data?.person_name?.first_name}</Typography>
        </div> : null
    );
};

const mapStateToProps = (state) => {
    return {
        profileDataState : state.profileData 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfile : () => dispatch(fetchProfileData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileButton)
