import { REQUEST_COURSE, 
         COURSE_SUCCESS,
         COURSE_FAILURE, 
         CLEAR_COURSE,
         SAVE_COURSE,
         INSERT_COURSE} from './CourseTypes'

import { insertCourse } from './Course.utilis'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const courseReducer = (state = initialState, action) => {

    switch(action.type){

        case REQUEST_COURSE : 
            return {
                ...state,
                loading : true,
                error : null
            }

        case COURSE_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }
        
        case COURSE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_COURSE : 
            return {
                loading : false,
                response : {},
                error : null
            }
        
        case INSERT_COURSE : 
            return {
                ...state,
                response : {
                    ...state.response,
                    course : action.payload
                }
            }

        case SAVE_COURSE : 
            return {
                ...state,
                response : {
                    ...state.response,
                    course : insertCourse( action.payload.courseId, action.payload.courseArray )
                }
            }


        default : 
            return state
    }   
}

export default courseReducer