import React from 'react'
import PopUp from '../../common/popup/PopUp'
import { isEmpty } from 'lodash'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import './objective-list-popup.css'
import useMediaQuery from '@mui/material/useMediaQuery';

const ObjectiveListPopup = (props) => {

    const isMobile = useMediaQuery('(max-width:480px)')

    function objectiveList(objectives) {

        let objectiveOptions = []
        
        objectives.forEach((objective,index) => {
            objectiveOptions.push(<option className='primary-color-option' key={index} value={objective._id}>{objective.name}</option>)  // level 0
            if(!isEmpty(objective.sub_objectives)){
                objective.sub_objectives.forEach((subObjOne) => {
                    objectiveOptions.push(<option className='primary-color-option' key={subObjOne._id} value={subObjOne._id}>&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjOne.name}`}</option>) // level 1
                    if(!isEmpty(subObjOne.sub_objectives)){
                        subObjOne.sub_objectives.forEach(subObjTwo => {
                            objectiveOptions.push(<option className='primary-color-option' key={subObjTwo._id} value={subObjTwo._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjTwo.name}`}</option>)  // level 2
                            if(!isEmpty(subObjTwo.sub_objectives)){
                                subObjTwo.sub_objectives.forEach(subObjThree => {
                                    objectiveOptions.push(<option className='primary-color-option' key={subObjThree._id} value={subObjThree._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjThree.name}`}</option>)  // level 3
                                })
                            }
                        })
                    }
                })
            }
        })

        return objectiveOptions
    }

    const className = isMobile ? 'p-3' : 'p-4 min-width-350px'

    return (
        <PopUp handleClose={props.handleClose} open={props.open} fullWidth={isMobile} >
            <div className={className}>
                <h2 className="lato-fs-20-normal-lh32 primary-text-color font-bold text-center">Select VCF</h2>
                {
                    <select name='objective_id' value={props.value} onChange={props.onChange} onBlur={props.onBlur} className="primary-select-box">
                        {
                            <>
                                <option value='' className='primary-color-option' hidden>Select a VCF</option>
                                {objectiveList(props.options)}
                            </>
                        }
                    </select>
                }
                <hr/>
                <div className="d-flex justify-content-center align-item-center mb-2">
                    <CustomizeButton variant="primary-color-button" handleClick={props.onClickCreateObjective} label="Create VCF" />
                </div>
            </div>
        </PopUp>
    )
}


export default ObjectiveListPopup