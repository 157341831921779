import React,{ useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'query-string'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'

import ObjectiveCard from '../../objective-card/ObjectiveCard';
import withSupportDoubleClick from '../../../utils/with-support-double-click'
import { requestObjectiiveReviewAll } from '../../../services/review.service'
import './obj-review-by-lvl.css'
import { COMPONENTS_TYPE } from '../objective-all/objectiveAll.utils'
import { saveSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import { PROCESS_STATUS, REVIEW_TYPES } from '../../../utils/constants'

const ObjReviewByLvl = (props) => {

    const { requestObjectiveReview, reviewObjectiveAllState, ownProps, saveObjectives  } = props

    // const { activeLevel, setActiveLevel, setCurComponent } = props.ownProps

    const ACTIVE_LEVEL_KEY_MAPPING = {
        0 : 'level_zero_objectives',
        1 : 'level_one_objectives',
        2 : 'level_two_objectives',
        3 : 'level_three_objectives'
    }

    const [selectedIndex, setSelectedIndex] = useState({rowIndex: undefined, columnIndex: undefined})
    const [obj, setObj] = useState({loading : true, content : []})

    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const queryParams = qs.parse(location.search)

    function chunkArray(myArray, chunk_size){
        return new Promise((resolve, reject) => {

            const arrayLength = myArray.length;
            let tempArray = [];

            for (let index = 0; index < arrayLength; index += chunk_size) {
                let myChunk = myArray.slice(index, index+chunk_size);
                // Do something if you want with the group
                tempArray.push(myChunk);
            }

            resolve(tempArray);
        })
    }

    useEffect(() => {
        chunkArray(reviewObjectiveAllState.response[ACTIVE_LEVEL_KEY_MAPPING[parseInt(queryParams.lvl)]], 3).then(res => {
            setObj(ps => {
                return {
                    loading : false,
                    content : res,
                }
            })
        })
    },[queryParams.lvl])

    function getMaxLevel(objectives){
        let maxLevel = 0
        if(!isEmpty(objectives.level_zero_objectives)){
            if(!isEmpty(objectives.level_one_objectives)){
                maxLevel += 1
                if(!isEmpty(objectives.level_two_objectives)) {
                    maxLevel += 1
                    if(!isEmpty(objectives.level_three_objectives)){
                        maxLevel += 1
                    }
                }
            }
        }
        return maxLevel
    }

    function getSelectedObjectiveObj(rowIndex, columnIndex) {
        return obj.content[rowIndex][columnIndex]
    }

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick: (e, payload) => {
            saveObjectives({objective : getSelectedObjectiveObj(payload.rowIndex, payload.columnIndex)})
            history.push(`/review/${params.userId}/action/${getSelectedObjectiveObj(payload.rowIndex, payload.columnIndex)._id}?role=${queryParams.role}&action=objective`)
        },
        onSingleClick: (e, payload) => {
            setSelectedIndex(payload)
        },
    })

    function getNextLevel(){
        if(selectedIndex.rowIndex !== undefined && selectedIndex.columnIndex !== undefined ){
            if(!isEmpty(getSelectedObjectiveObj(selectedIndex.rowIndex, selectedIndex.columnIndex).sub_objectives)){
                const selectedObj = getSelectedObjectiveObj(selectedIndex.rowIndex, selectedIndex.columnIndex)
                history.push(`/review/${params.userId}?role=${queryParams.role}&action=vcf&tab=all&mode=${COMPONENTS_TYPE.listSubObjs}&parent_id=${selectedObj._id}&lvl=${selectedObj.level + 1}`)
                setSelectedIndex({rowIndex : undefined, columnIndex : undefined})
            } else {
                toast.info('No sub-objectives to view', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            if(getMaxLevel(reviewObjectiveAllState.response) >= parseInt(queryParams.lvl)){
                history.push(`/review/${params.userId}?role=${queryParams.role}&action=vcf&tab=all&mode=${COMPONENTS_TYPE.listObjBylvl}&lvl=${parseInt(queryParams.lvl) + 1}`)
            }
        }
    }

    function getPreviousLevel(){
        if(0<parseInt(queryParams.lvl)){
            history.push(`/review/${params.userId}?role=${queryParams.role}&action=vcf&tab=all&mode=${COMPONENTS_TYPE.listObjBylvl}&lvl=${parseInt(queryParams.lvl)-1}`)
        }
    }

    function actionStatus(approvalStatus,reviewStatus){
        if(approvalStatus == "needs_approval"){
            if(queryParams.role == "reviewer"){
                if(reviewStatus.reviewer_needed == true){
                    if(reviewStatus.reviewer_status == "pending"){
                        return "pending"
                    }else if((reviewStatus.reviewer_status == "approved" || reviewStatus.reviewer_status == "modified_and_approved")){
                        return "approved"
                    }
                }else{
                    return "not_needed"
                }
            }else{
                if(queryParams.role == "super-reviewer"){
                    if(reviewStatus.super_reviewer_needed == true){
                        if(reviewStatus.reviewer_status == "pending"){
                            return "pending"
                        }else if(reviewStatus.reviewer_status == "approved" || reviewStatus.reviewer_status == "modified_and_approved"){
                            return "approved"
                        }
                    }else{
                        return "not_needed"
                    }
                }
            }
        }
    }

    function getValue(key, objective){
        if((objective.status === PROCESS_STATUS.needsApproval) && (objective.review_status.review_type === REVIEW_TYPES.editRequest)){
            return objective.edits[key]
        } else {
            return objective[key]
        }
    }

    return (
        !obj.loading ?
            (queryParams.mode === COMPONENTS_TYPE.listObjBylvl) && !isEmpty(obj.content) ?
            <div>
                {obj.content.map((row, rowIndex) => (
                    <div key={rowIndex} className="scroll-container">
                        {row.map((col, colIndex) => (
                            <ObjectiveCard key={col._id}
                                selected={selectedIndex.rowIndex === rowIndex && selectedIndex.columnIndex === colIndex}
                                wallPaper={ getValue('wallpaper',col)}
                                icon={getValue('icon',col)}
                                name={getValue('name',col)}
                                maxLevel={col.max_level}
                                level={col.level}
                                onClick={(event) => handleCardClick(event, {rowIndex : rowIndex, columnIndex : colIndex})}
                                pendingStatus={actionStatus(col.status,col.review_status)}
                            />
                        ))}
                    </div>
                ))}
                <div className="drill-button-container">
                        {
                            parseInt(queryParams.lvl)!==0 ?
                                <>
                                    <Button className="drill-button">
                                        <FontAwesomeIcon  icon={faArrowUp} onClick={getPreviousLevel}/>
                                    </Button>
                                </> : null
                        }
                        {
                            getMaxLevel(reviewObjectiveAllState.response) > 0 && 
                            parseInt(queryParams.lvl) < getMaxLevel(reviewObjectiveAllState.response) ?
                            <>
                                <Button className="ms-2 drill-button">
                                    <FontAwesomeIcon icon={faArrowDown} onClick={getNextLevel}/>
                                </Button>
                            </> : null
                        }
                </div>
            </div> 
            : null 
        : null
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        reviewObjectiveAllState : state.objectiveReviewAll,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjectiveReview : (userId) => dispatch(requestObjectiiveReviewAll(userId)),
        saveObjectives : (response) => dispatch(saveSpecificObjectiveForReview(response))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ObjReviewByLvl)