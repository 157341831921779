import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import PopUp from '../../common/popup/PopUp'
import InputBox from '../../common/Input-box/InputBox'
import Button from 'react-bootstrap/Button'
import { saveDepartments } from '../../redux/department/Department.action'
import { createDepartment, fetchDepartments, updateDepartment } from '../../services/departments.service'
import { clearCUDepartmentsState } from '../../redux/CU-department/CUDepartment.action'
import CustomizeButton from '../../common/customize-button/CustomizeButton'


const CreateDepartmentPopup = (props) => {

    const { CUDepartmentState, 
            departmentState, 
            updateDepartment, 
            requestCreateDepartment, 
            saveDepartments, 
            ownProps, 
            clearCUDepartmentState, 
            fetchDepartments } = props

    const activeDepartments = departmentState.response.active_departments

    const [ pageLoading, setPageLoading ] = useState(true)

    const initialValues ={
        name : ownProps.editDepartment == true && !isEmpty(activeDepartments) && !isEmpty(activeDepartments[ownProps.nameIndex]) ? activeDepartments[ownProps.nameIndex].name : ""
    }

    const validationSchema = Yup.object({
        name : Yup.string().required("Please enter your department")
    })

    const onSubmit = (values) => {
        if(ownProps.editDepartment == true){
            updateDepartment(activeDepartments[ownProps.nameIndex]._id,values)
        }else{
            requestCreateDepartment(values)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    useEffect(() => {
        if(props.editDepartment == true ){
            if(isEmpty(departmentState.response)){
                fetchDepartments()
            }
        }else{
            setPageLoading(false)
        }

        return () => {
            clearCUDepartmentState()
        }
    },[])

    useEffect(() => {
        if(!CUDepartmentState.loading && !isEmpty(CUDepartmentState.response)){
            console.log(CUDepartmentState.response)
            saveDepartments(CUDepartmentState.response)
            ownProps.handleClose()
        }

        if(!CUDepartmentState.loading && !isEmpty(CUDepartmentState.error)){
            console.log(CUDepartmentState.error)
        }
    },[CUDepartmentState])

    useEffect(() => {
        if(!departmentState.loading && !isEmpty(departmentState.response)){
            setPageLoading(false)
        }

        if(!departmentState.loading && !isEmpty(departmentState.error)){
            console.log(departmentState.error)
        }
    },[])

    return (
        <PopUp open={ownProps.open} handleClose={ownProps.handleClose}>
            {
                !pageLoading ?  
                    <div className='p-3 min-width-300px'>
                        <h4 className="lato-fs22-normal-lh32 font-bold text-center mb-3">{`${ownProps.heading} Department`}</h4>
                        <form onSubmit={formik.handleSubmit} >
                            <InputBox type="text"
                                name="name"
                                label="Name"
                                value={formik.values.name}
                                placeholder="Please enter name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={ formik.touched.name && formik.errors.name ? formik.errors.name : "" }
                            />
                            <div className="mt-3 mb-2 d-flex justify-content-between">
                                <CustomizeButton 
                                    type="button"
                                    variant="secondary-color-button"
                                    handleClick={ownProps.handleClose}
                                    label="Cancel"
                                />
                                <CustomizeButton 
                                    type="submit"
                                    variant="primary-color-button"
                                    label={`${ownProps.buttonLabelName} Department`}
                                />
                                {/* <Button className="reject-button mr-2" onClick={ownProps.handleClose}>Cancel</Button>
                                <Button className="accept-button" type="submit">{`${ownProps.buttonLabelName} Department`}</Button> */}
                            </div>
                        </form>
                    </div>
                    : null
            }
        </PopUp>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        CUDepartmentState : state.cuDepartment,
        departmentState : state.departments,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestCreateDepartment : (departmentDetail) => dispatch(createDepartment(departmentDetail)),
        saveDepartments : (response) => dispatch(saveDepartments(response)),
        fetchDepartments : () => dispatch(fetchDepartments()), 
        updateDepartment : (departmentId,departmentDetail) => dispatch(updateDepartment(departmentId,departmentDetail)),
        clearCUDepartmentState : () => dispatch(clearCUDepartmentsState()) 
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateDepartmentPopup)