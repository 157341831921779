import axios from 'axios'
import { getAuthToken, removeUserCredentials } from './utils'
import store from '../redux/store'
import {isEmpty} from 'lodash'
import config from '../config'

const {SERVER_URL} = config()

const ProtectedWebClient = axios.create({
    baseURL: SERVER_URL   
})

ProtectedWebClient.interceptors.request.use(config => {
    config.headers = config.headers || {}
    config.headers['Authorization'] = `Bearer ${getAuthToken()}`
    return config
})

const UNAUTHORIZED = 401;
const SUBSCRIPTION_INVALID = 403;
const { dispatch } = store;

ProtectedWebClient.interceptors.response.use(
    response => response,
    error => {
        if(!isEmpty(error) && !isEmpty(error.response)){
            const { status } = error.response
            if( status === UNAUTHORIZED ){
                removeUserCredentials()
                dispatch({
                    type : `USER_LOGOUT`
                })
                window.location.reload()
            } else if( status === SUBSCRIPTION_INVALID){
                return window.location.href = '/subscription/update'
            }
        }
        return Promise.reject(error)
    }
)

export default ProtectedWebClient