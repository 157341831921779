import React, { useRef } from 'react';
import Slider from 'react-slick'
import IconButton from '@mui/material/IconButton';
import useWindowDimension from '../../hooks/useWindowDimension'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './carousel-slider.css'

const CarouselSlider = (props) => {

    const slideRef = useRef(null)
    const {width, height} = useWindowDimension()

    const settings = {
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };

    function getNavigationBtnDisplayValidity(size){
        if(width > 1200 & size > 4){
            return true
        } else if(width <= 1200 & size > 3){
            return true
        } else {
            return false
        }
    }

    switch(props.variant){
        case "3-slide" :
            return (
                <div>
                    <div style={{ display: "flex", alignItems: "center" }} className='px-4 mb-2'>
                        <div className='me-3' style={{flex: "1"}} >
                            {  
                                props.breadcrumbs ? 
                                    props.breadcrumbs :
                                    null
                            }
                        </div>
                        {
                            props.size > 3 ?
                            <div style={{backgroundColor: 'white', borderRadius: "10px"}}>
                                <IconButton onClick={() => slideRef.current?.slickPrev()}>
                                    <ArrowBackIosNewIcon sx={{color: "black"}} />
                                </IconButton>
                                <IconButton onClick={() => slideRef.current?.slickNext()}>
                                    <ArrowForwardIosIcon sx={{color: "black"}} />
                                </IconButton>
                            </div> : null
                        }
                    </div>
                    <div className='mx-2'>
                        <Slider slidesToShow={3} slidesToScroll={3} infinite={false} speed={500} arrows={false} initialSlide={0} ref={slideRef} >
                            {props.children}
                        </Slider>
                    </div>
                </div>
            )

        default : 
            return (
                <div>
                    <div style={{ display: "flex", alignItems: "center" }} className='px-4 mb-2'>
                        <div className='me-3' style={{flex: "1"}} >
                            {  
                                props.breadcrumbs ? 
                                    props.breadcrumbs :
                                    null
                            }
                        </div>
                        {
                            getNavigationBtnDisplayValidity(props.size) ?
                            <div style={{backgroundColor: 'white', borderRadius: "10px"}}>
                                <IconButton onClick={() => slideRef.current?.slickPrev()}>
                                    <ArrowBackIosNewIcon sx={{color: "black"}} />
                                </IconButton>
                                <IconButton onClick={() => slideRef.current?.slickNext()}>
                                    <ArrowForwardIosIcon sx={{color: "black"}} />
                                </IconButton>
                            </div> : null
                        }
                    </div>
                    <div className='mx-2'>
                        <Slider {...settings} ref={slideRef} >
                            {props.children}
                        </Slider>
                    </div>
                </div>
            )
    }
};

export default CarouselSlider;
