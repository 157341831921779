import { REQUEST_FETCH_LEARNING,
         FETCH_LEARNING_SUCCESS,  
         FETCH_LEARNING_FAILURE,
         CLEAR_FETCH_LEARNING } from './FetchLearningTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const fetchLearningReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_FETCH_LEARNING : 
            return {
                ...state,
                loading: true,
                error: null
            }
        
        case FETCH_LEARNING_SUCCESS : 
            return {
                ...state,
                loading: false,
                response : action.payload
            }

        case FETCH_LEARNING_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_FETCH_LEARNING : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }
        
        default : 
            return state
    }
}

export default fetchLearningReducer