import ProtectedWebClient from "../utils/protected-web-client"
import PublicWebClient from "../utils/public-web-client"

export const fetchJobroles = () => {
    return new Promise((resolve, reject) => {
        PublicWebClient.get("/constant/job-role").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
            console.log(err)
        })
    })
}

export const fetchHighestQualifications = () => {
    return new Promise((resolve, reject) => {
        PublicWebClient.get("/constant/highest-qualification").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
            console.log(err)
        })
    })
}

export const fetchSubcriptionTypes = () => {
    return new Promise((resolve, reject) => {
        PublicWebClient.get("/constant/subscription-type").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchIndustries = () =>{
    return new Promise((resolve,reject) => {
        PublicWebClient.get("/constant/industry").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchStates = () => {
    return new Promise((resolve,reject) => {
        PublicWebClient.get("/constant/states").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchCountries = () => {
    return new Promise((resolve,reject) => {
        PublicWebClient.get("/constant/country-names").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchGoalsForMetric = () => {
    return new Promise((resolve,reject) => {
        ProtectedWebClient.get("/goal?option=select").then((response) => {
            resolve(response)
        }).catch((err) =>{
            reject(err)
        })
    })            
}

export const fetchGraphType = () => {
    return new Promise ((resolve,reject) => {
        PublicWebClient.get("/constant/graph-types").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchCourseContentStatus = () => {
    return new Promise ((resolve,reject) => {
        PublicWebClient.get("/constant/course-content-statuses").then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const requestPlan = (subscriptionType) => {
    return new Promise ((resolve,reject) => {
        PublicWebClient.get(`/plan?type=${subscriptionType}`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const requestBasicInfoPromise = () => {
    return new Promise ((resolve,reject) => {
        ProtectedWebClient.get(`/user-profile/basic-read`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const requestCreateOrderPromise = (planId) => {
    return new Promise ((resolve,reject) => {
        ProtectedWebClient.get(`/subscription/${planId}/create-order`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const requestConsultWithUs = (payload) => {
    return new Promise ((resolve,reject) => {
        ProtectedWebClient.post(`/constant/request-consultation`,payload)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}