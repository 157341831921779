import React,{useState,useEffect} from 'react'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { goalSelect } from '../../services/goals.service'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const GoalSelect = (props) => {

    const { fetchGoals, goalSelectState, ownProps, setSelectedValue, selectedValue} = props

    const [pageLoading,setPageLoading] = useState(true)
    const [ selectedGoal, setSelectedGoal ] = useState([])

    const objectives = goalSelectState.response.objectives

    function formattedGoalOptions(){
        let options=[]
        for( let objective of objectives ){
            let goals=[]
            for( let goal of objective.goals){
                goals.push({
                    label : goal.name,
                    value : goal._id
                })
            }
            options.push({label : objective.name ,options:goals})
        }
        return options
    }

    function handleInputChange(value){
        setSelectedGoal( value )
    }

    function handleProceedEvent(){
        setSelectedValue(selectedGoal)
        ownProps.onCancel()
    }

    useEffect(() => {
        if(!goalSelectState.loading && !isEmpty(goalSelectState.response)){
            setPageLoading(false)
        }

        if(!goalSelectState.loading && !isEmpty(goalSelectState.error)){
            console.log(goalSelectState.error)
            setPageLoading(false)
            if(goalSelectState.error.data){
                toast.error(goalSelectState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[goalSelectState])

    useEffect(() => {
        if(isEmpty(goalSelectState.response)){
            fetchGoals()
        }

        setSelectedGoal(selectedValue)
    },[])

    return (
        !pageLoading ? 
            <>
                <Select 
                    closeMenuOnSelect={false}
                    isMulti
                    menuPortalTarget={document.body}
                    styles={{ 
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: (styles ,{ isFocused }) => ({
                                    ...styles,
                                    ':hover': { borderColor: '#14213d' }, // border style on hover
                                    border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                    boxShadow: isFocused ? '' : "", // no box-shadow
                                }),
                            option : (styles) => ({
                                ...styles,
                                fontSize : "15px",
                                fontFamily :"'Lato',sans-serif"
                            })
                        }}
                    options={formattedGoalOptions()}
                    onChange={handleInputChange}
                    value={selectedGoal}
                />
                <div className="mt-3 d-flex justify-content-center">
                    <CustomizeButton variant="primary-color-button" handleClick={handleProceedEvent} label="Proceed" />
                </div>
            </> :null
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        goalSelectState : state.goalSelect,
        ownProps : ownProps,
        selectedValue : ownProps.selectedValue,
        setSelectedValue : ownProps.setSelectedValues,
        removeSelectedObjective : ownProps.removeSelectedObjective,
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGoals : () => dispatch(goalSelect())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GoalSelect)