import {  
    CLEAR_UPDATE_ORG_PROFILE_STATES,
    REQUEST_UPDATE_ORG_PROFILE_DATA,
    UPDATE_ORG_PROFILE_DATA_SUCCESS,
    UPDATE_ORG_PROFILE_DATA_FAILURE
} from './updateOrgProfile.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const updateOrgProfileDataReducer = ( state = initialState, action ) => {
    switch(action.type){

        case REQUEST_UPDATE_ORG_PROFILE_DATA:
            return {
                ...state,
                loading: true,
                error : null
            }

        case UPDATE_ORG_PROFILE_DATA_SUCCESS: 
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case UPDATE_ORG_PROFILE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        
        case CLEAR_UPDATE_ORG_PROFILE_STATES :
            return {
                ...state,
                loading: false,
                response: {},
                error: null
            }

        default : 
            return state
    }
}

export default updateOrgProfileDataReducer