import { REQUEST_LIST_COURSES, 
         LIST_COURSES_SUCCESS, 
         LIST_COURSES_FAILURE, 
         CLEAR_LIST_COURSES } from './ListCoursesTypes'

export const requestListOfCourses = () =>{
    return {
        type : REQUEST_LIST_COURSES
    }
} 

export const listOfCoursesSuccess = (resData) => {
    return {
        type : LIST_COURSES_SUCCESS,
        payload : resData
    }
}

export const listOfCoursesFailure = (error) => {
    return {
        type : LIST_COURSES_FAILURE,
        payload : error
    }
}

export const clearListOfCourses = () => {
    return {
        type : CLEAR_LIST_COURSES
    }
}