import { REQUEST_MEMBERS,
        SAVE_MEMBERS,
        MEMBERS_FAILURE,
        CLEAR_MEMBER_STATE } from './members.types'

export const requestMembers = () => {
    return {
        type : REQUEST_MEMBERS
    }
}

export const fetchedMemberSuccess = (response) => {
    return {
        type : SAVE_MEMBERS,
        payload : response
    }
}

export const fetchedMemberFailure = (error) => {
    return {
        type : MEMBERS_FAILURE,
        payload : error
    }
}

export const clearMemberState = () => {
    return {
        type : CLEAR_MEMBER_STATE
    }
}