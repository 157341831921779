import { isEmpty } from 'lodash';
import React from 'react'
import './text-field.css';

const TextField = (props) => {
    const className = props.className ? `text-field-static ${props.className}` : 'text-field-static'

    return (
        <div className={!isEmpty(props.value) ? className : "text-field-static h-40"}>
            <label className="m-0 d-block fs16-lh20 pb-1 text-left">{props.label}</label>
            <h1 className="m-0 pb-0 fs15-lh20 text-left">{props.value}</h1>
        </div>
    )
}

export default TextField