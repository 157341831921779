import { REQUEST_SPECIFIC_GOALS, 
         SPECIFIC_GOAL_SUCCESS,
         SPECIFIC_GOAL_FAILURE,
         CLEAR_SPECIFIC_GOALS_STATES } from './specificGoal.type'

export const requestSpecificGoals = () => {
    return {
        type : REQUEST_SPECIFIC_GOALS
    }
}

export const specificGoalSuccess = (responseData) => {
    return {
        type : SPECIFIC_GOAL_SUCCESS,
        payload : responseData
    }
}

export const specificGoalFailure = (error) => {
    return {
        type : SPECIFIC_GOAL_FAILURE,
        payload : error
    }
}

export const clearSpecificGoalStates = () => {
    return {
        type : CLEAR_SPECIFIC_GOALS_STATES
    }
}