import React from 'react'
import './parent-obj-card.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';

import wallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png'

const ParentObjectiveCard = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    switch(props.variant){
        case "desktop-view":
            return (
                <div className={props.active ? 'obj-parent-card max-width-200px active' : 'obj-parent-card max-width-200px'} 
                    style={{backgroundImage:`url(${props.wallPaper ? props.wallPaper : wallpaperPlaceholder})`}} >
                    <div className="tree-overlay">
                        <div className='d-flex align-items-center px10-py12'>
                            <p className={props.active ? "m-0 lato-fs20-normal-lh28 one-line-wrap white-color font-bold" : "m-0 lato-fs18-normal-lh22 one-line-wrap white-color"} 
                                onClick={props.onClick}>{props.name}</p>
                            {props.hideMenu ? "" :
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <FontAwesomeIcon className="white-color fs-13 ms-2" icon={faEllipsisV} />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}>
                                        <MenuItem 
                                            disabled={props.disableChoosePath}
                                            onClick={() => {
                                                props.onChoosePathClick()
                                                handleClose()
                                            }}>Choose path</MenuItem>
                                        <MenuItem 
                                            disabled={props.disableRemovePath}
                                            onClick={() => {
                                                props.onRemovePathClick()
                                                handleClose()
                                            }}>Remove path</MenuItem>
                                    </Menu>
                                    {
                                        props.hasErrors ?
                                        <ErrorIcon sx={{ color: 'red', marginLeft: '10px' }} fontSize="medium" /> :
                                        null
                                    }
                                </div>}
                        </div>
                    </div>
                </div>
            )
        
        default :
            return (
                <div className={props.active ? 'obj-parent-card active' : 'obj-parent-card'} 
                    style={{backgroundImage:`url(${props.wallPaper ? props.wallPaper : wallpaperPlaceholder})`}} >
                    <div className="tree-overlay">
                        <div className='d-flex align-items-center px10-py12'>
                            <p className={props.active ? "m-0 lato-fs18-normal-lh20 one-line-wrap white-color font-bold" : "m-0 lato-fs16-normal-lh20 one-line-wrap white-color"} onClick={props.onClick}>{props.name}</p>
                            {props.hideMenu ? "" :
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <FontAwesomeIcon className="white-color fs-13 ms-2" icon={faEllipsisV} />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}>
                                        <MenuItem 
                                            disabled={props.disableChoosePath}
                                            onClick={() => {
                                                props.onChoosePathClick()
                                                handleClose()
                                            }}>Choose path</MenuItem>
                                        <MenuItem 
                                            disabled={props.disableRemovePath}
                                            onClick={() => {
                                                props.onRemovePathClick()
                                                handleClose()
                                            }}>Remove path</MenuItem>
                                    </Menu>
                                    {
                                        props.hasErrors ?
                                        <ErrorIcon sx={{ color: 'red', marginLeft: '10px' }} fontSize="medium" /> :
                                        null
                                    }
                                </div>}
                        </div>
                    </div>
                </div>
            )
    }
}

export default ParentObjectiveCard