import { REQUEST_REVIEW_TEAM, 
        FETCH_REVIEW_TEAM_SUCCESS,
        FETCH_REVIEW_TEAM_FAILURE,
        CLEAR_REVIEW_TEAM_STATE } from './ReviewTeam.types'

export const requestReviewTeam = () => {
    return {
        type : REQUEST_REVIEW_TEAM
    }
}

export const fetchReviewTeamSuccess = (response) => {
    return {
        type : FETCH_REVIEW_TEAM_SUCCESS,
        payload : response
    }
}

export const fetchReviewTeamFailure = (error) => {
    return {
        type : FETCH_REVIEW_TEAM_FAILURE,
        payload : error
    }
}

export const clearReviewTeamState = () => {
    return {
        type : CLEAR_REVIEW_TEAM_STATE
    }
}