import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faTrophy, faVideo } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import waveBg from '../../assets/icons/wave.png'
import { IconButton } from '@mui/material'

const ProcessHeaderCard = (props) => {

    const location = useLocation().pathname
    const companyview = location.split("/")[1]
    return (
        <div 
            style={{
                borderBottomLeftRadius: '1rem', 
                borderBottomRightRadius: '1rem', 
                backgroundColor: 'white', 
                boxShadow: "0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%)"  
            }}
        >
            <div style={{position: 'relative', height: '180px', width: '100%', marginBottom: '1px'}}>
                <img src={waveBg} style={{width: '100%', height: '100%'}} alt=""/>
                <div style={{position: 'absolute', top: 0, left: 0, width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
                    <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                        <div style={{width: '65%', paddingLeft: '5%'}}>
                            <h4 className="lato-fs-20-normal-lh32 font-bold" style={{color: "white"}}>{props.punchLine}</h4>
                        </div>
                        <div style={{width: '35%', paddingRight: '5%', display: 'flex', justifyContent: 'center'}}>
                            <img src={props.coverImgSrc} alt="" style={{maxWidth: "95%", maxHeight: "110px" }} />
                        </div>
                    </div>
                    <div 
                        style={{
                            height: '3rem', 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            paddingRight: '2%',
                            paddingLeft: '2%'
                        }} 
                    >
                        {
                            props.enableBuildingIcon ?
                                <IconButton onClick={props.onBuildingIconClick} >
                                    <FontAwesomeIcon 
                                        style={{fontSize: '20px'}}
                                        className={ companyview  ==="company-view" ? "primary-color-v2" : "secondary-text-color"} 
                                        icon={faBuilding}
                                    />
                                </IconButton>
                                : null
                        }
                        { 
                            props.enableVideoIcon ? 
                                <IconButton onClick={props.onVideoIconClick} >
                                    <FontAwesomeIcon 
                                        style={{fontSize: '20px'}}
                                        className="secondary-color" 
                                        icon={faVideo}
                                    />
                                </IconButton>
                                : null
                        }
                        { 
                            props.enableAwardIcon ? 
                                <IconButton onClick={props.onTrophyIconClick} >
                                    <FontAwesomeIcon
                                        style={{fontSize: '20px'}}
                                        className="secondary-color"
                                        icon={faTrophy}
                                    />
                                </IconButton>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ProcessHeaderCard