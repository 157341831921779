import React from 'react'
import { isEmpty } from 'lodash'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import wallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png'
import './course-panel.css'

const CoursePanel = (props) => {

    function getCourseThumbnail(course){
        let thumbnail = ''
        if(!isEmpty(course.course_content_files)){
            for (const file of course.course_content_files) {
                if(file?.mimetype?.split('/')[0] == 'image'){
                    thumbnail = file.url
                    break
                }
            }
        }
        
        if(!thumbnail && !isEmpty(course.course_content_links)){
            for(const link of course.course_content_links){
                if(link.link_meta_data?.contentType?.split('/')[0] == 'image'){
                    thumbnail = link.link_meta_data.url
                    break
                } else if(link.link_meta_data?.contentType?.split('/')[0] == 'text' && !isEmpty(link.link_meta_data.images)) {
                    thumbnail = link.link_meta_data.images[0]
                    break
                }
            }
        }
        return thumbnail
    }

    switch(props.variant){
        case "mobile": 
            return (
                <Card 
                    style={{
                        height: "200px",
                        width: "100%",
                        cursor: "pointer",
                        display: 'flex',
                        flexDirection: "column"
                    }} 
                    onClick={props.onCardClick}
                >
                    <div style={{width: "100%", flex: "1", position: 'relative'}}>
                        <img 
                            style={{objectFit: "cover", width: "100%", height: "100%", position: "absolute"}}
                            src={getCourseThumbnail(props.course) ? getCourseThumbnail(props.course) : wallpaperPlaceholder} 
                            alt=""
                        />
                    </div>
                    <CardContent style={{padding : "10px", display: "flex", alignItems: "center"}} >
                        <Typography component="div" variant="p"
                            sx={{
                                fontSize : "16px",
                                color : "rgba(0,0,0,1)",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp :2 ,
                                WebkitBoxOrient:"vertical",
                                lineHeight:"20px",
                                textOverflow: "ellipsis",
                                fontFamily: '"Lato", sans-serif',
                                fontWeight: "500",
                                flex : 1,
                                minWidth: 0
                            }}
                        >
                                {props.course?.name}
                        </Typography>
                    </CardContent>
                </Card>
            )

        default : 
            return (
                <Card 
                    style={{
                        height: "200px",
                        width: "100%",
                        cursor: "pointer",
                        display: 'flex',
                        flexDirection: "column"
                    }} 
                    onClick={props.onCardClick}
                >
                    <div style={{width: "100%", flex: "1", position: 'relative'}}>
                        <img 
                            style={{objectFit: "cover", width: "100%", height: "100%", position: "absolute"}}
                            src={getCourseThumbnail(props.course) ? getCourseThumbnail(props.course) : wallpaperPlaceholder} 
                            alt=""
                        />
                    </div>
                    <CardContent style={{padding : "15px", display: "flex", alignItems: "center"}} >
                        <Typography component="div" variant="p"
                            sx={{
                                paddingLeft : "10px",
                                fontSize : "20px",
                                color : "rgba(0,0,0,1)",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp :2 ,
                                WebkitBoxOrient:"vertical",
                                lineHeight:"21px",
                                textOverflow: "ellipsis",
                                fontFamily: '"Lato", sans-serif',
                                fontWeight: "500",
                                flex : 1,
                                minWidth: 0
                            }}
                        >
                                {props.course?.name}
                        </Typography>
                    </CardContent>
                </Card>
            )
    }
}

export default CoursePanel