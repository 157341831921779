import React from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import qs from 'query-string'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {isEmpty} from 'lodash'
import { COMPONENTS_TYPE } from '../../components/review-tab/objective-all/objectiveAll.utils'

import './review-action-tab.css'
import ObjectiveAll from '../../components/review-tab/objective-all/ObjectiveAll'
import ObjectiveUnderReview from '../../components/review-tab/objective-under-review/ObjectiveUnderReview'

const ReviewActionsTab = () => {

    const location = useLocation()
    const history = useHistory()
    const params = useParams()
    const queryParams = qs.parse(location.search)

    function handleTabEvent(key, ...search){
        if(!isEmpty(search)){
            let queryStr = '' 
            for(const item of search){
                queryStr += `&${item}`
            }
            history.push(`/review/${params.userId}?role=${queryParams.role}&action=${queryParams.action}&tab=${key}${queryStr}`)
        } else {
            history.push(`/review/${params.userId}?role=${queryParams.role}&action=${queryParams.action}&tab=${key}`)
        }
    }

    function handleMainTabEvent(key){
        history.push(`/review/${params.userId}?role=${queryParams.role}&action=${key}&tab=under-your-review`)
    }

    return (
        <>
        <div className="container pe-0 ps-0 ">
            <Tabs fill activeKey={queryParams.action ? queryParams.action : "objective"} id="uncontrolled-tab-example" className="flex-nowrap scroll-container" style={{borderBottom:"none"}}
                onSelect={(key) => handleMainTabEvent(key)}>
                <Tab eventKey="vcf" title="VCF" className="color-black" tabClassName="border-tab" className="mt-2">
                    <div className="container-bg pt-2 border-rl" style={{height:"92vh"}}>
                        <Tabs fill activeKey={queryParams.tab ? queryParams.tab : "under-your-review"} id="uncontrolled-tab-example" className="nested-tab" 
                            onSelect={(key) => handleTabEvent(key, 'lvl=0', `mode=${COMPONENTS_TYPE.listObjBylvl}`)} style={{borderBottom:"none"}}>
                            <Tab eventKey="all" title="All" className="color-black" tabClassName="tab-items">   
                                <div className="mt-3">
                                    <ObjectiveAll />
                                </div>
                            </Tab>
                            <Tab eventKey="under-your-review" title="Under Your Review" className="color-black" tabClassName="tab-items"> 
                                <div className="mt-3">
                                    <ObjectiveUnderReview/>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Tab>
                <Tab eventKey="goal" title="Goal" className="color-black" tabClassName="border-tab" className="mt-2">
                    <div className="container-bg pt-2 border-rl" style={{height:"92vh"}}>
                        <Tabs fill activeKey={queryParams.tab ? queryParams.tab : "under-your-review"} id="uncontrolled-tab-example" className="nested-tab" 
                            onSelect={(key) => handleTabEvent(key)} style={{borderBottom:"none"}}>
                            <Tab eventKey="all" title="All" className="color-black" tabClassName="tab-items">   
                                <div className="mt-3">
                                    <h6>No goals present</h6>
                                </div>                           
                            </Tab>
                            <Tab eventKey="under-your-review" title="Under Your Review" className="color-black" tabClassName="tab-items"> 
                                <div className="mt-3">
                                    <h6>No goal under action</h6>
                                </div>
                            </Tab>
                        </Tabs> 
                    </div>
                </Tab>
                <Tab eventKey="metric" title="Metric" className="color-black" tabClassName="border-tab">
                    <Tabs fill activeKey={queryParams.tab ? queryParams.tab : "under-your-review"} id="uncontrolled-tab-example" className="mt-3" 
                        onSelect={(key) => handleTabEvent(key)} style={{borderBottom:"none"}}>
                        <Tab eventKey="all" title="All" className="color-black" tabClassName="tab-items">   
                            <div className="mt-3">
                                <h6>No goals present</h6>
                            </div>                           
                        </Tab>
                        <Tab eventKey="under-your-review" title="Under Your Review" className="color-black" tabClassName="tab-items"> 
                            <div className="mt-3">
                                <h6>No goal under action</h6>
                            </div>
                        </Tab>
                    </Tabs> 
                </Tab>
                <Tab eventKey="learning-area" title="Learning Area" className="color-black"tabClassName="border-tab" >
                    <Tabs fill activeKey={queryParams.tab ? queryParams.tab : "under-your-review"} id="uncontrolled-tab-example" className="mt-3" 
                        onSelect={(key) => handleTabEvent(key)} style={{borderBottom:"none"}}>
                        <Tab eventKey="all" title="All" className="color-black" tabClassName="tab-items">   
                            <div className="mt-3">
                                <h6>No goals present</h6>
                            </div>                           
                        </Tab>
                        <Tab eventKey="under-your-review" title="Under Your Review" className="color-black" tabClassName="tab-items"> 
                            <div className="mt-3">
                                <h6>No goal under action</h6>
                            </div>
                        </Tab>
                    </Tabs> 
                </Tab>
            </Tabs>
        </div>
        </>
    )
}

export default ReviewActionsTab
