import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty, toArray, capitalize, size } from 'lodash'
import qs from 'query-string'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { faListAlt, faSignal, faThLarge } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import './sent-detail.css'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { notificationDetail } from '../../../services/notification.service'
import MailUserDetail from '../../../components/notification/mail-user-detail/MailUserDetail'
import { clearNotificationDetailState } from '../../../redux/notification/notification-detail/notificationDetail.action'
import ObjectiveCard from '../../../components/objective-card/ObjectiveCard'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import goalIcon from '../../../assets/icons/active-bullseye.svg'
import MetricCard from '../../../components/metric-card/MetricCard'
import SimpleCard from '../../../components/simple-card/SimpleCard'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import CarouselSlider from '../../../common/carousel-slider/CarouselSlider'
import Header from '../../../common/header/Header'
import AggregateCard from '../../../components/aggregate-card/AggregateCard'

const SentDetail = (props) => {

    const { notificationDetailState, requestSentNotificationDetail, userLogout } = props

    const [pageLoading, setPageLoading] = useState(true)
    const [ menu, setMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const location = useLocation()
    const params = useParams()
    const history = useHistory()

    const queryParams = qs.parse(location.search)
    const sentActions = notificationDetailState.response.mail
    const isDesktop = useMediaQuery('(min-width:992px)')

    function handleBackArrowEvent(){
        history.goBack();
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    useEffect(() => {
        if( !notificationDetailState.loading && !isEmpty(notificationDetailState.response)){
            setPageLoading(false)
        }

        if(!notificationDetailState.loading && !isEmpty(notificationDetailState.error)){
            setPageLoading(false)
            if(notificationDetailState.error?.data){
                toast.error(notificationDetailState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    },[notificationDetailState])

    useEffect(() => {
        if(isEmpty(notificationDetailState.response)){
            requestSentNotificationDetail(params.notificationId,"sent")
        }
    },[])

    function getPath(objective){
        if(objective.edited_copy){
            return toArray(objective.edited_after.path)
        } else {
            return toArray(objective.path)
        }
    }

    function getActiveId(objective){
        if(objective.edited_copy){
            return objective.edited_after._id
        } else {
            return objective._id
        }
    }

    // course icon
    function getCourseThumbnail(course){
        let thumbnail = ''
        if(!isEmpty(course.course_content_files)){
            for (const file of course.course_content_files) {
                if(file.mimetype?.split('/')[0] == 'image'){
                    thumbnail = file.url
                    break
                }
            }
        }
        
        if(!thumbnail && !isEmpty(course.course_content_links)){
            for(const link of course.course_content_links){
                if(link.link_meta_data?.contentType.split('/')[0] == 'image'){
                    thumbnail = link.link_meta_data.url
                    break
                } else if(link.link_meta_data?.contentType?.split('/')[0] == 'text' && !isEmpty(link.link_meta_data.images)) {
                    thumbnail = link.link_meta_data.images[0]
                    break
                }
            }
        }
        return thumbnail
    }

    function getDetail(){
        return (
            <>
                { isDesktop ?
                    <div className='bg-tertiary-color-v2' style={{padding:"10px", borderRadius:"1rem"}}>
                        <CarouselSlider size={size(sentActions.actions)} variant="3-slide"
                            breadcrumbs={<h1 className="lato-fs18-normal-lh22 font-bold my-2 primary-text-color">{sentActions.action_type === "objectives" ? "VCF" : capitalize(sentActions.action_type )}</h1>} >
                            {!isEmpty(sentActions.actions) ?
                                sentActions.actions.map((action, index) => (
                                    <div key={index} className="mb-3">
                                        {action.action ? 
                                            <>
                                                {/* { sentActions.action_type == "objectives" ? 
                                                    <BreadCrumbs path={getPath(action.action)} containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                        activeId={getActiveId(action.action)}  
                                                        icon={sentActions.action_type == "objectives" ? faThLarge : ""}
                                                    /> :"" } */}
                                                {/* {sentActions.action_type  == "goals" ? 
                                                    <BreadCrumbs path={toArray(action.action.objective_id.path)} 
                                                        containerClassName="mb-2 bread-crumb-box-shadow" 
                                                        listClassName="mb-0"
                                                        icon={faThLarge}
                                                        activeId={action.action.objective_id._id} /> : ""} */}
                                                {/* {sentActions.action_type == "graphs" ? 
                                                    <>
                                                        <BreadCrumbs path={toArray(action.action.goal.objective_id.path)} 
                                                            containerClassName="mb-2 bread-crumb-box-shadow" 
                                                            listClassName="mb-0"
                                                            activeId={action.action.goal.objective_id._id}
                                                            icon={faThLarge} /> 
                                                        <div>
                                                            <BreadCrumbs path={[{name:action.action.goal.name,_id :action.action.goal._id}]} 
                                                                containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                                activeId={action.action.goal._id} img={goalIcon} />
                                                        </div>
                                                    </>: ""} */}
                                                {/* {sentActions.action_type === "courses" ? 
                                                    <BreadCrumbs icon={faListAlt} containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                        path={[{name : action.action.parent_learning_area.name,_id : action.action.parent_learning_area._id}]} activeId={action.action.parent_learning_area._id} /> : ""} */}
                                                {sentActions.action_type === "graphs" ?
                                                    <Box sx={{marginLeft:"10px",marginRight:"10px"}}>
                                                        <AggregateCard 
                                                            variant="desktop"
                                                            hideIcon={true}
                                                            name={action.action.metric.name}
                                                            containerClassName="cursor-unset"
                                                        />
                                                    </Box>  : 
                                                        sentActions.action_type === "learningareas" || sentActions.action_type == "courses"  ? 
                                                            <Box sx={{marginLeft:"10px",marginRight:"10px"}}>
                                                                <AggregateCard 
                                                                    name={action.action.name} 
                                                                    iconImage={true}
                                                                    icon={sentActions.action_type === "courses" ? getCourseThumbnail(action.action) : action.action.icon} 
                                                                    containerClassName="cursor-unset"
                                                                />
                                                            </Box> :
                                                    <Box sx={{marginLeft:"10px",marginRight:"10px"}}>
                                                        <AggregateCard 
                                                            containerClassName="cursor-unset"
                                                            wallpaper ={ action.action.wallpaper }
                                                            icon={ action.action.icon }
                                                            iconImage={true}
                                                            name={ action.action.name }
                                                            level={action.action?.level}
                                                            maxLevel={action.action?.max_level} 
                                                            variant={isDesktop ? "desktop" : ""}
                                                        />
                                                    </Box> } 
                                                </>:
                                            <div className='ml-10 mr-10'>
                                                <SimpleCard name="Process Unavailable"/>
                                            </div> }
                                    </div>
                                )) : 
                                <p>Shared {queryParams.action} is not available</p>
                            }
                        </CarouselSlider>
                    </div> : 
                    <div className='scroll-container bg-tertiary-color-v2 py-3' style={{borderRadius:"1rem"}}>
                        {!isEmpty(sentActions.actions) ?
                            sentActions.actions.map((action, index) => (
                                <div key={index} className="inbox-card-detail-container">
                                    {action.action ? 
                                        <>
                                            {sentActions.action_type === "graphs" ?
                                                <Box>
                                                    <AggregateCard 
                                                        variant="mobile"
                                                        hideIcon={true}
                                                        containerClassName="cursor-unset"
                                                        name={action.action.metric.name} />
                                                </Box>  : 
                                                    sentActions.action_type === "learningareas" || sentActions.action_type === "courses"  ? 
                                                        <Box>
                                                            <AggregateCard 
                                                                containerClassName="cursor-unset max-width-250px"
                                                                name={action.action.name} 
                                                                icon={sentActions.action_type === "courses" ? getCourseThumbnail(action.action) : action.action.icon} 
                                                            />
                                                        </Box> :
                                                <Box>
                                                    <AggregateCard 
                                                        containerClassName="cursor-unset max-width-250px"
                                                        wallpaper ={ action.action.wallpaper }
                                                        icon={ action.action.icon }
                                                        name={ action.action.name }
                                                        level={action.action?.level}
                                                        maxLevel={action.action?.max_level} 
                                                    />
                                                </Box> } 
                                            </>:
                                        <div>
                                            <SimpleCard name="Process Unavailable"/>
                                        </div> }
                                </div>
                            )) : 
                            <p>Shared {queryParams.action} is not available</p>
                        }
                    </div>
                }
                <hr/>
                <div className={isDesktop ? '' : 'px-3'}>
                    <MailUserDetail
                        variant={isDesktop ? '' : 'mobile'} 
                        messageContainerClassName={isDesktop ? "mb-0" : ""}
                        createdDate={sentActions.createdAt}
                        usersData={sentActions.to}
                        message={sentActions.message}
                        actionWay="To"
                    />
                </div>
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableLogout={true} 
                            enableProfileButton={ isDesktop ? true : false } 
                            enableMore={true}
                            open={open}
                            menu={menu}
                            moreRight="72px"
                            handlePopoverClose={handleMenuClose} 
                            handlePopoverOpen={handleMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {!pageLoading ? 
                                        <>
                                            <div className='mt-3'>
                                                <h5 className='lato-fs-20-normal-lh32 primary-text-color font-bold mb-0'>{sentActions.subject}</h5>
                                            </div>
                                            <div className='mt-3'>
                                                {getDetail()}
                                            </div>
                                        </> : null 
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                : 
                <>
                    <Navbar 
                        variant="mobile" 
                        enableLogout={true}
                        enableDrawer={true}
                        drawerOpened={drawer}
                        handleDrawerClose={toggleDrawer}
                        onDrawerClick={toggleDrawer}
                    />
                    <div className='container'>
                        {!pageLoading ? 
                            <>
                                <div className='mt-3 px-3'>
                                    <Header heading={sentActions.subject} handleBackArrowEvent={handleBackArrowEvent}/>
                                    <h5 className="lato-fs15-normal-lh20 mb-0 pb-0 secondary-text-color">{capitalize(sentActions.action_type === "objectives" ? "VCF" : sentActions.action_type )}</h5>
                                </div>
                                <div className="mt-2">
                                    {getDetail()}
                                </div>
                            </> : null
                        }
                    </div>
                    <BottomNavigation />
                </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        notificationDetailState : state.notificationDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestSentNotificationDetail : (mailId,action) => dispatch(notificationDetail(mailId,action)),
        clearNotificationDetailState : () => dispatch(clearNotificationDetailState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SentDetail)