import { REQUEST_CU_DEPARTMENTS, 
        CU_DEPARTMENTS_SUCCESS,
        CU_DEPARTMENTS_FAILURE,
        CLEAR_CUDEPARTMENTS_STATE } from './CUDepartment.types'

export const requestCUDepartments = () => {
    return {
        type : REQUEST_CU_DEPARTMENTS
    }
}

export const saveCUDepartments = (response) => {
    return {
        type : CU_DEPARTMENTS_SUCCESS,
        payload : response
    }
}

export const cuDepartmentsFailure = (error) => {
    return {
        type : CU_DEPARTMENTS_FAILURE,
        payload : error
    }
}

export const clearCUDepartmentsState = () => {
    return {
        type : CLEAR_CUDEPARTMENTS_STATE
    }
}