import React,{ useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import moment from 'moment'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'

import InputBox from '../../common/Input-box/InputBox'
import SelectBoxValueById from '../../common/select-box-value-id/SelectBoxValueById'
import { getRoleMembers, createUser, fetchMemberDetail, updateUser } from '../../services/user.service'
import { fetchBranches } from '../../services/branch.service'
import { fetchDepartments } from '../../services/departments.service'
import DatePicker from '../../common/date-picker/DatePicker'
import Container from '../desktop-layout/container/Container';
import Navbar from '../desktop-layout/navbar/Navbar';
import LeftVerticalMenuBar from '../desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar';
import ProcessSectionContainer from '../desktop-layout/process-section-container/ProcessSectionContainer';
import SubMainContainer from '../desktop-layout/sub-main-container/SubMainContainer';
import CustomizeButton from '../../common/customize-button/CustomizeButton';
import { formatDate} from '../../utils/utils'
import useDebounce from '../../hooks/useDebounce'
import BottomNavigation from '../bottom-navgation/BottomNavigation';
import ProcessMainContainer from '../desktop-layout/process-main-container/ProcessMainContainer';
import Header from '../../common/header/Header';

const CreateOrEditUser = (props) => {

    const { requestBranch, 
            branchesState, 
            requestDepartment, 
            departmentsState, 
            memberDetailState,
            requestMemberDetail } = props

    const isDesktop = useMediaQuery('(min-width:992px)')

    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const action = location.pathname.split('/')[3]

    const [ inviteState, setInviteState ] = useState(false)
    const [ pageLoading, setPageLoading ] = useState({
        branch : true,
        department : true,
        memberDetail : true
    })
    const [ menu, setMenu ] = useState(false)
    const [ searchedValue, setSearchedValue ] = useState({
        value : "",
        typing: false,
        data : []
    })
    const [ drawerOpen, setDrawerOpen ] = useState(false)

    const memberDetail = memberDetailState.response

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    }

    function handleInputChange(event, value){
        setSearchedValue( ps => {
            return {
                ...ps,
                value : event.target.value,
                typing : true,
                data : []
            }
        })
        searchDebounce(event.target.value)
    }

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    const searchDebounce = useDebounce((nextString) => {
        if(nextString && nextString.length > 0){
            getRoleMembers(nextString)
                .then(res => {
                    setSearchedValue(ps => {
                        return {
                            ...ps,
                            data : res.data?.users,
                            typing: false
                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setSearchedValue(ps => {
                return {
                    ...ps,
                    value : "",
                    typing : false,
                    data : []
                }
            })
        }
    }, 1000)

    const userRole = [
        {
            label : "User",
            value : "user"
        },
        {
            label : "Controller",
            value : "user_cum_controller"
        }
    ]

    const initialValues={
        person_name : {
            first_name : action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.person_name) && !isEmpty(memberDetail.person_name.first_name) ? memberDetail?.person_name?.first_name : "",
            last_name : action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.person_name) && !isEmpty(memberDetail.person_name.last_name) ? memberDetail?.person_name?.last_name : ""
        },
        email : action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.email) && !isEmpty(memberDetail.email.id) ? memberDetail?.email?.id : "",
        gender: action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.gender) ? memberDetail?.gender : "",
        mobile_number: action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.mobile) && !isEmpty(memberDetail.mobile.number) ? memberDetail?.mobile?.number : "",
        job_role : action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0].job_role) ? memberDetail?.user_subscriber_mapping[0]?.job_role : "",
        department : action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0].department) ? memberDetail?.user_subscriber_mapping[0]?.department?._id : "",
        branch : action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0].branch) ? memberDetail?.user_subscriber_mapping[0]?.branch?._id : "",
        dob: action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.dob) ? formatDate(memberDetail?.dob) : "",
        role : action === 'edit' && !isEmpty(memberDetail) && !isEmpty(memberDetail.user_subscriber_mapping) && !isEmpty(memberDetail.user_subscriber_mapping[0].role) ? memberDetail?.user_subscriber_mapping[0]?.role : "user",
        assignees : [],
        settings : {
            objective : {
                super_reviewer : false,
                reviewer : false
            },
            goal : {
                super_reviewer : false,
                reviewer : false
            },
            metric : {
                super_reviewer : false,
                reviewer : false
            },
            learning_area : {
                super_reviewer : false,
                reviewer : false
            }
        }
    }

    const validationSchema = Yup.object({
        person_name : Yup.object().shape({
            first_name : Yup.string().required("Please enter first name"),
            last_name : Yup.string().required("Please enter last name")
        }),
        email : Yup.string().required("Please enter email").email("Invalid email"),
        gender: Yup.string().required("Please select the gender"),
        mobile_number : Yup.string().required("Please enter mobile number").max(10,"Invalid mobile number").min(10,"Invalid mobile number"),
        job_role : Yup.string().required("Please enter job role"),
        dob: Yup.string().required("Please select date of birth"),
        role : Yup.string(),
        branch : Yup.string().required("Please select a location"),
    })

    console.log(action)

    const onSubmit = (values) => {
        const valuesCopy = { ...values }
        valuesCopy.dob = moment(values.dob).toISOString()
        valuesCopy.assignees = getAssigneeId(values.assignees)
        if(navigator.onLine){
            if(action === 'edit'){
                updateUser(params.memberId,valuesCopy)
                    .then((res) => {
                        if(res){
                            history.goBack()
                            requestMemberDetail(params.memberId)
                        }
                    }).catch((error) => {
                        toast.error(error.response.data.error,{
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    })
            }else{
                createUser(inviteState,valuesCopy).then((res) => {
                    if(res){
                        history.push("/organisation?selected=members")
                    }
                }).catch(error => {
                    // console.log(error.response.data.error)
                    toast.error(error.response.data.error,{
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function getAssigneeId(array){
        return array.map((assignee) => {
            return assignee._id
        })
    }

    // async function handleInputChange(event,value){
    //     if(value.length > 1){
    //         try {
    //             // console.log(value)
    //             const emailSuggestions = await getRoleMembers(value)
    //             setMembers(emailSuggestions.data.users) 
    //         } catch (error) {
    //             console.log(error)
    //             if(error && !isEmpty(error.response) && !isEmpty(error.response.data)){
    //                 toast.error(error.response.data?.error, {
    //                     position: "bottom-right",
    //                     autoClose: 3000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 })
    //             }
    //         }
    //     }
    // }

    function getBranch(array){
        return array.map((branch) => {
            return {
                label : branch.city,
                value : branch._id
            }
        })
    }

    function getDepartment(array){
        return array.map((department) => {
            return {
                label : department.name,
                value : department._id
            }
        })
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function loadingPage(){
        if(action === 'edit'){
            if( !pageLoading.branch && !pageLoading.department && !pageLoading.memberDetail){
                return true
            }else{
                return false
            }
        }else{
            if(!pageLoading.branch && !pageLoading.department){
                return true
            }else{
                return false
            }
        }
    }

    useEffect(() => {
        if(isEmpty(branchesState.response)){
            requestBranch()
        }

        if(isEmpty(departmentsState.response)){
            requestDepartment()
        }

        if(action === 'edit' && isEmpty(memberDetailState.response)){
            requestMemberDetail(params.memberId)
        }
    },[])

    useEffect(() => {
        if(!branchesState.loading && !isEmpty(branchesState.response)){
            // console.log(branchesState.response)
            setPageLoading(ps => {
                return {
                    ...ps,
                    branch : false
                }
            })
        }
        if(!branchesState.loading && !isEmpty(branchesState.error)){
            console.log(branchesState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    branch : false
                }
            })
            if(branchesState.error?.data){
                toast.error(branchesState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[branchesState])

    useEffect(() => {
        if(!departmentsState.loading && !isEmpty(departmentsState.response)){
            // console.log(departmentsState.response)
            setPageLoading(ps => {
                return {
                    ...ps,
                    department : false
                }
            })
        }
        if(!departmentsState.loading && !isEmpty(departmentsState.error)){
            console.log(departmentsState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    department : false
                }
            })
            if(departmentsState.error?.data){
                toast.error(departmentsState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[departmentsState])

    useEffect(() => {
        if(!memberDetailState.loading && !isEmpty(memberDetailState.response)){
            console.log("member detail state",memberDetailState.response)
            setPageLoading( ps => {
                return {
                    ...ps,
                    memberDetail : false
                }
            })
        }

        if(!memberDetailState.loading && !isEmpty(memberDetailState.error)){
            console.log(memberDetailState.error)
            setPageLoading( ps => {
                return {
                    ...ps,
                    memberDetail : false
                }
            })
        }
    },[memberDetailState])

    const getForm = () => {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema ={validationSchema}
                >
                {props => {
                    const { touched, errors, values, handleChange, handleBlur, setFieldValue } = props
                    return (
                        <>
                            <Form>
                                <div className="row pt-3">
                                    <div className="col-md-6 mb-3">
                                        <InputBox type="text" 
                                            label="First Name"
                                            placeholder="Enter first name"
                                            name="person_name.first_name"
                                            value={values.person_name.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.person_name && touched.person_name.first_name && errors.person_name && errors.person_name.first_name ? errors.person_name.first_name : ""}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <InputBox type="text" 
                                            label="Last Name"
                                            placeholder="Enter last name"
                                            name="person_name.last_name"
                                            value={values.person_name.last_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.person_name && touched.person_name.last_name && errors.person_name && errors.person_name.last_name ? errors.person_name.last_name : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label className="lato-fs13-normal-lh18 tertiary-text-color-v2 mb-2">Gender</label>
                                        <>
                                            <div className='d-flex' role="group" aria-labelledby="my-radio-group">
                                                <label className="d-flex align-items-center me-2 mb-0 lato-fs15-normal-lh20">
                                                    <Field className="me-1 my-1" type="radio" name="gender" value="M" />
                                                    Male
                                                </label>
                                                <label className="d-flex align-items-center me-2 mb-0 lato-fs15-normal-lh20">
                                                    <Field className="me-1 my-1" type="radio" name="gender" value="F" />
                                                    Female
                                                </label>
                                                <label className="d-flex align-items-center mb-0 lato-fs15-normal-lh20">
                                                    <Field className="me-1 my-1" type="radio" name="gender" value="O" />
                                                    Others
                                                </label>
                                            </div>
                                            {
                                                touched.gender && errors.gender ? 
                                                    <div className="ms-1">
                                                        <span className="error-message">{errors.gender}</span>
                                                    </div> 
                                                    : null
                                            }
                                        </>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <DatePicker type="date"
                                            name="dob"
                                            label="Date Of Birth"
                                            placeholder="Enter date of birth"
                                            value={values.dob}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.dob && errors.dob ? errors.dob : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <InputBox type="email"
                                            placeholder="Enter email id"
                                            name="email"
                                            label="Email Id"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.email && errors.email ? errors.email : ""}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <InputBox type="tel" 
                                            placeholder="Enter mobile number"
                                            label="Mobile Number"
                                            name="mobile_number"
                                            value={values.mobile_number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.mobile_number && errors.mobile_number ? errors.mobile_number : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-md-6 mb-3">
                                        <InputBox type="text" 
                                            placeholder="Enter job role"
                                            label="Job Role"
                                            name="job_role"
                                            value={values.job_role}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.job_role && errors.job_role ? errors.job_role : ""}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <SelectBoxValueById
                                            options={!isEmpty(getDepartment(departmentsState.response.active_departments)) ? getDepartment(departmentsState.response.active_departments) : []}
                                            placeholder="Select department"
                                            name="department"
                                            label="Department"
                                            value={values.department}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.department && errors.department ? errors.department : ""}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <SelectBoxValueById 
                                            name="branch"
                                            label="Location"
                                            options={!isEmpty(getBranch(branchesState.response.active_branches)) ? getBranch(branchesState.response.active_branches) : [] }
                                            value={values.branch}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMgs={ touched.branch && errors.branch ? errors.branch : ""}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <SelectBoxValueById
                                            label="Role"
                                            options={userRole}
                                            name="role" 
                                            value={values.role}
                                            onChange={handleChange} 
                                            className="select-box" 
                                            onBlur={handleBlur}
                                            defaultValue="user"
                                        />
                                        { 
                                            touched.role && errors.role ? 
                                                <div className="ms-1">
                                                    <span className="error-message">{errors.value}</span>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                {/* { 
                                    values.role === "user" ? 
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label className="lato-fs13-normal-lh18 tertiary-text-color-v2 mt-0 mb-2">Reviewer</label>
                                                <Autocomplete
                                                    multiple
                                                    options={searchedValue?.data || []}
                                                    name='assignees'
                                                    getOptionLabel={(suggestion) => suggestion.email.id}
                                                    renderOption={
                                                        (props, option) => {
                                                            return (
                                                                <div key={option._id} className="d-flex ps-2" {...props}>
                                                                    <Avatar className={classes.profileImg} src={option.profile_pic} />
                                                                    <div className="ms-2">
                                                                        { 
                                                                            !isEmpty(option.person_name) ?
                                                                                <p 
                                                                                    className="lato-fs15-normal-lh20 mb-0"
                                                                                >
                                                                                    {`${option.person_name.first_name}  ${option.person_name.last_name}`}
                                                                                </p> 
                                                                                : null

                                                                        }
                                                                        { 
                                                                            !isEmpty(option.email) ?
                                                                                <p className="lato-fs15-normal-lh20 mb-0" dangerouslySetInnerHTML={{__html: boldString(option.email.id, searchedValue.value)}} />
                                                                                : null
                                                                        }
                                                                        { 
                                                                            !isEmpty(option.user_subscriber_mapping) && !isEmpty(option.user_subscriber_mapping[0]) && option.user_subscriber_mapping[0].job_role ? 
                                                                            <p
                                                                                className="lato-fs12-normal-lh15 mb-0"
                                                                            >
                                                                                {option.user_subscriber_mapping[0].job_role}
                                                                            </p> 
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                    onInputChange={handleInputChange}
                                                    onChange={(event, value) => {
                                                        setFieldValue('assignees', value)
                                                    }}
                                                    freeSolo
                                                    renderInput={(params) => {
                                                        const inputProps = params.inputProps;
                                                        inputProps.autoComplete = "off";
                                                        return (
                                                            <CssTextField
                                                                {...params}
                                                                inputProps={inputProps}
                                                                sx={{marginTop: '0', marginBottom: '0'}}
                                                                name='assignees'
                                                                variant="outlined"
                                                                label="User Name"
                                                                placeholder="Enter user name"
                                                                margin="normal"
                                                                fullWidth
                                                            />
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </div> 
                                        : null 
                                } */}
                                {/* {
                                    !isEmpty(values.assignees) ? 
                                        <>
                                            <h4 className="fs-16 mt-3 text-left font-weight-bold">Review Settings </h4>
                                            <div>
                                                <h6 className="fs-16 color-grey mt-0">Objective</h6>
                                                <div>
                                                    <label className="me-3">
                                                        <Field type="checkbox" name="settings.objective.reviewer" className="me-1" />
                                                        Reviewer
                                                    </label>
                                                    <label>
                                                        <Field type="checkbox" name="settings.objective.super_reviewer" className="me-1"/>
                                                        Super Reviewer
                                                    </label>
                                                </div>
                                                <h6 className="fs-16 color-grey mt-0">Goal</h6>
                                                <div>
                                                    <label className="me-3">
                                                        <Field type="checkbox" name="settings.goal.reviewer" className="me-1" />
                                                        Reviewer
                                                    </label>
                                                    <label>
                                                        <Field type="checkbox" name="settings.goal.super_reviewer" className="me-1"/>
                                                        Super Reviewer
                                                    </label>
                                                </div>
                                                <h6 className="fs-16 color-grey mt-0">Metric</h6>
                                                <div>
                                                    <label className="me-3">
                                                        <Field type="checkbox" name="settings.metric.reviewer" className="me-1" />
                                                        Reviewer
                                                    </label>
                                                    <label>
                                                        <Field type="checkbox" name="settings.metric.super_reviewer" className="me-1"/>
                                                        Super Reviewer
                                                    </label>
                                                </div>
                                                <h6 className="fs-16 color-grey mt-0">Learning Area</h6>
                                                <div>
                                                    <label className="me-3">
                                                        <Field type="checkbox" name="settings.learning_area.reviewer" className="me-1" />
                                                        Reviewer
                                                    </label>
                                                    <label>
                                                        <Field type="checkbox" name="settings.learning_area.super_reviewer" className="me-1"/>
                                                        Super Reviewer
                                                    </label>
                                                </div>
                                            </div> 
                                        </> 
                                        : null
                                } */}
                                {
                                    action === 'create' ?
                                        <div className="d-flex align-items-center">
                                            <input 
                                                className='my-1'
                                                type="checkbox"
                                                name="invite"
                                                checked={inviteState}
                                                onChange={() => setInviteState(ps => !ps)}
                                            />
                                            <label className="ms-2 my-0 lato-fs15-normal-lh20">Invite</label>
                                        </div> : null
                                }
                                <div className="d-flex justify-content-center mt-3">
                                    <CustomizeButton variant="primary-color-button" type="submit" label={action === 'edit' ? "Update Member" : inviteState ? "Create And Invite Member" : "Create Member"}/>
                                </div>
                            </Form>
                        </>
                    )
                }}
            </Formik>
        )
    }

    return (
        <Container>
            {
                isDesktop ?
                    <>
                        <Navbar
                            open={open}
                            menu={menu}
                            handlePopoverClose={handleMenuClose}
                            handlePopoverOpen={handleMenuOpen} 
                            enableMore={true} 
                            enableLogout={true} 
                            enableProfileButton={true}
                            enableNotification={true}
                            notificationOnClick={onClickNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer variant="noBackground">
                                <SubMainContainer>
                                    {
                                        loadingPage()?
                                            <div className='pt-4'>
                                                <h4 className='lato-fs22-normal-lh32 font-bold mb-0'>{ action === 'edit' ? 'Update Member' :'Create Member'}</h4>
                                                {getForm()}
                                            </div>
                                            : null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    : <>
                        <Navbar 
                            variant="mobile"
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={onClickNotification}
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawerOpen}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        {
                            !pageLoading.branch && !pageLoading.department ?
                                <div className='container pt-4 pb-70 px-3'>
                                    <Header 
                                        handleBackArrowEvent={() => history.goBack()}
                                        heading={action === 'edit' ? 'Update member' : "Create Member"}
                                    />
                                    {getForm()}
                                </div>
                                : null
                        }
                        <BottomNavigation />
                    </>
            }
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        branchesState : state.branches,
        departmentsState : state.departments,
        memberDetailState : state.memberDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestBranch : () => dispatch(fetchBranches()),
        requestDepartment : () => dispatch(fetchDepartments()),
        requestMemberDetail : (userId) => dispatch(fetchMemberDetail(userId))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateOrEditUser)