import { REQUEST_FETCH_GOALS,
         FETCH_GOALS_SUCCESS,
         FETCH_GOALS_FAILURE,
         GOAL_ACTION_CHANGES,
         CLEAR_FETCH_GOALS } from './FetchGoalsTypes'

export const requestFetchGoals = () => {
    return{
        type : REQUEST_FETCH_GOALS,
    }
}

export const successFetchGoals = (resData) => {
    return {
        type : FETCH_GOALS_SUCCESS,
        payload: resData
    }
}

export const failureFetchGoals = (error) => {
    return {
        type : FETCH_GOALS_FAILURE,
        payload: error
    }
}

export const goalActionChanges = () => {
    return {
        type : GOAL_ACTION_CHANGES
    }
}

export const clearFetchGoals = () => {
    return {
        type : CLEAR_FETCH_GOALS
    }
}