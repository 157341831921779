import { REQUEST_CREATE_GOALS, 
         CREATE_GOAL_SUCCESS,
         CREATE_GOAL_FAILURE,
         CLEAR_GOALS_STATES } from './GoalsType'

export const requestCreateGoals = () => {
    return {
        type : REQUEST_CREATE_GOALS
    }
}

export const createGoalSuccess = (responseData) => {
    return {
        type : CREATE_GOAL_SUCCESS,
        payload : responseData
    }
}

export const createGoalFailure = (error) => {
    return {
        type : CREATE_GOAL_FAILURE,
        payload : error
    }
}

export const clearGoalsStates = () => {
    return {
        type : CLEAR_GOALS_STATES
    }
}