import { goalSelectFailure, goalSelectSuccess, requestGoalSelect } from '../redux/goal-select/goalSelect.action'
import { requestCreateGoals, 
        createGoalSuccess, 
        createGoalFailure } from '../redux/goals/GoalsAction'
import { requestSpecificGoals, specificGoalFailure, specificGoalSuccess } from '../redux/specific-goal/specificGoal.action'
import ProtectedWebClient from '../utils/protected-web-client'

export const createGoals = (goalsDetails) => {
    return async (dispatch) => {
        try {
            dispatch(requestCreateGoals())
            const response = await ProtectedWebClient.post("/goal/create", goalsDetails)
            dispatch(createGoalSuccess(response.data))
        }catch(error){
            dispatch(createGoalFailure(error.response))
        }
    }
}

export const editGoal = (goalId, goalData) => {
    return async (dispatch) => {
        try {
            dispatch(requestCreateGoals())
            const response = await ProtectedWebClient.put(`/goal/${goalId}/edit`, goalData )
            dispatch(createGoalSuccess(response.data))
        } catch (error) {
            dispatch(createGoalFailure(error.response))
        }
    }
}

export const deleteGoal = (goalId) => {
    return async (dispatch) => {
        try {
            dispatch(requestCreateGoals())
            const response = await ProtectedWebClient.delete(`/goal/${goalId}/delete`)
            dispatch(createGoalSuccess(response.data))
        } catch (error) {
            dispatch(createGoalFailure(error.response))
        }
    }
}

export const specificGoal = (goalId) => {
    return async (dispatch) => {
        try {
            dispatch(requestSpecificGoals())
            const response = await ProtectedWebClient.get(`/goal/${goalId}/read`)
            dispatch(specificGoalSuccess(response.data))
        } catch (error) {
            dispatch(specificGoalFailure(error.response))
        }
    }
}

export const goalSelect = () => {
    return async (dispatch) => {
        try {
            dispatch(requestGoalSelect())
            const response = await ProtectedWebClient.get(`/goal?option=select`)
            dispatch(goalSelectSuccess(response.data))
        }catch(error){
            dispatch(goalSelectFailure(error.response))
        }
    }
}

export const goalDateValidation = (type,date,objectiveId,goalId) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.get( goalId ? `/goal/validate-date?type=${type}&date=${date}&objective=${objectiveId}&goal=${goalId}` :
            `/goal/validate-date?type=${type}&date=${date}&objective=${objectiveId}`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
            console.log(err)
        })
     })
}

export const goalDeletePromise = (goalId) => {
    return new Promise((resolve, reject) => {
        ProtectedWebClient.delete(`/goal/${goalId}/delete`)
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
            console.log(err)
        })
     })
}