import React  from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import DeleteIcon from '@mui/icons-material/Delete'

import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import './reference-card.css'

const ReferenceCard = (props) => {

    return (
        <>
            <Card
                className={ props.className ? `mt-3 ${props.className}` : "mt-3"}
                onClick={props.onClick}
            >
                <CardContent 
                    sx={{
                        padding:"1rem 1.25rem",
                        display: "flex",
                        flexDirection:"row",
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '&:last-child':{
                            paddingBottom: '1rem'
                        }
                    }}
                >
                    <div className='d-flex' style={{overflow:"hidden"}} >
                        <Avatar 
                            sx={{width: '50px', height: '50px'}}
                            src={props.preview || iconPlaceholder}
                        />
                        <div className="ms-3" style={{overflow:"hidden"}}>
                            <h2 className="lato-fs24-normal-lh32 mb-0" dangerouslySetInnerHTML={{__html: props.subject}} />
                            <div className="d-flex flex-column min-width-zero">
                                <a className="lato-fs18-normal-lh22 mb-0 pb-1 one-line-wrap filename" href={props.link} target="_blank" dangerouslySetInnerHTML={{__html: props.filename}} />
                                <span className="lato-fs15-normal-lh20">{props.createdDate}</span>
                            </div>
                        </div>
                    </div>
                    <div className="ms-2">
                        <IconButton onClick={props.onReferenceDeleteClick} >
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                </CardContent>
            </Card>
        </>
    )

    // switch(ownProps.variant){
    //     case "desktop-view" :
    //         return (
    //             <>
    //                 <div className="card notification-reference-card justify-content-start mb-2">
    //                     <div className="d-flex align-items-center">
    //                         <img className="desktop-obj-icon file-preview" src={ownProps.preview ? ownProps.preview : iconPlaceholder} alt="img" />
    //                         <div className="d-flex flex-column flex-fill min-width-zero ps-2">
    //                             <a className="lato-fs18-normal-lh22 mb-0 pb-1 one-line-wrap filename" href={ownProps.link} target="_blank">{ownProps.filename}</a>
    //                             <span className="lato-fs15-normal-lh20">{ownProps.createdDate}</span>
    //                         </div>
    //                         <div className="ml-auto mr-2">
    //                             <FontAwesomeIcon className="ml-2 secondary-color" icon={faTrash} onClick={ownProps.onReferenceDeleteClick}/>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </>
    //         )

    //     default : 
    //         return (
    //             <>
    //                 <div className="card notification-reference-card justify-content-start mb-2" key={props.key}>
    //                     <div className="d-flex align-items-center">
    //                         <img className="sub-obj-icon file-preview" src={ownProps.preview ? ownProps.preview : iconPlaceholder} alt="img" />
    //                         <div className="d-flex flex-column flex-fill min-width-zero ps-2">
    //                             <a className="lato-fs15-normal-lh20 mb-0 pb-1 one-line-wrap filename" href={ownProps.link} target="_blank">{ownProps.filename}</a>
    //                             <span className="lato-fs12-normal-lh15">{ownProps.createdDate}</span>
    //                         </div>
    //                         <div className="ml-auto mr-2">
    //                             <FontAwesomeIcon className="ml-2 secondary-color" icon={faTrash} onClick={ownProps.onReferenceDeleteClick}/>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </>
    //         )
    // }

    
}

// const mapStateToProps = ( state,ownProps ) => {
//     return {
//         state : state,
//         ownProps : ownProps
//     }
// }

export default ReferenceCard
