import React, { useEffect, useState } from 'react'
import { Tree, TreeNode } from 'react-organizational-chart'
import { isEmpty, toArray, size, unset } from 'lodash'
import { connect } from 'react-redux'
import qs from 'query-string'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import moment from 'moment'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import { specificObjectives } from '../../../services/objectives.service'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import ObjectivePathCard from '../../../components/objective-path-card/ObjectivePathCard'
import ParentObjectiveCard from '../../../components/parent-obj-card/ParentObjCard'
import ChildObjCard from '../../../components/child-obj-card/ChildObjCard'
import ChoosePathPopup from '../../../components/choose-path-popup/ChoosePathPopup'
import SwapObjPopup from '../../../components/swap-obj-popup/SwapObjPopup'
import ModifyObjectiveEditForm from '../../../components/modify-objective-edit-form/ModifyObjectiveEditForm'
import AddToObjPopup from '../../../components/add-to-obj-popup/AddToObjPopup'
import { modifyAction } from '../../../services/notification.service'
import { fetchSpecificObjectivePromise } from '../../../services/fetchObjectives.service'
import ObjectiveCard from '../../../components/objective-card/ObjectiveCard'
import './modify-objective.css'
import { v4 as uuidv4 } from 'uuid'
import {
    getObjById, 
    objectiveValidationSchema
} from './utils'
import {
    clearModifyObjectiveStates,
    modifyObjectiveSuccess,
    removeObjectiveWallpaper,
    removeObjectiveReferenceFile,
    removeObjectiveIcon,
    removeOneValidateObj,
    updateManyValidateObjs
} from '../../../redux/notification/modify-objective/ModifyObjective.action'
import { objectiveActionChanges } from '../../../redux/fetch-objectives/FetchObjectivesAction'
import { mailActionChanges } from '../../../redux/notification/notification-detail/notificationDetail.action'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../../components/desktop-layout/description/Description'
import ReferenceFiles from '../../../components/reference-files/ReferenceFiles'
import ProcessWallpaperIcon from '../../../components/desktop-layout/process-wallpaper-icon/ProcessWallpaperIcon'
import Header from '../../../common/header/Header'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import Name from '../../../components/name/Name'

const OBJECTIVE_LEVEL_KEY_MAPPING = {
    0 : 'level_zero',
    1 : 'level_one',
    2 : 'level_two',
    3 : 'level_three'
}

const MODIFY_OBJECTIVE_ATTACHMENT_NAMES ={
    data : "action_data",
    reference : "action_reference",
    wallpaper : "action_wallpaper",
    icon : "action_icon",
}

const ModifyObjective = (props) => {

    const { 
        modifyObjectiveState, 
        fetchObjective,
        clearObjectiveStates,
        saveModifiedObjective,
        removeReferenceFiles,
        removeWallpaperFile,
        removeOneValidateObj,
        saveManyValidateObjs,
        removeIconFile,
        objectiveActionChanges,
        notificationStatusChanges
    } = props

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ choosePath, setChoosePath ] = useState(false)
    const [ swapObjPopup, setSwapObjPopup ] = useState(false)
    const [ addToObjPopup, setAddToObjPopup ] = useState(false)
    const [ rootObj, setRootObj ] = useState({})
    const [ editObjective, setEditObjective ] = useState(false)
    const [ activeObjectiveId, setActiveObjectiveId ] = useState("")
    const [ parentObjIdOfActiveObj, setParentObjIdOfActiveObj ] = useState("")
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)
    const [ buttonLoading, setButtonLoading] = useState(false)

    // path objectives
    const [ pathObj, setPathObj ] = useState({})

    const location = useLocation()
    const history = useHistory()
    const params = useParams()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const queryParams = qs.parse(location.search)

    const objective = modifyObjectiveState.response.objective

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    useEffect(() => {
        if(isEmpty(modifyObjectiveState.response)){
            fetchObjective(queryParams.objectiveId)
        }
        return () => {
            clearObjectiveStates()
        }
    }, [])

    useEffect(() => {
        if(!modifyObjectiveState.loading && !isEmpty(modifyObjectiveState.response)){

            if(!activeObjectiveId) {
                if(objective.level > 0){
                    // copy of objective
                    const objectivePathCopy = JSON.parse(JSON.stringify(objective.path))

                    // removing last level in the path
                    unset(objectivePathCopy, OBJECTIVE_LEVEL_KEY_MAPPING[objective.level-1])

                    // fetch path of objective shared
                    fetchSpecificObjectivePromise({path: objectivePathCopy, level: objective.level})
                        .then(res => {
                            if(res.success){
                                setPathObj(res.data)
                            } else {
                                handleRemovePathTrigger()
                            }
                            setActiveObjectiveId(objective._id)
                            setPageLoading(false)
                        }).catch(error => {
                            console.log(error)
                        })
                } else {
                    setActiveObjectiveId(objective._id)
                    setPageLoading(false)
                }
                
            } else {
                setPageLoading(false)
            }
        }

        if(!modifyObjectiveState.loading && !isEmpty(modifyObjectiveState.error)){
            if(modifyObjectiveState.error){
                if(modifyObjectiveState.error.data){
                    toast.error(modifyObjectiveState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
        
    }, [modifyObjectiveState])

    function toggleChoosePathPopup() {
        setChoosePath(!choosePath)
    }

    function toggleSwapObjPopup() {
        setSwapObjPopup(!swapObjPopup)
    }

    function toggleAddToObjPopup() {
        setAddToObjPopup(!addToObjPopup)
    }

    function handleChoosePathTrigger() {
        toggleChoosePathPopup()
    }

    function handleRemovePathTrigger() {
        saveModifiedObjective({objective : formatedRemovedPathObjective(objective, toArray(objective.path))})
    }

    function handlePathChange(obj) {
        saveModifiedObjective({objective : formatNewPathAddition(objective, toArray(obj.path))})
        setPathObj(obj)
    }

    function formatNewPathAddition(objective, newPath){
        const objectiveCopy = { ...objective }
        function func(obj) {
            let existingPathArr = toArray(obj.path)
            const updatedPathArr = newPath.concat(existingPathArr)
            obj.path = updatedPathArr.reduce(function(acc, cur, i) {
                acc[OBJECTIVE_LEVEL_KEY_MAPPING[i]] = cur;
                return acc;
            }, {});

            obj.level = size(toArray(obj.path)) - 1

            if (isEmpty(obj.sub_objectives)) {
                return
            }
    
            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }

        func(objectiveCopy)
        return objectiveCopy
    }

    // validation while submitting
    async function validateObjective(){
        const array = []
        async function func(obj, parentObj) {
            try {
                await objectiveValidationSchema(obj, parentObj).validate(obj)
                array.push({
                    id: obj._id,
                    hasErrors : false
                })
            } catch(err) {
                array.push({
                    id: obj._id,
                    hasErrors : true
                })
            }

            if (isEmpty(obj.sub_objectives)) {
                return
            }

            for(const subObjective of obj.sub_objectives){
                await func(subObjective, obj)
            }
        }
        await func(objective, objective.level > 0 ? pathObj: {})
        return array
    }

    function getMaxLevel() {
        let maxLevel = 0
        function func(obj) {
            
            if(maxLevel < obj.level){
                maxLevel = obj.level
            }

            if (isEmpty(obj.sub_objectives)) {
                return
            }

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }
        func(objective)
        return maxLevel
    }

    function formatedRemovedPathObjective(objective, objPathArray) {
        let objectiveCopy = {...objective}
        function func(obj) {
            let existingObjPathArr = toArray(obj.path)
            existingObjPathArr.splice(0, size(objPathArray)-1)
            obj.path = existingObjPathArr.reduce(function(acc, cur, i) {
                acc[OBJECTIVE_LEVEL_KEY_MAPPING[i]] = cur;
                return acc;
            }, {});

            obj.level = size(existingObjPathArr) - 1

            if (isEmpty(obj.sub_objectives)) {
                return
            }

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }
        func(objectiveCopy)
        return objectiveCopy
    }

    function handleObjSwap(obj){
        saveModifiedObjective({objective : swapObjective(objective, rootObj, obj)})
    }

    function handleDeleteObjTrigger(obj){
        if(activeObjectiveId._id === obj._id){
            setActiveObjectiveId(objective)
        }
        if(obj.new_wallpaper){
            removeWallpaperFile(obj._id)
        }
        if(obj.new_icon) {
            removeIconFile(obj._id)
        }
        if(!isEmpty(obj.new_reference_files)){
            removeReferenceFiles(obj._id)
        }
        saveModifiedObjective({objective : deleteObjective(objective, obj)})
        removeOneValidateObj(obj._id)
    }

    function deleteObjective(objective, delObjNode){
        let objectiveCopy = JSON.parse(JSON.stringify(objective))

        function func(obj) {
            if(!isEmpty(obj.sub_objectives)){
                obj.sub_objectives = obj.sub_objectives.filter((sub_objective) => !((sub_objective.name === delObjNode.name) && (sub_objective.level === delObjNode.level)))
            }

            if (isEmpty(obj.sub_objectives)) {
                return
            }

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }

        func(objectiveCopy)
        return objectiveCopy
    }

    function handleAddToObjective(obj) {
        
        let formattedObj = formatAddToObj(obj)
        let detachedObjective = detachObjective(objective)

        saveModifiedObjective({objective : patchObjective(detachedObjective, formattedObj, obj)})
        
    }

    function patchObjective(objTree, targetObj, baseObj){

        function func(obj){

            if(obj._id === baseObj._id){
                if(obj.sub_objectives){
                    obj.sub_objectives.push(targetObj)
                } else {
                    obj.sub_objectives = [targetObj]
                }
            }

            if(isEmpty(obj.sub_objectives)){
                return
            }

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }

        func(objTree)

        return objTree
    }

    function detachObjective(objective){

        let objectiveCopy = JSON.parse(JSON.stringify(objective))

        function func(obj){
            if(isEmpty(obj.sub_objectives)) {
                return
            }

            obj.sub_objectives = obj.sub_objectives.filter(subObj => subObj._id !== rootObj._id)

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }
        func(objectiveCopy)
        return objectiveCopy
    }

    function formatAddToObj(objective){
        let rootObjCopy = JSON.parse(JSON.stringify(rootObj))
        let objectiveLevel = objective.level
        function func(obj){
            if(obj._id === rootObjCopy._id){
                obj.level = objectiveLevel + 1
            } else {
                let parentLevel = getParentLevel(rootObjCopy, obj._id)
                obj.level = parentLevel + 1
            }
            if(isEmpty(obj.sub_objectives)) {
                return
            }

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }
        func(rootObjCopy)
        return rootObjCopy
    }

    function getParentLevel(objective, targetObjectiveId){

        let level = undefined;

        function func(obj){
            if(isEmpty(obj.sub_objectives) || level) {
                return
            }

            obj.sub_objectives.some(subObj => {
                if(subObj._id === targetObjectiveId){
                    level = obj.level
                    return true
                }
            })

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }

        func(objective)

        return level
    }

    function swapObjective(objective, swapFrom, swapTo){
        let objectiveCopy = JSON.parse(JSON.stringify(objective))

        function func(obj) {
            if((obj.name === swapTo.name) && (obj.level === swapTo.level)){

                obj.status = swapFrom.status
                obj.name = swapFrom.name
                obj._id = swapFrom._id
                obj.start_date = swapFrom.start_date
                obj.end_date = swapFrom.end_date

                if(swapFrom.new_wallpaper){
                    obj.new_wallpaper = swapFrom.new_wallpaper
                } else if(obj.new_wallpaper) {
                    delete obj.new_wallpaper
                }

                if(swapFrom.wallpaper){
                    obj.wallpaper = swapFrom.wallpaper
                } else if(obj.wallpaper){
                    delete obj.wallpaper
                }

                if(swapFrom.new_icon){
                    obj.new_icon = swapFrom.new_icon
                } else if(obj.new_icon) {
                    delete obj.new_icon
                }

                if(swapFrom.icon){
                    obj.icon = swapFrom.icon
                } else if(obj.icon) {
                    delete obj.icon
                }

                if(swapFrom.description){
                    obj.description = swapFrom.description
                } else if(obj.description) {
                    delete obj.description
                }

                if(!isEmpty(swapFrom.reference_files)){
                    obj.reference_files = swapFrom.reference_files
                } else if(!isEmpty(obj.reference_files)){
                    delete obj.reference_files
                }

                if(!isEmpty(swapFrom.new_reference_files)){
                    obj.new_reference_files = swapFrom.new_reference_files
                } else if(!isEmpty(obj.new_reference_files)){
                    delete obj.new_reference_files
                }

                if(!isEmpty(swapFrom.reference_links)){
                    obj.reference_links = swapFrom.reference_links
                } else if(!isEmpty(obj.reference_links)){
                    delete obj.reference_links
                }

                if(!isEmpty(swapFrom.new_reference_links)){
                    obj.new_reference_links = swapFrom.new_reference_links
                } else if(!isEmpty(obj.new_reference_links)){
                    delete obj.new_reference_links
                }

                // path considered only for top level objective
                if(objective.name === obj.name){
                    const existingPathArr = toArray(obj.path)
                    existingPathArr.pop()

                    const targetPath = {name : obj.name, _id: obj._id, level: obj.level}

                    const updatedPathArr = existingPathArr.concat([targetPath])
                    
                    obj.path = updatedPathArr.reduce(function(acc, cur, i) {
                        acc[OBJECTIVE_LEVEL_KEY_MAPPING[i]] = cur;
                        return acc;
                    }, {});
                }

            } else if((obj.name === swapFrom.name) && (obj.level === swapFrom.level)){

                obj.status = swapTo.status
                obj.name = swapTo.name
                obj._id = swapTo._id
                obj.start_date = swapTo.start_date
                obj.end_date = swapTo.end_date

                if(swapTo.new_wallpaper){
                    obj.new_wallpaper = swapTo.new_wallpaper
                } else if(obj.new_wallpaper) {
                    delete obj.new_wallpaper
                }

                if(swapTo.wallpaper){
                    obj.wallpaper = swapTo.wallpaper
                } else if(obj.wallpaper){
                    delete obj.wallpaper
                }

                if(swapTo.icon){
                    obj.icon = swapTo.icon
                } else if(obj.icon) {
                    delete obj.icon
                }

                if(swapTo.new_icon){
                    obj.new_icon = swapTo.new_icon
                } else if(obj.new_icon) {
                    delete obj.new_icon
                }

                if(swapTo.description){
                    obj.description = swapTo.description
                } else if(obj.description) {
                    delete obj.description
                }

                if(!isEmpty(swapTo.reference_files)){
                    obj.reference_files = swapTo.reference_files
                } else if(!isEmpty(obj.reference_files)){
                    delete obj.reference_files
                }

                if(!isEmpty(swapTo.new_reference_files)){
                    obj.new_reference_files = swapTo.new_reference_files
                } else if(!isEmpty(obj.new_reference_files)){
                    delete obj.new_reference_files
                }

                if(!isEmpty(swapTo.reference_links)){
                    obj.reference_links = swapTo.reference_links
                } else if(!isEmpty(obj.reference_links)){
                    delete obj.reference_links
                }

                if(!isEmpty(swapTo.new_reference_links)){
                    obj.new_reference_links = swapTo.new_reference_links
                } else if(!isEmpty(obj.new_reference_links)){
                    delete obj.new_reference_links
                }

            }

            if (isEmpty(obj.sub_objectives)) {
                return
            }

            obj.sub_objectives.forEach(sub_objective => func(sub_objective))
        }
        func(objectiveCopy)
        return objectiveCopy
    }

    function handleOnObjectiveClicked(objectiveId, parentObjId){
        if(activeObjectiveId !== objectiveId){
            setParentObjIdOfActiveObj(parentObjId)
            setActiveObjectiveId(objectiveId)
        }
        if(editObjective){
            setEditObjective(false)
        }
    }

    function objectiveTree(){
        if(objective.level === 0){
            return (
                <Tree label={<ParentObjectiveCard 
                                variant={isDesktop ? "desktop-view" : ""}
                                // wallPaper={objective.wallpaper}
                                name={objective.name} 
                                disableChoosePath={getMaxLevel()>2}
                                disableRemovePath={true}
                                hasErrors={checkForError(objective._id)}
                                active={objective._id === activeObjectiveId}
                                onChoosePathClick={handleChoosePathTrigger} 
                                onRemovePathClick={handleRemovePathTrigger}
                                onClick={() => handleOnObjectiveClicked(objective._id, "")} />} >
                    {objective.sub_objectives.map((subObjective,index) => (
                        <TreeNode label={<ChildObjCard 
                                            onSwapClick={() => {
                                                setRootObj(subObjective)
                                                toggleSwapObjPopup()
                                            }}
                                            onAddToClick={() => {
                                                setRootObj(subObjective)
                                                toggleAddToObjPopup()
                                            }}
                                            key={subObjective._id}
                                            variant={isDesktop ? "desktop-view" : ""}
                                            hasErrors={checkForError(subObjective._id)}
                                            onClick={() => handleOnObjectiveClicked(subObjective._id, objective._id)}
                                            onDeleteClick={() => handleDeleteObjTrigger(subObjective)}
                                            active={subObjective._id === activeObjectiveId}
                                            // wallPaper={subObjective.wallPaper ? subObjective.wallPaper : ""}
                                            name={subObjective.name} />} >
                            {!isEmpty(subObjective.sub_objectives) ?
                                subObjective.sub_objectives.map((subSubObjective,index) =>(
                                    <TreeNode label={<ChildObjCard 
                                                        key={subSubObjective._id} 
                                                        variant={isDesktop ? "desktop-view" : ""}
                                                        onSwapClick={() => {
                                                            setRootObj(subSubObjective)
                                                            toggleSwapObjPopup()
                                                        }}
                                                        onAddToClick={() => {
                                                            setRootObj(subSubObjective)
                                                            toggleAddToObjPopup()
                                                        }}
                                                        hasErrors={checkForError(subSubObjective._id)}
                                                        onClick={() => handleOnObjectiveClicked(subSubObjective._id, subObjective._id)}
                                                        active={subSubObjective._id === activeObjectiveId}
                                                        onDeleteClick={() => handleDeleteObjTrigger(subSubObjective)}
                                                        // wallPaper={subSubObjective.wallpaper}
                                                        name={subSubObjective.name} />}>
                                        {!isEmpty(subSubObjective.sub_objectives) ?
                                            subSubObjective.sub_objectives.map((subSubSubObjective, index) => (
                                                <TreeNode label={<ChildObjCard 
                                                                    variant={isDesktop ? "desktop-view" : ""}
                                                                    key={subSubSubObjective._id} 
                                                                    onSwapClick={() => {
                                                                        setRootObj(subSubSubObjective)
                                                                        toggleSwapObjPopup()
                                                                    }}
                                                                    onAddToClick={() => {
                                                                        setRootObj(subSubSubObjective)
                                                                        toggleAddToObjPopup()
                                                                    }}
                                                                    hasErrors={checkForError(subSubSubObjective._id)}
                                                                    onClick={() => handleOnObjectiveClicked(subSubSubObjective._id, subSubObjective._id)}
                                                                    active={subSubSubObjective._id === activeObjectiveId}
                                                                    onDeleteClick={() => handleDeleteObjTrigger(subSubSubObjective)}
                                                                    // wallPaper={subSubSubObjective.wallPaper}
                                                                    name={subSubSubObjective.name} />} />
                                            ))
                                        : null}
                                    </TreeNode>
                                ))
                                : null }
                        </TreeNode>
                    ))}
                </Tree>
            )
        } else {

            const objectivePath = {...objective.path}
            delete objectivePath[OBJECTIVE_LEVEL_KEY_MAPPING[objective.level]]

            const objectiveBase = <TreeNode label={<ParentObjectiveCard 
                                                        name={objective.name} 
                                                        disableChoosePath={true}
                                                        variant={isDesktop ? "desktop-view" : ""}
                                                        disableRemovePath={false}
                                                        // wallPaper={objective.wallpaper}
                                                        hasErrors={checkForError(objective._id)}
                                                        active={objective._id === activeObjectiveId}
                                                        onClick={() => handleOnObjectiveClicked(objective._id, "")}
                                                        onChoosePathClick={handleChoosePathTrigger} 
                                                        onRemovePathClick={handleRemovePathTrigger} />} >
                                    {objective.sub_objectives.map((subObjective,index) => (
                                        <TreeNode label={<ChildObjCard 
                                                            key={subObjective._id} 
                                                            variant={isDesktop ? "desktop-view" : ""}
                                                            onSwapClick={() => {
                                                                setRootObj(subObjective)
                                                                toggleSwapObjPopup()
                                                            }}
                                                            onAddToClick={() => {
                                                                setRootObj(subObjective)
                                                                toggleAddToObjPopup()
                                                            }}
                                                            onClick={() => handleOnObjectiveClicked(subObjective._id, objective._id)}
                                                            // wallPaper={subObjective.wallpaper}
                                                            hasErrors={checkForError(subObjective._id)}
                                                            active={subObjective._id === activeObjectiveId}
                                                            onDeleteClick={() => handleDeleteObjTrigger(subObjective)}
                                                            name={subObjective.name} />} >
                                            {!isEmpty(subObjective.sub_objectives) ?
                                                subObjective.sub_objectives.map((subSubObjective, index) =>(
                                                    <TreeNode label={<ChildObjCard 
                                                                        variant={isDesktop ? "desktop-view" : ""}
                                                                        onSwapClick={() => {
                                                                            setRootObj(subSubObjective)
                                                                            toggleSwapObjPopup()
                                                                        }}
                                                                        onAddToClick={() => {
                                                                            setRootObj(subSubObjective)
                                                                            toggleAddToObjPopup()
                                                                        }}
                                                                        key={subSubObjective._id}
                                                                        onClick={() => handleOnObjectiveClicked(subSubObjective._id, subObjective._id)}
                                                                        active={subSubObjective._id === activeObjectiveId}
                                                                        hasErrors={checkForError(subSubObjective._id)}
                                                                        onDeleteClick={() => handleDeleteObjTrigger(subSubObjective)}
                                                                        // wallPaper={subSubObjective.wallpaper}
                                                                        name={subSubObjective.name}/>}>
                                                        {!isEmpty(subSubObjective.sub_objectives) ?
                                                            subSubObjective.sub_objectives.map((subSubSubObjective, index) => (
                                                                <TreeNode label={<ChildObjCard 
                                                                                    variant={isDesktop ? "desktop-view" : ""}
                                                                                    key={subSubSubObjective._id} 
                                                                                    onSwapClick={() => {
                                                                                        setRootObj(subSubSubObjective)
                                                                                        toggleSwapObjPopup()
                                                                                    }}
                                                                                    onAddToClick={() => {
                                                                                        setRootObj(subSubSubObjective)
                                                                                        toggleAddToObjPopup()
                                                                                    }}
                                                                                    onClick={() => handleOnObjectiveClicked(subSubSubObjective._id, subSubObjective._id)}
                                                                                    active={subSubSubObjective._id === activeObjectiveId}
                                                                                    onDeleteClick={() => handleDeleteObjTrigger(subSubSubObjective)}
                                                                                    // wallPaper={subSubSubObjective.wallpaper}
                                                                                    hasErrors={checkForError(subSubSubObjective._id)}
                                                                                    name={subSubSubObjective.name} />} />
                                                            ))
                                                        : null}
                                                    </TreeNode>
                                                ))
                                                : null }
                                        </TreeNode>
                                    ))}
                                </TreeNode>

            return (
                <Tree label={<ObjectivePathCard name={objectivePath.level_zero.name} />}>
                    {
                        objectivePath.level_one ? 
                            <TreeNode label={<ObjectivePathCard name={objectivePath.level_one.name} />}>
                                {
                                    objectivePath.level_two ? 
                                        <TreeNode label={<ObjectivePathCard name={objectivePath.level_two.name} />}>
                                            {objectiveBase}
                                        </TreeNode> : objectiveBase
                                }
                            </TreeNode> : objectiveBase
                    }
                </Tree>
            )
        }
    }

    function onClickEditIconTrigger(){
        setEditObjective(!editObjective)
    }

    function addFormData(obj){

        let objectiveFormData = new FormData()

        objectiveFormData.append(MODIFY_OBJECTIVE_ATTACHMENT_NAMES.data, JSON.stringify(obj))
        if(!isEmpty(modifyObjectiveState.wallpapers)){
            for(const wallpaper of modifyObjectiveState.wallpapers){
                if(wallpaper.file){
                    objectiveFormData.append(MODIFY_OBJECTIVE_ATTACHMENT_NAMES.wallpaper, wallpaper.file)
                }
            }
        }

        if(!isEmpty(modifyObjectiveState.icons)){
            for(const icon of modifyObjectiveState.icons){
                if(icon.file){
                    objectiveFormData.append(MODIFY_OBJECTIVE_ATTACHMENT_NAMES.icon, icon.file)
                }
            }
        }

        if(!isEmpty(modifyObjectiveState.referenceFiles)){
            for(const referenceFile of modifyObjectiveState.referenceFiles){
                if(!isEmpty(referenceFile.files)){
                    for(const file of referenceFile.files){
                        objectiveFormData.append(MODIFY_OBJECTIVE_ATTACHMENT_NAMES.reference, file)
                    }
                }
            }
        }
        return objectiveFormData
    }

    async function changeToISODateString(){
        const objectiveCopy = JSON.parse(JSON.stringify(objective))
        function func(obj) {
            return new Promise((resolve, reject) => {
                obj.start_date = (moment(obj.start_date).startOf('day')).toISOString()
                obj.end_date = (moment(obj.end_date).endOf('day')).toISOString()

                if (isEmpty(obj.sub_objectives)) {
                    resolve()
                }

                for(const subObjective of obj.sub_objectives){
                    resolve(func(subObjective))
                }
            })
        }

        await func(objectiveCopy)

        return objectiveCopy
    }

    async function handleModifyObjective(){
        if(navigator.onLine){
            const validateResult = await validateObjective()
            if(validateResult.some(obj => obj.hasErrors)){
                saveManyValidateObjs(validateResult)
            } else {
                const formattedObj = await changeToISODateString()
                const payload = addFormData(formattedObj)
                setButtonLoading(true)
                modifyAction( queryParams.actionId, "objective", payload)
                .then((res) => {
                    if(res.data.message === "Action modified successfully"){
                        objectiveActionChanges()
                        notificationStatusChanges()
                        setButtonLoading(false)
                        toast.success(res.data.message , {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        history.push(`/notification/${params.notificationId}/inbox?action=objectives`)
                    }
                }).catch((error) => {
                    if(!isEmpty(error.response) && !isEmpty(error.response.data)){
                        setButtonLoading(false)
                        toast.error(error.response.data?.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                })
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function getActiveObjectiveReadModeDetails(){

        const activeObjective = getObjById(activeObjectiveId, objective)
        const newIcon = activeObjective.new_icon ? modifyObjectiveState.icons.find(icon => icon._id === activeObjective._id)?.preview_data : ""
        const newWallpaper = activeObjective.new_wallpaper ? modifyObjectiveState.wallpapers.find(wallpaper => wallpaper._id === activeObjective._id)?.preview_data : ""

        return (
            <div className="mt-3 px-2">
                <div>
                    <ProcessWallpaperIcon 
                        variant={ isDesktop ? "" : "mobile" }
                        wallpaper={activeObjective.new_wallpaper ? newWallpaper : activeObjective.wallpaper}
                        icon={activeObjective.new_icon ? newIcon : activeObjective.icon}
                    />
                </div>
                {
                    activeObjective.name ?
                        <div className='mt-3'>
                            <Name name={activeObjective.name} />
                        </div> 
                    : null
                }
                { activeObjective.description ? 
                    <div className='mt-2'>
                        <Description description={activeObjective.description} />
                    </div> : null }
                <div className='mt-5'>
                    <ReferenceFiles variant="modify-reference"
                        referenceFiles={activeObjective.reference_files} 
                        newReferenceFiles={activeObjective.new_reference_files}
                        referenceLinks={activeObjective.reference_links}
                        newReferenceLinks={activeObjective.new_reference_links} />
                </div>
                <div className="mt-2 d-flex justify-content-center align-items-center">
                    {/* <CustomizeButton variant="primary-color-button" handleClick={handleModifyObjective} label="Modify VCF" type="submit"/> */}
                    <CustomizeLoadingButton 
                        variant="accept-loading-button-v2"
                        loading={buttonLoading} 
                        label="Modify VCF"
                        onClick={handleModifyObjective} />
                </div>
            </div>
        )
    }

    function checkForError(id){
        if(!isEmpty(modifyObjectiveState.validationError)){
            const appropriateObj = modifyObjectiveState.validationError.find(obj => obj.id === id)
            if(!isEmpty(appropriateObj)){
                return appropriateObj.hasErrors
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function getDetail(){
        return (
            <>
                <div className={isDesktop ? "overflow-auto" : "scroll-container bg-tertiary-color-v2 py-3 border-radius-1rem"}>
                    <div className={isDesktop ? "d-flex w-100 mb-3 ps-2 pe-2" :"d-flex ml-10 mr-10 w-100"}>
                        {objectiveTree()}
                    </div>
                </div>
                <hr/>
                { !editObjective && <div className="d-flex justify-content-end align-items-center mt-2 me-2">
                    <FontAwesomeIcon className="secondary-color pt-cursor" icon={faPen} onClick={onClickEditIconTrigger}/>
                </div>}
                {!isEmpty(activeObjectiveId) ? 
                    <>
                    { !editObjective ? 
                        getActiveObjectiveReadModeDetails() :
                        <div className="mt-4">
                            <ModifyObjectiveEditForm
                                activeObjectiveId={activeObjectiveId}
                                activeObjective={getObjById(activeObjectiveId, objective)} 
                                parentObjOfActiveObj={objective._id === activeObjectiveId ? pathObj : getObjById(parentObjIdOfActiveObj, objective)}
                                onCancelBtnClick={onClickEditIconTrigger} />
                        </div>
                    } </> : null
                }
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableMore={true}
                            enableProfileButton={true}
                            enableLogout={true}
                            moreRight="83px"
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                                <ProcessMainContainer className="white-bg">
                                    <SubMainContainer className="mb-40">
                                    { !pageLoading && !isEmpty(objective) ?
                                        <>
                                            <div className='mt-3'>
                                                <h5 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>Modify VCF</h5>
                                            </div>
                                            <div className='mt-4'>
                                                {getDetail()}
                                            </div> 
                                        </> : null
                                    }
                                    </SubMainContainer>
                                </ProcessMainContainer> 
                        </ProcessSectionContainer>
                    </> :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        <div className='container pb-70'>
                            <div className='mt-3 mb-3'>
                                <Header heading="Modify VCF" handleBackArrowEvent={() => history.goBack()} />
                            </div>
                            { !pageLoading && !isEmpty(objective) ? 
                                <div>
                                    {getDetail()}
                                </div> : null 
                            }
                        </div>
                        <BottomNavigation />
                    </>
                }
            </Container>
            {choosePath ? <ChoosePathPopup handleClose={toggleChoosePathPopup} maxLevel={getMaxLevel} onProceed={handlePathChange} open={choosePath} /> :null}
            {swapObjPopup ? <SwapObjPopup handleClose={toggleSwapObjPopup} onSwap={handleObjSwap} objFor={rootObj} open={swapObjPopup}/> :null}
            {addToObjPopup ? <AddToObjPopup handleClose={toggleAddToObjPopup} onAddedTo={handleAddToObjective} objFor={rootObj} open={addToObjPopup} /> :null}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        modifyObjectiveState : state.modifyObjective
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchObjective : (objectiveId) => dispatch(specificObjectives(objectiveId, true)),
        clearObjectiveStates : () => dispatch(clearModifyObjectiveStates()),
        saveModifiedObjective : (objective) => dispatch(modifyObjectiveSuccess(objective)),
        removeReferenceFiles : (objectiveId) => dispatch(removeObjectiveReferenceFile(objectiveId)),
        removeWallpaperFile : (objectiveId) => dispatch(removeObjectiveWallpaper(objectiveId)),
        removeIconFile : (objectiveId) => dispatch(removeObjectiveIcon(objectiveId)),
        removeOneValidateObj : (id) => dispatch(removeOneValidateObj(id)),
        saveManyValidateObjs : (payload) => dispatch(updateManyValidateObjs(payload)),
        objectiveActionChanges : () => dispatch(objectiveActionChanges()),
        notificationStatusChanges : () => dispatch(mailActionChanges()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModifyObjective)