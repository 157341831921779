import React,{ useEffect, useState }  from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { toast } from 'react-toastify'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import useMediaQuery from '@mui/material/useMediaQuery'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import './goal-progress.css'

import PopUp from '../../common/popup/PopUp'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import GoalProgressCard from '../goal-progress-card/GoalProgressCard'

import { editEnableProgress } from '../../services/metric.service'
import { clearComputeMetricProgress, successComputeMetricProgress } from '../../redux/goal-progress/compute-metric-progress/ComputeMetricProgressAction'
import { clearUpdateComputeProgressMetric } from '../../redux/goal-progress/update-compute-progress-metric/UpdateComputeProgressMetricAction'
import { clearGoalProgress } from '../../redux/goal-progress/fetch-metric/GoalProgressAction'
import { requestMetricForSelect } from '../../services/videoConference.service'

const GoalProgress = (props) => {

    const { 
        metricState, 
        requestMetrics, 
        editEnableProgress,
        editProgressEnabledMetricState,
        computedGoalProgressState,
        saveComputeProgress,
        ownProps,
        clearUpdateComputeProgressState,
        clearFetchMetricState,
        clearComputeProgressState
    } = props 

    const [pageLoading, setPageLoading] = useState(true)
    const [ editGoalProgress, setEditGoalProgress ] = useState(false)

    const goals = metricState.response?.goals
    const isDesktop = useMediaQuery('(min-width:992px)')

    const initialValues = {
        progress_enabled: getAllProgressEnabledGraphIds(goals)
    }

    const validationSchema = Yup.object({
        progress_enabled : Yup.array().of(
                                Yup.string().required("Please select metric for goal progress")
                            ).max(5,"Max length 5")
    })

    const onSubmit = (values) => {
        editEnableProgress(values)
    }

    useEffect(() => {
        if( !metricState.loading && !isEmpty(metricState.response)){
            setPageLoading(false)
        }

        if(!metricState.loading && !isEmpty(metricState.error)){
            setPageLoading(false)
            console.log(metricState.error)
            if(!isEmpty(metricState.error)){
                if(metricState.error.data){
                    toast.error(metricState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else{
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    
    }, [metricState])

    function getAllProgressEnabledGraphIds(graphDataUnderGoals){
        let progressEnabledIds = []
        if(!isEmpty(graphDataUnderGoals)){
            for(const goal of graphDataUnderGoals){
                for(const graph of goal.graphs){
                    if(graph.enable_progress_computing){
                        progressEnabledIds.push(graph._id)
                    }
                }
            }
        }
        return progressEnabledIds
    }

    useEffect(() => {
        if(!editProgressEnabledMetricState.loading && !isEmpty(editProgressEnabledMetricState.response)){
            requestMetrics()
            saveComputeProgress(editProgressEnabledMetricState.response)
            setEditGoalProgress(false)
        }
        if(!editProgressEnabledMetricState.loading && !isEmpty(editProgressEnabledMetricState.error)){
            console.log(editProgressEnabledMetricState.error)
            if(!isEmpty(editProgressEnabledMetricState.error)){
                if(!isEmpty(editEnableProgress.error.data)){
                    toast.error(editEnableProgress.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else {
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[editProgressEnabledMetricState])

    useEffect(() =>{
        if(isEmpty(metricState.response)){
            requestMetrics()
        }
        return () => {
            clearUpdateComputeProgressState()
            clearFetchMetricState()
            // clearComputeProgressState()
        }
    },[])

    function metricOptions(goals){
        let goalArray = []
        for(let goal of goals){
            const graphs=[]
            for( let graph of goal.graphs){
                graphs.push(
                    <MenuItem 
                        sx={{
                            fontSize:"15px",
                            fontFamily:"'Lato',sans-serif",
                            color:"#444444"
                        }} 
                        value={graph._id} 
                        key={graph._id}
                    >
                        {graph.metric?.name}
                    </MenuItem>
                )
            }
            goalArray.push(
                <ListSubheader 
                    sx={{
                        fontSize:"13px",
                        fontFamily:"'Lato',sans-serif",
                        lineHeight:"30px",
                        color:"#858585"
                    }} 
                    key={goal._id} 
                >
                    {goal.name}
                </ListSubheader>, 
                ...graphs
            )
        }
        return goalArray
    }

    return (
        <PopUp handleClose={ownProps.handleClose} open={props.open} maxWidth="sm" fullWidth={true}>
            <div className='p-4'>
                <div className='progress-close-icon' onClick={ownProps.handleClose}>
                    <IconButton>
                        <CloseIcon />
                    </IconButton>
                </div>
                <h4 className="lato-fs-20-normal-lh32 font-bold primary-text-color text-center">Goal progress</h4>
                {
                    !pageLoading && !isEmpty(goals) ?
                        <>
                            {
                                !editGoalProgress ?
                                    !isEmpty(computedGoalProgressState.response?.amberZoneMetrics) || !isEmpty(computedGoalProgressState.response?.blueZoneMetrics) || !isEmpty(computedGoalProgressState.response?.greenZoneMetrics) || !isEmpty(computedGoalProgressState.response?.redZoneMetrics) ?
                                        <div>
                                            <div className="d-flex justify-content-end mb-3">
                                                <IconButton >
                                                    <EditIcon onClick={() => setEditGoalProgress(true)} />
                                                </IconButton>
                                            </div>
                                            {
                                                !isEmpty(computedGoalProgressState.response?.redZoneMetrics) ?
                                                    <div className='mb-4'>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <span className="bg-red px-3 py-1 lato-fs12-normal-lh15 font-boldest" style={{color: 'white'}}>(0% - 89%)</span>
                                                        </div>
                                                        { 
                                                            isDesktop ?
                                                                <Grid container spacing={{ xs:2}}>
                                                                    {
                                                                        computedGoalProgressState.response?.redZoneMetrics.map(metric => (
                                                                            <Grid item md={4}>
                                                                                <GoalProgressCard 
                                                                                    goalName={metric.goal_name}
                                                                                    metricName={metric.metric_name}
                                                                                    percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%`}
                                                                                    error={ metric.error ? true : "" }
                                                                                    cardBackground="bg-pale-red"
                                                                                />
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid> : 
                                                                <div className="scroll-container">
                                                                    {
                                                                        computedGoalProgressState.response?.redZoneMetrics.map(metric => (
                                                                            <GoalProgressCard 
                                                                                goalName={metric.goal_name}
                                                                                metricName={metric.metric_name}
                                                                                percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%`}
                                                                                error={ metric.error ? true : "" }
                                                                                cardBackground="bg-pale-red"
                                                                            />
                                                                        ))
                                                                    }
                                                                </div>
                                                        }
                                                    </div> 
                                                    : null
                                            }
                                            { 
                                                !isEmpty(computedGoalProgressState.response?.amberZoneMetrics) ?
                                                    <div className='mb-4'>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <span className="bg-amber px-3 py-1 lato-fs12-normal-lh15 font-boldest" style={{color: 'white'}}>(90% - 99%)</span>
                                                        </div>
                                                        {
                                                            isDesktop ? 
                                                                <Grid container spacing={{ xs:2}}>
                                                                    {
                                                                        computedGoalProgressState.response?.amberZoneMetrics.map(metric => (
                                                                            <Grid item md={4}>
                                                                                <GoalProgressCard 
                                                                                    goalName={metric.goal_name}
                                                                                    metricName={metric.metric_name}
                                                                                    percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%`}
                                                                                    error={ metric.error ? true : "" }
                                                                                    cardBackground="bg-pale-amber"
                                                                                />
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid> :
                                                                <div className="scroll-container">
                                                                    { 
                                                                        computedGoalProgressState.response?.amberZoneMetrics.map((metric) => (
                                                                            <GoalProgressCard 
                                                                                goalName={metric.goal_name}
                                                                                metricName={metric.metric_name}
                                                                                percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%`}
                                                                                error={ metric.error ? true : "" }
                                                                                cardBackground="bg-pale-amber"
                                                                            />
                                                                        ))
                                                                    }
                                                                </div>
                                                        }
                                                    </div> 
                                                    : null
                                            }
                                            { 
                                                !isEmpty(computedGoalProgressState.response?.greenZoneMetrics) ? 
                                                    <div className='mb-4'>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <span className="bg-green px-3 py-1 lato-fs12-normal-lh15 font-boldest" style={{color: 'white'}}>(100% - 109%)</span>
                                                        </div>
                                                        {
                                                            isDesktop ?
                                                                <Grid container spacing={{ xs:2, md:2 }}>
                                                                    {
                                                                        computedGoalProgressState.response?.greenZoneMetrics.map(metric => (
                                                                            <Grid item md={4} sx={{marginLeft:"10px"}}>
                                                                                <GoalProgressCard 
                                                                                    goalName={metric.goal_name}
                                                                                    metricName={metric.metric_name}
                                                                                    percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%`}
                                                                                    error={ metric.error ? true : ""}
                                                                                    cardBackground="bg-pale-green"
                                                                                />
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid> :
                                                                <div className="scroll-container">
                                                                    {
                                                                        computedGoalProgressState.response?.greenZoneMetrics.map((metric) => (
                                                                            <GoalProgressCard 
                                                                                goalName={metric.goal_name}
                                                                                metricName={metric.metric_name}
                                                                                percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%`}
                                                                                error={ metric.error ? true : ""}
                                                                                cardBackground="bg-pale-green"
                                                                            />
                                                                        ))
                                                                    }
                                                                </div>
                                                        }
                                                    </div> 
                                                    : null
                                            }
                                            { 
                                                !isEmpty(computedGoalProgressState.response?.blueZoneMetrics) ?
                                                    <div className='mb-4'>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <span className="bg-blue px-3 py-1 lato-fs12-normal-lh15 font-boldest" style={{color: 'white'}}>(110% and above)</span>
                                                        </div>
                                                        { 
                                                            isDesktop ?
                                                                <Grid container spacing={{ xs:2}}>
                                                                    {
                                                                        computedGoalProgressState.response?.blueZoneMetrics.map(metric => (
                                                                            <Grid item md={4}>
                                                                                <GoalProgressCard 
                                                                                    goalName={metric.goal_name}
                                                                                    metricName={metric.metric_name}
                                                                                    percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%` }
                                                                                    error={ metric.error ? true : "" }
                                                                                    cardBackground="bg-pale-blue"
                                                                                />
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid> :
                                                                <div className="scroll-container">
                                                                    {
                                                                        computedGoalProgressState.response?.blueZoneMetrics.map((metric) => (
                                                                            <GoalProgressCard 
                                                                                goalName={metric.goal_name}
                                                                                metricName={metric.metric_name}
                                                                                percentage={metric.error ? metric.error : `${metric.progressPercentage.toFixed(2)}%` }
                                                                                error={ metric.error ? true : "" }
                                                                                cardBackground="bg-pale-blue"
                                                                            />
                                                                        ))
                                                                    }
                                                                </div>
                                                        }
                                                    </div> 
                                                    : null 
                                            }
                                        </div> 
                                        : <div className='d-flex justify-content-center align-items-center py-4 flex-column'>
                                            <p className='mb-0 lato-fs17-normal-lh20 primary-text-color text-center'>No metrics selected yet to monitor goal progress</p>
                                            <CustomizeButton 
                                                className="mt-3"
                                                variant="primary-start-icon-button" 
                                                startIcon={<AddIcon />} 
                                                type="button" 
                                                handleClick={() => setEditGoalProgress(true)} 
                                                label="Choose Metrics"
                                            />
                                        </div>
                                    : <div 
                                        className='mt-4'
                                        style={{
                                            maxWidth:"350px",
                                            margin:"auto"
                                        }}
                                    >
                                        <Formik 
                                            enableReinitialize={true}
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                            onSubmit={onSubmit}
                                        >
                                            {
                                                ({ values, handleBlur, handleChange, errors, touched }) => (
                                                    <Form>
                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="grouped-select" className="input-label">Select Metric</InputLabel>
                                                            <Select defaultValue=""
                                                                className="group-select" 
                                                                id="grouped-select" 
                                                                label="Select metric" 
                                                                multiple
                                                                onChange={ handleChange }
                                                                onBlur={ handleBlur } 
                                                                name="progress_enabled"
                                                                error={touched.progress_enabled && Boolean(errors.progress_enabled)}
                                                                value={values.progress_enabled}>
                                                                {metricOptions(goals)}
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            touched.progress_enabled && Boolean(errors.progress_enabled) ?
                                                                <FormHelperText 
                                                                    sx={{
                                                                        color : '#dc3545', 
                                                                        fontFamily: '"oxygen", sans-serif'
                                                                    }}
                                                                >
                                                                    {errors.progress_enabled}
                                                                </FormHelperText> 
                                                                : null
                                                        }
                                                        <div className="d-flex justify-content-center mt-4 mb-2">
                                                            <CustomizeButton 
                                                                variant="secondary-color-button" 
                                                                label="Cancel" 
                                                                handleClick={() => setEditGoalProgress(false)} 
                                                                className="me-4" 
                                                            />
                                                            <CustomizeButton 
                                                                variant="primary-color-button" 
                                                                label="Submit" 
                                                                type="submit"
                                                            />
                                                        </div>
                                                    </Form>
                                                )
                                        }
                                        </Formik>
                                    </div>
                            }
                        </>
                        : null
                }
            </div>
        </PopUp>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        metricState : state.metricsForSelect,
        computedGoalProgressState : state.computeProgressMetric,
        editProgressEnabledMetricState : state.updateComputeProgressMetric,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestMetrics : () => dispatch(requestMetricForSelect()),
        editEnableProgress : (metrics) => dispatch(editEnableProgress(metrics)),
        saveComputeProgress : (metricProgress) => dispatch(successComputeMetricProgress(metricProgress)),
        clearUpdateComputeProgressState : () => dispatch(clearUpdateComputeProgressMetric()),
        clearFetchMetricState : () => dispatch(clearGoalProgress()),
        clearComputeProgressState : () => dispatch(clearComputeMetricProgress()),
        // clearMetricSelectState : () => dispatch(clearMetricsForSelectState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GoalProgress)
