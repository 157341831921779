import { REQUEST_COURSE_PERFORMANCE,
         COURSE_PERFORMANCE_SUCCESS, 
         COURSE_PERFORMANCE_FAILURE,
         COURSE_PERFORMANCE_TABLE } from './CoursePerformanceType'

export const requestCoursePerformance = () => {
    return {
        type : REQUEST_COURSE_PERFORMANCE
    }
}

export const coursePerformanceSuccess = (resData) => {
    return {
        type : COURSE_PERFORMANCE_SUCCESS,
        payload : resData
    }
}

export const coursePerformanceFailure = (error) => {
    return {
        type : COURSE_PERFORMANCE_FAILURE,
        payload : error
    }
}

export const clearCoursePerformance = () => {
    return {
        type : COURSE_PERFORMANCE_TABLE,
    }
}