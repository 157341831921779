import React, {forwardRef} from 'react'

import ImgMenu from '../../common/img-menu/ImgMenu'

import WallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png'
import EditIcon from '@mui/icons-material/Edit'

import './process-wallpaper-edit.css'
import { IconButton } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'

const ProcessWallpaperEdit = (props, ref) => {
    return (
        <div className="wallpaper-preview-container overflow-hidden d-flex justify-content-center align-items-center mx-auto">
            <img 
                style={{
                    zIndex: '1',
                    width: '100%',
                    height: '100%',
                    objectFit: "cover",
                    objectPosition: 'center'
                }}
                src={props.previewImage || WallpaperPlaceholder} 
                alt=""
            />
            <IconButton
                sx={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    "&:hover" : {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    },
                    zIndex: 2
                }}
                id="wallpaper-edit-icon"
                size='small'
                aria-controls={props.menuOpen ? 'wallpaper-edit-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={props.menuOpen ? 'true' : undefined}
                onClick={props.handleEditIconClick}
            >
                <EditIcon sx={{color: 'white', fontSize: '1rem'}}/>
            </IconButton>
            <ImgMenu
                id="wallpaper-edit-menu"
                ariaLabelledBy="wallpaper-edit-icon"
                anchorEl={props.anchorEl}
                open={props.menuOpen}
                onClose={props.handleMenuClose}
                onUploadClick={props.onUploadClick}
                onRemoveClick={props.onRemoveClick}
                disableRemove={ props.disableRemove ? props.disableRemove : Boolean(!props.previewImage)}
            />
            <input 
                type="file"
                style={{display:"none"}} 
                ref={ref} 
                accept="image/jpg,image/png,image/jpeg"
                name="process_wallpaper"
                onChange={props.onInputChange}
            />
        </div>
    )
}

export default forwardRef(ProcessWallpaperEdit)