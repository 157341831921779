import React from 'react'
import Badge from '@mui/material/Badge'

import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import wallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png' 
import LevelIndicator from '../level-indicator/LevelIndicator'
import pendingIcon from '../../assets/icons/pending-clock.svg'
import approvedIcon from '../../assets/icons/on-time.svg'
import './objective-card.css'

const ObjectiveCard = (props, ref) => {

    return (
        <div className={ props.selected ? `vcf-card pt-cursor p-0 selected ${props.cardContainer}` : `vcf-card pt-cursor p-0 ${props.cardContainer}`} 
            onClick={props.onClick}
            style={{ backgroundImage: `url(${ props.wallPaper ? props.wallPaper : wallpaperPlaceholder})`}} 
            data-row={props.row} 
            data-col={props.col}>
            <div className="overlay">
                <div className="obj-detail">
                    <img className="obj-icon-mobile" src={props.icon ? props.icon : iconPlaceholder} alt="obj-icon"/>
                    <div className="d-flex align-items-center">
                        <span className={`lato-fs20-normal-lh28 ps-3 pe-2 mb-0 white-color ${props.cardNameClassName ? props.cardNameClassName : ""}`}>{props.name}</span>
                    </div>
                    <div className="ms-auto d-flex">
                        <LevelIndicator 
                            containerClassName="d-flex flex-column align-items-center justify-content-center ps-1" 
                            maxLevel={props.maxLevel}
                            level={props.level}  />
                    </div>
                    { props.pendingStatus ?
                        <div className="status-container-card">
                            {props.pendingStatus === "pending" ?
                                <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                                <img className="action-icon" src={pendingIcon} alt=""></img>
                                </Badge> : null }
                            {props.pendingStatus === "not_needed" ? <img className="action-icon" src={pendingIcon} alt=""></img> : ""}
                            {props.pendingStatus === "approved" ? <img className="approved-icon" src={approvedIcon} alt=""></img> : ""}
                        </div> : null
                    }
                    { props.sharedStatus === "rejected" || props.sharedStatus === "accepted" || props.sharedStatus === "modified" ? 
                        <div className="d-flex align-items-center">
                            <p className={ props.sharedStatus === "rejected" ? "mb-0 sharing-status reject-sharing" : props.sharedStatus === "modified" ? 
                            "mb-0 sharing-status modify-sharing" : "mb-0 sharing-status accept-sharing"}>{props.sharedStatus}</p>
                        </div> : ""}
                </div>
            </div>
        </div>
    )
}

export default ObjectiveCard