import React from 'react'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem'
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: p.focusColor
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: p.focusColor
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: p.focusColor
      }
    }
}));

const StandardSelectBoxById = (props) => {

    function createOptions(optionList){
        return optionList.map((item,index) => 
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
        );
    }

    return (
        <div>
            <CssTextField  id="standard-basic"
                    select
                    hiddenLabel={!props.label}
                    label={props.label}
                    sx={{minWidth :"100px"}}
                    variant="filled"
                    name={props.name} 
                    fullWidth
                    onChange={props.onChange} 
                    value={props.value} 
                    onBlur={props.onBlur} 
                    size={props.size}
                    // defaultValue={props.defaultValue}
                    focusColor="#14213d"
                    InputProps={{
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    autoComplete="off">
                {createOptions(props.options, props.defaultvalue)}
            </CssTextField>
            {props.errorMgs ? 
                <div className="ms-1">
                    <span className="error-message">{props.errorMgs}</span>
                </div> : null}
        </div>
    )
}

export default StandardSelectBoxById