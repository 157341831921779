import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import { removeUserCredentials } from '../../utils/utils'

const HeaderContainer = (props) => {

    const { userLogout } = props

    const history = useHistory()

    const handleLogout = () => {
        userLogout()
        removeUserCredentials()
        history.push("/sign-in")
    }

    return (
        <div className="py-2 d-flex flex-column px-2">
            <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faArrowLeft} className="secondary-color pt-cursor" onClick={props.BackArrowEvent} />
                <h4 className="fsb17-lh32 mb-0 ms-2 one-line-wrap">{props.heading}</h4>
                { props.logoutIcon ?
                    <div>
                        <FontAwesomeIcon icon={faSignOutAlt} className="fs-18 pt-cursor secondary-color" onClick={handleLogout} />
                    </div> : null}
            </div>
            { props.subHeading ?
                <div>
                    <p className="secondary-color mb-0 ps-3 fs13-lh15">{props.subHeading}</p>
                </div> : null }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return{
        userLogout : () => dispatch({
            type :"USER_LOGOUT"
        }),
    }
}

export default connect(null,mapDispatchToProps)(HeaderContainer)