import React, {useState, useEffect} from 'react'
import { useHistory, useParams } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng"
import { useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import PeopleIcon from "@mui/icons-material/People";
import useMediaQuery from '@mui/material/useMediaQuery'
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

import { requestScreenSharingToken } from '../../services/videoConference.service'
import Just4BlueLogo from '../../assets/icons/Just_4_logo_blue.svg'
import Just4BlueIcon from '../../assets/icons/Just_4_icon_blue.svg'
// import { changingRemoteUser } from '../../redux/video-conference/video-chat/videoChat.action'

import './controls.css'

const config = { 
    mode: "rtc", codec: "vp8"
};

export const Controls = (props) => {

  const isMobile = useMediaQuery('(max-width: 480px)')

  const { 
    start,
    tracks,
    setStart,
    setInCall,
    setTrackState,
    trackState,
    setEndCall,
    localClient : client,
    setDrawerOpen,
    drawerOpen
  } = props;

  const videoChatState = useSelector((state) => state.videoChat)

  const localVC = videoChatState.localVC
  const screenShareUser = videoChatState.screenShareUser

  const [screenShareClient, setScreenShareClient] = useState(null)
  const [screenVideoTrack, setScreenVideoTrack] = useState(null)

  const params = useParams()
  const history = useHistory();

  // Drawer open
  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }
    
  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };
  
  useEffect(() => {
    if(start && tracks){
      if(!trackState.video){
        tracks[1].setEnabled(trackState.video)
      }
      if(!trackState.audio){
        tracks[0].setEnabled(trackState.audio)
      }
    }
  },[start])
  
  useEffect(() => {
    if (screenVideoTrack) {
      screenVideoTrack.on('track-ended', async () => {
        await handleScreenShareStop()
      })
    }
  },[screenVideoTrack])

  async function handleScreenShareStart() {
    const screenClient = AgoraRTC.createClient(config);
    try{
      const ssTokenObj = await requestScreenSharingToken(params.channelId)
      try {
        await screenClient.join(localVC.app_id, params.channelId, ssTokenObj.data.token, localVC.vc_data.ss_uid);
        const screenTrack = await AgoraRTC.createScreenVideoTrack();
        await screenClient.publish(screenTrack);
        // setLocalScreenSharing(true)
        setScreenShareClient(screenClient)
        setScreenVideoTrack(screenTrack)
      } catch(err) {
        console.log(err)
        await screenClient.leave()
        console.log('line 1')
      }
    }catch(error){
      console.log(error)
      toast.error(error?.data?.error ? error?.data?.error : 'Something went wrong!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
            //TODO clear error message in redux state
        }
      })
    }
    console.log('line 2')
  }
  
  async function handleScreenShareStop() {
    screenVideoTrack.close();
    await screenShareClient.leave();
    // setLocalScreenSharing(false)
    setScreenVideoTrack(null)
    setScreenShareClient(null)
  }
    
  const leaveChannel = async () => {
    if(screenShareClient){
      await handleScreenShareStop()
    }
    await client.leave()
    client.removeAllListeners()
    tracks[0].close()
    tracks[1].close()
    history.goBack()
    setStart(false)
    setEndCall(true)
    setInCall(false)
  };

  return (
    <div className='p-relative' style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
      <Grid container>
        <Grid className='d-flex align-items-center' sx={{paddingLeft: {xs: '1rem', sm: '3rem'}}} item xs={3}>
          <img
            src={ isMobile ? Just4BlueIcon : Just4BlueLogo}
            alt=""
            style={{width: isMobile ? '35px' : "100px"}}
          />
        </Grid>
        <Grid item xs={6}>
          <div className="d-flex justify-content-around px-3 py-2 max-width-600px mx-auto">
            <IconButton
              sx={{
                color: 'var(--primary-text-color-v2)',
                "&:hover": {
                    ...(!trackState?.audio && { backgroundColor: 'var(--primary-color-v2)', color: 'white'})
                },
                ...(!trackState?.audio && { backgroundColor: 'var(--primary-color-v2)', color: 'white'})
              }}
              onClick={() => mute("audio")}
              disabled={!start}
            >
              {
                trackState.audio ? 
                  <MicIcon />
                  : <MicOffIcon />
              }
            </IconButton>
            <IconButton
              sx={{
                color: 'var(--primary-text-color-v2)',
                "&:hover": {
                    ...(!trackState?.video && { backgroundColor: 'var(--primary-color-v2)', color: 'white'})
                },
                ...(!trackState?.video && { backgroundColor: 'var(--primary-color-v2)', color: 'white'})
              }}
              onClick={() => mute("video")}
              disabled={!start}
            >
              {
                trackState.video ? 
                  <VideocamIcon/>
                  : <VideocamOffIcon />
              }
            </IconButton>
            {
              !isMobile ?
                !screenVideoTrack ?
                  <IconButton
                    sx={{color: 'var(--primary-text-color-v2)'}}
                    onClick={handleScreenShareStart}
                    disabled={!start || !isEmpty(screenShareUser)}
                  >
                    <PresentToAllIcon />
                  </IconButton>
                  : <IconButton 
                    sx={{
                      "&:hover": {
                        backgroundColor: 'var(--primary-color-v2)', 
                        color: 'white'
                      },
                      backgroundColor: 'var(--primary-color-v2)', 
                      color: 'white'
                    }}
                    onClick={handleScreenShareStop}
                  >
                    <CancelPresentationIcon />
                  </IconButton>
                : null
            }
            <IconButton
              sx={{
                backgroundColor : "red", 
                '&:hover': {backgroundColor: "red"},
                color: 'white'
              }}
              onClick={() => leaveChannel()}
              disabled={!start}
            >
              <CallEndIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid className='d-flex justify-content-end align-items-center' sx={{paddingRight: {xs: '0.5rem', sm: '3rem'}}} item xs={3}>
          <IconButton
            disabled={!start}
            onClick={handleDrawerOpen}
            sx={{
              color: 'var(--primary-text-color-v2)',
              "&:hover": {
                  ...(drawerOpen && { backgroundColor: 'var(--primary-color-v2)', color: 'white'})
              },
              ...(drawerOpen && { backgroundColor: 'var(--primary-color-v2)', color: 'white'})
            }}
          >
            <PeopleIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Controls