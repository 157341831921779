import React,{ useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty, size } from 'lodash'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'

import { requestYourMeetings } from '../../services/videoConference.service'
import VideoConferenceCard from '../video-conference-card/VideoConferenceCard'

const YourMeeting = (props) => {

    const { requestYourMeeting, yourMeetingState } = props

    const yourMeetings = yourMeetingState.response.your_meetings

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [pageLoading,setPageLoading] = useState(true)
    const [anchorEls, setAnchorEls] = useState({})

    const handleClick = (event, id) => {
        setAnchorEls({[id]: event.currentTarget})
    }

    const handleClose = () => {
        setAnchorEls({})
    }

    useEffect(() => {
        requestYourMeeting()
    },[])
    
    useEffect(() => {
        if(!yourMeetingState.loading && !isEmpty(yourMeetingState.response)){
            setPageLoading(false)
        }

        if(!yourMeetingState.loading && !isEmpty(yourMeetingState.error)){
            console.log(yourMeetingState.error)
            if(!isEmpty(yourMeetingState.error)){
                if(yourMeetingState.error.data){
                    toast.error(yourMeetingState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[yourMeetingState])

    return (
        <>
            {
                !pageLoading ?
                    <div className={ isDesktop ? "pt-5" : "container pt-4 px-4"}>
                        { 
                            !isEmpty(yourMeetings.past) || !isEmpty(yourMeetings.present) || !isEmpty(yourMeetings.future) ?
                                <>
                                    {
                                        !isEmpty(yourMeetings.present) ?
                                            <div>
                                                <h4 className='lato-fs16-normal-lh20 secondary-text-color mb-3'>Meetings today</h4>
                                                {
                                                    yourMeetings.present.map((meeting, index) => (
                                                        <div key={meeting._id} className="mb-3">
                                                            <VideoConferenceCard 
                                                                meetingDate = {moment(meeting.scheduled_at).format('DD MMM YYYY')}
                                                                meetingTime = {moment(meeting.scheduled_at).format('hh:mm A')}
                                                                subject = {meeting.subject}
                                                                meetingMember={meeting.to}
                                                                member={`${meeting.to.length} people invited` }
                                                                buttonLabel="Start conference"
                                                                enableButton={true}
                                                                anchorEl = {anchorEls[meeting._id] ? anchorEls[meeting._id] : null}
                                                                handleClick = {(event) => handleClick(event, meeting._id)}
                                                                handleClose= {handleClose}
                                                                conferenceId = {meeting.channel_id}
                                                                memberPopUpId={meeting._id}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            : null
                                    }
                                    {
                                        !isEmpty(yourMeetings.future) ?
                                            <div>
                                                <h4 className='lato-fs16-normal-lh20 secondary-text-color mb-3'>Future meetings</h4>
                                                {
                                                    yourMeetings.future.map((meeting, index) => (
                                                        <div key={meeting._id} className="mb-3">
                                                            <VideoConferenceCard 
                                                                meetingDate = {moment(meeting.scheduled_at).format('DD MMM YYYY')}
                                                                meetingTime = {moment(meeting.scheduled_at).format('hh:mm A')}
                                                                subject = {meeting.subject}
                                                                meetingMember={meeting.to}
                                                                member={`${meeting.to.length} people invited` }
                                                                buttonLabel="Start conference"
                                                                enableButton={true}
                                                                anchorEl = {anchorEls[meeting._id] ? anchorEls[meeting._id] : null}
                                                                handleClick = {(event) => handleClick(event, meeting._id)}
                                                                handleClose= {handleClose}
                                                                conferenceId = {meeting.channel_id}
                                                                memberPopUpId={meeting._id}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            : null
                                    }
                                    {
                                        !isEmpty(yourMeetings.past) ?
                                            <div>
                                                <h4 className='lato-fs16-normal-lh20 secondary-text-color mb-3'>Past meetings</h4>
                                                {
                                                    yourMeetings.past.map((meeting, index) => (
                                                        <div key={meeting._id} className="mb-3">
                                                            <VideoConferenceCard
                                                                meetingDate = {moment(meeting.scheduled_at).format('DD MMM YYYY')}
                                                                meetingTime = {moment(meeting.scheduled_at).format('hh:mm A')}
                                                                subject = {meeting.subject}
                                                                meetingMember={meeting.to}
                                                                member={`${meeting.to.length} people invited`}
                                                                buttonLabel="Start conference"
                                                                anchorEl = {anchorEls[meeting._id] ? anchorEls[meeting._id] : null}
                                                                handleClick = {(event) => handleClick(event, meeting._id)}
                                                                handleClose= {handleClose}
                                                                conferenceId = {meeting.channel_id}
                                                                memberPopUpId={meeting._id}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            : null
                                    }
                                </>
                                : <div className={isDesktop ? "d-flex" : "d-flex justify-content-center"}>
                                    <p className="lato-fs18-normal-lh25 text-center mb-0">You don't have any meetings yet</p>
                                </div>
                        }
                    </div>
                    : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        yourMeetingState : state.videoConferenceYourMeeting
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestYourMeeting : () => dispatch(requestYourMeetings())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(YourMeeting)