import {INDIVIDUAL_SIGNUP_REQUEST, 
        INDIVIDUAL_SIGNUP_SUCCESS, 
        INDIVIDUAL_SIGNUP_FAILURE,
        INDIVIDUAL_SIGNIN_REQUEST,
        INDIVIDUAL_SIGNIN_SUCCESS,
        INDIVIDUAL_SIGNIN_FAILURE, 
        CLEAR_AUTH_STATES} from './AuthenticationTypes'

export const requestSignupIndividual = () => {
    return{
        type: INDIVIDUAL_SIGNUP_REQUEST
    }
}

export const individualSignupSuccess = (resData) =>{
    return{
        type: INDIVIDUAL_SIGNUP_SUCCESS,
        payload: resData
    }
}

export const individualSignupFailure = (error) => {
    return{
        type: INDIVIDUAL_SIGNUP_FAILURE,
        payload: error
    }
}

export const individualSigninRequest = () => {
    return{
        type : INDIVIDUAL_SIGNIN_REQUEST
    }
}

export const individualSigninSuccess = (resData) => {
    return{
        type: INDIVIDUAL_SIGNIN_SUCCESS,
        payload: resData
    }
}

export const individualSigninFailure = (error) => {
    return{
        type: INDIVIDUAL_SIGNIN_FAILURE,
        payload: error
    }
}

export const clearAuthStates = () => {
    return{
        type: CLEAR_AUTH_STATES
    }
}