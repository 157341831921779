export const REQUEST_MODIFY_LEARNING_AREA = "request_modify_learning_area"
export const MODIFY_LEARNING_AREA_SUCCESS = "modify_learning_area_success"
export const MODIFY_LEARNING_AREA_FAILURE = "modify_learning_area_failure"

export const SAVE_LEARNING_AREA_ICON = "save_learning_area_icon"
export const SAVE_COURSE_CONTENT_FILES = "save_course_content_files"
export const REMOVE_COURSE_CONTENT_FILES = "remove_course_content_files"

export const SAVE_MODIFY_LEARNING_VALIDATION_ERROR = "save_modify_learning_validation_error"
export const REMOVE_MODIFY_LEARNING_VALIDATION_ERROR = "remove_modify_learning_validation_error"

export const CLEAR_MODIFY_LEARNING_AREA = "clear_modify_learning_area"