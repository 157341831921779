import React from 'react'

const Container = (props) => {
    return (
        <div className='d-flex flex-column vh-100'>
            {props.children}
        </div>
    )
}

export default Container