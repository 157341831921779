import React,{ useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import IconButton from '@mui/material/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import EditIcon from '@mui/icons-material/Edit'

import editIcon from '../../../assets/icons/edit_white.svg'
import InputBox from '../../../common/Input-box/InputBox';
import { updateOrgProfileData } from '../../../services/ProfileData.service';
import { clearUpdateOrgProfileDataStates } from '../../../redux/update-org-profile/updateOrgProfile.action';
import { saveProfileData } from '../../../redux/profile/ProfileAction'
import { getRole } from '../../../utils/utils';
import { fetchDepartments } from '../../../services/departments.service';
import SelectBoxValueById from '../../../common/select-box-value-id/SelectBoxValueById';
import { fetchBranches } from '../../../services/branch.service';
import { CONTROLLER_ROLE } from '../../../utils/constants'
import Grid from '@mui/material/Grid'
import CompanyPlaceholder from '../../../assets/images/company-placeholder.png'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import Avatar from '@mui/material/Avatar'

import './corporate-profile.css'
import ImgMenu from '../../../common/img-menu/ImgMenu'

const CorporateProfile = (props) => {

    const {
        updateOrgProfileData,
        updateOrgProfileState, 
        clearOrgUpdateProfileState, 
        saveUpdatedProfileData, 
        requestDepartment, 
        departmentState,
        branchState,
        requestBranch,
        ownProps
    } = props

    const imageRef = useRef()

    const [imageFile, setImageFile] = useState("")
    const [profileImage, setProfileImage] = useState("")
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)
    // const [ corporateProfileEdit, setCorporateProfileEdit ] = useState(false)
    const [ pageLoading, setPageLoading ] = useState({
        branch : true,
        department : true
    })

    const corporateProfileData = props.profileData.profile_data.user_subscriber_mapping

    const initialValues = {
        org_name: !isEmpty(corporateProfileData) && !isEmpty(corporateProfileData[0]) && !isEmpty(corporateProfileData[0].subscribed_to) ? corporateProfileData[0].subscribed_to.org_name : "",
        department : !isEmpty(corporateProfileData) && !isEmpty(corporateProfileData[0]) && !isEmpty(corporateProfileData[0].department) && corporateProfileData[0].department._id ? corporateProfileData[0].department._id : "",
        job_role : !isEmpty(corporateProfileData) && !isEmpty(corporateProfileData[0]) && corporateProfileData[0].job_role ? corporateProfileData[0].job_role : "",
        branch: !isEmpty(corporateProfileData) && !isEmpty(corporateProfileData[0]) && !isEmpty(corporateProfileData[0].branch) && corporateProfileData[0].branch._id ? corporateProfileData[0].branch._id :""
    }

    const validationSchema = Yup.object({
        org_name : Yup.string().required("Please enter organisation name"),
        branch : Yup.string().required("Please select a location")
    })

    const onSubmit = (values) => {
        const valuesCopy = JSON.parse(JSON.stringify(values))
        if(navigator.onLine){
            if(!profileImage && corporateProfileData?.[0]?.subscribed_to?.org_pic){
                valuesCopy.org_pic = ""
            }
            updateOrgProfileData(formatToFormData(values))
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function formatToFormData(profileData){
        const json = JSON.stringify(profileData)
        let data = new FormData()
        data.append("org_data", json)
        if(imageFile !== ""){
            data.append("org_pic", imageFile)
        }
        return data
    }

    // profile remove feature
    const profileMenuOpen = Boolean(profileAnchorEl)

    const handleProfileEditIconClick = (event) => {
        event.preventDefault()
        setProfileAnchorEl(event.currentTarget)
    }

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null)
    }

    const onRemoveProfileClick = () => {
        if(imageFile) setImageFile('')
        if(profileImage) setProfileImage('')
    }

    useEffect(() => {
        setProfileImage(!isEmpty(corporateProfileData) && !isEmpty(corporateProfileData[0].subscribed_to) && corporateProfileData[0].subscribed_to.org_pic ? corporateProfileData[0].subscribed_to.org_pic : '')
        if(isEmpty(departmentState.response)){
            requestDepartment()
        }
        if(isEmpty(branchState.response)){
            requestBranch()
        }
    }, [])

    useEffect(() => {
        if(!departmentState.loading && !isEmpty(departmentState.response)){
            setPageLoading((ps) => {
                return {
                    ...ps,
                    department : false
                }
            })
        }

        if(!departmentState.loading && !isEmpty(departmentState.error)){
            console.log(departmentState.error)
            setPageLoading((ps) => {
                return {
                    ...ps,
                    department : false
                }
            })
            if(departmentState.error?.data){
                toast.error(departmentState.error?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
        
    }, [departmentState])

    useEffect(() => {
        if(!branchState.loading && !isEmpty(branchState.response)){
            setPageLoading((ps) => {
                return {
                    ...ps,
                    branch : false
                }
            })
        }

        if(!branchState.loading && !isEmpty(branchState.error)){
            console.log(branchState.error)
            setPageLoading((ps) => {
                return {
                    ...ps,
                    branch : false
                }
            })
            if(branchState.error?.data){
                toast.error(branchState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
        
    }, [branchState])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit,
        validationSchema
    })

    useEffect(() => {
        if(imageFile){
            const reader = new FileReader()
            reader.onloadend =() =>{
                setProfileImage(reader.result)
            }
            reader.readAsDataURL(imageFile)
        }
    }, [imageFile])

    useEffect(()=>{
        if(!updateOrgProfileState.loading && !isEmpty(updateOrgProfileState.response)){
            saveUpdatedProfileData(updateOrgProfileState.response)
            // setCorporateProfileEdit(ps => !ps)
            ownProps.handleEditModeChange(false)
            clearOrgUpdateProfileState()
        }

        if(!updateOrgProfileState.loading && !isEmpty(updateOrgProfileState.error)){
            console.log('updating profile data failed', updateOrgProfileState.error)
            if(updateOrgProfileState.error?.data){
                toast.error(updateOrgProfileState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    }, [updateOrgProfileState])

    function handleRemainingUsers(){
        if(!isEmpty(corporateProfileData)){
            let activeUser = corporateProfileData[0].subscribed_to.users.length
            let maxUsers = corporateProfileData[0].subscribed_to.org_range.max
            return maxUsers-activeUser
        }
    }

    // function handleEditButton(){
    //     setCorporateProfileEdit(ps => !ps)
    //     handleClose()
    // }

    function getDepartment(array){
        return array.map((department) => {
            return {
                label : department.name,
                value : department._id
            }
        })
    }

    function getBranch(array){
        if(!isEmpty(array[0].city)){
            return array.map((branch) => {
                return {
                    label : branch.city,
                    value : branch._id
                }
            })
        }
    }

    function getCorporateProfileDetailsForm(){
        return (
            <form onSubmit={formik.handleSubmit}>
                <div className="mb-2">
                    <div className="p-relative preview-image-container " style={{backgroundColor: '#f1f1f1', borderRadius: '50%'}}>
                        <img className="preview-image" src={!profileImage ? CompanyPlaceholder : profileImage} alt=""/>
                            <button
                                id="profile-edit-icon"
                                aria-controls={profileMenuOpen ? 'profile-edit-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={profileMenuOpen ? 'true' : undefined}
                                className="image-button"
                                onClick={handleProfileEditIconClick} 
                            >
                                <img className="" src={editIcon} alt="" />
                            </button>
                            <ImgMenu 
                                id="profile-edit-menu"
                                ariaLabelledBy="profile-edit-icon"
                                anchorEl={profileAnchorEl}
                                open={profileMenuOpen}
                                onClose={handleProfileMenuClose}
                                onUploadClick={(event) => {
                                    event.preventDefault();
                                    imageRef.current.click();
                                    handleProfileMenuClose()
                                }}
                                onRemoveClick={() => {
                                    onRemoveProfileClick()
                                    handleProfileMenuClose()
                                }}
                                disableRemove={Boolean(!profileImage)}
                            />
                    </div>
                    <input type="file"
                        style={{display:"none"}} 
                        ref={imageRef} 
                        accept="image/png, image/jpeg, image/jpg"
                        name="banner"
                        onClick={(event) => {
                            event.target.value = null
                        }}
                        onChange={(event) =>{
                            const file = event.target.files[0];
                            if(file && file.type.substr(0,5) === "image"){
                                setImageFile(file)
                            }else {
                                setImageFile(null)
                            }
                        }} />
                </div>
                <div className="row pt-3">
                    <div className="col-md-6 mb-3">
                            <InputBox type="text" 
                                name="org_name"
                                label="Organization Name"
                                placeholder="Enter organization name"
                                value={formik.values.org_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                errorMgs={formik.touched.org_name && formik.errors.org_name ? formik.errors.org_name : ""}
                            /> 
                    </div>
                    <div className="col-md-6 mb-3">
                        <SelectBoxValueById 
                            options={!isEmpty(getBranch(branchState.response.active_branches)) ? getBranch(branchState.response.active_branches) : []}
                            label="Location"
                            name="branch"
                            value={formik.values.branch}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errorMgs={ formik.touched.branch && formik.errors.branch ? formik.errors.branch : ""}
                        />
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-md-6 mb-3">
                            <SelectBoxValueById 
                                options={!isEmpty(getDepartment(departmentState.response.active_departments)) ? getDepartment(departmentState.response.active_departments) : []}
                                label="Department"
                                name="department"
                                value={formik.values.department}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            /> 
                    </div>
                    <div className="col-md-6 mb-3">
                        <InputBox 
                            type="text"
                            name="job_role"
                            label="Job Role"
                            placeholder="Enter job role"
                            value={formik.values.job_role}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                </div>
                {/* <div>
                    {!isEmpty(corporateProfileData) && !isEmpty(corporateProfileData[0]) && !isEmpty(corporateProfileData[0].assignees) ?
                        <>
                            <label className="label-heading">Reviewer</label>
                            <div className="grid-column-2">
                                { corporateProfileData[0].assignees.map((reviewer,index) => (
                                    <div className="d-flex" key={index}>
                                        <img src={reviewer.profile_pic ? reviewer.profile_pic : iconPlaceholder} style={{width:"50px", height: "50px", borderRadius:"50%"}}></img>
                                        <div className="ml-1">
                                            { !isEmpty(reviewer.person_name) ?
                                                <div>
                                                    <span className="pe-1">{reviewer.person_name.first_name}</span>
                                                    <span>{reviewer.person_name.last_name}</span>
                                                </div> : null }
                                            { !isEmpty(reviewer.user_subscriber_mapping[0]) && !isEmpty(reviewer.user_subscriber_mapping[0].job_role) ?  <span>Job {reviewer.user_subscriber_mapping[0].job_role}</span> : null }
                                        </div>
                                    </div>
                                ))}
                            </div> 
                        </> : null }
                </div>
                <div className="mt-1">
                    {!isEmpty(corporateProfileData) && !isEmpty(corporateProfileData[0]) && !isEmpty(corporateProfileData[0].super_reviewers) ?
                        <>
                            <label className="label-heading">Super Reviewer</label>
                            <div className="grid-column-2">
                                { corporateProfileData[0].super_reviewers.map((supereReviewer,index) => (
                                    <div className="d-flex" key={index}>
                                        <img src={supereReviewer.profile_pic ? supereReviewer.profile_pic : iconPlaceholder} style={{width:"50px", height: "50px", borderRadius:"50%"}}></img>
                                        <div className="ml-1">
                                            { !isEmpty(supereReviewer.person_name) ?
                                                <div>
                                                    <span className="pe-1">{supereReviewer.person_name.first_name}</span>
                                                    <span>{supereReviewer.person_name.last_name}</span>
                                                </div> : null }
                                            { !isEmpty(supereReviewer.user_subscriber_mapping[0]) && !isEmpty(supereReviewer.user_subscriber_mapping[0].job_role) ?  <span>Job {supereReviewer.user_subscriber_mapping[0].job_role}</span> : null }
                                        </div>
                                    </div>
                                ))}
                            </div> 
                        </> : null 
                    }
                </div> */}
                <div className="button-container mt-4">
                    <CustomizeButton label="Cancel" variant="secondary-color-button" handleClick={() => ownProps.handleEditModeChange(false)} />
                    <CustomizeButton className="ms-3" label="Update" variant="primary-color-button" type="submit" />
                </div>
            </form>
        )
    }

    function getCorporateProfileDetails(){
        return (
            <div className='container'>
                { 
                    getRole() === CONTROLLER_ROLE ?
                        <div className="d-flex justify-content-end" >
                            <IconButton onClick={() => ownProps.handleEditModeChange(true, "corporateProfileEdit")} >
                                <EditIcon />
                            </IconButton>
                            {/* <IconButton
                                aria-label="more"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <FontAwesomeIcon size="xs" icon={faEllipsisV} />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}>
                                    <MenuItem onClick={handleEditButton} >Edit</MenuItem> 
                            </Menu>  */}
                        </div> : null 
                }
                <div className='row'>
                    <div className="d-flex align-items-center col-xs-12 col-sm-3 col-md-2 col-lg-2 col-xl-2" >
                        <div style={{width: '100px', height: '100px', backgroundColor: '#f1f1f1', borderRadius: '50%' }} >
                            <img style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: "center", borderRadius: '50%'}} src={!profileImage ? CompanyPlaceholder : profileImage} alt=""/>
                        </div>
                    </div>
                    <div className='d-flex flex-column justify-content-center col'>
                        <p className="lato-fs22-normal-lh32 font-bold mb-1">{corporateProfileData[0]?.subscribed_to?.org_name}</p>
                        {
                            !isEmpty(corporateProfileData[0]?.branch) ?
                            <div className="d-flex mt-2">
                                <FontAwesomeIcon className="primary-color-v2 me-2 mt-1" style={{fontSize: '20px'}} icon={faMapMarkerAlt} />
                                <div>
                                    {
                                        corporateProfileData[0]?.branch?.door_no || corporateProfileData[0]?.branch?.area ?
                                            <div>
                                                {
                                                    corporateProfileData[0]?.branch?.door_no ?
                                                        <span className="lato-fs17-normal-lh20" >{`${corporateProfileData[0]?.branch?.door_no}, `}</span>
                                                        : null
                                                }
                                                {
                                                    corporateProfileData[0]?.branch?.area ?
                                                        <span className="lato-fs17-normal-lh20" >{`${corporateProfileData[0]?.branch?.area},`}</span>
                                                        : null
                                                }
                                            </div> :
                                            null
                                    }
                                    {
                                        corporateProfileData[0]?.branch?.city ?
                                            <div>
                                                <span className="lato-fs17-normal-lh20">{`${corporateProfileData[0]?.branch?.city},`}</span>
                                            </div> :
                                            null
                                    }
                                    {
                                        corporateProfileData[0]?.branch?.state || corporateProfileData[0]?.branch?.pincode ?
                                            <div>
                                                {
                                                    corporateProfileData[0]?.branch?.state ?
                                                        <span className="lato-fs17-normal-lh20">{`${corporateProfileData[0]?.branch?.state} - `}</span>
                                                        : null
                                                }
                                                {
                                                    corporateProfileData[0]?.branch?.pincode ?
                                                        <span className="lato-fs17-normal-lh20">{corporateProfileData[0]?.branch?.pincode}</span>
                                                        : null
                                                }
                                            </div> :
                                            null
                                    }
                                </div>
                            </div> : null 
                        }
                    </div>
                </div>
                <hr className="small-hl" />
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                        <h5 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Access level:</h5>
                        <p className="mb-0 lato-fs17-normal-lh20">{corporateProfileData[0].role === CONTROLLER_ROLE ? "Controller" : "User"}</p>
                    </Grid>
                    {
                        !isEmpty(corporateProfileData[0].department) ?
                            <Grid item xs={6} sm={4}>
                                <h5 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Department:</h5>
                                <p className="mb-0 lato-fs17-normal-lh20">{corporateProfileData[0]?.department?.name}</p>
                            </Grid>
                            : null
                    }
                    {
                        !isEmpty(corporateProfileData[0].job_role) ?
                            <Grid item xs={6} sm={4}>
                                <h5 className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 pb-1">Job Role:</h5>
                                <p className="mb-0 lato-fs17-normal-lh20">{corporateProfileData[0]?.job_role}</p>
                            </Grid>
                            : null
                    }
                </Grid>
                <hr className="hl-long" />
                {
                    !isEmpty(corporateProfileData?.[0]?.assignees) || !isEmpty(corporateProfileData?.[0]?.super_reviewers) ?
                        <>
                            {
                                !isEmpty(corporateProfileData?.[0]?.super_reviewers) ?
                                    <div>
                                        <label className="lato-fs13-normal-lh18 tertiary-text-color-v2 mb-2">Super Reviewers</label>
                                        <div className="grid-column-2">
                                            { 
                                                corporateProfileData[0].super_reviewers.map((superReviewer, index) => (
                                                    <div className="d-flex align-items-center mb-3" key={index}>
                                                        <Avatar 
                                                            sx={{width: '50px', height: '50px'}} 
                                                            src={superReviewer.profile_pic}
                                                        />
                                                        <div className="ms-2">
                                                            { 
                                                                !isEmpty(superReviewer.person_name) ?
                                                                    <div>
                                                                        {superReviewer?.person_name?.first_name && <span className="lato-fs17-normal-lh20 mb-0">{superReviewer.person_name.first_name}</span>}
                                                                        {superReviewer?.person_name?.middle_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{superReviewer.person_name.middle_name}</span>}
                                                                        {superReviewer?.person_name?.last_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{superReviewer.person_name.last_name}</span>}
                                                                    </div> 
                                                                    : null
                                                            }
                                                            { 
                                                                !isEmpty(superReviewer.email) ?
                                                                    <div>
                                                                        {superReviewer?.email?.id && <span className="lato-fs15-normal-lh20 tertiary-text-color-v2 mb-0">{superReviewer.email.id}</span>}
                                                                    </div> 
                                                                    : null
                                                            }
                                                            <div>
                                                                <span 
                                                                    className='lato-fs14-normal-lh20 tertiary-text-color-v2 mb-0'
                                                                >
                                                                    {
                                                                        superReviewer?.user_subscriber_mapping?.[0]?.job_role ?
                                                                            `${superReviewer?.user_subscriber_mapping?.[0]?.job_role} (${superReviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"})` 
                                                                            : superReviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div> 
                                    </div> 
                                    : null 
                            }
                            {
                                !isEmpty(corporateProfileData?.[0]?.assignees) ?
                                    <div>
                                        <label className="mb-0 lato-fs13-normal-lh18 tertiary-text-color-v2 mb-1">Reviewer</label>
                                        <div className="grid-column-2">
                                            { 
                                                corporateProfileData[0].assignees.map((reviewer, index) => (
                                                    <div className="d-flex align-items-center mb-3" key={index}>
                                                        <Avatar 
                                                            sx={{width: '50px', height: '50px'}} 
                                                            src={reviewer.profile_pic}
                                                        />
                                                        <div className="ms-2">
                                                            { 
                                                                !isEmpty(reviewer.person_name) ?
                                                                    <div>
                                                                        {reviewer?.person_name?.first_name && <span className="lato-fs17-normal-lh20 mb-0">{reviewer.person_name.first_name}</span>}
                                                                        {reviewer?.person_name?.middle_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{reviewer.person_name.middle_name}</span>}
                                                                        {reviewer?.person_name?.last_name && <span className='lato-fs17-normal-lh20 mb-0 ms-1'>{reviewer.person_name.last_name}</span>}
                                                                    </div> 
                                                                    : null
                                                            }
                                                            { 
                                                                !isEmpty(reviewer.email) ?
                                                                    <div>
                                                                        {reviewer?.email?.id && <span className="lato-fs15-normal-lh20 tertiary-text-color-v2 mb-0">{reviewer.email.id}</span>}
                                                                    </div> 
                                                                    : null
                                                            }
                                                            <div>
                                                                <span className='lato-fs14-normal-lh20 tertiary-text-color-v2 mb-0'>
                                                                    {
                                                                        reviewer?.user_subscriber_mapping[0]?.job_role ?
                                                                            `${reviewer?.user_subscriber_mapping?.[0]?.job_role} (${reviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"})` 
                                                                            : reviewer?.user_subscriber_mapping?.[0]?.role === "user_cum_controller" ? "Controller" : "User"
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div> 
                                    </div> 
                                    : null 
                            }
                            <hr className="hl-long" />
                        </>
                        : null
                }   
            </div>
        )
    }

    return (
        !pageLoading.department && !pageLoading.branch ?
            !ownProps.editMode.inEdit ?
                getCorporateProfileDetails()
                : ownProps.editMode.component === "corporateProfileEdit" ?
                    getCorporateProfileDetailsForm()
                    : null
            : null
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        updateOrgProfileState : state.updateOrgProfileData,
        departmentState : state.departments,
        branchState : state.branches,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateOrgProfileData : (profileData) => dispatch(updateOrgProfileData(profileData)),
        clearOrgUpdateProfileState : () => dispatch(clearUpdateOrgProfileDataStates()),
        saveUpdatedProfileData : (profileData) => dispatch(saveProfileData(profileData)),
        requestDepartment : () => dispatch(fetchDepartments()),
        requestBranch : () => dispatch(fetchBranches())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateProfile)