import React from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const DialogBox = (props) => {

    const { onClose, open, mgs, cancelButtonEvent, acceptButtonEvent, rejectLabel, acceptLabel } = props

    return (
        <Dialog onClose={onClose} open={open}>
            <Box sx={{ padding:"20px"}}>
                {mgs}
                <div className='d-flex justify-content-center align-items-center mt-3'>
                    <CustomizeButton label={rejectLabel} variant="secondary-color-button" className="me-4" handleClick={cancelButtonEvent} />
                    <CustomizeButton label={acceptLabel} variant="primary-color-button" handleClick={acceptButtonEvent} />
                </div>
            </Box>
        </Dialog>
    )
}

export default DialogBox