import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router'

import CustomizeLoadingButton from '../../common/loading-button/CustomizeLoadingButton'
import PlanCard from '../../components/plan-card/PlanCard'
import { requestBasicInfoPromise , requestCreateOrderPromise, requestPlan } from '../../services/constants.service'
import { updateSubscription } from '../../services/IndividualAuthentication.service'
import { getAuthToken } from '../../utils/utils'
import { CONTROLLER_ROLE, INDIVIDUAL_ROLE, USER_ROLE } from '../../utils/constants'
import SelectBoxValueById from '../../common/select-box-value-id/SelectBoxValueById'
import Container from '../../components/desktop-layout/container/Container'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import './update-subscription.css'

const UpdateSubscription = () => {

    const [ plans, setPlans ] = useState([])
    const [ userInfo, setUserInfo ] = useState({})
    const [ selectedPlan, setSelectedPlan ] = useState({
        value : "",
        index : null,
        disabled : false,
    })
    const [hasError, setHasErrors] = useState(false)

    const history = useHistory()

    function getPlanAccordingToRole(userInfo){
        if(!isEmpty(userInfo)){
            if(!isEmpty(userInfo.user_subscriber_mapping)){
                if(userInfo.user_subscriber_mapping.role === INDIVIDUAL_ROLE ){
                    requestPlan("open_org")
                    .then((res) => {
                        setPlans(res.data?.plans)
                    }).catch((err) => {
                        console.log(err)
                        if( err && !isEmpty(err.response) && !isEmpty(err.response.data)){
                            toast.error(err.response.data.error, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    })
                }else if(userInfo.user_subscriber_mapping.role === CONTROLLER_ROLE ){
                    requestPlan("closed_org")
                    .then((res) => {
                        setPlans(res.data?.plans)
                    }).catch((err) => {
                        console.log(err)
                        if( err && !isEmpty(err.response) && !isEmpty(err.response.data)){
                            toast.error(err.response.data.error, {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                    })
                }else{
                    if(userInfo.gig && userInfo.user_subscriber_mapping.role === USER_ROLE ){
                        return
                    }else if(userInfo.gig && userInfo.user_subscriber_mapping.role === "individual") {
                        requestPlan("open_org")
                        .then((res) => {
                            setPlans(res.data?.plans)
                        }).catch((err) => {
                            console.log(err)
                            if( err && !isEmpty(err.response) && !isEmpty(err.response.data)){
                                toast.error(err.response.data.error, {
                                    position: "bottom-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                })
                            }
                        })
                    }else{
                        return
                    }
                }
            }
        }
    }

    useEffect(() => {
        requestBasicInfoPromise()
        .then((res) => {
            setUserInfo(res.data)
            getPlanAccordingToRole(res.data)
        }).catch((error) => {
            console.log(error)
            if(error && !isEmpty(error.response)){
                if(error.response.data){
                    toast.error(error.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        })
        
    },[])

    function handleSubscribeEvent(){
        if(userInfo.user_subscriber_mapping.role === INDIVIDUAL_ROLE){
            requestCreateOrderPromise(plans[0]._id)
            .then((res) => {
                displayRazorpay(res.data)
            }).catch((err) => {
                console.log(err)
                if( err && !isEmpty(err.response) && !isEmpty(err.response.data)){
                    toast.error(err.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
        }else if(userInfo.user_subscriber_mapping.role === CONTROLLER_ROLE){
            if(selectedPlan.index !== null && selectedPlan.index !== undefined ){
                requestCreateOrderPromise(plans[selectedPlan.index]._id)
                .then((res) => {
                    displayRazorpay(res.data)
                }).catch((err) => {
                    console.log(err)
                    if( err && !isEmpty(err.response) && !isEmpty(err.response.data)){
                        toast.error(err.response.data.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                })
            } else {
                setHasErrors(true)
            }
        }
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(payload) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            toast.error("Razorpay SDK failed to load. Are you online?", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const options = {
            key: payload.key,
            order_id: payload.order.id ,
            name: "HR App",
            amount: payload.order?.amount, 
            currency: payload.order?.currency,
            // description: "Monthly Test Plan",
            // image: "/your_logo.png",
            // payment response
            handler: function(res) {
                updateSubscription(payload.order.id, res, getAuthToken())
                .then((res) => {
                    history.push("/vcf?mode=list-vcfs-by-lvl&lvl=0")
                })
            },
            prefill: {
                name: `${userInfo.user?.person_name?.first_name} ${userInfo.user?.person_name?.last_name}`,
                email: `${userInfo.user?.email?.id}`,
                contact: ""
            },
            // notes: {
            //     note_key_1: "Tea. Earl Grey. Hot",
            //     note_key_2: "Make it so."
            // },
            theme: {
                color: "#14213d"
            }
        }

        const paymentObject = new window.Razorpay(options);
        // payment error
        // paymentObject.on("payment.failed",function(error){
        //     console.log("error",error)
        // })
        paymentObject.open();
    }

    function showPlans(){
        if(!isEmpty(userInfo)){
            if(userInfo.user_subscriber_mapping.role === "individual"){
                return (
                    <>
                        <h4 className="lato-fs24-bold-lh32 mt-5">Subscribe to continue</h4>
                        { 
                            !isEmpty(plans) ?
                            <PlanCard
                                containerClassName="mt-3"
                                name={plans[0].plan_info?.item?.name}
                                currency={plans[0].plan_info?.item?.currency === "INR" ? "₹" : "$"}
                                amount={plans[0].plan_info?.item?.amount}
                                period={`/${plans[0].plan_info?.period}`}
                                description={plans[0].plan_info?.item?.description}
                                notes={plans[0].plan_info?.notes}
                            /> : null
                        }
                        <div className="mt-4">
                            <CustomizeLoadingButton 
                                variant="accept-loading-button-v2" 
                                label="Subscribe" 
                                onClick={handleSubscribeEvent}
                            />
                        </div>
                    </>
                )   
            }else if(userInfo.user_subscriber_mapping.role === CONTROLLER_ROLE){
                return (
                    <>
                        <h4 className="lato-fs24-bold-lh32 mt-5">Subscribe to continue</h4>
                        <div className='mt-3'>
                            <SelectBoxValueById
                                options={getUserSlabArray(plans)} 
                                label="UserSlab"
                                onChange={(e,child) => {
                                    setSelectedPlan({ value : e.target.value, index: child.props.id })
                                    if(hasError) setHasErrors(false)
                                }} 
                                name="plan" 
                                value={selectedPlan.value} 
                            />
                        </div>
                        {
                            hasError ?
                            <div className="ms-1">
                                <span className="error-message">Please select a user slab</span>
                            </div>: null
                        }
                        { 
                            selectedPlan.value  ?
                            <PlanCard
                                containerClassName="mt-3"
                                name={plans[selectedPlan.index]?.plan_info?.item?.name}
                                currency={plans[selectedPlan.index]?.plan_info?.item?.currency === "INR" ? "₹" : "$"}
                                amount={plans[selectedPlan.index]?.plan_info?.item?.amount * plans[selectedPlan.index].max}
                                period={`/${plans[selectedPlan.index]?.plan_info?.period}`}
                                description={plans[selectedPlan.index]?.plan_info?.item?.description}
                                notes={plans[selectedPlan.index]?.plan_info?.notes}
                            />
                            : null
                        }
                        <div className="mt-4">
                            <CustomizeLoadingButton 
                                variant="accept-loading-button-v2" 
                                label="Subscribe" 
                                onClick={handleSubscribeEvent}
                            />
                        </div>
                    </>
                )
            } else if(userInfo.user_subscriber_mapping.role === USER_ROLE ) {
                if(userInfo.gig && !userInfo.gig_has_active_subscription) {
                    return(
                        <div className="px-3 mt-5">
                            <p className="lato-fs-20-normal-lh32 font-bold text-center">Your Individual account has no active subscription. Logging into your individual account and subscribe to continue.</p>
                        </div> 
                    )
                } else {
                    return (
                        <div className="px-3 mt-5">
                            <p className="lato-fs-20-normal-lh32 font-bold text-center">Your Organisation has no active subscription.</p>
                        </div>
                    )
                }
            }
        }
    }

    function getUserSlabArray(plan){
        let userSlabArray = []
        for( let slab of plan){
            userSlabArray.push({
                label : `${slab.min} to ${slab.max}`,
                value : slab._id,
                disabled : userInfo.active_users > slab.max ? true : false
            })
        }
        return userSlabArray
    }
    
    return (
        <Container>
            <Navbar enableLogout={true} />
            <div className='update-subscribe-container w-100 max-width-385px px-3'>
                {showPlans()}
            </div>
        </Container>
    )
}

export default UpdateSubscription