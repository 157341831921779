import React from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import './date-picker.css'

const StyledDatePicker = styled(TextField)({
    // input label when focused
    "& label.Mui-focused": {
        color: "#14213d !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
        borderColor: "#14213d !important"
        }
    }
})

const DatePicker = (props) => {

    return (
        <div>
            <StyledDatePicker type={props.type} 
                    id="date"
                    variant="outlined"
                    label={props.label}
                    name={props.name}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    required={props.required}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    InputProps={{
                        inputProps : {
                            min: props.min,
                            max: props.max
                        },
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    autoComplete="off"  /> 
            {props.errorMgs ? 
                <div className="ms-1">
                    <span className="error-message">{props.errorMgs}</span>
                </div>: null}
        </div>
    )
}

export default DatePicker