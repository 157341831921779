import { useFormik } from 'formik'
import React,{ useEffect, useState, useCallback } from 'react'
import './sign-in.css'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Card, CardContent } from '@mui/material'

import InputBox from '../../../common/Input-box/InputBox'
import { individualSignin,forgetPassword } from '../../../services/IndividualAuthentication.service'
import { clearAuthStates } from '../../../redux/authentication/AuthenticationAction'
import Footer from '../../../components/footer/Footer'
import PasswordInput from '../../../common/password-input/PasswordInput'
import Container from '../../../components/desktop-layout/container/Container'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import Just4Logo from '../../../assets/icons/Just_4_logo_blue.svg'
import PublicNavbar from '../../home-page/public-navbar/PublicNavbar'

const SignIn = (props) => {

    const { authenticationIndividual, requestSignInstate, clearState} = props
    const [showPassword, setShowPassword] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const initialValues = {
        email: "",
        password: "",
    }

    const onSubmit= (values) => {
        if(navigator.onLine){
            authenticationIndividual(values)
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }) 
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(ps => !ps)
    }

    const toggleDrawerCallback = useCallback(toggleDrawer, [])

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const validationSchema = Yup.object({
        email: Yup.string().required("Please enter your email").email("Invalid email"),
        password : Yup.string()
                    .required('Please enter your password')
    })

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    useEffect(() => {
        if(!isEmpty(requestSignInstate.response)){
            if(requestSignInstate.response){
                if(requestSignInstate.response.gig === true){
                    history.push(`/sign-in/gig-user`)
                }else{
                    clearState();
                    history.push('/vcf?mode=list-vcfs-by-lvl&lvl=0')
                }
            }
        }
        if(!isEmpty(requestSignInstate.error)){
            if(requestSignInstate.error){
                if(requestSignInstate.error.data){
                    toast.error(requestSignInstate.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[requestSignInstate])

    async function handleForgetPassword(email){
        try{
            const response = await forgetPassword({email : email})
            if(response.data){
                toast.success(response.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
        }catch(error){
            if(!isEmpty(error) && !isEmpty(error.response)){
                toast.error(error.response.data.error, {
                    position:"bottom-right",
                    autoClose:3000,
                    hideProgressBar:false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                })
            }
        }
    }

    return ( 
        <Container>
            {/* <Navbar enableSignUp={true}/> */}
            <PublicNavbar 
                enableHome={true}
                enableProduct={true}
                enableConsult={true}
                enableAboutUs={true}
                logo={true}
                enableSubscribe={true}
                variant={isDesktop ? "" : "mobile" }
                enableDrawer={true}
                drawerOpened={drawer}
                handleDrawerClose={toggleDrawerCallback}
                onDrawerClick={toggleDrawerCallback}
                drawerHome={true}
            />
            <div className="sign-in-container bg-tertiary-color-v2">
                {/* <div className='d-flex justify-content-center align-items-center mt-4'>
                    <img src={Just4Logo} style={{width:"200px"}}/>
                </div> */}
                <div className='sign-in-card-container' style={{marginBottom:"40px", marginTop:"40px"}}>
                    <Card sx={{maxWidth:"500px",marginLeft:"auto", marginRight:"auto"}}>
                        <CardContent sx={{"&:last-child" : {paddingBottom:"34px"}, padding:"26px 28px"}} >
                            <h1 className="lato-fs26-bold-lh32 font-bold text-center mb-4">Sign in</h1>
                            <form onSubmit={formik.handleSubmit}>
                                <InputBox type="email" 
                                    label="Email Id"
                                    name="email" 
                                    placeholder="Enter your email Id" 
                                    value={formik.values.email}
                                    onChange = {formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    errorMgs={formik.touched.email && formik.errors.email} 
                                    />
                                <div className="mt-3">
                                    <PasswordInput 
                                        label="Password"
                                        required={true}
                                        name="password" 
                                        placeholder="Enter your password"
                                        showPassword={showPassword}
                                        handleClickShowPassword={handleClickShowPassword}
                                        value={formik.values.password} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        errorMgs={formik.touched.password && formik.errors.password ? formik.errors.password : ""} />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <p className=" mt-2 lato-fs12-normal-lh15 primary-text-color align-end pt-cursor" onClick={() => {
                                        formik.setFieldTouched('email')
                                        if(!formik.errors.email && formik.values.email){
                                            handleForgetPassword(formik.values.email)
                                        }
                                    }}>Forgot password?</p>
                                </div>
                                <div className='d-flex mt-3 justify-content-center'>
                                    <CustomizeButton variant="primary-color-button" label="Sign in" type="submit" />
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
            {/* <Footer/> */}
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        requestSignInstate : state.individualAuthentication
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticationIndividual : (signinDetails) => dispatch(individualSignin(signinDetails)),
        clearState : () => dispatch(clearAuthStates())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SignIn)