import { 
    FETCH_PROFILE_DATA, 
    PROFILE_DATA_FETCH_SUCCESS, 
    PROFILE_DATA_FETCH_FAILURE, 
    CLEAR_FETCHED_PROFILE_DATA,
    SAVE_PROFILE_DATA
} from './ProfileTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const fetchProfileDataReducer = ( state = initialState, action ) => {
    switch(action.type){
        case FETCH_PROFILE_DATA:
            return {
                ...state,
                loading: true,
                error : null
            }

        case PROFILE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case PROFILE_DATA_FETCH_FAILURE:
            return { 
                ...state,
                loading: false,
                error: action.payload
            }

        case SAVE_PROFILE_DATA:
            return { 
                ...state,
                response : {
                    ...state.response,
                    profile : action.payload
                }
            }
        
        case CLEAR_FETCHED_PROFILE_DATA :
            return {
                ...state,
                loading: false,
                response: {},
                error: null
            }

        default : 
            return state
    }
}

export default fetchProfileDataReducer