import { REQUEST_LIST_COURSES,
         LIST_COURSES_SUCCESS,  
         LIST_COURSES_FAILURE,
         CLEAR_LIST_COURSES } from './ListCoursesTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const listOfCoursesReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_LIST_COURSES : 
            return {
                ...state,
                loading: true,
                error: null
            }
        
        case LIST_COURSES_SUCCESS : 
            return {
                ...state,
                loading: false,
                response : action.payload
            }

        case LIST_COURSES_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_LIST_COURSES : 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }
        
        default : 
            return state
    }
}

export default listOfCoursesReducer