import { REQUEST_CREATE_METRIC,
    CREATE_METRIC_SUCCES,
    CREATE_METRIC_FAILURE,
    CLEAR_CREATE_METRICS } from './CreateMetricTypes'

const initialState = {
    loading : false,
    response : {},
    error: null
}

const metricReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_CREATE_METRIC :
            return {
                ...state,
                loading:true,
                error:null
            }

        case CREATE_METRIC_SUCCES : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }
        
        case CREATE_METRIC_FAILURE: 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_CREATE_METRICS : 
            return {
                loading : false,
                response : {},
                error: null
            }

        default : 
            return state
    }
}

export default metricReducer