import { REQUEST_CREATE_METRIC,
         CREATE_METRIC_SUCCES,
         CREATE_METRIC_FAILURE,
         CLEAR_CREATE_METRICS } from './CreateMetricTypes'

export const requestCreateMetric = () => {
    return {
        type : REQUEST_CREATE_METRIC
    }
}

export const createMetricSuccess = (resData) => {
    return {
        type : CREATE_METRIC_SUCCES ,
        payload: resData
    }
} 

export const createMetricFailure = (error) => {
    return {
        type : CREATE_METRIC_FAILURE,
        payload : error
    }
}

export const clearCreateMetrics = () => {
    return {
        type : CLEAR_CREATE_METRICS 
    }
}