import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory, useLocation } from 'react-router'
import moment from 'moment'
import { toast } from 'react-toastify'
import qs from 'query-string'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import NotificationCard from '../../../common/notification-card/NotificationCard'
import { fetchInboxActions } from '../../../services/notification.service'
import { clearNotificationDetailState, fetchNotificationDetailSuccess } from '../../../redux/notification/notification-detail/notificationDetail.action'
import { clearFetchInboxAction } from '../../../redux/notification/inbox/inbox.action'
import { boldString } from '../../../utils/utils'

const Inbox = (props) => {

    const { fetchInboxActionState, 
            fetchInboxActions, 
            ownProps, 
            saveInboxAction, 
            clearInboxActionState,
            clearNotificationDetailState,
            notificationDetailState } = props

    const [pageLoading, setPageLoading] = useState(true)

    const inboxActions = fetchInboxActionState.response.received_mails

    const history= useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    function handleCardClick(index,inbox){
        saveInboxAction({mail : inbox})
        history.push(`/notification/${inbox._id}/inbox?action=${ownProps.action}`)
    }

    useEffect(() => {
        fetchInboxActions(ownProps.action)

        if(!isEmpty(notificationDetailState.response)){
            clearNotificationDetailState()
        }
        return () => {
            clearInboxActionState()
        }

    },[])

    useEffect(() => {
        if(!fetchInboxActionState.loading){
            fetchInboxActions(ownProps.action)
        }
        
    },[queryParams.action])

    useEffect(() => {
        if(!fetchInboxActionState.loading && !isEmpty(fetchInboxActionState.response)){
            setPageLoading(false)
        }

        if(!fetchInboxActionState.loading && !isEmpty(fetchInboxActionState.error)){
            if(fetchInboxActionState.error){
                if(fetchInboxActionState.error.data){
                    toast.error(fetchInboxActionState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }

    },[fetchInboxActionState])

    function getAppropriateCards(){
        if(props.searchedValue.value){
            if(!props.searchedValue.typing){
                if(!isEmpty(props.searchedValue.data)){
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            {
                                props.searchedValue.data.map((inboxAction,index) => (
                                    <NotificationCard 
                                        key={inboxAction._id}
                                        onClick={() => handleCardClick(index,inboxAction)}
                                        subject={boldString(inboxAction.subject, props.searchedValue.value)}
                                        message={inboxAction.message}
                                        person={`${inboxAction.from.user.person_name.first_name}  ${inboxAction.from.user.person_name.last_name}`}
                                        time={moment(inboxAction.createdAt).format('Do MMM, YYYY')}
                                    />
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            <p className='lato-fs15-normal-lh20'>
                                No mail found for search results "
                                <span className='lato-fs15-normal-lh20 font-bold'>
                                    {props.searchedValue.value}
                                </span>
                                "
                            </p>
                        </>
                    )
                }
            } else {
                return (
                    <Box className="d-flex justify-content-center align-items-center">
                        <CircularProgress sx={{color: 'var(--primary-color-v2)'}} />
                    </Box>
                )
            }
        } else {
            if(!pageLoading){
                if(!isEmpty(inboxActions)){
                    return (
                        <>
                            {
                                inboxActions.map((inboxAction,index) => (
                                    <NotificationCard
                                        key={inboxAction._id}
                                        onClick={() => handleCardClick(index,inboxAction)}
                                        subject={inboxAction.subject}
                                        message={inboxAction.message}
                                        person={`${inboxAction.from.user.person_name.first_name}  ${inboxAction.from.user.person_name.last_name}`}
                                        time={moment(inboxAction.createdAt).format('Do MMM, YYYY')}
                                    />
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <div className="d-flex justify-content-center">
                            <h6 className="lato-fs20-normal-lh28 mb-0 tertiary-text-color-v2">No received mails</h6>
                        </div>
                    )
                }
            } else {
                return null
            }
        }
    }

    return (
        isDesktop ?
            <div className='pt-4 pb-3'>
                {getAppropriateCards()}
            </div>
            : <div className='pt-3 pb-70'>
                {getAppropriateCards()}
            </div>
    )
}

const mapStateToProps = ( state, ownProps ) =>{
    return {
        fetchInboxActionState : state.fetchInboxActions,
        ownProps : ownProps,
        notificationDetailState : state.notificationDetail,
    } 
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchInboxActions : (action) => dispatch(fetchInboxActions(action)),
        saveInboxAction : (response) => dispatch(fetchNotificationDetailSuccess(response)),
        clearInboxActionState : () => dispatch(clearFetchInboxAction()),
        clearNotificationDetailState : () => dispatch(clearNotificationDetailState()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Inbox)