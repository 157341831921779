import { REQUEST_CU_COURSE, 
         CU_COURSE_SUCCESS,
         CU_COURSE_FAILURE, 
         CLEAR_CU_COURSE} from './CreateEditTypes'

export const requestCuCourse = () => {
    return {
        type : REQUEST_CU_COURSE
    }
}

export const cuCourseSuccess = (response) => {
    return {
        type : CU_COURSE_SUCCESS,
        payload : response
    }
}

export const cuCourseFailure = (error) => {
    return {
        type : CU_COURSE_FAILURE,
        payload : error
    }
}

export const clearCuCourse = () => {
    return {
        type : CLEAR_CU_COURSE
    }
}