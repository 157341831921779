import React,{ useState, useEffect } from 'react'
import PopUp from '../../common/popup/PopUp'
import { isEmpty } from 'lodash'
import { maxLevelObjective } from '../../services/notification.service'
import { clearChoosePathObjective } from '../../redux/notification/choose-path/ChoosePathObjective.action'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { deepSearch } from '../../utils/utils'
import { useFormik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const ChoosePathPopup = (props) => {

    const {requestMaxLevelObjective, maxLevelObjectiveState, ownProps, clearObjectives} = props
    const [loading, setLoading] = useState(true)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const objectives = maxLevelObjectiveState.response.objectives

    const formik = useFormik({
        initialValues : {
            objectiveId : ''
        },
        validationSchema : yup.object().shape({
            objectiveId : yup.string().required('Please enter the objective')
        }),
        onSubmit : values => {
            const appropriateObj = deepSearch(objectives, '_id', (k, v) => v === values.objectiveId)
            ownProps.onProceed(appropriateObj)
            ownProps.handleClose()
        }
    })

    useEffect(() =>{
        requestMaxLevelObjective(ownProps.maxLevel())
        return () => {
            clearObjectives()
        }
    },[])


    useEffect(() => {
        if(!maxLevelObjectiveState.loading && !isEmpty(maxLevelObjectiveState.response)){
            setLoading(false)
        }

        if(!maxLevelObjectiveState.loading && !isEmpty(maxLevelObjectiveState.error)){
            if(!isEmpty(maxLevelObjectiveState.error.data)){
                console.log(maxLevelObjectiveState.error.data.error)
            }
        }
    },[maxLevelObjectiveState])

    function objectiveList(objectives) {

        let objectiveOptions = []
        
        objectives.forEach((objective,index) => {
            objectiveOptions.push(<option key={index} value={objective._id}>{objective.name}</option>)  // level 0
            if(!isEmpty(objective.sub_objectives)){
                objective.sub_objectives.forEach((subObjOne) => {
                    objectiveOptions.push(<option key={subObjOne._id} value={subObjOne._id}>&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjOne.name}`}</option>) // level 1
                    if(!isEmpty(subObjOne.sub_objectives)){
                        subObjOne.sub_objectives.forEach(subObjTwo => {
                            objectiveOptions.push(<option key={subObjTwo._id} value={subObjTwo._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjTwo.name}`}</option>)  // level 2
                            if(!isEmpty(subObjTwo.sub_objectives)){
                                subObjTwo.sub_objectives.forEach(subObjThree => {
                                    objectiveOptions.push(<option key={subObjThree._id} value={subObjThree._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjThree.name}`}</option>)  // level 3
                                })
                            }
                        })
                    }
                })
            }
        })

        return objectiveOptions
    }

    return (
        <PopUp handleClose={props.handleClose} open={props.open}>
            {
                !loading ?
                    <div className={isDesktop ? 'p-3 min-width-300px' : 'p-3 min-width-250px'}>
                        <form onSubmit={formik.handleSubmit}>
                            <h6 className="text-center lato-fs22-normal-lh32 font-bold primary-text-color pb-2">Choose objective</h6>
                            <select name="objective" className="grey-select-box" onChange={formik.handleChange} name='objectiveId' value={formik.values.objectiveId}>
                                <option value="" hidden>Select your Objective</option>
                                {objectiveList(objectives)}
                            </select>
                            <div className="mt-3 d-flex justify-content-center">
                                <CustomizeButton type="Submit" variant="primary-color-button" label="Proceed" />
                            </div>
                        </form>
                    </div> : null
            }
        </PopUp>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        maxLevelObjectiveState : state.choosePathObjective,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestMaxLevelObjective : (maxLevel) => dispatch(maxLevelObjective(maxLevel)),
        clearObjectives : () => dispatch(clearChoosePathObjective())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ChoosePathPopup)