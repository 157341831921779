import { REQUEST_INVITED_MEMBERS,
        SAVE_INVITED_MEMBERS,
        INVITED_MEMBERS_FAILURE,
        CLEAR_INVITED_MEMBER_STATE } from './invitedMembers.types'

export const requestInvitedMembers = () => {
    return {
        type : REQUEST_INVITED_MEMBERS
    }
}

export const fetchedInvitedMemberSuccess = (response) => {
    return {
        type : SAVE_INVITED_MEMBERS,
        payload : response
    }
}

export const fetchedInvitedMemberFailure = (error) => {
    return {
        type : INVITED_MEMBERS_FAILURE,
        payload : error
    }
}

export const clearInvitedMemberState = () => {
    return {
        type : CLEAR_INVITED_MEMBER_STATE
    }
}