import React,{ useRef, useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'

import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import { createLearningArea, fetchLearningCircleLearningAreas, specificLearningArea, updateLearningArea } from '../../../services/learning.service'
import './create-or-edit-learning.css'
import { fetchLearningSuccess } from '../../../redux/learning/fetch-learning/FetchLearningAction'
import { formatDate } from '../../../utils/utils'
import { clearCULearning } from '../../../redux/learning/create-edit-learning/CULearingAction'
import DatePicker from '../../../common/date-picker/DatePicker'
import { specificLearningAreaSuccess } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import Container from '../../../components/desktop-layout/container/Container'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Header from '../../../common/header/Header'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessIconEdit from '../../../components/process-icon-edit/ProcessIconEdit'

const LEARNING_ATTACHMENT_NAMES = {
    data: 'learning_area_data',
    icon: 'learning_area_icon'
}

const CreateOrEditLearning = (props) => {

    const { 
        CuLearningState,
        createLearning,
        saveLearning,
        clearCULearningState,
        updateLearningArea,
        requestSpecificLearningArea,
        specificLearningAreaState,
        saveSpecificLeaningArea
    } = props

    const [iconImageFile,setIconImageFile] = useState('')
    const [iconPreviewImage, setIconPreviewImage] = useState('')
    const [isReset,setIsReset] = useState(false)
    const [pageLoading,setPageLoading] = useState(true)
    const [loading,setLoading] = useState(false)
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)
    const [iconAnchorEl, setIconAnchorEl] = useState(null)
    
    const history = useHistory()
    const iconImageRef = useRef()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const action = location.pathname.split('/')[2]
    const isDesktop = useMediaQuery('(min-width:992px)')

    const learningArea = specificLearningAreaState.response.learning_area

    const initialValues = {
        name: action == "edit" && !isEmpty(learningArea) && learningArea.name ? (!isReset ? learningArea.name : '') : "",
        description: action == "edit" && !isEmpty(learningArea) && learningArea.description ? ( !isReset ? learningArea.description : '' ) : "",
        start_date: action === "edit" && !isEmpty(learningArea) && learningArea.start_date ? (!isReset ? formatDate(learningArea.start_date) : "") :  moment().format("YYYY-MM-DD"),
        end_date:action === "edit" && !isEmpty(learningArea) && learningArea.end_date ? (!isReset ? formatDate(learningArea.end_date) : "") : "",
    }

    const onSubmit = (values) =>{
        const valuesCopy = JSON.parse(JSON.stringify(values))
        valuesCopy.start_date = (moment(valuesCopy.start_date).startOf("day")).toISOString()
        valuesCopy.end_date = (moment(valuesCopy.end_date).endOf("day")).toISOString()
        setLoading(true)
        if(navigator.onLine){
            if(action === "edit"){
                if(!iconPreviewImage && specificLearningAreaState?.response?.learning_area?.icon) {
                    valuesCopy.icon = ''
                }
                updateLearningArea(addFormData(valuesCopy),queryParams.segmentId)
            }else{ 
                createLearning(addFormData(valuesCopy),queryParams.circleId,queryParams.segment)
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("Please enter the learning area name"),
        description: Yup.string(),
        start_date: Yup.date().required("Please select the start date"),
        end_date: Yup.date()
                    .required("Please select the end date")
                    .min(Yup.ref('start_date'), "End date can't be before start date")
                    .test('check for past dates', "End date can't be in past", (value) => {
                        if(value){
                            return (moment(value).endOf("day")).isSameOrAfter(moment())
                        } else {
                            return false
                        }
                    })
    })

    function addFormData(objectiveData){
        let objectiveFormData = new FormData()
        objectiveFormData.append(LEARNING_ATTACHMENT_NAMES.data, JSON.stringify(objectiveData))
        if(iconImageFile){
            objectiveFormData.append(LEARNING_ATTACHMENT_NAMES.icon, iconImageFile)
        }
        return objectiveFormData
    }

    function handleFormReset(resetForm){
        setIsReset(true)
        resetForm()
        setIconImageFile('')
        setIconPreviewImage('')
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    useEffect(() =>{
        if(iconImageFile){
            const reader = new FileReader()
            reader.onloadend = () => {
                setIconPreviewImage(reader.result)
            }
            reader.readAsDataURL(iconImageFile)
        }else{
            setIconPreviewImage(null)
        }
    },[iconImageFile])

    useEffect(() => {
        if(!CuLearningState.loading && !isEmpty(CuLearningState.response)){
            if(action === "edit"){
                saveSpecificLeaningArea(CuLearningState.response)
            }else{
                saveLearning(CuLearningState.response)
            }
            setLoading(false)
            toast.success(action == "edit" ? " Learning area updated successfully ": "Learning area created successfully",{
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.goBack()
        }
        if(!CuLearningState.loading && !isEmpty(CuLearningState.error)){
            console.log(CuLearningState.error)
            setLoading(false)
            if(!isEmpty(CuLearningState.error)){
                if(CuLearningState.error.data){
                    toast.error( CuLearningState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }
    },[CuLearningState])

    useEffect(() => {
        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.response)){
            setIconPreviewImage(specificLearningAreaState.response.learning_area.icon ? specificLearningAreaState.response.learning_area.icon : "")
            setPageLoading(false)
        }

        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.error)){
            // console.log(specificLearningAreaState.error)
            setPageLoading(false)
            if(specificLearningAreaState.error){
                if(specificLearningAreaState.error.data){
                    toast.error(specificLearningAreaState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }

    },[specificLearningAreaState])

    useEffect(() => {
        if(action == "edit"){
            if(isEmpty(specificLearningAreaState.response)){
                requestSpecificLearningArea(queryParams.segmentId)
            }
        }else{
            setPageLoading(false)
        }
        
        return () =>{
            clearCULearningState()
        }

    },[])

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    const iconMenuOpen = Boolean(iconAnchorEl)

    const handleEditIconClick = (event) => {
        setIconAnchorEl(event.currentTarget)
    }

    const handleIconMenuClose = () => {
        setIconAnchorEl(null)
    }

    const onRemoveIconClick = () => {
        if(iconImageFile) setIconImageFile('')
        if(iconPreviewImage) setIconPreviewImage('')
    }

    function getForm(){
        return (
            <Formik 
                enableReinitialize={true} 
                initialValues={initialValues}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={onSubmit} >
                {props => {
                    const { values, touched, errors, handleChange, resetForm, handleBlur } = props
                    return (
                        <>
                            <Form>
                                { 
                                    isDesktop ? 
                                        null 
                                        : <div className="mt-4 pt-1">
                                            <Grid container>
                                                <Grid item sm={6} xs={12}>
                                                    <InputBox type="text" 
                                                        name="name"
                                                        label="Name"
                                                        placeholder="Enter your learning area name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errorMgs={touched.name && errors.name ? errors.name : "" }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                }
                                {  
                                    isDesktop ? 
                                        <Grid container sx={{marginTop:"1.5rem"}}>
                                            <Grid item md={4} sx={{paddingRight:{xs:0, md:"10px"}}}>
                                                <InputBox type="text" 
                                                    name="name"
                                                    label="Name"
                                                    placeholder="Enter the learning area name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.name && errors.name ? errors.name : "" }
                                                /> 
                                            </Grid>
                                            <Grid item md={4}sx={{paddingLeft: {xs:0, md:"10px"}, paddingRight:{xs:0, md:"10px"}}}>
                                                <DatePicker type="date" 
                                                    label="Start Date"
                                                    name="start_date"
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.start_date && errors.start_date ? errors.start_date : "" }
                                                />
                                            </Grid>
                                            <Grid item md={4} sx={{paddingLeft: {xs:0 , md:"10px"}}}>
                                                <DatePicker type="date" 
                                                    label="End Date"
                                                    name="end_date"
                                                    min={moment().format("YYYY-MM-DD")}
                                                    value={values.end_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.end_date && errors.end_date ? errors.end_date : "" }
                                                />
                                            </Grid>
                                        </Grid> 
                                        : null 
                                }
                                <div className="mt-3">
                                    <TextAreaBox name="description" 
                                        label="Description"
                                        placeholder = "Enter the learning area description"
                                        value = {values.description}
                                        rows={isDesktop ? 4 : ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errormgs={touched.description && errors.description ? errors.description : "" }
                                    />
                                </div>
                                {
                                    isDesktop ? 
                                        null 
                                        : <Grid container sx={{marginTop:"5px"}} spacing={2}>
                                            <Grid item sm={6} xs={12}>
                                                <DatePicker type="date" 
                                                    label="Start Date"
                                                    name="start_date"
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.start_date && errors.start_date ? errors.start_date : "" }
                                                />
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <DatePicker type="date" 
                                                    label="End Date"
                                                    name="end_date"
                                                    min={moment().format("YYYY-MM-DD")}
                                                    value={values.end_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.end_date && errors.end_date ? errors.end_date : "" }
                                                />
                                            </Grid>
                                        </Grid>
                                }
                                <div className="d-flex justify-content-center align-center mt-4 pb-2">
                                    <CustomizeButton 
                                        variant="secondary-color-button" 
                                        type="reset" 
                                        handleClick={() => handleFormReset(resetForm)}
                                        label="Clear All" 
                                        className={isDesktop ? "me-5" : "me-4"}
                                    />
                                    <CustomizeLoadingButton 
                                        variant="accept-loading-button-v2"
                                        label={action === "edit" ? "Update Learning Area" : "Create Learning Area"} 
                                        type="submit" 
                                        loading={loading}
                                    />
                                </div>
                            </Form>
                        </>
                    )
                }}
            </Formik>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableMore={true} 
                            enableLogout={true} 
                            enableProfileButton={true}
                            moreRight="72px" 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer>
                                {!pageLoading ? 
                                    <>
                                        <div className='mt-3'>
                                            <h5 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>{action === "edit" ? "Update Learning Area" : "Create Learning Area"}</h5>
                                        </div>
                                        <div className='position-relative '>
                                            <ProcessIconEdit
                                                containerClassName="ms-0 mt-3"
                                                ref={iconImageRef}
                                                onRemoveClick={() => {
                                                    onRemoveIconClick()
                                                    handleIconMenuClose()
                                                }}
                                                onUploadClick={(event) => {
                                                    event.preventDefault();
                                                    iconImageRef.current.click();
                                                    handleIconMenuClose()
                                                }}
                                                handleMenuClose={handleIconMenuClose}
                                                menuOpen={iconMenuOpen}
                                                anchorEl={iconAnchorEl}
                                                previewImage={iconPreviewImage}
                                                handleEditIconClick={handleEditIconClick}
                                                onInputChange={(event) =>{
                                                    const file = event.target.files[0];
                                                    if(file && file.type.substr(0,5) === "image"){
                                                        setIconImageFile(file)
                                                    }
                                                }}
                                            />
                                            <div className='section-name-desktop'>
                                                <p className='mb-0'>{queryParams.segment_name}{queryParams.segment}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {getForm()}
                                        </div>
                                    </> : null
                                }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={() => history.push(`/notification-reference?selected=notification&tab=inbox&action=learnings`)}
                        />
                        <div className="container pb-60px">
                            <div className='mt-3 mb-3 px-3'>
                                <Header handleBackArrowEvent={handleBackArrowEvent}
                                    heading={ action === "edit" ? "Edit Learning Area" : "Create Learning Area" } />
                            </div>
                            <div className='px-3'>
                                {!pageLoading ? 
                                    <>
                                        <div>
                                            <div className='position-relative'>
                                                <ProcessIconEdit
                                                    containerClassName="ms-0 mt-3"
                                                    ref={iconImageRef}
                                                    onRemoveClick={() => {
                                                        onRemoveIconClick()
                                                        handleIconMenuClose()
                                                    }}
                                                    onUploadClick={(event) => {
                                                        event.preventDefault();
                                                        iconImageRef.current.click();
                                                        handleIconMenuClose()
                                                    }}
                                                    handleMenuClose={handleIconMenuClose}
                                                    menuOpen={iconMenuOpen}
                                                    anchorEl={iconAnchorEl}
                                                    previewImage={iconPreviewImage}
                                                    handleEditIconClick={handleEditIconClick}
                                                    onInputChange={(event) =>{
                                                        const file = event.target.files[0];
                                                        if(file && file.type.substr(0,5) === "image"){
                                                            setIconImageFile(file)
                                                        }
                                                    }}
                                                />
                                                <div className='section-name-desktop'>
                                                    <p className='mb-0'>{queryParams.segment_name}{queryParams.segment}</p>
                                                </div>
                                            </div>
                                            <div>
                                                {getForm()}
                                            </div>
                                        </div>
                                    </> : null
                                }
                            </div>
                        </div>
                        <BottomNavigation />
                    </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        CuLearningState : state.learningCU,
        specificLearningAreaState : state.specificLearningArea
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLearningCircle : () => dispatch(fetchLearningCircleLearningAreas()),
        createLearning : (learningDetails,circleId,segment) => dispatch(createLearningArea(learningDetails,circleId,segment)),
        saveLearning : (learningDetails) => dispatch(fetchLearningSuccess(learningDetails)),
        clearCULearningState : () => dispatch(clearCULearning()),
        updateLearningArea : (learningAreaDetails, learningAreaId) =>dispatch(updateLearningArea(learningAreaDetails,learningAreaId)),
        requestSpecificLearningArea : (learningAreaId) => dispatch(specificLearningArea(learningAreaId)),
        saveSpecificLeaningArea : (response) => dispatch(specificLearningAreaSuccess(response)) 
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateOrEditLearning)