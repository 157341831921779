import React,{ useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import YourMeeting from '../../../components/your-meeting/YourMeeting'
import Invites from '../../../components/invites/Invites'
import { removeUserCredentials } from '../../../utils/utils'
import SimpleTab from '../../../components/tabs/simple-tab/simple-tab/SimpleTab'
import SimpleTabPanel from '../../../components/tabs/simple-tab/Simple-tab-panel/SimpleTabPanel'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import Header from '../../../common/header/Header'

const VideoConference = (props) => {

    const { userLogout } = props

    const history = useHistory()
    const location = useLocation()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const queryParams = qs.parse(location.search)

    const [ drawer, setDrawer ] = useState(false)
    const [ menu, setMenu ] = useState(false)
    
    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function handleConference(){
        history.push('/video-conference/create')
    }

    function handleTabs(event,key){
        history.push(`/video-conference?selected=${key}`)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function onClickNotification(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    return (
        <Container>
            {
                isDesktop ? 
                    <>
                        <Navbar
                            enableNotification={true}
                            enableMore={true}
                            open={open}
                            menu={menu}
                            enableLogout={true}
                            enableProfileButton={true}
                            notificationOnClick={onClickNotification}
                            handlePopoverClose={handleMenuClose}
                            handlePopoverOpen={handleMenuOpen}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar 
                                addIcon={true} 
                                addIconTooltip="Create Video Conference" 
                                onAddIconClick={handleConference}
                            />
                            <ProcessMainContainer className="bg-tertiary-color-v2">
                                <Box 
                                    sx={{  
                                        background:"white",
                                        paddingLeft: "5%",
                                        paddingRight: "5%",
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                                    }}
                                >
                                    <SimpleTab 
                                        value={ queryParams.selected ? queryParams.selected :"your-meeting"} 
                                        variant="desktop"
                                        onChange={handleTabs} 
                                        tabArray={[ 
                                            {label:"Your Meeting", value:"your-meeting"},
                                            {label:"Invites", value:"invites"}
                                        ]}
                                    />
                                </Box>
                                <SimpleTabPanel
                                    value="your-meeting"
                                    selectedTab={queryParams.selected} 
                                    containerClassname="overflow-auto"
                                    style={{height: "calc(100vh - 113px)"}}
                                >
                                    <div
                                        style={{
                                            maxWidth:"800px", 
                                            marginLeft:"10%", 
                                            marginRight:"auto"
                                        }}
                                    >
                                        <YourMeeting/>
                                    </div>
                                </SimpleTabPanel>
                                <SimpleTabPanel 
                                    value="invites"
                                    selectedTab={queryParams.selected} 
                                    containerClassname="overflow-auto"
                                    style={{height: "calc(100vh - 113px)"}}
                                >
                                    <div
                                        style={{
                                            maxWidth:"800px", 
                                            marginLeft:"10%", 
                                            marginRight:"auto"
                                        }}
                                    >
                                        <Invites />
                                    </div>
                                </SimpleTabPanel>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </> 
                    : <>
                        <Navbar 
                            variant="mobile"
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={onClickNotification}
                        />
                        <div className="content-area-height">
                            <div className="pt-3 pb-2 px-3">
                                <Header handleBackArrowEvent={() => history.goBack()} />
                            </div>
                            <div>
                                <Box 
                                    sx={{  
                                        width: "100%",
                                        background:" white",
                                        paddingLeft: "5%",
                                        paddingRight: "5%",
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                                    }} 
                                >
                                    <SimpleTab 
                                        value={ queryParams.selected ? queryParams.selected :"your-meeting"} 
                                        tabVariant="fullWidth"
                                        variant="mobile"
                                        onChange={handleTabs} 
                                        tabArray={[ 
                                            {label:"Your Meeting", value:"your-meeting"},
                                            {label:"Invites", value:"invites"}
                                        ]}
                                    />
                                </Box>
                                <SimpleTabPanel 
                                    value="your-meeting" 
                                    containerClassname="content-area-height-not-and-ref bg-tertiary-color-v2 overflow-auto"
                                    selectedTab={queryParams.selected} 
                                >
                                    <YourMeeting/>
                                </SimpleTabPanel>
                                <SimpleTabPanel 
                                    value="invites" 
                                    containerClassname="content-area-height-not-and-ref bg-tertiary-color-v2 overflow-auto"
                                    selectedTab={queryParams.selected} 
                                >
                                    <Invites/>
                                </SimpleTabPanel>
                            </div>
                        </div>
                        <BottomNavigation 
                            enableAddIcon={true} 
                            onAddIconClick={handleConference}
                        />
                    </>
            }
        </Container>
    )
}

export default VideoConference