import React from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/styles'

const StyledSelect = styled(TextField)({
    // input label when focused
    "& label.Mui-focused": {
        color: "#14213d !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
        borderColor: "#14213d !important"
        }
    }
})

const SelectField = (props) => {

    function createOptions(optionList){
        return optionList.map((item,index) => 
            <MenuItem key={index} value={item}>{item}</MenuItem>
        );
    }

    return (
        <div className={props.containerClassName}>
            <StyledSelect id="outlined-select-currency"
                    select
                    fullWidth
                    label={props.label}
                    name={props.name} 
                    required={props.required}
                    onChange={props.onChange} 
                    value={props.value} 
                    onBlur={props.onBlur} 
                    defaultValue={props.defaultValue}
                    InputProps={{
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    InputLabelProps={{
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    autoComplete="off" >
                {createOptions(props.options, props.defaultvalue)}
            </StyledSelect>
            {props.errorMgs ? 
                <div className="ms-1">
                    <span className="error-message">{props.errorMgs}</span>
                </div> : null}
        </div>
    )
}

export default SelectField