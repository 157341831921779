import { REQUEST_NOTIFICATION_DETAIL ,
         FETCH_MAIL_DETAIL_FAILURE, 
         FETCH_MAIL_DETAIL_SUCCESS,
         CLEAR_MAIL_DETAIL_STATE,
         MAIL_ACTION_CHANGES } from './notificationDetail.types'

const initialState = {
    loading : false,
    action_changes : false,
    response : {},
    error : null
}

const notificationDetailReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_NOTIFICATION_DETAIL :
            return {
                ...state,
                loading : true,
                action_changes : false,
                error : null
            }

        case FETCH_MAIL_DETAIL_SUCCESS : 
            return {
                ...state,
                loading : false,
                action_changes : false,
                response : action.payload
            }
        
        case FETCH_MAIL_DETAIL_FAILURE : 
            return {
                ...state,
                loading : false,
                action_changes : false,
                error : action.payload
            }

        case MAIL_ACTION_CHANGES : 
            return {
                ...state,
                loading : true,
                action_changes : true
            }
        
        case CLEAR_MAIL_DETAIL_STATE : 
            return {
                ...state,
                loading : false,
                action_changes : false,
                response: {},
                error : null
            }

        default : 
            return state
    }

}

export default notificationDetailReducer