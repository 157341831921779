import React,{ useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty, toArray } from 'lodash'
import { connect } from 'react-redux'
import qs from 'query-string'
import moment from 'moment'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import DeletePopUp from '../../../components/delete-popup/DeletePopUp'

import './reference.css'
import ReferenceCard from '../../../components/reference-card/ReferenceCard';
import { clearReferenceState, insertNoteInReference, removeReferenceAtIndex} from '../../../redux/notification/reference/Reference.action';
import { deleteNote, fetchReferences, deleteReference } from '../../../services/notification.service';
import { clearDeleteNoteState } from '../../../redux/notification/delete-note/DeleteNote.action';
import ReferencePopup from '../../../components/reference-popup/ReferencePopup';
import MiniBreadCrumbs from '../../../components/mini-breadcrumbs/MiniBreadCrumbs';
import { boldString } from '../../../utils/utils'

const Reference = (props) => {

    const TAG = "Reference"

    const { 
        referenceState, 
        requestReference, 
        ownProps, 
        clearReferenceState,
        removeReference 
    } = props

    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')
    
    const [ pageLoading, setPageLoading ] = useState(true)
    const deleteWarningPopUpInitialState = {
        open: false,
        fileName: null,
        id: null,
        grpIndex: null,
        itemIndex: null,
        refType: null
    }
    const [ deleteWarningPopUp, setDeleteWarningPopUp ] = useState(deleteWarningPopUpInitialState)

    const references = referenceState?.response?.references

    useEffect(() => {
        requestReference(ownProps.action)
        return () => {
            clearReferenceState()
        }
    },[])

    useEffect(() => {
        if(!referenceState.loading){
            requestReference(ownProps.action)
            setPageLoading(true)
        }
    },[queryParams.action])

    useEffect(() => {
        if(!referenceState.loading && !isEmpty(referenceState.response)){
            toast.success(referenceState.response?.data?.message , {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setPageLoading(false)
        }

        if(!referenceState.loading && !isEmpty(referenceState.error)){
            console.log(TAG, referenceState.error)
            if(!isEmpty(referenceState.error) && !isEmpty(referenceState.error.response)){
                if(referenceState.error.response.data){
                    toast.error(referenceState.error.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }else{
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }

    },[referenceState])

    function handleDeleteReferenceTrigger(){
        deleteReference(deleteWarningPopUp.id).then(response => {
            // console.log('response', response)
            // removeReference(deleteWarningPopUp.grpIndex, deleteWarningPopUp.itemIndex, deleteWarningPopUp.refType)
            requestReference(ownProps.action)
            setPageLoading(true)
            toast.success(response.data?.message , {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            handleDeletePopUpClose()
        }).catch(err => {
            console.log(err)
            toast.error( err?.response?.data?.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        })
    }

    function breadcrumbs(actionType, reference){
        switch(actionType){
            case "objectives":
                return (
                    <>
                        {
                            reference.path ?
                                <div className='mb-2'>
                                    <MiniBreadCrumbs path={toArray(reference?.path)}  />
                                </div> 
                                : <p className="lato-fs15-normal-lh20 secondary-text-color mb-0 mb-2">
                                    {reference?.name}
                                </p>
                        }
                    </>
                )

            case "goals":
                return (
                    <p className="lato-fs15-normal-lh20 secondary-text-color mb-0 mb-2">{reference?.name}</p>
                )

            case "metrics":
                return (
                    <p className="lato-fs15-normal-lh20 secondary-text-color mb-0 mb-2">{reference?.metric?.name}</p>
                )

            default:
                return null
        }
    }

    function getRefList(actionType, referenceGrps, listFrom){
        // console.log('reference', referenceGrps)
        if(actionType === "learnings"){
            return (
                referenceGrps.map((referenceGrp, groupIndex) => (
                    <div className='mt-3' key={groupIndex}>
                        <h6 className="lato-fs15-normal-lh20 secondary-text-color mb-0 pb-1">{referenceGrp?.name}</h6>
                        {/* notes */}
                        {
                            referenceGrp.course_content_files?.map((file,index) => (
                                <ReferenceCard key={file._id}
                                    filename={listFrom === 'search_results' ? boldString(file.originalname, props.searchedValue.value) : file.originalname}
                                    link={file.url}
                                    onReferenceDeleteClick={
                                        () => handleOnDeleteIconClick(
                                            file.originalname,
                                            file._id,
                                            groupIndex,
                                            index,
                                            'course_content_files'
                                        )
                                    }
                                    createdDate={moment(file.updatedAt).format('Do MMM, YYYY')}
                                    preview={!isEmpty(file.mimetype) && file.mimetype.split('/')[0] === "image" ? file.url : ""}
                                />
                            ))
                        }
                        {
                            referenceGrp.course_content_links?.map((link, index) => (
                                <ReferenceCard key={link._id}
                                    filename={link.originalname ? listFrom === "search_results" ? boldString(link.originalname, props.searchedValue.value) : link.originalname : listFrom === 'search_results' ? boldString(link.url, props.searchedValue.value) : link.url} 
                                    link={link.url}
                                    onReferenceDeleteClick={
                                        () => handleOnDeleteIconClick(
                                            link.originalname,
                                            link._id,
                                            groupIndex,
                                            index,
                                            'course_content_links'
                                        )
                                    }
                                    createdDate={moment(link.updatedAt).format('Do MMM, YYYY')}
                                    preview={!isEmpty(link.link_meta_data) && !isEmpty(link.link_meta_data.images) ? link.link_meta_data.images[0] : ''}
                                />
                            ))
                        }
                        {/*courses in learning area*/}
                        {
                            referenceGrp?.courses?.map((course) => (
                                <>
                                    {
                                        !isEmpty(course.course_content_files) ?
                                            course.course_content_files.map((file, index) => (
                                                <ReferenceCard key={file._id} 
                                                    filename={listFrom === 'search_results' ? boldString(file.originalname, props.searchedValue.value) : file.originalname}
                                                    link={file.url}
                                                    onReferenceDeleteClick={
                                                        () => handleOnDeleteIconClick(
                                                            file.originalname,
                                                            file._id,
                                                            groupIndex,
                                                            index,
                                                            'course_content_files'
                                                        )
                                                    }
                                                    createdDate={moment(file.updatedAt).format('Do MMM, YYYY')}
                                                    preview={!isEmpty(file.mimetype) && file.mimetype.split('/')[0] === "image" ? file.url : ""}
                                                />
                                            ))
                                            : null
                                    }
                                    {
                                        !isEmpty(course.course_content_links) ? 
                                            course.course_content_links.map((link, index) => (
                                                <ReferenceCard key={link._id}
                                                    filename={link.originalname ? listFrom === "search_results" ? boldString(link.originalname, props.searchedValue.value) : link.originalname : listFrom === 'search_results' ? boldString(link.url, props.searchedValue.value) : link.url}
                                                    link={link.url}
                                                    onReferenceDeleteClick={
                                                        () => handleOnDeleteIconClick(
                                                            link.originalname ? link.originalname : link.url,
                                                            link._id,
                                                            groupIndex,
                                                            index,
                                                            'course_content_links'
                                                        )
                                                    }
                                                    createdDate={moment(link.updatedAt).format('Do MMM, YYYY')}
                                                    preview={!isEmpty(link.link_meta_data) && !isEmpty(link.link_meta_data.images) ? link.link_meta_data.images[0] : ''}
                                                />
                                            ))
                                            :null
                                    }
                                </>
                            ))
                        }
                    </div>
                ))
            )
        }else{
            return (
                referenceGrps.map((referenceGrp, groupIndex) => (
                    <div className='mt-4' key={groupIndex}>
                        {
                            breadcrumbs(referenceState.response.action_type, referenceGrp)
                        }
                        { 
                            !isEmpty(referenceGrp.reference_files) ?
                                referenceGrp.reference_files.map((file,index) => (
                                    <ReferenceCard key={file._id} 
                                        filename={listFrom === 'search_results' ? boldString(file.originalname, props.searchedValue.value) : file.originalname} 
                                        link={file.url}
                                        createdDate={moment(file.updatedAt).format('Do MMM, YYYY')}
                                        preview={!isEmpty(file.mimetype) && file.mimetype.split('/')[0] === "image" ? file.url : ""}
                                        onReferenceDeleteClick={
                                            () => handleOnDeleteIconClick(
                                                file.originalname,
                                                file._id,
                                                groupIndex,
                                                index,
                                                'reference_files'
                                            )
                                        }
                                    />
                                ))
                                : null
                        }
                        {
                            !isEmpty(referenceGrp.reference_links) ? 
                                referenceGrp.reference_links.map((link, index) => (
                                    <ReferenceCard key={link._id}
                                        filename={link.originalname ? listFrom === "search_results" ? boldString(link.originalname, props.searchedValue.value) : link.originalname : listFrom === 'search_results' ? boldString(link.url, props.searchedValue.value) : link.url}
                                        link={link.url}
                                        onReferenceDeleteClick={
                                            () => handleOnDeleteIconClick(
                                                link.originalname ? link.originalname : link.url,
                                                link._id,
                                                groupIndex,
                                                index,
                                                'reference_links'
                                            )
                                        }
                                        createdDate={moment(link.updatedAt).format('Do MMM, YYYY')}
                                        preview={!isEmpty(link.link_meta_data) && !isEmpty(link.link_meta_data.images) ? link.link_meta_data.images[0] : ''}
                                    />
                                ))
                                :null
                        }
                    </div>
                ))
            )
        }
    }

    function handleDeletePopUpClose(){
        setDeleteWarningPopUp(ps => {
            return {
                ...ps,
                ...deleteWarningPopUpInitialState
            }
        })
    }

    function handleOnDeleteIconClick(fileName, id, grpIndex, itemIndex, refType){
        setDeleteWarningPopUp(ps => {
            return {
                ...ps,
                open: true,
                fileName,
                id,
                grpIndex,
                itemIndex,
                refType
            }
        })
    }

    function getAppropriateCards(){
        if(props.searchedValue.value){
            if(!props.searchedValue.typing){
                if(!isEmpty(props.searchedValue.data)){
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            {getRefList(props.action, props.searchedValue.data, 'search_results')}
                        </>
                    )
                } else {
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            <p className='lato-fs15-normal-lh20'>
                                No mail found for search results "
                                <span className='lato-fs15-normal-lh20 font-bold'>
                                    {props.searchedValue.value}
                                </span>
                                "
                            </p>
                        </>
                    )
                }
            } else {
                return (
                    <Box className="d-flex justify-content-center align-items-center">
                        <CircularProgress sx={{color: 'var(--primary-color-v2)'}} />
                    </Box>
                )
            }
        } else {
            if(!pageLoading){
                if(!isEmpty(references)){
                    return (
                        getRefList(referenceState.response.action_type, referenceState.response.references, 'fetched_list')
                    )
                } else {
                    return (
                        <div className="d-flex justify-content-center">
                            <h6 className="lato-fs20-normal-lh28 mb-0 tertiary-text-color-v2">{ queryParams.action === 'learnings' ? "No courses" : "No references"}</h6>
                        </div>
                    )
                }
            } else {
                return null
            }
        }
    }

    return (
        <>
            {
                isDesktop ?
                    <div className='pt-4 pb-3'>
                        {getAppropriateCards()}
                    </div>
                    : <div className='pt-3 pb-70'>
                        {getAppropriateCards()}
                    </div>
            }
            {
                ownProps.addRef ?
                    <ReferencePopup 
                        handleClose={ownProps.toggleAddRef} 
                        action={ownProps.action} 
                        open={ownProps.addRef}
                    />
                    : null
            }
            {
                deleteWarningPopUp ?
                    <DeletePopUp
                        open={deleteWarningPopUp.open}
                        handleClose={handleDeletePopUpClose}
                        onClick={handleDeleteReferenceTrigger}
                        msg={`Are you sure you want to delete '${deleteWarningPopUp.fileName}'`}
                    />
                :null 
            }
        </>
    )
}

const mapStateToProps = ( state,ownProps ) => {
    return {
        referenceState : state.references,
        deleteNoteState : state.deleteNote,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestReference : (action) => dispatch(fetchReferences(action)),
        clearReferenceState : () => dispatch(clearReferenceState()),
        requestDeleteNote : (noteId) => dispatch(deleteNote(noteId)),
        clearDeleteNoteState : () => dispatch(clearDeleteNoteState()),
        removeReference : (grpIndex, refIndex, refType) => dispatch(removeReferenceAtIndex(grpIndex, refIndex, refType)),
        updateReference : (groupIndex,referenceType,referenceIndex,payload) => dispatch(insertNoteInReference(groupIndex,referenceType,referenceIndex,payload))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Reference)