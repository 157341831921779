import React,{ useState, useEffect, useRef } from 'react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { isEmpty, size } from 'lodash'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import { modifyLearningAreaSuccess, saveCourseContentFiles } from '../../../redux/notification/modify-learning-area/ModifyLearningArea.action'
import {courseValidationSchema} from './ModifyLearning.utils'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import UploadFiles from '../../../components/upload-files/UploadFiles'

const ModifyCourse = (props) => {

    const { 
        modifyLearningAreaState, 
        saveEditedCourse,
        saveCourseContentFiles,
        ownProps
    } = props

    const referenceFileRef = useRef()
    const formRef = useRef()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [referenceFiles, setReferenceFiles] = useState([])
    const [existingReferences, setExistingReferences] = useState([])
    const [ course, setCourse ] = useState({loading: true, doc: {}})

    const learningArea = modifyLearningAreaState.response?.learning_area

    const initialValues = {
        name: !isEmpty(course.doc) && course.doc.name ? course.doc.name : "",
        description: !isEmpty(course.doc) && course.doc.description ? course.doc.description : "",
        course_content_links : !isEmpty(course.doc) && !isEmpty(course.doc.course_content_links) ? getLinks(course.doc.course_content_links) : [""]
    }

    const onSubmit = (values) =>{
        const editedCourse = editCourse(values)
        saveEditedCourse({learning_area: editedCourse})
        if(referenceFiles.length > 0){
            saveCourseContentFiles({id: course.doc._id, files: referenceFiles})
        }
        handleGoBack()
    }

    function editCourse(values){
        const learningAreaCopy = JSON.parse(JSON.stringify(learningArea))
        const curCourse = learningAreaCopy.courses.find(mCourse => mCourse._id === course.doc._id)
        if(curCourse.name !== values.name){
            curCourse.name = values.name
        }
        if(curCourse.description !== values.description){
            curCourse.description = values.description
        }
        if(curCourse.description !== values.description){
            curCourse.description = values.description
        }
        if(size(curCourse.course_content_files) !== size(existingReferences)){
            curCourse.course_content_files = existingReferences
        }
        if(referenceFiles.length > 0){
            const fileNames = referenceFiles.map(file => file.name)
            curCourse.new_course_content_files = fileNames
        } else if(!isEmpty(curCourse.new_course_content_files)){
            delete curCourse.new_course_content_files
        }
        if(!isEmpty(values.course_content_links)){
            const formatedContentLinks = values.course_content_links.filter(link => !isEmpty(link))
            if(!isEmpty(formatedContentLinks)){
                curCourse.course_content_links = formatedContentLinks.map(link => ({url: link}))
            } else {
                curCourse.course_content_links = []
            }
        } else if(!isEmpty(curCourse.course_content_links)){
            curCourse.course_content_links = []
        }
        return learningAreaCopy
    }

    function handleGoBack(){
        ownProps.cancel()
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.map((link) => (
            contentLink.push(link.url)
        ))
        return contentLink
    }

    useEffect(() => {
        const courseDoc = learningArea.courses.find(course => course._id === ownProps.id)
        if(!isEmpty(courseDoc)){
            setCourse(ps => {
                return {
                    loading: false,
                    doc : courseDoc
                }
            })
        }
    }, [ownProps.id])

    useEffect(() => {
        if(!course.loading && !isEmpty(course.doc)){
            setExistingReferences(course.doc.course_content_files)
            if(!isEmpty(modifyLearningAreaState.courseContentFiles)){
                const newContentFilesObj = modifyLearningAreaState.courseContentFiles.find(obj => obj.id === course.doc._id)
                if(!isEmpty(newContentFilesObj)){
                    setReferenceFiles(newContentFilesObj.files)
                }
            }
        }
    }, [course])

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index !== i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index !== i
            })
            return newState
        })
    }

    function handleReferenceFileAddition(files){
        setReferenceFiles(prevState => {
            return prevState.length > 0 ? [...prevState, ...files] : [...files]
        })
    }

    return (
        <div className={isDesktop ? "" : "ml-10 mr-10"}>
            <Formik 
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={courseValidationSchema(existingReferences, referenceFiles, formRef.current?.values?.course_content_links)}
                innerRef={formRef}
                onSubmit={onSubmit}>
                {props => {
                    const { values,touched,errors, handleChange, handleBlur } = props
                    return (
                        <>
                            <Form>
                                <div className={isDesktop ? "mt-3 w-50" : "mt-3"}>
                                    <InputBox type="text"
                                        name="name"
                                        label="Name"
                                        placeholder="Enter the course name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMgs={touched.name && errors.name ? errors.name : ""}
                                    />
                                </div>
                                <div className="mt-3">
                                    <TextAreaBox name ="description"
                                        placeholder="Enter the course description"
                                        label="Description"
                                        rows= {isDesktop ? 4 : ""}
                                        value={values.description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errormgs={touched.description && errors.description ? errors.description : ""}
                                    />
                                </div>
                                <div className="mt-3">
                                    <FieldArray name="course_content_links" >
                                        {fieldArrayProps => {
                                            const { push, remove, form } = fieldArrayProps
                                            const { values } = form
                                            const { course_content_links } = values
                                            return (
                                                <>
                                                    <Grid container>
                                                        {course_content_links.map((link, index) => (
                                                            <> 
                                                                <Grid item xs={index > 0 ? 11 : 12} md={ index > 0 ? 5 : 6} sx={{paddingRight :{xs:0 ,md: index/2 === 0 ? "10px": "0px"},marginBottom:"10px"}}>
                                                                    <Field name={`course_content_links[${index}]`}>
                                                                        {props => {
                                                                            const {field,meta} = props
                                                                            return (
                                                                                <>
                                                                                    <InputBox type="url" 
                                                                                        label="Link Url"
                                                                                        placeholder="http://www.example.com"
                                                                                        {...field}
                                                                                        errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }}
                                                                    </Field>
                                                                </Grid>
                                                                { index > 0 ?
                                                                    <Grid item md={1} xs={1}>
                                                                        <IconButton onClick={() => remove(index)} sx={{display:"flex", paddingBottom:"20px", height:"100%"}}>
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </Grid> : null }
                                                            </>
                                                        ))}
                                                    </Grid>
                                                    <div className='mt-2'>
                                                        <CustomizeButton variant="secondary-start-icon-button"
                                                            StartIcon={<AddIcon />} 
                                                            label="Add Link"
                                                            handleClick={() => push('')}  />
                                                    </div>
                                                </>
                                            )
                                        }}
                                    </FieldArray>
                                </div>
                                <div>
                                    <div className='mt-4'>
                                        { !isEmpty(existingReferences) ? existingReferences.map((reference, index) => ( 
                                                <div key={index} className="d-flex mb-2">
                                                    <p className="filename lato-fs15-normal-lh20 one-line-wrap primary-text-color mb-0"> {reference.originalname}</p>
                                                    <FontAwesomeIcon className=" ms-auto me-2 secondary-color" 
                                                        onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                </div>
                                            )
                                        ) : null }
                                        { !isEmpty(referenceFiles) ? referenceFiles.map((file,index)=>(
                                                <div key={index} className="mb-2 d-flex">
                                                    <p className="mb-0 lato-fs15-normal-lh20 one-line-wrap primary-text-color">{file.name}</p>
                                                    <FontAwesomeIcon className="ms-auto me-2 secondary-color" 
                                                        onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                </div>
                                        )) : null}
                                    </div>
                                    <div className='mt-3'>
                                        <UploadFiles 
                                            onClick={(event) => {
                                                event.preventDefault();
                                                referenceFileRef.current.click();
                                            }} 
                                            label="Click to upload attachment" 
                                            variant={isDesktop ? "" : "mobile-view"}
                                        />
                                    </div>
                                </div>
                                <input type="file"
                                    style={{display:"none"}} 
                                    ref={referenceFileRef}
                                    multiple
                                    accept="video/mp4,video/x-m4v,video/*,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,text/plain, application/pdf,image/png, image/jpeg, image/jpg,.mp3,audio/*,csv,.xlsx,.xls"
                                    name="course_reference"
                                    onChange={(event) =>{
                                        const files = event.target.files
                                        if(files){
                                            handleReferenceFileAddition(files)
                                        }
                                }} />
                                <div className="d-flex justify-content-center align-center mt-4">
                                    <CustomizeButton variant="secondary-color-button" 
                                        handleClick={handleGoBack} 
                                        label="Cancel" 
                                        className="me-4" />
                                    <CustomizeButton variant="primary-color-button" type="submit" label="Save" />
                                </div>
                            </Form>
                        </>
                    )
                }}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        modifyLearningAreaState : state.modifyLearningArea,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveEditedCourse : (editedLearningArea) => dispatch(modifyLearningAreaSuccess(editedLearningArea)),
        saveCourseContentFiles : (payload) => dispatch(saveCourseContentFiles(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyCourse)