import {isEmpty} from 'lodash'

export const insertUserAndProfile = (userArr, newUser, newUserProfile) => {
    return !isEmpty(userArr) ? 
        [...userArr, { user : newUser, profileInfo : newUserProfile }] : 
        [{ user : newUser, profileInfo : newUserProfile }]
}

export const insertUser = (userArr, newUser) => {
    return !isEmpty(userArr) ? [...userArr, newUser] : [newUser]
}

export const removeUser = (userArr, userUid) => {
    return userArr.filter(user => user.uid !== userUid)
}

export const removeUserAndProfile = (userArr, userUid) => {
    return userArr.filter(user => user.user.uid !== userUid)
}

export const updateUser = (userArr, userToBeUpdated) => {
    if(!isEmpty(userArr)){
        const userUidArr = userArr.map(user => user.uid)
        if(userUidArr.includes(userToBeUpdated.uid)){
            const userIndex = userUidArr.findIndex(userToBeUpdated.uid)
            userArr[userIndex].user = userToBeUpdated
        }
    }
    return userArr
}

export const changingRemoteUser = (array,index) => {
    let remoteUser = array[index]
    let remoteUserCopy = array.filter((remoteUser, i) => i !== index )
    const newArray = [ remoteUser,...remoteUserCopy]
    return newArray
}