import React,{ useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import qs from 'query-string'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import {COMPONENTS_TYPE} from './objectiveAll.utils'

import { requestObjectiiveReviewAll } from '../../../services/review.service'
import './objective-all.css'
import SubObjectiveReview from '../sub-objective-review/SubObjectiveReview'
import ObjReviewByLvl from '../obj-review-by-lvl/ObjReviewByLvl'
import { clearObjectiveReviewState } from '../../../redux/review-team-action/objective-review/objectiveReview.action'
import { clearSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'

const ObjectiveAll = (props) => {

    const { requestObjectiveReview, reviewObjectiveAllState, clearObjectiveReview, clearSpecificObjectiveState, objectiveDetailState } = props

    const [ pageLoading, setPageLoading ] = useState(true)

    const location = useLocation()
    const params = useParams()
    const queryParams = qs.parse(location.search)

    useEffect(() => {
        if(isEmpty(reviewObjectiveAllState.response)){
            requestObjectiveReview(params.userId)
        }
        if(!isEmpty(objectiveDetailState.response)){
            clearSpecificObjectiveState()
        }
        return () => {
            clearObjectiveReview()
        }
    },[])

    useEffect(() => {
        if(!reviewObjectiveAllState.loading && !isEmpty(reviewObjectiveAllState.response)){
            setPageLoading(false)
        }

        if(!reviewObjectiveAllState.loading && !isEmpty(reviewObjectiveAllState.error)){
            console.log(reviewObjectiveAllState.error)
        }

        if(reviewObjectiveAllState.action_changed && reviewObjectiveAllState.loading){
            requestObjectiveReview(params.userId)
        }

    },[reviewObjectiveAllState])

    return (
        !pageLoading ?
            queryParams.mode === COMPONENTS_TYPE.listObjBylvl ? 
                <ObjReviewByLvl /> :
                <SubObjectiveReview /> :
            null
    )
}

const mapStateToProps = (state) => {
    return {
        reviewObjectiveAllState : state.objectiveReviewAll,
        objectiveDetailState : state.reviewSpecificObjectiveDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjectiveReview : (userId) => dispatch(requestObjectiiveReviewAll(userId)),
        clearObjectiveReview : () => dispatch(clearObjectiveReviewState()),
        clearSpecificObjectiveState : () => dispatch(clearSpecificObjectiveForReview())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ObjectiveAll)