import { REQUEST_COMPUTE_METRIC_PROGRESS, 
         COMPUTE_METRIC_PROGRESS_SUCCESS,
         COMPUTE_METRIC_PROGRESS_FAILURE,
         CLEAR_COMPUTE_METRIC_PROGRESS } from './ComputeMetricProgressTypes'

const initialState = {
    loading: false,
    response: {},
    error: null
}

const computeMetricProgressReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_COMPUTE_METRIC_PROGRESS : 
            return {
                ...state,
                loading: true,
                error: null
            }

        case COMPUTE_METRIC_PROGRESS_SUCCESS : 
            return {
                ...state,
                loading : false,
                response: action.payload
            }

        case COMPUTE_METRIC_PROGRESS_FAILURE : 
            return {
                ...state,
                loading: false,
                error : action.payload
            }

        case CLEAR_COMPUTE_METRIC_PROGRESS : 
            return  {
                ...state,
                loading: false,
                response: {},
                error : null
            }

        default : 
            return state
    }
}

export default computeMetricProgressReducer