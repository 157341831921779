import {
    CLEAR_UPDATE_ORG_PROFILE_STATES, 
    REQUEST_UPDATE_ORG_PROFILE_DATA,
    UPDATE_ORG_PROFILE_DATA_SUCCESS,
    UPDATE_ORG_PROFILE_DATA_FAILURE,
} from './updateOrgProfile.types'

export const requestOrgProfileDataUpdate = () => {
    return {
        type : REQUEST_UPDATE_ORG_PROFILE_DATA
    }
}

export const updateOrgProfileDataSuccess = (resData) => {
    return {
        type : UPDATE_ORG_PROFILE_DATA_SUCCESS,
        payload : resData
    }
}

export const updateOrgProfileDataFailure = (error) => {
    return {
        type : UPDATE_ORG_PROFILE_DATA_FAILURE,
        payload : error
    }
}

export const clearUpdateOrgProfileDataStates = () => {
    return{
        type : CLEAR_UPDATE_ORG_PROFILE_STATES
    }
}