import { REQUEST_FETCH_CREATE_METRIC_DATA,  
        FETCH_CREATE_METRIC_DATA_SUCCESS,
        FETCH_CREATE_METRIC_DATA_FAILURE,
        CLEAR_FETCH_CREATE_METRIC_DATA } from './FetchCreateMetricDataTypes'

const initialState = {
    loading : false,
    response: {},
    error: null
}

const fetchCreateMetricDataReducer = (state = initialState, action) => {
    switch(action.type) {
        case REQUEST_FETCH_CREATE_METRIC_DATA: 
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_CREATE_METRIC_DATA_SUCCESS : 
            return {
                ...state,
                loading: false,
                response: action.payload
            }

        case FETCH_CREATE_METRIC_DATA_FAILURE : 
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_FETCH_CREATE_METRIC_DATA : 
            return {
                ...state,
                loading : false,
                response: {},
                error: null
            }

        default : 
            return state
    }
}

export default fetchCreateMetricDataReducer