import React from 'react'
import './glassy-card.css'

const GlassyCard = (props) => {
    const className = props.className ? `frost border-radius-20 ${props.className}` : `frost border-radius-20`
    return(
        <div className={className}>
            {props.children}
        </div>
    )
};

export default GlassyCard;
