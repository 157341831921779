import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { size } from 'lodash'
// import moment from 'moment'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'

import useWindowDimension from '../../../hooks/useWindowDimension'

// import { formatDate } from '../../../utils/utils'

const DoughnutChart = (props) => {

    const {width, height} = useWindowDimension()
    const isMediumScreen = useMediaQuery('(min-width:900px)')

    function formatGraphData(graphData, key="B") {
        let graphDataCopy = graphData.slice(1, size(graphData))
        // const maskedLabelValues = graphDataCopy.shift()
        return {
            labels: getGraphDataArr(graphDataCopy, 'A'),
            datasets : getDatasets(graphDataCopy, key)
        }
    }

    function getDatasets(data, key) {
        const dataset = []
        dataset.push({
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
            data: getGraphDataArr(data, key)
        })
        return dataset
    }

    function getGraphDataArr(data, key){
        const graphLabel = []
        for(let i = 0; i<size(data); i++ ){
            graphLabel.push(data[i][key])
        }
        return graphLabel
    }

    const getCalcHeight = (colCount = 1) => {
        const contentWidth = width > 800 ? 800 : width
        const paddingHorizontal = (16 + 16) * colCount
        return contentWidth - paddingHorizontal
    }

    switch(props.variant){
        case "preview": 
            return (
                <div className="minh-18_5">
                    <Doughnut 
                        data={formatGraphData(props.graphDetail)}
                        options={{
                            responsive : true,
                            maintainAspectRatio : false,
                            animation : {
                                duration : 0
                            },
                        }}
                    />
                </div>
            )
        case "expanded-graph":
            const firstData = props.graphDetail[1]
            const labels = props.graphDetail[0]
            if("B" in firstData && "C" in firstData){
                const height = isMediumScreen ? Math.floor(getCalcHeight(2)/2) : Math.floor(getCalcHeight()*0.75)
                return (
                    <Grid container>
                        <Grid xs={12} md={6} item>
                            <div>
                                <Doughnut 
                                    data={formatGraphData(props.graphDetail, "B")}
                                    style={{height: `${height}px` }}
                                    options={{
                                        responsive : true,
                                        maintainAspectRatio : false,
                                        // animation : {
                                        //     duration : 0
                                        // },
                                    }}
                                />
                            </div>
                            <h4 className='text-center my-3 lato-fs18-normal-lh22'>{labels.B}</h4>
                        </Grid>
                        <Grid xs={12} md={6} item >
                            <div>
                                <Doughnut 
                                    data={formatGraphData(props.graphDetail, "C")}
                                    style={{height: `${height}px`}}
                                    options={{
                                        responsive : true,
                                        maintainAspectRatio : false,
                                        // animation : {
                                        //     duration : 0
                                        // },
                                    }}
                                />
                            </div>
                            <h4 className='text-center my-3 lato-fs18-normal-lh22'>{labels.C}</h4>
                        </Grid>
                    </Grid>
                )
            } else {
                const height = Math.floor(getCalcHeight()*0.75)
                return (
                    <div>
                        <div>
                        <Doughnut 
                            data={formatGraphData(props.graphDetail)}
                            style={{height: `${height}px` }}
                            options={{
                                responsive : true,
                                maintainAspectRatio : false,
                                // animation : {
                                //     duration : 0
                                // },
                            }}
                        />
                        </div>
                        <h4 className='text-center my-3 lato-fs18-normal-lh22'>{labels.B}</h4>
                    </div>
                )
            }

        default : 
            return (
                <div style={{height:"100%",padding:"10px 0px"}}>
                    <Doughnut 
                        data={formatGraphData(props.graphDetail)}
                        options={{
                            responsive : true,
                            animation : {
                                duration : 0
                            },
                            maintainAspectRatio : false,
                            plugins: {              // hide the color representation
                                legend: {
                                    display: false,
                                },
                                tooltip:{
                                    enabled: false
                                }
                            },
                            animation :{
                                duration : 0  
                            },
                        }}
                    />
                </div>
            )
    }
}

export default DoughnutChart