import React,{useState, useEffect, useRef} from 'react'
import { Form, Formik, FieldArray, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { isEmpty, size } from 'lodash'
import { connect } from 'react-redux'
import { formatDate } from '../../utils/utils'
import moment from 'moment'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'

import InputBox from '../../common/Input-box/InputBox'
import TextAreaBox from '../../common/textarea-box/TextAreaBox'
import './modify-objective-edit-form.css'
import { checkObjectiveDuplicationPromise } from '../../services/fetchObjectives.service'
import DatePicker from '../../common/date-picker/DatePicker'
import { 
    addObjectiveReferenceFile, 
    addObjectiveWallpaper, 
    addObjectiveIcon, 
    modifyObjectiveSuccess, 
    updateOneValidateObj 
} from '../../redux/notification/modify-objective/ModifyObjective.action'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import UploadFiles from '../upload-files/UploadFiles'
import ProcessWallpaperEdit from '../process-wallpaper-edit/ProcessWallpaperEdit'
import ProcessIconEdit from '../process-icon-edit/ProcessIconEdit'

const ModifyObjectiveEditForm = (props) => {

    const { 
        ownProps,
        modifyObjectiveState,
        saveModifiedObjective,
        updateWallpaper,
        updateIcon,
        updateReferenceFiles,
        saveOneValidateObj
    } = props

    const wallpaperimageRef = useRef()
    const iconImageRef = useRef()
    const referenceFileRef = useRef()
    const formRef = useRef()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [wallpaperImageFile,setWallpaperImageFile] = useState('')
    const [wallpaperPreviewImage, setWallpaperPreviewImage] = useState('')
    const [iconImageFile, setIconImageFile] = useState("")
    const [iconPreviewImage,setIconPreviewImage] = useState("")
    const [referenceFiles, setReferenceFiles] = useState([])
    const [existingReferences, setExistingReferences] = useState([])
    const [activeObjective, setActiveObjective] = useState({})
    const [parentObjective, setParentObjective] = useState({})

    const [wallpaperAnchorEl, setWallpaperAnchorEl] = useState(null)

    const wallpaperMenuOpen = Boolean(wallpaperAnchorEl)

    const handleWallpaperEditIconClick = (event) => {
        setWallpaperAnchorEl(event.currentTarget)
    }

    const handleWallpaperMenuClose = () => {
        setWallpaperAnchorEl(null)
    }

    const [iconAnchorEl, setIconAnchorEl] = useState(null)

    const iconMenuOpen = Boolean(iconAnchorEl)

    const handleIconEditIconClick = (event) => {
        setIconAnchorEl(event.currentTarget)
    }

    const handleIconMenuClose = () => {
        setIconAnchorEl(null)
    }

    const onRemoveWallpaperClick = () => {
        if(wallpaperImageFile) setWallpaperImageFile('')
        if(wallpaperPreviewImage) setWallpaperPreviewImage('')
        if(activeObjective?.wallpaper){
            return activeObjective.wallpaper = ""
        }
    }

    const onRemoveIconClick = () => {
        if(iconImageFile) setIconImageFile('')
        if(iconPreviewImage) setIconPreviewImage('')
        if(activeObjective?.icon){
            return activeObjective.icon = ""
        }
    }

    const activeValidateObj = modifyObjectiveState.validationError.find(obj => obj.id === ownProps.activeObjectiveId)

    // const nameDebounce = useDebounce(async (name) => {
    //     //console.log('aslkjfd', name)
    //     //if(validateObjName(name)){
    //         const result = await checkObjectiveDuplicationPromise({level : ownProps.activeObjective.level, name : name})
    //         //console.log(result)
    //         return !result.already_present
    //     // } else {
    //     //     return false
    //     // }
    // }, 1000)

    useEffect(() => {
        const activeObj = ownProps.activeObjective
        const parentObj = ownProps.parentObjOfActiveObj
        setActiveObjective(activeObj)
        setParentObjective(parentObj)
    }, [])

    useEffect(()=> {
        setExistingReferences(activeObjective.reference_files ? activeObjective.reference_files : [])
        if(activeObjective.new_wallpaper){
            const wallpaperPreview = modifyObjectiveState.wallpapers.find(wallpaper => wallpaper._id === activeObjective._id)?.preview_data
            if(wallpaperPreview){
                setWallpaperPreviewImage(wallpaperPreview)
            }
        }
        if(activeObjective.new_icon){
            const iconPreview = modifyObjectiveState.icons.find(icon => icon._id === activeObjective._id)?.preview_data
            if(iconPreview){
                setIconPreviewImage(iconPreview)
            }
        }
        if(!isEmpty(activeObjective.new_reference_files)) {
            const newReferenceFilesObj = modifyObjectiveState.referenceFiles.find(referenceFile => referenceFile._id === ownProps.activeObjective._id)
            if(!isEmpty(newReferenceFilesObj)){
                setReferenceFiles(newReferenceFilesObj.files)
            } else {
                setReferenceFiles([])
            }
        }
        if(!isEmpty(activeValidateObj) && activeValidateObj.hasErrors && !isEmpty(formRef.current)){
            formRef.current.validateForm()
            formRef.current.setFieldTouched('name')
            formRef.current.setFieldTouched('description')
            formRef.current.setFieldTouched('start_date')
            formRef.current.setFieldTouched('end_date')
        }
    }, [activeObjective])

    const emptyLinkObj = {
        originalname: "",
        url : ""
    }

    const initialValues ={
        name: !isEmpty(activeObjective.name) ? activeObjective.name : "",
        description : !isEmpty(activeObjective.description) ? activeObjective.description : "",
        start_date : !isEmpty(activeObjective.start_date) ? formatDate(activeObjective.start_date) : "",
        end_date : !isEmpty(activeObjective.end_date) ? formatDate(activeObjective.end_date) : "",
        reference_links : referenceLinks()
    }

    const validationSchema = yup.object().shape({
        name : yup.string().required('Required')
                .test('name-duplication-test', 'Already exists', (name) => {
                    return new Promise((resolve, reject) => {
                        if(name){
                            const isValid = validateObjName(name)
                            if(isValid){
                                checkObjectiveDuplicationPromise({level: activeObjective.level, name: name}).then(res => {
                                    resolve(!res.already_present)
                                }).catch(err => {
                                    console.log(err)
                                    resolve(false)
                                })
                            } else {
                                resolve(false)
                            }
                        } else {
                            return false
                        }
                    })
                }),
        description: yup.string(),
        start_date: yup.date().required("Required")
                        .test('start-date-validation', 'Invalid start date', (value) => {
                            if(activeObjective.level > 0){
                                if(!isEmpty(parentObjective)){
                                    const parentStartDate = moment(parentObjective.start_date).startOf("day")
                                    const parentEndDate = moment(parentObjective.end_date).endOf("day")
                                    return moment(value).isBetween(parentStartDate, parentEndDate, undefined, '[]')
                                } else {
                                    console.log('Error in validation')
                                    return false
                                }
                            } else {
                                return true
                            }
                        }),
        end_date : yup.date().required('Required')
                        .min(yup.ref('start_date'), "End date can't be before Start date")
                        .test('end-date-validation', 'Invalid end date', (value) => {
                            if(activeObjective.level > 0){
                                if(!isEmpty(parentObjective)){
                                    const parentStartDate = moment(parentObjective.start_date).startOf("day")
                                    const parentEndDate = moment(parentObjective.end_date).endOf("day")
                                    return moment(value).isBetween(parentStartDate, parentEndDate, undefined, '[]')
                                } else {
                                    console.log('Error in validation')
                                    return false
                                }
                            } else {
                                const valueInMoment = moment(value).endOf("day")
                                const currentEndDateInMoment = moment().endOf("day")
                                return valueInMoment.isSameOrAfter(currentEndDateInMoment)
                            }
                        }),
        reference_links : yup.array().of(
            yup.object().shape({
                originalname : yup.string(),
                url : yup.string().when("originalname", {
                    is: (value) => !isEmpty(value),
                    then: yup.string().required("Please enter the url").url("Invalid url"),
                    otherwise : yup.string().url("Invalid url")
                })
            })
        )
    })

    function referenceLinks(){
        if(!isEmpty(activeObjective.reference_links) && !isEmpty(activeObjective.new_reference_links)){
            return [...activeObjective.reference_links.forEach(link => ({ url: link.url, originalname : link.originalname})), ...activeObjective.new_reference_links]
        } else if(!isEmpty(activeObjective.reference_links)){
            return activeObjective.reference_links.map(link => ({url: link.url, originalname: link.originalname}))
        } else if(!isEmpty(activeObjective.new_reference_links)){
            return activeObjective.new_reference_links
        } else {
            return [emptyLinkObj]
        }
    }

    const onSubmit =( values ) => {
        saveModifiedObjective({objective : updateObjective(values)})
        saveOneValidateObj({id: activeObjective._id, hasErrors: false})
        ownProps.onCancelBtnClick()
    }

    function validateObjName(name){
        let validName = true
        let parentFound = false

        function func(obj){
            if(parentFound || activeObjective._id === modifyObjectiveState.response.objective._id || isEmpty(obj.sub_objectives)){
                return
            }
            
            obj.sub_objectives.some(subObj => {
                if(subObj._id === activeObjective._id){
                    parentFound = true
                    return true
                }
            })

            if(parentFound){
                obj.sub_objectives.some(subObj => {
                    if((subObj._id !== activeObjective._id) && (subObj.name === name)){
                        validName = false
                    }
                })
            }
        }

        func(modifyObjectiveState.response.objective)
        return validName
    }

    function updateObjective(values){

        let objectiveCopy = JSON.parse(JSON.stringify(modifyObjectiveState.response.objective))
        let updated = false

        function func(obj) {

            if(updated){
                return
            }

            if(activeObjective._id === obj._id){

                obj.name = values.name
                obj.description = values.description
                obj.start_date = moment(values.start_date).toISOString()
                obj.end_date = moment(values.end_date).toISOString()
                obj.reference_links = getExistinglinks(values.reference_links.filter(link => {
                    if(link) {
                        return true
                    } else {
                        return false
                    }
                }))
                obj.new_reference_links = getAddedlinks(values.reference_links.filter(link => {
                    if(link) {
                        return true
                    } else {
                        return false
                    }
                }))
                obj.reference_files = existingReferences

                if(size(referenceFiles)) {
                    updateReferenceFiles(obj._id, referenceFiles)
                } else if(!isEmpty(activeObjective.new_reference_files)) {
                    updateReferenceFiles(obj._id, [])
                }
                
                if(size(referenceFiles)){
                    obj.new_reference_files = getReferenceFileNames(referenceFiles)
                } else if(!isEmpty(activeObjective.new_reference_files)) {
                    obj.new_reference_files = []
                }

                if(wallpaperImageFile){
                    updateWallpaper(obj._id, wallpaperImageFile, wallpaperPreviewImage)
                    obj.new_wallpaper = wallpaperImageFile.name
                    obj.wallpaper = ''
                }

                if(iconImageFile){
                    updateIcon(obj._id, iconImageFile, iconPreviewImage)
                    obj.new_icon = iconImageFile.name
                    obj.icon = ''
                }

                updated = true
            }

            if(isEmpty(obj.sub_objectives)){
                return
            }

            obj.sub_objectives.forEach(subObjective => func(subObjective));
        }

        func(objectiveCopy)
        return objectiveCopy
    }

    function getReferenceFileNames(files){
        return files.map(file => file.name)
    }

    function getExistinglinks(links){
        return activeObjective.reference_links.filter(referenceLink => links.includes(referenceLink.url))
    }

    function getAddedlinks(links){
        return links.filter(link => !activeObjective.reference_links.map(referenceLink => referenceLink.url).includes(link))
    }

    function setImagePreview(file, setState){
        const reader = new FileReader()
        reader.onloadend = () => {
            setState(reader.result)
        }
        reader.readAsDataURL(file)
    }

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index !== i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index !== i
            })
            return newState
        })
    }

    function handleReferenceFileAddition(files) {
        setReferenceFiles(prevState => {
            return size(prevState) ? [...prevState, ...files] : [...files]
        })
    }

    return (
            !isEmpty(activeObjective) ?
            <div>
                <div>
                    <ProcessWallpaperEdit 
                        ref={wallpaperimageRef}
                        onRemoveClick={() => {
                            onRemoveWallpaperClick()
                            handleWallpaperMenuClose()
                        }}
                        onUploadClick={(event) => {
                            event.preventDefault();
                            wallpaperimageRef.current.click();
                            handleWallpaperMenuClose()
                        }}
                        handleMenuClose={handleWallpaperMenuClose}
                        menuOpen={wallpaperMenuOpen}
                        anchorEl={wallpaperAnchorEl}
                        previewImage={wallpaperPreviewImage ? wallpaperPreviewImage : activeObjective.wallpaper ? activeObjective.wallpaper : ""}
                        handleEditIconClick={handleWallpaperEditIconClick}
                        onInputChange={(event) =>{
                            const file = event.target.files[0];
                            if(file && file.type.substr(0,5) === "image"){
                                setWallpaperImageFile(file)
                                setImagePreview(file, setWallpaperPreviewImage)
                            }
                        }}
                        disableRemove={isEmpty(activeObjective.wallpaper) ? true : wallpaperPreviewImage ? Boolean(wallpaperPreviewImage) : false}
                    />
                    <ProcessIconEdit
                        ref={iconImageRef}
                        onRemoveClick={() => {
                            onRemoveIconClick()
                            handleIconMenuClose()
                        }}
                        onUploadClick={(event) => {
                            event.preventDefault();
                            iconImageRef.current.click()
                            handleIconMenuClose()
                        }}
                        handleMenuClose={handleIconMenuClose}
                        menuOpen={iconMenuOpen}
                        anchorEl={iconAnchorEl}
                        previewImage={iconPreviewImage ? iconPreviewImage : activeObjective.icon ? activeObjective.icon : ""}
                        handleEditIconClick={handleIconEditIconClick}
                        onInputChange={(event) =>{
                            const file = event.target.files[0];
                            if(file && file.type.substr(0,5) === "image"){
                                setIconImageFile(file)
                                setImagePreview(file, setIconPreviewImage)
                            }
                        }}
                        disableRemove={isEmpty(activeObjective.icon) ? true : iconPreviewImage ? Boolean(iconPreviewImage) : false}
                    />
                </div>
                <div className='mt-4'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        innerRef={formRef}
                        onSubmit={onSubmit}>
                        {props => {
                            const { values, touched, errors, handleBlur, handleChange } = props
                            return (
                                <>
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item md={4} xs={12}>
                                                <InputBox type="text"
                                                    name="name"
                                                    label="Name"
                                                    placeholder="Enter the VCF name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.name && errors.name ? errors.name : null}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <DatePicker type="date"
                                                    label="Start Date"
                                                    name="start_date"
                                                    min={!isEmpty(parentObjective) ? formatDate(parentObjective.start_date) : ""}
                                                    max={!isEmpty(parentObjective) ? formatDate(parentObjective.end_date) : ""}
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.start_date && errors.start_date ? errors.start_date : null}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <DatePicker type="date"
                                                    name="end_date"
                                                    label="End Date"
                                                    min={!isEmpty(parentObjective) ? formatDate(parentObjective.start_date) : ""}
                                                    max={!isEmpty(parentObjective) ? formatDate(parentObjective.end_date) : ""}
                                                    value={values.end_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.end_date && errors.end_date ? errors.end_date : null}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className="mt-3">
                                            <TextAreaBox name="description"
                                                placeholder="Enter the VCF description"
                                                value={values.description}
                                                rows={isDesktop ? 4 : ''}
                                                label="Description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errormgs={touched.description && errors.description ? errors.description : null}
                                            />
                                        </div>
                                        <div className="">
                                            <FieldArray name="reference_links" >
                                                {fieldArrayProps => {
                                                    const { push, remove, form } = fieldArrayProps
                                                    const { values } = form 
                                                    const { reference_links } = values
                                                    return (
                                                        <div>
                                                            {reference_links.map((file,index) => (
                                                                <>
                                                                <Grid container spacing={2} sx={{marginTop:"10px"}}>
                                                                    <Grid item xs={12} md={4} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].originalname`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <>
                                                                                        <InputBox type="text" 
                                                                                            label="Link Name"
                                                                                            placeholder="Please enter link name"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item md={ index === 0 ? 8 : 7} xs={index === 0 ? 12 : 11} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].url`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <div>
                                                                                        <InputBox type="url" 
                                                                                            label="Link Url"
                                                                                            placeholder="http://www.example.com"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    {index > 0 ?
                                                                        <Grid item md={1} xs={1} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                            <div className='d-flex justify-content-center mt-1'>
                                                                                <IconButton onClick={() => remove(index)}>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                    </Grid> : null }
                                                                </Grid>
                                                                </>
                                                            ))}
                                                            <div className='mt-2'>
                                                                <CustomizeButton variant="secondary-start-icon-button" label="Add link" StartIcon={<AddIcon/>} handleClick={() => push(emptyLinkObj)} />
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </FieldArray>
                                        </div>
                                        <div className="p-relative mt-4">
                                            <div>
                                                { !isEmpty(existingReferences) ? existingReferences.map((reference, index) => (
                                                        <div key={index} className="mb-1 d-flex">
                                                            <a  href={reference.url} target="_blank" className="filename lato-fs15-normal-lh20 mb-0 pb-1 one-line-wrap"> {reference.originalname}</a>
                                                            <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                        </div>
                                                    )
                                                ) : null}
                                                { referenceFiles.length > 0 ? referenceFiles.map((file,index)=>(
                                                    <div key={index} className="mb-1 d-flex">
                                                        <p className="lato-fs15-normal-lh20 mb-0 pb-1 one-line-wrap"> { file.filename ? file.filename : file.name}</p>
                                                        <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor"
                                                            onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                    </div>
                                                )) : null}
                                            </div>
                                            <div className='mt-3'>
                                                <UploadFiles 
                                                    variant={isDesktop ? '' : 'mobile-view'}
                                                    label="Click to upload attachment"   
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        referenceFileRef.current.click();
                                                    }} />
                                            </div>
                                        </div>
                                        <input type="file"
                                            style={{display:"none"}} 
                                            ref={referenceFileRef} 
                                            multiple
                                            accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx"
                                            name="objective_reference"
                                            onChange={(event) =>{
                                                const files = event.target.files
                                                if(files){
                                                    handleReferenceFileAddition(files)
                                                }
                                        }} />
                                        <div className="d-flex justify-content-center align-center mt-4">
                                            <CustomizeButton className="me-4"
                                                variant="secondary-color-button" 
                                                label="Cancel" 
                                                handleClick={ownProps.onCancelBtnClick} />
                                            <CustomizeButton variant="primary-color-button" label="Save" type="Submit" />
                                        </div>
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </div>
            </div> 
            : null
    )
}

const mapStateToProps =(state, ownProps) => {
    return {
        ownProps : ownProps,
        modifyObjectiveState : state.modifyObjective
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveModifiedObjective : (objective) => dispatch(modifyObjectiveSuccess(objective)),
        updateReferenceFiles : (objectiveId, referenceFiles) => dispatch(addObjectiveReferenceFile(objectiveId, referenceFiles)),
        updateWallpaper : (objId, newFile, data) => dispatch(addObjectiveWallpaper(objId, newFile, data)),
        updateIcon : (objId, newFile, data) => dispatch(addObjectiveIcon(objId, newFile, data)),
        saveOneValidateObj : (payload) => dispatch(updateOneValidateObj(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyObjectiveEditForm)