import React,{ useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { clearDevelopmentAreaState } from '../../redux/video-conference/development/Development.action'
import { requestDevelopmentAreaForSelect } from '../../services/videoConference.service'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const DevelopmentAreaSelect = (props) => {

    const { developmentAreaState, 
            requestDevelopmentArea, 
            clearDevelopmentArea, 
            ownProps, 
            selectedValue, 
            setSelectedValue } = props

    const learningCircles = developmentAreaState.response.learning_circles

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ selectedDevelopmentArea, setSelectedDevelopmentArea ] = useState([])
    const [ validation, setValidation ] = useState(false)

    useEffect(() => {
        if(isEmpty(developmentAreaState.response)){
            requestDevelopmentArea()
        }

        setSelectedDevelopmentArea(selectedValue)
        return () => {
            clearDevelopmentArea()
        }
    },[])

    useEffect(() => {
        if(!developmentAreaState.loading && !isEmpty(developmentAreaState.response)){
            // console.log(developmentAreaState.response)
            setPageLoading(false)
        }

        if(!developmentAreaState.loading && !isEmpty(developmentAreaState.error)){
            console.log(developmentAreaState.error)
            setPageLoading(false)
            if(developmentAreaState.error.data){
                toast.error(developmentAreaState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[developmentAreaState])

    function formattedDevelopmentOptions(){
        let options=[]
        for(let circle of learningCircles){
            let developmentArea =[]

            for(let learningArea of circle.learning_areas){
                developmentArea.push({
                    label : learningArea.name,
                    value : learningArea._id
                })
            }
            options.push({label: circle.name, options : developmentArea})
        }
        return options
    }

    function handleInputChange(value){
        setValidation(false)
        setSelectedDevelopmentArea(value)
    }

    function handleProceedEvent(){
        if(!isEmpty(selectedDevelopmentArea)){
            setSelectedValue(selectedDevelopmentArea)
            ownProps.onCancel()
        }else{
            setValidation(true)
        }
    }

    return (
        !pageLoading ?
            <>
                <Select 
                    closeMenuOnSelect={false}
                    isMulti
                    menuPortalTarget={document.body}
                    styles={{ 
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        control: (styles ,{ isFocused }) => ({
                                ...styles,
                                ':hover': { borderColor: '#14213d' }, // border style on hover
                                border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                boxShadow: isFocused ? '' : "", // no box-shadow
                            }),
                        option : (styles) => ({
                            ...styles,
                            fontSize : "15px",
                            fontFamily :"'Lato',sans-serif"
                        })
                    }}
                    options={formattedDevelopmentOptions()}
                    onChange={handleInputChange}
                    value = {selectedDevelopmentArea}
                />
                { validation ? <p className="error-message mt-1">Please select development</p> : ""}
                <div className="mt-3 d-flex justify-content-center">
                    <CustomizeButton variant="primary-color-button" handleClick={handleProceedEvent} label="Proceed"/>
                </div>
            </> : null
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        developmentAreaState : state.developmentArea,
        ownProps : ownProps,
        setSelectedValue : ownProps.setSelectedValues,
        selectedValue : ownProps.selectedValue,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestDevelopmentArea : () => dispatch(requestDevelopmentAreaForSelect()),
        clearDevelopmentArea : () => dispatch(clearDevelopmentAreaState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DevelopmentAreaSelect)