import { REQUEST_OBJECTIVE_REVIEW, 
        FETCH_OBJECTIVE_REVIEW_SUCCESS, 
        FETCH_OBJECTIVE_REVIEW_FAILURE, 
        CLEAR_OBJECTIVE_REVIEW_STATE, 
        REVIEW_OBJECTIVE_ALL_ACTION_CHANGED} from './objectiveReview.types'

const initialState = {
    loading : false,
    action_changed : false,
    response : {},
    error : null
}

const objectiveReviewAllReducer = ( state = initialState, action ) =>  {
    switch(action.type) {
        case REQUEST_OBJECTIVE_REVIEW :
            return{
                ...state,
                loading : true,
                action_changed : false,
                error : null
            }

        case FETCH_OBJECTIVE_REVIEW_SUCCESS : 
            return {
                ...state,
                loading : false,
                action_changed : false,
                response : action.payload
            }

        case FETCH_OBJECTIVE_REVIEW_FAILURE : 
            return {
                ...state,
                loading : false,
                action_changed : false,
                error : action.payload
            }

        case REVIEW_OBJECTIVE_ALL_ACTION_CHANGED : 
            return {
                ...state,
                loading : true,
                action_changed : true,
            }

        case CLEAR_OBJECTIVE_REVIEW_STATE : 
            return {
                ...state,
                loading : false,
                action_changed : false,
                response : {},
                error : null
            }

        default : 
            return state
    }
}

export default objectiveReviewAllReducer