import React,{ useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { isEmpty, toArray } from 'lodash'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { faThLarge, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
// import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import Check from '@mui/icons-material/Check'
import Collapse from '@mui/material/Collapse'
import useMediaQuery from '@mui/material/useMediaQuery'
import qs from 'query-string'

import './objective-details.css'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { deleteObjectivePromise } from '../../../services/objectives.service'
import DeletePopUp from '../../../components/delete-popup/DeletePopUp'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import { clearSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import { requestSpecificObjective } from '../../../services/review.service'
import ObjectiveCard from '../../../components/objective-card/ObjectiveCard'
import { getRole, getUserId } from '../../../utils/utils'
import { REVIEW_TYPES, PROCESS_STATUS, CONTROLLER_ROLE } from '../../../utils/constants'
import { fetchObjectives } from '../../../services/fetchObjectives.service'
import { clearObjectiveState as clearObjectiveForSelectStates } from '../../../redux/video-conference/objective/Objective.action'
import ReferenceFiles from '../../../components/reference-files/ReferenceFiles'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import Container from '../../../components/desktop-layout/container/Container'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import ProcessWallpaperIcon from '../../../components/desktop-layout/process-wallpaper-icon/ProcessWallpaperIcon'
import Description from '../../../components/desktop-layout/description/Description'
import Header from '../../../common/header/Header'
import LevelIndicator from '../../../components/level-indicator/LevelIndicator'
import Name from '../../../components/name/Name'

const useStyles = makeStyles({
    hamberIcon: {
        left:"unset !important",
        top:"78px !important",
        right:"10px",
        zIndex:"1"
    }
});

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'rgba(255,122,102,255)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'rgba(255,122,102,255)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
        borderLeftWidth : 2,
        marginLeft :"-6px !important",
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: 'rgba(255,122,102,255)',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: 'rgba(255,122,102,255)',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

const useStepperStyle = makeStyles({
    root: {
        width: '100%',
        paddingLeft :10,
    },
    stepContainer :{
        backgroundColor : "transparent",
    },
    label :{
        fontSize : 15,
        color : "rgba(68,68,68,255) !important",
        fontWeight : "600 !important",
        fontFamily : '"oxygen",sans-serif',
    },
    active :{
        color : "rgba(255,122,102,255) !important"
    },
});

const ObjectivesDetails = (props) => {

    const { 
        objectiveDetailState,
        requestObjective,
        requestSpecificObjective,
        clearObjectiveForSelectStates,
        objectiveForSelectState,
        clearSpecificObjectiveState
    } = props

    const [contentLoading, setContentLoading] = useState(true)
    const [ objective, setObjective ] = useState({})
    const [deletePopup, setDeletePopup] = useState(false)
    const [ collapse, setCollapse ] = useState(false)
    const [ menu, setMenu ] = useState(null)
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const history = useHistory()
    const params = useParams()
    const classes = useStyles()
    const stepperStyle = useStepperStyle()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    const open = Boolean(menu)

    const handleClick = (event) => {
        setMenu(event.currentTarget)
    };

    const handleClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }
    
    const REVIEW_TYPE = {
        new_creation_request : "Create request",
        edit_request : "Edit request",
        delete_request : "Delete request"
    }

    function backArrowEvent(){
        history.goBack()
    }

    function toggleDeletePopup() {
        setDeletePopup(!deletePopup)
    }

    useEffect(() => {
        if(!objectiveDetailState.loading && !isEmpty(objectiveDetailState.response)){
            setObjective(objectiveDetailState.response.objective)
            setContentLoading(false)
        }
        if(!objectiveDetailState.loading && !isEmpty(objectiveDetailState.error)){
            console.error(objectiveDetailState.error)
            setContentLoading(false)
            if(!isEmpty(objectiveDetailState.error.data)){
                toast.error(objectiveDetailState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

        if(objectiveDetailState.loading && objectiveDetailState.updated){
            requestSpecificObjective(params.vcfId)
        }

    }, [objectiveDetailState])

    useEffect(() => {
        if(isEmpty(objectiveDetailState.response)){
            requestSpecificObjective(params.vcfId)
        }
        if(!isEmpty(objectiveForSelectState.response)){
            clearObjectiveForSelectStates()
        }

        // return () => {
        //     clearSpecificObjectiveState()
        // }

    }, [])

    function handleAddSubObjectiveClick() {
        history.push(`/vcf/create?vcf_id=${objective._id}&level=${objective.level}`)
    }

    function handleEditEvent(){
        history.push(`/vcf/edit?vcf_id=${objective._id}&level=${objective.level}`)
    }

    function handleDeleteObjective(){
        deleteObjectivePromise(objective._id)
        .then((res) => {
            requestObjective()
            history.push(`/vcf?mode=list-vcfs-by-lvl&lvl=0`)
        }).catch(err => {
            console.log(err)
            if(err.response?.data){
                toast.error(err.response.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        })
    }

    const isStepReviewer = (step) => {
        return step === 1;
    };

    const isStepSuperReviewer = (step) => {
        return step === 2;
    };

    function getStepperLabel(){
        let label = []
        if(!isEmpty(objective)){
            if(objective.status == "needs_approval"){
                if(objective.review_status){
                    if(objective.review_status.reviewer_needed && objective.review_status.super_reviewer_needed){
                        label.push(REVIEW_TYPE[objective.review_status.review_type],"Reviewer","Super reviewer")
                    } else if (objective.review_status.reviewer_needed){
                        label.push(REVIEW_TYPE[objective.review_status.review_type],"Reviewer")
                    } else if (objective.review_status.super_reviewer_needed){
                        label.push(REVIEW_TYPE[objective.review_status.review_type],"Super reviewer")
                    }
                }
            }
        }
        return label
    }

    function activeStep(){
        if(!isEmpty(objective)){
            if(objective.status == "needs_approval"){
                if(!isEmpty(objective.review_status)){
                    if(objective.review_status.reviewer_needed && objective.review_status.super_reviewer_needed){
                        if(objective.review_status.reviewer_status == "pending"){
                            return 0
                        }else{
                            return 1
                        }
                    }else if(objective.review_status.reviewer_needed){
                        if(objective.review_status.reviewer_status == "pending"){
                            return 0
                        }
                    }else if(objective.review_status.super_reviewer_needed){
                        if(objective.review_status.super_reviewer_status == "pending"){
                            return 0
                        }
                    }
                }
            }
        }
    }

    function getValue(key, objective){
        if((objective.status === PROCESS_STATUS.needsApproval) && (objective.review_status.review_type === REVIEW_TYPES.editRequest)){
            return objective.edits[key]
        } else {
            return objective[key]
        }
    }

    function getPath(){
        if(!isEmpty(objective)){
            let pathArray =  toArray(objective.path)
            return pathArray.splice(0,pathArray.length-1)
        } else {
            return null
        }
    }

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function handleOnNotificationsIconClick(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    function getobjectiveDetail(){
        return (
            <>
                {
                    isDesktop ?
                        <Header platform="desktop" 
                            verticalEllipsisEvent={handleClick}
                            handleClose={handleClose}
                            menu={menu}
                            open={open}
                            editEvent={handleEditEvent}
                            deleteEvent={toggleDeletePopup}
                            start={
                                <>
                                    {
                                        objective.level > 0 ? 
                                        <BreadCrumbs
                                            path={getPath()}
                                            icon={faThLarge} 
                                            truncate={isMobile}/>
                                        : null
                                    }
                                </>
                            }
                        />
                    :null
                }
                {
                    !isDesktop ? 
                        <div className="d-flex align-items-center mb-3">
                            {
                                objective.level > 0 ? 
                                <BreadCrumbs
                                    path={getPath()}
                                    icon={faThLarge} 
                                    truncate={isMobile}/>
                                : null
                            }
                        </div> 
                    : null
                }
                <ProcessWallpaperIcon
                    wallpaper={objective.wallpaper} 
                    icon={objective.icon}
                    name={objective.name} 
                    enableMoreIcon={(getRole() !== CONTROLLER_ROLE && queryParams.company_view) || !isDesktop ? false : true}
                    levelIndicator={true}
                    level={objective.level}
                    maxLevel={objective.max_level}
                    variant={isDesktop ? 'desktop' : 'mobile'}
                />
                <div className='mt-3 d-flex'>
                    <div>
                        <Name name={objective?.name} />
                    </div>
                    <div className='ms-3 mt-2'>
                        <LevelIndicator 
                            level={objective.level}
                            maxLevel={objective.max_level}
                            containerClassName="d-flex flex-column align-items-center"
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center mt-2">
                    {
                        objective.description ? 
                            <Description description={objective?.description} />
                            : null
                    }
                </div>
                {
                    !isEmpty(getValue('reference_files',objective)) || !isEmpty(getValue('reference_links',objective)) ? 
                    <>
                        <div className='mt-5'>
                            <ReferenceFiles referenceFiles={getValue('reference_files',objective)} 
                                            referenceLinks={getValue('reference_links',objective)} />
                        </div> 
                    </> : null
                }
                {objective.status === "needs_approval" ? 
                    <div className="progress-chip" onClick={() => setCollapse(ps => !ps)}>
                        <div className="d-flex align-items-center">
                            <p className="mb-0 mr-2 secondary-color" >{ collapse ? "Hide Approval Progress" : "Show Approval Progress"}</p>
                            <FontAwesomeIcon className="orange-color" icon={ collapse ? faChevronUp : faChevronDown}  />
                        </div>
                    </div> 
                    : null 
                }
                <Collapse in={collapse}>
                    <div >
                    { objective.status === "needs_approval" ?
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep()} orientation="vertical" className={stepperStyle.stepContainer} connector={<QontoConnector />}>
                                {getStepperLabel().map((label,index) => {
                                    const labelProps ={}
                                    if(isStepReviewer(index)){
                                        labelProps.optional =( 
                                            !isEmpty(objective) && !isEmpty(objective.review_status) ?
                                                objective.review_status.reviewer_needed ?
                                                <div>
                                                    {  objective.review_status.reviewer_status ? 
                                                        <p className="mb-0 fs13-lh15 secondary-color">{objective.review_status.reviewer_status}</p> : null
                                                    }
                                                    { !isEmpty(objective.review_status.reviewed_by) ? 
                                                        !isEmpty(objective.review_status.reviewed_by.person_name) ?
                                                        <p className="mb-0 fs13-lh15 secondary-color">by <a>{ getUserId() === objective.review_status.reviewed_by._id ? 'You' : `${objective.review_status.reviewed_by.person_name.first_name} ${objective.review_status.reviewed_by.person_name.last_name}`}</a></p> : null : ""}
                                                </div> : 
                                                objective.review_status.super_reviewer_needed ? 
                                                <div>
                                                    {  objective.review_status.reviewer_status ? 
                                                        <p className="mb-0 fs13-lh15 secondary-color">{objective.review_status.super_reviewer_status}</p> : null
                                                    }
                                                </div> : null
                                            : null
                                        )
                                    }
                                    if(isStepSuperReviewer(index)){
                                        labelProps.optional=(
                                            !isEmpty(objective) && !isEmpty(objective.review_status) ?
                                            <div>
                                                {  objective.review_status.super_reviewer_status ? 
                                                    <p className="mb-0 fs13-lh15 secondary-color">{objective.review_status.super_reviewer_status}</p> : null
                                                }
                                            </div> : null
                                        )
                                    }
                                    
                                    return (
                                        <Step key={label}> 
                                            <StepLabel  StepIconComponent={QontoStepIcon}
                                                StepIconProps={{ classes : {
                                                    active : stepperStyle.active,
                                                    completed : stepperStyle.active
                                                } }} 
                                                {...labelProps} classes={{label : stepperStyle.label}}>
                                                    {label}
                                            </StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </div> : null
                    }
                    </div>
                </Collapse>
            </>
        )
    } 

    return (
        <>
            {
                deletePopup ? 
                    <DeletePopUp open={deletePopup}
                        handleClose={toggleDeletePopup} 
                        onClick={handleDeleteObjective} 
                        msg={`${getValue('name', objective)} with its sub VCFs, goals and metrics will be deleted permanently. Are you sure?` } 
                    />
                    : null
            }
            {
                isDesktop ?
                    <>
                        <Container>
                            <Navbar 
                                enableMore={true}
                                enableLogout={true}
                                enableProfileButton={true}
                                open={navbarOpen}
                                menu={navbarMoreMenu}
                                handlePopoverClose={handleNavbarMoreMenuClose}
                                handlePopoverOpen={handleNavbarMoreMenuOpen}
                                enableNotification={true}
                                notificationOnClick={handleOnNotificationsIconClick}
                            />
                            <ProcessSectionContainer>
                            <LeftVerticalMenuBar addIcon={true} addIconTooltip={`Create VCF ${objective?.name ? " inside " + objective.name : ""}`} onAddIconClick={handleAddSubObjectiveClick}/>
                                <ProcessMainContainer className="white-bg">
                                    <SubMainContainer>
                                        {
                                            !contentLoading && !isEmpty(objective) ?
                                                <div className='mb-40' style={{marginTop:"24px"}}>
                                                    {getobjectiveDetail()}
                                                </div>
                                            : null
                                        }
                                    </SubMainContainer>
                                </ProcessMainContainer>
                            </ProcessSectionContainer>
                        </Container>
                    </>
                    : <>
                        <div className="d-flex flex-column pb-70 vh-100">
                            <Navbar 
                                variant="mobile" 
                                enableLogout={true}
                                enableDrawer={true}
                                drawerOpened={drawer}
                                handleDrawerClose={toggleDrawer}
                                onDrawerClick={toggleDrawer}
                                enableNotificationsIcon={true}
                                onNotificationsIconClick={handleOnNotificationsIconClick}
                            />
                            <div className='container'>
                                <div className='mt-3 px-3'>
                                    <Header handleBackArrowEvent={() => history.goBack()}
                                        moreIcon={true}
                                        moreIconEvent={handleClick}
                                        open={open}
                                        menu={menu}
                                        handleClose={handleClose}
                                        handleEditEvent={handleEditEvent}
                                        handleDeleteEvent={toggleDeletePopup}
                                    />
                                </div>
                                <div className="pb-70 px-3">
                                    {
                                        !contentLoading && !isEmpty(objective) ?
                                            getobjectiveDetail()
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <BottomNavigation onAddIconClick={handleAddSubObjectiveClick} enableAddIcon={true} />
                    </>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        objectiveDetailState : state.reviewSpecificObjectiveDetail,
        objectiveForSelectState : state.objectiveForSelect
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clearSpecificObjectiveState : () => dispatch(clearSpecificObjectiveForReview()),
        requestSpecificObjective : (objectiveId) => dispatch(requestSpecificObjective(objectiveId)),
        requestObjective: () => dispatch(fetchObjectives()),
        clearObjectiveForSelectStates : () => dispatch(clearObjectiveForSelectStates())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ObjectivesDetails)