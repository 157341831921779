import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isAuthenticated } from '../utils/utils'

const PublicRoute = ({component: Component, redirectPathname: targetpath, ...rest}) => {

    return (
        <Route 
            {...rest}
            render= {(props) => {
                if(isAuthenticated()){
                    return <Redirect 
                        to={{ pathname : targetpath,
                            state : {from: props.location}
                        }}
                    />
                }else {
                    return <Component />
                }
            }}
        />
    )
}

export default PublicRoute
