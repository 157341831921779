import { isEmpty, size } from 'lodash'
import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { Field, Form, Formik } from 'formik'

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { fetchMembers } from '../../services/user.service'
import { boldString } from '../../utils/utils'
import MemberCard from '../member-card/MemberCard'
import './members.css'
import { clearMemberState } from '../../redux/user/Members/members.action'
import { fetchDepartments } from '../../services/departments.service'
import { fetchBranches } from '../../services/branch.service'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { clearMemberDetailState } from '../../redux/user/member-detail/membersDetail.action'

const drawerWidth = 240;

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop:113,
        height:819,
    },
    mobileDrawerPaper : {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
    },
});

const Members = (props) => {

    const { 
        requestMembers, 
        membersState, 
        departmentsState, 
        requestDepartments, 
        branchState,
        requestBranch,
        clearMemberState,
        memberDetailState,
        clearMemberDetailState
    } = props

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [ pageLoading, setPageLoading ] = useState({
        member: true,
        department : true,
        branch : true
    })

    const members = membersState.response.active_users
    const activeDepartment = departmentsState.response.active_departments
    const activeBranch = branchState.response.active_branches

    const theme = useTheme();
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false)

    const initialValues = {
        branches : [],
        departments : []
    }

    const onSubmit = (values) => {
        // console.log(values)
        requestMembers(values)
        if(mobileOpen === true){
            handleDrawerToggle()
        }
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const getDrawerItems = () => {
        return (
            <Formik
                initialValues={initialValues} 
                onSubmit={onSubmit} >
                {(props) => (
                    <Form>
                        <div className="d-flex justify-content-between px-4 py-2 align-items-center">
                            <h4 className='lato-fs-20-normal-lh32 font-bold mb-0' >Filters</h4>
                            <CustomizeButton 
                                label="Apply"
                                type="submit"
                                variant="primary-color-button"
                            />
                        </div>
                        <hr className='my-0'/>
                        <div className="px-4 py-3">
                            <label className="lato-fs16-normal-lh20 tertiary-text-color-v2 mb-2" >Department:</label>
                            {
                                !isEmpty(activeDepartment) ?
                                <div role="group" aria-labelledby="checkbox-group">
                                    { 
                                        activeDepartment.map((department, index) => (
                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                <Field type="checkbox" name="departments" className="my-1" value={department._id} />
                                                <label className="ms-2 mb-0 lato-fs15-normal-lh20">
                                                    {department.name}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div> : 
                                <div>
                                    <p className="lato-fs15-normal-lh20 mb-0">You don't have any department yet</p>
                                </div>
                            }
                            <hr />
                            <label className="lato-fs16-normal-lh20 tertiary-text-color-v2 mb-2"> Location:</label>
                            {
                                !isEmpty(activeBranch) ?
                                <div role="group" aria-labelledby="checkbox-group">
                                    { 
                                        activeBranch.map((branch, index) => (
                                            <div className="d-flex align-items-center mb-2" key={index}>
                                                <Field type="checkbox" name="branches" className="my-1" value={branch._id} />
                                                <label className="ms-2 mb-0 lato-fs15-normal-lh20">
                                                    {branch.city}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div> : 
                                <div>
                                    <p className="lato-fs15-normal-lh20 mb-0">You don't have any locations yet</p>
                                </div>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }
    
    useEffect(() => {
        requestMembers()
        if(isEmpty(departmentsState.response)){
            requestDepartments()
        }
        if(isEmpty(branchState.response)){
            requestBranch()
        }

        if(!isEmpty(memberDetailState.response)){
            clearMemberDetailState()
        }

        return () => {
            clearMemberState()
        }
    }, [])

    useEffect(() => {
        if(!departmentsState.loading && !isEmpty(departmentsState.response)){
            // console.log("department",departmentsState.response.active_departments)
            setPageLoading((ps) => {
                return {
                    ...ps,
                    department :false
                }
            })
        }

        if(!departmentsState.loading && !isEmpty(departmentsState.error)){
            console.log(departmentsState.error)
            setPageLoading((ps) => {
                return {
                    ...ps,
                    department :false
                }
            })
            if(!isEmpty(departmentsState.eror.data) ){
                toast.error(departmentsState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[departmentsState])

    useEffect(() => {
        if(!branchState.loading && !isEmpty(branchState.response)){
            // console.log("branch",branchState.response.active_branches)
            setPageLoading((ps) => {
                return {
                    ...ps,
                    branch : false
                }
            })
        }

        if(!branchState.loading && !isEmpty(branchState.error)){
            setPageLoading((ps) => {
                return {
                    ...ps,
                    branch : false
                }
            })
            console.log(branchState.error)
            if( !isEmpty(branchState.eror.data) ){
                toast.error(branchState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[branchState])

    useEffect(() => {
        if(!membersState.loading && !isEmpty(membersState.response)){
            setPageLoading((ps) => {
                return {
                    ...ps,
                    member : false
                }
            })
        }

        if(!membersState.loading && !isEmpty(membersState.error)){
            setPageLoading((ps) => {
                return {
                    ...ps,
                    member : false
                }
            })
            console.log(membersState.error)
            if( !isEmpty(membersState.eror.data) ){
                toast.error(membersState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[membersState])

    function concatAllUserNames(receiver){
        let receiverString = ''
        if(receiverString){
            receiverString = receiver.first_name + " " + receiver.last_name
        } else {
            receiverString += receiver.first_name
        }
        return receiverString
    }

    function handleCardClick(userId){
        history.push(`/organisation/member/${userId}`)
    }

    function getName(name){
        let str = ''
        if(name.first_name){
            str += `${name.first_name} `
        }
        if(name.middle_name){
            str += `${name.middle_name} `
        }
        if(name.last_name){
            str += name.last_name
        }
        return str
    }

    function getAppropriateMemberCard(){
        if(props.searchedValue.value){
            if(!props.searchedValue.typing){
                if(!isEmpty(props.searchedValue.data)){
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            {
                                props.searchedValue.data.map((member,index) => (
                                    <div key={index}>
                                        <MemberCard
                                            handleCardClick={() => handleCardClick(member._id)}
                                            className="pt-cursor"
                                            role={
                                                member.user_subscriber_mapping[0].job_role ?
                                                    `${member.user_subscriber_mapping[0].job_role} (${member.user_subscriber_mapping[0].role === "user_cum_controller" ? "Controller" : "User"})` 
                                                    : member.user_subscriber_mapping[0].role === "user_cum_controller" ? "Controller" : "User"
                                            }
                                            name={boldString(getName(member.person_name), props.searchedValue.value)} 
                                            profilePic={member.profile_pic ? member.profile_pic : "" }
                                            profile={true}
                                            emailId={member.email.id}
                                        />
                                    </div>
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <>
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            <p className='lato-fs15-normal-lh20'>No members found for search results "<span className='lato-fs15-normal-lh20 font-bold'>{props.searchedValue.value}</span>"</p>
                        </>
                    )
                }
            } else {
                return (
                    <Box className="d-flex justify-content-center align-items-center">
                        <CircularProgress sx={{color: 'var(--primary-color-v2)'}} />
                    </Box>
                )
            }
        } else {
            if(!pageLoading.member && !pageLoading.branch && !pageLoading.department) {
                if(!isEmpty(members)){
                    return (
                        <>
                            <h6 className='lato-fs15-normal-lh20 tertiary-text-color-v2 mb-3'>Members: {size(members)}</h6>
                            { 
                                members.map((member,index) => (
                                    <div key={index}>
                                        <MemberCard 
                                            handleCardClick={() => handleCardClick(member._id)}
                                            className="pt-cursor"
                                            role={
                                                member.user_subscriber_mapping[0].job_role ?
                                                    `${member.user_subscriber_mapping[0].job_role} (${member.user_subscriber_mapping[0].role == "user_cum_controller" ? "Controller" : "User"})` 
                                                    : member.user_subscriber_mapping[0].role == "user_cum_controller" ? "Controller" : "User"
                                            }
                                            name={getName(member.person_name)} 
                                            profilePic={member.profile_pic ? member.profile_pic : "" }
                                            profile={true}
                                            emailId={member.email.id}
                                        />
                                    </div>
                                ))
                            }
                        </>
                    )
                } else {
                    return (
                        <>
                            <p className="lato-fs18-normal-lh25">No members</p>
                        </>
                    )
                }
            } else {
                return null
            }
        }
    }

    return (
        isDesktop ?
            <>
                <div className='flex-fill bg-tertiary-color-v2 overflow-auto pt-4 pb-3' style={{paddingLeft: '7%', paddingRight: '7%'}}>
                    {getAppropriateMemberCard()}
                </div>
                <div style={{width: '27%', backgroundColor: 'white'}} className={props.searchedValue.value ? 'opacity-50 pe-none overflow-auto' : "overflow-auto"}>
                    {getDrawerItems()}
                </div>
            </>
            : <div className='container pt-3 pb-70 px-3'>
                {
                    !pageLoading.member && !pageLoading.branch && !pageLoading.department ?
                        <>
                            <div className="d-flex justify-content-end">
                                <IconButton
                                    disabled={Boolean(props.searchedValue.value)}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                >
                                    <FilterAltIcon />
                                </IconButton>
                            </div>
                            <Drawer
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.mobileDrawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block' },
                                    '& .MuiDrawer-paper': { 
                                        boxSizing: 'border-box', 
                                        width: drawerWidth 
                                    },
                                }}
                            >
                                {getDrawerItems()}
                            </Drawer>
                            {getAppropriateMemberCard()}
                        </>
                        : null 
                }
            </div>
    )
}

const mapStateToProps = (state) => {
    return {
        membersState : state.members,
        departmentsState : state.departments,
        branchState : state.branches,
        memberDetailState : state.memberDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestMembers : (payload) => dispatch(fetchMembers(payload)),
        requestDepartments : () => dispatch(fetchDepartments()),
        requestBranch : () => dispatch(fetchBranches()),
        clearMemberState : () => dispatch(clearMemberState()),
        clearMemberDetailState : () => dispatch(clearMemberDetailState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Members)