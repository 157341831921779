import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';

const ImgMenu = (props) => {

    return (
        <Menu
            id={props.id || "basic-menu"}
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.onClose}
            MenuListProps={{
                'aria-labelledby': props.ariaLabelledBy || 'basic-button',
            }}
        >
            <MenuItem
                sx={{ 
                    paddingLeft:"13px",
                    paddingRight:"13px", 
                    fontSize:"15px", 
                    fontFamily:"'Lato',sans-serif", 
                    paddingBottom:"3px" 
                }}
                disabled={Boolean(props.disableUpload)} 
                onClick={props.onUploadClick}
            >
                <FileUploadIcon className="me-2 secondary-color" />
                Upload
            </MenuItem>
            <MenuItem
                sx={{ 
                    paddingLeft:"13px",
                    paddingRight:"13px", 
                    fontSize:"15px", 
                    fontFamily:"'Lato',sans-serif", 
                    paddingBottom:"3px" 
                }}
                disabled={Boolean(props.disableRemove)} 
                onClick={props.onRemoveClick}
            >
                <DeleteIcon className="me-2 secondary-color" />
                Remove
            </MenuItem>
        </Menu>
    )
}

export default ImgMenu