import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'

const StyledTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#ff806a',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(1),
    color: 'rgba(156,155,185,255)',
    fontFamily: '"Lato",sans-serif',
    fontSize:"15px",
    '&.Mui-selected': {
      color: 'var(--primary-color-v2)',
      fontWeight: 600,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
}));

const DesktopStyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'var(--primary-color-v2)',
  },
});

const DesktopStyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  marginRight: "25px",
  marginLeft: "25px",
  color: 'rgba(156,155,185,255)',
  fontFamily: '"Lato",sans-serif',
  fontSize:"15px",
  fontWeight:"600",
  '&.Mui-selected': {
    color: '#14213d',
    fontWeight: "600"
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const SimpleTab = (props) => {

  switch(props.variant){
    case 'desktop':
      return (
        <DesktopStyledTabs 
          value={props.value} 
          onChange={props.onChange} 
          aria-label="basic tabs example" 
          variant={props.tabVariant ? props.tabVariant : ''}
          TabIndicatorProps={{style: {background:'#14213d'}}}
        >
          {
            props.tabArray.map((tab,index) => (
              <DesktopStyledTab label={tab.label} value={tab.value} key={uuidv4()} />
            ))
          }
          {props.additionalComponent}
        </DesktopStyledTabs>
      )

    default:
      return (
        <StyledTabs 
          value={props.value} 
          onChange={props.onChange}
          aria-label="basic tabs example"
          variant={props.tabVariant ? props.tabVariant : ''}
          TabIndicatorProps={{style: {background:'#14213d'}}}
        >
          { 
            props.tabArray.map((tab,index) => (
              <StyledTab label={tab.label} value={tab.value} key={uuidv4()} />
            ))
          }
          {props.additionalComponent}
        </StyledTabs>
      )
  }
}

export default SimpleTab