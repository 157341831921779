import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { requestDevelopmentAreaForSelect } from '../../services/videoConference.service'

const DevelopmentTab = (props) => {

    const { developmentAreaState, requestDevelopemntArea } = props

    const [ pageLoading, setPageLoading ] = useState(true)

    const learningCircles = developmentAreaState.response.learning_circles

    function formattedDevelopmentOptions(){
        let options=[]
        for(let circle of learningCircles){
            let developmentArea =[]

            for(let learningArea of circle.learning_areas){
                developmentArea.push({
                    label : learningArea.name,
                    value : learningArea._id
                })
            }
            options.push({label: circle.name, options : developmentArea})
        }
        return options
    }

    function handleInputChange(value){
        props.setSelected( prevState => {
            return {
                ...prevState,
                learningareas : value
            }
        })
    }

    useEffect(() => {
        if(isEmpty(developmentAreaState.response)){
            requestDevelopemntArea()
        }
    },[])

    useEffect(() => {
        if(!developmentAreaState.loading && !isEmpty(developmentAreaState.response)){
            // console.log(developmentAreaState.response)
            setPageLoading(false)
        }

        if(!developmentAreaState.loading && !isEmpty(developmentAreaState.error)){
            console.log(developmentAreaState.error)
            setPageLoading(false)
            if(!isEmpty(developmentAreaState.error)){
                if(developmentAreaState.error.data){
                    toast.error(developmentAreaState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[developmentAreaState])

    return (
            <div className="tab-container-300px px-3 mt-3">
                <h2 className="lato-fs-20-normal-lh32 mb-0 primary-text-color font-bold text-center mb-3">Select Learning Area</h2>
                { !pageLoading ?
                    <Select 
                        closeMenuOnSelect={false}
                        isMulti
                        menuPortalTarget={document.body}
                        styles={{ 
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            control: (styles ,{ isFocused }) => ({
                                    ...styles,
                                    ':hover': { borderColor: '#14213d' }, // border style on hover
                                    border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                    boxShadow: isFocused ? '' : "", // no box-shadow
                                }),
                            option : (styles) => ({
                                ...styles,
                                fontSize : "15px",
                                fontFamily :"'Lato',sans-serif"
                            })
                        }}
                        options={formattedDevelopmentOptions()}
                        onChange={handleInputChange}
                        value = {props.selected.learningareas}
                    /> 
                : null }
            </div> 
    )
}

const mapStateToProps = (state) => {
    return {
        developmentAreaState : state.developmentArea
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestDevelopemntArea : () => dispatch(requestDevelopmentAreaForSelect()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DevelopmentTab)