import { REQUEST_CU_COURSE,
         CU_COURSE_SUCCESS,
         CU_COURSE_FAILURE,
         CLEAR_CU_COURSE } from './CreateEditTypes'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const cuCourseReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_CU_COURSE :
            return {
                ...state,
                loading : true,
                error : null
            }
        
        case CU_COURSE_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case CU_COURSE_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload 
            }

        case CLEAR_CU_COURSE : 
            return {
                ...state,
                loading : false,
                response: {},
                error : null
            }

        default :
            return state
    }
}

export default cuCourseReducer