import React,{ useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { connect } from 'react-redux'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { isEmpty } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import qs from 'query-string'
import useMediaQuery from '@mui/material/useMediaQuery'
import './company-view.css'

import AggregateCard from '../aggregate-card/AggregateCard'
import { toast } from 'react-toastify'
import { companyViewObjectives } from '../../services/objectives.service'
import { clearCompanyViewState } from '../../redux/company-view/CompanyView.action'
import ProcessContentArea from '../desktop-layout/process-content-area/ProcessContentArea'
import withSupportDoubleClick from '../../utils/with-support-double-click'
import { saveSpecificObjectiveForReview } from '../../redux/review-team-action/specific-objective/specificObjective.action'

const MobileAndTabMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, drawerWidth }) => ({
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft:`-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        }),
    }),
)

const DesktopMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, drawerWidth }) => ({
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft:`-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        }),
    }),
)

const CompanyView = (props) => {

    const { requestCompanyView, companyViewState, clearCompanyViewState, saveObjectives } = props

    const [ open, setOpen ] = useState(false)
    const [ selectedObjective, setSelectedObjective ] = useState(undefined)
    const [ objectives, setObjectives ] = useState([])
    const [ pageLoading, setPageLoading ] = useState(false)
    // const cardRefs = Array.from({ length: 10 }, () => React.createRef())
    const cardRefs = useRef(null)

    const isDesktop = useMediaQuery('(min-width:992px)')
    const isMobile = useMediaQuery('(max-width:480px)')

    const drawerWidth = isDesktop ? 340 : isMobile ? 160 : 220;
    const drawerHeight = isMobile ? "calc(100vh - 288px)" : "calc(100vh - 296px)"
    
    const ACTIVE_LEVEL_KEY_MAPPING = {
        0 : 'level_zero_objectives',
        1 : 'level_one_objectives',
        2 : 'level_two_objectives',
        3 : 'level_three_objectives'
    }

    const PATH_KEY_MAPPING = {
        0 : 'level_zero',
        1 : 'level_one',
        2 : 'level_two',
        3 : 'level_three'
    }

    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)

    // Drawer open
    const handleDrawerOpen = () => {
        setOpen(true)
    }

    // Drawer close
    const handleDrawerClose = () => {
        setOpen(false)
    }

    // Next level objective
    function handleNextLevel(){
        if(selectedObjective && selectedObjective.aggregate){
            // console.log("next level", selectedObjective)
            getSubObjectives(selectedObjective.parentId, parseInt( selectedObjective.aggregate === true ? parseInt(queryParams.lvl) +1 : queryParams.lvl)).then((res) => {
                if(!isEmpty(res)){
                    setObjectives(res)
                    // scrollSmoothHandler()
                    history.push(`/company-view?lvl=${parseInt(queryParams.lvl) + 1}`)
                    setSelectedObjective(ps => {
                        return {
                            ...ps,
                            aggregate : false
                        }
                    })
                }else{
                    toast.info('No sub-objectives to view', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }).catch((err) => {
                console.log(err)
            })
        }else{
            if( !isEmpty(selectedObjective) && !selectedObjective.aggregate){
                setSelectedObjective(undefined)
            }
            history.push(`/company-view?lvl=${parseInt(queryParams.lvl) + 1}`)
        }        
    }

    // Previous level objective
    function handlePerviousLevel(){
        history.push(`/company-view?lvl=${parseInt(queryParams.lvl) - 1}`)
        setSelectedObjective(undefined)
    }

    // handle card click event for sidebar
    // function handleCardClick(e, payload){
    //     if(!isEmpty(selectedObjective)){
    //         if(selectedObjective.parentId === payload.id){
    //             setSelectedObjective(undefined)
    //         }else{
    //             setSelectedObjective(ps => {
    //                 return {
    //                     ...ps,
    //                     index: payload.index, 
    //                     parentId : payload.id, 
    //                     aggregate : payload.aggregate
    //                 }
    //             })
    //         }
    //     }else{
    //         setSelectedObjective(ps => {
    //             return {
    //                 ...ps,
    //                 index: payload.index,
    //                 parentId: payload.id,
    //                 aggregate: payload.aggregate
    //             }
    //         })
    //     }
    // }

    function getSelectedObjectiveObj(index) {
        return companyViewState.response[ACTIVE_LEVEL_KEY_MAPPING[queryParams.lvl ? parseInt(queryParams.lvl) : 0]][index]
    }

    // console.log(companyViewState.response[ACTIVE_LEVEL_KEY_MAPPING[queryParams.lvl]])

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick: (e, payload) => {
            // console.log(e, payload)
            if(payload.aggregate){
                saveObjectives({objective : getSelectedObjectiveObj(payload.index)})
                history.push(`/vcf/${getSelectedObjectiveObj(payload.index)._id}?level=${getSelectedObjectiveObj(payload.index).level}&company_view=true`)
            }
        },
        onSingleClick: (e, payload) => {
            if(!isEmpty(selectedObjective)){
                if(selectedObjective.parentId === payload.id){
                    setSelectedObjective(undefined)
                }else{
                    setSelectedObjective(ps => {
                        return {
                            ...ps,
                            index: payload.index, 
                            parentId : payload.id, 
                            aggregate : payload.aggregate
                        }
                    })
                }
            }else{
                setSelectedObjective(ps => {
                    return {
                        ...ps,
                        index: payload.index,
                        parentId: payload.id,
                        aggregate: payload.aggregate
                    }
                })
            }
        }
    })

    function aggregateCards(){
        if(!isEmpty(selectedObjective) && queryParams.lvl > 0 && !selectedObjective?.aggregate){
            getSubObjectives( selectedObjective.parentId, parseInt(queryParams.lvl)).then((res) => {
                if(!isEmpty(res)){
                    setObjectives(res)
                }else{
                    setObjectives([])
                    toast.info('No sub-objectives to view', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        }else{
            if(!isEmpty(companyViewState.response)){
                const appropriateLevelObjectives = companyViewState.response[ACTIVE_LEVEL_KEY_MAPPING[queryParams.lvl || 0]]
                if(!isEmpty(appropriateLevelObjectives)){
                    setObjectives(appropriateLevelObjectives)
                } else {
                    setObjectives([])
                }
            }
        }
    }

    useEffect(() => {
        if(isEmpty(companyViewState.response)) requestCompanyView()

        return () => {
            clearCompanyViewState()
        }
    },[])

    useEffect(() => {
        aggregateCards()
    },[selectedObjective])

    useEffect(() => {
        if(!isEmpty(companyViewState.response)){
            aggregateCards()
        }
    }, [queryParams.lvl])

    useEffect(() => {
        if(!companyViewState.loading && !isEmpty(companyViewState.response)){
            setPageLoading(true)
            aggregateCards()
        }

        if(!companyViewState.loading && !isEmpty(companyViewState.error)){
            console.log(companyViewState.error)
            if(companyViewState.error && companyViewState.error?.data){
                toast.error(companyViewState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[companyViewState])

    function getSubObjectives(parentObjectiveId, level){
        return new Promise((resolve, reject) => {
            let objectivesByLvl = companyViewState.response[ACTIVE_LEVEL_KEY_MAPPING[level]]
            if(!isEmpty(objectivesByLvl)){
                let subObjs = []
                for(const obj of objectivesByLvl){
                    if(obj.path[PATH_KEY_MAPPING[level - 1]]._id === parentObjectiveId){
                        subObjs.push(obj)
                    }
                }
                resolve(subObjs)
            } else {
                resolve([])
            }
        })
    }

    function getMaxLevel(objectives){
        let maxLevel = 0
        if(!isEmpty(objectives.level_zero_objectives)){
            if(!isEmpty(objectives.level_one_objectives)){
                maxLevel += 1
                if(!isEmpty(objectives.level_two_objectives)) {
                    maxLevel += 1
                    if(!isEmpty(objectives.level_three_objectives)){
                        maxLevel += 1
                    }
                }
            }
        }
        return maxLevel
    }
    // const executeScroll = (i) => myRef.current.scrollIntoView({behavior: 'smooth'})

    function getPreviousLevelAggregateCard(){
        const appropriateLevelObjectives = companyViewState.response[ACTIVE_LEVEL_KEY_MAPPING[queryParams.lvl > 0 ? parseInt(queryParams.lvl)- 1 : 0]]
        if(!isEmpty(appropriateLevelObjectives)){
            if(isDesktop){
                return (
                    appropriateLevelObjectives.map((obj , index) => (
                        <div className='m-3' ref={cardRefs} key={index} >
                            <AggregateCard
                                variant="desktop"
                                wallpaper={ obj.wallpaper ? obj.wallpaper : "" }
                                icon={ obj.icon? obj.icon : "" }
                                name={ obj.name }
                                level={obj.level}
                                maxLevel={obj.max_level}
                                selected={selectedObjective?.parentId === obj._id}
                                onClick={(e) => handleCardClick(e, {index: index, id: obj._id, aggregate: false})}
                            />
                        </div>
                    ))
                )
            } else {
                return (
                    appropriateLevelObjectives.map((obj , index) => (
                        <div className='m-2' ref={cardRefs} key={index} >
                            <AggregateCard 
                                wallpaper={ obj.wallpaper ? obj.wallpaper : "" }
                                icon={ obj.icon? obj.icon : "" }
                                name={ obj.name }
                                level={obj.level}
                                maxLevel={obj.max_level}
                                selected={selectedObjective?.parentId === obj._id}
                                onClick={(e) => handleCardClick(e, {index: index, id: obj._id, aggregate: false})}
                            />
                        </div>
                    ))
                )
            }
        } else {
            return null
        }
    }

    // const scrollSmoothHandler = (i) => {
    //     console.log("enter")
    //     console.log("selected card Ref",cardRefs)
    //     if(cardRefs && cardRefs.current){
    //         console.log("enter inside",cardRefs.current.offsetTop)
    //         // selectedCardRef.current.scrollIntoView({ behavior: "smooth" });
    //         // window.scrollTo(0, 0);
    //         // cardRefs.current?.scrollIntoView({  behavior: "smooth", top: cardRefs.current.offsetTop })
    //         window.scroll({
    //             top : 2000,
    //             left: 0,
    //             behavior: "smooth"
    //         })
    //     }
    // }

    return (
        pageLoading ?
            isDesktop ?
                !isEmpty(companyViewState.response) && (!isEmpty(companyViewState.response.level_zero_objectives) ||!isEmpty(companyViewState.response.level_one_objectives) || !isEmpty(companyViewState.response.level_two_objectives) || !isEmpty(companyViewState.level_three_objectives)) ?
                    <ProcessContentArea className='d-flex'>
                        <div className="sidebar-container">
                            <IconButton
                                sx={{
                                    backgroundColor: 'var(--primary-color-v2)', 
                                    color: 'white', 
                                    '&:hover': {
                                        backgroundColor: 'var(--primary-color-v2)'
                                    },
                                    ...(open && { display: 'none' })
                                }}
                                onClick={handleDrawerOpen}
                                >
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                        <div className="sidebar-container" >
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    height: '100%',
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                        boxSizing: 'border-box',
                                        position: "relative",
                                        height: '100%',
                                        borderRadius: "10px",
                                        boxShadow: "0px 10px 15px 10px rgb(0 0 0 / 15%)",
                                        backgroundColor: "var(--secondary-color-v2)"
                                    }
                                }}
                                variant="persistent"
                                anchor="left"
                                open={open}
                            >
                                <div
                                    style={{
                                        display:"flex", 
                                        justifyContent:"space-evenly",
                                        backgroundColor:'white',
                                        padding: '0.5rem 0rem'
                                    }}
                                >
                                    <IconButton 
                                        onClick={handlePerviousLevel} 
                                        disabled={ queryParams.lvl > 0 ? false : true } 
                                    >
                                        <ArrowUpwardIcon/>
                                    </IconButton>
                                    <IconButton
                                        onClick={handleNextLevel} 
                                        disabled={parseInt(queryParams.lvl ? queryParams.lvl : 0) < getMaxLevel(companyViewState.response) ? false : true} 
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                    <IconButton 
                                        onClick={handleDrawerClose} 
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                            <Divider />   
                                {
                                    queryParams.lvl > 0 ?
                                        <div>
                                            {getPreviousLevelAggregateCard()}
                                        </div> 
                                        : null
                                }       
                            </Drawer>
                        </div>
                        <DesktopMain
                            open={open} 
                            sx={{flex:"1", paddingLeft:"1rem"}}
                            drawerWidth={drawerWidth}
                        >
                            <Grid container spacing={{md: 2, xl: 3, lg: 4}}>
                                {objectives.map((obj , index) => (
                                    <Grid item md={ open ? 6 : 4} lg={ open ? 6 : 4 } xl={ open ? 4 : 3} key={obj._id} >
                                        <AggregateCard
                                            variant="desktop"
                                            wallpaper={ obj.wallpaper ? obj.wallpaper : "" }
                                            icon={ obj.icon? obj.icon : "" }
                                            name={ obj.name }
                                            level={obj.level}
                                            maxLevel={obj.max_level}
                                            selected={selectedObjective?.parentId === obj._id}
                                            onClick={(e) => handleCardClick(e, {index: index, id: obj._id, aggregate: true})}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </DesktopMain>
                    </ProcessContentArea>
                    : <div className='py-3'>
                        <p className="m-0 text-center lato-fs20-normal-lh28">
                            No VCF added yet
                        </p>
                    </div>
                : <div>
                    <Box sx={{ display: 'flex', position:"relative" }} className="process-aggregate-height" >
                        <>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ 
                                    ml:0, 
                                    alignItems:"flex-start",
                                    "&:hover": { 
                                        backgroundColor: "transparent" 
                                    }, 
                                    ...(open && { display: 'none' })
                                }}
                                >
                                <ChevronRightIcon className='primary-color'/>
                            </IconButton>
                        </>
                        <div>
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,
                                        boxSizing: 'border-box',
                                        position: "relative",
                                        height: drawerHeight
                                    }
                                }}
                                variant="persistent"
                                anchor="left"
                                open={open}
                            >
                                <div
                                    style={{
                                        display:"flex", 
                                        justifyContent:"space-evenly"
                                    }}
                                >
                                    <IconButton 
                                        onClick={handlePerviousLevel} 
                                        disabled={ queryParams.lvl > 0 ? false : true } 
                                    >
                                        <ArrowUpwardIcon/>
                                    </IconButton>
                                    <IconButton
                                        onClick={handleNextLevel} 
                                        disabled={parseInt(queryParams.lvl ? queryParams.lvl : 0) < getMaxLevel(companyViewState.response) ? false : true} 
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                    <IconButton 
                                        onClick={handleDrawerClose} 
                                    >
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                            <Divider />   
                                {
                                    queryParams.lvl > 0 ?
                                        <div>
                                            {getPreviousLevelAggregateCard()}
                                        </div> 
                                        : null
                                }       
                            </Drawer>
                        </div>
                        <MobileAndTabMain open={open} sx={{ overflow:"auto", flex:"1"}} drawerWidth={drawerWidth} >
                            {
                                !isEmpty(companyViewState.response) && (!isEmpty(companyViewState.response.level_zero_objectives) ||!isEmpty(companyViewState.response.level_one_objectives) || !isEmpty(companyViewState.response.level_two_objectives) || !isEmpty(companyViewState.level_three_objectives)) ?
                                    <Grid container spacing={{ xs: 1, md: 2 }}>
                                        {
                                            objectives.map((obj , index) => (
                                                <Grid item xs={ open ? 12 : 6} sm={ open ? 6 : 4 } md={ open ? 4 : 3} key={obj._id} >
                                                    <AggregateCard
                                                        wallpaper={ obj.wallpaper ? obj.wallpaper : "" }
                                                        icon={ obj.icon? obj.icon : "" }
                                                        name={ obj.name }
                                                        level={obj.level}
                                                        maxLevel={obj.max_level}
                                                        selected={selectedObjective?.parentId === obj._id}
                                                        onClick={(e) => handleCardClick(e, {index: index, id: obj._id, aggregate: true})}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                    : <div className='d-flex h-100 align-items-center justify-content-center'>
                                        <p className="m-0 text-center lato-fs20-normal-lh28">
                                            No VCF added yet
                                        </p>
                                    </div>
                            }
                        </MobileAndTabMain>
                    </Box>
                </div>
            :null
    )
}

const mapStateToProps = (state) => {
    return {
        companyViewState : state.companyView
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestCompanyView : () => dispatch(companyViewObjectives()),
        clearCompanyViewState : () => dispatch(clearCompanyViewState()),
        saveObjectives : (response) => dispatch(saveSpecificObjectiveForReview(response))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CompanyView)