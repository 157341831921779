import React from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/styles'
import { isEmpty } from 'lodash'

// const useStyles = makeStyles({
//     textBox:{
//         width:"100%",
//         marginTop:10,
//     },
//     notchedOutline:{
//         borderColor: "rgba(156,155,185,255) !important",
//         borderWidth: "1px",
//     },
//     label:{
//         "&.Mui-focused": {
//             color: "rgba(156,155,185,255) !important"
//           }
//     },
//     options:{
//         color: "rgba(156,155,185,255) !important"
//     }
// });

const StyledSelectBox = styled(TextField)({
    // input label when focused
    "& label.Mui-focused": {
        color: "#14213d !important"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
        borderColor: "#14213d !important"
        }
    }
})

const SelectBoxValueById = (props) => {

    function createOptions(optionList){
        return optionList.map((item,index) => 
            <MenuItem key={index} id={index} disabled={item.disabled} value={item.value}>{item.label}</MenuItem>
        );
    }

    return (
        <div className={props.containerClassName}>
            <StyledSelectBox id="outlined-select-currency"
                    select
                    label={props.label}
                    name={props.name} 
                    onChange={props.onChange} 
                    value={props.value} 
                    onBlur={props.onBlur} 
                    fullWidth
                    defaultValue={props.defaultValue}
                    InputProps={{
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    InputLabelProps={{
                        style:{fontFamily:'"Lato",sans-serif',fontSize:"15px"}
                    }}
                    autoComplete="off" >
                {createOptions(props.options)}
                {isEmpty(createOptions(props.options)) ? 
                    <MenuItem value="" >No option</MenuItem>: ""}
            </StyledSelectBox>
            {
                props.errorMgs ? 
                <div className="ms-1">
                    <span className="error-message">{props.errorMgs}</span>
                </div> : null
            }
        </div>
    )
}

export default SelectBoxValueById