import { REQUEST_NON_INVITED_MEMBERS,
        SAVE_NON_INVITED_MEMBERS,
        NON_INVITED_MEMBERS_FAILURE,
        CLEAR_NON_INVITED_MEMBER_STATE } from './nonInvitedMembers.types'

export const requestNonInvitedMembers = () => {
    return {
        type : REQUEST_NON_INVITED_MEMBERS
    }
}

export const fetchedNonInvitedMemberSuccess = (response) => {
    return {
        type : SAVE_NON_INVITED_MEMBERS,
        payload : response
    }
}

export const fetchedNonInvitedMemberFailure = (error) => {
    return {
        type : NON_INVITED_MEMBERS_FAILURE,
        payload : error
    }
}

export const clearNonInvitedMemberState = () => {
    return {
        type : CLEAR_NON_INVITED_MEMBER_STATE
    }
}