import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(1, 1, 1, 0.8)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        fontWeight: 600,
        fontFamily: 'Lato, "sans-serif'
    },
}));

export default LightTooltip;
