import { REQUEST_COURSES_FOR_SELECT,
        COURSES_FOR_SELECT_SUCCESS,
        COURSES_FOR_SELECT_FAILURE,
        CLEAR_COURSES_FOR_SELECT_STATE } from './Course.types'

export const requestCourses = () => {
    return {
        type : REQUEST_COURSES_FOR_SELECT
    }
}

export const coursesSuccess = (response) => {
    return {
        type : COURSES_FOR_SELECT_SUCCESS,
        payload : response
    }
}

export const coursesFailure = (error) => {
    return {
        type : COURSES_FOR_SELECT_FAILURE,
        payload : error
    }
}

export const clearCoursesState = () => {
    return {
        type : CLEAR_COURSES_FOR_SELECT_STATE
    }
}