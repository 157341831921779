import {DEV_CLIENT_URL, DEV_SERVER_URL, PROD_CLIENT_URL, PROD_SERVER_URL, LOCAL_DEV_CLIENT_URL, LOCAL_DEV_SERVER_URL} from '../utils/constants'

const config = () => {

    const env = "prod"
    
    switch(env){
        case 'dev':
            return {
                CLIENT_URL: DEV_CLIENT_URL,
                SERVER_URL : DEV_SERVER_URL
            }
        case 'prod':
            return {
                CLIENT_URL: PROD_CLIENT_URL,
                SERVER_URL : PROD_SERVER_URL
            }
        default :
            return {
                CLIENT_URL: LOCAL_DEV_CLIENT_URL,
                SERVER_URL: LOCAL_DEV_SERVER_URL
            }
    }
}

export default config