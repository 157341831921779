import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faListAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty, size } from 'lodash'
import qs from 'query-string'
import { toast } from 'react-toastify'
import { Pagination } from 'react-bootstrap'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import './learning.css'

import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import LearningCoverImage from '../../../assets/images/learnings.png'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import DeletePopUp from '../../../components/delete-popup/DeletePopUp'
import CoursePanel from '../../../components/coursePanel/CoursePanel'
import CoursePerformance from '../../../components/course-performance-table/CoursePerformance'
import ProcessHeaderCard from '../../../components/process-header-card/ProcessHeaderCard'
import SimpleTabPanel from '../../../components/tabs/simple-tab/Simple-tab-panel/SimpleTabPanel'
import BgFilledTab from '../../../components/tabs/bg-filled-tab/bg-filled-tab-header/BgFilledTab'
import ProcessSearch from '../../../components/search/process-search/ProcessSearch'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSearchBar from '../../../components/desktop-layout/process-search-bar/ProcessSearchBar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SlantContainer from '../../../components/desktop-layout/slant-container/SlantContainer'
import ProcessContentArea from '../../../components/desktop-layout/process-content-area/ProcessContentArea'
import GlassyCard from '../../../common/glassy-card/GlassyCard'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import CarouselSlider from '../../../common/carousel-slider/CarouselSlider'
import MainContainer from '../../../components/main-container/MainContainer'

import { fetchLearningSuccess } from '../../../redux/learning/fetch-learning/FetchLearningAction'
import { clearAddSegmentStates } from '../../../redux/learning/add-segment-in-learning-area/AddSegment.action'
import { clearDeleteSegmentStates } from '../../../redux/learning/delete-segment-in-learning-area/DeleteSegment.action'
import { clearCourseContentStatus } from '../../../redux/learning/course/CourseAction'
import { clearSpecificLearningArea } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'

import { 
    fetchCoursesPerformance, 
    fetchLearningAreaCourses, 
    fetchLearningCircleLearningAreas, 
    addSegmentInLearningArea, 
    deleteSegmentInLearningArea 
} from '../../../services/learning.service'

const SEGMENT_KEY_MAPPING ={
    4 : 'segment_four',
    5 : 'segment_five',
    6 : 'segment_six'
}

const Learning = (props) => {

    const { 
        fetchLearningState,  
        deleteSegmentState, 
        addSegmentState, 
        addSegment, 
        deleteSegment,  
        clearDeleteSegmentStates,
        clearAddSegmentStates,
        saveLearningCircles,
        listOfCoursesState,
        fetchCoursesPerformance,
        fetchLearningAreaCourses,
        fetchLearnings,
        coursePerformanceState,
        clearSpecificLearningArea, 
        clearReadCourse,
        readCourseState,
        specificLearningAreaState } = props

    const history = useHistory()
    const location = useLocation()
    const queryParams  = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [pageLoading, setPageLoading] = useState({
        listOfCourses : true,
        learningCircle : true,
        table : true
    })
    const [activeIndex, setActiveIndex] = useState({
        index : 0,
        page : 1
    })
    const [deletePopup,setDeletePopup] = useState(false)
    const [ drawerOpen,setDrawerOpen ] = useState(false)
    const [ searchPopup, setSearchPopup] = useState(false)
    const [ menu, setMenu ] = useState(false)

    const learningCircles = fetchLearningState.response.learning_circles
    const listOfCourses = listOfCoursesState.response.learning_areas
    const coursePerformanceTable =  coursePerformanceState.response.courses

    function handleSegmentOnClickEvent(circleId,segmentName,segmentNo){
        history.push(`/learning/create?circleId=${circleId}&segment=${segmentNo}&segment_name=${segmentName}`)
    }

    function goToNextCircle(){
        setActiveIndex(prevState => {
            return {
                index : prevState.index + 1,
                page : 1
            }
        })
    }

    function goToPreviousCircle(){
        setActiveIndex(prevState => {
            return {
                index : prevState.index - 1,
                page : 1
            }
        })
    }

    function handlePageChange(pageNo){
        setActiveIndex(prevState => {
            return {
                ...prevState,
                page : pageNo
            }
        })
    }

    function learningAreaOnClickEvent(learningAreaId,segmentId,segment){
        history.push(`/learning/${learningAreaId}/learning-area/${segmentId}/${segment}`)
    }

    function handleCardClick(coursesId){
        history.push(`/learning/learning-area/course/${coursesId}`)
    }

    function handleNotification(){
        history.push(`/notification-reference?selected=notification&tab=inbox&action=learnings`)
    }

    useEffect(() => {
        fetchCoursesPerformance()
        fetchLearnings()
        fetchLearningAreaCourses()
        if(!isEmpty(specificLearningAreaState.response)){
            clearSpecificLearningArea()
        }
        if(!isEmpty(readCourseState.response)){
            clearReadCourse()
        }
    },[])

    useEffect(() => {
        if(!addSegmentState.loading && !isEmpty(addSegmentState.response)){
            //console.log(addSegmentState.response)
            saveLearningCircles(addSegmentState.response)
            clearAddSegmentStates()
            toast.success('Added a learning segment successfully',{
                position:"bottom-right",
                autoClose:3000,
                hideProgressBar:false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
        }

        if(!addSegmentState.loading && !isEmpty(addSegmentState.error)){
            // console.log(addSegmentState.error)
            if(addSegmentState.error){
                if(addSegmentState.error.data){
                    toast.error(addSegmentState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    }, [addSegmentState])

    useEffect(() => {
        if(!deleteSegmentState.loading && !isEmpty(deleteSegmentState.response)){
            //console.log(deleteSegmentState.response)
            saveLearningCircles(deleteSegmentState.response)
            clearDeleteSegmentStates()
            toast.success('Deleted a learning segment successfully',{
                position:"bottom-right",
                autoClose:3000,
                hideProgressBar:false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            fetchCoursesPerformance()
            toggleDeletePopup()
        }

        if(!deleteSegmentState.loading && !isEmpty(deleteSegmentState.error)){
            // console.log(deleteSegmentState.error)
            if(deleteSegmentState.error){
                if(deleteSegmentState.error.data){
                    toast.error(deleteSegmentState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    }, [deleteSegmentState])

    useEffect(()=>{
        if(!listOfCoursesState.loading && !isEmpty(listOfCoursesState.response)){
            // console.log("listOfCourses" , listOfCoursesState.response)
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    listOfCourses : false
                }
            })
        }

        if(!listOfCoursesState.loading && !isEmpty(listOfCoursesState.error)){
            // console.log(listOfCourses)
            if(listOfCoursesState.error?.data){
                toast.error(listOfCoursesState.error?.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }            
        }
    },[listOfCoursesState])

    useEffect(()=>{
        if(!fetchLearningState.loading && !isEmpty(fetchLearningState.response)){
            // console.log(fetchLearningState.response)
            setPageLoading(prevState =>{
                return {
                    ...prevState,
                    learningCircle : false
                }
            })
        }

        if(!fetchLearningState.loading && !isEmpty(fetchLearningState.error)){
            // console.log(fetchLearningState.error)
            if(fetchLearningState.error){
                if(fetchLearningState.error.data){
                    toast.error(fetchLearningState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[fetchLearningState])

    useEffect(() => {
        if(! coursePerformanceState.loading && !isEmpty( coursePerformanceState.response)){
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    table : false
                }
            })
        }
        if(! coursePerformanceState.loading && !isEmpty( coursePerformanceState.error)){
            // console.log( coursePerformanceState.error)
            if(coursePerformanceState.error){
                if(coursePerformanceState.error.data){
                    toast.error(coursePerformanceState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[ coursePerformanceState])

    function segmentDetail(segmentsArray, segmentNo){
        if(!isEmpty(segmentsArray)){
            return segmentsArray.find((segment) => segment.segment_no == segmentNo)
        }
    }

    function getAppropriateCircleSegments(){
        if(learningCircles[activeIndex.index].segment_count > 3 && activeIndex.page > 1) {
            if(learningCircles[activeIndex.index].segment_count == 4) {
                return <>
                    <div className="full-circle">
                        {
                            segmentDetail(learningCircles[activeIndex.index].segments, "4") ? 
                                <div className="clickable-area-full" onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id, segmentDetail(learningCircles[activeIndex.index].segments, "4")._id, 4)}>
                                    <img className="icon-preview-learning" src={segmentDetail(learningCircles[activeIndex.index].segments, "4") && segmentDetail(learningCircles[activeIndex.index].segments, "4").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "4").icon : iconPlaceholder}></img>
                                    <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "4") && segmentDetail(learningCircles[activeIndex.index].segments, "4").name ? segmentDetail(learningCircles[activeIndex.index].segments, "4").name : ""}</h6>
                                </div>
                            : <div className="clickable-area-full" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 4)}></div>
                        }
                    </div>
                </>
            } else if(learningCircles[activeIndex.index].segment_count == 5) {
                return <>
                    <div id="semi-circle-right" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "4") ? 
                                    <div className="clickable-area-semi-right" onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id, segmentDetail(learningCircles[activeIndex.index].segments, "4")._id, 4)}>
                                        <img className="icon-preview-learning mb-1" src={segmentDetail(learningCircles[activeIndex.index].segments, "4") && segmentDetail(learningCircles[activeIndex.index].segments, "4").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "4").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "4") && segmentDetail(learningCircles[activeIndex.index].segments, "4").name ? segmentDetail(learningCircles[activeIndex.index].segments, "4").name : ""}</h6>
                                    </div>
                                : <div className="clickable-area-semi-right" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id, learningCircles[activeIndex.index].name, 4)}></div>
                            }
                        </div>
                    </div>
                    <div id="semi-circle-left" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "5") ? 
                                    <div className="clickable-area-semi-left" onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id, segmentDetail(learningCircles[activeIndex.index].segments, "5")._id, 4)}>
                                        <img className="icon-preview-learning mb-1" src={segmentDetail(learningCircles[activeIndex.index].segments, "5") && segmentDetail(learningCircles[activeIndex.index].segments, "5").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "5").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "5") && segmentDetail(learningCircles[activeIndex.index].segments, "5").name ? segmentDetail(learningCircles[activeIndex.index].segments, "5").name : ""}</h6>
                                    </div>
                                : <div className="clickable-area-semi-left" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 5)}></div>
                            }
                        </div>
                    </div>
                </>
            } else {
                return <>
                    <div id="segment4-container" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "4") ? 
                                    <div className="slice1-heading" 
                                        onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id, segmentDetail(learningCircles[activeIndex.index].segments, "4")._id, 4)}>
                                        <img className="icon-preview-learning" src={segmentDetail(learningCircles[activeIndex.index].segments, "4") && segmentDetail(learningCircles[activeIndex.index].segments, "4").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "4").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "4") ? segmentDetail(learningCircles[activeIndex.index].segments, "4").name : ""}</h6>
                                    </div>
                                    : <div className="slice1-heading" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 4)}></div>
                            }
                        </div>
                    </div>
                    <div id="segment5-container" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "5") ? 
                                    <div className="slice2-heading" onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id, segmentDetail(learningCircles[activeIndex.index].segments, "5")._id, 5)}>
                                        <img className="icon-preview-learning" src={segmentDetail(learningCircles[activeIndex.index].segments, "5") && segmentDetail(learningCircles[activeIndex.index].segments, "5").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "5").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "5") ? segmentDetail(learningCircles[activeIndex.index].segments, "5").name : ""}</h6>
                                    </div>
                                    : <div className="slice2-heading" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 5)}></div>
                            }
                        </div>
                    </div>
                    <div id="segment6-container" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "6") ? 
                                    <div className="slice3-heading" onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id, segmentDetail(learningCircles[activeIndex.index].segments, "6")._id, 6)}>
                                        <img className="icon-preview-learning" src={segmentDetail(learningCircles[activeIndex.index].segments, "6") && segmentDetail(learningCircles[activeIndex.index].segments, "6").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "6").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "6") ? segmentDetail(learningCircles[activeIndex.index].segments, "6").name : ""}</h6>
                                    </div> 
                                    : <div className="slice3-heading" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 6)} ></div>
                            }
                        </div>
                    </div>
            </>
            }
        } else {
            return <>
                    <div id="segment1-container" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "1") ? 
                                    <div className="slice1-heading" 
                                        onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id,segmentDetail(learningCircles[activeIndex.index].segments,"1")._id, 1)}>
                                        <img className="icon-preview-learning mb-1" src={ segmentDetail(learningCircles[activeIndex.index].segments,"1") && segmentDetail(learningCircles[activeIndex.index].segments,"1").icon ? segmentDetail(learningCircles[activeIndex.index].segments,"1").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments,"1")? segmentDetail(learningCircles[activeIndex.index].segments,"1").name : ""}</h6>
                                    </div>
                                    : <div className="slice1-heading" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 1)}></div>
                            }
                        </div>
                    </div>
                    <div id="segment2-container" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "2") ? 
                                    <div className="slice2-heading" onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id,segmentDetail(learningCircles[activeIndex.index].segments, "2")._id, 2)}>
                                        <img className="icon-preview-learning mb-1" src={segmentDetail(learningCircles[activeIndex.index].segments, "2") && segmentDetail(learningCircles[activeIndex.index].segments, "2").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "2").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "2") ? segmentDetail(learningCircles[activeIndex.index].segments, "2").name : ""}</h6>
                                    </div> 
                                    : <div className="slice2-heading" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 2)}></div>
                            }
                        </div>
                    </div>
                    <div id="segment3-container" className="hold">
                        <div className="pie">
                            {
                                segmentDetail(learningCircles[activeIndex.index].segments, "3")? 
                                    <div className="slice3-heading" onClick={() => learningAreaOnClickEvent(learningCircles[activeIndex.index]._id,segmentDetail(learningCircles[activeIndex.index].segments, "3")._id, 3)}>
                                        <img className="icon-preview-learning mb-1" src={segmentDetail(learningCircles[activeIndex.index].segments, "3") && segmentDetail(learningCircles[activeIndex.index].segments, "3").icon ? segmentDetail(learningCircles[activeIndex.index].segments, "3").icon : iconPlaceholder}></img>
                                        <h6 className={isDesktop ? "text-center lato-fs18-bold-lh22 two-line-overflow-hidden" : "text-center lato-fs17-normal-lh20 two-line-overflow-hidden font-bold"}>{segmentDetail(learningCircles[activeIndex.index].segments, "3") ? segmentDetail(learningCircles[activeIndex.index].segments, "3").name : ""}</h6>
                                    </div> 
                                    : <div className="slice3-heading" onClick={() => handleSegmentOnClickEvent(learningCircles[activeIndex.index]._id,learningCircles[activeIndex.index].name, 3)} ></div>
                            }
                        </div>
                    </div>
            </>
        }
    }

    function toggleDeletePopup(){
        setDeletePopup(!deletePopup)
    }

    function handleSegmentChangeTrigger(action) {
        if(action === 'add'){
            addSegment(learningCircles[activeIndex.index]._id)
        } else if(action === 'delete') {
            if(learningCircles[activeIndex.index].segment_count){
                if(!isEmpty(SEGMENT_KEY_MAPPING[learningCircles[activeIndex.index].segment_count])){
                    toggleDeletePopup()
                }
            }else{
                deleteSegment(learningCircles[activeIndex.index]._id)
            }
        }
    }

    function handleTabs(event,key){
        history.push(`/learning?selected=${key}`)
    }

    function handleVideoConference(){
        history.push(`/video-conference?selected=your-meeting`)
    }

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    }

    function handleTrophyEvent(){
        toast.info("Under development", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    function searchPopupOpen(){
        setSearchPopup(true)
    }

    function searchPopupClose(){
        setSearchPopup(false)
    }

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    return (
        <>
            {
                isDesktop ? 
                    <Container>
                        <Navbar
                            enableSearchBar={true}
                            searchAutocomplete={<ProcessSearchBar />}
                            enableNotification={true}
                            enableMore={true}
                            open={open}
                            menu={menu}
                            moreRight="207px"
                            logo={true}
                            enableLogout={true}
                            enableProfileButton={true}
                            notificationOnClick={handleNotification}
                            handlePopoverClose={handleMenuClose}
                            handlePopoverOpen={handleMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar 
                                videoIcon={true}
                                awardsIcon={true}
                                onAwardsIconClick={handleTrophyEvent} />
                            <ProcessMainContainer>
                                <SlantContainer punchLine="Fire up your imagination to create value" coverImgSrc={LearningCoverImage} />
                                <ProcessContentArea>
                                    <GlassyCard className="pt-3 content-area-min-height-desktop">
                                        {!isEmpty(learningCircles) ? 
                                            <>
                                                <Box sx={{background:"white", margin:"10px 1.5rem", borderRadius:"10px", position:"sticky", top:"75px", zIndex: "4" }}>
                                                    <BgFilledTab
                                                        variant="desktop"
                                                        value={queryParams.selected ? queryParams.selected : "focus-of-learning"}
                                                        onChange={handleTabs} 
                                                        className="tab-font"
                                                        tabArray={[ 
                                                            {label:"Focus of Learning", value:"focus-of-learning"},
                                                            {label:"List of Courses", value:"list-of-courses"}
                                                        ]}
                                                    />
                                                </Box>
                                                <SimpleTabPanel
                                                    value="focus-of-learning"
                                                    boxContainerClassName="h-100"
                                                    containerClassname="transparent-bg"
                                                    selectedTab={queryParams.selected}>
                                                    <div className='px-4 py-1' >
                                                        <div className='d-flex justify-content-between'>
                                                            <h4 className="white-color lato-fs26-bold-lh3">{learningCircles[activeIndex.index].name}</h4>
                                                            <div className="d-flex align-items-center">
                                                                {
                                                                    learningCircles[activeIndex.index].segment_count < 6 ?
                                                                        <IconButton 
                                                                            className='me-2'
                                                                            sx={{
                                                                                backgroundColor: 'var(--primary-color-v2)',
                                                                                '&:hover' : {
                                                                                    backgroundColor: 'var(--primary-color-v2)'
                                                                                }
                                                                            }}
                                                                        >
                                                                            <AddIcon
                                                                                onClick={() => handleSegmentChangeTrigger('add')} 
                                                                                sx={{
                                                                                    color: 'white'
                                                                                }}
                                                                            />
                                                                        </IconButton> :
                                                                        null
                                                                }
                                                                {
                                                                    learningCircles[activeIndex.index].segment_count > 3 ?
                                                                        <IconButton
                                                                            sx={{
                                                                                backgroundColor: 'var(--primary-color-v2)',
                                                                                '&:hover' : {
                                                                                    backgroundColor: 'var(--primary-color-v2)'
                                                                                }
                                                                            }}
                                                                        >
                                                                            <DeleteIcon
                                                                                onClick={() => handleSegmentChangeTrigger('delete')} 
                                                                                sx={{
                                                                                    color: 'white'
                                                                                }}
                                                                            />
                                                                        </IconButton> :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            {
                                                                activeIndex.index !== 0 ?
                                                                    <div>
                                                                        <IconButton onClick={goToPreviousCircle}>
                                                                            <ArrowBackIosNewIcon />
                                                                        </IconButton>
                                                                    </div> :
                                                                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}></div>
                                                            }
                                                            <div className="pie-container overflow-hidden mb-3">
                                                                {getAppropriateCircleSegments()}
                                                            </div>
                                                            {
                                                                activeIndex.index < size(learningCircles) - 1 ?
                                                                    <div>
                                                                        <IconButton onClick={goToNextCircle}>
                                                                            <ArrowForwardIosIcon />
                                                                        </IconButton>
                                                                    </div> :
                                                                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}></div>
                                                            }
                                                        </div>
                                                        {
                                                            learningCircles[activeIndex.index].segment_count > 3 ? 
                                                                <div className="d-flex justify-content-center">
                                                                    <Pagination>
                                                                        <Pagination.Item 
                                                                            key={1}
                                                                            active={activeIndex.page === 1} 
                                                                            onClick={() => handlePageChange(1)}>
                                                                            1
                                                                        </Pagination.Item>
                                                                        <Pagination.Item 
                                                                            key={2} 
                                                                            active={activeIndex.page === 2} 
                                                                            onClick={() => handlePageChange(2)}>
                                                                            2
                                                                        </Pagination.Item>
                                                                    </Pagination>
                                                                </div> : null
                                                        }
                                                        { 
                                                            !isEmpty( coursePerformanceState.response) && !isEmpty(coursePerformanceTable) ?
                                                                <div className="mt-4">
                                                                    <CoursePerformance coursePerformance={coursePerformanceTable}/>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </SimpleTabPanel>
                                                <SimpleTabPanel
                                                    value="list-of-courses"
                                                    boxContainerClassName="h-100"
                                                    containerClassname="transparent-bg"
                                                    selectedTab={queryParams.selected}>
                                                    <>
                                                        { !isEmpty(listOfCourses) && !isEmpty(listOfCourses).courses?
                                                            <div className='pt-3'>
                                                                {
                                                                    listOfCourses.map((learningArea,index) => (
                                                                        <div key={learningArea._id}>
                                                                            <CarouselSlider
                                                                                breadcrumbs={ 
                                                                                    <BreadCrumbs
                                                                                        activeId={learningArea._id}
                                                                                        icon={faListAlt}
                                                                                        path={[{_id: learningArea._id, name: learningArea.name}]} />
                                                                                }
                                                                                size={size(learningArea.courses)}>
                                                                                { learningArea.courses.map((course, index) => (
                                                                                    <div className='px-3 pb-4' key={course._id}>
                                                                                        <CoursePanel 
                                                                                            index={index}
                                                                                            onCardClick={() => handleCardClick(course._id)}
                                                                                            course={course}
                                                                                        />
                                                                                    </div>
                                                                                ))}
                                                                            </CarouselSlider>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div> : 
                                                            <div className='d-flex content-area-min-height-desktop align-items-center justify-content-center'>
                                                                <p className="lato-fs18-normal-lh22 text-center mb-0">You don't have any courses yet</p>
                                                            </div>
                                                        }
                                                    </>
                                                </SimpleTabPanel>
                                            </>
                                        :null}
                                    </GlassyCard>
                                </ProcessContentArea>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </Container>
                    : <Container>
                        <Navbar 
                            variant="mobile"
                            enableLogout={true}
                            enableSearchIcon={true}
                            onSearchIconClick={searchPopupOpen}
                            enableDrawer={true}
                            logo={true}
                            drawerOpened={drawerOpen}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={handleNotification}
                        />
                        <MainContainer className="bg-tertiary-color-v2">
                            <ProcessHeaderCard
                                punchLine="Fire up your imagination to create value" 
                                coverImgSrc={LearningCoverImage}
                                enableVideoIcon={true} 
                                onVideoIconClick={handleVideoConference}
                                enableAwardIcon={true}
                                onTrophyIconClick={handleTrophyEvent}
                            />
                            { 
                                !pageLoading.learningCircle && !pageLoading.listOfCourses && !pageLoading.table ?
                                    <div className='overflow-auto d-flex flex-column process-aggregate-height' >
                                        <Box 
                                            sx={{ 
                                                borderBottom: 1, 
                                                borderColor: 'divider', 
                                                background:"white", 
                                                margin:"10px", 
                                                borderRadius:"10px" 
                                            }}
                                        >
                                            <BgFilledTab
                                                value={queryParams.selected ? queryParams.selected : "focus-of-learning"}
                                                onChange={handleTabs} 
                                                className="tab-font"
                                                tabArray={[ 
                                                    {label:"Focus of Learning", value:"focus-of-learning"},
                                                    {label:"List of Courses", value:"list-of-courses"}
                                                ]}
                                            />
                                        </Box>
                                        <SimpleTabPanel 
                                            value="focus-of-learning"
                                            containerClassname="transparent-bg pb-2"
                                            selectedTab={queryParams.selected}
                                        >
                                            <div>
                                                <div className='d-flex justify-content-between px-3'>
                                                    <h4>{learningCircles[activeIndex.index].name}</h4>
                                                    <div className="d-flex align-items-center">
                                                    {
                                                        learningCircles[activeIndex.index].segment_count < 6 ?
                                                            <IconButton 
                                                                className='me-2'
                                                                sx={{
                                                                    backgroundColor: 'var(--primary-color-v2)',
                                                                    '&:hover' : {
                                                                        backgroundColor: 'var(--primary-color-v2)'
                                                                    }
                                                                }}
                                                            >
                                                                <AddIcon
                                                                    onClick={() => handleSegmentChangeTrigger('add')} 
                                                                    sx={{
                                                                        color: 'white'
                                                                    }}
                                                                />
                                                            </IconButton> :
                                                            null
                                                    }
                                                    {
                                                        learningCircles[activeIndex.index].segment_count > 3 ?
                                                            <IconButton
                                                                sx={{
                                                                    backgroundColor: 'var(--primary-color-v2)',
                                                                    '&:hover' : {
                                                                        backgroundColor: 'var(--primary-color-v2)'
                                                                    }
                                                                }}
                                                            >
                                                                <DeleteIcon
                                                                    onClick={() => handleSegmentChangeTrigger('delete')} 
                                                                    sx={{
                                                                        color: 'white'
                                                                    }}
                                                                />
                                                            </IconButton> :
                                                            null
                                                    }
                                                    </div>
                                                </div>
                                                <div className="d-flex overflow-hidden justify-content-center">
                                                    <div className="arrow-circle">
                                                        <FontAwesomeIcon 
                                                            className={ activeIndex.index != 0 ? "flex-fill align-self-center button-z-index pt-cursor" : "flex-fill align-self-center button-z-index invisible"} 
                                                            icon ={faChevronLeft} 
                                                            onClick={goToPreviousCircle}/>
                                                        <div className="pie-container">
                                                            {getAppropriateCircleSegments()}
                                                        </div>
                                                        <FontAwesomeIcon 
                                                            className={ activeIndex.index < size(learningCircles) - 1 ? "flex-fill align-self-center button-z-index pt-cursor" : "flex-fill align-self-center button-z-index invisible"} 
                                                            icon={faChevronRight} 
                                                            onClick={goToNextCircle}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                learningCircles[activeIndex.index].segment_count > 3 ? 
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <Pagination>
                                                            <Pagination.Item 
                                                                key={1} 
                                                                active={activeIndex.page == 1} 
                                                                onClick={() => handlePageChange(1)}>
                                                                1
                                                            </Pagination.Item>
                                                            <Pagination.Item 
                                                                key={2} 
                                                                active={activeIndex.page == 2} 
                                                                onClick={() => handlePageChange(2)}>
                                                                2
                                                            </Pagination.Item>
                                                        </Pagination>
                                                    </div> : null
                                            }
                                            { 
                                                !isEmpty( coursePerformanceState.response) && !isEmpty(coursePerformanceTable) ?
                                                    <div className="mt-4">
                                                        <CoursePerformance variant="mobile" coursePerformance={coursePerformanceTable}/>
                                                    </div>
                                                    : null 
                                            }
                                        </SimpleTabPanel>
                                        <SimpleTabPanel 
                                            value="list-of-courses"
                                            containerClassname="transparent-bg"
                                            selectedTab={queryParams.selected}>
                                            <>
                                                { !isEmpty(listOfCourses) && !isEmpty(listOfCourses).courses?
                                                    <div className='pt-3'>
                                                        {
                                                            listOfCourses.map((learningArea,index) => (
                                                                <div key={index}>
                                                                    <div className='ml-20 mr-20 my-1'>
                                                                        <BreadCrumbs
                                                                            listClassName="bg-white"
                                                                            activeId={learningArea._id}
                                                                            icon={faListAlt}
                                                                            path={[{_id: learningArea._id, name: learningArea.name}]}
                                                                        />
                                                                    </div>
                                                                    <div className="scroll-container">
                                                                        { learningArea.courses.map((course, index) => (
                                                                            <div className='course-container pb-4' key={course._id} style={{minWidth : "200px", maxWidth: '250px'}}>
                                                                                <CoursePanel 
                                                                                    variant="mobile"
                                                                                    index={index}
                                                                                    onCardClick={() => handleCardClick(course._id)}
                                                                                    course={course}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))
                                                        } 
                                                    </div> : 
                                                    <div className='d-flex align-items-center justify-content-center py-5'>
                                                        <p className="lato-fs18-normal-lh25 primary-text-color text-center flex-1">You don't have any courses yet</p>
                                                    </div>
                                                }
                                            </>
                                        </SimpleTabPanel>
                                    </div>
                                    : null
                            }
                        </MainContainer>
                        <BottomNavigation/>
                        {
                            searchPopup ?
                                <ProcessSearch handleClose={searchPopupClose} popupState={searchPopup} selectedAction="learnings" />
                                : null
                        }
                    </Container>
            }
            {
                deletePopup ? 
                <DeletePopUp 
                    handleClose={toggleDeletePopup}
                    open={deletePopup}
                    onClick={() => deleteSegment(learningCircles[activeIndex.index]._id)}
                    msg={"Last segment and all it's binded learning area and courses of the current circle will get deleted. Are you sure?"}/> 
                : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        fetchLearningState : state.fetchLearningCircle,
        addSegmentState : state.addSegment,
        deleteSegmentState : state.deleteSegment,
        listOfCoursesState : state.listOfCourses,
        coursePerformanceState : state.learningDetailsTable,
        specificLearningAreaState : state.specificLearningArea,
        readCourseState : state.readCourse
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLearnings : () => dispatch(fetchLearningCircleLearningAreas()),
        fetchLearningAreaCourses : () => dispatch(fetchLearningAreaCourses()),
        fetchCoursesPerformance : () => dispatch(fetchCoursesPerformance()),
        saveLearningCircles : (learningCircles) => dispatch(fetchLearningSuccess(learningCircles)),
        clearAddSegmentStates : () => dispatch(clearAddSegmentStates()),
        clearDeleteSegmentStates : () => dispatch(clearDeleteSegmentStates()),
        addSegment : (learningCircleId) => dispatch(addSegmentInLearningArea(learningCircleId)),
        deleteSegment : (learningCircleId) => dispatch(deleteSegmentInLearningArea(learningCircleId)),
        clearSpecificLearningArea : () => dispatch(clearSpecificLearningArea()),
        clearReadCourse : () => dispatch(clearCourseContentStatus())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Learning)