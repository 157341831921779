import React from 'react'
import './password-input.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { styled } from "@mui/material/styles"

const StyledOutlinedInput = styled(OutlinedInput)({
    // focused color for input with variant='outlined'
    "&.Mui-focused fieldset": {
        borderColor: "#14213d !important"
    }
})

const StyledInputLabel = styled(InputLabel)({
    fontFamily: "'Lato', sans-serif",
    // input label when focused
    "&.Mui-focused": {
        color: "#14213d !important"
    }
})

const PasswordInput = (props) => {

    return (
            <FormControl sx={{width: "100%"}} variant="outlined">
                <StyledInputLabel htmlFor="outlined-adornment-password">{props.label}</StyledInputLabel>
                <StyledOutlinedInput
                    id="outlined-adornment-password"
                    name={props.name}
                    placeholder={props.placeholder}
                    type={props.showPassword ? 'text' : 'password'}
                    value={props.value}
                    onChange={props.onChange}
                    inputProps={{
                        style:{fontFamily:'"Lato",sans-serif', fontSize:"15px"}
                    }}
                    onBlur={props.onBlur}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={props.handleClickShowPassword}
                            edge="end" >
                            {props.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label={props.label}
                />
                {
                    props.errorMgs ? 
                    <div className="ms-1 mb-1">
                        <span className="error-message">{props.errorMgs}</span>
                    </div> : null
                }
            </FormControl>
    )
}

export default PasswordInput