import React,{useEffect, useState, useRef} from 'react'
import { Formik, Form, FieldArray, Field } from 'formik'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { faArrowLeft, faCloudUploadAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button'
import { useHistory, useLocation, useParams } from 'react-router'
import { toast } from 'react-toastify'
import moment from 'moment'

import editIcon from '../../assets/icons/edit_white.svg'
import wallpaperPlaceholder from '../../assets/images/wallpaper-placeholder.png'
import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'

import CustomNavbar from '../../components/navbar/CustomNavbar'
import InputBox from '../../common/Input-box/InputBox'
import TextAreaBox from '../../common/textarea-box/TextAreaBox'
import { formatDate } from '../../utils/utils'
import TextField from '../../common/text-field/TextField'
import { actionChangedUnderReview } from '../../redux/review-team-action/objective-under-review/objectiveUnderReview.action'
import { objectiveAllReviewActionchanged } from '../../redux/review-team-action/objective-review/objectiveReview.action'
import { requestSpecificObjective, objectiveReviewModifyAction } from '../../services/review.service'

const OBJECTIVE_ATTACHMENT_NAMES = {
    data : 'objective_data',
    wallpaper : 'objective_wallpaper',
    icon : 'objective_icon',
    reference : 'objective_references'
}

const ObjectiveReviewModify = (props) => {

    const { objectiveDetail, requestObjectiveReview, actionChangeUnderReview, objectiveAllActionChanged } = props

    const wallpaperimageRef = useRef();
    const iconImageRef = useRef();
    const referenceFileRef = useRef();
    const [wallpaperImageFile,setWallpaperImageFile] = useState('')
    const [wallpaperPreviewImage, setWallpaperPreviewImage] = useState("")
    const [iconImageFile, setIconImageFile] = useState("")
    const [iconPreviewImage,setIconPreviewImage] = useState("")
    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [pageLoading, setPageLoading] = useState(true)

    let history = useHistory()
    let location = useLocation()
    const params = useParams()

    let objectives = objectiveDetail.response.objective

    const initialValues = {
        name: !isEmpty(objectives) ? objectives.name : '',
        description: !isEmpty(objectives) ? objectives.description : '',
        reference_links : !isEmpty(objectives) && !isEmpty(objectives) && !isEmpty(objectives.reference_links) ? getLinks(objectives.reference_links) :[''],
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("Please enter your objective name"),
        description: Yup.string(),
    })

    const onSubmit = (values) =>{
        const res = objectiveReviewModifyAction(params.actionId,updateFormData(values))
        .then((res) => {
            actionChangeUnderReview()
            objectiveAllActionChanged()
            toast.success(res.data.message , {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            history.goBack()
            history.goBack()
        }).catch((err) => {
            if(err.response){
                if(err.response.data){
                    toast.error(err.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        })
         
    }

    function updateFormData(objectiveData){
        let objectiveFormData = new FormData()
        if(!isEmpty(existingReferences)){
            objectiveData.references = existingReferences
        } else {
            objectiveData.references = []
        }

        objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.data, JSON.stringify(objectiveData))

        if(wallpaperImageFile){
            objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.wallpaper, wallpaperImageFile)
        }

        if(iconImageFile){
            objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.icon, iconImageFile)
        }

        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }
        return objectiveFormData
    }

    function handleBackArrowEvent(){
        history.goBack();
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.map((link) => (
            contentLink.push(link.url)
        ))
        return contentLink

    }

    useEffect(() => {                
        if(!objectiveDetail.loading && !isEmpty(objectiveDetail.response)){
            console.log(objectiveDetail.response)
            setPageLoading(false)
            setExistingReferences(objectiveDetail.response.objective.reference_files)
            setWallpaperPreviewImage(objectiveDetail.response.objective.wallpaper)
            setIconPreviewImage(objectiveDetail.response.objective.icon)
        }

        if(!objectiveDetail.loading && !isEmpty(objectiveDetail.error)){
            console.error(objectiveDetail.error)
            toast.error(objectiveDetail.error.data.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    },[objectiveDetail])

    useEffect(() => {
        if(isEmpty(objectiveDetail.response)){
            requestObjectiveReview(params.actionId)
        }
    },[])

    useEffect(() => {
        if(wallpaperImageFile){
            const reader = new FileReader()
            reader.onloadend =() =>{
                setWallpaperPreviewImage(reader.result)
            }
            reader.readAsDataURL(wallpaperImageFile)
        }else{
            setWallpaperPreviewImage(null)
        }
    }, [wallpaperImageFile])
    
    useEffect(() =>{
        if(iconImageFile){
            const reader = new FileReader()
            reader.onloadend = () => {
                setIconPreviewImage(reader.result)
            }
            reader.readAsDataURL(iconImageFile)
        }else{
            setIconPreviewImage(null)
        }
    },[iconImageFile])

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index != i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index != i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }

    return (
        <>
            { !pageLoading && !isEmpty(objectives)?
                <>
                    <CustomNavbar />
                    <div className="container pb-3">
                        <div className="mt-3 d-flex justify-content-start align-items-center mb-2">
                            <FontAwesomeIcon className="back-arrow-icon" icon={faArrowLeft} onClick={handleBackArrowEvent} />
                            <h4 className="fs20-lh32 mb-0">Modify VCF</h4>
                        </div>
                        <div className="wallpaper-preview-container">
                            <img className="wallpaper-preview-image" src={wallpaperPreviewImage ? wallpaperPreviewImage : wallpaperPlaceholder} alt="" />
                            <button className="image-button" onClick={(event) => {
                                    event.preventDefault();
                                    wallpaperimageRef.current.click();
                                }}><img src={editIcon} alt=""></img></button>
                        </div>
                        <input type="file"
                            style={{display:"none"}} 
                            ref={wallpaperimageRef} 
                            accept="image/png, image/jpeg, image/jpg"
                            name="objective_wallpaper"
                            onChange={(event) =>{
                                const file = event.target.files[0];
                                if(file && file.type.substr(0,5) === "image"){
                                    setWallpaperImageFile(file)
                                }else {
                                    setWallpaperImageFile(null)
                            }
                        }} />

                        <div className="icon-preview-container">
                            <img className="icon-preview-image" src={iconPreviewImage ? iconPreviewImage : iconPlaceholder} alt=""/>
                            <button className="image-button" onClick={(event) => {
                                    event.preventDefault();
                                    iconImageRef.current.click();
                                }}><img src={editIcon} alt=""></img></button>
                        </div>
                        <input type="file"
                            style={{display:"none"}} 
                            ref={iconImageRef} 
                            accept="image/*"
                            name="objective_icon"
                            onChange={(event) =>{
                                const file = event.target.files[0];
                                if(file && file.type.substr(0,5) === "image"){
                                    setIconImageFile(file)
                                }else {
                                    setIconImageFile(null)
                            }
                        }} />
                        <div className="mt-3">
                            <Formik 
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema ={ validationSchema }
                                onSubmit = {onSubmit} >
                                {props => {
                                    const { values, touched, errors, handleChange, handleBlur, resetForm } = props
                                    return (
                                        <>
                                            <Form>
                                                <div>
                                                    <div>
                                                        <InputBox type="text" 
                                                            label="Name"
                                                            required = {true}
                                                            name="name"
                                                            placeholder="Enter your VCF name"
                                                            value={ values.name }
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errorMgs={touched.name && errors.name}
                                                        />
                                                    </div>
                                                    <div className="mt-4">
                                                        <TextAreaBox name="description"
                                                            placeholder="Enter your VCF description"
                                                            label="Description"
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errormgs={touched.description && errors.description}
                                                        />
                                                    </div>
                                                    <div className="mt-3">
                                                        <TextField label="Start Date" value={formatDate(objectives.start_date)} />
                                                    </div>
                                                    <div className="mt-3">
                                                        <TextField label="End date" value={formatDate(objectives.end_date)} />
                                                    </div>
                                                    <div className="mt-4">
                                                        <FieldArray name="reference_links" >
                                                            {fieldArrayProps => {
                                                                const { push, remove, form } = fieldArrayProps
                                                                const { values } = form 
                                                                const { reference_links } = values
                                                                return (
                                                                    <div>
                                                                        {reference_links.map((file,index) => (
                                                                            <>
                                                                            <div key={index}>
                                                                                <Field name={`reference_links[${index}]`}>
                                                                                    {props => {
                                                                                        const {field,meta} = props
                                                                                        return (
                                                                                            <>
                                                                                                <InputBox type="url" 
                                                                                                    label="Reference Link"
                                                                                                    placeholder="http://www.example.com"
                                                                                                    {...field}
                                                                                                    errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                                />
                                                                                            </>
                                                                                        )
                                                                                    }}
                                                                                </Field>
                                                                                <div className="d-flex justify-content-end mt-2">
                                                                                    {index > 0 && (
                                                                                        <Button type="button" className="me-1 brown-color-button" variant="danger" 
                                                                                        onClick={() => remove(index)}> Delete </Button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            </>
                                                                        ))}
                                                                        <Button type='button' className="blue-color-button" onClick={() => push('')}> 
                                                                            Add Link</Button>
                                                                    </div>
                                                                )
                                                            }}
                                                        </FieldArray>
                                                    </div>
                                                </div>
                                                <div className="p-relative  mt-3">
                                                    <div>
                                                        {!isEmpty(existingReferences) || !isEmpty(referenceFiles.files) 
                                                            ? <label className="mt-3 mb-0 font-weight-bold fs16-lh20">Reference Files</label> : null}
                                                        { !isEmpty(existingReferences) ? existingReferences.map((reference, index) => (
                                                                <div key={index} className="mb-1">
                                                                    <a  href={reference.url} target="_blank" className="d-inline filename fs15-lh20"> {reference.originalname}</a>
                                                                    <FontAwesomeIcon className="d-inline float-right me-2 secondary-color" 
                                                                        onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                                </div>
                                                            )
                                                        ) : null}
                                                        { !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? referenceFiles.files.map((file,index)=>(
                                                            <div key={index} className="mb-1">
                                                                <p className="d-inline fs15-lh20"> { file.filename ? file.filename : file.name}</p>
                                                                <FontAwesomeIcon className="d-inline float-right me-2 secondary-color" 
                                                                    onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                            </div>
                                                        )) : null}
                                                    </div>
                                                    <button className="upload-image" onClick={(event) => {
                                                            event.preventDefault();
                                                            referenceFileRef.current.click();
                                                        }}> <FontAwesomeIcon icon={faCloudUploadAlt} className="me-1" /> Click to upload attachment</button>
                                                </div>
                                                <input type="file"             //accept all files
                                                    style={{display:"none"}} 
                                                    ref={referenceFileRef} 
                                                    multiple
                                                    accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx" 
                                                    name="objective_icon"
                                                    onChange={(event) =>{
                                                        const file = event.target.files
                                                        if(file){
                                                            handleReferenceFileAddition(file)
                                                        }
                                                }} />
                                                <div className="d-flex justify-content-center align-center mt-4">
                                                    <Button className="accept-button" type="Submit">Modify VCF</Button>
                                                </div>
                                            </Form> 
                                        </>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </> :null
            }
        </>
    )
}

const mapStateToProps = ( state ) => {
    return {
        objectiveDetail : state.reviewSpecificObjectiveDetail 
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return {
        requestObjectiveReview : (userId) => dispatch(requestSpecificObjective(userId)),
        actionChangeUnderReview : () => dispatch(actionChangedUnderReview()),
        objectiveAllActionChanged : () => dispatch(objectiveAllReviewActionchanged()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectiveReviewModify)