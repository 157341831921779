import React from 'react'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'

const DesktopActionButton = (props) => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <CustomizeButton variant="primary-color-button" label="Accept" className="me-4" handleClick={props.acceptAction} />
            <CustomizeButton variant="secondary-color-button" label="Modify" className="me-4" handleClick={props.modifyAction}/>
            <CustomizeButton variant="red-color-button" label="Reject" handleClick={props.rejectAction} />
        </div> 
    )
}

export default DesktopActionButton