import { REQUEST_YOUR_MEETING, 
         YOUR_MEETING_SUCCESS, 
         YOUR_MEETING_FAILURE , 
         CLEAR_YOUR_MEETING_STATE,
         INSERTING_MEETING_IN_YOUR_MEETING } from './YourMeeting.types'

export const requestYourMeeting = () => {
    return {
        type : REQUEST_YOUR_MEETING
    }
}

export const yourMeetingSuccess = (resData) => {
    return {
        type : YOUR_MEETING_SUCCESS,
        payload : resData
    }
}

export const yourMeetingFailure = (error) => {
    return {
        type : YOUR_MEETING_FAILURE,
        payload : error
    }
}

export const addMeeting = (array) => {
    return {
        type : INSERTING_MEETING_IN_YOUR_MEETING,
        payload : {array}
    }
}

export const clearYourMeetingState = () => {
    return {
        type : CLEAR_YOUR_MEETING_STATE
    }
}