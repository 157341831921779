import { REQUEST_COURSE,
         COURSE_SUCCESS,
         COURSE_FAILURE,
         CLEAR_COURSE,
         INSERT_COURSE, 
         SAVE_COURSE} from './CourseTypes'

export const requestCourseContentStatus = () => {
    return {
        type : REQUEST_COURSE
    }
}

export const courseContentStatusSuccess = (resData) => {
    return {
        type : COURSE_SUCCESS,
        payload : resData
    }
}

export const courseContentStatusFailure = (error) => {
    return {
        type : COURSE_FAILURE,
        payload : error
    }
}

export const clearCourseContentStatus = () => {
    return {
        type : CLEAR_COURSE
    }
}

export const insertCourseData = (payload) => {
    return {
        type : INSERT_COURSE,
        payload : payload
    }
}

export const saveCourse = ( courseId, courseArray ) => {
    return {
        type : SAVE_COURSE,
        payload : { courseId, courseArray }
    }
}