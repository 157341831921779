import { combineReducers } from "redux";
import individualAuthenticationReducer from "./authentication/AuthenticationReducer";
import objectivesReducer from "./objectives/ObjectivesReducer";
import fetchProfileDataReducer from './profile/profileReducer'
import fetchObjectivesReducer from './fetch-objectives/FetchObjectivesReducer'
import goalReducer from "./goals/GoalsReducer";
import fetchMetricReducer from './fetch-metric/FetchMetricReducer'
import metricReducer from "./create-metric/CreateMetricReducer";
import fetchGoalsReducer from "./fetch-goals/FetchGoalsReducer";
import fetchCreateMetricDataReducer from "./fetch-create-metric-data/FetchCreateMetricDataReducer";
import deleteMetricReducer from "./delete-metric/DeleteMetricReducer";
import goalProgressReducer from "./goal-progress/fetch-metric/GoalProgressReducer";
import updateComputeProgressMetricReducer from "./goal-progress/update-compute-progress-metric/UpdateComputeProgressMetricReducer";
import computeMetricProgressReducer from "./goal-progress/compute-metric-progress/ComputeMetricProgressReducer";
import CULearningReducer from "./learning/create-edit-learning/CULearningReducer";
import fetchLearningReducer from "./learning/fetch-learning/FetchLearningReducer";
import deleteLearningAreaReducer from "./learning/delete-learning-Area/DeleteLearningAreaReducer";
import deleteSegmentReducer from "./learning/delete-segment-in-learning-area/DeleteSegment.reducer";
import addSegmentReducer from "./learning/add-segment-in-learning-area/AddSegment.reducer";
import cuCourseReducer from "./learning/create-edit-course/CreateEditReducer";
import listOfCoursesReducer from "./learning/list-courses/ListCoursesReducer";
import deleteCourseReducer from "./learning/delete-courses/DeleteCourseReducer";
import courseReducer from "./learning/course/CourseReducer";
import learningDetailsTableReducer from "./learning/course-performance/CoursePerformanceReducer";
import composeActionReducer from "./compose/compose.reducer";
import fetchSentActionsReducer from "./notification/sent/sent.reducer";
import fetchInboxActionReducer from "./notification/inbox/inbox.reducer";
import specificObjectiveReducer from "./specific-objective/SpecificObjectiveReducer";
import modifyObjectiveReducer from "./notification/modify-objective/ModifyObjective.reducer";
import choosePathObjectiveReducer from "./notification/choose-path/ChoosePathObjective.reducer";
import refernceReducer from "./notification/reference/Reference.reducer";
import addNoteReducer from "./notification/add-note/AddNote.reducer";
import deleteNoteReducer from "./notification/delete-note/DeleteNote.reducer";
import developmentAreaReducer from "./video-conference/development/Development.reducer";
import objectiveForSelectReducer from "./video-conference/objective/Objective.reducer";
import coursesForSelectReducer from "./video-conference/course/Course.reducer";
import metricsForSelectReducer from "./video-conference/metric/Metric.reducer";
import yourMeetingReducer from "./video-conference/your-meeting/YourMeeting.reducer";
import invitesReducer from "./video-conference/invites/Invites.reducer";
import videoChatReducer from "./video-conference/video-chat/videoChat.reducer";
import membersReducer from "./user/Members/members.reducer";
import invitedMembersReducer from "./user/invited-member/invitedMembers.reducer";
import memberDetailReducer from "./user/member-detail/membersDetail.reducer";
import updateProfileReducer from "./update-profile/updateProfile.reducer";
import updateOrgProfileReducer from "./update-org-profile/updateOrgProfile.reducer";
import departmentReducer from "./department/Department.reducer";
import cuDepartmentReducer from "./CU-department/CUDepartment.reducer";
import branchesReducer from "./Branch/branches/Branches.reducer";
import cuBranchReducer from "./Branch/CU-branch/CUBranch.reducer";
import nonInvitedMembersReducer from "./user/non-invited-member/nonInvitedMembers.reducer";
import teamMembersReducer from "./team-member/teamMember.reducer";
import reviewTeamReducer from "./review-team/ReviewTeam.reducer";
import objectiveReviewAllReducer from "./review-team-action/objective-review/objectiveReview.reducer";
import reviewSpecificObjectiveReducer from "./review-team-action/specific-objective/specificObjective.reducer";
import objectiveUnderReviewReducer from "./review-team-action/objective-under-review/objectiveUnderReview.reducer";
import notificationDetailReducer from "./notification/notification-detail/notificationDetail.reducer";
import specificGoalReducer from "./specific-goal/specificGoal.reducer";
import goalSelectReducer from "./goal-select/goalSelect.reducer";
import specificMetricReducer from "./specific-metric/specificMetric.reducer";
import specificLearningAreaReducer from "./learning/specific-learning-area/specificLearningArea.reducer";
import modifyLearningAreaReducer from "./notification/modify-learning-area/ModifyLearningArea.reducer";
import subscriptionReducer from "./subscription/Subscription.reducer";
import companyViewReducer from "./company-view/CompanyView.reducer";

const appReducer = combineReducers({
    individualAuthentication : individualAuthenticationReducer,
    profileData : fetchProfileDataReducer,
    objectiveCUD : objectivesReducer,
    fetchObjectivesData : fetchObjectivesReducer,
    specficObjective : specificObjectiveReducer,
    goalCUD : goalReducer,
    fetchMetricData : fetchMetricReducer,
    metricCUD : metricReducer,
    fetchGoals: fetchGoalsReducer,
    specificGoal : specificGoalReducer,
    fetchCreateMetricData: fetchCreateMetricDataReducer,
    deleteMetric : deleteMetricReducer,
    readGoalProgress : goalProgressReducer,
    specificMetric : specificMetricReducer,
    updateComputeProgressMetric : updateComputeProgressMetricReducer,
    computeProgressMetric : computeMetricProgressReducer,
    learningCU : CULearningReducer,
    fetchLearningCircle : fetchLearningReducer,
    deleteLearningArea : deleteLearningAreaReducer,
    addSegment : addSegmentReducer,
    deleteSegment : deleteSegmentReducer,
    cuCourse : cuCourseReducer,
    listOfCourses : listOfCoursesReducer,
    deleteCourse : deleteCourseReducer,
    readCourse : courseReducer,
    learningDetailsTable : learningDetailsTableReducer,
    composeAction : composeActionReducer,
    fetchSentActions : fetchSentActionsReducer,
    fetchInboxActions : fetchInboxActionReducer,
    modifyObjective : modifyObjectiveReducer,
    choosePathObjective : choosePathObjectiveReducer,
    references : refernceReducer,
    addNote : addNoteReducer,
    deleteNote : deleteNoteReducer,
    developmentArea : developmentAreaReducer,
    objectiveForSelect : objectiveForSelectReducer,
    courseForSelect : coursesForSelectReducer,
    metricsForSelect : metricsForSelectReducer,
    videoConferenceYourMeeting : yourMeetingReducer,
    videoConferenceInvites : invitesReducer,
    videoChat : videoChatReducer,
    members : membersReducer,
    invitedMembers : invitedMembersReducer,
    memberDetail : memberDetailReducer,
    updateProfileData : updateProfileReducer,
    updateOrgProfileData : updateOrgProfileReducer,
    departments : departmentReducer,
    cuDepartment : cuDepartmentReducer,
    branches : branchesReducer,
    cuBranch : cuBranchReducer,
    nonInvitedMembers : nonInvitedMembersReducer,
    teamMembers : teamMembersReducer,
    reviewTeam : reviewTeamReducer,
    objectiveReviewAll : objectiveReviewAllReducer,
    reviewSpecificObjectiveDetail : reviewSpecificObjectiveReducer,
    objectiveUnderReview : objectiveUnderReviewReducer,
    notificationDetail : notificationDetailReducer,
    goalSelect : goalSelectReducer,
    specificLearningArea : specificLearningAreaReducer,
    modifyLearningArea : modifyLearningAreaReducer,
    subscription : subscriptionReducer,
    companyView : companyViewReducer
});

const RootReducer = (state,action) =>{
    if(action.type == "USER_LOGOUT"){
        return appReducer(undefined, action)
    }
    return appReducer(state,action)
}

export default RootReducer