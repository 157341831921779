import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import qs from 'query-string'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'

import { fetchCourse, specificLearningArea } from '../../../services/learning.service'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { clearCoursesState } from '../../../redux/video-conference/course/Course.action'
import { clearSpecificLearningArea } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'
import DevelopmentPopup from '../../../components/development-popup/DevelopmentPopup'
import ReferenceFiles from '../../../components/reference-files/ReferenceFiles'
import RejectPopup from '../../../components/reject-popup/RejectPopup'
import { newMailAdded } from '../../../redux/notification/sent/sent.actions'
import { acceptAction } from '../../../services/notification.service'
import { modifyLearningAreaSuccess } from '../../../redux/notification/modify-learning-area/ModifyLearningArea.action'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import { faLink, faListAlt, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { developmentAcceptancePromise } from '../../../services/notification.service' 
import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clearNotificationDetailState, mailActionChanges } from '../../../redux/notification/notification-detail/notificationDetail.action'
import StatusChip from '../../../components/status-chip/StatusChip'
import Container from '../../../components/desktop-layout/container/Container'
import DesktopActionButton from '../../../components/desktop-layout/desktop-action-button/DesktopActionButton'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../../components/desktop-layout/description/Description'
import Header from '../../../common/header/Header'
import LearningCard from '../../../components/learning-card/LearningCard'

const COMPONENTS = {
    learningArea : 'learningArea',
    course : 'course'
}

const LearningInboxDetail = (props) => {

    const { requestSpecificdevelopmentArea, 
            specificLearningAreaState, 
            readCourseState, 
            requestSpecificCourse,
            notificationStatusChanges,
            newMessageAdded,
            saveLearningArea } = props

    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const action = location.pathname.split("/")[3];
    const isDesktop = useMediaQuery('(min-width:992px)')

    const learningArea = specificLearningAreaState.response.learning_area
    const course = readCourseState.response.course

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ learningCirclePopup, setLearningCirclePopup ] = useState(false)
    const [ rejectPopup,setRejectPopup ] = useState(false)
    const [ activeCard, setActiveCard ] = useState({})
    const [ menu, setMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function handleAcceptAction(){
        if(action === "course"){
            acceptAction(queryParams.actionId,"course")
            .then((res) => {
                newMessageAdded()
                notificationStatusChanges()
                if(res){
                    toast.success(res.data.message , {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                    history.goBack()
                }
            })
            .catch((error) => 
                toast.error(error.response.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            )
        }else {
            toggleLearningCirclepopup()
        }
    }

    function onLearningCircleSelected(circleSegment){
        developmentAcceptancePromise(queryParams.actionId, circleSegment)
        .then((res) => {
            newMessageAdded()
            notificationStatusChanges()
            if(res){
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                history.goBack()
            }
        })
        .catch((error) => 
            toast.error(error.response.data.error, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        )
    }

    function toggleLearningCirclepopup(){
        setLearningCirclePopup(ps => !ps)
    }

    function toggleRejestPopup(){
        setRejectPopup( ps => !ps )
    }

    function handleModifyAction(){
        if(action === "course"){
            history.push(`/notification/${params.notificationId}/modify-course?course_id=${course._id}&actionId=${queryParams.actionId}`)
        }else{
            saveLearningArea(specificLearningAreaState.response)
            history.push(`/notification/${params.notificationId}/modify-learning-area?development_id=${learningArea._id}&actionId=${queryParams.actionId}`)
        }
    }

    function handleCardClick(component, id){
        setActiveCard(ps => {
            return {
                currComponent : component,
                id : id
            }
        })
    }

    function getActiveCourseAreaDetails(){
        if(isEmpty(activeCard)){
            return null
        }

        const courseDoc = learningArea.courses.find(course => course._id === activeCard.id)
        if(isEmpty(courseDoc)){
            return null
        }

        return (
            <div>
                <div>
                    <div className={isDesktop ? "d-flex align-items-center mt-3" : "d-flex align-items-center ml-20 mr-20 mt-3"}>
                        <h5 className="mb-0 lato-fs22-normal-lh32 font-bold primary-text-color">{courseDoc.name}</h5>
                    </div>
                    <div className={isDesktop ? "mb-3 mt-3" :'mb-3 px-3 mt-3'}>
                        <Description description={courseDoc.description} />
                    </div>
                    <div className={isDesktop ? "mt-5" : 'px-3 mt-5'}>
                        <ul className='ps-0'>
                            { 
                                courseDoc.course_content_files.map((file) => (
                                    <li style={{listStyle:"none",fontSize:"0",marginBottom:"5px"}} key={file._id}>
                                        <div className="d-flex align-items-center mb-2">
                                            <FontAwesomeIcon icon={faPaperclip} className="fs-15 me-1 acent-color" />
                                            <a href={file.url} style={{wordBreak:'break-word'}}
                                                className="link lato-fs17-normal-lh20 primary-text-color two-line-wrap pb-0" target="_blank">
                                                {file.originalname}
                                            </a>
                                        </div>
                                    </li>
                                ))
                            }
                            {
                                courseDoc.course_content_links.map((link) => (
                                    <li style={{listStyle:"none",fontSize:"0",marginBottom:"5px"}} key={link._id}>
                                        <div className="d-flex align-items-center mb-2">
                                            <FontAwesomeIcon icon={faLink} className="fs-15 me-1 acent-color" />
                                            <a href={link.url} style={{wordBreak:'break-word'}}
                                                className="link lato-fs17-normal-lh20 primary-text-color two-line-wrap pb-0" target="_blank">{link.url}</a>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if(!isEmpty(activeCard)){
            setPageLoading(false)
        }
    }, [activeCard])

    useEffect(() => {
        if(action === "course"){
            requestSpecificCourse(params.courseId)
        }else{
            requestSpecificdevelopmentArea(params.developmentId)
        }
    },[])

    useEffect(() => {
        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.response)){
            // console.log(specificLearningAreaState.response)
            if(isEmpty(activeCard)){
                setActiveCard(ps => {
                    return {
                        currComponent : COMPONENTS.learningArea,
                        id : learningArea._id
                    }
                })
            }
            setPageLoading(false)
        }

        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.error)){
            console.log(specificLearningAreaState.error)
            setPageLoading(false)
            if(specificLearningAreaState.error?.data){
                toast.error(specificLearningAreaState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[specificLearningAreaState])

    useEffect(() => {
        if(!readCourseState.loading && !isEmpty(readCourseState.response)){
            setPageLoading(false)
        }

        if(!readCourseState.loading && !isEmpty(readCourseState.error)){
            console.log(readCourseState.error)
            setPageLoading(false)
            if(readCourseState.error?.data){
                toast.error(readCourseState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[readCourseState])

    function getDetail(){
        return (
            <>
                { action === "course" ? 
                    <>
                        <div className={isDesktop ? "" : 'px-3'}>
                            <BreadCrumbs icon={faListAlt}
                                path={[{name : course.parent_learning_area.name,_id : course.parent_learning_area._id}]} 
                                activeId={course.parent_learning_area._id} /> 
                        </div>
                        <div className={isDesktop ? 'mt-2' : 'px-3'}>
                            <h5 className='mb-0 lato-fs22-normal-lh32 primary-text-color mb-0 font-bold'>{course.name}</h5>
                        </div>
                        {course.description ? 
                            <div className={isDesktop ? "mt-3" :"mt-3 px-3"}>
                                <Description description={course.description} />
                            </div> : null}
                    </>: ""}
                    { action === "course" && (!isEmpty(course.course_content_files) || !isEmpty(course.course_content_links)) ?
                        <div className={ isDesktop ? "mt-5" : 'px-3 mt-5'}>
                            <ReferenceFiles referenceFiles={course.course_content_files} referenceLinks={course.course_content_links} />
                        </div> : ""}
                        {
                            action !== "course" ?
                            <>
                                <div className={isDesktop ? '' : 'bg-tertiary-color-v2 border-radius-1rem pt-4 pb-3'}>
                                    <LearningCard 
                                        variant={isDesktop ? "desktop-learning-card" : "mobile-learning-card"}
                                        selected={!isEmpty(activeCard) && (activeCard.id === learningArea._id)}
                                        icon={learningArea.icon}
                                        name={learningArea.name}
                                        cardClickEvent={() => handleCardClick(COMPONENTS.learningArea, learningArea._id)}
                                    />
                                    {
                                        !isEmpty(learningArea.courses) ? 
                                        <>
                                            <hr className={isDesktop ? "" : "mx-3"} />
                                            <div className={isDesktop ? "d-flex overflow-auto" : "scroll-container"}>
                                                {
                                                    learningArea.courses.map((course) => (
                                                        <LearningCard 
                                                            key={course._id}
                                                            variant={isDesktop ? "" : "mobile-course-card"}
                                                            name={course.name}
                                                            selected={!isEmpty(activeCard) && (activeCard.id === course._id)}
                                                            cardClickEvent={() => handleCardClick(COMPONENTS.course, course._id)}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </> : ""
                                    }
                                </div>
                                { !isEmpty(activeCard) && activeCard.currComponent === COMPONENTS.learningArea ?
                                    <div className='px-3'>
                                        <div className="d-flex align-items-center mb-2 mt-3" >
                                            <img className="learning-area-icon me-2" src={learningArea.icon? learningArea.icon : iconPlaceholder} alt="Icon"/>
                                            <h5 className="mb-0 lato-fs22-normal-lh32 primary-text-color font-bold">{ learningArea.name }</h5>
                                        </div>
                                        {learningArea.description ? 
                                            <div className='mt-3'>
                                                <Description description={learningArea.description} />
                                            </div> : null }
                                    </div> :
                                    getActiveCourseAreaDetails()
                                }
                            </>: ""
                        }
                    { queryParams.status !== "pending"? 
                        <div className='px-3'>
                            <StatusChip status={queryParams.status} />
                        </div> : null }
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                <>
                    <>
                        <Navbar enableLogout={true}
                            enableMore={true}
                            enableProfileButton={true}
                            open={open}
                            menu={menu}
                            moreRight="88px"
                            handlePopoverClose={handleMenuClose} 
                            handlePopoverOpen={handleMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    { !pageLoading && (action === "course" ? !isEmpty(course) : !isEmpty(learningArea)) ?
                                        <>
                                            <div className='mt-3'>
                                                {getDetail()}
                                            </div>
                                            {queryParams.status ==="pending" ? 
                                                <div className='mt-4'>
                                                    <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} /> 
                                                </div>
                                            : null }
                                        </> 
                                        : null 
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                </> :
                <>
                    <Navbar 
                        variant="mobile" 
                        enableLogout={true}
                        enableDrawer={true}
                        drawerOpened={drawer}
                        handleDrawerClose={toggleDrawer}
                        onDrawerClick={toggleDrawer}
                    />
                    <div className="container">
                        {/* <HeaderContainer heading={ action === 'course' ? course.name : learningArea.name} BackArrowEvent={handleBackArrowEvent} logoutIcon={true} /> */}
                        <div className='mt-3 px-3'>
                            <Header  
                                // heading={ action === 'course' ? course.name : learningArea.name} 
                                handleBackArrowEvent={handleBackArrowEvent} 
                            />
                        </div>
                        {!pageLoading && (action === "course" ? !isEmpty(course) : !isEmpty(learningArea)) ?
                            <div className="">
                                <div className='mt-3 pb-70'>
                                    {getDetail()}
                                    {queryParams.status ==="pending" ? 
                                        <div className='mt-3'>
                                            <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} /> 
                                        </div>
                                    : null }
                                </div>
                            </div> : null 
                        }
                    </div> 
                    <BottomNavigation />
                </>}
                {
                    learningCirclePopup ? 
                    <DevelopmentPopup open={learningCirclePopup}
                        handleClose={toggleLearningCirclepopup} 
                        onSubmit={onLearningCircleSelected} />
                    : null
                }
                { 
                    rejectPopup? 
                    <RejectPopup open={rejectPopup}
                        handleClose={toggleRejestPopup} 
                        actionId={queryParams.actionId}
                        mailId={params.notificationId} />
                    : null
                }
            </Container>
        </> 
    )
}

const mapStateToProps = (state) => {
    return {
        specificLearningAreaState : state.specificLearningArea,
        readCourseState : state.readCourse
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestSpecificdevelopmentArea : (developmentAreaId) => dispatch(specificLearningArea(developmentAreaId)),
        requestSpecificCourse : (courseId) => dispatch(fetchCourse(courseId)),
        clearCourseState : () => dispatch(clearCoursesState()),
        clearDevelopmentArea : () => dispatch(clearSpecificLearningArea()),
        newMessageAdded : () => dispatch(newMailAdded()),
        saveLearningArea : (response) => dispatch(modifyLearningAreaSuccess(response)),
        clearNotificationDetailState : () => dispatch(clearNotificationDetailState()),
        notificationStatusChanges : () => dispatch(mailActionChanges())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LearningInboxDetail)