import { requestSignupIndividual, 
         individualSignupSuccess, 
         individualSignupFailure,
         individualSigninRequest,
         individualSigninSuccess,
         individualSigninFailure } from '../redux/authentication/AuthenticationAction'
import { fetchSubscriptionFailure, fetchSubscriptionSuccess, requestSubscription } from '../redux/subscription/Subscription.action';
import ProtectedWebClient from '../utils/protected-web-client';
import PublicWebClient from '../utils/public-web-client';
import { storeUserCredentials } from '../utils/utils';

export const individualSignup = ( signupDetails ) => {
    return async (dispatch , getState) => {
        try{
            dispatch(requestSignupIndividual());
            const response = await PublicWebClient.post('/user/sign-up?type=open_org', signupDetails)
            // await storeUserCredentials({
            //     token : response.data.token,
            //     role : response.data.role,
            //     userId : response.data._id
            // });
            dispatch(individualSignupSuccess(response.data));
        } catch(error) {
            dispatch(individualSignupFailure(error.response));
        }
    }
}

export const individualSignin = (signinDetails) => {
    return async (dispatch, getState) => {
        try{
            dispatch(individualSigninRequest())
            const response = await PublicWebClient.post('/user/sign-in', signinDetails)
            if(response.data.gig === false){
                await storeUserCredentials({
                    token : response.data.token,
                    role : response.data.role,
                    userId : response.data._id
                })
            }
            dispatch(individualSigninSuccess(response.data));
        } catch(error){
            dispatch(individualSigninFailure(error.response))
        }
    }
}

export const corporateSignup = (signupDetails) => {
    return async (dispatch, getState) => {
        try{
            dispatch(requestSignupIndividual())
            const response = await PublicWebClient.post(`/user/sign-up?type=closed_org`,signupDetails)
            // await storeUserCredentials({
            //     token : response.data.token,
            //     role : response.data.role,
            //     userId : response.data._id
            // })
            dispatch(individualSignupSuccess(response.data))
        }catch(error){
            dispatch(individualSignupFailure(error.error))
        }
    }
}

export const gigUserCorporateStatus = (token) => {
    return new Promise ((resolve,reject) => {
        PublicWebClient.get("user-profile/activate-user",{
            headers : {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateSubscription = (subscribeId,payload,token) => {
    return new Promise ((resolve,reject) => {
        PublicWebClient.post(`/subscription/${subscribeId}/create`,payload,{
            headers : {
                Authorization : `Bearer ${token} `
            }
        })
        .then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const forgetPassword = (payload) => {
    return new Promise((resolve, reject) => {
        PublicWebClient.post('/user/request-reset-password', payload).then((res) => {
            resolve(res)
        }).catch(err => {
            if(err.toJSON().message === 'Network Error'){
                reject('Connect to Internet')
            } else {
                reject(err)
            }
        })
    })
}

export const resetPassword = (payload) => {
    return new Promise((resolve, reject) => {
        PublicWebClient.put('/user/reset-password', payload)
        .then((res) => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export const fetchSubscription = () => {
    return async (dispatch) => {
        try{
            dispatch(requestSubscription())
            const response = await ProtectedWebClient.get(`/subscription/read`)
            dispatch(fetchSubscriptionSuccess(response.data))
        }catch(error){
            dispatch(fetchSubscriptionFailure(error.response))
        }
    }
}