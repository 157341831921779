import React, {forwardRef} from 'react'
import EditIcon from '@mui/icons-material/Edit'

import IconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import ImgMenu from '../../common/img-menu/ImgMenu'

import './process-icon-edit.css'
import { IconButton } from '@mui/material'

const ProcessIconEdit = (props, ref) => {
    const containerClassName = props.containerClassName ? `icon-preview-container ${props.containerClassName}` : 'icon-preview-container'
    return (
        <div className={containerClassName}>
            <img className="icon-preview-image" src={props.previewImage || IconPlaceholder} alt=""/>
            <IconButton
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    "&:hover" : {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }}
                size="small"
                id="icon-edit-icon"
                aria-controls={props.menuOpen ? 'icon-edit-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={props.menuOpen ? 'true' : undefined}
                onClick={props.handleEditIconClick}
            >
                <EditIcon sx={{color: 'white', fontSize: '1rem'}}/>
            </IconButton>
            <ImgMenu 
                id="icon-edit-menu"
                ariaLabelledBy="icon-edit-icon"
                anchorEl={props.anchorEl}
                open={props.menuOpen}
                onClose={props.handleMenuClose}
                onUploadClick={props.onUploadClick}
                onRemoveClick={props.onRemoveClick}
                disableRemove={Boolean(!props.previewImage)}
            />
            <input
                type="file"
                style={{display:"none"}} 
                ref={ref} 
                accept="image/png,imge/jpg,image/jpeg"
                name="process_icon"
                onChange={props.onInputChange}
            />
        </div>
    )
}

export default forwardRef(ProcessIconEdit)