import { REQUEST_SPECIFIC_OBJECTIVE_FOR_REVIEW,
         SAVE_SPECIFIC_OBJECTIVE_FOR_REVIEW, 
         SPECIFIC_OBJECTIVE_FAILURE_FOR_REVIEW,
         CLEAR_SPECIFIC_OBJECTIVE_STATE,
         SPECIFIC_OBJECTIVE_UPDATED } from './specificObjective.types'

export const requestSpecificObjectiveForReview = () => {
    return {
        type : REQUEST_SPECIFIC_OBJECTIVE_FOR_REVIEW,
    }
}

export const saveSpecificObjectiveForReview = (response) => {
    return {
        type : SAVE_SPECIFIC_OBJECTIVE_FOR_REVIEW,
        payload : response
    }
}

export const specificObjectiveForReviewFailure = (error) => {
    return {
        type : SPECIFIC_OBJECTIVE_FAILURE_FOR_REVIEW,
        payload : error
    }
}

export const specificObjectiveUpdated = () => {
    return {
        type : SPECIFIC_OBJECTIVE_UPDATED
    }
}

export const clearSpecificObjectiveForReview = () => {
    return {
        type : CLEAR_SPECIFIC_OBJECTIVE_STATE
    }
}