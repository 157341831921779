import { REQUEST_MEMBER_DETAIL,
        SAVE_MEMBER_DETAIL,
        MEMBER_DETAIL_FAILURE,
        CLEAR_MEMBER_DETAIL_STATE, 
        UPDATE_SETTINGS} from './membersDetail.types'
import { updateSettings } from './memberDetail.utils'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const memberDetailReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_MEMBER_DETAIL :
            return {
                ...state,
                loading : true,
                error :null
            }

        case SAVE_MEMBER_DETAIL : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case MEMBER_DETAIL_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case UPDATE_SETTINGS : {
            return {
                ...state,
                response : {
                    ...state.response,
                    user_subscriber_mapping : updateSettings(state.response.user_subscriber_mapping, action.payload)
                }
            }
        }

        case CLEAR_MEMBER_DETAIL_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default memberDetailReducer