import {
    FETCH_PROFILE_DATA,
    PROFILE_DATA_FETCH_SUCCESS,
    PROFILE_DATA_FETCH_FAILURE, 
    CLEAR_FETCHED_PROFILE_DATA,
    SAVE_PROFILE_DATA, 
} from './ProfileTypes'

export const requestProfileDataFetch = () => {
    return{
        type : FETCH_PROFILE_DATA
    }
}

export const profileDataFetchSuccess = (resData) => {
    return {
        type : PROFILE_DATA_FETCH_SUCCESS,
        payload : resData 
    }
}

export const profileDataFetchFailure = (error) => {
    return{
        type : PROFILE_DATA_FETCH_FAILURE,
        payload : error
    }
}

export const saveProfileData = (profileData) => {
    return{
        type : SAVE_PROFILE_DATA,
        payload : profileData
    }
}

export const clearProfileDataStates = () => {
    return{
        type : CLEAR_FETCHED_PROFILE_DATA
    }
}