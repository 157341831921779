export const PROCESS_STATUS = {
    active : "active",
    inactive: "inactive",
    needsApproval : 'needs_approval',
    rejected : 'rejected',
    expired : "expired"
}

export const REVIEW_STATUS = {
    pending : "pending",
    approved : "approved",
    modifiedAndApproved : "modified_and_approved",
    rejected : 'rejected',
    unnecessary : 'unnecessary',
    unavailable : "unavailable"
}

export const REVIEW_TYPES = {
    newCreationRequest : "new_creation_request",
    editRequest : "edit_request",
    deleteRequest : "delete_requested"
}

export const ACTIVE_LEVEL_KEY_MAPPING = {
    0 : 'level_zero_objectives',
    1 : 'level_one_objectives',
    2 : 'level_two_objectives',
    3 : 'level_three_objectives'
}

export const CONTROLLER_ROLE = "user_cum_controller"
export const USER_ROLE = "user"
export const INDIVIDUAL_ROLE = "individual"

// front-end 
export const PROD_CLIENT_URL = "https://www.just4.in"
export const DEV_CLIENT_URL= "https://dev.d1udivilaixfri.amplifyapp.com"
export const LOCAL_DEV_CLIENT_URL= "http://localhost:3000"

// back-end
export const DEV_SERVER_URL = "https://api.vgml.in"
export const PROD_SERVER_URL = "https://api.just4.in"
export const LOCAL_DEV_SERVER_URL = "http://localhost:9000"

export const ALL_FILES = [
    "image/*",
    "audio/*",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ".doc",
    ".docx",
    ".odt",
    ".txt",
    ".rtf",
    ".xlsx",
    ".xls",
    ".ods",
    ".xlsx"
]