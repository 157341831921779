import { 
    SAVE_REMOTE_USER,
    REMOVE_REMOTE_USER,
    UPDATE_REMOTE_USER,
    CLEAR_VIDEO_CHAT_STATES, 
    SAVE_SCREEN_SHARING_REMOTE_USER,
    REMOVE_SCREEN_SHARING_REMOTE_USER,
    CHANGING_USER,
    SAVE_LOCAL_VC_DATA
} from './videoChat.types'

export const saveLocalVCData = (data) => {
    return {
        type : SAVE_LOCAL_VC_DATA,
        payload : data
    }
}

export const saveRemoteUser = (user, profileInfo) => {
    return {
        type : SAVE_REMOTE_USER,
        payload : {
            user,
            profileInfo
        }
    }
}

export const saveScreenSharingUser = (user) => {
    return {
        type : SAVE_SCREEN_SHARING_REMOTE_USER,
        payload : user
    }
}

export const removeRemoteUser = (userUid) => {
    return {
        type : REMOVE_REMOTE_USER,
        payload : userUid
    }
}

export const removeScreenSharingUser = () => {
    return {
        type : REMOVE_SCREEN_SHARING_REMOTE_USER,
        // payload : userUid
    }
}

export const updateRemoteUser = (user) => {
    return {
        type : UPDATE_REMOTE_USER,
        payload : user
    }
}

export const changingRemoteUser = (index) => {
    return {
        type : CHANGING_USER,
        payload : index
    }
}

export const clearVideoChatStates = () => {
    return {
        type : CLEAR_VIDEO_CHAT_STATES
    }
}