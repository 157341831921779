import React, { useState, useEffect } from 'react'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { isEmpty, toArray } from 'lodash'
import qs from 'query-string'
import {toast} from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { specificObjectives } from '../../../services/objectives.service'
import './objective-inbox-detail.css'
import RejectPopup from '../../../components/reject-popup/RejectPopup'
import { acceptAction } from '../../../services/notification.service'
import { modifyObjectiveSuccess } from '../../../redux/notification/modify-objective/ModifyObjective.action'
import ObjectiveCard from '../../../components/objective-card/ObjectiveCard'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import ReferenceFiles from '../../../components/reference-files/ReferenceFiles'

import ParentObjectiveCard from '../../../components/parent-obj-card/ParentObjCard'
import ChildObjCard from '../../../components/child-obj-card/ChildObjCard'
import { Tree, TreeNode } from 'react-organizational-chart'
import ObjectivePathCard from '../../../components/objective-path-card/ObjectivePathCard'
import { objectiveActionChanges } from '../../../redux/fetch-objectives/FetchObjectivesAction'
import { clearNotificationDetailState } from '../../../redux/notification/notification-detail/notificationDetail.action'
import StatusChip from '../../../components/status-chip/StatusChip'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Description from '../../../components/desktop-layout/description/Description'
import ProcessWallpaperIcon from '../../../components/desktop-layout/process-wallpaper-icon/ProcessWallpaperIcon'
import DesktopActionButton from '../../../components/desktop-layout/desktop-action-button/DesktopActionButton'
import Header from '../../../common/header/Header'
import Name from '../../../components/name/Name'

const OBJECTIVE_LEVEL_KEY_MAPPING = {
    0 : 'level_zero',
    1 : 'level_one',
    2 : 'level_two',
    3 : 'level_three'
}

const InboxActionDetail = (props) => {

    const { specificObjective, specificObjectiveState, modifyObjective, objectiveActionChanges, clearNotificationDetailState } = props

    const [pageLoading,setPageLoading] = useState(true)
    const [rejectPopup,setRejectPopup] = useState(false)
    const [ activeObjective, setActiveObjective ] = useState({})
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const history = useHistory()
    const params = useParams()
    const location = useLocation()

    const queryParams = qs.parse(location.search)
    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    const objective = specificObjectiveState.response.objective

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null);
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function toggleRejestPopup(){
        setRejectPopup(!rejectPopup)
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    const handleAcceptAction = () => {
        acceptAction(queryParams.actionId,"objective")
        .then((res) => {
            // console.log(res)
            objectiveActionChanges()
            clearNotificationDetailState()
            if(res){
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                history.goBack()
            }
        })
        .catch((error) => {
            console.log(error.response)
            if(!isEmpty(error.response)){
                if(!isEmpty(error.response.data)){
                    toast.error(error?.response?.data?.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }else{
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        })
    }

    function handleModifyAction(){
        modifyObjective(specificObjectiveState.response)
        history.push(`/notification/${params.notificationId}/modify-vcf?action=${queryParams.action}&actionId=${queryParams.actionId}&objectiveId=${params.objectiveId}`)
    }

    function handleCardClick(objective){
        setActiveObjective(objective)
    }

    function objectiveTree(){
        if(objective.level === 0){
            return (
                <Tree label={<ParentObjectiveCard 
                                variant={isDesktop ? "desktop-view" : ""}
                                // wallPaper={objective.wallpaper}
                                hideMenu={true}
                                name={objective.name} 
                                onClick={() => handleCardClick(objective)}
                                active={objective._id === activeObjective._id}/>} >
                    {objective.sub_objectives.map((subObjective,index) => (
                        <TreeNode label={<ChildObjCard 
                                            variant={isDesktop ? "desktop-view" : ""}
                                            key={subObjective._id}
                                            hideMenu={true}
                                            onClick={() => handleCardClick(subObjective)}
                                            active={subObjective._id === activeObjective._id}
                                            // wallPaper={subObjective.wallPaper ? subObjective.wallPaper : ""}
                                            name={subObjective.name} />} >
                            {!isEmpty(subObjective.sub_objectives) ?
                                subObjective.sub_objectives.map((subSubObjective,index) =>(
                                    <TreeNode label={<ChildObjCard 
                                                        key={subSubObjective._id}
                                                        variant={isDesktop ? "desktop-view" : ""}
                                                        hideMenu={true}
                                                        onClick={() => handleCardClick(subSubObjective)}
                                                        active={subSubObjective._id === activeObjective._id}
                                                        // wallPaper={subSubObjective.wallpaper}
                                                        name={subSubObjective.name} />}>
                                        {!isEmpty(subSubObjective.sub_objectives) ?
                                            subSubObjective.sub_objectives.map((subSubSubObjective, index) => (
                                                <TreeNode label={<ChildObjCard 
                                                                    key={subSubSubObjective._id} 
                                                                    variant={isDesktop ? "desktop-view" : ""}
                                                                    hideMenu={true}
                                                                    onClick={() => handleCardClick(subSubSubObjective)}
                                                                    active={subSubSubObjective._id === activeObjective._id}
                                                                    // wallPaper={subSubSubObjective.wallPaper}
                                                                    name={subSubSubObjective.name} />} />
                                            ))
                                        : null}
                                    </TreeNode>
                                ))
                                : null }
                        </TreeNode>
                    ))}
                </Tree>
            )
        } else {

            const objectivePath = {...objective.path}
            delete objectivePath[OBJECTIVE_LEVEL_KEY_MAPPING[objective.level]]

            const objectiveBase = <TreeNode label={<ParentObjectiveCard 
                                                        name={objective.name} 
                                                        variant={isDesktop ? "desktop-view" : ""}
                                                        // wallPaper={objective.wallpaper}
                                                        hideMenu={true}
                                                        onClick={() => handleCardClick(objective)}
                                                        active={objective._id === activeObjective._id} />} >
                                    {objective.sub_objectives.map((subObjective,index) => (
                                        <TreeNode label={<ChildObjCard 
                                                            key={subObjective._id}
                                                            variant={isDesktop ? "desktop-view" : ""}
                                                            // wallPaper={subObjective.wallpaper}
                                                            hideMenu={true}
                                                            onClick={() => handleCardClick(subObjective)}
                                                            active={subObjective._id === activeObjective._id}
                                                            name={subObjective.name} />} >
                                            {!isEmpty(subObjective.sub_objectives) ?
                                                subObjective.sub_objectives.map((subSubObjective, index) =>(
                                                    <TreeNode label={<ChildObjCard 
                                                                        key={subSubObjective._id}
                                                                        variant={isDesktop ? "desktop-view" : ""}
                                                                        hideMenu={true}
                                                                        onClick={() => handleCardClick(subObjective)}
                                                                        active={subSubObjective._id === activeObjective._id}
                                                                        // wallPaper={subSubObjective.wallpaper}
                                                                        name={subSubObjective.name}/>}>
                                                        {!isEmpty(subSubObjective.sub_objectives) ?
                                                            subSubObjective.sub_objectives.map((subSubSubObjective, index) => (
                                                                <TreeNode label={<ChildObjCard 
                                                                                    key={subSubSubObjective._id}
                                                                                    variant={isDesktop ? "desktop-view" : ""}
                                                                                    hideMenu={true}
                                                                                    onClick={() => handleCardClick(subSubObjective)}
                                                                                    active={subSubSubObjective._id === activeObjective._id}
                                                                                    // wallPaper={subSubSubObjective.wallpaper}
                                                                                    name={subSubSubObjective.name} />} />
                                                            ))
                                                        : null}
                                                    </TreeNode>
                                                ))
                                                : null }
                                        </TreeNode>
                                    ))}
                                </TreeNode>
            return (
                <Tree label={<ObjectivePathCard name={objectivePath.level_zero.name} />}>
                    {
                        objectivePath.level_one ? 
                            <TreeNode label={<ObjectivePathCard name={objectivePath.level_one.name} />}>
                                {
                                    objectivePath.level_two ? 
                                        <TreeNode label={<ObjectivePathCard name={objectivePath.level_two.name} />}>
                                            {objectiveBase}
                                        </TreeNode> : objectiveBase
                                }
                            </TreeNode> : objectiveBase
                    }
                </Tree>
            )
        }
    }

    function getPath(){
        if(!isEmpty(objective)){
            let pathArray =  toArray(objective.path)
            return pathArray.splice(0,pathArray.length-1)
        } else {
            return null
        }
    }

    useEffect(() => {
        if(isEmpty(specificObjectiveState.response)){
            specificObjective(params.objectiveId)    
        }
    }, [])

    useEffect(() => {
        if(!specificObjectiveState.loading && !isEmpty(specificObjectiveState.response)){
            if(!isEmpty(objective)){
                setActiveObjective( objective )
            }
            setPageLoading(false)
        }

        if(!specificObjectiveState.loading && !isEmpty(specificObjectiveState.error)){
            console.log(specificObjectiveState.error)
            if(!isEmpty(specificObjectiveState.error)){
                if(specificObjectiveState.error.data){
                    toast.error(specificObjectiveState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[specificObjectiveState])

    function getDetail(){
        return (
            <>
                {
                    objective.level > 0 ? 
                    <BreadCrumbs 
                        path={getPath()}
                        icon={faThLarge} 
                        truncate={isMobile}/>
                    : null
                }
                <div className={isDesktop ? "overflow-auto" : "scroll-container bg-tertiary-color-v2 py-3 border-radius-1rem"}>
                    <div className={isDesktop ? "d-flex w-100 mb-3 ps-2 pe-2" : "d-flex w-100 mb-3"}>
                        {objectiveTree()}
                    </div>
                </div>
                <hr className="mx-3"/>
                <div className={isDesktop ? '' : 'px-3'}>
                    { !isEmpty(activeObjective) ?
                        <>
                            <div>
                                <ProcessWallpaperIcon 
                                    variant={isDesktop ? "" : "mobile"}
                                    wallpaper={activeObjective.wallpaper}
                                    icon={activeObjective.icon} />
                            </div>
                            {
                                activeObjective.name ?
                                    <div className='mt-3'>
                                        <Name name={activeObjective.name} />
                                    </div>
                                : null
                            }
                            { activeObjective.description ? 
                                <div className='mt-2'>
                                    <Description description={activeObjective.description} />
                                </div> : null }
                            {!isEmpty(activeObjective.reference_files) || !isEmpty(activeObjective.reference_links) ? 
                                <div className='mt-5'>
                                    <ReferenceFiles referenceFiles={activeObjective.reference_files} 
                                                    referenceLinks={activeObjective.reference_links} /> 
                                </div> : null
                            } 
                        </> : null
                    }
                    { queryParams.status != "pending"? 
                        <div >
                            <StatusChip status={queryParams.status} />
                        </div> : null }
                </div>
            </>
        )
    }

    return (
        <div>
            <Container>
                { isDesktop ? 
                    <>  
                        <Navbar enableLogout={true}
                            enableProfileButton={true} 
                            enableMore={true}
                            open={navbarOpen}
                            moreRight="150px"
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    { !pageLoading &&  !isEmpty(objective) ? 
                                        <>
                                            <div className='mt-3'>
                                                {getDetail()}
                                            </div>
                                            { queryParams.status === "pending" ?
                                                <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} />
                                                : null }
                                        </> :null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </> :
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                        />
                        { !pageLoading &&  !isEmpty(objective) ? 
                            <div className='container'>
                                <div className='mt-3 px-3 mb-3'>
                                    <Header heading={objective.name} handleBackArrowEvent={handleBackArrowEvent} />
                                </div>
                                    <div>
                                        <div className='pb-70'>
                                            {getDetail()}
                                            {queryParams.status === "pending" ? 
                                                <div className='mt-3'>
                                                    <DesktopActionButton acceptAction={handleAcceptAction} modifyAction={handleModifyAction} rejectAction={toggleRejestPopup} /> 
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                <BottomNavigation/>
                            </div> :null
                        }
                    </>
                }
                {rejectPopup ? <RejectPopup handleClose={toggleRejestPopup} open={rejectPopup}
                                actionId={queryParams.actionId}
                                mailId={params.notificationId} /> : null}
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        specificObjectiveState : state.specficObjective,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        specificObjective : (objectiveId) => dispatch(specificObjectives(objectiveId)),
        modifyObjective : (objective) => dispatch(modifyObjectiveSuccess(objective)),
        objectiveActionChanges : () => dispatch(objectiveActionChanges()),
        clearNotificationDetailState : () => dispatch(clearNotificationDetailState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(InboxActionDetail)