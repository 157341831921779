import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'

import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import './gig-users-sign-in.css'

import { storeUserCredentials } from '../../utils/utils'
import Navbar from '../../components/desktop-layout/navbar/Navbar'
import { gigUserCorporateStatus } from '../../services/IndividualAuthentication.service'
import Container from '../../components/desktop-layout/container/Container'
import { INDIVIDUAL_ROLE } from '../../utils/constants'

const GigUsersSignIn = (props) => {

    const { signinState } = props

    const history = useHistory()

    function handleClick(token){
        if(token.user_subscriber_mapping.status === "invited"){
            gigUserCorporateStatus(token.token)
            .then((res) => {
                storeUserCredentials({
                    token : token.token,
                    role : token.user_subscriber_mapping.role,
                    userId : signinState.response?._id
                })
                history.push('/vcf?mode=list-vcfs-by-lvl&lvl=0')
            }).catch((error) => {
                if(error.response){
                    toast.error(error.response.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
        } else {
            storeUserCredentials({
                token : token.token,
                role : token.user_subscriber_mapping.role,
                userId : signinState.response?._id
            })
            history.push('/vcf?mode=list-vcfs-by-lvl&lvl=0')
        }
    }

    console.log(signinState.response)

    return (
        !isEmpty(signinState.response) ?
            <Container>
                <Navbar />
                <div className='bg-tertiary-color-v2 flex-1'>
                    <div className="container gig-user-container">
                        <div className='px-2' style={{maxWidth:"500px", width:"100%"}}>
                            <h5 className="mb-3 lato-fs22-normal-lh32 text-center font-bold primary-text-color">Choose An Account</h5>
                            { !isEmpty(signinState.response.tokens) && signinState.response.tokens.map((token,index) => (
                                <Card sx={{marginBottom:"16px", cursor:"pointer"}} onClick={() => handleClick(token)}>
                                    <CardContent sx={{display:"flex", '&:last-child':{paddingBottom:"16px"}}}>
                                        {/* <div className="d-flex card gig-user-inner-card justify-content-start align-items-center mb-3 pt-cursor" 
                                            key={index} 
                                            onClick={() => handleClick(token)}>  */}
                                            { token.user_subscriber_mapping.role === INDIVIDUAL_ROLE ?
                                                <img className="member-icon" src={signinState.response.userInfo.profile_pic ? signinState.response.userInfo.profile_pic :  iconPlaceholder} alt="image"></img> : 
                                                <img className="member-icon" src={iconPlaceholder} alt="image"></img>}
                                            { !isEmpty(token.user_subscriber_mapping) ?
                                                <div className="ms-2 d-flex flex-column justify-content-center">
                                                    {token.user_subscriber_mapping.role === INDIVIDUAL_ROLE ?
                                                        !isEmpty(signinState.response.userInfo) ?
                                                            <div style={{display:"flex"}}>
                                                                <span className="lato-fs18-normal-lh22 pb-1 pe-1 primary-text-color">{!isEmpty(signinState.response?.userInfo?.person_name?.first_name) ? `${signinState.response?.userInfo?.person_name?.first_name}` : null }</span> 
                                                                { !isEmpty(signinState.response?.userInfo?.person_name?.middle_name) ? <span className="lato-fs18-normal-lh22 pb-1 pe-1 primary-text-color">{`${signinState.response?.userInfo?.person_name?.middle_name}`}</span> : null}
                                                                <span className="lato-fs18-normal-lh22 pb-1 primary-text-color">{!isEmpty(signinState.response?.userInfo?.person_name?.last_name) ? `${signinState.response?.userInfo?.person_name?.last_name}` : null }</span> 
                                                            </div> : null
                                                        :<span className="lato-fs18-normal-lh22 pb-1 primary-text-color">{token.user_subscriber_mapping.subscribed_to.org_name}</span>}
                                                    <span className="lato-fs15-normal-lh20 primary-text-color">{token.user_subscriber_mapping?.role === INDIVIDUAL_ROLE ? "Individual" : "User"  }</span>
                                                </div> : null}
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        : null
    )
}

const mapStateToProps = (state) => {
    return {
        signinState : state.individualAuthentication
    }
}

export default connect(mapStateToProps,null)(GigUsersSignIn)