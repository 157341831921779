import React,{useState} from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#14213d",
        color: theme.palette.common.white,
        fontFamily:"'Lato',sans-serif",
        fontSize: 16,
        padding : "12px 10px"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 18,
        fontFamily:"'Lato',sans-serif",
        color : "#111111",
        padding :"12px 10px"
    },
}));

const MobileStyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#14213d",
        color: theme.palette.common.white,
        fontFamily:"'Lato',sans-serif",
        fontSize: 15,
        padding : "10px 8px"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily:"'Lato',sans-serif",
        color : "#111111",
        padding :"10px 8px"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CoursePerformance = (props) => {

    const [ page, setPage ] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    function getCourseCompletionPercentage(course){
        const percentage = Math.round(((course.completed_content_links_count + course.completed_content_files_count)/(course.total_content_files + course.total_content_links)) * 100)
        return isNaN(percentage) ? 0 : percentage
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    switch(props.variant){
        case "mobile":
            return (
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <MobileStyledTableCell align="center">Sl. No.</MobileStyledTableCell>
                                <MobileStyledTableCell align="center">Learning Circle</MobileStyledTableCell>
                                <MobileStyledTableCell align="center">Learning Area</MobileStyledTableCell>
                                <MobileStyledTableCell align="center">Course</MobileStyledTableCell>
                                <MobileStyledTableCell align="center">Modules</MobileStyledTableCell>
                                <MobileStyledTableCell align="center">Completion (%)</MobileStyledTableCell>
                                <MobileStyledTableCell align="center">Video Conf.</MobileStyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {props.coursePerformance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((learningArea,index) => (
                                <StyledTableRow key={index+1}>
                                    <MobileStyledTableCell align="center" component="th" scope="row"> { page * rowsPerPage + (index+1) } </MobileStyledTableCell>
                                    <MobileStyledTableCell align="center">{learningArea.parent_circle.name}</MobileStyledTableCell>
                                    <MobileStyledTableCell align="center">{learningArea.name}</MobileStyledTableCell>
                                    <MobileStyledTableCell align="center">{learningArea.course ? learningArea.course?.name : '-' }</MobileStyledTableCell>
                                    <MobileStyledTableCell align="center">{learningArea.course ? learningArea.course?.total_content_files + learningArea.course.total_content_links : '-' }</MobileStyledTableCell>
                                    <MobileStyledTableCell align="center">{learningArea.course ? getCourseCompletionPercentage(learningArea.course) : '-' }</MobileStyledTableCell>
                                    <MobileStyledTableCell align="center">{learningArea.course ? learningArea.course?.video_conference_count : '-'}</MobileStyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.coursePerformance.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )

        default :
            return (
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Sl. No.</StyledTableCell>
                                <StyledTableCell align="center">Learning Circle</StyledTableCell>
                                <StyledTableCell align="center">Learning Area</StyledTableCell>
                                <StyledTableCell align="center">Course</StyledTableCell>
                                <StyledTableCell align="center">Modules</StyledTableCell>
                                <StyledTableCell align="center">Completion (%)</StyledTableCell>
                                <StyledTableCell align="center">Video Conf.</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {props.coursePerformance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((learningArea,index) => (
                                <StyledTableRow key={index+1}>
                                    <StyledTableCell align="center" component="th" scope="row"> { page * rowsPerPage + (index+1) } </StyledTableCell>
                                    <StyledTableCell align="center">{learningArea.parent_circle.name}</StyledTableCell>
                                    <StyledTableCell align="center">{learningArea.name}</StyledTableCell>
                                    <StyledTableCell align="center">{learningArea.course ? learningArea.course?.name : '-' }</StyledTableCell>
                                    <StyledTableCell align="center">{learningArea.course ? learningArea.course?.total_content_files + learningArea.course.total_content_links : '-' }</StyledTableCell>
                                    <StyledTableCell align="center">{learningArea.course ? getCourseCompletionPercentage(learningArea.course) : '-' }</StyledTableCell>
                                    <StyledTableCell align="center">{learningArea.course ? learningArea.course?.video_conference_count : '-'}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={props.coursePerformance.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )
    }
}

export default CoursePerformance
