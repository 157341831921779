import { REQUEST_NON_INVITED_MEMBERS,
        SAVE_NON_INVITED_MEMBERS,
        NON_INVITED_MEMBERS_FAILURE,
        CLEAR_NON_INVITED_MEMBER_STATE } from './nonInvitedMembers.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const nonInvitedMembersReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_NON_INVITED_MEMBERS :
            return {
                ...state,
                loading : true,
                error :null
            }

        case SAVE_NON_INVITED_MEMBERS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case NON_INVITED_MEMBERS_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_NON_INVITED_MEMBER_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default nonInvitedMembersReducer