import React, { useEffect, useState } from 'react'
import { isEmpty, size, toArray } from 'lodash'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faThLarge } from '@fortawesome/free-solid-svg-icons'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'

import './metric.css'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { fetchMetrics } from '../../../services/fetchMetric.service'
import { fetchComputedGoalProgress } from '../../../services/metric.service'
import GoalProgress from '../../../components/goal-progress/GoalProgress'
import ProcessHeaderCard from '../../../components/process-header-card/ProcessHeaderCard'
import BreadCrumbs from '../../../components/breadcrumb/BreadCrumbs'
import withSupportDoubleClick from '../../../utils/with-support-double-click'
import goalIcon from '../../../assets/icons/active-bullseye.svg' 
import { clearSpecificMetricState } from '../../../redux/specific-metric/specificMetric.action'
import LineChart from '../../../components/chart/line-chart/LineChart'
import BarChart from '../../../components/chart/bar-chart/BarChart'
import PieChart from '../../../components/chart/pie-chart/PieChart'
import DoughnutChart from '../../../components/chart/doughnut-chart/DoughnutChart'
import { clearFetchMetrics } from '../../../redux/fetch-metric/FetchMetricAction'
import useMediaQuery from '@mui/material/useMediaQuery';
import MetricAggregateCard from '../../../components/metric-aggregate-card/MetricAggregateCard'
import GanttChart from '../../../components/chart/gantt-chart/GanttChart'
import ProcessSearch from '../../../components/search/process-search/ProcessSearch'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSearchBar from '../../../components/desktop-layout/process-search-bar/ProcessSearchBar'
import Container from '../../../components/desktop-layout/container/Container'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import SlantContainer from '../../../components/desktop-layout/slant-container/SlantContainer'
import MetricCoverImage from '../../../assets/images/metrics.png'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import CarouselSlider from '../../../common/carousel-slider/CarouselSlider'
import GlassyCard from '../../../common/glassy-card/GlassyCard'
import ProcessContentArea from '../../../components/desktop-layout/process-content-area/ProcessContentArea'
import MainContainer from '../../../components/main-container/MainContainer'

const Metrics = (props) => {

    const { fetchMetricState, 
            fetchMetrics,
            computedGoalProgressState, 
            fetchGoalsProgress, 
            specificMetricState, 
            clearSpecificMetricState,
            clearMetricState } = props

    const [activeIndex, setActiveIndex] = useState([])
    const [pageLoading, setPageLoading] = useState({
        goalProgress : true,
        metricAggregate : true
    })
    const [goalProgressPopup, setGoalProgressPopup] = useState(false)
    const [ drawerOpen,setDrawerOpen ] = useState(false)
    const [ searchPopup, setSearchPopup] = useState(false)
    const [ menu, setMenu ] = useState(false)

    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    const objectives = fetchMetricState.response.objectives

    let history = useHistory()

    function handleAddClickEvent(){
        history.push("/metric/create")
    }

    function handleMetricDetails(graphId){
        history.push(`/metric/${graphId}`)
    }

    function handleNotification(){
        history.push(`/notification-reference?selected=notification&tab=inbox&action=metrics`)
    }

    function toggleGoalProgressPopup(event,reason){
        if(reason && reason === "backdropClick") return
        else setGoalProgressPopup( ps => !ps )
    }

    useEffect(() => {
        if(!fetchMetricState.loading && !isEmpty(fetchMetricState.response)){
            setActiveIndex(Array(fetchMetricState.response.objectives.length).fill(0))
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    metricAggregate : false
                }
            })
        }
        if(!fetchMetricState.loading && !isEmpty(fetchMetricState.error)){
            console.log(fetchMetricState.error)
            if(fetchMetricState.error?.data){
                toast.error(fetchMetricState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

        if(fetchMetricState.action_changes && fetchMetricState.loading){
            fetchMetrics()
        }
    }, [fetchMetricState])

    useEffect(() => {
        if(!computedGoalProgressState.loading && !isEmpty(computedGoalProgressState.response)){
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    goalProgress : false
                }
            })
        }
        if(!computedGoalProgressState.loading && !isEmpty(computedGoalProgressState.error)){
            console.log(computedGoalProgressState.error)
            if(computedGoalProgressState.error.data){
                toast.error(computedGoalProgressState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    }, [computedGoalProgressState])

    useEffect(()=>{
        if(isEmpty(fetchMetricState.response)){
            fetchMetrics()
        }
        if(isEmpty(computedGoalProgressState.response)){
            fetchGoalsProgress()
        }

        if(!isEmpty(specificMetricState.response)){
            clearSpecificMetricState()
        }
        return () => {
            clearMetricState()
        }
    },[])

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick: (e, payload) => {
            history.push(`/metric/${payload.graph._id}/expanded-graph`)
        },
        onSingleClick: (e, index) => {
            let row = index.rowIndex
            let col = index.columnIndex
            setActiveIndex( ps => {
                let psCopy = JSON.parse(JSON.stringify(ps))
                psCopy[row] = col
                return psCopy
            })
        },
    })

    function graphType(graph,graphDetail){
        switch(graph){
            case "Gantt chart":
                return (
                    <div className='me-4'>
                        <GanttChart graphDetail={graphDetail?.graph?.data}/>
                    </div>
                )

            case "Bar chart":
                return (
                    <BarChart graphData={graphDetail.graph.data} />
                )
            
            case "Pie chart": 
                return (
                    <PieChart graphDetail={graphDetail.graph.data} />
                )

            case "Doughnut chart":
                return (
                    <DoughnutChart graphDetail={graphDetail.graph.data} />
                )

            default : 
                return (
                    <LineChart graphData={graphDetail.graph.data}/>
                )
        }
    }

    // const data = {                 //radar chart data
    //     labels : ["Africa", "Asia", "Europe", "Latin America", "North America"],
    //     datasets :  [
    //         {
    //           label: "1950",
    //           fill: true,
    //           backgroundColor: "rgba(179,181,198,0.2)",
    //           borderColor: "rgba(179,181,198,1)",
    //           pointBorderColor: "#fff",
    //           pointBackgroundColor: "rgba(179,181,198,1)",
    //           data: [8.77,55.61,21.69,6.62,6.82]
    //         }, {
    //           label: "2050",
    //           fill: true,
    //           backgroundColor: "rgba(255,99,132,0.2)",
    //           borderColor: "rgba(255,99,132,1)",
    //           pointBorderColor: "#fff",
    //           pointBackgroundColor: "rgba(255,99,132,1)",
    //           pointBorderColor: "#fff",
    //           data: [25.48,54.16,7.61,8.06,4.45]
    //         }
    //       ]
    // }

    // const polarData = {    //polar chart data
    //     labels: [
    //       'Red',
    //       'Green',
    //       'Yellow',
    //       'Grey',
    //       'Blue'
    //     ],
    //     datasets: [{
    //       label: 'My First Dataset',
    //       data: [11, 16, 7, 3, 14],
    //       backgroundColor: [
    //         'rgb(255, 99, 132)',
    //         'rgb(75, 192, 192)',
    //         'rgb(255, 205, 86)',
    //         'rgb(201, 203, 207)',
    //         'rgb(54, 162, 235)'
    //       ]
    //     }]
    // };

    const toggleDrawer = () => {
        setDrawerOpen( ps => !ps )
    };

    function searchPopupOpen(){
        setSearchPopup(true)
    }

    function searchPopupClose(){
        setSearchPopup(false)
    }

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function getAggregateDetail(){
        return (
            !pageLoading.metricAggregate && !pageLoading.goalProgress ?
                <div className="overflow-auto h-100" >
                    {
                        !isEmpty(objectives) ?
                        <div>
                            <div className="mt-4 ml-20 mr-20 ">
                                <div className='d-flex align-items-center'>
                                    <ProgressBar 
                                        className="flex-fill" 
                                        onClick={toggleGoalProgressPopup}
                                    >
                                        <ProgressBar 
                                            className="bg-red" 
                                            now={25} 
                                            key={1} 
                                            label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.redZoneMetrics) ? size(computedGoalProgressState.response.redZoneMetrics) : 0} 
                                        />
                                        <ProgressBar 
                                            className="bg-amber" 
                                            now={25} 
                                            key={2} 
                                            label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.amberZoneMetrics) ? size(computedGoalProgressState.response.amberZoneMetrics) : 0} 
                                        />
                                        <ProgressBar 
                                            className="bg-green" 
                                            now={25} 
                                            key={3} 
                                            label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.greenZoneMetrics) ? size(computedGoalProgressState.response.greenZoneMetrics) : 0} 
                                        />
                                        <ProgressBar 
                                            className="bg-blue" 
                                            now={25} 
                                            key={4} 
                                            label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.blueZoneMetrics) ? size(computedGoalProgressState.response.blueZoneMetrics) : 0} 
                                        />
                                    </ProgressBar>
                                </div>
                            </div>
                            <div className='mt-4'>
                                {
                                    objectives.map((objective, rowIndex) => (
                                        <div key={rowIndex}>
                                            <div className='mb-2 ml-20'>
                                                { 
                                                    !isEmpty(activeIndex) ?
                                                        <>
                                                            <BreadCrumbs
                                                                listClassName="bg-white"
                                                                truncate={isMobile}
                                                                icon={faThLarge}
                                                                activeId={objective.aggregate_graphs[activeIndex[rowIndex]].goal.objective_id._id}
                                                                path={toArray(objective.aggregate_graphs[activeIndex[rowIndex]].goal.objective_id.path)} 
                                                            />
                                                            <div>
                                                                <BreadCrumbs 
                                                                    listClassName="bg-white"

                                                                    img={goalIcon} 
                                                                    activeId={objective.aggregate_graphs[activeIndex[rowIndex]].goal._id}
                                                                    path={[{
                                                                        name : objective.aggregate_graphs[activeIndex[rowIndex]].goal.name, 
                                                                        _id:objective.aggregate_graphs[activeIndex[rowIndex]].goal._id
                                                                    }]}
                                                                />
                                                            </div>
                                                        </> 
                                                        : null
                                                }
                                            </div>
                                            <div className="scroll-container mb-4">
                                                { 
                                                    objective.aggregate_graphs.map((graph, colIndex) => (
                                                        <div 
                                                            key={colIndex} 
                                                            className='metric-container'
                                                        >
                                                            <MetricAggregateCard 
                                                                selected={activeIndex[rowIndex] === colIndex}
                                                                handleClick={(e) => handleCardClick(e,{rowIndex : rowIndex,columnIndex : colIndex,graph : graph})} 
                                                            >
                                                                { 
                                                                    !isEmpty(graph?.graph?.data) ?
                                                                        graphType(graph.graph_type,graph)
                                                                        : 
                                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                                            <p className="mb-0 lato-fs16-normal-lh20">No graph data available</p>
                                                                        </div>
                                                                }
                                                            </MetricAggregateCard>
                                                            <div className="d-flex justify-content-center max-width-250px">
                                                                <div
                                                                    className="mt-1 d-flex justify-content-center align-items-center pt-1 pt-cursor"
                                                                    onClick={() => handleMetricDetails(graph._id)} 
                                                                >
                                                                    <span className="text-center lato-fs16-normal-lh20 pb-0 font-weight-bold me-1 two-line-overflow-hidden">{`${graph.metric.name}`}</span> 
                                                                    <FontAwesomeIcon 
                                                                        className='ms-2'
                                                                        icon={faAngleRight}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>  :  
                        <>
                            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                                <p className="m-0 text-center lato-fs20-normal-lh28">
                                    You don't have any metrics added yet
                                </p>
                                <div className='d-flex justify-content-center mt-3'>
                                    <CustomizeButton
                                        variant="primary-start-icon-button"
                                        startIcon={<AddIcon />}
                                        type="button"
                                        handleClick={() => history.push("/metric/create")}
                                        label="Create Metric"
                                    />
                                </div>
                            </div>
                        </> 
                    }
                </div> 
                : null
        )
    }

    return (
        isDesktop ? 
            <Container>
                <Navbar
                    enableSearchBar={true}
                    searchAutocomplete={<ProcessSearchBar />} 
                    enableNotification={true}
                    enableMore={true}
                    open={open}
                    menu={menu}
                    logo={true}
                    enableLogout={true}
                    enableProfileButton={true}
                    notificationOnClick={handleNotification}
                    handlePopoverClose={handleMenuClose}
                    handlePopoverOpen={handleMenuOpen} />
                <ProcessSectionContainer>
                    <LeftVerticalMenuBar addIcon={true} addIconTooltip="Create Metric" onAddIconClick={handleAddClickEvent} buildingIcon={false} />
                    <ProcessMainContainer>
                        <SlantContainer punchLine="Sense of pulse of how is it going" coverImgSrc={MetricCoverImage} />
                        {
                            !pageLoading.metricAggregate ?
                                !isEmpty(objectives) ?
                                <ProcessContentArea>
                                    {
                                        !pageLoading.goalProgress ? 
                                            <div className='d-flex align-items-center mb-4'>
                                                <ProgressBar className="flex-fill height-1_5rem pt-cursor font-size-16px" onClick={toggleGoalProgressPopup}>
                                                    <ProgressBar className="bg-red" now={25} key={1} label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.redZoneMetrics) ? size(computedGoalProgressState.response.redZoneMetrics) : 0} />
                                                    <ProgressBar className="bg-amber" now={25} key={2} label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.amberZoneMetrics) ? size(computedGoalProgressState.response.amberZoneMetrics) : 0} />
                                                    <ProgressBar className="bg-green" now={25} key={3} label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.greenZoneMetrics) ? size(computedGoalProgressState.response.greenZoneMetrics) : 0} />
                                                    <ProgressBar className="bg-blue" now={25} key={4} label={!isEmpty(computedGoalProgressState.response) && !isEmpty(computedGoalProgressState.response.blueZoneMetrics) ? size(computedGoalProgressState.response.blueZoneMetrics) : 0} />
                                                </ProgressBar>
                                            </div> :
                                            null
                                    }
                                    {
                                        objectives.map((objective, rowIndex) => (
                                            <GlassyCard className={rowIndex === 0 ? "pt-4" : "mt-4 pt-4"}>
                                                <CarouselSlider
                                                    breadcrumbs={ 
                                                        !isEmpty(activeIndex) ?
                                                        <>
                                                            <BreadCrumbs
                                                                truncate={isMobile}
                                                                icon={faThLarge} 
                                                                activeId={objective.aggregate_graphs[activeIndex[rowIndex]].goal.objective_id._id}
                                                                path={toArray(objective.aggregate_graphs[activeIndex[rowIndex]].goal.objective_id.path)} />
                                                            <div>
                                                                <BreadCrumbs 
                                                                    img={goalIcon}
                                                                    activeId={objective.aggregate_graphs[activeIndex[rowIndex]].goal._id}
                                                                    path={[{ name : objective.aggregate_graphs[activeIndex[rowIndex]].goal.name, _id:objective.aggregate_graphs[activeIndex[rowIndex]].goal._id}]} />
                                                            </div>
                                                        </> : null 
                                                    }
                                                    size={size(objective.aggregate_graphs)}>
                                                    { 
                                                        objective.aggregate_graphs.map((graph, colIndex) => (
                                                            <div key={colIndex} className='px-3 pb-4'>
                                                                <MetricAggregateCard
                                                                    variant='desktop'
                                                                    className='pt-cursor'
                                                                    selected={activeIndex[rowIndex] === colIndex}
                                                                    handleClick={(e) => handleCardClick(e,{rowIndex : rowIndex,columnIndex : colIndex,graph : graph})} >
                                                                    { 
                                                                        !isEmpty(graph?.graph?.data) ?
                                                                            graphType(graph.graph_type, graph)
                                                                            : <div className="d-flex justify-content-center align-items-center h-100">
                                                                                <p className="mb-0 lato-fs16-normal-lh20">No graph data available</p>
                                                                            </div>
                                                                    }
                                                                </MetricAggregateCard>
                                                                <div className="d-flex justify-content-center">
                                                                    <div
                                                                        className="mt-1 d-flex justify-content-center align-items-center pt-1 pt-cursor"
                                                                        onClick={() => handleMetricDetails(graph._id)} 
                                                                    >
                                                                        <span className="text-center lato-fs16-normal-lh20 pb-0 font-weight-bold me-1 two-line-overflow-hidden">{`${graph.metric.name}`}</span> 
                                                                        <FontAwesomeIcon 
                                                                            className='ms-2' 
                                                                            icon={faAngleRight}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) 
                                                    }
                                                </CarouselSlider>
                                            </GlassyCard> 
                                        ))
                                    }
                                </ProcessContentArea> :
                                <div className='py-3'>
                                    <p className="m-0 text-center lato-fs20-normal-lh28">
                                        You don't have any metrics added yet
                                    </p>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <CustomizeButton 
                                            variant="primary-start-icon-button" 
                                            startIcon={<AddIcon />} 
                                            type="button" 
                                            handleClick={() => history.push("/metric/create")} 
                                            label="Create Metric"
                                        />
                                    </div>
                                </div> :
                                null
                        }
                    </ProcessMainContainer>
                </ProcessSectionContainer>
                { 
                    goalProgressPopup ? 
                        <GoalProgress handleClose={toggleGoalProgressPopup} open={goalProgressPopup} /> 
                        : null
                }
            </Container>
            : <Container>
                <Navbar
                    variant="mobile"
                    enableSearchIcon={true}
                    onSearchIconClick={searchPopupOpen}
                    enableNotificationsIcon={true}
                    onNotificationsIconClick={handleNotification}
                    enableLogout={true}
                    enableDrawer={true}
                    logo={true}
                    drawerOpened={drawerOpen}
                    handleDrawerClose={toggleDrawer}
                    onDrawerClick={toggleDrawer}
                />
                <MainContainer className="bg-tertiary-color-v2">
                    <ProcessHeaderCard
                        punchLine="Sense of pulse of how is it going"
                        coverImgSrc={MetricCoverImage}
                    />
                    <div className='process-aggregate-height'>
                        {getAggregateDetail()}
                    </div>
                </MainContainer>
                <BottomNavigation
                    enableAddIcon={true}
                    onAddIconClick={handleAddClickEvent}
                />
                {
                    searchPopup ? 
                        <ProcessSearch 
                            handleClose={searchPopupClose} 
                            popupState={searchPopup} 
                            selectedAction="metrics" 
                        />
                        : null
                }
                { 
                    goalProgressPopup ? 
                        <GoalProgress 
                            handleClose={toggleGoalProgressPopup} 
                            open={goalProgressPopup} 
                        /> 
                        : null
                }
            </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        fetchMetricState : state.fetchMetricData,
        computedGoalProgressState : state.computeProgressMetric,
        specificMetricState : state.specificMetric
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMetrics : () => dispatch(fetchMetrics()),
        fetchGoalsProgress : () => dispatch(fetchComputedGoalProgress()),
        clearSpecificMetricState : () => dispatch(clearSpecificMetricState()),
        clearMetricState : () => dispatch(clearFetchMetrics())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Metrics)