import React, { useState,useRef, useEffect } from 'react'
import * as Yup from 'yup'
import { Formik, Field, FieldArray, Form } from 'formik'
import {connect} from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { isEmpty, size } from 'lodash'
import qs from 'query-string'
import { toast } from 'react-toastify'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'

import ObjectiveListPopup from '../../../components/objective-list-popup/ObjectiveListPopup' 
import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import DatePicker from '../../../common/date-picker/DatePicker'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'

import editIcon from '../../../assets/icons/edit_white.svg'
import wallpaperPlaceholder from '../../../assets/images/wallpaper-placeholder.png'
import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import { deepSearch, formatDate } from '../../../utils/utils'

import { clearGoalsStates } from '../../../redux/goals/GoalsAction'
import { goalActionChanges } from '../../../redux/fetch-goals/FetchGoalsAction'
import { specificGoalSuccess } from '../../../redux/specific-goal/specificGoal.action'
import { createGoals, editGoal, goalDateValidation, specificGoal } from '../../../services/goals.service'
import { requestObjectiveForSelect } from '../../../services/videoConference.service'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import UploadFiles from '../../../components/upload-files/UploadFiles'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import MobileHeading from '../../../common/header/Header'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import ImgMenu from '../../../common/img-menu/ImgMenu'
import ProcessWallpaperEdit from '../../../components/process-wallpaper-edit/ProcessWallpaperEdit'
import ProcessIconEdit from '../../../components/process-icon-edit/ProcessIconEdit'

const GOALS_ATTACHMENT_NAMES = {
    data : 'goal_data',
    wallpaper : 'goal_wallpaper',
    icon : 'goal_icon',
    reference : 'goal_references'
}

const CreateOrEditGoals = (props) => {

    const { 
        objectiveForSelectStates,
        createGoal,
        alterGoalStates,
        requestSpecificGoal,
        specificGoalState,
        editGoal,
        requestObjective,
        updateGoals,
        saveUpdatedSpecificGoal,
        clearAlterGoalstates
    } = props

    const history = useHistory()
    const wallpaperImageRef = useRef()
    const iconImageRef = useRef()
    const formRef = useRef()

    const [wallpaperImageFile,setWallpaperImageFile] = useState('')
    const [wallpaperPreviewImage, setWallpaperPreviewImage] = useState("")
    const [iconImageFile, setIconImageFile] = useState("")
    const [iconPreviewImage,setIconPreviewImage] = useState("")
    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [objectivePopup, setobjectivePopup] = useState(false)
    const [isReset, setIsReset] = useState(false)
    const [currentObj, setCurrentObj] = useState({})
    const [ goals,setGoals ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [pageLoading, setPageLoading] = useState({
        objectives : true,
        goals : true
    })
    const [ navbarMoreMenu, setNavbarMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const referenceFileRef = useRef()

    const objectives = objectiveForSelectStates.response.objectives
    const isDesktop = useMediaQuery('(min-width:992px)')

    let location = useLocation()
    const queryParams = qs.parse(location.search)
    const action = location.pathname.split('/')[2]

    const [wallpaperAnchorEl, setWallpaperAnchorEl] = useState(null);
    const wallpaperMenuOpen = Boolean(wallpaperAnchorEl);
    const handleWallpaperEditIconClick = (event) => {
        setWallpaperAnchorEl(event.currentTarget);
    };
    const handleWallpaperMenuClose = () => {
        setWallpaperAnchorEl(null);
    };

    const [iconAnchorEl, setIconAnchorEl] = useState(null);
    const iconMenuOpen = Boolean(iconAnchorEl);
    const handleIconEditIconClick = (event) => {
        setIconAnchorEl(event.currentTarget);
    };
    const handleIconMenuClose = () => {
        setIconAnchorEl(null);
    };

    function getObjById(object, id){
        return new Promise((resolve, reject) => {
            const obj = deepSearch(object, '_id', (k, v) => v === id)
            resolve(obj)
        })
    }

    function updateCurrObj(id){
        getObjById(objectives, id).then(obj => {
            setCurrentObj(obj)
        })
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const navbarOpen = Boolean(navbarMoreMenu);

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget);
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null);
    }

    const emptyLinkObj = {
        originalname : "",
        url : ""
    }

    const initialValues={
        name: action === "edit" && !isEmpty(goals) ? (!isReset ? goals.name : '') : '',
        description: action === "edit" && !isEmpty(goals) ? (!isReset ? goals.description : '') : '',
        start_date: action === "edit" && !isEmpty(goals)  && goals.start_date ? (!isReset ? formatDate(goals.start_date) : '') : '',
        end_date: action === "edit" && !isEmpty(goals) && goals.end_date ? (!isReset ? formatDate(goals.end_date) : '') : '',
        objective_id : action === "edit" && !isEmpty(goals) && !isEmpty(goals.objective_id) ? (!isReset ? goals.objective_id._id : '' ) : '',
        metrics: action === "edit" && !isEmpty(goals) ? (!isReset ? goals.metrics.map(metric => metric.name) :  ['']) : [''],
        reference_links : action === "edit" && !isEmpty(goals) && !isEmpty(goals.reference_links) ? ( !isReset ? getLinks(goals.reference_links) : [emptyLinkObj]) : [emptyLinkObj] 
    }


    const validationSchema = (objective) => {
        return Yup.object({
            name: Yup.string().required("Please enter the goal name"),
            description: Yup.string(),
            start_date: Yup.date().required('Please select the start date')
                        .test("check objective","Please select the objective",(value) => {
                            if(value && isEmpty(objective)){
                                return false
                            }else{
                                return true
                            }
                        })
                        .test("check date range", "Invalid date", (value) => {
                            if(action === "edit"){
                                if(!isEmpty(objective) && value){
                                    const startDate = (moment(value).startOf("day")).toISOString()
                                    return new Promise((resolve, reject) => {
                                        goalDateValidation( "start_date", startDate, objective._id, queryParams.goal_id).then(res => {
                                            resolve(res.data.valid)
                                        }).catch(err => {
                                            console.log(err)
                                            resolve(false)
                                        })
                                    })
                                }else{
                                    return false
                                }
                            }else{
                                if(value && !isEmpty(objective)){
                                    const startDate = (moment(value).startOf("day")).toISOString()
                                    return new Promise((resolve, reject) => {
                                        goalDateValidation( "start_date", startDate, objective._id).then(res => {
                                            resolve(res.data.valid)
                                        }).catch(err => {
                                            console.log(err)
                                            resolve(false)
                                        })
                                    })
                                }else{
                                    return false
                                }
                            }
                        }),
            end_date:Yup.date().required('Please select the end date')
                    .test("check objective","Please select the objective",(value) => {
                        if(value && isEmpty(objective)){
                            return false
                        }else{
                            return true
                        }
                    })
                    .min(Yup.ref('start_date'),"End date can't be before Start date")
                    .test('check for past dates', "End date can't be in past", (value) => {
                        if(value){
                            return (moment(value).endOf("day")).isSameOrAfter(moment())
                        } else {
                            return false
                        }
                    })
                    .test("check date range", "Invalid date", (value) => {
                        if(action === "edit"){
                            if(value && !isEmpty(objective)){
                                const endDate = (moment(value).endOf("day")).toISOString()
                                return new Promise((resolve, reject) => {
                                    goalDateValidation( "end_date", endDate, objective._id, queryParams.goal_id).then(res => {
                                        resolve(res.data.valid)
                                    }).catch(err => {
                                        console.log(err)
                                        resolve(false)
                                    })
                                })
                            }else{
                                return false
                            }
                        }else{
                            if(value && !isEmpty(objective)){
                                const endDate = (moment(value).endOf("day")).toISOString()
                                return new Promise((resolve, reject) => {
                                    goalDateValidation( "end_date", endDate, objective._id).then(res => {
                                        resolve(res.data.valid)
                                    }).catch(err => {
                                        console.log(err)
                                        resolve(false)
                                    })
                                })
                            }else{
                                return false
                            }
                        }
                    }),
            objective_id: Yup.string().required("Please select a objective"),
            metrics: Yup.array().of(
                Yup.string().required("Please enter the metric name")
            ).min(1, 'Choose minimum one metric'),
            reference_links : Yup.array().of(
                Yup.object().shape({
                    originalname : Yup.string(),
                    url : Yup.string().when("originalname", {
                        is: (value) => !isEmpty(value),
                        then: Yup.string().required("Please enter the url").url("Invalid url"),
                        otherwise : Yup.string().url("Invalid url")
                    })
                })
            )
        })
    }

    const onSubmit = (values) =>{
        const valuescopy = JSON.parse(JSON.stringify(values))
        valuescopy.start_date = (moment(values.start_date).startOf('day')).toISOString()
        valuescopy.end_date = (moment(values.end_date).endOf('day')).toISOString()
        valuescopy.reference_links = valuescopy.reference_links.filter(item => item.original_name || item.url)
        if(navigator.onLine){
            setLoading(true)
            if(action === 'edit'){
                if(!wallpaperPreviewImage && specificGoalState?.response?.wallpaper) {
                    valuescopy.wallpaper = ''
                }
                if(!iconPreviewImage && specificGoalState?.response?.icon) {
                    valuescopy.icon = ''
                }
                editGoal(queryParams.goal_id, addFormData(valuescopy))
            } else {
                createGoal(addFormData(valuescopy))
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function addFormData(goalData){

        let goalDataCopy = JSON.parse(JSON.stringify(goalData))
        let goalFormData = new FormData()

        if(!isEmpty(existingReferences)){
            goalDataCopy.reference_files = existingReferences.map(referenceFile => {
                return referenceFile._id
            })
        } else {
            goalDataCopy.reference_files = []
        }

        goalFormData.append(GOALS_ATTACHMENT_NAMES.data, JSON.stringify(goalDataCopy))
        if(wallpaperImageFile){
            goalFormData.append(GOALS_ATTACHMENT_NAMES.wallpaper, wallpaperImageFile)
        }
        if(iconImageFile){
            goalFormData.append(GOALS_ATTACHMENT_NAMES.icon, iconImageFile)
        } 
        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                goalFormData.append(GOALS_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }
        return goalFormData
    }

    function toggleObjectivePopup(){
        setobjectivePopup(!objectivePopup)
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.forEach((link) => (
            contentLink.push({url :link.url, originalname : link.originalname})
        ))
        return contentLink
    }

    useEffect(() => {
        if(!objectiveForSelectStates.loading && !isEmpty(objectiveForSelectStates.response)){
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    objectives : false 
                }
            })
        }
        if(!objectiveForSelectStates.loading && !isEmpty(objectiveForSelectStates.error)){
            console.log(objectiveForSelectStates.error)
            if(!isEmpty(objectiveForSelectStates.error)){
                if(objectiveForSelectStates.error.data){
                    toast.error(objectiveForSelectStates.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }else{
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    }, [objectiveForSelectStates])

    useEffect(() => {
        if(!objectiveForSelectStates.loading && !isEmpty(objectiveForSelectStates.response) && !specificGoalState.loading && !isEmpty(specificGoalState.response) && (action === 'edit')){
            updateCurrObj(specificGoalState.response.objective_id._id)
        }
    }, [objectiveForSelectStates, specificGoalState])

    useEffect(() => {
        if(!specificGoalState.loading && !isEmpty(specificGoalState.response)){
            if(action === "edit"){
                setGoals(specificGoalState.response)
                setExistingReferences(!isEmpty(specificGoalState.response) && !isEmpty(specificGoalState.response.reference_files) ? 
                    specificGoalState.response.reference_files : [])
                setWallpaperPreviewImage(!isEmpty(specificGoalState.response) ? specificGoalState.response.wallpaper :"")
                setIconPreviewImage(!isEmpty(specificGoalState.response) && specificGoalState.response.icon ? specificGoalState.response.icon : "")
                
                setPageLoading(prevState => {
                    return {
                        ...prevState,
                        goals : false
                    }
                })
            }else{
                setPageLoading(prevState => {
                    return {
                        ...prevState,
                        goals : false
                    }
                })
            }
        }
        if(!specificGoalState.loading && !isEmpty(specificGoalState.error)){
            if(!isEmpty(specificGoalState.error)){
                if(!isEmpty(specificGoalState.error.data)){
                    console.log(specificGoalState.error.data.error) 
                    toast.error(specificGoalState.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }else{
                    toast.error("Something went wrong", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    }, [specificGoalState])

    useEffect(() => {
        if(!alterGoalStates.loading && !isEmpty(alterGoalStates.response)){
            setLoading(false)
            updateGoals()
            saveUpdatedSpecificGoal(alterGoalStates.response)
            toast.success( action === 'edit' ? 'Goal updated successfully' : 'Goal created successfully', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            history.goBack()
        }

        if(!alterGoalStates.loading && !isEmpty(alterGoalStates.error)){
            setLoading(false)
            if(!isEmpty(alterGoalStates.error)){
                if(!isEmpty(alterGoalStates.error.data)){
                    toast.error(alterGoalStates.error.response.data.error,{
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    }, [alterGoalStates])

    useEffect(()=>{
        if(action === 'edit'){
            if(isEmpty(specificGoalState.response)){
                requestSpecificGoal(queryParams.goal_id)
            } 
        }
        if(isEmpty(objectiveForSelectStates.response) || objectiveForSelectStates.action_changes){
            requestObjective()
        }
        return () => {
            clearAlterGoalstates()
        }
    },[])

    useEffect(() => {
        if(wallpaperImageFile){
            const reader = new FileReader()
            reader.onloadend =() =>{
                setWallpaperPreviewImage(reader.result)
            }
            reader.readAsDataURL(wallpaperImageFile)
        }
    }, [wallpaperImageFile])
    
    useEffect(() =>{
        if(iconImageFile){
            const reader = new FileReader()
            reader.onloadend = () => {
                setIconPreviewImage(reader.result)
            }
            reader.readAsDataURL(iconImageFile)
        }
    },[iconImageFile])

    function handleCreateObjectiveEvent(){
        history.push("/vcf/create")
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index !== i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index !== i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }

    function getLoadingCondition(){
        if(action === 'edit'){
            return !pageLoading.objectives && !pageLoading.goals
        } else {
            return !pageLoading.objectives
        }
    }

    function handleResetForm(resetForm) {
        setIsReset(true)
        resetForm()
        setReferenceFiles({files : []})
        setWallpaperImageFile('')
        setWallpaperPreviewImage('')
        setIconImageFile('')
        setIconPreviewImage('')
        setExistingReferences([])
    }

    const onClickNotification = () => {
        history.push(`/notification-reference?selected=notification&tab=inbox&action=goals`)
    }

    const onRemoveWallpaperClick = () => {
        if(wallpaperImageFile) setWallpaperImageFile('')
        if(wallpaperPreviewImage) setWallpaperPreviewImage('')
    }

    const onRemoveIconClick = () => {
        if(iconImageFile) setIconImageFile('')
        if(iconPreviewImage) setIconPreviewImage('')
    }

    function getForm(){
        return (
            <>
                <Formik
                    innerRef={formRef}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={validationSchema(currentObj)}
                    onSubmit={onSubmit} >
                    { 
                        props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                resetForm,
                            } = props
                            return (
                                <>
                                    <Form >
                                        <Grid container sx={{ marginTop:"20px"}} spacing={isDesktop ? "" : 2 }>
                                            <Grid item md={6} xs={12} sm={6} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                <InputBox type="text" 
                                                    variant="withOnClick"
                                                    name="objective_id"
                                                    label="VCF"
                                                    placeholder="Select a VCF"
                                                    onClick={toggleObjectivePopup}
                                                    value={ !isEmpty(currentObj) && !isEmpty(values.objective_id) ? currentObj.name :""}
                                                    errorMgs={errors.objective_id && touched.objective_id}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12} sm={6} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                <InputBox type="text" 
                                                    name="name"
                                                    label="Name"
                                                    placeholder="Enter the goal name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.name && errors.name}
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className="mt-3">
                                            <TextAreaBox name="description"
                                                label="Description"
                                                placeholder="Enter the goal description"
                                                value={values.description}
                                                rows={ isDesktop ? 4 : ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errormgs={touched.description && errors.description}
                                            />
                                        </div>
                                        <Grid container sx={{marginTop: isDesktop ? "17px" : "5px"}} spacing={ isDesktop ? "" : 2 }>
                                            <Grid item md={6} xs={12} sm={6} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                <DatePicker type="date" 
                                                    name="start_date"
                                                    label="Start Date"
                                                    min={ !isEmpty(currentObj) ? formatDate(currentObj.start_date) : ""}
                                                    max={!isEmpty(currentObj) ? formatDate(currentObj.end_date) : ""}
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.start_date && errors.start_date}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12} sm={6} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                <DatePicker type="date" 
                                                    name="end_date"
                                                    label="End Date"
                                                    min={moment().format("YYYY-MM-DD")}
                                                    max={!isEmpty(currentObj) ? formatDate(currentObj.end_date) : ""}
                                                    value={values.end_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.end_date && errors.end_date}
                                                />
                                            </Grid>
                                        </Grid>  
                                        <div className="mt-3"> 
                                            <FieldArray name="metrics" >
                                                {fieldArrayProps => {
                                                    const { push, remove, form } = fieldArrayProps
                                                    const { values } = form
                                                    const { metrics } = values
                                                    return (
                                                        <div>
                                                            <Grid container spacing={2}>
                                                                {metrics.map((metric, index) => ( 
                                                                    <>
                                                                        <Grid item md={ size(metrics) > 1 ? 5 : 6} xs={ size(metrics) > 1 ? 11 : 12 } sx={{paddingRight: {xs:0, md: size(metrics) > 1 ? "0px" : "10px"}, paddingLeft : {xs:0, md: size(metrics) > 1 ? "10px" : "0px"}}} >
                                                                            <Field name={`metrics[${index}]`}>
                                                                                {props => {
                                                                                    const {field,meta} = props
                                                                                    return (
                                                                                        <>
                                                                                            <InputBox type="text"
                                                                                                label="Metric" 
                                                                                                placeholder="Enter the metric name"
                                                                                                {...field}
                                                                                                errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                            />
                                                                                        </>
                                                                                    )
                                                                                }}
                                                                            </Field>
                                                                        </Grid>
                                                                        { 
                                                                            size(metrics) > 1 ?
                                                                            <Grid item md={1} xs={1} >
                                                                                <div className='d-flex align-items-center mt-1 justify-content-center'>
                                                                                    <IconButton onClick={() => remove(index)}>
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            </Grid> : null
                                                                        }
                                                                    </>
                                                                ))}
                                                            </Grid>
                                                                <div className='mt-2'>
                                                                    <CustomizeButton variant="secondary-start-icon-button" label="Add Metric" StartIcon={<AddIcon/>} handleClick={() => push('')} />
                                                                </div>
                                                        </div>
                                                    )
                                                }}
                                            </FieldArray>
                                        </div>
                                        <div className="mt-3">
                                        <FieldArray name="reference_links" >
                                            {fieldArrayProps => {
                                                const { push, remove, form } = fieldArrayProps
                                                const { values } = form 
                                                const { reference_links } = values
                                                return (
                                                    <>
                                                        {reference_links.map((file,index) => (
                                                            <>
                                                                <Grid container sx={{marginTop:"10px"}} spacing={ isDesktop ? "" : 2 }>
                                                                    <Grid item md={4} xs={12} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].originalname`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <>
                                                                                        <InputBox type="text"
                                                                                            label="Link Name"
                                                                                            placeholder="Enter the link name"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item md={ size(reference_links) > 1 ? 7 : 8} xs={ size(reference_links) ? 11 : 12 } sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].url`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <div>
                                                                                        <InputBox type="url"
                                                                                            label="Link Url"
                                                                                            placeholder="http://www.example.com"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid> 
                                                                    {
                                                                        size(reference_links) > 1 ?
                                                                            <Grid item md={1} xs={1} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                                <div className=" d-flex justify-content-center mt-1">
                                                                                    <IconButton onClick={() => remove(index)}>
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            </Grid> : null
                                                                    }
                                                                </Grid>
                                                            </>
                                                        ))}
                                                        <div className='mt-2'>
                                                            <CustomizeButton variant="secondary-start-icon-button" label="Add Link" StartIcon={<AddIcon/>} handleClick={() => push(emptyLinkObj)} />
                                                        </div>
                                                    </>
                                                )
                                                }}
                                            </FieldArray>
                                        </div>
                                        <div>
                                            <div className='mt-4'>
                                                { 
                                                    !isEmpty(existingReferences) ? 
                                                        existingReferences.map((reference, index) => ( 
                                                            <div key={index} className="d-flex mb-2">
                                                                <a href={reference.url} className="filename one-line-wrap lato-fs15-normal-lh20" target="_blank" > {reference.originalname}</a>
                                                                <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                    onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                            </div>
                                                        )) 
                                                        : null  
                                                }
                                                { 
                                                    !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? 
                                                        referenceFiles.files.map((file,index)=>(
                                                            <div key={index} className="mb-2 d-flex">
                                                                <p className="mb-0 one-line-wrap lato-fs15-normal-lh20">{file.name}</p>
                                                                <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                    onClick={() => handleDeleteReferenceFile(index,"reference-file",file?.name)} icon={faTrash} />
                                                            </div>
                                                        )) 
                                                        : null
                                                }
                                            </div>
                                            <div className='mt-2'>
                                                <UploadFiles label="Click to upload attachment" 
                                                    variant={ isDesktop ? "" : "mobile-view" }
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        referenceFileRef.current.click();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <input type="file"
                                            style={{display:"none"}} 
                                            ref={referenceFileRef} 
                                            multiple
                                            accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx"
                                            name="objective_icon"
                                            onClick={(event) => {
                                                event.target.value = null
                                            }}
                                            onChange={(event) =>{
                                                const file = event.target.files
                                                if(file){
                                                    handleReferenceFileAddition(file)
                                                }
                                        }} />
                                        <div className="d-flex justify-content-center align-center mt-4">
                                            <CustomizeButton variant="secondary-color-button" 
                                                label="Clear All"
                                                className={isDesktop ? "me-5" : "me-4"}
                                                type="reset" 
                                                handleClick={() => handleResetForm(resetForm)} />
                                            <CustomizeLoadingButton type="submit" variant="accept-loading-button-v2"
                                                loadingState={loading}
                                                label={ action === 'edit' ? 'Update Goal' : 'Create Goal' } />
                                        </div>
                                        {
                                            objectivePopup ? 
                                                <ObjectiveListPopup 
                                                    handleClose={toggleObjectivePopup}
                                                    open={objectivePopup}
                                                    onBlur={handleBlur}
                                                    onChange={(event) => { 
                                                        handleChange(event)
                                                        updateCurrObj(event.target.value)
                                                        toggleObjectivePopup()
                                                    }}
                                                    options={objectives}
                                                    value={values.objective_id}
                                                    onClickCreateObjective={handleCreateObjectiveEvent}
                                                /> 
                                                : null
                                        }
                                    </Form>
                                </>
                            )
                        }
                    }
                </Formik>
            </>
        )
    }

    return (
        <>
            <Container>
                {
                    isDesktop ? 
                        <>
                            <Navbar enableMore={true}
                                enableLogout={true}
                                enableProfileButton={true}
                                moreRight="202px" 
                                open={navbarOpen}
                                menu={navbarMoreMenu} 
                                handlePopoverClose={handleNavbarMoreMenuClose}
                                handlePopoverOpen={handleNavbarMoreMenuOpen}
                                enableNotification={true}
                                notificationOnClick={onClickNotification}
                            />
                            <ProcessSectionContainer>
                                <LeftVerticalMenuBar />
                                <ProcessMainContainer className="white-bg">
                                    <SubMainContainer className="mb-40">
                                        { getLoadingCondition() ?
                                            <>
                                                <div className='mt-3'>
                                                    <h5 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>{ action === "edit" ? "Update Goal" :"Create Goal"}</h5>
                                                </div>
                                                <div className='mt-4'>
                                                    <ProcessWallpaperEdit
                                                        ref={wallpaperImageRef}
                                                        onRemoveClick={() => {
                                                            onRemoveWallpaperClick()
                                                            handleWallpaperMenuClose()
                                                        }}
                                                        onUploadClick={(event) => {
                                                            event.preventDefault();
                                                            wallpaperImageRef.current.click();
                                                            handleWallpaperMenuClose()
                                                        }}
                                                        handleMenuClose={handleWallpaperMenuClose}
                                                        menuOpen={wallpaperMenuOpen}
                                                        anchorEl={wallpaperAnchorEl}
                                                        previewImage={wallpaperPreviewImage}
                                                        handleEditIconClick={handleWallpaperEditIconClick}
                                                        onInputChange={(event) =>{
                                                            const file = event.target.files[0];
                                                            if(file && file.type.substr(0,5) === "image"){
                                                                setWallpaperImageFile(file)
                                                            }
                                                        }}
                                                    />
                                                    <ProcessIconEdit 
                                                        ref={iconImageRef}
                                                        onRemoveClick={() => {
                                                            onRemoveIconClick()
                                                            handleIconMenuClose()
                                                        }}
                                                        onUploadClick={(event) => {
                                                            event.preventDefault();
                                                            iconImageRef.current.click();
                                                            handleIconMenuClose()
                                                        }}
                                                        handleMenuClose={handleIconMenuClose}
                                                        menuOpen={iconMenuOpen}
                                                        anchorEl={iconAnchorEl}
                                                        previewImage={iconPreviewImage}
                                                        handleEditIconClick={handleIconEditIconClick}
                                                        onInputChange={(event) =>{
                                                            const file = event.target.files[0];
                                                            if(file && file.type.substr(0,5) === "image"){
                                                                setIconImageFile(file)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className='mt-3'>
                                                    {getForm()}
                                                </div>
                                            </>
                                        : null}
                                    </SubMainContainer>
                                </ProcessMainContainer>
                            </ProcessSectionContainer>
                        </>
                        : 
                            <>
                                <Navbar 
                                    variant="mobile" 
                                    enableLogout={true}
                                    enableDrawer={true}
                                    drawerOpened={drawer}
                                    handleDrawerClose={toggleDrawer}
                                    onDrawerClick={toggleDrawer}
                                    enableNotificationsIcon={true}
                                    onNotificationsIconClick={onClickNotification}
                                />
                                <div className='container' style={{paddingBottom:"50px"}}>
                                    <div className='mt-3 px-3 mb-3'>
                                        <MobileHeading heading={ action === "edit" ? "Update Goal" : "Create Goal" } handleBackArrowEvent={handleBackArrowEvent} />
                                    </div>
                                    <div className="px-3 pb-4" >
                                        { 
                                            getLoadingCondition() ? 
                                            <>
                                                <ProcessWallpaperEdit
                                                    ref={wallpaperImageRef}
                                                    onRemoveClick={() => {
                                                        onRemoveWallpaperClick()
                                                        handleWallpaperMenuClose()
                                                    }}
                                                    onUploadClick={(event) => {
                                                        event.preventDefault();
                                                        wallpaperImageRef.current.click();
                                                        handleWallpaperMenuClose()
                                                    }}
                                                    handleMenuClose={handleWallpaperMenuClose}
                                                    menuOpen={wallpaperMenuOpen}
                                                    anchorEl={wallpaperAnchorEl}
                                                    previewImage={wallpaperPreviewImage}
                                                    handleEditIconClick={handleWallpaperEditIconClick}
                                                    onInputChange={(event) =>{
                                                        const file = event.target.files[0];
                                                        if(file && file.type.substr(0,5) === "image"){
                                                            setWallpaperImageFile(file)
                                                        }
                                                    }}
                                                />
                                                <ProcessIconEdit 
                                                    ref={iconImageRef}
                                                    onRemoveClick={() => {
                                                        onRemoveIconClick()
                                                        handleIconMenuClose()
                                                    }}
                                                    onUploadClick={(event) => {
                                                        event.preventDefault();
                                                        iconImageRef.current.click();
                                                        handleIconMenuClose()
                                                    }}
                                                    handleMenuClose={handleIconMenuClose}
                                                    menuOpen={iconMenuOpen}
                                                    anchorEl={iconAnchorEl}
                                                    previewImage={iconPreviewImage}
                                                    handleEditIconClick={handleIconEditIconClick}
                                                    onInputChange={(event) =>{
                                                        const file = event.target.files[0];
                                                        if(file && file.type.substr(0,5) === "image"){
                                                            setIconImageFile(file)
                                                        }
                                                    }}
                                                />
                                                {getForm()}
                                            </> 
                                            : null
                                        }
                                        </div>
                                </div>
                                <BottomNavigation />
                            </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        objectiveForSelectStates : state.objectiveForSelect,
        alterGoalStates : state.goalCUD,
        specificGoalState : state.specificGoal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjective : () => dispatch(requestObjectiveForSelect()),
        createGoal: (goalsDetails) => dispatch(createGoals(goalsDetails)),
        clearAlterGoalstates : () => dispatch(clearGoalsStates()),
        editGoal : (goalId, goalDetails) => dispatch(editGoal(goalId, goalDetails)),
        requestSpecificGoal : (goalId) => dispatch(specificGoal(goalId)),
        updateGoals : () => dispatch(goalActionChanges()),
        saveUpdatedSpecificGoal : (updatedGoal) => dispatch(specificGoalSuccess(updatedGoal))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditGoals)