import { requestCULearning, 
         CULearningSuccess,
         CULearningFailure } from '../redux/learning/create-edit-learning/CULearingAction'
import { requestDeleteLearningArea,
         deleteLearningAreaSuccess,
         deleteLearningAreaFailure } from '../redux/learning/delete-learning-Area/DeleteLearningAreaAction'
import { fetchLearningFailure, fetchLearningSuccess, requestFetchLearning } from '../redux/learning/fetch-learning/FetchLearningAction'
import { requestAddSegment, addSegmentSuccess, addSegmentFailure } from '../redux/learning/add-segment-in-learning-area/AddSegment.action'
import { requestDeleteSegment, deleteSegmentSuccess, deleteSegmentFailure } from '../redux/learning/delete-segment-in-learning-area/DeleteSegment.action'
import { requestCuCourse, cuCourseSuccess, cuCourseFailure } from '../redux/learning/create-edit-course/CreateEditCourseAction'
import { listOfCoursesSuccess, requestListOfCourses, listOfCoursesFailure } from '../redux/learning/list-courses/ListCoursesAction'
import { requestDeleteCourse, deleteCourseSuccess, deleteCourseFailure } from '../redux/learning/delete-courses/DeleteCourseAction'
import { fetchCourseContentStatus } from './constants.service'
import { courseContentStatusSuccess, requestCourseContentStatus, courseContentStatusFailure } from '../redux/learning/course/CourseAction'
import ProtectedWebClient from '../utils/protected-web-client'
import { coursePerformanceSuccess, requestCoursePerformance, coursePerformanceFailure } from '../redux/learning/course-performance/CoursePerformanceAction'
import { requestSpecificLearningArea, 
         specificLearningAreaFailure, 
         specificLearningAreaSuccess } from '../redux/learning/specific-learning-area/specificLearningArea.action'
import { developmentAreaFailure, 
        developmentAreaSuccess, 
        requestDevelopmentArea } from '../redux/video-conference/development/Development.action'
import { 
    requestModifyLearningArea,
    modifyLearningAreaSuccess, 
    modifyLearningAreafailure 
} from '../redux/notification/modify-learning-area/ModifyLearningArea.action'

export const createLearningArea = (learningDetails,circleId,segment) => {
    return async (dispatch) => {
        try {
            dispatch(requestCULearning)
            const response = await ProtectedWebClient.post(`/learning-area/${circleId}/create?segment=${segment}`, learningDetails )
            dispatch(CULearningSuccess(response.data))
        }catch(error){
            dispatch(CULearningFailure(error.response))
        }
    }
}

export const fetchLearningCircleLearningAreas = () => {
    return async (dispatch) => {
        try {
            dispatch(requestFetchLearning())
            const response = await ProtectedWebClient.get("/learning-circle")
            dispatch(fetchLearningSuccess(response.data))
        }catch(error){
            dispatch(fetchLearningFailure(error.response))
        }
    }
}


export function fetchLearningAreaCourses(){
    return async (dispatch) => {
        try {
            dispatch(requestListOfCourses())
            const learingAreaCourses = await ProtectedWebClient.get("/learning-area/read")
            dispatch(listOfCoursesSuccess(learingAreaCourses.data))
        } catch (error) {
            dispatch(listOfCoursesFailure(error.response))
        }
    }
}

export function fetchCoursesPerformance(){
    return async (dispatch) => {
        try {
            dispatch(requestCoursePerformance())
            const coursesPerformance = await ProtectedWebClient.get("/course/performance-read")
            dispatch(coursePerformanceSuccess(coursesPerformance.data))
        } catch (error) {
            dispatch(coursePerformanceFailure(error.response))
        }
    }
}

export const updateLearningArea = (learningAreaDetails,learningAreaId) => {
    return async (dispatch) =>{
        try{
            dispatch(requestCULearning())
            const response =  await ProtectedWebClient.put(`learning-area/${learningAreaId}/edit`,learningAreaDetails)
            dispatch(CULearningSuccess(response.data))
        }catch(error){
            dispatch(CULearningFailure(error.response))
        }
    }
}

export const deleteLearningArea = (learningAreaDetailId) => {
    return async (dispatch) => {
        try{
            dispatch(requestDeleteLearningArea())
            const response = await ProtectedWebClient.delete(`learning-area/${learningAreaDetailId}/delete`)
            dispatch(deleteLearningAreaSuccess(response.data))
        }catch(error){
            dispatch(deleteLearningAreaFailure(error.response))
        }
    }
}

export const addSegmentInLearningArea = (learningAreaId) => {
    return async (dispatch) => {
        try{
            dispatch(requestAddSegment())
            const response = await ProtectedWebClient.post(`learning-circle/${learningAreaId}/add-segment`, {})
            dispatch(addSegmentSuccess(response.data))
        }catch(error){
            dispatch(addSegmentFailure(error.response))
        }
    }
}

export const deleteSegmentInLearningArea = (learningAreaId) => {
    return async (dispatch) => {
        try{
            dispatch(requestDeleteSegment())
            const response = await ProtectedWebClient.delete(`learning-circle/${learningAreaId}/delete-segment`)
            dispatch(deleteSegmentSuccess(response.data))
        }catch(error){
            dispatch(deleteSegmentFailure(error.response))
        }
    }
}

export const createCourse = (courseDetails,segmentId) =>{
    return async (dispatch) => {
        try {
            dispatch(requestCuCourse())
            const response = await ProtectedWebClient.post(`course/${segmentId}/create`,courseDetails)
            dispatch(cuCourseSuccess(response.data))
        }catch(error) {
            dispatch(cuCourseFailure(error.response))
        }
    }
}

export const deleteCourse = (courseId) => {
    return async (dispatch) => {
        try{
            dispatch(requestDeleteCourse())
            const response = await ProtectedWebClient.delete(`/course/${courseId}/delete`)
            dispatch(deleteCourseSuccess(response.data))
        }catch(error){
            dispatch(deleteCourseFailure(error.response))
        }
    }
}

export const updateCourse = (courseDetails,courseId) => {
    return async (dispatch) => {
        try{
            dispatch(requestCuCourse())
            const response = await ProtectedWebClient.put(`/course/${courseId}/edit`,courseDetails)
            dispatch(cuCourseSuccess(response.data))
        }catch(error){
            dispatch(cuCourseFailure(error.response))
        }
    }
}

function readCourse(courseId) {
    return new Promise ((resolve,reject) => {
        ProtectedWebClient.get(`/course/${courseId}/read`).then((response)=>{
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchCourse = (courseId) => {
    return async (dispatch) => {
        try{
            dispatch(requestCourseContentStatus())
            const response = await ProtectedWebClient.get(`/course/${courseId}/read`)
            dispatch(courseContentStatusSuccess(response.data))
        }catch(error){
            dispatch(courseContentStatusFailure(error.response))
        }
    }
}

export const readCourseAndCourseContentStatus = (courseId) => {
    return async (dispatch) => {
        try {
            dispatch(requestCourseContentStatus())
            const [courseRes, courseContentStatusRes] = await Promise.all([readCourse(courseId),fetchCourseContentStatus()])
            dispatch(courseContentStatusSuccess({ course : courseRes.data.course,
                                                  courseContentStatus : courseContentStatusRes.data.course_content_statuses}))
        }catch(error){
            dispatch(courseContentStatusFailure(error.response))
        }
    }
}

export const specificLearningArea = (learningAreaId, isModifyLearningArea = false) => {
    return async (dispatch) => {
        try {
            if(isModifyLearningArea){
                dispatch(requestModifyLearningArea())
            } else {
                dispatch(requestSpecificLearningArea())
            }
            const response = await ProtectedWebClient.get(`/learning-area/${learningAreaId}/read`)
            if(isModifyLearningArea){
                dispatch(modifyLearningAreaSuccess(response.data))
            } else {
                dispatch(specificLearningAreaSuccess(response.data))
            }
        } catch(error) {
            if(isModifyLearningArea){
                dispatch(modifyLearningAreafailure(error.response))
            } else {
                dispatch(specificLearningAreaFailure(error.response))
            }
        }
    }
}

export const requestNotificationSelectForDevelopment = () => {
    return async (dispatch) => {
        try{
            dispatch(requestDevelopmentArea())
            const response = await ProtectedWebClient.get(`/learning-circle?option=dev-select`)
            dispatch(developmentAreaSuccess(response.data))
        }catch(error){
            dispatch(developmentAreaFailure(error.response))
        }
    }
}