import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { isEmpty, size } from 'lodash'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import qs from 'query-string'
import useMediaQuery from '@mui/material/useMediaQuery'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'
import { deleteLearningArea, specificLearningArea } from '../../../services/learning.service'
import { fetchLearningSuccess } from '../../../redux/learning/fetch-learning/FetchLearningAction'
import { clearDeleteLearningArea } from '../../../redux/learning/delete-learning-Area/DeleteLearningAreaAction'
import './learning-area-details.css'
import DeletePopUp from '../../../components/delete-popup/DeletePopUp'
import CoursePanel from '../../../components/coursePanel/CoursePanel'
import Container from '../../../components/desktop-layout/container/Container'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import Description from '../../../components/desktop-layout/description/Description'
import CarouselSlider from '../../../common/carousel-slider/CarouselSlider'
import Header from '../../../common/header/Header'

const LearningAreaDetails = (props) => {

    const { 
        deleteLearningArea,
        deleteLearningAreaState,
        saveLearningArea,
        clearDeleteLearningAreaState,
        specificLearningAreaState,
        requestSpecificLearningArea
    } = props
    

    const params = useParams()
    const history = useHistory()
    const location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const [pageLoading, setPageLoading] = useState(true)
    const [deletePopup, setDeletePopup] = useState(false)
    const [ menu, setMenu ] = useState(null)
    const [navbarMoreMenu,setNavbarMoreMenu] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const learningArea = specificLearningAreaState.response.learning_area

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    const open = Boolean(menu)

    const handleClick = (event) => {
        setMenu(event.currentTarget)
    }

    const handleClose = () => {
        setMenu(null)
    }

    function handleEditTrigger(){
        history.push(`/learning/edit?circleId=${params.learningCircleId}&segmentId=${params.segmentId}&segment=${params.segment}&segment_name=${learningArea.parent_circle.name}`)
    }

    function toggleDeletePopup(){
        setDeletePopup(!deletePopup)
    }

    function handleDeleteTrigger(){
        if(!isEmpty(learningArea.courses)){
            toggleDeletePopup()
        }else{
            deleteLearningArea(params.segmentId)
        }
    }

    function handleCreateCourse(){
        history.push(`/learning/learning-area/${params.segmentId}/course/create`)
    }

    function handleCardClick(courseId) {
        history.push(`/learning/learning-area/course/${courseId}`)
    }

    useEffect(() => {
        if(!deleteLearningAreaState.loading && !isEmpty(deleteLearningAreaState.response)){
            saveLearningArea(deleteLearningAreaState.response)
            toast.success('Learning area deleted successfully',{
                position:"bottom-right",
                autoClose:3000,
                hideProgressBar:false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            })
            history.goBack()
        }

        if(!deleteLearningAreaState.loading && !isEmpty(deleteLearningAreaState.error)){
            console.log(deleteLearningAreaState.error)
            if(deleteLearningAreaState.error?.data){
                toast.error(deleteLearningAreaState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

    },[deleteLearningAreaState])

    useEffect(() => {
        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.response)){
            // console.log(specificLearningAreaState.response)
            setPageLoading(false)
        }

        if(!specificLearningAreaState.loading && !isEmpty(specificLearningAreaState.error)){
            console.log(specificLearningAreaState.error)
            setPageLoading(false)
            if(specificLearningAreaState.error?.data){
                toast.error(specificLearningAreaState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[specificLearningAreaState])

    useEffect(() => {

        if(isEmpty(specificLearningAreaState.response)){
            requestSpecificLearningArea(params.segmentId)
        }

        return () => {
            clearDeleteLearningAreaState()
        }

    },[])

    const navbarOpen = Boolean(navbarMoreMenu)

    const handleNavbarMoreMenuOpen = (event) => {
        setNavbarMoreMenu(event.currentTarget)
    }

    const handleNavbarMoreMenuClose = () => {
        setNavbarMoreMenu(null)
    }

    function handleNotification(){
        history.push(`/notification-reference?selected=notification&tab=inbox&action=learnings`)
    }
    
    return (
        <Container>
            {
                isDesktop ? 
                    <>
                        <Navbar enableMore={true}
                            enableLogout={true}
                            enableProfileButton={true} 
                            open={navbarOpen}
                            menu={navbarMoreMenu} 
                            handlePopoverClose={handleNavbarMoreMenuClose}
                            handlePopoverOpen={handleNavbarMoreMenuOpen} 
                            enableNotification={true}
                            notificationOnClick={handleNotification}
                        />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar addIcon={true} addIconTooltip="Create Course" onAddIconClick={handleCreateCourse}  />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {  
                                        !pageLoading && !isEmpty(learningArea)?
                                            <> 
                                                <div className='mt-3 d-flex align-items-center'>
                                                    <h2 className='lato-fs22-normal-lh32 font-bold mb-0'>{`${learningArea.parent_circle?.name}${learningArea.segment_no}`}</h2>
                                                    <div className='ms-auto'>
                                                        <IconButton onClick={handleClick}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={menu}
                                                            open={open}
                                                            onClose={handleClose}
                                                            onClick={handleClose}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: 'visible',
                                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                    mt: 1.5,
                                                                    '& .MuiAvatar-root': {
                                                                        width: 32,
                                                                        height: 32,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                    },
                                                                    '&:before': {
                                                                        content: '""',
                                                                        display: 'block',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 14,
                                                                        width: 10,
                                                                        height: 10,
                                                                        bgcolor: 'background.paper',
                                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                                        zIndex: 0,
                                                                    },
                                                                },
                                                            }}
                                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                        >
                                                            <MenuItem sx={{fontSize:"16px", fontFamily:"'Lato',sans-serif", color:"var(--primary-text-color-v2)"}} onClick={handleEditTrigger}>
                                                                <FontAwesomeIcon icon={faPen} className="me-2 secondary-color" />Edit
                                                            </MenuItem>
                                                            <MenuItem sx={{fontSize:"16px", fontFamily:"'Lato',sans-serif", color:"var(--primary-text-color-v2)"}} onClick={handleDeleteTrigger}> 
                                                                <FontAwesomeIcon icon={faTrash} className="me-2 secondary-color" />Delete 
                                                            </MenuItem>
                                                        </Menu>
                                                    </div>
                                                </div>
                                                <div className='mt-3 d-flex align-items-center'>
                                                    <div className='icon-preview-container ms-0 mt-0 me-0'>
                                                        <img className='icon-preview-image' src={ !isEmpty(learningArea) && learningArea.icon? learningArea.icon : iconPlaceholder} alt="img"></img>
                                                    </div>
                                                    <div className='ms-3'>
                                                        <h1 className='lato-fs22-normal-lh32 font-bold mb-0'>{!isEmpty(learningArea) && learningArea.name ? learningArea.name : ""}</h1>
                                                    </div>
                                                </div>
                                                {
                                                    !isEmpty(learningArea) ? 
                                                        <div className='mt-4'>
                                                            <Description description={learningArea.description} />
                                                        </div> 
                                                        : null
                                                }
                                                {
                                                    !isEmpty(learningArea.courses) ?
                                                        <div className='mt-4 bg-tertiary-color-v2 py-4 px-2' style={{borderRadius: '1rem'}}>
                                                            <CarouselSlider size={size(learningArea.courses)} variant="3-slide">
                                                                { 
                                                                    learningArea.courses.map((course, index) => (
                                                                        <div className='px-2 py-1' key={course._id}>
                                                                            <CoursePanel
                                                                                index={index}
                                                                                onCardClick={() => handleCardClick(course._id)}
                                                                                course={course}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                }
                                                            </CarouselSlider>
                                                        </div>
                                                        : null
                                                }
                                            </>  
                                            : null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </>
                    : <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}
                            enableNotificationsIcon={true}
                            onNotificationsIconClick={handleNotification}
                        />
                        <div className="container">
                            {!pageLoading ? 
                                <>
                                    <div className="mt-3 mb-3 px-3">
                                        <Header heading={`${learningArea.parent_circle?.name}${learningArea.segment_no}`}
                                            handleBackArrowEvent={handleBackArrowEvent}
                                            moreIcon={true}
                                            moreIconEvent={handleClick}
                                            handleClose={handleClose}
                                            open={open}
                                            menu={menu}
                                            handleEditEvent={handleEditTrigger}
                                            handleDeleteEvent={toggleDeletePopup}
                                        />
                                    </div>
                                    <div className="pb-70" >
                                        <div className="px-3">
                                            <div className="icon-preview-container ms-0 mt-0">
                                                <img className="icon-preview-image" alt="" src={ !isEmpty(learningArea) && learningArea.icon ? learningArea.icon : iconPlaceholder} />
                                            </div> 
                                            <div className="mt-2">
                                                <h3 className="lato-fs22-normal-lh32 primary-text-color font-bold mb-0">{!isEmpty(learningArea) && learningArea.name ? learningArea.name :"" }</h3>
                                            </div>
                                            <div className='mt-3'>
                                                <Description description={learningArea.description} />
                                            </div>
                                        </div>
                                            {   !isEmpty(learningArea.courses) ?
                                                <>
                                                    <div className="scroll-container mt-4 bg-tertiary-color-v2 py-4" style={{borderRadius:"1rem"}}>
                                                        { 
                                                            learningArea.courses.map((course, index) => (
                                                                <div className='course-container' key={course._id} style={{minWidth:"200px", maxWidth: '250px'}}>
                                                                    <CoursePanel
                                                                        variant="mobile"
                                                                        index={index}
                                                                        onCardClick={() => handleCardClick(course._id)}
                                                                        course={course}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </> : null
                                            }
                                        </div>
                                </> : null
                            }
                        </div>
                        <BottomNavigation enableAddIcon={true} onAddIconClick={handleCreateCourse} /> 
                    </>
            }
            { 
                deletePopup ? 
                    <DeletePopUp  
                        handleClose={toggleDeletePopup} 
                        open={deletePopup} 
                        onClick={() => deleteLearningArea(params.segmentId)} 
                        msg={"If you delete the selected learning area, all it's binded courses will get deleted. Are you sure?"} 
                    /> 
                    : null
            }
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        deleteLearningAreaState : state.deleteLearningArea,
        specificLearningAreaState : state.specificLearningArea
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteLearningArea : (learningId) => dispatch(deleteLearningArea(learningId)),
        saveLearningArea : (learningAreaDetails) => dispatch(fetchLearningSuccess(learningAreaDetails)),
        clearDeleteLearningAreaState : () => dispatch(clearDeleteLearningArea()),
        requestSpecificLearningArea : (learningAreaId) => dispatch(specificLearningArea(learningAreaId))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LearningAreaDetails)