import { REQUEST_INBOX_ACTION_FETCH ,
         FETCH_INBOX_ACTION_FAILURE, 
         FETCH_INBOX_ACTION_SUCCESS,
         CLEAR_FETCH_INBOX_ACTION_STATES } from './inbox.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const fetchInboxActionReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_INBOX_ACTION_FETCH :
            return {
                ...state,
                loading : true,
                error : null
            }

        case FETCH_INBOX_ACTION_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }
        
        case FETCH_INBOX_ACTION_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }
        
        case CLEAR_FETCH_INBOX_ACTION_STATES : 
            return {
                ...state,
                loading : false,
                response: {},
                error : null
            }

        default : 
            return state
    }

}

export default fetchInboxActionReducer