import {
    CLEAR_UPDATE_PROFILE_STATES, 
    REQUEST_UPDATE_PROFILE_DATA,
    UPDATE_PROFILE_DATA_SUCCESS,
    UPDATE_PROFILE_DATA_FAILURE,
} from './updateProfile.types'

export const requestProfileDataUpdate = () => {
    return {
        type : REQUEST_UPDATE_PROFILE_DATA
    }
}

export const updateProfileDataSuccess = (resData) => {
    return {
        type : UPDATE_PROFILE_DATA_SUCCESS,
        payload : resData
    }
}

export const updateProfileDataFailure = (error) => {
    return {
        type : UPDATE_PROFILE_DATA_FAILURE,
        payload : error
    }
}

export const clearUpdateProfileDataStates = () => {
    return{
        type : CLEAR_UPDATE_PROFILE_STATES
    }
}