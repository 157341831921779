import { REQUEST_DELETE_LEARNING_AREA,
         DELETE_LEARNING_AREA_SUCCESS,
         DELETE_LEARNING_AREA_FAILURE,
         CLEAR_DELETE_LEARNING_AREA } from './DeleteLearningAreaTypes'

export const requestDeleteLearningArea = () => {
    return {
        type : REQUEST_DELETE_LEARNING_AREA
    }
}

export const deleteLearningAreaSuccess = (resData) => {
    return {
        type : DELETE_LEARNING_AREA_SUCCESS,
        payload : resData
    }
}

export const deleteLearningAreaFailure = (error) => {
    return {
        type : DELETE_LEARNING_AREA_FAILURE,
        payload : error
    } 
}

export const clearDeleteLearningArea = () => {
    return {
        type : CLEAR_DELETE_LEARNING_AREA
    }
}