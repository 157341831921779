import { 
    REQUEST_MODIFY_LEARNING_AREA, 
    MODIFY_LEARNING_AREA_SUCCESS,
    MODIFY_LEARNING_AREA_FAILURE,
    CLEAR_MODIFY_LEARNING_AREA,
    SAVE_LEARNING_AREA_ICON,
    SAVE_COURSE_CONTENT_FILES,
    REMOVE_COURSE_CONTENT_FILES,
    SAVE_MODIFY_LEARNING_VALIDATION_ERROR,
    REMOVE_MODIFY_LEARNING_VALIDATION_ERROR

} from './ModifyLearningArea.types'

import {remove, save} from './ModifyLearningArea.utils'

const initialState = {
    loading : false,
    response : {},
    icon : {
        //iconPreview : "",
        //file : FILE
    },
    courseContentFiles: [
        // {
        //     id: '',
        //     files : []
        // }
    ],
    validationError : [
        // {
        //     id: '',
        //     hasErrors: true/false 
        // }
    ],
    error : null
}

const modifyLearningReducer = ( state = initialState , action ) => {
    switch(action.type){
        case REQUEST_MODIFY_LEARNING_AREA:
            return {
                ...state,
                loading : true,
                error : null
            }

        case MODIFY_LEARNING_AREA_SUCCESS:
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case MODIFY_LEARNING_AREA_FAILURE: 
            return {
                ...state,
                loading : false,
                error :action.payload
            }

        case CLEAR_MODIFY_LEARNING_AREA: 
            return {
                ...state,
                loading : false,
                response : {},
                error : null
            }

        case SAVE_LEARNING_AREA_ICON:
            return {
                ...state,
                icon : {
                    iconPreview: action.payload.iconPreview,
                    file: action.payload.file
                }
            }
        
        case SAVE_COURSE_CONTENT_FILES:
            return {
                ...state,
                courseContentFiles : save(state.courseContentFiles, action.payload)
            }
        
        case REMOVE_COURSE_CONTENT_FILES:
            return {
                ...state,
                courseContentFiles : remove(state.courseContentFiles, action.payload)
            }

        case SAVE_MODIFY_LEARNING_VALIDATION_ERROR:
            return {
                ...state,
                validationError : save(state.validationError, action.payload)
            }
        
        case REMOVE_MODIFY_LEARNING_VALIDATION_ERROR:
            return {
                ...state,
                validationError : remove(state.validationError, action.payload)
            }

        default : 
            return state
    }
}

export default modifyLearningReducer