import { REQUEST_GOALS_FOR_SELECT,
         GOAL_FOR_SELECT_SUCCESS,
         GOAL_FOR_SELECT_FAILURE,
         CLEAR_GOAL_SELECT_STATE } from './goalSelect.types'

export const requestGoalSelect = () => {
    return{
        type : REQUEST_GOALS_FOR_SELECT,
    }
}

export const goalSelectSuccess = (resData) => {
    return {
        type : GOAL_FOR_SELECT_SUCCESS,
        payload: resData
    }
}

export const goalSelectFailure = (error) => {
    return {
        type : GOAL_FOR_SELECT_FAILURE,
        payload: error
    }
}

export const clearGoalSelectState = () => {
    return {
        type : CLEAR_GOAL_SELECT_STATE
    }
}