import React,{ useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import * as Yup from 'yup'
import { Formik, Field, FieldArray, Form } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { isEmpty, size } from 'lodash'
import qs from 'query-string'
import { toast } from 'react-toastify'
import ObjectiveListPopup from '../../../components/objective-list-popup/ObjectiveListPopup' 
import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import { deepSearch, formatDate } from '../../../utils/utils'

import { clearFetchGoals } from '../../../redux/fetch-goals/FetchGoalsAction'

import {  goalDateValidation, specificGoal } from '../../../services/goals.service'
import { requestObjectiveForSelect } from '../../../services/videoConference.service'
import DatePicker from '../../../common/date-picker/DatePicker'
import { modifyAction } from '../../../services/notification.service'
import { clearSpecificGoalStates } from '../../../redux/specific-goal/specificGoal.action'
import { fetchSpecificObjectivePromise } from '../../../services/fetchObjectives.service'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import { mailActionChanges } from '../../../redux/notification/notification-detail/notificationDetail.action'
import Container from '../../../components/desktop-layout/container/Container'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import UploadFiles from '../../../components/upload-files/UploadFiles'
import Header from '../../../common/header/Header'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import ProcessWallpaperEdit from '../../../components/process-wallpaper-edit/ProcessWallpaperEdit'
import ProcessIconEdit from '../../../components/process-icon-edit/ProcessIconEdit'

const GOALS_ATTACHMENT_NAMES = {
    data : 'action_data',
    wallpaper : 'action_wallpaper',
    icon : 'action_icon',
    reference : 'action_reference'
}

const ModifyGoal = (props) => {

    const { requestSpecificGoal, 
            clearGoalState,
            requestObjective, 
            specificGoalState, 
            objectiveStates, 
            alterGoalStates, 
            clearSpecificGolState,
            notificationActionChanges } = props

    const history = useHistory()
    const wallpaperimageRef = useRef()
    const iconImageRef = useRef()
    const formRef = useRef()

    const [wallpaperImageFile,setWallpaperImageFile] = useState('')
    const [wallpaperPreviewImage, setWallpaperPreviewImage] = useState("")
    const [iconImageFile, setIconImageFile] = useState("")
    const [iconPreviewImage,setIconPreviewImage] = useState("")
    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [objectivePopup, setobjectivePopup] = useState(false)
    const [ currentObj, setCurrentObj ] = useState({})
    const [pageLoading, setPageLoading] = useState({
        objectives : true,
        goals : true
    })
    const [ goals,setGoals ] = useState({})
    const [ menu, setMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)
    const [ loadingButton, setLoadingButton ] = useState(false)

    const referenceFileRef = useRef();

    const objectives = objectiveStates.response.objectives

    let location = useLocation()
    const queryParams = qs.parse(location.search)
    const isDesktop = useMediaQuery('(min-width:992px)')

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function getObjById(object, id){
        return new Promise((resolve, reject) => {
            const obj = deepSearch(object, '_id', (k, v) => v === id)
            resolve(obj)
        })
    }

    function updateCurrObj(id){
        getObjById(objectives, id).then(obj => {
            setCurrentObj(obj)
        })
    }

    const emptyLinkObj = {
        originalname : "",
        url : ""
    }

    const initialValues={
        name: !isEmpty(goals) ? goals.name  : '',
        description: !isEmpty(goals) ? goals.description : '',
        start_date: !isEmpty(goals)  && goals.start_date ? formatDate(goals.start_date) : '',
        end_date: !isEmpty(goals) && goals.end_date ? formatDate(goals.end_date) : '',
        objective_id : '',
        metrics: !isEmpty(goals) ? goals.metrics.map(metric => metric.name) : [''],
        reference_links : !isEmpty(goals) && !isEmpty(goals.reference_links) ? getLinks(goals.reference_links) : [emptyLinkObj]
    }

    const validationSchema = (objective) => {
        return Yup.object({
            name: Yup.string().required("Please enter the goal name"),
            description: Yup.string(),
            start_date: Yup.date().required('Please select the start date')
                        .test("check objective","Please select the objective",(value) => {
                            if(value && isEmpty(objective)){
                                return false
                            }else{
                                return true
                            }
                        })
                        .test("check date range","Invalid date", value => {
                            if(value && !isEmpty(objective)){
                                const startDate = (moment(value).startOf("day")).toISOString()
                                return new Promise((resolve, reject) => {
                                    goalDateValidation( "start_date", startDate, objective._id).then(res => {
                                        resolve(res.data.valid)
                                    }).catch(err => {
                                        console.log(err)
                                        resolve(false)
                                    })
                                })
                            }else{
                                return false
                            }
                        }),
            end_date:Yup.date().required('Please select your end date')
                    .min(Yup.ref('start_date'),"End date can't be before Start date")
                    .test("check objective","Please select the objective",(value) => {
                        if(value && isEmpty(objective)){
                            return false
                        }else{
                            return true
                        }
                    })
                    .test('check for past dates', "End date can't be in past", (value) => {
                        if(value){
                            return (moment(value).endOf("day")).isSameOrAfter(moment())
                        } else {
                            return false
                        }
                    })
                    .test("check date range","Invalid date", value => {
                        if(value && !isEmpty(objective)){
                            const EndDate = (moment(value).endOf("day")).toISOString()
                            return new Promise((resolve, reject) => {
                                goalDateValidation( "start_date", EndDate, objective._id).then(res => {
                                    resolve(res.data.valid)
                                }).catch(err => {
                                    console.log(err)
                                    resolve(false)
                                })
                            })
                        }else{
                            return false
                        }
                    }),
            objective_id: Yup.string().required("Please select a objective"),
            metrics: Yup.array().of(
                Yup.string().required("Please enter the metric name")
            ).min(1, 'Choose minimum one metric'),
            reference_links : Yup.array().of(
                Yup.object().shape({
                    originalname: Yup.string(),
                    url: Yup.string().when("originalname", {
                        is: ( value ) => !isEmpty(value),
                        then: Yup.string().required("Please enter the url").url("Invalid url"),
                        otherwise: Yup.string().url("Invalid url")
                    })
                })
            )
        })
    }


    const onSubmit = (values) =>{
        const valuesCopy = JSON.parse(JSON.stringify(values))
        valuesCopy.start_date = (moment(valuesCopy.start_date).startOf('day')).toISOString()
        valuesCopy.end_date = (moment(valuesCopy.end_date).endOf('day')).toISOString()
        valuesCopy.reference_links = valuesCopy.reference_links.filter(item => item.original_name || item.url)
        if(!wallpaperPreviewImage && goals?.wallpaper){
            valuesCopy.wallpaper = ""
        }
        if(!iconPreviewImage && goals?.icon){
            valuesCopy.icon = ""
        }
        const payload = addFormData(valuesCopy)
        if(navigator.onLine){
            setLoadingButton(true)
            modifyAction(queryParams.actionId, "goal", payload)
            .then((res) => {
                clearGoalState()
                clearSpecificGolState()
                notificationActionChanges()
                setLoadingButton(false)
                toast.success(res.data.message , {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.goBack()
                history.goBack()
            }).catch((err) => {
                console.log(err.response.data)
                if(!isEmpty(err) && !isEmpty(err.response)){
                    if(err.response.data){
                        setLoadingButton(false)
                        toast.error(err.response.data.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }
            })
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function addFormData(goalData){

        let copyGoalData = JSON.parse(JSON.stringify(goalData))

        let goalFormData = new FormData()

        if(!isEmpty(existingReferences)){
            copyGoalData.reference_files = existingReferences.map(referenceFile => {
                return referenceFile._id
            })
        } else {
            copyGoalData.reference_files = []
        }

        goalFormData.append(GOALS_ATTACHMENT_NAMES.data, JSON.stringify(copyGoalData))

        if(wallpaperImageFile){
            goalFormData.append(GOALS_ATTACHMENT_NAMES.wallpaper, wallpaperImageFile)
        }

        if(iconImageFile){
            goalFormData.append(GOALS_ATTACHMENT_NAMES.icon, iconImageFile)
        }

        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                goalFormData.append(GOALS_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }
        return goalFormData
    }

    useEffect(() => {                         //wallPaper preview
        if(wallpaperImageFile){
            const reader = new FileReader()
            reader.onloadend =() =>{
                setWallpaperPreviewImage(reader.result)
            }
            reader.readAsDataURL(wallpaperImageFile)
        }
    }, [wallpaperImageFile])
    
    useEffect(() =>{                         //Icon preview
        if(iconImageFile){
            const reader = new FileReader()
            reader.onloadend = () => {
                setIconPreviewImage(reader.result)
            }
            reader.readAsDataURL(iconImageFile)
        }
    },[iconImageFile])

    useEffect(() => {
        if(!objectiveStates.loading && !isEmpty(objectiveStates.response)){
            // console.log(objectiveStates.response)
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    objectives : false 
                }
            })
        }
        if(!objectiveStates.loading && !isEmpty(objectiveStates.error)){
            console.log(objectiveStates.error)
        }
    }, [objectiveStates])

    useEffect(() => {
        if(!specificGoalState.loading && !isEmpty(specificGoalState.response)){
            setGoals(specificGoalState.response)
            setExistingReferences(!isEmpty(specificGoalState.response) && !isEmpty(specificGoalState.response.reference_files) ? 
                specificGoalState.response.reference_files : [])
            setWallpaperPreviewImage(!isEmpty(specificGoalState.response) ? specificGoalState.response.wallpaper :"")
            setIconPreviewImage(!isEmpty(specificGoalState.response) && specificGoalState.response.icon ? specificGoalState.response.icon : "")
            fetchSpecificObjectivePromise({path : specificGoalState.response?.objective_id?.path, level : specificGoalState.response?.objective_id?.level })
            .then((res) => {
                if(res.success){
                    setCurrentObj(res.data)
                    if(!isEmpty(formRef.current)){
                        formRef.current.setFieldValue('objective_id', res.data._id)
                    }
                }else{
                    setCurrentObj({})
                }
            }).catch((err) => {
                console.log(err)
            })
            setPageLoading(prevState => {
                return {
                    ...prevState,
                    goals : false
                }
            })
        }
        if(!specificGoalState.loading && !isEmpty(specificGoalState.error)){
            if(!isEmpty(specificGoalState.error)){
                if(!isEmpty(specificGoalState.error.data)){
                    console.log(specificGoalState.error.data.error) 
                }
            }
        }
    }, [specificGoalState])

    useEffect(()=>{
        if(isEmpty(objectiveStates.response)){
            requestObjective()
        } 
        if(isEmpty(specificGoalState.response)){
            requestSpecificGoal(queryParams.goal_id)
        } 
        return () => {
            
        }
    },[])

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index != i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index != i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            //console.log(prevState.files)
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    function toggleObjectivePopup(){
        setobjectivePopup(!objectivePopup)
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.map((link) => (
            contentLink.push({url : link.url, originalname : link.originalname})
        ))
        return contentLink
    }

    const [wallpaperAnchorEl, setWallpaperAnchorEl] = useState(null)

    const wallpaperMenuOpen = Boolean(wallpaperAnchorEl)

    const handleWallpaperEditIconClick = (event) => {
        setWallpaperAnchorEl(event.currentTarget)
    }

    const handleWallpaperMenuClose = () => {
        setWallpaperAnchorEl(null)
    }

    const [iconAnchorEl, setIconAnchorEl] = useState(null)

    const iconMenuOpen = Boolean(iconAnchorEl)

    const handleIconEditIconClick = (event) => {
        setIconAnchorEl(event.currentTarget);
    }

    const handleIconMenuClose = () => {
        setIconAnchorEl(null)
    }

    const onRemoveWallpaperClick = () => {
        if(wallpaperImageFile) setWallpaperImageFile('')
        if(wallpaperPreviewImage) setWallpaperPreviewImage('')
    }

    const onRemoveIconClick = () => {
        if(iconImageFile) setIconImageFile('')
        if(iconPreviewImage) setIconPreviewImage('')
    }

    function getDetail(){
        return (
            <>
                <Formik
                    innerRef={formRef}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema(currentObj)}
                    onSubmit={onSubmit} >
                    { 
                        props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                            } = props

                            return (
                                <>
                                    <Form >
                                        <>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6} sm={6} >
                                                    <InputBox type="text"
                                                    variant="withOnClick"
                                                    name="objective_id"
                                                    label="VCF"
                                                    placeholder="Select VCF"
                                                    onClick={toggleObjectivePopup}
                                                    value={ !isEmpty(currentObj) ? currentObj.name:""}
                                                    errorMgs={errors.objective_id && touched.objective_id ? errors.objective_id : ""} />
                                                </Grid>
                                                <Grid item md={6} xs={12} sm={6} >
                                                    <InputBox type="text" 
                                                        name="name"
                                                        label="Name"
                                                        placeholder="Enter the goal name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errorMgs={touched.name && errors.name}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </> 
                                        <div className="mt-3">
                                            <TextAreaBox name="description"
                                                label="Description"
                                                rows={ isDesktop ? 4 : ""}
                                                placeholder="Enter the goal description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errormgs={touched.description && errors.description}
                                            />
                                        </div>
                                        <Grid container spacing={2} sx={{marginTop:"8px"}}>
                                            <Grid item xs={12} md={6} sm={6}>
                                                <DatePicker type="date" 
                                                    name="start_date"
                                                    label="Start Date"
                                                    min={ values.objective_id && !isEmpty(currentObj) ? formatDate(currentObj.start_date) : ""}
                                                    max={values.objective_id && !isEmpty(currentObj) ? formatDate(currentObj.end_date) : ""}
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.start_date && errors.start_date}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} sm={6}>
                                                <DatePicker type="date" 
                                                    name="end_date"
                                                    label="End Date"
                                                    min={values.objective_id && !isEmpty(currentObj) ? formatDate(currentObj.start_date) : ""}
                                                    max={values.objective_id && !isEmpty(currentObj) ? formatDate(currentObj.end_date) : ""}
                                                    value={ values.objective_id ? values.end_date : ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.end_date && errors.end_date}
                                                />
                                            </Grid>
                                        </Grid>  
                                        <div className="mt-3"> 
                                            <FieldArray name="metrics" >
                                                {fieldArrayProps => {
                                                    const { push, remove, form } = fieldArrayProps
                                                    const { values } = form
                                                    const { metrics } = values
                                                    return (
                                                        <div>
                                                            <Grid container sx={{marginTop:"10px"}} spacing={2}>
                                                            {metrics.map((metric,index) => (
                                                                <> 
                                                                <Grid item md={ size(metrics) > 1 ? 5 : 6 } xs={ size(metrics) > 1 ? 11 : 12 } sx={{paddingRight: {xs:0, md: size(metrics) > 1 ? "0px" : "10px"}, paddingLeft : {xs:0, md: size(metrics) > 1 ? "10px" : "0px"}}}>
                                                                    <Field name={`metrics[${index}]`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <>
                                                                                        <InputBox type="text"
                                                                                            label="Metrics" 
                                                                                            placeholder="Enter your metric name"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    { size(metrics) > 1 ?
                                                                        <Grid item md={1} xs={1}>
                                                                            <div className='d-flex justify-content-center mt-1'>
                                                                                <IconButton  onClick={() => remove(index)}>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                        </Grid> : null }
                                                                </>
                                                            ))}
                                                            </Grid>
                                                            <div className='mt-2'>
                                                                <CustomizeButton variant="secondary-start-icon-button" label="Add Metric" StartIcon={<AddIcon/>} handleClick={() => push('')} />
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </FieldArray>
                                        </div>
                                        <div className="mt-3">
                                        <FieldArray name="reference_links" >
                                            {fieldArrayProps => {
                                                const { push, remove, form } = fieldArrayProps
                                                const { values } = form 
                                                const { reference_links } = values
                                                return (
                                                    <>
                                                        {reference_links.map((file,index) => (
                                                            <div key={index} className="mb-2">
                                                                <Grid container spacing={2}>
                                                                    <Grid item md={4} xs={12} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].originalname`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <>
                                                                                        <InputBox type="text" 
                                                                                            label="Link Name"
                                                                                            placeholder="Enter the link name"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item md={ index === 0 ? 8 : 7} xs={ index === 0 ? 12 : 11} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].url`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <div>
                                                                                        <InputBox type="url" 
                                                                                            label="Link Url"
                                                                                            placeholder="http://www.example.com"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    {
                                                                        index > 0 ?
                                                                        <Grid item md={1} xs={1} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                            <div className='d-flex justify-content-center mt-1'>
                                                                                <IconButton onClick={() => remove(index)}>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </div>
                                                                        </Grid> : null
                                                                    }
                                                                </Grid>
                                                            </div>
                                                        ))}
                                                        <div className='mt-2'>
                                                            <CustomizeButton variant="secondary-start-icon-button" StartIcon={<AddIcon />} handleClick={() => push('')} label="Add Link" />
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        </FieldArray>
                                    </div>
                                        <div>
                                            <div className='mt-4'>
                                                { !isEmpty(existingReferences) ? 
                                                    existingReferences.map((reference, index) => ( 
                                                        <div key={index} className='d-flex mb-2'>
                                                            <a href={reference.url} className="filename one-line-wrap lato-fs15-normal-lh20" target="_blank" >{reference.originalname}</a>
                                                            <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                        </div>
                                                    )
                                                ) : null }
                                                { !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? referenceFiles.files.map((file,index)=>(
                                                        <div key={index} className="mb-2 d-flex">
                                                            <p className="mb-0 one-line-wrap lato-fs15-normal-lh20">{file.name}</p>
                                                            <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                                onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                        </div>
                                                )) : null}
                                            </div>
                                            <div className='mt-2'>
                                                <UploadFiles label="Click to upload attachment"
                                                    variant={isDesktop ? '' : 'mobile-view'} 
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        referenceFileRef.current.click();
                                                    }} />
                                            </div>
                                        </div>
                                        <input type="file"
                                            style={{display:"none"}} 
                                            ref={referenceFileRef} 
                                            multiple
                                            accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx"
                                            name="objective_icon"
                                            onChange={(event) =>{
                                                const file = event.target.files
                                                if(file){
                                                    handleReferenceFileAddition(file)
                                                }
                                        }} />
                                        <div className="d-flex justify-content-center align-center mt-4">
                                            {/* <CustomizeButton variant="primary-color-button" type="submit" label="Modify Goal" /> */}
                                            <CustomizeLoadingButton 
                                                variant="accept-loading-button-v2"
                                                label="Modify Goal"
                                                loading={loadingButton}
                                                type="submit"
                                            />
                                        </div>
                                        {objectivePopup ? 
                                            <ObjectiveListPopup handleClose={toggleObjectivePopup}
                                                open={objectivePopup}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    updateCurrObj(e.target.value)
                                                    toggleObjectivePopup()
                                                }}
                                                options={objectives}
                                                value={currentObj._id} 
                                                /> : null}
                                    </Form>
                                </>
                            )
                        }
                    }
                </Formik>
            </>
        )
    }

    return (
        <>
            <Container>
                {isDesktop ? 
                    <>
                        <Navbar enableLogout={true}
                            enableMore={isDesktop ? true : false} 
                            enableProfileButton={ isDesktop ? true : false }
                            moreRight="88px"
                            open={open}
                            menu={menu}
                            handlePopoverClose={handleMenuClose} 
                            handlePopoverOpen={handleMenuOpen} />
                        <ProcessSectionContainer>
                            <LeftVerticalMenuBar />
                            <ProcessMainContainer className="white-bg">
                                <SubMainContainer className="mb-40">
                                    {!pageLoading.goals && !pageLoading.objectives ? 
                                        <>
                                            <div className='mt-3'>
                                                <h5 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold'>Modify Goal</h5>
                                            </div>
                                            <div className='mt-3'>
                                                <ProcessWallpaperEdit
                                                    ref={wallpaperimageRef}
                                                    onRemoveClick={() => {
                                                        onRemoveWallpaperClick()
                                                        handleWallpaperMenuClose()
                                                    }}
                                                    onUploadClick={(event) => {
                                                        event.preventDefault();
                                                        wallpaperimageRef.current.click();
                                                        handleWallpaperMenuClose()
                                                    }}
                                                    handleMenuClose={handleWallpaperMenuClose}
                                                    menuOpen={wallpaperMenuOpen}
                                                    anchorEl={wallpaperAnchorEl}
                                                    previewImage={wallpaperPreviewImage}
                                                    handleEditIconClick={handleWallpaperEditIconClick}
                                                    onInputChange={(event) =>{
                                                        const file = event.target.files[0];
                                                        if(file && file.type.substr(0,5) === "image"){
                                                            setWallpaperImageFile(file)
                                                        }
                                                    }}
                                                />
                                                <ProcessIconEdit
                                                    ref={iconImageRef}
                                                    onRemoveClick={() => {
                                                        onRemoveIconClick()
                                                        handleIconMenuClose()
                                                    }}
                                                    onUploadClick={(event) => {
                                                        event.preventDefault();
                                                        iconImageRef.current.click();
                                                        handleIconMenuClose()
                                                    }}
                                                    handleMenuClose={handleIconMenuClose}
                                                    menuOpen={iconMenuOpen}
                                                    anchorEl={iconAnchorEl}
                                                    previewImage={iconPreviewImage}
                                                    handleEditIconClick={handleIconEditIconClick}
                                                    onInputChange={(event) =>{
                                                        const file = event.target.files[0];
                                                        if(file && file.type.substr(0,5) === "image"){
                                                            setIconImageFile(file)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='mt-4'>
                                                {getDetail()}
                                            </div>
                                        </> : null
                                    }
                                </SubMainContainer>
                            </ProcessMainContainer>
                        </ProcessSectionContainer>
                    </> : 
                    <>
                        <Navbar 
                            variant="mobile" 
                            enableLogout={true}
                            enableDrawer={true}
                            drawerOpened={drawer}
                            handleDrawerClose={toggleDrawer}
                            onDrawerClick={toggleDrawer}a
                        />
                        <div className='container'>
                            <div className='mt-3 px-3'>
                                <Header handleBackArrowEvent={handleBackArrowEvent} heading="Modify Goal" />
                            </div>
                            <div className='px-3 pb-70'>
                                {!pageLoading.goals && !pageLoading.objectives ? 
                                    <>
                                        <div className='mt-3'>
                                            <ProcessWallpaperEdit
                                                ref={wallpaperimageRef}
                                                onRemoveClick={() => {
                                                    onRemoveWallpaperClick()
                                                    handleWallpaperMenuClose()
                                                }}
                                                onUploadClick={(event) => {
                                                    event.preventDefault();
                                                    wallpaperimageRef.current.click();
                                                    handleWallpaperMenuClose()
                                                }}
                                                handleMenuClose={handleWallpaperMenuClose}
                                                menuOpen={wallpaperMenuOpen}
                                                anchorEl={wallpaperAnchorEl}
                                                previewImage={wallpaperPreviewImage}
                                                handleEditIconClick={handleWallpaperEditIconClick}
                                                onInputChange={(event) =>{
                                                    const file = event.target.files[0];
                                                    if(file && file.type.substr(0,5) === "image"){
                                                        setWallpaperImageFile(file)
                                                    }
                                                }}
                                            />
                                            <ProcessIconEdit
                                                ref={iconImageRef}
                                                onRemoveClick={() => {
                                                    onRemoveIconClick()
                                                    handleIconMenuClose()
                                                }}
                                                onUploadClick={(event) => {
                                                    event.preventDefault();
                                                    iconImageRef.current.click();
                                                    handleIconMenuClose()
                                                }}
                                                handleMenuClose={handleIconMenuClose}
                                                menuOpen={iconMenuOpen}
                                                anchorEl={iconAnchorEl}
                                                previewImage={iconPreviewImage}
                                                handleEditIconClick={handleIconEditIconClick}
                                                onInputChange={(event) =>{
                                                    const file = event.target.files[0];
                                                    if(file && file.type.substr(0,5) === "image"){
                                                        setIconImageFile(file)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='mt-4'>
                                            {getDetail()}
                                        </div>
                                    </> : null
                                }
                            </div>
                        </div>
                        <BottomNavigation />
                    </>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        specificGoalState : state.specificGoal,
        objectiveStates : state.objectiveForSelect,
        alterGoalStates : state.goalCUD,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjective : () => dispatch(requestObjectiveForSelect()),
        requestSpecificGoal : (goalId) => dispatch(specificGoal(goalId)),
        clearSpecificGolState : () => dispatch(clearSpecificGoalStates()),
        clearGoalState : () => dispatch(clearFetchGoals()),
        notificationActionChanges : () => dispatch(mailActionChanges())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ModifyGoal)