import { REQUEST_OBJECTIVE_UNDER_REVIEW, 
        FETCH_OBJECTIVE_UNDER_REVIEW_SUCCESS, 
        FETCH_OBJECTIVE_UNDER_REVIEW_FAILURE, 
        CLEAR_OBJECTIVE_UNDER_REVIEW_STATE, 
        REVIEW_ACTION_CHANGED_UNDER_REVIEW} from './objectiveUnderReview.types'

const initialState = {
    loading : false,
    action_changed : false,
    response : {},
    error : null
}

const objectiveUnderReviewReducer = ( state = initialState, action ) =>  {
    switch(action.type) {
        case REQUEST_OBJECTIVE_UNDER_REVIEW :
            return{
                ...state,
                loading : true,
                action_changed : false,
                error : null
            }

        case FETCH_OBJECTIVE_UNDER_REVIEW_SUCCESS : 
            return {
                ...state,
                loading : false,
                action_changed : false,
                response : action.payload
            }

        case FETCH_OBJECTIVE_UNDER_REVIEW_FAILURE : 
            return {
                ...state,
                loading : false,
                action_changed: false,
                error : action.payload
            }

        case REVIEW_ACTION_CHANGED_UNDER_REVIEW :
            return {
                ...state,
                loading : true,
                action_changed : true,
                response : {},
                error : null
            }

        case CLEAR_OBJECTIVE_UNDER_REVIEW_STATE : 
            return {
                ...state,
                loading : true,
                action_changed : true,
            }

        default : 
            return state
    }
}

export default objectiveUnderReviewReducer