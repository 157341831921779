import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import './landing-page.css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useHistory } from 'react-router-dom'

import Container from '../../../components/desktop-layout/container/Container'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import PublicNavbar from '../public-navbar/PublicNavbar'
import Footer from '../../../components/footer/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

const LandingPage = () => {

    const [ drawer, setDrawer ] = useState(false)

    const history = useHistory()
    const isDesktop = useMediaQuery('(min-width:992px)')

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    return (
        <Container>
            <PublicNavbar 
                variant={isDesktop ? "" : "mobile"}
                logo={true} 
                enableProduct={true} 
                enableConsult={true} 
                enableSubscribe={true} 
                // enableLogin={true}
                enableAboutUs={true}
                enableDrawer={true}
                drawerOpened={drawer}
                handleDrawerClose={toggleDrawer}
                onDrawerClick={toggleDrawer} />
            <article>
                {isDesktop ? 
                    <>
                        <section className='d-flex flex-column mx-auto py-5' style={{minHeight: '85vh', width:"86%"}}>
                            <Grid container spacing={2} sx={{display:"flex", alignItems:"flex-end"}}> 
                                <Grid className='d-flex flex-column justify-content-center' item xs={12} md={6}>
                                    <div>
                                        <h1 className='mb-4 lato-fs-42-normal-lh54 primary-text-color font-bold'>The performance and productivity challenge</h1>
                                        <h5 className='mb-4 lato-fs22-normal-lh32 primary-text-color'>Is everyone in your organization “wired up, plugged in and switched on” to achieve results?</h5>
                                    </div>
                                </Grid>
                                <Grid item md={6} sx={{display:"flex", justifyContent:"center"}}>
                                    <img style={{maxHeight:"300px",maxWidth:"100%"}} src="https://www.commbox.io/wp-content/uploads/2020/03/244-1.jpg" alt=""/>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} className="mt-4" sx={{width:"83vw",marginLeft:"auto",marginRight:"auto"}}>
                                <Grid item md={6}>
                                    <ul className='list-style-none'>
                                        <li className='mb-3 lato-fs19-normal-lh22 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                            Is the incumbent completely devoted to value creation? </li>
                                        <li className='mb-3 lato-fs19-normal-lh22 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                            Is workforce alignment to strategy always on-time and complete?</li>
                                    </ul>
                                </Grid>
                                <Grid item md={6}>
                                    <ul className='list-style-none'>
                                        <li className='mb-3 lato-fs19-normal-lh22 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                        Is work crafted to make it absorbing?</li>
                                        <li className='mb-3 lato-fs19-normal-lh22 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                            Self-directed, not boss driven?</li>
                                    </ul>
                                </Grid>
                            </Grid>
                            <div className='mt-3'>
                                <p className='lato-fs20-normal-lh28 primary-text-color font-medium'>The Just 4 model is a solution created to deliver this transformation.</p>
                            </div>
                            <div className='mt-3'>
                                <CustomizeButton 
                                    variant="primary-color-button" 
                                    label="Consult Us" 
                                    handleClick={() => history.push(`/consult-us`)} 
                                />
                            </div>
                        </section>
                    </>:
                    <>
                        <section className='d-flex flex-column mx-auto pt-4 pb-5' style={{minHeight: '88vh', width:"86%"}}>
                            <Grid container spacing={2} sx={{display:"flex", alignItems:"center"}}> 
                                <Grid className='d-flex flex-column justify-content-center' item xs={12}>
                                    <div>
                                        <h1 className="lato-fs34-normal-lh42 my-3 font-bold primary-text-color">The performance and productivity challenge</h1>
                                        <h5 className='mb-2 lato-fs22-normal-lh32 primary-text-color'>Is everyone in your organization “wired up, plugged in and switched on” to achieve results?</h5>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container columnSpacing={2} className="mt-2">
                                <Grid item md={6}>
                                    <ul className='list-style-none'>
                                        <li className='mb-3 lato-fs18-normal-lh25 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                            Is the incumbent completely devoted to value creation? </li>
                                        <li className='mb-3 lato-fs18-normal-lh25 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                            Is workforce alignment to strategy always on-time and complete?</li>
                                    </ul>
                                </Grid>
                                <Grid item md={6}>
                                    <ul className='list-style-none'>
                                        <li className='mb-3 lato-fs18-normal-lh25 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                        Is work crafted to make it absorbing?</li>
                                        <li className='mb-3 lato-fs18-normal-lh25 tertiary-text-color-v2 '>
                                            <FontAwesomeIcon icon={faCaretRight} className="list-icon" />
                                            Self-directed, not boss driven?</li>
                                    </ul>
                                </Grid>
                            </Grid>
                            <div className='mt-3'>
                                <p className='lato-fs20-normal-lh28 primary-text-color mb-0 font-medium'>The Just 4 model is a solution created to deliver this transformation.</p>
                            </div>
                            <div className='mt-3 d-flex justify-content-center'>
                                <img style={{maxHeight:"300px",maxWidth:"100%"}} src="https://www.commbox.io/wp-content/uploads/2020/03/244-1.jpg" alt=""/>
                            </div>
                            <div className='mt-4'>
                                <CustomizeButton 
                                    variant="primary-color-button" 
                                    label="Consult Us" 
                                    handleClick={() => history.push(`/consult-us`)} 
                                />
                            </div>
                        </section>
                    </>
                }
                {/* <div>
                    <Footer/>
                </div> */}
            </article>
        </Container>
    )
}

export default LandingPage