import React,{ useState, useEffect } from 'react'
import PopUp from '../../common/popup/PopUp'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import CustomizeButton from '../../common/customize-button/CustomizeButton'


const SwapObjPopup = (props) => {

    const {modifyObjectiveState, ownProps} = props

    const objectives = [modifyObjectiveState.response.objective]
    const isDesktop = useMediaQuery('(min-width:992px)')

    const formik = useFormik({
        initialValues : {
            objectiveId : ''
        },
        validationSchema : yup.object().shape({
            objectiveId : yup.string().required('Please enter the objective')
        }),
        onSubmit : values => {
            //const appropriateObj = deepSearch(objectives, '_id', (k, v) => v === values.objectiveId)
            const appropriateObj = getAppropriateObjective(values.objectiveId)
            ownProps.onSwap(appropriateObj)
            ownProps.handleClose()
        }
    })

    function getAppropriateObjective(targetObjectiveId){
        let appropriateObj = {}
        function func(obj){
            if(!isEmpty(appropriateObj)){
                return
            }
            if(obj._id === targetObjectiveId){
                appropriateObj = obj
            }
            if(isEmpty(obj.sub_objectives)){
                return
            }
            obj.sub_objectives.forEach((subObj) => func(subObj))
        }
        func(modifyObjectiveState.response.objective)
        return appropriateObj
    }

    function objectiveList(objectives) {

        let objectiveOptions = []
        
        objectives.forEach((objective,index) => {
            objectiveOptions.push(<option key={index} disabled={ownProps.objFor._id === objective._id} value={objective._id}>{objective.name}</option>)  // level 0
            if(!isEmpty(objective.sub_objectives)){
                objective.sub_objectives.forEach((subObjOne) => {
                    objectiveOptions.push(<option key={subObjOne._id} disabled={ownProps.objFor._id === subObjOne._id} value={subObjOne._id}>&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjOne.name}`}</option>) // level 1
                    if(!isEmpty(subObjOne.sub_objectives)){
                        subObjOne.sub_objectives.forEach(subObjTwo => {
                            objectiveOptions.push(<option key={subObjTwo._id} disabled={ownProps.objFor._id === subObjTwo._id} value={subObjTwo._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjTwo.name}`}</option>)  // level 2
                            if(!isEmpty(subObjTwo.sub_objectives)){
                                subObjTwo.sub_objectives.forEach(subObjThree => {
                                    objectiveOptions.push(<option key={subObjThree._id} disabled={ownProps.objFor._id === subObjThree._id} value={subObjThree._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjThree.name}`}</option>)  // level 3
                                })
                            }
                        })
                    }
                })
            }
        })
        return objectiveOptions
    }

    return (
        <PopUp handleClose={props.handleClose} open={props.open}>
            <div className={ isDesktop ? "p-3 min-width-300px" :'p-3 min-width-250px'}>
                <form onSubmit={formik.handleSubmit}>
                    <h6 className="text-center lato-fs22-normal-lh32 primary-text-color font-bold pb-2">{`Swap ${ownProps.objFor.name} to`}</h6>
                    <select name="objective" className="grey-select-box" onChange={formik.handleChange} name='objectiveId' value={formik.values.objectiveId}>
                        <option value="" hidden>Select your Objective to swap</option>
                        {objectiveList(objectives)}
                    </select>
                    <div className="mt-2 d-flex justify-content-center align-items-center">
                        <CustomizeButton variant="primary-color-button" type="submit" label="Swap" />
                    </div>
                </form>
            </div>
        </PopUp>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        modifyObjectiveState : state.modifyObjective,
        ownProps : ownProps
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         requestMaxLevelObjective : (maxLevel) => dispatch(maxLevelObjective(maxLevel)),
//         clearObjectives : () => dispatch(clearChoosePathObjective())
//     }
// }

export default connect(mapStateToProps, null)(SwapObjPopup)