import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'

import PopUp from '../../common/popup/PopUp'
import './non-invited-member-popup.css'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const NonInvitedMemberPopup = (props) => {

    const { nonInvitedMemberState, ownProps } = props

    const uninvitedUsers = nonInvitedMemberState.response.uninvited_users
    const isDesktop = useMediaQuery('(min-width:992px)')

    const emailSentSuccessUser = ownProps.invitedMembersRes.data ? getUserObj(ownProps.invitedMembersRes.data.users) : [] 
    const emailSentFailedUser = ownProps.invitedMembersRes.error ? getErrorisedUser(ownProps.invitedMembersRes.error) : []

    function getUserObj(userIds){
        let users = []
        for(const userId of userIds){
            const user = uninvitedUsers.find((invitedUser) => invitedUser._id === userId)
            users.push(user)
        }
        return users
    }

    function getErrorisedUser(error){
        if(!isEmpty(error.email_failed) && !isEmpty(error.invalid_users)){
            return getUserObj([...error.email_failed.users, ...error.invalid_users.users])
        } else if(!isEmpty(error.email_failed)) {
            return getUserObj(error.email_failed.users)
        } else {
            return getUserObj(error.invalid_users.users)
        }
    }

    return (
        <PopUp handleClose={ownProps.handleClose} open={ownProps.open}>
            <div className={ isDesktop ? 'p-3 min-width-300px' : 'p-3 min-width-250px'}>
                <h4 className='text-center lato-fs24-bold-lh32 mb-3'>Invite status</h4>
                {
                    !isEmpty(emailSentSuccessUser) ?
                        <div className='mb-2'>
                            <div className='d-flex align-items-center mb-1'>
                                <CheckCircleIcon sx={{color: 'green', fontSize: '16px'}} className='me-1' />
                                <h5 className='lato-fs16-normal-lh20 m-0'>Invite successful for</h5>
                            </div>
                            <div>
                                {
                                    emailSentSuccessUser.map((user,index) => (
                                        <div className='pb-1'>
                                            {
                                                user?.person_name?.first_name ?
                                                    <span className="mb-0 lato-fs18-normal-lh25">{user.person_name.first_name}</span>
                                                    : null
                                            }
                                            {
                                                user?.person_name?.middle_name ?
                                                    <span className="mb-0 lato-fs18-normal-lh25">{user.person_name.middle_name}</span>
                                                    : null
                                            }
                                            {
                                                user?.person_name?.last_name ?
                                                    <span className="mb-0 lato-fs18-normal-lh25">{user.person_name.last_name}</span>
                                                    : null
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div> 
                        : null
                }
                {
                    !isEmpty(emailSentFailedUser) ?
                        <div className='mb-3'>
                            <div className='d-flex align-items-center mb-1'>
                                <ErrorIcon sx={{color: 'red', fontSize: '16px'}} className='me-1' />
                                <h5 className='lato-fs16-normal-lh20 m-0'>Invite failed for</h5>
                            </div>
                            <div>
                                {
                                    emailSentFailedUser.map((user, index) => (
                                        <div className='pb-1 d-flex flex-wrap'>
                                            {
                                                user?.person_name?.first_name ?
                                                    <span className="mb-0 lato-fs18-normal-lh25">{user.person_name.first_name}</span>
                                                    : null
                                            }
                                            {
                                                user?.person_name?.middle_name ?
                                                    <span className="mb-0 ps-1 lato-fs18-normal-lh25">{user.person_name.middle_name}</span>
                                                    : null
                                            }
                                            {
                                                user?.person_name?.last_name ?
                                                    <span className="mb-0 ps-1 lato-fs18-normal-lh25">{user.person_name.last_name}</span>
                                                    : null
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        : null
                }
                <div className="d-flex justify-content-center mt-2">
                    <CustomizeButton variant="primary-color-button" handleClick={ownProps.handleClose} label="Close" />
                </div>
            </div>
        </PopUp>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        nonInvitedMemberState : state.nonInvitedMembers,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NonInvitedMemberPopup)
