import { isEmpty } from "lodash";

export const updateArray = (array, objectiveId, newFile, data) => {
    // console.log(array)
    // console.log(objectiveId)
    // console.log(newFile)
    // console.log(data)
    if(!isEmpty(array)){
        let targetObjIndex = undefined
        array.some((item, index) => {
            if(item._id === objectiveId){
                targetObjIndex = index
                return true
            }
        })

        if(targetObjIndex !== undefined){
            array[targetObjIndex] = {
                _id : objectiveId,
                file : newFile,
                preview_data : data
            }
        } else {
            array.push({
                _id : objectiveId,
                file : newFile,
                preview_data : data
            })
        }

    } else {
        array.push({
            _id : objectiveId,
            file : newFile,
            preview_data : data
        })
    }

    return array
}

export const updateArrayItem = (array, id, files) => {
    if(!isEmpty(array)){

        let targetObjIndex = undefined;
        array.some((item, index) => {
            if(item._id === id){
                targetObjIndex = index
                return true
            }
        })

        if(targetObjIndex !== undefined){
            array[targetObjIndex] = {
                _id : id,
                files : files
            }
        } else {
            array.push({
                _id : id,
                files : files
            })
        }

    } else {
        array.push({
            _id : id,
            files : files
        })
    }
    return array
}

export const removeItemFromArray = (array, id) => {
    if(!isEmpty(array)){
        return array.filter(item => item._id !== id) 
    } else {
        return array
    }
}

export const saveOne = (prevState, payload) => {
    if(!isEmpty(prevState)){
        const filteredList = prevState.filter(obj => obj.id !== payload.id)
        if(!isEmpty(filteredList)){
            return [...filteredList, payload]
        } else {
            return [payload]
        }
    } else {
        return [payload]
    }
}

export const removeOne = (prevState, id) => {
    if(!isEmpty(prevState)){
        return prevState.filter(obj => obj.id !== id)
    } else {
        return prevState
    }
}

export const saveMany = (prevState, payload) => {
    if(!isEmpty(prevState)){
        const idsArr = payload.map(item => item.id)
        const filteredList = prevState.filter(obj => !idsArr.includes(obj.id))
        if(!isEmpty(filteredList)){
            return [...filteredList, ...payload]
        } else {
            return payload
        }
    } else {
        return payload
    }
}