import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

const Description = (props) => {

    const isDesktop = useMediaQuery('(min-width:992px)')

    return (
        <>
            <p className={ isDesktop ? 'mb-0 lato-fs18-normal-lh25 primary-text-color' :'mb-0 lato-fs16-normal-lh20 primary-text-color'}>{props.description}</p>
        </>
    )
}

export default Description