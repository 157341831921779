import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'

import Popup from '../../common/popup/PopUp'
import { rejectAction } from '../../services/notification.service'
import TextAreaBox from '../../common/textarea-box/TextAreaBox'
import { objectiveReviewRejectAction } from '../../services/review.service'
import { actionChangedUnderReview } from '../../redux/review-team-action/objective-under-review/objectiveUnderReview.action'
import { objectiveAllReviewActionchanged } from '../../redux/review-team-action/objective-review/objectiveReview.action'
import { clearNotificationDetailState, mailActionChanges } from '../../redux/notification/notification-detail/notificationDetail.action'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const RejectPopup = (props) => {

    const { actionChangeUnderReview, objectiveAllActionChanged, clearNotificationDetailState, notificationStatusChanges } = props

    const history = useHistory()
    const params = useParams()
    const location = useLocation().pathname
    const path = location.split('/')[1]
    const isDesktop = useMediaQuery('(min-width:992px)')

    const initialValues = {
        message : ""
    }

    const validationSchema = Yup.object({
        message : Yup.string().required("Please enter your message")
    })

    const onSubmit = async (values) => {
        if(path == "review"){
            try{
                const response = await objectiveReviewRejectAction(params.actionId,values)
                if(response.data){
                    actionChangeUnderReview()
                    objectiveAllActionChanged()
                    toast.success(response.data.message , {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                    history.goBack()
                }
            }catch(error){
                if(error.response){
                    if(error.response.data){
                        toast.error(error.response.data.error, {
                            position: "bottom-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                }
            }
        }else{
            try{
                const response = await rejectAction(props.actionId,values)
                if(response){
                    notificationStatusChanges()
                    toast.success(response.data.message , {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                    history.goBack()
                }
            }catch(error){
                // console.log(error.response.data.error)
                toast.error(error.response.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    return (
        <Popup handleClose={props.handleClose} open={props.open}>
            <div className='p-3' style={{minWidth: isDesktop? "350px" : "250px"}}>
                <h5 className="text-center lato-fs-20-normal-lh32 mb-0 primary-text-color font-bold">Process Rejection</h5>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mt-2">
                        <TextAreaBox
                            name="message"
                            label="Rejection message"
                            placeholder="Enter your rejection message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errormgs={formik.touched.message && formik.errors.message ? formik.errors.message : ""}
                        />
                    </div>
                    <div className="d-flex justify-content-center align-intems-center mt-3">
                        <CustomizeButton type="submit" variant="primary-color-button" label="Submit" />
                    </div>
                </form>
            </div>
        </Popup>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        actionChangeUnderReview : () => dispatch(actionChangedUnderReview()),
        objectiveAllActionChanged : () => dispatch(objectiveAllReviewActionchanged()),
        clearNotificationDetailState : () => dispatch(clearNotificationDetailState()),
        notificationStatusChanges : () => dispatch(mailActionChanges())
    }
}

export default connect(null,mapDispatchToProps)(RejectPopup)