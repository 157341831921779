import React,{useEffect, useState, useRef} from 'react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { isEmpty, size } from 'lodash'
import { connect } from 'react-redux'
import qs from 'query-string'
import { faCloudUploadAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import editIcon from '../../../assets/icons/edit_white.svg'
import wallpaperPlaceholder from '../../../assets/images/wallpaper-placeholder.png'
import iconPlaceholder from '../../../assets/images/icon-placeholder.jpg'

import InputBox from '../../../common/Input-box/InputBox'
import TextAreaBox from '../../../common/textarea-box/TextAreaBox'
import './add-or-edit-objective.css'
import { objectiveActionChanges } from '../../../redux/fetch-objectives/FetchObjectivesAction'
import { createObjectives, updateObjectives } from '../../../services/objectives.service'
import { formatDate } from '../../../utils/utils'
import DatePicker from '../../../common/date-picker/DatePicker'
import { requestObjectiveForSelect } from '../../../services/videoConference.service'
import { clearObjectives as clearObjectiveCUDStates } from '../../../redux/objectives/ObjectivesAction'
import { createObjectiveValidationSchema, editObjectiveValidationSchema } from './AddOrEditObjective.utils'
import { requestSpecificObjective } from '../../../services/review.service'
import { clearSpecificObjectiveForReview, specificObjectiveUpdated } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import CustomizeButton from '../../../common/customize-button/CustomizeButton'
import CustomizeLoadingButton from '../../../common/loading-button/CustomizeLoadingButton'
import { getObjByIdInArr } from '../../notification/modify-objective/utils'
import { objectiveSelectActionChanges } from '../../../redux/video-conference/objective/Objective.action'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import Header from '../../../common/header/Header'
import ProcessIconEdit from '../../../components/process-icon-edit/ProcessIconEdit'
import ProcessWallpaperEdit from '../../../components/process-wallpaper-edit/ProcessWallpaperEdit'

const OBJECTIVE_ATTACHMENT_NAMES = {
    data : 'objective_data',
    wallpaper : 'objective_wallpaper',
    icon : 'objective_icon',
    reference : 'objective_references'
}

const AddObjective = (props) => {

    const { objectiveStates, 
            createObjective,
            updateObjectives, 
            objectiveActionChanges,
            requestObjectiveForSelect,
            selectObjectiveState,
            clearObjectiveCUDStates,
            // clearObjectiveStateForSelect,
            specificObjectiveState, 
            requestSpecificObjective,
            clearSpecificObjectiveState,
            specificObjectiveUpdated,
            objectiveSelectActionChanges } = props

    const wallpaperImageRef = useRef();
    const iconImageRef = useRef();
    const referenceFileRef = useRef();
    const [wallpaperImageFile,setWallpaperImageFile] = useState('')
    const [wallpaperPreviewImage, setWallpaperPreviewImage] = useState("")
    const [iconImageFile, setIconImageFile] = useState("")
    const [iconPreviewImage,setIconPreviewImage] = useState("")
    const [referenceFiles, setReferenceFiles] = useState({files : []})
    const [existingReferences, setExistingReferences] = useState([])
    const [nestVCFCheckState, setNestVCFCheckState] = useState(false)
    const [loading, setLoading] = useState(false)

    const [pageLoading, setPageLoading] = useState({
        objective : true,
        objectiveSelect : true
    })
    
    const [isReset, setIsReset] = useState(false)
    const [objective, setObjective] = useState({})
    const [nestedVCF, setNestedVCF] = useState({})
    const [ moreMenu, setMoreMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const [wallpaperAnchorEl, setWallpaperAnchorEl] = useState(null);
    const wallpaperMenuOpen = Boolean(wallpaperAnchorEl);
    const handleWallpaperEditIconClick = (event) => {
        setWallpaperAnchorEl(event.currentTarget);
    };
    const handleWallpaperMenuClose = () => {
        setWallpaperAnchorEl(null);
    };

    const [iconAnchorEl, setIconAnchorEl] = useState(null);
    const iconMenuOpen = Boolean(iconAnchorEl);
    const handleIconEditIconClick = (event) => {
        setIconAnchorEl(event.currentTarget);
    };
    const handleIconMenuClose = () => {
        setIconAnchorEl(null);
    };

    const onRemoveWallpaperClick = () => {
        if(wallpaperImageFile) setWallpaperImageFile('')
        if(wallpaperPreviewImage) setWallpaperPreviewImage('')
    }

    const onRemoveIconClick = () => {
        if(iconImageFile) setIconImageFile('')
        if(iconPreviewImage) setIconPreviewImage('')
    }

    const OBJECTIVE_LEVEL = {
        0 : "level_zero",
        1 : "level_one",
        2 : "level_two",
        3 : "level_three"
    }

    let history = useHistory()
    let location = useLocation()
    const queryParams = qs.parse(location.search)

    const action = location.pathname.split('/')[2]
    const isDesktop = useMediaQuery('(min-width:992px)')

    const emptyLinkObj = {
        originalname : "",
        url : ""
    }

    const initialValues = {
        name: action === "edit" && !isEmpty(objective) ? (!isReset ? objective.name : '') : '',
        description: action === "edit" && !isEmpty(objective) ? (!isReset ? objective.description : '') : '',
        start_date: action === "edit" && !isEmpty(objective) && objective.start_date ? (!isReset ? formatDate(objective.start_date) : "") : isEmpty(queryParams.vcf_id) ? moment().format("YYYY-MM-DD") : "",
        end_date: action === "edit" && !isEmpty(objective) && objective.end_date ? (!isReset ? formatDate(objective.end_date) : '') : '',
        reference_links : action === "edit" && !isEmpty(objective) && !isEmpty(objective.reference_links) ? ( !isReset ? getLinks(objective.reference_links) : [emptyLinkObj]) : [emptyLinkObj],
    }

    const onSubmit = (values) =>{
        const valuesCopy = JSON.parse(JSON.stringify(values))
        valuesCopy.start_date = (moment(values.start_date).startOf('day')).toISOString()
        valuesCopy.end_date = (moment(values.end_date).endOf('day')).toISOString()
        valuesCopy.reference_links = valuesCopy.reference_links.filter(item => item.original_name || item.url)
        if(navigator.onLine){
            setLoading(true)
            if(action === "edit"){
                if(!wallpaperPreviewImage && specificObjectiveState?.response?.objective?.wallpaper) {
                    valuesCopy.wallpaper = ''
                }
                if(!iconPreviewImage && specificObjectiveState?.response?.objective?.wallpaper) {
                    valuesCopy.icon = ''
                }
                updateObjectives(updateFormData(valuesCopy), queryParams.vcf_id)
            } else {
                createObjective(addFormData(valuesCopy), nestVCFCheckState ? nestedVCF._id : queryParams.vcf_id )
            }
        }else{
            toast.error( "Please check your internet connection", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    function addFormData(objectiveData){

        let objectiveFormData = new FormData()

        objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.data, JSON.stringify(objectiveData))
        if(wallpaperImageFile){
            objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.wallpaper, wallpaperImageFile)
        }
        if(iconImageFile){
            objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.icon, iconImageFile)
        } 
        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }
        return objectiveFormData
    }

    function updateFormData(objectiveData){

        let copyObjectiveData = JSON.parse(JSON.stringify(objectiveData))

        let objectiveFormData = new FormData()

        if(!isEmpty(existingReferences)){
            copyObjectiveData.reference_files = existingReferences.map(referenceFile => {
                return referenceFile._id
            })
        } else {
            copyObjectiveData.reference_files = []
        }

        objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.data, JSON.stringify(copyObjectiveData))

        if(wallpaperImageFile){
            objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.wallpaper, wallpaperImageFile)
        }

        if(iconImageFile){
            objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.icon, iconImageFile)
        }

        if(!isEmpty(referenceFiles)){
            for(const referenceFile of referenceFiles.files){
                objectiveFormData.append(OBJECTIVE_ATTACHMENT_NAMES.reference, referenceFile)
            }
        }
        return objectiveFormData
    }

    function handleBackArrowEvent(){
        history.goBack()
    }

    const open = Boolean(moreMenu)

    const handleMenuOpen = (event) => {
        setMoreMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMoreMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function getLinks(contentLinkArray){
        const contentLink = []
        contentLinkArray.forEach((link) => (
            contentLink.push({url : link.url, originalname: link.originalname})
        ))
        return contentLink
    }

    // nested objective checkbox onChange
    function handleOnNestedVCFCheckChange(e){
        if(!e.target.checked && !isEmpty(nestedVCF)){
            setNestedVCF({})
        }
        setNestVCFCheckState(e.target.checked)
    }

    // nested objective option list
    function objectiveList(objectives) {

        let objectiveOptions = []
        
        objectives.forEach((objective,index) => {
            objectiveOptions.push(<option className="lato-fs15-normal-lh20 primary-color" key={index} value={objective._id}>{objective.name}</option>)  // level 0
            if(!isEmpty(objective.sub_objectives)){
                objective.sub_objectives.forEach((subObjOne) => {
                    objectiveOptions.push(<option className="lato-fs15-normal-lh20 primary-color" key={subObjOne._id} value={subObjOne._id}>&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjOne.name}`}</option>) // level 1
                    if(!isEmpty(subObjOne.sub_objectives)){
                        subObjOne.sub_objectives.forEach(subObjTwo => {
                            objectiveOptions.push(<option className="lato-fs15-normal-lh20 primary-color" key={subObjTwo._id} value={subObjTwo._id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${subObjTwo.name}`}</option>)  // level 2
                        })
                    }
                })
            }
        })
        return objectiveOptions
    }

    // current objective promise used for nested objective feature
    function getObj(objectives, id){
        return new Promise((resolve, reject) => {
            const obj = getObjByIdInArr(id,objectives)
            resolve(obj)
        })
    }

    //objective cud state
    useEffect(() => {
        if(!objectiveStates.loading && !isEmpty(objectiveStates.response)){
            setLoading(false)
            objectiveActionChanges()
            if(action === "edit"){
                specificObjectiveUpdated()
            }
            objectiveSelectActionChanges()
            toast.success( action === "edit" ? 'Objective updated successfully' : 'Objective created successfully', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if(!isEmpty(queryParams.vcf_id) && action !== "edit"){
                history.goBack()
            }
            history.goBack()
        }

        if(!objectiveStates.loading && !isEmpty(objectiveStates.error)){
            setLoading(false)
            console.log(objectiveStates.error)
            if(objectiveStates.error){
                if(objectiveStates.error.data){
                    toast.error(objectiveStates.error.data.error, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            }
        }
    },[objectiveStates])

    useEffect(() => {
        // request objective for select
        if(action === "create" && isEmpty(selectObjectiveState.response)){
            requestObjectiveForSelect()
        }
        if(!isEmpty(queryParams.vcf_id) && isEmpty(specificObjectiveState.response) ){
            requestSpecificObjective(queryParams.vcf_id)
        } else {
            setPageLoading(ps => {
                return {
                    ...ps,
                    objective : false
                }
            })
        }

        return () => {
            // clearObjectiveStateForSelect()
            clearSpecificObjectiveState()
            clearObjectiveCUDStates()
        }
    },[])

    // objective select state
    useEffect(() => {
        if(!selectObjectiveState.loading && !isEmpty(selectObjectiveState.response)){
            // console.log(selectObjectiveState.response)
            setPageLoading( ps => {
                return {
                    ...ps,
                    objectiveSelect : false
                }
            })
        }

        if(!selectObjectiveState.loading && !isEmpty(selectObjectiveState.error)){
            console.log(selectObjectiveState.error)
            setPageLoading( ps => {
                return {
                    ...ps,
                    objectiveSelect : false
                }
            })
            if(selectObjectiveState.error?.data){
                toast.error(selectObjectiveState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[selectObjectiveState])

    useEffect(() => {
        if(!specificObjectiveState.loading && !isEmpty(specificObjectiveState.response)){
            if(action === "edit"){
                setIconPreviewImage( !isEmpty(specificObjectiveState.response) && !isEmpty(specificObjectiveState.response.objective) ? specificObjectiveState.response.objective.icon : "")
                setWallpaperPreviewImage( !isEmpty(specificObjectiveState.response) && !isEmpty(specificObjectiveState.response.objective) ? specificObjectiveState.response.objective.wallpaper : "")  
                setObjective(specificObjectiveState.response?.objective)
                setExistingReferences( specificObjectiveState.response?.objective && !isEmpty(specificObjectiveState.response.objective?.reference_files) ? specificObjectiveState.response?.objective.reference_files : [])
            }

            if(action === "create" && !isEmpty(queryParams.vcf_id)){
                setObjective(specificObjectiveState.response.objective)
            }
            
            setPageLoading(ps => {
                return {
                    ...ps,
                    objective : false
                }
            })
        }

        if(!specificObjectiveState.loading && !isEmpty(specificObjectiveState.error)){
            console.log(specificObjectiveState.error)
            setPageLoading(ps => {
                return {
                    ...ps,
                    objective : false
                }
            })
            if(specificObjectiveState.error?.data){
                toast.error(specificObjectiveState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[specificObjectiveState])

    useEffect(() => {
        if(wallpaperImageFile){
            const reader = new FileReader()
            reader.onloadend =() =>{
                setWallpaperPreviewImage(reader.result)
            }
            reader.readAsDataURL(wallpaperImageFile)
        }
    }, [wallpaperImageFile])
    
    useEffect(() =>{
        if(iconImageFile){
            const reader = new FileReader()
            reader.onloadend = () => {
                setIconPreviewImage(reader.result)
            }
            reader.readAsDataURL(iconImageFile)
        }
    },[iconImageFile])

    function handleFormReset(resetForm){
        setIsReset(true)
        resetForm()
        setReferenceFiles({files : []})
        setWallpaperImageFile('')
        setWallpaperPreviewImage('')
        setIconImageFile('')
        setIconPreviewImage('')
        setExistingReferences([])
        setNestVCFCheckState(false)
        setNestedVCF({})
    }

    function handleDeleteExistingReferences(index){
        setExistingReferences(prevRef => {
            let prevRefCopy = prevRef.slice()
            let newRef = prevRefCopy.filter((ref, i) => {
                return index !== i
            })
            return newRef
        })
    }

    function handleDeleteReferenceFile(index){
        setReferenceFiles(prevState => {
            let prevStateCopy = prevState.files.slice()
            let newState = prevStateCopy.filter((ref, i) => {
                return index !== i
            })
            return { files : newState }
        })
    }

    function handleReferenceFileAddition(file){
        setReferenceFiles(prevState => {
            return !isEmpty(prevState.files) ? { files : [...prevState.files, ...file]} : {files : [...file]}
        })
    }

    function loadingStage(){
        if( action === "edit"){
            return !pageLoading.objective
        }else{
            return !pageLoading.objective && !pageLoading.objectiveSelect
        }
    }

    function handleOnNotificationsIconClick(){
        history.push("/notification-reference?selected=notification&tab=inbox&action=objectives")
    }

    function getDateRange(type){
        if(type === 'start_date'){
            if(action === "edit" && !isEmpty(objective) && parseInt(queryParams.level) > 0) {
                return formatDate(objective?.path[OBJECTIVE_LEVEL[objective.level - 1]]?.start_date)
            } else if(nestVCFCheckState && !isEmpty(nestedVCF)) {
                return formatDate(nestedVCF.start_date)
            } else if(action === "create" && !isEmpty(queryParams.vcf_id) && !isEmpty(objective)) {
                return formatDate(objective.start_date)
            } else {
                return ""
            }
        } else if(type === 'end_date') {
            if(action === "edit" && !isEmpty(objective) && parseInt(queryParams.level) > 0) {
                return formatDate(objective?.path[OBJECTIVE_LEVEL[objective.level - 1]]?.end_date)
            } else if(nestVCFCheckState && !isEmpty(nestedVCF)) {
                return formatDate(nestedVCF.end_date)
            } else if(action === "create" && !isEmpty(queryParams.vcf_id) && !isEmpty(objective)) {
                return formatDate(objective.end_date)
            } else {
                return ""
            }
        } else {
            return ""
        }
    }

    function getForm(){
        return (
            <div className="mt-3">
                <Formik 
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema = { action === "edit" ? editObjectiveValidationSchema(objective) : createObjectiveValidationSchema(objective) }
                    onSubmit = {onSubmit} >
                    {props => {
                        const { values, touched, errors, handleChange, handleBlur, resetForm } = props
                        return (
                            <>
                                <Form>
                                    <div>
                                    <Grid container sx={{marginTop:"20px"}} spacing={3} >
                                            <Grid item md={4} xs={12} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                <InputBox type="text" 
                                                    label="Name"
                                                    name="name"
                                                    placeholder="Enter the VCF name"
                                                    value={ values.name }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.name && errors.name}
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                <DatePicker type="date" 
                                                    label="Start Date"
                                                    name="start_date"
                                                    min={getDateRange('start_date')}
                                                    max={ getDateRange('end_date')}
                                                    value={values.start_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.start_date && errors.start_date}  
                                                />
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                <DatePicker type="date" 
                                                    label="End Date"
                                                    name="end_date"
                                                    min={moment().format("YYYY-MM-DD")}
                                                    max={getDateRange("end_date")}
                                                    value={values.end_date}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMgs={touched.end_date && errors.end_date}
                                                />
                                            </Grid>
                                        </Grid> 
                                        <div className="mt-3">
                                            <TextAreaBox name="description"
                                                placeholder="Enter the VCF description"
                                                label="Description"
                                                value={values.description}
                                                rows={4}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errormgs={touched.description && errors.description}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <FieldArray name="reference_links" >
                                                {fieldArrayProps => {
                                                    const { push, remove, form } = fieldArrayProps
                                                    const { values } = form 
                                                    const { reference_links } = values
                                                    return (
                                                        <>
                                                            {reference_links.map((file,index) => (
                                                                <Grid key={index} container sx={{marginTop:"10px"}} spacing={ isDesktop ? "" : 2}>
                                                                    <Grid item md={4} xs={12} sx={{paddingRight: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].originalname`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <>
                                                                                        <InputBox type="text"
                                                                                            label="Link Name"
                                                                                            placeholder="Enter the link name"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item md={ size(reference_links) > 1 ? 7 : 8} xs={ size(reference_links) > 1 ? 11 : 12} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                        <Field name={`reference_links[${index}].url`}>
                                                                            {props => {
                                                                                const {field,meta} = props
                                                                                return (
                                                                                    <div>
                                                                                        <InputBox type="url"
                                                                                            label="Link Url"
                                                                                            placeholder="http://www.example.com"
                                                                                            {...field}
                                                                                            errorMgs = {(meta.touched && meta.error) ? meta.error : ""}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        </Field>
                                                                    </Grid> 
                                                                    <Grid item md={1} xs={1} sx={{paddingLeft: {xs:0, md:"10px"}}}>
                                                                        {
                                                                            size(reference_links) > 1 ?
                                                                                <div className="d-flex justify-content-end h-100 align-items-center mt-1">
                                                                                    <IconButton onClick={() => remove(index)}>
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                                </div> 
                                                                                : null
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                            <div className='mt-2'>
                                                                <CustomizeButton variant="secondary-start-icon-button" label="Add Link" StartIcon={<AddIcon/>} handleClick={() => push(emptyLinkObj)} />
                                                            </div>
                                                        </>
                                                    )
                                                    }}
                                            </FieldArray>
                                        </div>
                                    </div>
                                    <div className="p-relative mt-4">
                                        <div>
                                            { !isEmpty(existingReferences) ? existingReferences.map((reference, index) => (
                                                    <div key={index} className="mb-1 d-flex">
                                                        <a  href={reference.url} target="_blank" 
                                                            className="filename lato-fs15-normal-lh20 one-line-wrap pb-2"> {reference.originalname}</a>
                                                        <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                            onClick={() => handleDeleteExistingReferences(index)} icon={faTrash} />
                                                    </div>
                                                )
                                            ) : null}
                                            { !isEmpty(referenceFiles) && referenceFiles.files.length > 0 ? referenceFiles.files.map((file,index)=>(
                                                <div key={index} className="mb-1 d-flex">
                                                    <p className="lato-fs15-normal-lh20 mb-0 one-line-wrap pb-2"> { file.filename ? file.filename : file.name}</p>
                                                    <FontAwesomeIcon className="ms-auto me-2 secondary-color pt-cursor" 
                                                        onClick={() => handleDeleteReferenceFile(index)} icon={faTrash} />
                                                </div>
                                            )) : null}
                                        </div>
                                        <button className={isDesktop ? "upload-image p-2" : "upload-image"} onClick={(event) => {
                                                event.preventDefault();
                                                referenceFileRef.current.click();
                                            }}> <FontAwesomeIcon icon={faCloudUploadAlt} className="me-1" /> Click to upload attachment</button>
                                    </div>
                                    <input type="file"
                                        style={{display:"none"}} 
                                        ref={referenceFileRef} 
                                        multiple
                                        accept="image/*,audio/*,image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.doc,.docx,.odt,.txt,.rtf,.xlsx,.xls,.ods,.xlsx" 
                                        name="objective_icon"
                                        onChange={(event) =>{
                                            const file = event.target.files
                                            if(file){
                                                handleReferenceFileAddition(file)
                                            }
                                        }}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }} 
                                    />
                                    { 
                                        !isEmpty(selectObjectiveState.response.objectives) && isEmpty(queryParams) ?
                                            <div>
                                                <div className="d-flex align-items-center mt-3">
                                                    <input type="checkbox" checked={nestVCFCheckState} onChange={(e) => handleOnNestedVCFCheckChange(e)}/> 
                                                    <label className="ms-2 mb-0 lato-fs15-normal-lh20 pb-0 primary-text-color">Nest VCF to</label>
                                                </div>
                                                <div className="mt-1 w-50">
                                                    {
                                                        nestVCFCheckState ?
                                                            <select className="grey-select-box"
                                                                value={nestedVCF?._id || ""}
                                                                onChange={(e)=>{
                                                                    getObj(selectObjectiveState.response.objectives,e.target.value)
                                                                    .then((res) => {
                                                                        console.log(res)
                                                                        setNestedVCF(res)
                                                                    })
                                                                }}>
                                                                <option className="lato-fs15-normal-lh20 primary-color" value="" hidden>Select a VCF</option>
                                                                {objectiveList(selectObjectiveState.response.objectives)}
                                                            </select>
                                                            : null
                                                    }
                                                </div>
                                            </div> 
                                            : null
                                    }
                                    <div className="d-flex justify-content-center align-center mt-4 pb-4">
                                        <CustomizeButton 
                                            variant="secondary-color-button" 
                                            type="reset" 
                                            className={ isDesktop ? "me-5" : "me-4" }
                                            handleClick={() => handleFormReset(resetForm)} 
                                            label="Clear All" 
                                        />
                                        <CustomizeLoadingButton 
                                            type="submit" 
                                            variant="accept-loading-button-v2"
                                            loadingState={loading}
                                            label={action === "edit" ? "Update VCF" : "Create VCF"}
                                        />
                                    </div>
                                </Form> 
                            </>
                        )
                    }}
                </Formik>
            </div>
        )
    }

    return (
        isDesktop ? 
            <Container>
                <Navbar 
                    enableMore={true} 
                    enableLogout={true}
                    enableProfileButton={true}
                    open={open}
                    moreRight="202px"
                    menu={moreMenu} 
                    handlePopoverClose={handleMenuClose}
                    handlePopoverOpen={handleMenuOpen}
                    enableNotification={true}
                    notificationOnClick={handleOnNotificationsIconClick}
                />
                <ProcessSectionContainer>
                    <LeftVerticalMenuBar />
                    <div style={{ width: "calc(100vw - 70px)", minHeight: "calc(100vh - 64px)", marginLeft:"70px"}}>
                        {
                            loadingStage() ?
                                <div style={{width:"800px",marginLeft:"auto",marginRight:"auto", marginTop:"20px"}}>
                                    <span className='primary-text-color lato-fs22-normal-lh32 font-bold'>{action === "edit" ? "Update VCF" : "Create VCF"}</span>
                                    <span className='secondary-text-color lato-fs16-normal-lh20'>{ action === "create" && !isEmpty(queryParams.level) && !isEmpty(objective) ? ` in ${objective.path[OBJECTIVE_LEVEL[parseInt(queryParams.level)]].name}` : "" }</span>
                                    <div className='mt-3'>
                                        <ProcessWallpaperEdit 
                                            ref={wallpaperImageRef}
                                            onRemoveClick={() => {
                                                onRemoveWallpaperClick()
                                                handleWallpaperMenuClose()
                                            }}
                                            onUploadClick={(event) => {
                                                event.preventDefault();
                                                wallpaperImageRef.current.click();
                                                handleWallpaperMenuClose()
                                            }}
                                            handleMenuClose={handleWallpaperMenuClose}
                                            menuOpen={wallpaperMenuOpen}
                                            anchorEl={wallpaperAnchorEl}
                                            previewImage={wallpaperPreviewImage}
                                            handleEditIconClick={handleWallpaperEditIconClick}
                                            onInputChange={(event) =>{
                                                const file = event.target.files[0];
                                                if(file && file.type.substr(0,5) === "image"){
                                                    setWallpaperImageFile(file)
                                                }
                                            }}
                                        />
                                        <ProcessIconEdit 
                                            ref={iconImageRef}
                                            onRemoveClick={() => {
                                                onRemoveIconClick()
                                                handleIconMenuClose()
                                            }}
                                            onUploadClick={(event) => {
                                                event.preventDefault();
                                                iconImageRef.current.click();
                                                handleIconMenuClose()
                                            }}
                                            handleMenuClose={handleIconMenuClose}
                                            menuOpen={iconMenuOpen}
                                            anchorEl={iconAnchorEl}
                                            previewImage={iconPreviewImage}
                                            handleEditIconClick={handleIconEditIconClick}
                                            onInputChange={(event) =>{
                                                const file = event.target.files[0];
                                                if(file && file.type.substr(0,5) === "image"){
                                                    setIconImageFile(file)
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>
                                        {getForm()}
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </ProcessSectionContainer>
            </Container>
        :
        <Container>
            <Navbar
                variant="mobile" 
                enableLogout={true}
                enableDrawer={true}
                drawerOpened={drawer}
                handleDrawerClose={toggleDrawer}
                onDrawerClick={toggleDrawer}
                enableNotificationsIcon={true}
                onNotificationsIconClick={handleOnNotificationsIconClick}
            />
            <div className="container">
                {
                    loadingStage() ?
                        <div className='mt-3 px-3' style={{paddingBottom:"50px"}}>
                            <div className='mb-3'>
                                <Header 
                                    heading={ action === "edit" ? "Update VCF" : "Create VCF" } 
                                    subHeading={ action === "create" && !isEmpty(queryParams.level) && !isEmpty(objective) ? ` in ${objective.path[OBJECTIVE_LEVEL[parseInt(queryParams.level)]].name}` : "" }
                                    handleBackArrowEvent={handleBackArrowEvent} />
                            </div>
                            <div>
                                <ProcessWallpaperEdit 
                                    ref={wallpaperImageRef}
                                    onRemoveClick={() => {
                                        onRemoveWallpaperClick()
                                        handleWallpaperMenuClose()
                                    }}
                                    onUploadClick={(event) => {
                                        event.preventDefault();
                                        wallpaperImageRef.current.click();
                                        handleWallpaperMenuClose()
                                    }}
                                    handleMenuClose={handleWallpaperMenuClose}
                                    menuOpen={wallpaperMenuOpen}
                                    anchorEl={wallpaperAnchorEl}
                                    previewImage={wallpaperPreviewImage}
                                    handleEditIconClick={handleWallpaperEditIconClick}
                                    onInputChange={(event) =>{
                                        const file = event.target.files[0];
                                        if(file && file.type.substr(0,5) === "image"){
                                            setWallpaperImageFile(file)
                                        }
                                    }}
                                />
                                <ProcessIconEdit 
                                    ref={iconImageRef}
                                    onRemoveClick={() => {
                                        onRemoveIconClick()
                                        handleIconMenuClose()
                                    }}
                                    onUploadClick={(event) => {
                                        event.preventDefault();
                                        iconImageRef.current.click();
                                        handleIconMenuClose()
                                    }}
                                    handleMenuClose={handleIconMenuClose}
                                    menuOpen={iconMenuOpen}
                                    anchorEl={iconAnchorEl}
                                    previewImage={iconPreviewImage}
                                    handleEditIconClick={handleIconEditIconClick}
                                    onInputChange={(event) =>{
                                        const file = event.target.files[0];
                                        if(file && file.type.substr(0,5) === "image"){
                                            setIconImageFile(file)
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                {getForm()}
                            </div>
                        </div>
                    : null
                }
            </div>
            <BottomNavigation />
        </Container>
    )
}

const mapStateToProps = ( state ) => {
    return {
        objectiveStates : state.objectiveCUD,
        selectObjectiveState : state.objectiveForSelect,
        specificObjectiveState : state.reviewSpecificObjectiveDetail,
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return {
        createObjective : (objectiveDetails, objectiveId) => dispatch(createObjectives(objectiveDetails, objectiveId)),
        updateObjectives : (objectivesDetails, objectiveId) => dispatch(updateObjectives(objectivesDetails, objectiveId)),
        objectiveActionChanges : () => dispatch(objectiveActionChanges()),
        requestObjectiveForSelect : () => dispatch(requestObjectiveForSelect()),
        clearObjectiveCUDStates : () => dispatch(clearObjectiveCUDStates()),
        requestSpecificObjective : (objectiveId) => dispatch(requestSpecificObjective(objectiveId)),
        clearSpecificObjectiveState : () => dispatch(clearSpecificObjectiveForReview()),
        specificObjectiveUpdated : () => dispatch(specificObjectiveUpdated()),
        objectiveSelectActionChanges : () => dispatch(objectiveSelectActionChanges()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddObjective)