import React,{ useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import './more-popup.css'
import { toast } from 'react-toastify'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useHistory } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import { connect } from 'react-redux'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'

import { fetchProfileData } from '../../../services/ProfileData.service'
import { getRole } from '../../../utils/utils'
import { CONTROLLER_ROLE, INDIVIDUAL_ROLE } from '../../../utils/constants'

const MorePopup = (props) => {

    const { profileDataState, fetchProfile  } = props

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ controllerCollapse, setControllerCollapse] = useState(true)
    const [ accountCollapse, setAccountCollapse ] = useState(true)

    const history = useHistory()

    const profile = profileDataState.response.profile

    const handleClick = () => {
        setControllerCollapse(!controllerCollapse);
    };

    const handleAccountCollapse = () => {
        setAccountCollapse( ps => !ps )
    }

    function handleMembers(){
        if(getRole() === "user_cum_controller"){
            history.push(`/organisation?selected=members`)
        }else{
            history.push(`/organisation`)
        }
    }
    
    function handleTeamMember(){
        let teamMember = false
        if(getRole() === CONTROLLER_ROLE){
            return teamMember = true
        }else if(getRole() === "user"){
            if(!isEmpty(profile) && !isEmpty(profile.profile_data)){
                if(!isEmpty(profile.profile_data.user_subscriber_mapping)){
                    if(profile.profile_data.user_subscriber_mapping[0]){
                        if(!isEmpty(profile.profile_data.user_subscriber_mapping[0].incharge_of)){
                            return teamMember = true
                        }else{
                            return teamMember
                        }
                    }
                }
            }
        }
    }

    function handleReviewEvent(){
        toast.info('Under Development', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    useEffect(() => {
        if(isEmpty(profileDataState.response) && !profileDataState.loading){
            fetchProfile()
        }
    },[])

    useEffect(() => {
        if(!profileDataState.loading && !isEmpty(profileDataState.response)){
            setPageLoading(false)
        }

        if(!profileDataState.loading && !isEmpty(profileDataState.error)){
            console.log(profileDataState.error)
        }
    },[profileDataState])

    return (
        !pageLoading ?
            <List sx={{paddingTop: 0, paddingBottom: 0}}>
                <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2, pt:1, pb: 1}}>
                    <ListItemAvatar sx={{minWidth:"0px"}}>
                        <Avatar src={profile.profile_data.profile_pic} />
                    </ListItemAvatar>
                    <ListItemText 
                        primary={`${profile.profile_data?.person_name?.first_name} ${profile.profile_data?.person_name?.last_name}`} 
                        secondary={profile.profile_data?.user_subscriber_mapping[0]?.job_role ? profile.profile_data?.user_subscriber_mapping[0]?.job_role : 
                                    profile.profile_data?.user_subscriber_mapping[0].role === CONTROLLER_ROLE ? "Controller" :
                                    profile.profile_data?.user_subscriber_mapping[0].role === INDIVIDUAL_ROLE ? "" : "User" }
                        secondaryTypographyProps={{
                            color : "var(--primary-color-v2)", 
                            fontSize:14,
                            fontFamily:'"Lato",sans-serif',
                        }}
                        primaryTypographyProps={{ 
                            color: "var(--primary-color-v2)",
                            fontWeight:"bold",
                            fontSize:16,
                            fontFamily:'"Lato",sans-serif',
                        }}
                        onClick={() => history.push("/profile")}
                    />
                </ListItem>
                <Divider/>
                <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}} onClick={handleAccountCollapse}>
                    <ListItemText 
                        primary="Account" 
                        primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif', marginBottom:"0px !important"}} 
                    />
                        {accountCollapse ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={accountCollapse} unmountOnExit >
                    <List sx={{ py: 0 }}>
                        {(getRole() === CONTROLLER_ROLE || getRole() === INDIVIDUAL_ROLE) ? 
                            <ListItemButton sx={{ pl: 5, height:30 }}>
                                <ListItemText primary="Subscription" 
                                primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}}
                                onClick={() => history.push(`/subscription`)}/>
                            </ListItemButton> : null }
                        <ListItemButton sx={{ pl: 5, height:30 }}>
                            <ListItemText primary="Reset Password" 
                            primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}}
                            onClick={() => history.push(`/reset-password`)}/>
                        </ListItemButton>
                    </List>
                </Collapse>
                { 
                    getRole() !== INDIVIDUAL_ROLE ? 
                        <>
                            <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}} >
                                <ListItemText 
                                    primary="Your Organisation" 
                                    primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}}
                                    onClick={ handleMembers }
                                />
                            </ListItem>
                            {
                                handleTeamMember() ?
                                    <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}}>
                                        <ListItemText 
                                            primary="Team" 
                                            primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}}
                                            onClick={() => history.push("/team-member")}
                                        />
                                    </ListItem> 
                                    : null 
                            }
                            { 
                                !isEmpty(profile) && !isEmpty(profile.profile_data) && !isEmpty(profile.profile_data.user_subscriber_mapping[0]) && !isEmpty(profile.profile_data. user_subscriber_mapping[0].incharge_of) ? 
                                    <ListItem button component="div" disablePadding sx={{pl:2 ,pr:2}}>
                                        <ListItemText 
                                            primary="Review"
                                            primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}}
                                            onClick={handleReviewEvent}
                                        />
                                    </ListItem> 
                                    : null
                            }
                            {
                                getRole() === CONTROLLER_ROLE ?
                                    <>
                                        <ListItem onClick={handleClick} sx={{ height: 25 }}>
                                            <ListItemText 
                                                primary="Controller Settings"
                                                primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}}
                                            />
                                            {controllerCollapse ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={controllerCollapse} unmountOnExit>
                                            <List sx={{ py: 0 }}>
                                                <ListItemButton sx={{ pl: 5, height:30 }} >
                                                    <ListItemText 
                                                        primary="Location" 
                                                        primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}} 
                                                        onClick={() => history.push("/location?selected=active-locations")}
                                                    />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 5, height:30 }}>
                                                    <ListItemText 
                                                        primary="Department" 
                                                        primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}}
                                                        onClick={() => history.push("/department?selected=active-departments")}    
                                                    />
                                                </ListItemButton>
                                                <ListItemButton sx={{ pl: 5, height:30 }} >
                                                    <ListItemText 
                                                        primary="Workflow Mappings"
                                                        primaryTypographyProps={{ color: "var(--primary-color-v2)", fontWeight:"normal", fontSize:15, fontFamily:'"Lato", sans-serif'}} 
                                                        onClick={() => history.push('/workflow-mappings')}
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                    </> 
                                    : null
                            }
                        </> 
                        : null
                }
            </List>
            :null 
    )
}

const mapStateToProps = (state) => {
    return {
        profileDataState : state.profileData 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProfile : () => dispatch(fetchProfileData()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MorePopup)