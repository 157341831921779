import { REQUEST_SENT_ACTION_FETCH,
    FETCH_SENT_ACTION_SUCCESS,
    FETCH_SENT_ACTION_FAILURE,
    CLEAR_FETCH_SENT_ACTION_STATES,
    NEW_MESSAGE_ADDED } from './sent.types'

const initialState = {
    loading : false,
    new_message : false,
    response : {},
    error : null
}

const fetchSentActionReducer = (state = initialState, action) => {
    switch(action.type){
        case REQUEST_SENT_ACTION_FETCH : 
            return {
                ...state,
                loading: true,
                new_message: false,
                error: null
            }

        case FETCH_SENT_ACTION_SUCCESS: 
            return {
                ...state,
                loading: false,
                new_message: false,
                response : action.payload
            }

        case FETCH_SENT_ACTION_FAILURE : 
            return {
                ...state,
                loading: false,
                new_message: false,
                error: action.payload
            }

        case  NEW_MESSAGE_ADDED:
            return {
                ...state,
                loading: true,
                new_message: true,
            }

        case CLEAR_FETCH_SENT_ACTION_STATES:
            return {
                loading : false,
                new_message: false,
                response : {},
                error : null
            }
            
        default : 
            return state
    }
}

export default fetchSentActionReducer