import React from 'react';
import './process-main-container.css'

const ProcessMainContainer = (props) => {

    switch(props.variant){
        case "noBackground":{
            const className = props.className ? `main-container ${props.className}` : 'main-container'
            return (<main className={className}>{props.children}</main>)
        }

        default:{
            const className = props.className ? `main-container bg-secondary-color-v2 ${props.className}` : 'main-container bg-secondary-color-v2'
            return (<main className={className}>{props.children}</main>)
        }
    }

};

export default ProcessMainContainer
