import React,{useState,useEffect} from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'
import { useLocation, useParams ,useHistory} from 'react-router-dom'
import { toast } from 'react-toastify'
import { isEmpty, toArray, capitalize, size } from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

import BottomNavigation from '../../../components/bottom-navgation/BottomNavigation'
import MailUserDetail from '../../../components/notification/mail-user-detail/MailUserDetail'
import { getUserId } from '../../../utils/utils'
import { notificationDetail } from '../../../services/notification.service'
import { clearSpecificMetricState, specificMetricSuccess } from '../../../redux/specific-metric/specificMetric.action'
import { clearSpecificObjectiveState } from '../../../redux/specific-objective/SpecificObjectiveAction'
import { clearSpecificGoalStates } from '../../../redux/specific-goal/specificGoal.action'
import { clearSpecificLearningArea } from '../../../redux/learning/specific-learning-area/specificLearningArea.action'
import { clearCoursesState } from '../../../redux/video-conference/course/Course.action'
import SimpleCard from '../../../components/simple-card/SimpleCard'
import Container from '../../../components/desktop-layout/container/Container'
import Navbar from '../../../components/desktop-layout/navbar/Navbar'
import ProcessSectionContainer from '../../../components/desktop-layout/process-section-container/ProcessSectionContainer'
import LeftVerticalMenuBar from '../../../components/desktop-layout/left-vertical-menu-bar/LeftVerticalMenuBar'
import ProcessMainContainer from '../../../components/desktop-layout/process-main-container/ProcessMainContainer'
import SubMainContainer from '../../../components/desktop-layout/sub-main-container/SubMainContainer'
import CarouselSlider from '../../../common/carousel-slider/CarouselSlider'
import Header from '../../../common/header/Header'
import './inbox-detail.css'
import AggregateCard from '../../../components/aggregate-card/AggregateCard'

const InboxDetail = (props) => {

    const { requestSentNotificationDetail, 
            notificationDetailState, 
            saveMetric,
            clearSpecificObjectiveState,
            specificObjectiveState,
            clearSpecificGoalState,
            specificGoalState,
            clearReadCourseState,
            readCourseState,
            specificLearningAreaState,
            specificMetricState } = props

    const [pageLoading, setPageLoading] = useState(true)
    const [ menu, setMenu ] = useState(false)
    const [ drawer, setDrawer ] = useState(false)

    const history = useHistory()
    const params = useParams()
    const location = useLocation()

    const queryParams = qs.parse(location.search)
    const inboxActions = notificationDetailState.response.mail
    const isMobile = useMediaQuery('(max-width:480px)')
    const isDesktop = useMediaQuery('(min-width:992px)')

    function handleBackArrowEvent(){
        history.goBack()
    }

    const open = Boolean(menu)

    const handleMenuOpen = (event) => {
        setMenu(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenu(null)
    }

    function toggleDrawer(){
        setDrawer( ps => !ps )
    }

    function handleCardClick(actionId,sharedAction){
        if(inboxActions.mail_type === "action_share"){
            const user = sharedAction.shared_with.find((sharedaction) => sharedaction.user === getUserId())
            if(inboxActions.action_type === "objectives"){
                history.push(`/notification/${params.notificationId}/vcf/${actionId}?action=${queryParams.action}&actionId=${sharedAction._id}&status=${user.status}`) 
            }else if(inboxActions.action_type === "goals"){
                // saveMetric(sharedAction.action)
                history.push(`/notification/${params.notificationId}/goal/${actionId}?action=${queryParams.action}&actionId=${sharedAction._id}&status=${user.status}`)
            }else if(inboxActions.action_type === "graphs"){
                history.push(`/notification/${params.notificationId}/metric/${actionId}?action=${queryParams.action}&actionId=${sharedAction._id}&status=${user.status}`)  
            }else if(inboxActions.action_type === "learningareas"){
                history.push(`/notification/${params.notificationId}/learning-area/${actionId}?action=${queryParams.action}&actionId=${sharedAction._id}&status=${user.status}`)
            }else if(inboxActions.action_type === "courses"){
                history.push(`/notification/${params.notificationId}/course/${actionId}?action=${queryParams.action}&actionId=${sharedAction._id}&status=${user.status}`)
            }
        }  
    }

    useEffect(() => {
        if( !notificationDetailState.loading && !isEmpty(notificationDetailState.response)){
            setPageLoading(false)
        }

        if(!notificationDetailState.loading && !isEmpty(notificationDetailState.error)){
            setPageLoading(false)
            if(notificationDetailState.error.data){
                toast.error(notificationDetailState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }

        }

        if(notificationDetailState.loading && notificationDetailState.action_changes){
            requestSentNotificationDetail(params.notificationId,"inbox")
        }

    },[notificationDetailState])

    useEffect(() => {
        if(isEmpty(notificationDetailState.response)){
            requestSentNotificationDetail(params.notificationId,"inbox")
        }

        // clear specific objective
        if(!isEmpty(specificObjectiveState.response)){
            clearSpecificObjectiveState()
        }

        //clear specific goal state
        if(!isEmpty(specificGoalState.response)){
            clearSpecificGoalState()
        }

        //clear specific metric state
        if(!isEmpty(specificMetricState.response)){
            clearSpecificMetricState()
        }

        //clear specific learning area
        if(!isEmpty(specificLearningAreaState.response)){
            clearSpecificLearningArea()
        }

        //clear specific course state
        if(!isEmpty(readCourseState.response)){
            clearReadCourseState()
        }
    },[])

    function getActiveId(objective){
        if(objective.edited_copy){
            return objective.edited_after._id
        } else {
            return objective._id
        }
    }

    // course icon
    function getCourseThumbnail(course){
        let thumbnail = ''
        if(!isEmpty(course.course_content_files)){
            for (const file of course.course_content_files) {
                if(file.mimetype?.split('/')[0] == 'image'){
                    thumbnail = file.url
                    break
                }
            }
        }
        
        if(!thumbnail && !isEmpty(course.course_content_links)){
            for(const link of course.course_content_links){
                if(link.link_meta_data?.contentType?.split('/')[0] == 'image'){
                    thumbnail = link.link_meta_data.url
                    break
                } else if(link.link_meta_data?.contentType?.split('/')[0] == 'text' && !isEmpty(link.link_meta_data.images)) {
                    thumbnail = link.link_meta_data.images[0]
                    break
                }
            }
        }
        return thumbnail
    }

    function currentUserStatus(sharedAction){
        const sharedStatus = sharedAction?.shared_with.find((sharedAction) => sharedAction.user === getUserId())
        return sharedStatus?.status
    }

    function getPath(objective){
        if(!isEmpty(objective)){
            let pathArray =  toArray(objective.path)
            return pathArray.splice(0,pathArray.length-1)
        } else {
            return null
        }
    }

    function sharedActionContainerClassName(mailType){
        if(mailType === "action_share"){
            if(isDesktop){
                return ""
            }else{
                return "max-width-250px"
            }
        }else{
            if(isDesktop){
                return "cursor-unset"
            }else{
                return "cursor-unset max-width-250"
            }
        }
    }

    function sharedActionCard(actionType, sharedAction, mailType){
        switch(actionType){
            case "graphs" :
                return (
                    <AggregateCard
                        onClick={ () => handleCardClick(sharedAction.action._id, sharedAction) }
                        variant={isDesktop ? "desktop" : "mobile"}
                        hideIcon={true}
                        containerClassName={sharedActionContainerClassName(mailType)}
                        status={mailType === "action_share" ? currentUserStatus(sharedAction) : ""}
                        name={sharedAction.action.metric.name} />
                )

            case "learningareas" :
                return (
                    <AggregateCard
                        name={sharedAction.action.name}
                        variant={isDesktop ? "desktop" : ""}
                        containerClassName={sharedActionContainerClassName(mailType)}
                        status={mailType === "action_share" ? currentUserStatus(sharedAction) : ""}
                        icon={inboxActions.action_type === "courses"? 
                                getCourseThumbnail(sharedAction.action) : sharedAction.action.icon }
                        onClick={()=>handleCardClick(sharedAction.action._id, sharedAction)} />
                )

            case "courses" : 
                return (
                    <AggregateCard 
                        name={sharedAction.action.name} 
                        variant={isDesktop ? "desktop" : ""}
                        containerClassName={sharedActionContainerClassName(mailType)}
                        status={mailType === "action_share" ? currentUserStatus(sharedAction) : ""}
                        onClick={()=>handleCardClick(sharedAction.action._id, sharedAction)}
                        icon={ inboxActions.action_type === "courses"? 
                                getCourseThumbnail(sharedAction.action) : sharedAction.action.icon} />
                )

            default : 
                return(
                    <AggregateCard 
                        wallpaper={sharedAction.action.wallpaper} 
                        icon={sharedAction.action.icon }
                        name={sharedAction.action.name}
                        level={sharedAction.action.level}
                        maxLevel={sharedAction.action.max_level}
                        onClick={()=>handleCardClick(sharedAction.action._id, sharedAction)}
                        variant = {isDesktop ? "desktop" : ""}
                        status = {mailType === "action_share" ? currentUserStatus(sharedAction) : ""}
                        containerClassName={sharedActionContainerClassName(mailType)}
                    />
                )
        }
    }

    function getDetail(){
        return (
            <>
                { isDesktop ? 
                    <div className='bg-tertiary-color-v2'style={{padding:"10px", borderRadius:"1rem"}}> 
                        <CarouselSlider size={size(inboxActions.actions)} variant="3-slide"
                            breadcrumbs={<h1 className="lato-fs18-normal-lh22 font-bold my-2 primary-text-color">{inboxActions.action_type === "objectives" ? "VCF" : capitalize(inboxActions.action_type )}</h1>} >
                            {!isEmpty(inboxActions.actions) ? 
                                inboxActions.actions.map((sharedAction) => (
                                    <div key={sharedAction._id} className="mb-3">
                                        {!isEmpty(sharedAction.action) && sharedAction.action_available ?
                                            <>
                                            {/* { inboxActions.action_type == "objectives" && sharedAction.action?.level > 0 ?
                                                    <BreadCrumbs containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                        path={getPath(sharedAction.action)}
                                                        icon={faThLarge} 
                                                        truncate={isMobile}/>
                                                    : null } */}
                                            {/* {inboxActions.action_type == "goals" ? 
                                                <BreadCrumbs path={toArray(sharedAction.action.objective_id.path)} 
                                                    icon={faThLarge}
                                                    containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                    activeId={sharedAction.action.objective_id._id} /> : ""} */}
                                            {/* {inboxActions.action_type == "graphs" ? 
                                                    <>
                                                        <BreadCrumbs path={toArray(sharedAction.action.goal.objective_id.path)} 
                                                            containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                            activeId={sharedAction.action.goal.objective_id._id}
                                                            icon={faThLarge} /> 
                                                        <div>
                                                            <BreadCrumbs path={[{name:sharedAction.action.goal.name,_id :sharedAction.action.goal._id}]}
                                                                containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                                activeId={sharedAction.action.goal._id} img={goalIcon} />
                                                        </div>
                                                    </>: ""} */}
                                            {/* {inboxActions.action_type === "courses" ? 
                                                <BreadCrumbs icon={faListAlt} 
                                                    containerClassName="mb-2 bread-crumb-box-shadow" listClassName="mb-0"
                                                    path={[{name : sharedAction.action.parent_learning_area.name,_id : sharedAction.action.parent_learning_area._id}]} activeId={sharedAction.action.parent_learning_area._id} /> : ""} */}
                                                
                                                <Box sx={{ marginLeft:"10px",marginRight:"10px"}}>
                                                    {sharedActionCard(inboxActions.action_type, sharedAction, inboxActions.mail_type)}
                                                </Box>
                                            </> : 
                                            <Box sx={{marginLeft:"10px",marginRight:"10px"}}>
                                                <SimpleCard name="Process Unavailable" /> 
                                            </Box>}
                                    </div>
                                )) : null
                            }
                        </CarouselSlider> 
                    </div> :
                    <div className='scroll-container bg-tertiary-color-v2 py-3' style={{borderRadius:"1rem"}}>
                        {!isEmpty(inboxActions.actions) ? 
                            inboxActions.actions.map((sharedAction) => (
                                <div className='inbox-card-detail-container' key={sharedAction._id}>
                                    {!isEmpty(sharedAction.action) && sharedAction.action_available ?
                                        <>
                                            <Box>
                                                {sharedActionCard(inboxActions.action_type, sharedAction, inboxActions.mail_type)}
                                            </Box>
                                        </> : 
                                        <Box>
                                            <SimpleCard name="Process Unavailable" /> 
                                        </Box>}
                                </div>
                            )) : null}
                    </div>
                }
                <hr/>
                <div className={isDesktop ? '' : 'px-3'}>
                    <MailUserDetail
                        variant={isDesktop ? '' : 'mobile'}
                        createdDate={inboxActions.createdAt}
                        usersData={inboxActions.from}
                        message={inboxActions.message}
                        actionWay="From"
                    />
                </div>
            </>
        )
    }

    return (
        <>
            {isDesktop ? 
                <Container>
                    <Navbar
                        enableLogout={true}
                        enableProfileButton={true}
                        enableMore={true}
                        open={open} 
                        menu={menu}
                        moreRight="70px"
                        handlePopoverClose={handleMenuClose} 
                        handlePopoverOpen={handleMenuOpen} />
                    <ProcessSectionContainer>
                        <LeftVerticalMenuBar />
                        <ProcessMainContainer className="white-bg">
                            <SubMainContainer className="mb-40">
                                {!pageLoading  && !isEmpty(inboxActions) ? 
                                    <>
                                        <div className='mt-3'>
                                            <h5 className='lato-fs-20-normal-lh32 primary-text-color font-bold mb-0'>{inboxActions.subject}</h5>
                                            {/* <h5 className="lato-fs15-normal-lh20 mb-0 pb-0 pt-1 secondary-text-color">{capitalize(inboxActions.action_type === "objectives" ? "vcfs" : inboxActions.action_type )}</h5> */}
                                        </div>
                                        <div className='mt-3'>
                                            {
                                                getDetail()
                                            }
                                        </div>
                                    </> : null
                                }
                            </SubMainContainer>
                        </ProcessMainContainer>
                    </ProcessSectionContainer>
                </Container>
            :
            <>
                <Container>
                    <Navbar 
                        variant="mobile" 
                        enableLogout={true}
                        enableDrawer={true}
                        drawerOpened={drawer}
                        handleDrawerClose={toggleDrawer}
                        onDrawerClick={toggleDrawer}
                    />
                    <div className='container'>
                        {!pageLoading  && !isEmpty(inboxActions) ? 
                            <>
                                <div className='px-3 mt-3'>
                                    <Header heading={inboxActions.subject} handleBackArrowEvent={handleBackArrowEvent} />
                                    <h5 className="lato-fs15-normal-lh20 mb-0 secondary-text-color">{capitalize(inboxActions.action_type === "objectives" ? "Vcfs" : inboxActions.action_type)}</h5>
                                </div>
                                <div className="mt-3 pb-70">
                                    {getDetail()}
                                </div>
                            </> : null
                        }
                    </div>
                    <BottomNavigation/>
                </Container>
            </> }
        </>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        notificationDetailState : state.notificationDetail,
        specificObjectiveState : state.specficObjective,
        specificGoalState : state.specificGoal,
        specificMetricState : state.specificMetric,
        specificLearningAreaState : state.specificLearningArea,
        readCourseState : state.readCourse
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestSentNotificationDetail : (mailId,action) => dispatch(notificationDetail(mailId,action)),
        saveMetric : (response) => dispatch(specificMetricSuccess(response)),
        clearSpecificObjectiveState : () => dispatch(clearSpecificObjectiveState()),
        clearSpecificGoalState : () => dispatch(clearSpecificGoalStates()),
        clearSpecifcMetricState : () => dispatch(clearSpecificMetricState()),
        clearLearningState : () => dispatch(clearSpecificLearningArea()),
        clearReadCourseState : () => dispatch(clearCoursesState()),
        userLogout : () => dispatch({
            type :"USER_LOGOUT"
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(InboxDetail)