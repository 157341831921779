import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { fetchNonInvitedMembers, inviteUsers } from '../../services/user.service'
import { clearNonInvitedMemberState } from '../../redux/user/non-invited-member/nonInvitedMembers.action'
import './non-invited-member.css'
import NonInvitedMemberPopup from '../non-inviited-member-popup/NonInvitedMemberPopup'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import {boldString} from '../../utils/utils'
import SubMainContainer from '../desktop-layout/sub-main-container/SubMainContainer'

const NonInvitedMemeber = (props) => {

    const { 
        nonInvitedMemberState, 
        requestNonInvitedMember, 
        ownProps 
    } = props

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ nonInvitedMemberPopup, setNonInvitedMemberPopup ] = useState({
        open : false,
        response : {}
    })

    const isDesktop = useMediaQuery('(min-width:992px)')

    const nonInvitedMember = nonInvitedMemberState.response.uninvited_users

    const initialValues = {
        users : []
    }

    function handleClose(){
        setNonInvitedMemberPopup((ps) => {
            return {
                ...ps,
                open : false,
                response : {}
            }
        })
        requestNonInvitedMember()
    }

    const onSubmit = (values, resetForm) => {
        inviteUsers(values)
            .then((res) => {
                setNonInvitedMemberPopup((ps) => {
                    return {
                        ...ps,
                        response : res.data,
                        open : true
                    }
                })
                resetForm()
            }).catch((error) =>{
                console.log(error.response)
            })
    }

    useEffect(() => {
        requestNonInvitedMember()
    },[])

    useEffect(() => {
        if(!nonInvitedMemberState.loading && !isEmpty(nonInvitedMemberState.response)){
            setPageLoading(false)
        }

        if(!nonInvitedMemberState.loading && !isEmpty(nonInvitedMemberState.error)){
            console.log(nonInvitedMemberState.error)
            setPageLoading(false)
            if(nonInvitedMemberState.error?.data){
                toast.error(nonInvitedMemberState.error.data?.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[nonInvitedMemberState])

    function showInvite(){
        if(!isEmpty(ownProps.searchedValue.value)){
            if(!isEmpty(ownProps.searchedValue.data)){
                return true
            }else{
                return false
            }
        }else{
            if(!isEmpty(nonInvitedMember)){
                return true
            }else{
                return false
            }
        }
    }

    function getName(name){
        let str = ''
        if(name.first_name){
            str += `${name.first_name} `
        }
        if(name.middle_name){
            str += `${name.middle_name} `
        }
        if(name.last_name){
            str += name.last_name
        }
        return str
    }

    function getAppropriateMemberCard(values){
        if(ownProps.searchedValue.value){
            if(!ownProps.searchedValue.typing){
                if(!isEmpty(ownProps.searchedValue.data)){
                    return (
                        <>
                            {
                                !isEmpty(values.users) ?
                                    <div className="d-flex justify-content-between align-items-center px-3 py-2 mb-3" style={{backgroundColor: 'white', borderRadius: '0.5rem'}}>
                                        <div className='lato-fs15-normal-lh20 font-bold'>
                                            { getCheckedNames(values.users) }
                                        </div>
                                        <CustomizeButton type="submit" variant="primary-color-button" label="Invite" />
                                    </div>
                                    : null
                            }
                            <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                            <div role="group" aria-labelledby="checkbox-group">
                                {
                                    ownProps.searchedValue.data.map((member, index) => (
                                        <div className="non-invited-member-container" key={index}>
                                            <Field type="checkbox" name="users" value={member._id} />
                                            <div className="ms-3 mb-0">
                                                <p 
                                                    className='lato-fs18-normal-lh25 primary-text-color mb-0'
                                                    dangerouslySetInnerHTML={{__html: boldString(getName(member.person_name), ownProps.searchedValue.value)}}
                                                />
                                                <p className="lato-fs15-normal-lh20 mb-1">{member.email.id}</p>
                                                <p className='lato-fs14-normal-lh20 tertiary-text-color-v2'>
                                                    {
                                                        member.user_subscriber_mapping[0].job_role ?
                                                        `${member.user_subscriber_mapping[0].job_role} (${member.user_subscriber_mapping[0].role == "user_cum_controller" ? "Controller" : "User"})` 
                                                        : member.user_subscriber_mapping[0].role == "user_cum_controller" ? "Controller" : "User"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            {
                                !isEmpty(values.users) ?
                                    <div className="d-flex justify-content-between align-items-center px-3 py-2 mb-3" style={{backgroundColor: 'white', borderRadius: '0.5rem'}}>
                                        <div className='lato-fs15-normal-lh20 font-bold'>
                                            { getCheckedNames(values.users) }
                                        </div>
                                        <CustomizeButton type="submit" variant="primary-color-button" label="Invite" />
                                    </div>
                                    : null
                            }
                            <div>
                                <h6 className='lato-fs16-normal-lh20 font-bold'>Search Results</h6>
                                <p className='lato-fs15-normal-lh20'>No members found for search results "<span className='lato-fs15-normal-lh20 font-bold'>{props.searchedValue.value}</span>"</p>
                            </div>
                        </>
                    )
                }
            } else {
                return (
                    <>
                        {
                            !isEmpty(values.users) ?
                                <div className="d-flex justify-content-between align-items-center px-3 py-2 mb-3" style={{backgroundColor: 'white', borderRadius: '0.5rem'}}>
                                    <div className='lato-fs15-normal-lh20 font-bold'>
                                        { getCheckedNames(values.users) }
                                    </div>
                                    <CustomizeButton type="submit" variant="primary-color-button" label="Invite" />
                                </div>
                                : null
                        }
                        <Box className="d-flex justify-content-center align-items-center h-100">
                            <CircularProgress sx={{color: 'var(--primary-color-v2)'}} />
                        </Box>
                    </>
                )
            }
        } else {
            if(!pageLoading) {
                if(!isEmpty(nonInvitedMember)){
                    return (
                        <>
                            {
                                !isEmpty(values.users) ?
                                    <div className="d-flex justify-content-between align-items-center px-3 py-2 mb-3" style={{backgroundColor: 'white', borderRadius: '0.5rem'}}>
                                        <div className='lato-fs15-normal-lh20 font-bold'>
                                            { getCheckedNames(values.users) }
                                        </div>
                                        <CustomizeButton type="submit" variant="primary-color-button" label="Invite" />
                                    </div>
                                    : null
                            }
                            <div role="group" aria-labelledby="checkbox-group">
                                {
                                    nonInvitedMember.map((member, index) => (
                                        <div className="non-invited-member-container" key={index}>
                                            <Field type="checkbox" name="users" value={member._id} />
                                            <div className="ms-3 mb-0">
                                                <p className='lato-fs18-normal-lh25 primary-text-color mb-0'>
                                                    {`${!isEmpty(member.person_name) && member.person_name.first_name ? member.person_name.first_name : "" } ${!isEmpty(member.person_name) && member.person_name.last_name ? member.person_name.last_name : "" }`}
                                                </p>
                                                <p className="lato-fs15-normal-lh20 mb-1" dangerouslySetInnerHTML={{__html: member.email.id}} />
                                                <p className='lato-fs14-normal-lh20 tertiary-text-color-v2'>
                                                    {
                                                        member.user_subscriber_mapping[0].job_role ?
                                                        `${member.user_subscriber_mapping[0].job_role} (${member.user_subscriber_mapping[0].role == "user_cum_controller" ? "Controller" : "User"})` 
                                                        : member.user_subscriber_mapping[0].role == "user_cum_controller" ? "Controller" : "User"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    )
                } else {
                    return (
                        <>
                            <p className="lato-fs18-normal-lh25">No non invited members</p>
                        </>
                    )
                }
            } else {
                return null
            }
        }
    }

    function getCheckedNames(users){
        let str = ''
        for(const member of nonInvitedMember){
            if(users.includes(member._id)){
                if(str){
                    str += `, ${getName(member.person_name)}`
                } else {
                    str += `${getName(member.person_name)}`
                }
            }
        }
        return str
    }

    return (
        <>
            {
                isDesktop ?
                    <SubMainContainer className="pb-3 pt-4">
                        <Formik
                            initialValues= {initialValues} 
                            onSubmit={(values, {resetForm }) => onSubmit(values, resetForm)}
                        >
                            {
                                (props) => {
                                    const { values } = props
                                    return (
                                        <Form>
                                            <div>
                                                {getAppropriateMemberCard(values)}
                                            </div>
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </SubMainContainer>
                    : <div className='container pt-3 pb-70 px-3'>
                        <Formik
                            initialValues= {initialValues} 
                            onSubmit={(values, {resetForm }) => onSubmit(values, resetForm)}
                        >
                            {
                                (props) => {
                                    const { values } = props
                                    return (
                                        <Form>
                                            {getAppropriateMemberCard(values)}
                                        </Form>
                                    )
                                }
                            }
                        </Formik>
                    </div>
            }
            {
                nonInvitedMemberPopup ? 
                    <NonInvitedMemberPopup 
                        open={nonInvitedMemberPopup.open}
                        invitedMembersRes={nonInvitedMemberPopup.response}
                        handleClose={handleClose}
                    />
                    : null
            }
        </>
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        nonInvitedMemberState : state.nonInvitedMembers,
        ownProps : ownProps
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestNonInvitedMember : (action) => dispatch(fetchNonInvitedMembers(action)),
        clearStates : () => dispatch(clearNonInvitedMemberState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NonInvitedMemeber)