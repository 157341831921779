import { REQUEST_OBJECTIVE_REVIEW, 
        FETCH_OBJECTIVE_REVIEW_SUCCESS,
        FETCH_OBJECTIVE_REVIEW_FAILURE,
        CLEAR_OBJECTIVE_REVIEW_STATE,
        REVIEW_OBJECTIVE_ALL_ACTION_CHANGED } from './objectiveReview.types'

export const requestObjectiveReview = () => {
    return {
        type : REQUEST_OBJECTIVE_REVIEW
    }
}

export const fetchObjectiveReviewSuccess = (response) => {
    return {
        type : FETCH_OBJECTIVE_REVIEW_SUCCESS,
        payload : response
    }
}

export const fetchObjectiveReviewFailure = (error) => {
    return {
        type : FETCH_OBJECTIVE_REVIEW_FAILURE,
        payload : error
    }
}

export const objectiveAllReviewActionchanged = () => {
    return {
        type : REVIEW_OBJECTIVE_ALL_ACTION_CHANGED
    }
}

export const clearObjectiveReviewState = () => {
    return {
        type : CLEAR_OBJECTIVE_REVIEW_STATE
    }
}