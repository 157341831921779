import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { isEmpty, toArray } from 'lodash'
import qs from 'query-string'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'

import { fetchObjectiveUnderReview } from '../../../services/review.service'
import ObjectiveCard from '../../objective-card/ObjectiveCard'
import { saveSpecificObjectiveForReview } from '../../../redux/review-team-action/specific-objective/specificObjective.action'
import withSupportDoubleClick from '../../../utils/with-support-double-click'
import BreadCrumbs from '../../breadcrumb/BreadCrumbs'
import { PROCESS_STATUS, REVIEW_TYPES } from '../../../utils/constants'

const ObjectiveUnderReview = (props) => {

    const { objectiveUnderReviewState, requestObjectiveUnderReview, saveObjectives } = props

    const [ pageLoading, setPageLoading ] = useState(true)
    const [ selectedIndex, setSelectedIndex ] = useState([])

    const objectives = objectiveUnderReviewState.response.objectives

    const params = useParams()
    const location = useLocation()
    const history = useHistory()
    const queryParams = qs.parse(location.search)

    useEffect(() => {
        if(isEmpty(objectiveUnderReviewState.response)){
            requestObjectiveUnderReview(params.userId)
        }
    },[])

    useEffect(() => {
        if(!objectiveUnderReviewState.loading && !isEmpty(objectiveUnderReviewState.response)){
            setPageLoading(false)
            setSelectedIndex(Array(objectiveUnderReviewState.response.objectives.length).fill(0))
        }

        if(!objectiveUnderReviewState.loading && !isEmpty(objectiveUnderReviewState.error)){
            console.log(objectiveUnderReviewState.error)
        }

        if(objectiveUnderReviewState.action_changed && objectiveUnderReviewState.loading){
            requestObjectiveUnderReview(params.userId)
        }

    },[objectiveUnderReviewState])

    function actionStatus(approvalStatus,reviewStatus){
        if(approvalStatus == "needs_approval"){
            if(queryParams.role == "reviewer"){
                if(reviewStatus.reviewer_needed == true){
                    if(reviewStatus.reviewer_status == "pending"){
                        return "pending"
                    }else if(reviewStatus.reviewer_status == "approved" || reviewStatus.reviewer_status == "modified_and_approved"){
                        return "approved"
                    }
                }else{
                    return "not_needed"
                }
            }else{
                if(queryParams.role == "super-reviewer"){
                    if(reviewStatus.super_reviewer_needed == true){
                        if(reviewStatus.super_reviewer_status == "pending"){
                            return "pending"
                        }else if(reviewStatus.super_reviewer_status == "approved" || reviewStatus.super_reviewer_status == "modified_and_approved"){
                            return "approved"
                        }
                    }else{
                        return "not_needed"
                    }
                }
            }
        }
    }

    const handleCardClick = withSupportDoubleClick({
        onDoubleClick : (e,payload) => {
            saveObjectives({objective : payload.objective})
            history.push(`/review/${params.userId}/action/${payload.objective._id}?role=${queryParams.role}&action=objective`)
        },
        onSingleClick : (e,index) => {
            let row = index.rowIndex
            let col = index.columnIndex
            setSelectedIndex( ps => {
                let psCopy = JSON.parse(JSON.stringify(ps))
                psCopy[row] = col
                return psCopy
            })
        }
    })

    function getValue(key, objective){
        if((objective.status === PROCESS_STATUS.needsApproval) && (objective.review_status.review_type === REVIEW_TYPES.editRequest)){
            return objective.edits[key]
        } else {
            return objective[key]
        }
    }

    return (
        !pageLoading ?
        <div>
            {!isEmpty(objectives) ?
                objectives.map((objectiverow,rowIndex) => (
                    <div key={objectiverow._id}>
                        <div className="ml-10">
                            <BreadCrumbs path={toArray(objectiverow.objectives_group[selectedIndex[rowIndex]].path)} 
                                icon={faThLarge} 
                                activeId={objectiverow.objectives_group[selectedIndex[rowIndex]]._id}/>
                        </div>
                        <div className="scroll-container">
                            { objectiverow.objectives_group.map((objective,colIndex) => (
                                <ObjectiveCard 
                                    key={objective._id}
                                    selected={selectedIndex[rowIndex] == colIndex }
                                    name={getValue('name',objective)}
                                    wallPaper={getValue('wallpaper',objective)}
                                    icon={getValue('icon',objective)}
                                    pendingStatus={actionStatus(objective.status,objective.review_status)}
                                    onClick={(e) => handleCardClick(e,{rowIndex : rowIndex, columnIndex : colIndex, objective : objective }) }
                                />
                            ))}
                        </div>
                    </div> 
                )) : null
            }
        </div> :null
    )
}

const mapStateToProps = (state) => {
    return {
        objectiveUnderReviewState : state.objectiveUnderReview
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjectiveUnderReview : (userId) => dispatch(fetchObjectiveUnderReview(userId)),
        saveObjectives : (response) => dispatch(saveSpecificObjectiveForReview(response)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ObjectiveUnderReview)