import React,{ useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import Select from 'react-select'

import { requestMetricForSelect } from '../../services/videoConference.service'
import { clearMetricsForSelectState } from '../../redux/video-conference/metric/Metric.action'
import CustomizeButton from '../../common/customize-button/CustomizeButton'
import { toast } from 'react-toastify'

const MetricSelect = (props) => {

    const { metricsState, requestMetric, selectedValue, ownProps, setSelectedValue, clearMetricState } = props

    const goals = metricsState.response.goals

    const [ pageLoading,setPageLoading ] = useState(true)
    const [ selectedMetrics, setSelectedMetrics ] = useState([])

    function formattedGoalOptions(){
        let options=[]
        for( let goal of goals ){
            let metrics=[]
            for( let graph of goal.graphs){
                metrics.push({
                    label : graph.metric.name,
                    value : graph._id
                })
            }
            options.push({label : goal.name ,options: metrics})
        }
        return options
    }

    function handleInputChange(value){
        setSelectedMetrics( value )
    }

    function handleProceedEvent(){
        setSelectedValue(selectedMetrics)
        ownProps.onCancel()
    }

    useEffect(() => {
        if(isEmpty(metricsState.response)){
            requestMetric()
        }

        setSelectedMetrics(selectedValue)
        return () => {
            clearMetricState()
        }
    },[])

    useEffect(() => {
        if(!metricsState.loading && !isEmpty(metricsState.response)){
            setPageLoading(false)
        }

        if(!metricsState.loading && !isEmpty(metricsState.error)){
            console.log(metricsState.error)
            setPageLoading(false)
            if(metricsState.error.data){
                toast.error(metricsState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[metricsState])

    return (
        !pageLoading ?
            <>
                <Select 
                    closeMenuOnSelect={false}
                    isMulti
                    menuPortalTarget={document.body}
                    styles={{ 
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        control: (styles ,{ isFocused }) => ({
                                ...styles,
                                ':hover': { borderColor: '#14213d' }, // border style on hover
                                border: isFocused ? "2px solid #14213d" : '1px solid gray', // default border color
                                boxShadow: isFocused ? '' : "", // no box-shadow
                            }),
                        option : (styles) => ({
                            ...styles,
                            fontSize : "15px",
                            fontFamily :"'Lato',sans-serif"
                        })
                    }}
                    options={formattedGoalOptions()}
                    onChange={handleInputChange}
                    value={selectedMetrics}
                />
                <div className="mt-3 d-flex justify-content-center">
                    <CustomizeButton variant="primary-color-button" handleClick={handleProceedEvent} label="Proceed"/>
                </div>
            </> : null
    )
}

const mapStateToProps = (state,ownProps) => {
    return {
        metricsState : state.metricsForSelect,
        ownProps : ownProps,
        setSelectedValue : ownProps.setSelectedValues,
        selectedValue : ownProps.selectedValue
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestMetric : () => dispatch(requestMetricForSelect()),
        clearMetricState : () => dispatch(clearMetricsForSelectState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MetricSelect)