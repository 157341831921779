import { AgoraVideoPlayer } from 'agora-rtc-react'
import React from 'react'
import MicOutlined from '@mui/icons-material/MicOutlined'
import MicOffOutlined from '@mui/icons-material/MicOffOutlined'
import PresentToAllIcon from '@mui/icons-material/PresentToAll'

import './vc-video-player.css'

const VCVideoPlayer = (props) => {

    switch(props.variant){
        case 'screen_share':
            return (
                <div className='p-relative w-100 h-100 overflow-hidden' style={{borderRadius: "1rem", backgroundColor: 'rgba(255, 255, 255, 0.15)'}}>
                    {
                        props.videoTrack ?
                            <AgoraVideoPlayer
                                className="w-100 h-100 p-absolute"
                                videoTrack={props.videoTrack}
                            />
                            : null
                    }
                    <div className="vid-controls">
                        <div 
                            className='px-3 py-2 d-flex align-items-center' 
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.5)', 
                                borderTopLeftRadius: '1rem'
                            }}
                        >
                            {
                                props.userName ?
                                    <h5 className='vid-user-name lato-fs14-normal-lh20 mb-0 me-2'>{props.userName}</h5>
                                    : null
                            }
                            <PresentToAllIcon sx={{color: 'var(--primary-color-v2)'}} />
                        </div>
                    </div>
                </div>
            )
        default:
            return (
                <div className='p-relative w-100 h-100 overflow-hidden' style={{borderRadius: "1rem", backgroundColor: 'rgba(255, 255, 255, 0.15)'}}>
                    {
                        props.isVideoTrackPresent ?
                            <AgoraVideoPlayer
                                className="w-100 h-100 p-absolute"
                                videoTrack={props.videoTrack}
                            />
                            : <div className='d-flex justify-content-center align-items-center w-100 h-100'>
                                <div 
                                    className='d-flex justify-content-center align-items-center overflow-hidden'
                                    style={{
                                        backgroundColor: 'var(--primary-color-v2)', 
                                        width: '75px',
                                        height: '75px',
                                        borderRadius: '4px',
                                        boxShadow: "0px 0px 10px 1px rgb(255 255 255 / 50%)"
                                    }}
                                >
                                    {
                                        props.userPic ?
                                            <img className='w-100 h-100' src={props.userPic} alt="" />
                                            : <h3 
                                                style={{
                                                    color: 'white',
                                                    fontSize: '26px',
                                                    lineHeight: "1.5em",
                                                    fontFamily: '"Lato", sans-serif',
                                                    fontWeight: 600,
                                                    marginBottom: 0
                                                }}
                                            >
                                                {props?.userName?.[0]}
                                            </h3>
                                    }
                                </div>
                                {
                                    props.excessMembersCount ?
                                        <div 
                                            className='d-flex justify-content-center align-items-center overflow-hidden'
                                            style={{
                                                backgroundColor: 'var(--primary-color-v2)', 
                                                width: '75px',
                                                height: '75px',
                                                borderRadius: '4px',
                                                boxShadow: "0px 0px 10px 1px rgb(255 255 255 / 50%)",
                                                marginTop: '3rem', 
                                                marginLeft: '-1rem'
                                            }}
                                        >
                                            <h3 
                                                style={{
                                                    color: 'white',
                                                    fontSize: '26px',
                                                    lineHeight: "1.5em",
                                                    fontFamily: '"Lato", sans-serif',
                                                    fontWeight: 600,
                                                    marginBottom: 0
                                                }}
                                            >
                                                {`+ ${props.excessMembersCount}`}
                                            </h3>
                                        </div>
                                        : null
                                }
                            </div>
                    }
                    <div className="vid-controls">
                        <div 
                            className='px-3 py-2 d-flex align-items-center' 
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.5)', 
                                borderTopLeftRadius: '1rem'
                            }}
                        >
                            {
                                props.userName ?
                                    <h5 className='vid-user-name lato-fs14-normal-lh20 mb-0 me-2'>{props.userName}</h5>
                                    : null
                            }
                            {
                                props.micOn ?
                                    <MicOutlined sx={{color: 'var(--primary-color-v2)'}} />
                                    : <MicOffOutlined sx={{color: 'var(--primary-color-v2)'}} />
                            }
                        </div>
                    </div>
                </div>
            )
    }
}

export default VCVideoPlayer