import { REQUEST_COURSES_FOR_SELECT, 
        COURSES_FOR_SELECT_SUCCESS,
        COURSES_FOR_SELECT_FAILURE,
        CLEAR_COURSES_FOR_SELECT_STATE } from './Course.types'

const initialState = {
    loading : false,
    response : {},
    error : null
}

const coursesForSelectReducer = ( state = initialState, action ) => {
    switch(action.type){
        case REQUEST_COURSES_FOR_SELECT :
            return {
                ...state,
                loading : true,
                error :null
            }

        case COURSES_FOR_SELECT_SUCCESS : 
            return {
                ...state,
                loading : false,
                response : action.payload
            }

        case COURSES_FOR_SELECT_FAILURE : 
            return {
                ...state,
                loading : false,
                error : action.payload
            }

        case CLEAR_COURSES_FOR_SELECT_STATE :
            return {
                ...state,
                loading : false,
                response : {},
                error :null
            }

        default :
            return state
    }
}

export default coursesForSelectReducer