import React from 'react'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'

import './member-card.css'

const MemberCard = (props) => {

    return (
        <>
            <Card
                className={ props.className ? `members-card ${props.className}` : "members-card"}
                onClick={props.handleCardClick} 
            >
                { 
                    props.profile ?
                        <Avatar 
                            sx={{width: '50px', height: '50px'}} 
                            src={props.profilePic} 
                        />
                        : null
                }
                <CardContent 
                    style={{ 
                        padding:"4px 12px",
                        display: "flex", 
                        flexDirection:"column",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >
                    { 
                        props.name ?
                            <p className="lato-fs18-normal-lh25 mb-0" dangerouslySetInnerHTML={{__html: props.name}} />
                            : null
                    }
                    {
                        props.emailId ?
                            <Typography 
                            noWrap 
                            component="div" 
                            variant="h6"
                            sx={{
                                fontSize : "15px",
                                fontWeight: 400,
                                fontFamily : "'Lato', sans-serif",
                            }}
                        >
                            {props.emailId}
                        </Typography> 
                        : null
                    }
                    <Typography 
                        noWrap 
                        component="div" 
                        variant="p"
                        sx={{
                            fontSize : "14px",
                            fontFamily: "'Lato', sans-serif",
                            color : "var(--tertiary-text-color-v2)",
                            flex : 1,
                            minWidth: 0,
                        }}
                    >
                        {props.role}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default MemberCard