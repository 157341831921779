import React from 'react'
import iconPlaceholder from '../../assets/images/icon-placeholder.jpg'
import './team-member-card.css'

const TeamMemberCard = (props) => {

    function pendingActionclassName(){
        if(props.pendingNumber >= 100){
            return "pending-action-35"
        }else if(props.pendingNumber >=10){
            return "pending-action-30"
        }else{
            return "pending-action-25"
        }
    } 

    return (
        <div className="mt-2 card align-items-center flex-row p-2 tm-card" onClick={props.onClick}>
            <img className="tm-profile" src={props.profilePic ? props.profilePic : iconPlaceholder}/>
            <div className="ms-1">
                <div>
                    <span className="me-1 fs18-lh25 primary-color">{props.firstName}</span>
                    <span className="fs18-lh25 primary-color">{props.lastName}</span>
                </div>
                <span className="fs15-lh20 secondary-color">{props.jobRole}</span>
            </div>
            { props.pendingNumber ?
                <div className={pendingActionclassName()}>
                    <span className="fs16">{props.pendingNumber}</span>
                </div> : null
            }
        </div>
    )
}

export default TeamMemberCard