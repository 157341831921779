import React,{ useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import './objective-select.css'
import { requestObjectiveForSelect } from '../../services/videoConference.service'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import { clearObjectiveState } from '../../redux/video-conference/objective/Objective.action'
import CustomizeButton from '../../common/customize-button/CustomizeButton'

const ObjectiveSelect = (props) => {

    const { 
        selectedObjectives, 
        setSelectedObjectives,  
        ownProps,
        requestObjective, 
        objectiveState,
        clearObjectiveStateForSelect
    } = props

    const [ pageLoading, setPageLoading] = useState(true)
    const [ selectedValue, setSelectedValue ] = useState([])

    const objectives = objectiveState.response.objectives

    useEffect(() => {
        if(isEmpty(objectiveState.response)){
            requestObjective()
        } 

        if(!isEmpty(selectedObjectives)){
            setSelectedValue(selectedObjectives)
        }

    },[])

    function getObjInFormat(){
        let objectivesCopy = JSON.parse(JSON.stringify(objectives))
        let objectivesArray = []
        for(let inputObjLvlZero of objectivesCopy){
            let objLvlZero = {
                label : inputObjLvlZero.name,
                value : inputObjLvlZero._id
            }
            if(!isEmpty(selectedValue) && formatToValueArray(selectedValue).includes(inputObjLvlZero._id)){
                objLvlZero.checked = true
            }
            if(!isEmpty(inputObjLvlZero.sub_objectives)){
                objLvlZero.children = []
            }
            if(!isEmpty(inputObjLvlZero.sub_objectives)){
                for(let inputObjLvlFirst of inputObjLvlZero.sub_objectives){
                    let objLvlFirst = {
                        label : inputObjLvlFirst.name,
                        value : inputObjLvlFirst._id,
                    }
                    if(!isEmpty(selectedValue) && formatToValueArray(selectedValue).includes(inputObjLvlFirst._id)){
                        objLvlFirst.checked = true
                    }
                    if(!isEmpty(inputObjLvlFirst.sub_objectives)){
                        objLvlFirst.children = []
                    }
                    if(!isEmpty(inputObjLvlFirst.sub_objectives)){
                        for(let inputObjLvlSecond of inputObjLvlFirst.sub_objectives){
                            let objLvlSecond = {
                                label : inputObjLvlSecond.name,
                                value : inputObjLvlSecond._id,
                            }
                            if(!isEmpty(selectedValue) && formatToValueArray(selectedValue).includes(inputObjLvlSecond._id)){
                                objLvlSecond.checked = true
                            }
                            if(!isEmpty(inputObjLvlSecond.sub_objectives)){
                                objLvlSecond.children = []
                            }
                            if(!isEmpty(inputObjLvlSecond.sub_objectives)){
                                for(let inputObjLvlThird of inputObjLvlSecond.sub_objectives){
                                    let objLvlThird = {
                                        label : inputObjLvlThird.name,
                                        value : inputObjLvlThird._id
                                    }
                                    if(!isEmpty(selectedValue) && formatToValueArray(selectedValue).includes(inputObjLvlThird._id)){
                                        objLvlThird.checked = true
                                    }
                                    objLvlSecond.children.push(objLvlThird)
                                }
                            }
                            objLvlFirst.children.push(objLvlSecond)
                        }
                    }
                    objLvlZero.children.push(objLvlFirst)
                }
            }
            objectivesArray.push(objLvlZero)
        }
        return objectivesArray
    }

    function formatToValueArray(array){
        return array.map((item) => item.value)
    }

    const onChange = (currentNode, selectedNodes) => {
        if(!isEmpty(selectedNodes)){
            let valuesArr = []
            for(const node of selectedNodes){
                valuesArr.push({
                    value : node.value,
                    label : node.label
                })
            }
            setSelectedValue(valuesArr)
        } else {
            setSelectedValue(selectedNodes)
        }
    }

    function handleProceedEvent(){
        setSelectedObjectives(selectedValue)
        ownProps.onCancel()
    }

    useEffect(() => {
        if( !objectiveState.loading && !isEmpty(objectiveState.response)){
            // console.log("objective",objectiveState.response)
            // console.log("objFormat", getObjInFormat(objectives))
            setPageLoading(false)
        }

        if(!objectiveState.loading && !isEmpty(objectiveState.error)){
            console.log(objectiveState.error)
            setPageLoading(false)
            if(!isEmpty(objectiveState.error.data)){
                toast.error(objectiveState.error.data.error, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }
    },[objectiveState])

    return (
        !pageLoading ? 
            <>
                {/* <h4 className='lato-fs22-normal-lh32 mb-0 primary-text-color font-bold text-center'>Select VCF</h4> */}
                <div className="container action-container">
                    { !isEmpty(objectives) ? 
                            <DropdownTreeSelect data={getObjInFormat()} 
                                className="bootstrap-demo" 
                                texts={{ placeholder: 'Select VCF' }} 
                                onChange={onChange} />
                        : null }
                </div>
                <div className="d-flex justify-content-center py-3">
                    <CustomizeButton variant="primary-color-button" handleClick={handleProceedEvent} label="Proceed"/>
                </div>
        </>: null
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps : ownProps,
        selectedObjectives : ownProps.selectedObjectives,
        setSelectedObjectives : ownProps.setSelectedObjectives,
        objectiveState : state.objectiveForSelect
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestObjective : () => dispatch(requestObjectiveForSelect()),
        clearObjectiveStateForSelect : () => dispatch(clearObjectiveState())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ObjectiveSelect)